import { HStack, Pressable, Text, VStack } from 'native-base';
import Icon from 'ui/components/Icon';
import { SearchOptionElement } from 'ui/components/InlineSearchInput/types';
import { colors } from 'ui/theme/colors';
import { Team } from 'ui/types/teams';
import { getTeamMembersTotal } from 'ui/utils/teams';

export const TeamSearchOption = (props: SearchOptionElement) => {
  const { data, onPress } = props;

  const team = JSON.parse(data.value) as Team;

  return (
    <Pressable onPress={() => onPress(data)} _hover={{ bg: 'gray.100' }}>
      <VStack space={1} p={3}>
        <Text
          fontSize={'sm'}
          lineHeight={'150%'}
          fontWeight={400}
          color={colors.gray[800]}
        >
          {data.label}
        </Text>

        <HStack alignItems={'center'} space={1}>
          <Icon icon={'users'} size={16} color={colors.gray[400]} />
          <Text
            fontSize={'sm'}
            lineHeight={'150%'}
            fontWeight={500}
            color={colors.gray[600]}
          >
            {getTeamMembersTotal(team)} members
          </Text>
        </HStack>
      </VStack>
    </Pressable>
  );
};

export default TeamSearchOption;
