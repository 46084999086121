import { Flex, HStack, Image, Text, useBreakpointValue } from 'native-base';
import Button from 'ui/components/Button';

import { useNonAuthHeaderController } from '~/components/NavBar/components/NonAuthenticatedHeader/useNonAuthHeaderController';

import { NonAuthenticatedHeaderProps } from './types';

export const NonAuthenticatedHeader = ({
  headerLogo,
  isDefaultSignInEcosystem,
  ecosystemName,
  isGuest,
}: NonAuthenticatedHeaderProps) => {
  const { logoWidth, logoHeight, handleGoToSignIn } =
    useNonAuthHeaderController({
      isDefaultSignInEcosystem,
    });
  return (
    <HStack
      w="100%"
      h="72px"
      py={3}
      px={[4, 12, 24]}
      alignItems={'center'}
      justifyContent="space-between"
      bg="white"
      borderBottomWidth="1"
      borderBottomColor="gray.200"
    >
      <Flex flexDir="row" justifyContent="space-between" alignItems="center">
        <Image
          src={headerLogo}
          w={logoWidth}
          h={logoHeight}
          alt="Ecosystem Logo"
          borderRadius={!isDefaultSignInEcosystem ? 'full' : 'none'}
        />
        {ecosystemName ? (
          <Text
            fontSize={'lg'}
            fontWeight={700}
            color="singletone.darkText"
            ml={2}
          >
            {ecosystemName}
          </Text>
        ) : null}
      </Flex>
      {isGuest ? (
        <Button
          w="fit-content"
          py="2"
          borderRadius="full"
          alignItems="center"
          rightIconName="log-in"
          onPress={handleGoToSignIn}
        >
          Sign In
        </Button>
      ) : null}
    </HStack>
  );
};
