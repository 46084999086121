import { Button, HStack, Popover, Text } from 'native-base';
import { useState } from 'react';

import { colors } from '../../theme/colors';
import Icon from '../Icon/';
import { styles } from './styles';
import { IPopOverOptions } from './types';

export const PopOverOptions = ({
  customButton,
  customButtomStyles,
  options,
  popovText,
  popovTextPosition = 'left',
  popTextColor = colors.darkText,
  popovIcon = 'more-horizontal',
  popovIconColor = colors.darkText,
}: IPopOverOptions) => {
  const [showPopOver, setShowPopOver] = useState<boolean>(false);

  const handleShowPopOver = (show: boolean) => {
    setShowPopOver(show);
  };

  return (
    <Popover
      isOpen={showPopOver}
      trigger={(triggerOptions) => (
        <Button
          {...customButtomStyles}
          {...triggerOptions}
          variant="link"
          fontSize="14px"
          fontWeight={500}
          w="fit-content"
          onPress={() => handleShowPopOver(true)}
        >
          <HStack justifyContent="center" alignItems="center">
            {customButton || (
              <HStack space={1} alignItems="center">
                {popovText && popovTextPosition === 'left' ? (
                  <Text color={popTextColor} fontSize="xs" fontWeight={500}>
                    {popovText}
                  </Text>
                ) : null}
                <Icon icon={popovIcon} color={popovIconColor} size={20} />
                {popovText && popovTextPosition === 'right' ? (
                  <Text color={popTextColor} fontSize="xs" fontWeight={500}>
                    {popovText}
                  </Text>
                ) : null}
              </HStack>
            )}
          </HStack>
        </Button>
      )}
      onClose={() => handleShowPopOver(false)}
    >
      <Popover.Content w="56" mt={4} borderRadius={16}>
        <Popover.Body>
          {options.map(
            ({ action, title, titleColor = 'darkText', icon, link }) => (
              <HStack
                alignItems="center"
                justifyContent="flex-start"
                key={title}
                p={2}
              >
                {icon ? (
                  <Icon
                    icon={icon.icon}
                    size={icon.size}
                    color={icon.color}
                    isCustom={icon.isCustom}
                  />
                ) : null}
                {link ? (
                  <a
                    style={styles.link}
                    href={link.href}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Text>{title}</Text>
                  </a>
                ) : (
                  <Button
                    variant="link"
                    w="fit-content"
                    onPress={() => {
                      handleShowPopOver(false);

                      if (action) action();
                    }}
                  >
                    <Text color={titleColor}>{title}</Text>
                  </Button>
                )}
              </HStack>
            ),
          )}
        </Popover.Body>
      </Popover.Content>
    </Popover>
  );
};
