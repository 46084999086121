import { Stack } from 'native-base';
import Loading from 'ui/components/Loading';
import { ActivityTypeEnum, MeasurementCategory } from 'ui/enums';

import { PAGES } from '~/constants/pages.constants';
import { LogHoursActivity } from '~/pages/Authenticated/LogActivity/components/LogHoursActivity';
import { LogAction } from '~/pages/Authenticated/LogActivity/LogAction';

import { useLogActivityController } from './useLogActivityController';

export const LogActivity = () => {
  const {
    activityApplication,
    activityType,
    goToRoute,
    isLoading,
    measurementUnit,
    measurements,
    app,
    refetchMeasurements,
  } = useLogActivityController();

  if (isLoading) {
    return (
      <Stack
        width="100%"
        height="100vh"
        justifyContent="center"
        alignItems="center"
        bgColor="gray.100"
      >
        <Loading />
      </Stack>
    );
  }

  if (!activityApplication || !activityType || !measurementUnit || !app) {
    goToRoute(PAGES.Page404);
    return <></>;
  }

  if (
    activityType === ActivityTypeEnum.Action &&
    measurementUnit.category !== MeasurementCategory.Time
  ) {
    return (
      <LogAction
        activityApplication={activityApplication}
        measurements={measurements}
        measurementUnit={measurementUnit}
        app={app}
        refetchMeasurements={refetchMeasurements}
      />
    );
  }

  return (
    <LogHoursActivity
      measurements={measurements}
      activity={activityApplication.activitySubDocument}
      activityApplication={activityApplication}
      activityType={activityType as ActivityTypeEnum}
      app={app}
      measurementUnit={measurementUnit}
      refetchMeasurements={refetchMeasurements}
    />
  );
};
