import { useBreakpointValue } from 'native-base';
import { useEffect, useMemo, useState } from 'react';
import { AppSwitcherOption } from 'ui/components/v2/AppInputs/AppSwitcher/types';

import { calculateFilterAppliedCount } from '~/components/Layouts/v2/SearchLayout/utils/calculate-filters-applied-count';
import { PAGES } from '~/constants/pages.constants';
import { useVolunteerEcosystemAvailableApps } from '~/hooks/useApps/useVolunteerEcosystemAvailableApps';
import { useRouter } from '~/hooks/useRouter';
import { mapAppToSwitcherOption } from '~/pages/Authenticated/v2/InApp/components/Header/utils';
import { generateUrlWithQueryParams } from '~/utils/transformURL';
import { useInApp } from '~/zustand/store/InApp';
import {
  DEFAULT_SEARCH_FILTERS,
  SearchFilters,
  useSearch,
} from '~/zustand/store/Search';

export const useInAppHeaderController = () => {
  // Providers
  const { apps } = useVolunteerEcosystemAvailableApps();
  const { goToRoute } = useRouter();

  // Store
  const { app } = useInApp((state) => state);

  const openFilterModal = useSearch((state) => state.openFilterModal);
  const applyFilters = useSearch((state) => state.applyFilters);

  // States
  const [searchTextInput, setSearchTextInput] = useState<string>('');

  const [selectedAppOption, setSelectedAppOption] =
    useState<AppSwitcherOption | null>(null);

  const [provisionalSearchFilters, setProvisionalSearchFilters] =
    useState<Partial<SearchFilters> | null>(null);

  // Hooks
  const appSwitcherOptions = useMemo(() => {
    if (!apps.length) return [];
    return apps
      .map((_app) => mapAppToSwitcherOption(_app))
      .filter((_app) => !!_app);
  }, [apps]);

  useEffect(() => {
    let option: AppSwitcherOption | null = null;
    if (!!app?._id) {
      option = mapAppToSwitcherOption(app);
    }
    setSelectedAppOption(option);
  }, [app]);

  const inputLabel = useBreakpointValue({ base: 'Search', md: '' });

  // Handlers
  const handleSearchSubmit = (searchValue: string) => {
    const combinedFilters = {
      ...(provisionalSearchFilters ?? {}),
      searchText: searchValue,
    };

    applyFilters(combinedFilters);
    const appId = app?._id || '';
    goToRoute(
      generateUrlWithQueryParams(PAGES.InAppSearch.replace(':appId', appId), {
        search_text: searchValue,
      }),
    );
  };

  const handleAppChange = (option: AppSwitcherOption | null) => {
    if (!option || option.value === selectedAppOption?.value) return;

    goToRoute(PAGES.InApp.replace(':appId', option?.value));
  };

  const handleGoToAllApps = () => {
    goToRoute(PAGES.AllApps);
  };

  const handleFilterButtonPress = () => {
    openFilterModal();
  };

  const handleFilterModalSubmit = (filters: Partial<SearchFilters>) => {
    setProvisionalSearchFilters(filters);
  };

  const handleSearchAutoCompleteChange = (value?: string) => {
    setSearchTextInput(value || '');
  };

  // Computed
  const defaultTextValue = ''; //appliedFilters.searchText;

  const isSearchAutoCompleteDisabled =
    !searchTextInput && !provisionalSearchFilters;

  const provisionalSearchFiltersCount = useMemo(() => {
    if (!provisionalSearchFilters) return 0;

    return calculateFilterAppliedCount(
      provisionalSearchFilters,
      DEFAULT_SEARCH_FILTERS,
    );
  }, [provisionalSearchFilters]);

  return {
    app,
    inputLabel,
    defaultTextValue,
    selectedAppOption,
    appSwitcherOptions,
    provisionalSearchFilters,
    isSearchAutoCompleteDisabled,
    provisionalSearchFiltersCount,
    handleSearchAutoCompleteChange,
    handleAppChange,
    handleGoToAllApps,
    handleSearchSubmit,
    handleFilterButtonPress,
    handleFilterModalSubmit,
  };
};
