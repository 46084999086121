import { Stack } from 'native-base';
import Modal from 'ui/components/Modals/Modal';

import { IModalPrivacyPolicyProps } from './types';

const ModalPrivacyPolicy = ({ modalRef }: IModalPrivacyPolicyProps) => (
  <Modal
    headerText="Privacy policy"
    ref={modalRef}
    modalContentW={{ base: '100vw', lg: '80vw' }}
    modalContentMaxW={{ base: '100vw', lg: '80vw' }}
    modalContentH={{ base: '100vh', lg: '90vh' }}
    modalContentMaxH={{ base: '100vh', lg: '90vh' }}
    // Fix responsive modal on mobile scroll view.
    _overlay={{ style: { position: 'fixed' } as any }}
  >
    <Stack w="full" py="2" alignSelf="center">
      <div
        style={{
          flexDirection: 'column',
          boxSizing: 'border-box',
          display: 'flex',
          whiteSpace: 'normal',
          overflowX: 'scroll',
        }}
      >
        <h3>
          <u>INTRODUCTION</u>
        </h3>
        <p>
          {' '}
          Do IT respects your privacy and is committed to protecting your
          personal data. This privacy policy will inform you as to how we look
          after your personal data when you access the Do IT Platform (the{' '}
          <b>Platform</b>) and tell you about your privacy rights and how the
          law protects you.{' '}
        </p>
        <p>
          {' '}
          Please also use the Glossary to understand the meaning of some of the
          terms used in this privacy policy.{' '}
        </p>
        <p>
          {' '}
          [All web pages on www.doit.life are encrypted via a secure and modern
          cypher suite, meaning data exchanged between your browser and our
          Platform is safe from prying eyes.]{' '}
        </p>
        <hr />
        <h2>
          1. <u>Important information and who we are</u>
        </h2>
        <h3>Purpose of this privacy policy</h3>
        <p>
          {' '}
          This privacy policy aims to give you information on how Do IT collects
          and processes your personal data through your use of the Do IT
          Platform (and all related web sites, applications and online services
          and tools where this policy is referenced, regardless of how you
          access or use them, including mobile devices) and including any data
          you may provide through the Do IT Platform.{' '}
        </p>
        <p>
          {' '}
          It is important that you read this privacy policy together with any
          other privacy policy or fair processing policy we may provide on
          specific occasions when we are collecting, or processing, personal
          data about you, so that you are fully aware of how and why we are
          using your data. This privacy policy supplements other notices and
          privacy policies and is not intended to override them.{' '}
        </p>
        <h3>Controller</h3>
        <p>
          {' '}
          Do IT is the controller in respect to certain uses of your personal
          data (collectively referred to as Do IT, “we”, “us” or “our” in this
          privacy policy).{' '}
        </p>
        <p>
          {' '}
          We have appointed a data privacy manager who is responsible for
          overseeing questions in relation to this privacy policy.{' '}
        </p>
        <p>
          {' '}
          If you have any questions about this privacy policy, including any
          requests to exercise your legal rights, please contact the data
          privacy manager using the details set out below.{' '}
        </p>
        <h3>Contact details</h3>
        <p>
          {' '}
          If you have any questions about this privacy policy or our privacy
          practices, please contact our data privacy manager in the following
          ways:{' '}
        </p>
        <table>
          <tr>
            <td>
              <b>Full name of legal entity:</b>
            </td>
            <td> Do IT Life Services Ltd </td>
          </tr>
          <tr>
            <td>
              <b>Email address:</b>
            </td>
            <td>
              <a href="mailto:data@doit.life">data@doit.life</a>
            </td>
          </tr>
        </table>
        <br />
        <p>
          {' '}
          We are registered with the Information Commissioner&apos;s Office
          (ICO) as a controller of personal data under registration number:
          ZB043523{' '}
        </p>
        <p>
          {' '}
          You have the right to make a complaint at any time to the Information
          Commissioner&apos;s Office (ICO), the UK supervisory authority for
          data protection issues{' '}
          <i>
            (<a href="http://www.ico.org.uk">www.ico.org.uk</a>)
          </i>
          . We would, however, appreciate the chance to deal with your concerns
          before you approach the ICO so please contact us in the first
          instance.{' '}
        </p>
        <h3>
          Changes to our privacy policy and your duty to inform us of changes to
          your personal data
        </h3>
        <p>
          {' '}
          We keep our privacy policy under regular review. <br /> It is
          important that the personal data we hold about you is accurate and
          current. Please keep us informed if your personal data changes during
          your relationship with us.{' '}
        </p>
        <h3>
          Your access to and use of third party websites or services accessible
          through the Do IT Platform
        </h3>
        <p>
          {' '}
          The Do IT Platform includes links to third-party websites and
          applications as well as to partner providers (Partners) of multiple
          different types of offline services associated with a particular
          Ecosystem.{' '}
        </p>
        <p>
          <b>Third party web sites and applications.</b> Clicking on links to
          third party web sites, content or applications may allow those third
          parties to collect or share data about you. We do not control those
          third-party websites or content and are not responsible for their
          privacy statements or the way in which they collect or use your data.{' '}
        </p>
        <p>
          <b>Offline Services available through an Ecosystem.</b> Where you
          access or use third party offline services provided by a Partner
          associated with a particular Ecosystem, we encourage you to request
          details of and read the privacy policy of that service provider. We do
          not control those third-party service providers and are not
          responsible for their privacy statements or the way in which they
          collect or use your data.{' '}
        </p>
        <p>
          <b>How we share your personal data</b> We may share your personal data
          with third parties in the following circumstances:{' '}
          <b>With your consent.</b>
          With your explicit consent, we may share your personal data, including
          your Identity, Contact, and other Data, with third-party charities for
          the purpose of allowing them to contact you directly about
          volunteering opportunities or other marketing materials that may be of
          interest to you. Please note that you have the right to withdraw your
          consent to the sharing of your personal data with third-party
          charities at any time by contacting us. However, this may impact our
          ability to provide our services to you or limit the volunteering
          opportunities available to you.
        </p>
        <p>
          <u>
            We will never share your data with any third-party service provider
            whether online or offline without your express permission.
          </u>
        </p>
        <p>
          <b>
            <u>Please Note:</u>
          </b>{' '}
          The Do IT Platform is made available to you as a service to facilitate
          your activities within a particular Ecosystem, we do not engage in any
          correspondence, or the relationship, between you as a user of the
          Platform and any Partner services you make use of which are made
          available to you as part of your Ecosystem or the Platform generally.
          In particular we do not participate in any part of any service or any
          contract for services which may be provided to you by an Ecosystem
          Partner or otherwise available to you as a user. You accept all
          responsibility in respect to ensuring that any specific services which
          you make use of are suitable for you.{' '}
        </p>
        <br />
        <h2>
          2. <u>The data we collect about you</u>
        </h2>
        <p>
          {' '}
          Personal data, or personal information, means any information about an
          individual from which that person can be identified. It does not
          include data where the identity has been removed (anonymous data).{' '}
        </p>
        <p>
          {' '}
          We may collect, use, store and transfer different kinds of personal
          data about you which we have grouped together as follows:{' '}
        </p>
        <ul>
          <li>
            <b>Identity Data</b> which includes first name, maiden name, last
            name, username or similar identifier, marital status, title, date of
            birth and gender.
          </li>
          <li>
            <b>Contact Data</b> which includes your home address, email address
            and telephone numbers.
          </li>
          <li>
            <b>Transaction Data</b> which includes details about payments you
            have made and other details of products and services you have
            accessed or used through the Platform. We do not store card details
            on our server.
          </li>
          <li>
            <b>Technical Data</b> which includes your internet protocol (IP)
            address, your login data, browser type and version, hardware
            information, time zone setting and location, browser plug-in types
            and versions, operating system and website, and other technology on
            the devices you use to access the Platform.
          </li>
          <li>
            <b>Profile Data</b> which includes your username and password,
            purchases or orders made by you, your interests, volunteer record,
            preferences, feedback and survey responses.
          </li>
          <li>
            <b>Usage Data</b> which includes information about how you use our
            Platform, products and services.
          </li>
          <li>
            <b>Marketing and Communications Data</b> which includes your
            preferences in receiving marketing from us and our third party
            Platform Partners and your communication preferences.
          </li>
        </ul>
        <br />
        <p>
          {' '}
          We also collect, use and share <b>Aggregated Data</b> such as
          statistical or demographic data for any purpose. Aggregated Data could
          be derived from your personal data but is not considered personal data
          in law as this data will not directly or indirectly reveal your
          identity.{' '}
        </p>
        <h3>If you fail to provide personal data</h3>
        <p>
          {' '}
          Where we need to collect personal data by law, or under the terms of
          an agreement we have with you, and you fail to provide that data when
          requested, we may not be able to perform the agreement we have or are
          trying to enter into with you (for example, to provide you with access
          to the Platform and particular services). In this case, you may not be
          able to access to a service which you have requested, but we will
          notify you if this is the case at the time.{' '}
        </p>
        <br />
        <h2>
          3. <u>How is your personal data collected?</u>
        </h2>
        <p>
          {' '}
          We use different methods to collect data from and about you including
          through:{' '}
        </p>
        <ul>
          <li>
            <b>Direct interactions.</b> You may give us your Identity, Contact
            and other Data by filling in forms or by corresponding with us by
            email or otherwise on the Platform. This includes personal data you
            provide when you: (a) create an account with us; (b) subscribe (or
            are subscribed) to our service; (c) request marketing to be sent to
            you; (d) enter a promotion or survey; or (e) give us feedback or
            contact us.{' '}
          </li>
          <li>
            <b>Third parties.</b> We may receive your data through our
            Affiliates in relation to the provision of services to you. We may
            also receive information from third parties that we use to verify
            your identity.{' '}
          </li>
          <li>
            <b>Automated technologies or interactions.</b> As you interact with
            our Platform, we will automatically collect Technical Data about
            your equipment, browsing actions and patterns. We collect this
            personal data by using cookies, server logs and other similar
            technologies.{' '}
          </li>
        </ul>
        <br />
        <h2>
          4. <u>How we use your personal data</u>
        </h2>
        <p>
          {' '}
          We will only use your personal data when the law allows us to. Most
          commonly, we will use your personal data in the following
          circumstances:{' '}
        </p>
        <ul>
          <li>
            {' '}
            Where we need to perform the contract we are about to enter into or
            have entered into with you.{' '}
          </li>
          <li>
            {' '}
            Where it is necessary for our legitimate interests (or those of a
            third party) and your interests and fundamental rights do not
            override those interests.{' '}
          </li>
          <li> Where we need to comply with a legal obligation. </li>
        </ul>
        <br />
        <p>
          {' '}
          Generally, we do not rely on consent as a legal basis for processing
          your personal data on the Do IT Platform although we will get your
          consent before sending any personal data to any third party Platform
          service provider or any third party direct marketing communications
          via e-mail or text message.{' '}
        </p>
        <h3>Purposes for which we will use your personal data</h3>
        <p>
          {' '}
          We have set out below, in a table format, a description of all the
          ways we plan to use your personal data, and which of the legal bases
          we rely on to do so. We have also identified what our legitimate
          interests are where appropriate.{' '}
        </p>
        <p>
          {' '}
          Note that we may process your personal data for more than one lawful
          ground depending on the specific purpose for which we are using your
          data. Please contact us if you need details about the specific legal
          ground we are relying on to process your personal data where more than
          one ground has been set out in the table below.{' '}
        </p>
        <table border={1}>
          <tr>
            <th>Purpose/Activity</th>
            <th>Type of data</th>
            <th>
              Lawful basis for processing including basis of legitimate interest
            </th>
          </tr>
          <tr>
            <td> To register you as a user of the Platform </td>
            <td>
              <ol>
                <li>Identity</li>
                <li>Contact</li>
              </ol>
            </td>
            <td> Performance of a contract with you </td>
          </tr>
          <tr>
            <td>
              {' '}
              To manage our relationship with you which will include:{' '}
              <ol>
                <li>Providing access to Platform services</li>
                <li>
                  Notifying you about changes to our terms or privacy policy
                </li>
                <li>Asking you to leave a review or take a survey</li>
                <li>Investigating complaints</li>
                <li>
                  Transferring certain elements of your Personal Data to a third
                  party service provider (where not a consent driven activity).
                </li>
              </ol>
            </td>
            <td>
              <ol>
                <li>Identity</li>
                <li>Contact</li>
                <li>Profile</li>
                <li>Marketing and Communications</li>
                <li>Transaction Data</li>
              </ol>
            </td>
            <td>
              <ol>
                <li>Performance of a contract with you</li>
                <li>Necessary to comply with a legal obligation</li>
                <li>
                  Necessary for our legitimate interests (to keep our records
                  updated and to study how users use the Platform and the
                  products/services)
                </li>
              </ol>
            </td>
          </tr>
          <tr>
            <td>
              {' '}
              To administer and protect our business and our services (including
              troubleshooting, data analysis, testing, system maintenance,
              support, reporting and hosting of data){' '}
            </td>
            <td>
              <ol>
                <li>Identity</li>
                <li>Contact</li>
                <li>Technical</li>
                <li>Transaction Data</li>
              </ol>
            </td>
            <td>
              <ol>
                <li>
                  Necessary for our legitimate interests (for running our
                  business, administering our CRM, provision of administration
                  and IT services, network security, to prevent fraud and in the
                  context of a business reorganisation or group restructuring
                  exercise)
                </li>
                <li>Necessary to comply with a legal obligation</li>
              </ol>
            </td>
          </tr>
          <tr>
            <td>
              {' '}
              To enable you to partake in a competition or complete a survey{' '}
            </td>
            <td>
              <ol>
                <li>Identity</li>
                <li>Contact</li>
                <li>Profile</li>
                <li>Usage</li>
                <li>Marketing and Communications</li>
              </ol>
            </td>
            <td>
              <ol>
                <li>Performance of a contract with you</li>
                <li>
                  Necessary for our legitimate interests (to study how users use
                  the Platform and to develop and grow our business)
                </li>
              </ol>
            </td>
          </tr>
          <tr>
            <td>
              {' '}
              To deliver relevant Platform content and advertisements to you and
              measure or understand the effectiveness of the advertising we
              serve to you{' '}
            </td>
            <td>
              <ol>
                <li>Identity</li>
                <li>Contact</li>
                <li>Profile</li>
                <li>Usage</li>
                <li>Marketing and Communications</li>
                <li>Technical</li>
                <li>Transaction Data</li>
              </ol>
            </td>
            <td>
              {' '}
              Necessary for our legitimate interests (to study how users use the
              Platform and to grow our business and inform our marketing and
              growth strategy){' '}
            </td>
          </tr>
          <tr>
            <td>
              {' '}
              To use data analytics to improve our Platform, products/services,
              marketing, user and Partner relationships and experiences{' '}
            </td>
            <td>
              <ol>
                <li>Technical</li>
                <li>Usage</li>
              </ol>
            </td>
            <td>
              {' '}
              Necessary for our legitimate interests (to define types of users
              for certain Platform services and to keep our services and web
              site updated and relevant, to develop our business and to inform
              our marketing strategy){' '}
            </td>
          </tr>
          <tr>
            <td>
              {' '}
              To make suggestions and recommendations to you about services
              available through the Platform that may be of interest to you{' '}
            </td>
            <td>
              <ol>
                <li>Identity</li>
                <li>Contact</li>
                <li>Technical</li>
                <li>Usage</li>
                <li>Profile</li>
                <li>Marketing and Communications</li>
                <li>Transaction Data</li>
              </ol>
            </td>
            <td>
              {' '}
              Necessary for our legitimate interests (to develop the products
              and services available through the Platform){' '}
            </td>
          </tr>
        </table>

        <h3>Marketing</h3>
        <p>
          {' '}
          We strive to provide you with choices regarding certain personal data
          uses, particularly around marketing and advertising.{' '}
        </p>
        <h3>Recommendations from us</h3>
        <p>
          {' '}
          We may use your Identity, Contact, Technical, Usage and Profile Data
          to form a view on what we think you may want or need, or what may be
          of interest to you. This is how we decide which services and offers
          may be relevant for you.{' '}
        </p>
        <p>
          {' '}
          You will receive marketing communications from us if you have
          requested information from us previously used services or purchased
          products or services through the Platform and you have not opted out
          of receiving that marketing.{' '}
        </p>
        <h3>Third-party marketing</h3>
        <p>
          {' '}
          We will get your express opt-in consent before we share your personal
          data with any third party for marketing purposes.{' '}
        </p>
        <h3>Opting out</h3>
        <p>
          {' '}
          You can ask us or third parties to stop sending you marketing messages
          at any time.
          <br /> Where you opt out of receiving these marketing messages, this
          will not apply to personal data provided to us as a result of your use
          of the Platform or any associated services.{' '}
        </p>
        <h3>Cookies</h3>
        <p>
          {' '}
          You can set your browser to refuse all or some browser cookies, or to
          alert you when websites set or access cookies. If you disable or
          refuse cookies, please note that some parts of our Platform may become
          inaccessible or not function properly.{' '}
        </p>
        <h3>Change of purpose</h3>
        <p>
          {' '}
          We will only use your personal data for the purposes for which we
          collected it, unless we reasonably consider that we need to use it for
          another reason and that reason is compatible with the original
          purpose. If you wish to get an explanation as to how the processing
          for the new purpose is compatible with the original purpose, please
          contact us.
          <br /> If we need to use your personal data for an unrelated purpose,
          we will notify you and we will explain the legal basis which allows us
          to do so.{' '}
        </p>
        <p>
          {' '}
          Please note that we may process your personal data without your
          knowledge or consent, in compliance with the above rules, where this
          is required or permitted by law.{' '}
        </p>
        <h2>
          5. <u>Disclosures of your personal data</u>
        </h2>
        <p>
          {' '}
          We may share your personal data with the parties set out below for the
          purposes set out in the table “Purposes for which we will use your
          personal data” above.{' '}
        </p>
        <ul>
          <li> External Third Parties as set out in the Glossary. </li>
          <li>
            {' '}
            Third parties to whom we may choose to sell, transfer or merge parts
            of our business or our assets. Alternatively, we may seek to acquire
            other businesses or merge with them. If a change happens to our
            business, then the new owners may use your personal data in the same
            way as set out in this privacy policy.{' '}
          </li>
        </ul>
        <p>
          {' '}
          Where third parties support the provision of services made available
          to you through the Platform, these third parties may receive certain
          personal data from you. Except where you have previously given
          consent, you will be informed of any transfer of data prior to it
          taking place and the extent of the data being shared. In these cases,
          during the transfer, we will, at all times, control and be responsible
          for the use of your personal data. Whilst we are not in control of any
          third parties to whom you request your data to be shared, we require
          all third parties to respect the security of your personal data and to
          treat it in accordance with the law. We do not allow our third-party
          service providers to use your personal data for their own purposes and
          only permit them to process your personal data for specified purposes
          and in accordance with your instructions.{' '}
        </p>
        <p>
          {' '}
          To help constantly improve and tailor the service we provide to you
          through our Platform, we may use aggregated information so that we can
          administer and improve our services, analyse trends, gather broad
          demographic information and detect suspicious or fraudulent
          transactions and most importantly monitor and improve our operations
          on a day to day basis. In carrying out this activity, we may pass some
          information to third parties in aggregate and anonymised format.{' '}
        </p>
        <h3>Your disclosure to third parties</h3>
        <p>
          {' '}
          Except as otherwise expressly included in this Privacy Policy, this
          document addresses only the use and disclosure of information we
          collect. If you disclose your information to others, different rules
          may apply to their use or disclosure of the information you provide to
          them. Do IT does not control the privacy policies of third parties,
          and you are subject to the privacy policies of those third parties
          where applicable. We will prompt you before you share personal data
          from the Platform with external third parties and encourage you to ask
          questions before you disclose that personal information to others.{' '}
        </p>
        <h2>
          6. <u>International transfers</u>
        </h2>
        <p>
          {' '}
          We may transfer your personal data outside the European Economic Area
          (EEA) in our performance of services.{' '}
        </p>
        <p>
          {' '}
          Whenever we transfer your personal data out of the EEA, we ensure an
          appropriate degree of protection is afforded to it by ensuring at
          least one of the following safeguards is implemented:{' '}
        </p>
        <ul>
          <li>
            {' '}
            We will only transfer your personal data to countries that have been
            deemed to provide an adequate level of protection for personal data
            by the European Commission.{' '}
          </li>
          <li>
            {' '}
            Where we use certain service providers, we may use specific
            contracts approved by the European Commission which give personal
            data the same protection it has in Europe.{' '}
          </li>
          <li>
            {' '}
            Where we use providers based in the US, we may transfer data to them
            if they are part of the Privacy Shield which requires them to
            provide similar protection to personal data shared between Europe
            and the US.{' '}
          </li>
        </ul>
        <p>
          {' '}
          Please contact us if you want further information on the specific
          mechanism used by us when transferring your personal data out of the
          EEA.{' '}
        </p>
        <h2>
          7. <u>Data security</u>
        </h2>
        <p>
          {' '}
          We have put in place appropriate security measures to prevent your
          personal data from being accidentally lost, used or accessed in an
          unauthorised way, altered or disclosed. In addition, we limit access
          to your personal data to those employees, agents, contractors and
          other third parties who have a business need to know. They will only
          process your personal data on our instructions and they are subject to
          a duty of confidentiality.
          <br /> We have put in place procedures to deal with any suspected
          personal data breach and will notify you and any applicable regulator
          of a breach where we are legally required to do so.{' '}
        </p>
        <h2>
          8. <u>Data retention</u>
        </h2>
        <h3>How long will you use my personal data for?</h3>
        <p>
          {' '}
          We will only retain your personal data for as long as reasonably
          necessary to fulfil the purposes we collected it for, including for
          the purposes of satisfying any legal, regulatory, tax, accounting or
          reporting requirements. We may retain your personal data for a longer
          period in the event of a complaint or if we reasonably believe there
          is a prospect of litigation in respect to our relationship with you.{' '}
        </p>
        <p>
          {' '}
          To determine the appropriate retention period for personal data, we
          consider the amount, nature and sensitivity of the personal data, the
          potential risk of harm from unauthorised use or disclosure of your
          personal data, the purposes for which we process your personal data
          and whether we can achieve those purposes through other means, and the
          applicable legal, regulatory, tax, accounting or other requirements.{' '}
        </p>
        <p>
          <b>
            With your prior consent, we may process sensitive personal data on
            the Platform.
          </b>{' '}
          This includes details about your race or ethnicity, religious or
          philosophical beliefs, sex life, sexual orientation, political
          opinions, trade union membership, information about your health, and
          genetic and biometric data). No sharing of sensitive personal data
          will be used made without your express prior written consent.{' '}
        </p>
        <p>
          {' '}
          In some circumstances you can ask us to delete your data: see your
          legal rights below for further information{' '}
        </p>
        <p>
          {' '}
          In some circumstances we will anonymise your personal data (so that it
          can no longer be associated with you) for research or statistical
          purposes, in which case we may use this information indefinitely
          without further notice to you.{' '}
        </p>
        <h2>
          9. <u>Your legal rights</u>
        </h2>
        <p>
          {' '}
          Under certain circumstances, you have rights under data protection
          laws in relation to your personal data. You have the right to:{' '}
        </p>
        <ul>
          <li>
            <b>Request access</b> to your personal data (commonly known as a
            “data subject access request”). This enables you to receive a copy
            of the personal data we hold about you and to check that we are
            lawfully processing it.{' '}
          </li>
          <li>
            <b>Request correction</b> of the personal data that we hold about
            you. This enables you to have any incomplete or inaccurate data we
            hold about you corrected, though we may need to verify the accuracy
            of the new data you provide to us.{' '}
          </li>
          <li>
            <b>Request erasure</b> of your personal data. This enables you to
            ask us to delete or remove personal data where there is no good
            reason for us continuing to process it. You also have the right to
            ask us to delete or remove your personal data where you have
            successfully exercised your right to object to processing (see
            below), where we may have processed your information unlawfully or
            where we are required to erase your personal data to comply with
            local law. Note, however, that we may not always be able to comply
            with your request of erasure for specific legal reasons which will
            be notified to you, if applicable, at the time of your request.{' '}
          </li>
          <li>
            <b>Object to processing</b> of your personal data where we are
            relying on a legitimate interest (or those of a third party) and
            there is something about your particular situation which makes you
            want to object to processing on this ground as you feel it impacts
            on your fundamental rights and freedoms. You also have the right to
            object where we are processing your personal data for direct
            marketing purposes. In some cases, we may demonstrate that we have
            compelling legitimate grounds to process your information which
            override your rights and freedoms.{' '}
          </li>
          <li>
            <b>Request restriction of processing</b> of your personal data. This
            enables you to ask us to suspend the processing of your personal
            data in the following scenarios:{' '}
            <ul className="doit-list__li--sublist">
              <li> If you want us to establish the data&apos;s accuracy. </li>
              <li>
                {' '}
                Where our use of the data is unlawful but you do not want us to
                erase it.{' '}
              </li>
              <li>
                {' '}
                Where you need us to hold the data even if we no longer require
                it as you need it to establish, exercise or defend legal claims.{' '}
              </li>
              <li>
                {' '}
                You have objected to our use of your data but we need to verify
                whether we have overriding legitimate grounds to use it.{' '}
              </li>
            </ul>
          </li>
          <li>
            <b>Request the transfer</b> of your personal data to you or to a
            third party. We will provide to you, or a third party you have
            chosen, your personal data in a structured, commonly used,
            machine-readable format. Note that this right only applies to
            automated information which you initially provided consent for us to
            use or where we used the information to perform a contract with you.{' '}
          </li>
          <li>
            <b>Withdraw consent at any time</b> where we are relying on consent
            to process your personal data. However, this will not affect the
            lawfulness of any processing carried out before you withdraw your
            consent. If you withdraw your consent, we may not be able to provide
            certain products or services to you. We will advise you if this is
            the case at the time you withdraw your consent.{' '}
          </li>
        </ul>
        <p>
          {' '}
          If you wish to exercise any of the rights set out above, please
          contact us.{' '}
        </p>
        <h3>No fee usually required</h3>
        <p>
          {' '}
          You will not have to pay a fee to access your personal data (or to
          exercise any of the other rights). However, we may charge a reasonable
          fee if your request is clearly unfounded, repetitive or excessive.
          Alternatively, we could refuse to comply with your request in these
          circumstances.{' '}
        </p>
        <h3>What we may need from you</h3>
        <p>
          {' '}
          We may need to request specific information from you to help us
          confirm your identity and ensure your right to access your personal
          data (or to exercise any of your other rights). This is a security
          measure to ensure that personal data is not disclosed to any person
          who has no right to receive it. We may also contact you to ask you for
          further information in relation to your request to speed up our
          response.{' '}
        </p>
        <h3>Time limit to respond</h3>
        <p>
          {' '}
          We try to respond to all legitimate requests within one month.
          Occasionally it could take us longer than a month if your request is
          particularly complex or you have made a number of requests. In this
          case, we will notify you and keep you updated.{' '}
        </p>
        <h2>
          10. <u>Glossary</u>
        </h2>
        <p>
          <b>Affiliates</b> means companies in the same group of companies as Do
          It and close affiliates including Do It Trust (the Do It Foundation){' '}
          <b>Legitimate Interest</b> means the interest of our business in
          conducting and managing our business to enable us to give you the best
          service/product and the best and most secure experience. We make sure
          we consider and balance any potential impact on you (both positive and
          negative) and your rights before we process your personal data for our
          legitimate interests. We do not use your personal data for activities
          where our interests are overridden by the impact on you (unless we
          have your consent or are otherwise required or permitted to by law).
          You can obtain further information about how we assess our legitimate
          interests against any potential impact on you in respect of specific
          activities by contacting us.
          <br />
          <b>Performance of Contract</b> means processing your data where it is
          necessary for the performance of a contract to which you are a party
          or to take steps at your request before entering into such a contract.
          In respect to Do IT, that is the performance of our agreement with you
          to make the Platform and its services available, and to support your
          entering into any agreement with providers of services to the
          Platform. We are not involved in the processing of your Personal Data
          during any engagement you enter into with our third party partners. We
          will always get your consent to transfer any Personal Data to any
          third party whom you enter into an agreement with for the provision of
          their services to you.
          <br />
          <b>Comply with a legal obligation</b> means processing your personal
          data where it is necessary for compliance with a legal obligation that
          we are subject to.{' '}
        </p>
        <b>External Third Parties</b>
        <ul>
          <li>
            {' '}
            Service providers acting as processors who provide our IT and system
            administration services.{' '}
          </li>
          <li>
            {' '}
            Payment service providers who provide payment services to us and our
            Affiliates and may also provide such services to you and persons you
            nominate for such services{' '}
          </li>
          <li> Providers of our cloud services such as AWS and Google. </li>
          <li>
            {' '}
            Professional advisers acting as processors or joint controllers
            including lawyers, bankers, auditors and insurers based in the
            United Kingdom who provide consultancy, banking, legal, insurance
            and accounting services.{' '}
          </li>
          <li>
            {' '}
            Regulators and other authorities acting as processors or joint
            controllers based in the United Kingdom who require reporting of
            processing activities in certain circumstances.{' '}
          </li>
          <li>
            {' '}
            Our third-party partners and affiliates who you engage with through
            the use of the Platform in order to facilitate the provision of
            services.{' '}
          </li>
        </ul>
        <br />
        <b>External Third Parties (with consent)</b>
        <ul>
          <li>
            {' '}
            Platform service providers where sensitive (special category) data
            is processed.{' '}
          </li>
        </ul>
      </div>
    </Stack>
  </Modal>
);

export default ModalPrivacyPolicy;
