import { useCallback, useState } from 'react';

const INITIAL_DIMENSIONS = { width: 0, height: 0 };
export const useWatchElementSize = () => {
  const [dimensions, setDimensions] = useState<{
    width: number;
    height: number;
  }>(INITIAL_DIMENSIONS);

  const ref = useCallback((node: HTMLElement) => {
    if (node !== null) {
      const handleResize = () => {
        const { width, height } = node.getBoundingClientRect();
        if (width >= 0 && height >= 0) {
          setDimensions({ width, height });
        }
      };
      handleResize();
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
  }, []);

  return { ref, dimensions };
};

export default useWatchElementSize;
