import { CauseOptions } from 'ui/types/interfaces/activity-category';
import { Address } from 'ui/types/interfaces/address';

export enum OrganizationType {
  NFP = 'NFP',
  COMPANY = 'COMPANY',
}

export interface Organization {
  _id: string;
  name: string;
  description: string;
  purpose: string;
  type: OrganizationType;
  websiteLink: string;
  termsOfServicesLink: string;
  logo: string;
  logoThumbnail?: string;
  fullAddress: Address;
  belongsTo: string;
  admins: string[];
  causeOptions: CauseOptions[];
  createdAt: string;
  updatedAt: string;
  contactPhoneNumber: string;
  contactEmail: string;
  organizationSettings?: OrganizationSettings;
  domainSlug?: string;
  webhookEndpoint?: string;
}

export interface OrganizationSettings {
  hasExternalId: boolean;
  measurementGoal: number;
  measurementGoalUnit: string;
  departments: OrganizationDepartment[];
}

export interface OrganizationDepartment {
  _id: string;
  name: string;
  slug: string;
}

export type OrganizationSummary = {
  name: string;
  type: string;
  logo: string;
  logoThumbnail: string;
  domainSlug: string;
  organizationSettings?: OrganizationSettings;
};
