import { useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { useSearchRoutes } from '~/components/Layouts/v2/SearchLayout/hooks/use-search-routes';
import { useCollectionController } from '~/pages/Authenticated/v2/Search/controllers/collection-controller';
import { SearchFilters, useSearch } from '~/zustand/store/Search';

export const useSearchController = () => {
  // Providers
  const { collection } = useParams();
  const [searchParams] = useSearchParams();
  const { goToSearchPage } = useSearchRoutes();
  const { appliedFilters, isCollectionChanging, filtersAppliedAt } = useSearch(
    (state) => state,
  );
  const applyFilters = useSearch((state) => state.applyFilters);
  const resetFilters = useSearch((state) => state.resetFilters);

  // Hooks
  const { collectionSlug } = useCollectionController();

  useEffect(() => {
    const searchText = searchParams.get('search_text') || '';
    applyFilters({ searchText });
  }, [searchParams, applyFilters]);

  const handleFiltersModalSubmit = (newFilters: Partial<SearchFilters>) => {
    window.scrollTo(0, 0);
    applyFilters(newFilters);
    // To exit from collection after filters change
    setTimeout(() => {
      goToSearchPage({ search_text: appliedFilters.searchText || '' });
    }, 100);
  };

  useEffect(() => {
    /* Should reset filters on Unmount */
    return () => {
      resetFilters();
    };
  }, []);

  return {
    handleFiltersModalSubmit,
    isCollectionChanging,
    appliedFilters,
    filtersAppliedAt,
    collectionSlug,
    collection,
  };
};
