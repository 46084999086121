import { useCallback, useEffect } from 'react';

import { ScaffoldControllerParams } from '~/components/Scaffold/scaffold.types';

export const useScaffoldController = ({
  onScrollReachEnd,
}: ScaffoldControllerParams) => {
  const handleScroll = useCallback(() => {
    if (
      window.innerHeight + Math.round(window.scrollY) >=
      document.body.offsetHeight
    ) {
      onScrollReachEnd && onScrollReachEnd();
    }
  }, [onScrollReachEnd]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);
};
