import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IAppPermissionGroups,
  IPermission,
} from 'ui/types/interfaces/permission';

import { PermissionsState } from '~/store/slices/permissions/types';
import { ESliceNames } from '~/store/types';

const initialState: PermissionsState = {
  userPermissionsGroupsByAppId: {},
  allUserPermissionsFromAvailableApps: [],
};

export const permissionsSlice = createSlice({
  name: ESliceNames.PERMISSIONS,
  initialState,
  reducers: {
    setUserPermissionsGroupsByAppId: (
      state,
      { payload }: PayloadAction<Record<string, IAppPermissionGroups[]>>,
    ) => {
      return { ...state, userPermissionsGroupsByAppId: payload };
    },

    setAvailableActivityPermissionsFromApps: (
      state,
      { payload }: PayloadAction<Partial<IPermission>[]>,
    ) => {
      return { ...state, allUserPermissionsFromAvailableApps: payload };
    },
    resetPermissionsState: () => {
      return initialState;
    },
  },
});

export const {
  reducer: permissionsSliceReducer,
  actions: permissionsSliceActions,
} = permissionsSlice;
