import { Box, HStack, VStack } from 'native-base';
import React, { ReactNode } from 'react';

export const UnorderedList = ({
  children,
  decorator = <span dangerouslySetInnerHTML={{ __html: '&#8226;' }} />,
}: {
  decorator?: ReactNode;
  children: ReactNode | ReactNode[];
}) => {
  const childrenArray = React.Children.toArray(children);

  return (
    <VStack space={3} alignItems="flex-start">
      {childrenArray.map((child, index) => (
        <HStack key={index} px={2} space={2} alignItems="center">
          <Box>{decorator}</Box>
          <Box flex={1}>{child}</Box>
        </HStack>
      ))}
    </VStack>
  );
};

export default UnorderedList;
