import { Avatar, Box, Button, HStack, Menu, Text, VStack } from 'native-base';
import Icon from 'ui/components/Icon';
import { colors } from 'ui/theme/colors';
import { getAvatarText } from 'ui/utils/getAvatarText';

import { AppSwitcherOption, AppSwitcherPopoverProps } from './types';

export const AppSwitcherPopOver = (props: AppSwitcherPopoverProps) => {
  const { onOptionPress, onAllAppsPress, options, selected } = props;

  const handleOptionPress = (option: AppSwitcherOption | null) => {
    onOptionPress?.(option);
  };

  return (
    <VStack
      borderRadius={10}
      borderWidth={{ base: 0, md: 1 }}
      borderColor={'gray.200'}
      py={3}
      px={2}
    >
      <VStack minW={'304px'} space={2}>
        <Text px={2} fontSize={'xs'} fontWeight={500} color={colors.gray[500]}>
          Your APPs
        </Text>

        {options.map((option: AppSwitcherOption) => (
          <Menu.Item
            key={option.label}
            onPress={() => handleOptionPress(option)}
            p={0}
            m={0}
            borderRadius={4}
          >
            <HStack
              py={3}
              justifyContent={'space-between'}
              alignItems={'center'}
              w="full"
              space={2}
            >
              <HStack space={3} alignItems={'center'}>
                <Box
                  w="8"
                  h="8"
                  borderRadius={8}
                  borderColor={'gray.300'}
                  backgroundColor={'white'}
                  overflow={'hidden'}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  <Avatar
                    h="full"
                    w="full"
                    bgColor="muted.100"
                    _text={{ color: 'darkText' }}
                    source={{ uri: option?.logo || '' }}
                    borderRadius={0}
                    _image={{ borderRadius: 0 }}
                  >
                    {getAvatarText(option.label)}
                  </Avatar>
                </Box>

                <Text
                  numberOfLines={1}
                  fontSize={'md'}
                  lineHeight={'150%'}
                  color={colors.gray[600]}
                >
                  {option.label}
                </Text>
              </HStack>
              <Box>
                <Box borderRadius={100} w="18px" h="18px" borderWidth={2}>
                  <Box
                    borderRadius={100}
                    h={2}
                    w={2}
                    margin={'auto'}
                    background={
                      selected?.value === option.value
                        ? colors.singletons.black
                        : 'transparent'
                    }
                  ></Box>
                </Box>
              </Box>
            </HStack>
          </Menu.Item>
        ))}

        <HStack alignItems={'center'} justifyContent={'center'} p={2} py={0}>
          <Button
            p={0}
            py={2}
            h="fit-content"
            variant={'ghost'}
            onPress={onAllAppsPress}
          >
            <HStack
              alignItems={'center'}
              justifyContent={'center'}
              space="10px"
            >
              <Text
                fontWeight={500}
                fontSize={'xs'}
                color={colors.singletons.black}
              >
                Go to my apps
              </Text>
              <Icon
                icon="chevron-right"
                size={'16px'}
                color={colors.singletons.black}
              />
            </HStack>
          </Button>
        </HStack>
      </VStack>
    </VStack>
  );
};
