import { Flex, HStack, Stack } from 'native-base';
import { FiKey } from 'react-icons/fi';
import Button from 'ui/components/Button';
import { ILLUSTRATIONS_NAME } from 'ui/components/Illustration';
import Input from 'ui/components/Input';

import FlowTwoColumnsTemplate from '~/components/Templates/FlowTwoColumnsTemplate';
import { colors } from '~/theme/colors';

import { styles } from './styles';
import { useAccessCodeController } from './useAccessCodeController';

const EnterAccessCode = () => {
  const {
    control,
    errors,
    userEmail,
    onSubmit,
    handleGoBackToSignIn,
    handleResendCode,
    isLoading,
  } = useAccessCodeController();
  return (
    <Flex flex={1} flexShrink={0} bgColor="gray.100">
      <FlowTwoColumnsTemplate
        ilustrationName={ILLUSTRATIONS_NAME.MAILER}
        title="Enter the code we sent you"
        subtitle={`We sent a verification code to ${userEmail}`}
        icon={<FiKey size={32} />}
        progress={80}
        primaryButtonText="Submit"
        primaryButtonVariant="outline"
        primaryButtonOnPress={onSubmit}
        secondaryButtonOnPress={handleGoBackToSignIn}
        secondaryButtonText="Back"
        isLoading={isLoading}
      >
        <Stack
          flex={1}
          h="full"
          space="4"
          w="full"
          px={{ base: 4, md: 6 }}
          py={8}
          alignItems={'flex-start'}
          justifyContent={'flex-start'}
        >
          <Stack
            display="flex"
            flexDir={{ base: 'column', md: 'row' }}
            justifyContent={{ base: 'center', md: 'space-between' }}
            alignItems="flex-end"
            w="full"
            space={4}
          >
            <Input
              name="accessCode"
              control={control}
              placeholder="Enter the verification code"
              label="Verification code"
              isRequired
              leftIconName="key"
              isFullWidth
              errorMessage={errors.accessCode?.message}
              iconErrorMessage="info"
              leftIconColor={colors.muted[400]}
            />

            <Button
              bgColor="gray.200"
              _text={styles.resendCodeText}
              height="53px"
              onPress={handleResendCode}
              w={{ base: 'full', md: 'auto' }}
              ml={{ base: 0, md: 4 }}
            >
              Resend code
            </Button>
          </Stack>
        </Stack>
      </FlowTwoColumnsTemplate>
    </Flex>
  );
};

export default EnterAccessCode;
