import { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  _getActivityDetailsPathname,
  _getHomePathname,
  _getInAppHomePathname,
  _getSearchCollectionPathname,
  _getSearchRootPathname,
} from './utils';

export const useSearchRoutes = () => {
  const params = useParams();
  const navigate = useNavigate();

  // Hooks
  const homePathname = useMemo(() => _getHomePathname(), []);

  const inAppHomePathname = useMemo(
    () => _getInAppHomePathname(params?.appId || ''),
    [params],
  );

  const goToHomePage = () => {
    navigate({ pathname: _getHomePathname() });
  };

  const goToInApp = (appId: string, searchParams?: URLSearchParams) => {
    navigate({
      pathname: _getInAppHomePathname(appId),
      search: searchParams?.toString(),
    });
  };

  const goToSearchPage = (searchParams?: Record<string, string>) => {
    let urlSearchParams;
    if (searchParams) {
      urlSearchParams = new URLSearchParams(searchParams);
    }

    navigate({
      pathname: _getSearchRootPathname(params?.appId),
      search: urlSearchParams?.toString(),
    });
  };

  const goToSearchCollectionPage = (
    collectionSlug: string,
    searchParams?: Record<string, string>,
  ) => {
    let urlSearchParams;
    if (searchParams) {
      urlSearchParams = new URLSearchParams(searchParams);
    }

    const pathname = _getSearchCollectionPathname(
      collectionSlug,
      params?.appId,
    );

    navigate({ pathname, search: urlSearchParams?.toString() });
  };

  const goToActivityDetails = (
    activityDefinitionId: string,
    activityId?: string,
  ) => {
    let urlSearchParams;
    if (activityId) {
      urlSearchParams = new URLSearchParams({ selected: activityId });
    }
    const pathname = _getActivityDetailsPathname(
      activityDefinitionId,
      params?.appId,
    );
    navigate({ pathname, search: urlSearchParams?.toString() });
  };

  return {
    goToInApp,
    goToHomePage,
    goToSearchPage,
    goToActivityDetails,
    goToSearchCollectionPage,
    getSearchCollectionPathname: _getSearchCollectionPathname,
    inAppHomePathname,
    homePathname,
  };
};
