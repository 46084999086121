import { Box, Pressable, Text } from 'native-base';
import Icon from 'ui/components/Icon';
import { colors } from 'ui/theme/colors';

export type FlatFilterButtonProps = {
  iconColor: string;
  onPress?: VoidFunction;
  filtersCount?: number;
};
export const FlatFilterButton = (props: FlatFilterButtonProps) => {
  const { onPress, filtersCount, iconColor = colors.singletons.black } = props;

  return (
    <Pressable
      px={3}
      onPress={onPress}
      position={'relative'}
      variant={'ghost'}
      pointerEvents={!onPress ? 'none' : 'auto'}
      justifyContent={'Center'}
      alignItems={'center'}
    >
      <Icon icon={'sliders'} enableAutoCustom size={'18px'} color={iconColor} />

      {/* TODO: WAIT FOR DESIGN RESOLUTION */}
      {!!filtersCount ? (
        <Box
          w={'16px'}
          h={'16px'}
          borderRadius={100}
          position={'absolute'}
          top={'2px'}
          right={'2px'}
          backgroundColor={colors.singletons.black}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Text
            color={colors.white}
            lineHeight={'150%'}
            fontSize={'10px'}
            textAlign={'center'}
            fontWeight={500}
          >
            {filtersCount}
          </Text>
        </Box>
      ) : null}
    </Pressable>
  );
};
