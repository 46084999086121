import { Button, HStack, Text, VStack } from 'native-base';
import Icon from 'ui/components/Icon';
import { colors } from 'ui/theme/colors';

export type PrimaryCarouselEmptyStateProps = {
  label: string;
  actionLabel: string;
  onAction?: VoidFunction;
};
export const PrimaryCarouselEmptyState = ({
  actionLabel,
  label,
  onAction,
}: PrimaryCarouselEmptyStateProps) => {
  return (
    <VStack w="full" h="full" minH={'full'}>
      <VStack py={6} space={3} alignItems={'center'}>
        <Text
          maxW={'200px'}
          textAlign={'center'}
          fontWeight={400}
          fontSize={'sm'}
          color={'gray.500'}
        >
          {label}
        </Text>
        <Button variant={'ghost'} onPress={onAction}>
          <HStack space={'10px'}>
            <Text
              color={colors.singletons.black}
              fontSize={'md'}
              fontWeight={500}
            >
              {actionLabel}
            </Text>

            <Icon
              size={24}
              color={colors.singletons.black}
              icon="chevron-right"
            />
          </HStack>
        </Button>
      </VStack>
    </VStack>
  );
};
