import { Hidden, PresenceTransition } from 'native-base';
import { AlertBar } from 'ui/components/v2/Alerts/AlertBar';

import { AlertBarProps } from '../types';

export interface MixedAlertBar extends AlertBarProps {
  bottomOffset?: number | string;
  showCloseButton?: boolean;
}

export const MixedAlertBar = ({
  showCloseButton,
  backgroundColor,
  bottomOffset,
  actionLabel,
  children,
  logoSrc,
  onAction,
}: MixedAlertBar) => {
  return (
    <>
      <Hidden till="md">
        <PresenceTransition
          visible={true}
          initial={{ opacity: 0.25 }}
          animate={{
            opacity: 1,
            transition: { duration: 250 },
          }}
        >
          <AlertBar.Base
            showCloseButton={showCloseButton}
            backgroundColor={backgroundColor}
            actionLabel={actionLabel}
            onAction={onAction}
            logoSrc={logoSrc}
          >
            {children}
          </AlertBar.Base>
        </PresenceTransition>
      </Hidden>

      <Hidden from="md">
        <PresenceTransition
          visible={true}
          initial={{ opacity: 0.25 }}
          animate={{
            opacity: 1,
            transition: { duration: 250 },
          }}
        >
          <AlertBar.Float
            backgroundColor={backgroundColor}
            logoSrc={logoSrc}
            actionLabel={actionLabel}
            onAction={onAction}
            bottomOffset={bottomOffset}
          >
            {children}
          </AlertBar.Float>
        </PresenceTransition>
      </Hidden>
    </>
  );
};
