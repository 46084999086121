import { Box, HStack, Skeleton, VStack } from 'native-base';
import { ResponsiveValue } from 'native-base/lib/typescript/components/types';

export const HomeWidgetSkeleton = ({
  maxWidth,
  minWidth,
}: {
  minWidth?: ResponsiveValue<string | number>;
  maxWidth?: ResponsiveValue<string | number>;
}) => {
  return (
    <Box
      borderRadius="md"
      rounded="8"
      overflow={'hidden'}
      backgroundColor="white"
      minW={minWidth}
      maxW={maxWidth}
    >
      <VStack space={2}>
        <Skeleton h={{ base: '32px', md: '46px' }} w={'full'} />
        <VStack space={2} padding={3} pb={0}>
          <HStack space={2} alignItems={'center'}>
            <Skeleton.Text lines={1} h={2} width="50%" />
            <Skeleton.Text lines={1} h={2} width="16px" />
          </HStack>

          <HStack space={2} alignItems={'center'}>
            <Skeleton.Text lines={1} h={2} width="16px" />
            <Skeleton.Text lines={1} h={2} width="40%" />
            <Skeleton.Text lines={1} h={2} width="16%" />
          </HStack>
          <HStack space={2} alignItems={'center'}>
            <Skeleton.Text lines={1} h={2} width="24%" />
            <Skeleton.Text lines={1} h={2} width="24px" />
          </HStack>

          <HStack
            space={2}
            py={3}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Skeleton.Text h={'8px'} lines={1} width="8px" />
            <Skeleton.Text h={'8px'} lines={1} width="8px" />
          </HStack>
        </VStack>
      </VStack>
    </Box>
  );
};
