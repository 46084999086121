import { colors } from 'ui/theme/colors';

import {
  CalendarDayConfig,
  CalendarDayState,
} from '~/components/CalendarInline/components/CalendarDay/types';

export const CalendarDayStyleMap = new Map<CalendarDayState, CalendarDayConfig>(
  [
    [
      CalendarDayState.muted,
      {
        backgroundColor: colors.white,
        fontColor: colors.gray[400],
        borderColor: colors.gray[300],
        tooltipLabel: 'unavailable',
      },
    ],
    [
      CalendarDayState.blocked,
      {
        backgroundColor: colors.gray[400],
        borderColor: colors.gray[400],
        fontColor: colors.white,
        tooltipLabel: 'Already booked',
      },
    ],
  ],
);
