import { ToastContainer } from 'react-toastify';
import styled from 'styled-components';

import { colors } from '~/theme/colors';

export const ToastStyledContainer = styled(ToastContainer)`

  /** Used to define container behavior: width, position: fixed etc... **/

  .Toastify__toast {
    min-height: 48px;
    border-radius: 4px;
    padding: 14px;
    margin-bottom: 12px;
    color: ${colors.gray[800]}
  }

  .Toastify__toast-body {
    padding: 0
  }

  .Toastify__toast.Toastify__toast--success {
    background: ${colors.success[100]};
  }

  .Toastify__toast.Toastify__toast--error {
    background: ${colors.error[100]};
  }

  .Toastify__toast.Toastify__toast--warning {
    background: ${colors.warning[100]};
  }

  .Toastify__toast.Toastify__toast--info {
    background: ${colors.info[100]};
  }

`;
