import { Analytics, logEvent, setUserId } from 'firebase/analytics';

import { initializeFirebaseAnalytics } from '~/config/firebase';
import IAnalytics from '~/types/interfaces/analytics';

class FirebaseAnalyticsService implements IAnalytics {
  private client: Analytics | undefined;

  constructor() {
    this.client = initializeFirebaseAnalytics();
  }

  track(event: string, params: Record<string, unknown>): void {
    if (this.client) {
      logEvent(this.client, event, params);
    }
  }

  identify(userId: string): void {
    if (this.client) {
      setUserId(this.client, userId);
    }
  }

  getClient(): Analytics | undefined {
    return this.client;
  }
}

export default FirebaseAnalyticsService;
