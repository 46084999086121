import { FC } from 'react';

import { BaseSelect } from './BaseSelect';
import { IBaseSelectProps } from './BaseSelect/types';
import { ControlledSelect } from './ControlledSelect';
import { IControlledSelectProps } from './ControlledSelect/types';
import { ControlledSelectGroup } from './ControlledSelectGroup';
import { IControlledSelectGroupProps } from './ControlledSelectGroup/types';

export const Select: {
  Base: FC<IBaseSelectProps>;
  Controlled: FC<IControlledSelectProps>;
  ControlledGroup: FC<IControlledSelectGroupProps>;
} = {
  Base: BaseSelect,
  Controlled: ControlledSelect,
  ControlledGroup: ControlledSelectGroup,
};

export default Select;
