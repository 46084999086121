export enum FeatureFlagProvider {
  posthog = 'posthog',
}

export interface IFeatureFlag {
  isFeatureEnabled(key: FeatureFlagKey): boolean;
  onFeatureFlags: (callback: VoidFunction) => void;
}

export enum FeatureFlagKey {
  maintenance = 'maintenance',
  newNavigationModal = 'new-navigation-modal',
}
