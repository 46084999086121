import React, { lazy, Suspense } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import Loading from 'ui/components/Loading';
import { EEventApplicationType } from 'ui/enums';

/* Layouts */
import { ActivityViewLayout } from '~/components/ActivityViewLayout';
import { AuthLayout } from '~/components/Layouts/AuthLayout';
import { AuthLayoutBackButton } from '~/components/Layouts/AuthLayoutBackButton';
import { AuthLayoutNoSideBar } from '~/components/Layouts/AuthLayoutNoSideBar';
import { SuspenseEcosystemLayout } from '~/components/Layouts/SuspenseEcosystemLayout';
import { PAGES } from '~/constants/pages.constants';
/* Hooks */
import { useLoadUserEcosystemData } from '~/hooks/useLoadUserEcosystemData';
/* My wallet */
import UserApps from '~/pages/Authenticated/UserApps';
const UserProfile = lazy(() => import('~/pages/Authenticated/UserProfile'));
const FindOpportunitiesPage = lazy(
  () => import('~/pages/Authenticated/FindOpportunitiesPage'),
);

/* Teams */
const TeamList = lazy(
  () => import('~/pages/Authenticated/Settings/Teams/TeamsList'),
);
const ViewTeam = lazy(
  () => import('~/pages/Authenticated/Settings/Teams/ViewTeam'),
);
const CreateTeam = lazy(
  () => import('~/pages/Authenticated/Settings/Teams/CreateTeam'),
);
const EditTeam = lazy(
  () => import('~/pages/Authenticated/Settings/Teams/EditTeam'),
);

/* Activities */
const Activities = lazy(() => import('~/pages/Authenticated/Activities'));
const ActivityViewPage = lazy(
  () => import('~/pages/Authenticated/v2/ActivityView'),
);

/* TODO: implement lazy load on reraly used pages */
import { InAppLayout } from '~/components/Layouts/v2/SearchLayout/SubLayouts/InAppLayout';
import { ActivityApplicationCancelConfirmation } from '~/pages/Authenticated/Activities/ActivityApplicationCancelConfirmation';
import { ExternalApplyLinkRedirect } from '~/pages/Authenticated/ExternalApplyLinkRedirect';
import { LogActivities } from '~/pages/Authenticated/LogActivities';
import { LogActivity } from '~/pages/Authenticated/LogActivity';
import { LogActivitySuccess } from '~/pages/Authenticated/LogActivity/components/LogActivitySuccess';
import { OffPlatformApplicationPage } from '~/pages/Authenticated/OffPlatformApplication';
import { OffplatformActivitySuccess } from '~/pages/Authenticated/OffPlatformApplication/components/OffplatformActivitySuccess';
import { EventApplicationPage } from '~/pages/Authenticated/OpportunityApplication/EventApplicationPage';
import { JoinActionPage } from '~/pages/Authenticated/OpportunityApplication/JoinActionPage';
import { OpportunityApplicationConfirmation } from '~/pages/Authenticated/OpportunityApplication/OpportunityApplicationConfirmation';
import { AccountSettings } from '~/pages/Authenticated/Settings/AccountSettings';
import { ChangePassword } from '~/pages/Authenticated/Settings/ChangePassword';
import { DeleteAccount } from '~/pages/Authenticated/Settings/DeleteAccount';
import { EmailPreferences } from '~/pages/Authenticated/Settings/EmailPreferences';
import SplashScreen from '~/pages/Authenticated/SplashScreen';
import { SwitchPersona } from '~/pages/Authenticated/SwitchPersona';
/* Root pages */
import { InApp } from '~/pages/Authenticated/v2/InApp';
const Search = lazy(() => import('~/pages/Authenticated/v2/Search'));
import { useVolunteerEcosystemAvailableApps } from '~/hooks/useApps/useVolunteerEcosystemAvailableApps';
import SignInMobile from '~/pages/SignInMobile';
import { MultipleAppsPersonaRoutes } from '~/routes/Authenticated/MultipleApps';
import { SingleAppPersonaRoutes } from '~/routes/Authenticated/SingleApp';
import { CommonRoutes } from '~/routes/Common';

import { AcceptInvite } from '../../pages/Common/AcceptInvite';

const MyWalletRoutes = (
  <React.Fragment>
    <Route
      element={
        <AuthLayout>
          <Suspense fallback={<Loading containerHeight="100vh" />}>
            <Outlet />
          </Suspense>
        </AuthLayout>
      }
    >
      <Route path={PAGES.Opportunities} element={<FindOpportunitiesPage />} />
      <Route path={PAGES.AllApps} element={<UserApps />} />
      <Route path={PAGES.Activities} element={<Activities />} />
      <Route path={PAGES.Teams} element={<TeamList />} />
      <Route path={PAGES.Profile} element={<UserProfile />} />
    </Route>

    <Route
      element={
        <AuthLayoutBackButton>
          <Outlet />
        </AuthLayoutBackButton>
      }
    >
      <Route path={`${PAGES.ViewTeam}/:id`} element={<ViewTeam />} />
      <Route path={PAGES.CreateTeam} element={<CreateTeam />} />
      <Route path={`${PAGES.EditTeam}/:id`} element={<EditTeam />} />
    </Route>
  </React.Fragment>
);

const ActivitiesRoutes = (
  <>
    <Route
      path={PAGES.ExternalApplyLinkRedirect}
      element={<ExternalApplyLinkRedirect />}
    />
    <Route path={PAGES.ActivitiesDetails} element={<ActivityViewPage />} />

    <Route path={PAGES.EventApplication} element={<EventApplicationPage />} />
    <Route path={PAGES.JoinAction} element={<JoinActionPage />} />
    <Route
      path={PAGES.OngoingOpportunityApplication}
      element={<EventApplicationPage isOngoingOpportunity />}
    />
    <Route
      path={PAGES.TeamEventApplication}
      element={
        <EventApplicationPage
          isTeamEvent
          applicationType={EEventApplicationType.Team}
        />
      }
    />
    {/* Off Platform */}
    <Route
      path={PAGES.CreateOffPlatformActivity}
      element={<OffPlatformApplicationPage />}
    />
    <Route
      path={PAGES.UpdateOffPlatformActivity}
      element={<OffPlatformApplicationPage />}
    />
    <Route
      path={PAGES.OffplatformActivitySuccess}
      element={<OffplatformActivitySuccess />}
    />
  </>
);

const SettingsRoutes = (
  <>
    <Route
      element={
        <AuthLayout isMobileBordered={false}>
          <Outlet />
        </AuthLayout>
      }
    >
      <Route path={PAGES.AccountSettings} element={<AccountSettings />} />
      <Route path={PAGES.EmailPreferences} element={<EmailPreferences />} />
    </Route>
    <Route
      element={
        <AuthLayoutNoSideBar>
          <Outlet />
        </AuthLayoutNoSideBar>
      }
    >
      <Route path={PAGES.ChangePassword} element={<ChangePassword />} />
      <Route path={PAGES.DeleteAccount} element={<DeleteAccount />} />
    </Route>
  </>
);

const SignUpFailsafeRedirects = [
  PAGES.SignUp,
  PAGES.AccessCode,
  PAGES.AuthorizationPersonalData,
].map((path) => (
  <Route
    key={path}
    path={path}
    element={<Navigate to={PAGES.Root} replace />}
  />
));

/* const InAppRoutes = (
  <>
    <Route
      path={PAGES.InAppRoot}
      element={
        <InAppViewLayout>
          <Outlet />
        </InAppViewLayout>
      }
    >
      <Route path={PAGES.InAppRoot} element={<InAppHomeView />} />
      <Route path={PAGES.InAppSearchView} element={<Explorer />} />
    </Route>
  </>
); */

const InvitationRoutes = (
  <>
    {/**
     * This is a workaround. When a user that is logged in copy the
     * invitation url, the system add /signup to the url. So, if they copy and send
     * to a friend, and this friend is logged in the plataform, he will be redirect to AcceptInvite
     * instead of getting a 404. InvitationRoutes are usually in the routes.common.tsx file
     */}
    <Route
      path={`${PAGES.InvitationSlug}/:code/signup`}
      element={<AcceptInvite />}
    />
  </>
);

export const AuthenticatedRoutes = () => {
  const { isLoading: isEcosystemLoading, selectedEcosystem } =
    useLoadUserEcosystemData();

  const { isSingleAppPersona } = useVolunteerEcosystemAvailableApps();

  return (
    <Suspense fallback={<Loading containerHeight="100vh" />}>
      <Routes>
        <Route path={PAGES.Root} element={<SplashScreen />} />
        {/* Ecosystem selected routes */}
        <Route
          element={
            <SuspenseEcosystemLayout
              selectedEcosystem={selectedEcosystem}
              isEcosystemLoading={isEcosystemLoading}
            >
              <Outlet />
            </SuspenseEcosystemLayout>
          }
        >
          {/* IN APP */}
          <Route
            element={
              <InAppLayout>
                <Outlet />
              </InAppLayout>
            }
          >
            <Route path={PAGES.InApp} element={<InApp />} />

            <Route path={PAGES.InAppSearch}>
              <Route index element={<Search />} />
              <Route path=":collection" element={<Search />} />
            </Route>

            <Route
              path={PAGES.InAppActivityDetails}
              element={<ActivityViewPage />}
            />
          </Route>

          {/* HOME */}
          {isSingleAppPersona
            ? SingleAppPersonaRoutes()
            : MultipleAppsPersonaRoutes()}

          <Route path={PAGES.LogActivity} element={<LogActivity />} />

          <Route
            path={PAGES.LogActivitySuccess}
            element={<LogActivitySuccess />}
          />

          {MyWalletRoutes}

          <Route
            element={
              <AuthLayout>
                <Outlet />
              </AuthLayout>
            }
          >
            <Route path={PAGES.LogActivities} element={<LogActivities />} />
          </Route>

          <Route
            element={
              <ActivityViewLayout>
                <Suspense fallback={<Loading containerHeight="100vh" />}>
                  <Outlet />
                </Suspense>
              </ActivityViewLayout>
            }
          >
            {ActivitiesRoutes}
          </Route>

          <Route
            path={PAGES.OpportunityApplicationConfirmation}
            element={<OpportunityApplicationConfirmation />}
          />

          <Route
            path={PAGES.CancelApplicationConfirmation}
            element={<ActivityApplicationCancelConfirmation />}
          />

          {SettingsRoutes}
        </Route>

        {/* Don`t need ecosystem selected */}

        <Route path="/mobile" element={<SignInMobile />} />

        <Route path={PAGES.SwitchPersona} element={<SwitchPersona />} />

        {InvitationRoutes}

        {SignUpFailsafeRedirects}

        {CommonRoutes}
      </Routes>
    </Suspense>
  );
};
