import { useMemo } from 'react';
import { ButtonGroup } from 'ui/components/v2/ButtonGroup';

import { SearchFilters, useSearch } from '~/zustand/store/Search';

export type CausesFilterSectionProps = {
  defaultCauses: string[];
  onChange?: (newFilters: Partial<SearchFilters>) => void;
};

export const CausesFilterSection = ({
  defaultCauses,
  onChange,
}: CausesFilterSectionProps) => {
  const { causes } = useSearch((state) => state);

  const handleChange = (values: string[]) => {
    const causes = values as string[];
    onChange?.({ causes });
  };

  const causeSelectOptions = useMemo(() => {
    return (causes ?? []).map((option) => ({
      value: option._id,
      label: option.displayName,
      imageUrl: option.icon,
    })) as { value: string; label: string; imageUrl: string }[];
  }, [causes]);

  const renderButtons = useMemo(
    () =>
      causeSelectOptions.map(({ value, label, imageUrl }) => (
        <ButtonGroup.RoundButton
          key={value}
          label={label}
          value={value}
          imageUrl={imageUrl}
        />
      )),
    [causeSelectOptions],
  );

  return (
    <ButtonGroup
      multi
      mode="grid"
      values={defaultCauses}
      onChange={handleChange}
      gridColumns={{ base: 4, md: 5, lg: 6 }}
    >
      {renderButtons}
    </ButtonGroup>
  );
};
