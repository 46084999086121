import './style.css';

import { Flex, Text } from 'native-base';
import React from 'react';

import { ActivityCardRecommendations } from '~/components/ActivityCards/CardRecommendations';
import { LogProgressAndHistory as ComposedLogProgressAndHistory } from '~/compound-components/log-progress-and-history/log-progress-and-history.composition';
// import { LogProgressAndHistoryCardProps } from '~/compound-components/log-progress-and-history/log-progress-and-history-card/log-progress-and-history-card.types';
import {
  LogProgressAndHistoryCardContentProps,
  LogProgressAndHistoryCardContentVariations,
} from '~/compound-components/log-progress-and-history/log-progress-and-history-content/log-progress-and-history-content.types';
import { LogProgressAndHistoryCardProps } from '~/compound-components/log-progress-and-history/log-progress-and-history-footer/log-progress-and-history-footer.types';
//
// const LogProgressCardContentFooter = () => {
//   return (
//     <HStack p={2} space={2} justifyContent={'flex-end'} alignItems={'center'}>
//       <Pressable
//         w={'container'}
//         flexDir={'row'}
//         onPress={() => {
//           alert('Edit');
//         }}
//       >
//         <Icon size={16} icon={'edit'} color={'black'} />
//         <Text fontWeight={500} fontSize={'xs'} pl={3}>
//           Edit
//         </Text>
//       </Pressable>
//     </HStack>
//   );
// };
//
// type LogProgressCardContentListType = 'steps' | 'units' | 'ongoingEvent';
//
// type LogProgressContentTypes = {
//   steps: LogProgressStep;
//   units: LogProgressUnit;
//   ongoingEvent: LogProgressOngoingEvent;
// };
//
// interface LogProgressCardContentProps<T extends LogProgressCardContentListType>
//   extends ReactFunctionComponent {
//   listType: T;
//   items?: LogProgressContentTypes[T][];
// }
//
// const LogProgressCardContentSteps = ({
//   items,
// }: Pick<LogProgressCardContentProps<'steps'>, 'items'>) => {
//   return (
//     <>
//       {items?.map((item, index) => {
//         return (
//           <Flex key={index} h={4} w={'full'}>
//             <Text>{item.title}</Text>
//           </Flex>
//         );
//       })}
//     </>
//   );
// };
//
// interface InfoSectionProps {
//   icon: string;
//   text: string;
// }
//
// const InfoSection = ({ icon, text }: InfoSectionProps) => {
//   return (
//     <HStack space={1} alignItems={'center'} minW={'1/3'}>
//       <Icon size={20} icon={icon} color={colors.gray[400]} />
//       <Text fontSize={'sm'} color={'gray.600'} py={0.5}>
//         {text}
//       </Text>
//     </HStack>
//   );
// };
//
// const LogProgressCardItem = ({ date, quantity, unit }: LogProgressUnit) => {
//   return (
//     <HStack w={'full'} bg={'white'} py={1} px={2} space={4} borderRadius={'sm'}>
//       <InfoSection
//         icon={'calendar'}
//         text={formatDate(new Date(date), 'DD/MM/YYYY') || ''}
//       />
//       <InfoSection icon={'plus-circle'} text={`${quantity} ${unit}`} />
//     </HStack>
//   );
// };
//
// const LogProgressCardContentUnits = ({
//   items,
// }: Pick<LogProgressCardContentProps<'units'>, 'items'>) => {
//   return (
//     <VStack space={2} py={3}>
//       {items?.map((item, index) => {
//         return (
//           <LogProgressCardItem
//             key={index}
//             date={item.date}
//             quantity={item.quantity}
//             unit={item.unit}
//           />
//         );
//       })}
//     </VStack>
//   );
// };
//
// const LogProgressCardContentOngoingEvent = ({
//   items,
// }: Pick<LogProgressCardContentProps<'ongoingEvent'>, 'items'>) => {
//   return (
//     <>
//       {items?.map((item, index) => {
//         return (
//           <Flex key={index} h={4} w={'full'}>
//             <Text>{item.hours}</Text>
//           </Flex>
//         );
//       })}
//     </>
//   );
// };
//
// interface LogProgressUnit {
//   date: string;
//   quantity: number;
//   unit: string;
// }
//
// interface LogProgressStep {
//   title: string;
//   description: string;
//   completed?: boolean;
// }
//
// interface LogProgressOngoingEvent {
//   date: string;
//   hours: number;
// }
//
// const logProgressContentDictionary: Record<
//   LogProgressCardContentListType,
//   JSX.Element | ReactNode
// > = {
//   units: <LogProgressCardContentUnits />,
//   steps: <LogProgressCardContentSteps />,
//   ongoingEvent: <LogProgressCardContentOngoingEvent />,
// };
//
// const LogProgressCardContent = <T extends LogProgressCardContentListType>({
//   children,
//   items = [],
//   listType,
// }: LogProgressCardContentProps<T>) => {
//   const content = logProgressContentDictionary[listType];
//
//   return (
//     <div className={`log-progress-content`}>
//       <Box pt={3}>
//         {React.cloneElement(content as React.ReactElement, {
//           items,
//         })}
//       </Box>
//       {children}
//     </div>
//   );
// };

//
// interface LogProgressRootProps extends ReactFunctionComponent {
//   isExpandable?: boolean;
// }
//
// const LogProgressRoot = ({ children, isExpandable }: LogProgressRootProps) => {
//   const [isExpanded, setIsExpanded] = useState(false);
//
//   const breakpointValue = useBreakpointValue({
//     base: {
//       hideArrow: true,
//       contentWidth: 'full',
//     },
//     sm: {
//       hideArrow: false,
//       contentWidth: 'calc(100% - 2rem)',
//     },
//   });
//
//   return (
//     <div className={`log-progress-root${isExpanded ? '-expanded' : ''}`}>
//       <Pressable
//         onPress={() => {
//           isExpandable && setIsExpanded(!isExpanded);
//         }}
//         w={'full'}
//       >
//         <HStack
//           w={'full'}
//           bg={'gray.100'}
//           py={2}
//           px={3}
//           borderWidth={1}
//           borderColor={'gray.300'}
//           borderRadius={'sm'}
//           alignItems={'center'}
//           justifyContent={'space-between'}
//         >
//           <Stack w={breakpointValue.contentWidth}>{children}</Stack>
//           {breakpointValue.hideArrow ? null : (
//             <Icon
//               isCustom
//               size={6}
//               icon={IconEnum.CARET_DOWN}
//               color={'black'}
//             />
//           )}
//         </HStack>
//       </Pressable>
//     </div>
//   );
// };
//
// const LogProgress = {
//   Root: LogProgressRoot,
//   Card: LogProgressCard,
//   CardContent: LogProgressCardContent,
//   ContentFooter: LogProgressCardContentFooter,
// };

interface LogProgressAndHistoryProps<
  T extends LogProgressAndHistoryCardContentVariations,
> extends Omit<LogProgressAndHistoryCardProps, 'children'>,
    Omit<LogProgressAndHistoryCardContentProps<T>, 'children'> {
  isOngoingActivity?: boolean;
}

export const LogProgressAndHistory = <
  Variation extends LogProgressAndHistoryCardContentVariations,
>({
  goalProgress,
  goalUnit,
  goalValue,
  items,
  listType,
  isOngoingActivity,
  onGoingActivityTotalHours,
}: LogProgressAndHistoryProps<Variation>) => {
  return (
    <ComposedLogProgressAndHistory.Root isExpandable={!!items?.length}>
      <ComposedLogProgressAndHistory.Card
        goalUnit={goalUnit}
        goalValue={goalValue}
        goalProgress={goalProgress}
        isOngoingActivity={isOngoingActivity}
        onGoingActivityTotalHours={onGoingActivityTotalHours}
      >
        <ComposedLogProgressAndHistory.CardContent
          listType={listType}
          items={items}
        >
          <ComposedLogProgressAndHistory.ContentFooter />
        </ComposedLogProgressAndHistory.CardContent>
      </ComposedLogProgressAndHistory.Card>
    </ComposedLogProgressAndHistory.Root>
  );
};

export const _Test = () => {
  return (
    <Flex bg={'white'} h={'100vh'} px={6} py={4}>
      {/*<details open={false}>*/}
      {/*  <summary>*/}
      {/*    <Text fontSize={'lg'}>Event booking card variations</Text>*/}
      {/*  </summary>*/}
      {/*  <CardBookingSelectionEvent*/}
      {/*    quantityOfSpotsAvailable={100}*/}
      {/*    totalQuantityOfSpots={100}*/}
      {/*    activities={mockActivityList.slice(0, 1)}*/}
      {/*  />*/}
      {/*  <br />*/}
      {/*  <CardBookingSelectionEvent*/}
      {/*    quantityOfSpotsAvailable={100}*/}
      {/*    totalQuantityOfSpots={100}*/}
      {/*    activities={mockActivityList.slice(0, 1)}*/}
      {/*    hidePrimaryAction*/}
      {/*  />*/}
      {/*  <br />*/}
      {/*  <CardBookingSelectionEvent*/}
      {/*    quantityOfSpotsAvailable={100}*/}
      {/*    totalQuantityOfSpots={100}*/}
      {/*    activities={mockActivityList.slice(0, 2)}*/}
      {/*  />*/}
      {/*  <br />*/}
      {/*  <CardBookingSelectionEvent*/}
      {/*    quantityOfSpotsAvailable={100}*/}
      {/*    totalQuantityOfSpots={100}*/}
      {/*    activities={mockActivityList.slice(0, 2)}*/}
      {/*    hidePrimaryAction*/}
      {/*    hideCardSelectIcon*/}
      {/*  />*/}
      {/*  <br />*/}
      {/*  <CardBookingSelectionEvent*/}
      {/*    bookedActivityId={'640e17ed2454fb495e936cd5'}*/}
      {/*    activities={mockActivityList.slice(0, 1)}*/}
      {/*    hidePrimaryAction*/}
      {/*  />*/}
      {/*  <br />*/}
      {/*  <CardBookingSelectionEvent*/}
      {/*    bookedActivityId={'640e17ed2454fb495e936cd5'}*/}
      {/*    activities={mockActivityList.slice(0, 1)}*/}
      {/*    footerSecondaryActionMenuItems={[*/}
      {/*      {*/}
      {/*        groupLabel: 'Options',*/}
      {/*        items: [*/}
      {/*          {*/}
      {/*            label: 'Cancel',*/}
      {/*            color: 'red.500',*/}
      {/*            onClick: () => console.log('Cancel'),*/}
      {/*            iconName: 'x-circle',*/}
      {/*          },*/}
      {/*        ],*/}
      {/*      },*/}
      {/*    ]}*/}
      {/*  />*/}
      {/*</details>*/}
      {/*<br />*/}
      {/*<details open={false}>*/}
      {/*  <summary>*/}
      {/*    <Text fontSize={'lg'}>Action booking card variations</Text>*/}
      {/*  </summary>*/}
      {/*  <br />*/}
      {/*  <CardBookingSelectionAction*/}
      {/*    currentActivityId={'640e17ed2454fb495e936cd5'}*/}
      {/*    actionActivityGoalText={'20 kilograms'}*/}
      {/*    quantityOfJoins={100}*/}
      {/*    hidePrimaryAction*/}
      {/*  />*/}
      {/*  <br />*/}
      {/*  <CardBookingSelectionAction*/}
      {/*    currentActivityId={'640e17ed2454fb495e936cd5'}*/}
      {/*    actionActivityGoalText={'20 kilograms'}*/}
      {/*    quantityOfJoins={2}*/}
      {/*  />*/}
      {/*  <br />*/}
      {/*  <CardBookingSelectionAction*/}
      {/*    currentActivityId={'640e17ed2454fb495e936cd5'}*/}
      {/*    bookedActivityId={'640e17ed2454fb495e936cd5'}*/}
      {/*  />*/}
      {/*  <br />*/}
      {/*  <CardBookingSelectionAction*/}
      {/*    currentActivityId={'640e17ed2454fb495e936cd5'}*/}
      {/*    bookedActivityId={'640e17ed2454fb495e936cd5'}*/}
      {/*  />*/}
      {/*</details>*/}
      {/*<br />*/}
      <details open>
        <summary>
          <Text fontSize={'lg'}>Log progress card </Text>
        </summary>
        <br />
        <LogProgressAndHistory
          goalValue={20}
          goalProgress={10}
          goalUnit={'kg'}
          listType={'units'}
          items={[
            {
              date: '2021-10-01',
              quantity: 3,
              measureUnit: 'kg',
            },
            {
              date: '2021-10-02',
              quantity: 7,
              measureUnit: 'kg',
            },
          ]}
        />
        <br />
        <LogProgressAndHistory
          goalValue={0}
          goalProgress={0}
          goalUnit={'[units]'}
          listType={'units'}
          items={[]}
        />
        <br />
        <LogProgressAndHistory
          isOngoingActivity
          goalValue={0}
          goalProgress={0}
          goalUnit={'[units]'}
          listType={'ongoingEvent'}
          onGoingActivityTotalHours={'5h 00m'}
          items={[
            {
              hours: '2h 00m',
              date: '2021-10-01',
            },
            {
              hours: '3h 00m',
              date: '2021-07-01',
            },
          ]}
        />
        <br />
        <LogProgressAndHistory
          isOngoingActivity
          goalValue={0}
          goalProgress={0}
          listType={'ongoingEvent'}
          onGoingActivityTotalHours={'0h 00m'}
          items={[]}
        />
        <br />
        <ActivityCardRecommendations />
        {/*<LogProgressAndHistory*/}
        {/*  listType={'steps'}*/}
        {/*  onGoingActivityTotalHours={'0h 00m'}*/}
        {/*  items={[*/}
        {/*    {*/}
        {/*      title: 'Step 1',*/}
        {/*      description: 'Step 1 description',*/}
        {/*      completed: true,*/}
        {/*    },*/}
        {/*    {*/}
        {/*      title: 'Step 2',*/}
        {/*      description: 'Step 2 description',*/}
        {/*      completed: true,*/}
        {/*    },*/}
        {/*  ]}*/}
        {/*/>*/}
      </details>
    </Flex>
  );
};
