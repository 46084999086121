import { useRef, useState } from 'react';

const ITEM_WIDTH = 88;
const ITEM_GAP = 32;
const ACCELERATION = 4;
const SPEED = (ITEM_WIDTH + ITEM_GAP) * ACCELERATION;

export const useCategoriesSliderScrollController = ({
  dataLength,
}: {
  dataLength: number;
}) => {
  const refScroll = useRef<any | null>(null);

  const [currentPosition, setCurrentPosition] = useState(0);
  const [scrollableWidth, setScrollableWidth] = useState(0);
  const [containerWidth, setContainerWidth] = useState(0);

  const goLeft = () => {
    if (refScroll.current) {
      const newPosition = currentPosition - SPEED;
      refScroll?.current?.scrollTo({
        x: newPosition,
        animated: true,
      });
    }
  };

  const goRight = () => {
    if (refScroll.current) {
      const newPosition = currentPosition + SPEED;
      refScroll?.current?.scrollTo({
        x: newPosition,
        animated: true,
      });
    }
  };

  const handleScrollPositionChange = (xPosition: number) => {
    setCurrentPosition(xPosition);
  };

  const handleLayoutChange = (event: any) => {
    const totalScrollableWidth = (ITEM_WIDTH + ITEM_GAP) * dataLength;
    const containerVisibleWidth = event.nativeEvent.layout.width;
    setScrollableWidth(totalScrollableWidth);
    setContainerWidth(containerVisibleWidth);
  };

  const showLeftButton = currentPosition != 0;
  const showRightButton =
    currentPosition + containerWidth < scrollableWidth - ITEM_GAP;

  return {
    refScroll,
    showLeftButton,
    showRightButton,
    currentPosition,
    handleScrollPositionChange,
    handleLayoutChange,
    goRight,
    goLeft,
  };
};
