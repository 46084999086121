import { yupResolver } from '@hookform/resolvers/yup';
import { useBreakpointValue } from 'native-base';
import { useMemo, useRef } from 'react';
import { useForm } from 'react-hook-form';
import {
  NativeSyntheticEvent,
  TextInputKeyPressEventData,
} from 'react-native/types';
import { IModalRefProps } from 'ui/components/Modals/Modal/types';
import Yup from 'ui/utils/validations/yup';

import { PAGES } from '~/constants/pages.constants';
import { useAppDispatch } from '~/hooks/useAppDispatch';
import { useAuth } from '~/hooks/useAuth';
import { useLoadPublicMemberInvite } from '~/hooks/useLoadPublicMemberInvite';
import { useRouter } from '~/hooks/useRouter';
import { redirectSliceActions } from '~/store/slices/redirect';
import { IAuthDTO } from '~/types/dtos';

const schemaValidation = Yup.object({
  email: Yup.string()
    .transform((value: string) => value?.trim() || '')
    .email()
    .required(),
  password: Yup.string().password().required(),
});

export const useJoinAsMemberSignInController = () => {
  const isMobile = useBreakpointValue({ base: true, md: false });

  const modalTermsOfServiceRef = useRef<IModalRefProps>(null);
  const modalPrivacyPolicyRef = useRef<IModalRefProps>(null);

  const dispatch = useAppDispatch();

  const {
    goToRoute,
    params: { code },
    location,
  } = useRouter();

  const { handleSignIn } = useAuth();
  const {
    control,
    handleSubmit,
    formState: { errors, isValid, isDirty, isSubmitting },
  } = useForm<IAuthDTO>({
    resolver: yupResolver(schemaValidation),
    mode: 'onChange',
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const { data: inviteCode, isLoading: isLoadingInviteCode } =
    useLoadPublicMemberInvite(code);

  const onSignUpPress = () => {
    goToRoute(location.pathname.replace('sign-in', 'signup'));
  };

  const goToDoitSignIn = () => {
    goToRoute('/');
  };

  const onSubmit = handleSubmit(async (data) => {
    dispatch(
      redirectSliceActions.addAcceptInviteRedirect({
        isRedirecting: true,
      }),
    );
    await handleSignIn(data);
    const inviteURL = location.pathname.replace('sign-in', '');
    goToRoute(inviteURL);
  });

  const onKeyDown = ({
    nativeEvent,
  }: NativeSyntheticEvent<TextInputKeyPressEventData>): void => {
    if (nativeEvent.key === 'Enter') {
      onSubmit();
    }
  };

  const onForgotPasswordPress = () => {
    goToRoute(PAGES.ConfirmEmail);
  };

  const isLoading = isLoadingInviteCode;

  const canSignIn = useMemo(() => !isValid || !isDirty, [isValid, isDirty]);

  return {
    inviteCode,
    isLoading,
    modalTermsOfServiceRef,
    modalPrivacyPolicyRef,
    control,
    canSignIn,
    isSubmitting,
    errors,
    isMobile,
    onForgotPasswordPress,
    onSubmit,
    onSignUpPress,
    onKeyDown,
    goToDoitSignIn,
  };
};
