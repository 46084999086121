import { IActivityApplication } from '~/types/interfaces/activity';
import { getLogAvailabiltyByApplication } from '~/utils/activityAvailability/get-log-availability';
import { AvailabilityResult } from '~/utils/activityAvailability/types';

export const logAvailabilityController = (props: {
  activityApplication?: IActivityApplication;
}) => {
  const { activityApplication } = props;

  const logAvailability: AvailabilityResult =
    getLogAvailabiltyByApplication(activityApplication);

  return { logAvailability };
};
