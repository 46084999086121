/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { formattedDate } from 'ui/utils/formatter';

import { LogItemActions } from '~/components/v2/LogCollapse/components/LogItem/types';
import MeasurementDefinitionService from '~/services/resources/measurementDefinition';
import { PopulatedMeasurement } from '~/types/interfaces/measurement';
import { groupMeasurementsByUnit } from '~/utils/measurements/format';

import { OffPlatformCardProps } from './types';

export const useOffPlatformLogCardController = (
  props: OffPlatformCardProps,
) => {
  const { measurementDefinition, onRefresh } = props;

  const deleteModalRef = useRef();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedMeasurement, setSelectedMeasurement] =
    useState<PopulatedMeasurement | null>(null);

  const headerText = useMemo(() => {
    if (!measurementDefinition.measurementDate) return 'Unknown date';
    return `Logged on ${formattedDate(
      new Date(measurementDefinition.updatedAt),
      'dd/MM/yyyy',
    )}`;
  }, [measurementDefinition]);

  const groupedMeasurements = useMemo(() => {
    return groupMeasurementsByUnit(measurementDefinition.measurements);
  }, [measurementDefinition]);

  const handleItemPress = (
    item: LogItemActions,
    measurement: PopulatedMeasurement,
  ) => {
    setSelectedMeasurement(measurement);
    switch (item) {
      case 'delete':
        deleteModalRef.current?.open();
        break;
      default:
        console.error('untracked action');
        break;
    }
  };

  const handleCloseModal = () => {
    deleteModalRef.current?.close();
  };

  const handleDeleteConfirmation = async () => {
    if (!selectedMeasurement?._id) return;

    try {
      setIsSubmitting(true);
      await MeasurementDefinitionService.deleteOffPlatformMeasurement(
        measurementDefinition?._id,
        selectedMeasurement._id,
      );
      handleCloseModal();

      toast.success(`Log deleted with success.`);
      onRefresh?.();
    } catch (error) {
      toast.error(`Error on trying to delete log.`);
    } finally {
      setIsSubmitting(false);
    }
  };

  return {
    headerText,
    groupedMeasurements,
    handleItemPress,
    deleteModalRef,
    isSubmitting,
    handleCloseModal,
    handleDeleteConfirmation,
  };
};
