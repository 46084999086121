import { Organization } from 'ui/types/interfaces/organization';

import { useAppSelector } from '~/hooks/useAppSelector';
import { useQuery } from '~/hooks/useQuery';

export const useLoadOrganization = (organizationId = '') => {
  const { isAuthenticated } = useAppSelector(({ auth }) => auth);

  const { data, isLoading, isRefetching } = useQuery<Organization>(
    `organization/${organizationId}`,
    {
      queryOptions: { enabled: !!isAuthenticated && !!organizationId },
      key: `organization-${organizationId}`,
    },
  );

  return { data, isLoading, isRefetching };
};
