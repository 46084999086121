import 'rc-tooltip/assets/bootstrap.css';

import type { SliderProps } from 'rc-slider';
import type { TooltipRef } from 'rc-tooltip';
import Tooltip from 'rc-tooltip';
import raf from 'rc-util/lib/raf';
import * as React from 'react';
import { useEffect } from 'react';

interface HandleTooltipProps {
  value: number;
  children: any;
  visible: boolean;
}

const HandleTooltip: React.FC<HandleTooltipProps> = (props) => {
  const { value, children, visible, ...restProps } = props;

  const tooltipRef = React.useRef<TooltipRef>();
  const rafRef = React.useRef<number | null>(null);

  function cancelKeepAlign() {
    raf.cancel(rafRef.current!);
  }

  function keepAlign() {
    rafRef.current = raf(() => {
      tooltipRef.current?.forceAlign();
    });
  }

  useEffect(() => {
    if (visible) {
      keepAlign();
    } else {
      cancelKeepAlign();
    }
    return cancelKeepAlign;
  }, [value, visible]);

  return (
    <Tooltip
      placement="bottom"
      overlay={value}
      overlayInnerStyle={{ minHeight: 'auto' }}
      ref={tooltipRef as any}
      visible={visible}
      {...restProps}
    >
      {children}
    </Tooltip>
  );
};

export const handleRender: SliderProps['handleRender'] = (node, props) => (
  <HandleTooltip value={props.value} visible={props.dragging}>
    {node}
  </HandleTooltip>
);
