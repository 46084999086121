import { ILocation } from 'ui/types/interfaces';

export const mapLocationsToOptions = (
  locations: ILocation[],
  search: string,
) => {
  const searchText = search.trim() || '';
  let options = locations.map(parseLocationOption);

  if (searchText.length > 0) {
    options = options.filter((opt) => {
      const regex = new RegExp(searchText, 'i');
      return (opt?.label || '').match(regex);
    });
  }
  return Promise.resolve(options);
};

export const parseLocationOption = (location: ILocation) => {
  return {
    value: JSON.stringify(location),
    label: location?.rawLocation || '',
  };
};
