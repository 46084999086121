import { configureStore } from '@reduxjs/toolkit';
import { persistStore } from 'redux-persist';

import { ENVIRONMENT } from '~/config';

import { persistedReducers } from './persist';

export const store = configureStore({
  reducer: persistedReducers,
  devTools: ENVIRONMENT !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const persistor = persistStore(store);
