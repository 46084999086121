import { api } from '~/services/api';
import { InviteInformation } from '~/types/interfaces/inviteMembers';

const MODULE_URL = '/invite-members';

export default class InviteMembersService {
  static async getInvitationDataByCode(
    code: string,
  ): Promise<InviteInformation> {
    return await api.get(`${MODULE_URL}/code/${code}`);
  }

  static async acceptInvitation(code: string) {
    return await api.post(`${MODULE_URL}/accept-invite`, { code });
  }
}
