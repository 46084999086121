import { useQuery } from '~/hooks/useQuery';
import { InviteInformation } from '~/types/interfaces/inviteMembers';

export const useLoadPublicMemberInvite = (code = '') => {
  const { data, isLoading } = useQuery<InviteInformation>(
    `invite-members/public/code/${code}`,
    {
      queryOptions: { enabled: !!code },
      key: `invite-member-code-public-${code}`,
      isOpenApi: true,
    },
  );

  return { data, isLoading };
};
