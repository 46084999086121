import { Flex, HStack, Progress, Stack, Text, VStack } from 'native-base';
import Button from 'ui/components/Button';
import Icon from 'ui/components/Icon';
import Input from 'ui/components/Input';

import { colors } from '~/theme/colors';

import { ConfirmEmailPageProps } from '../types';

export const ConfirmEmailPage = ({
  control,
  coolDownTime,
  errors,
  isSubmitting,
  isResendCodeDisabled,
  isDisabled,
  email,
  onBack,
  handleResendCode,
  onVerifyCode,
}: ConfirmEmailPageProps) => {
  return (
    <Stack
      width={{ base: '100%', lg: '50vw' }}
      minH="100%"
      justifyContent={{ base: 'initial' }}
      padding={{ base: 5, lg: '5rem' }}
      bgColor={colors.singletons.lightText}
      overflowY="auto"
    >
      <Stack
        flexShrink={0}
        bgColor="white"
        width={{ base: '100%', lg: '85%' }}
        borderRadius="2xl"
        height={{ base: '100%', lg: '100%' }}
      >
        <Stack width="100%" p={4}>
          <HStack alignItems="center">
            <Icon icon="key" size={32} />
            <Stack ml={5} space={3}>
              <Text fontSize={{ md: '2xl', base: 20 }} fontWeight={500}>
                Enter the code we sent you
              </Text>
              <Text fontSize={{ md: '2xl', base: 14 }}>
                We sent a verification code to {email}
              </Text>
            </Stack>
          </HStack>
        </Stack>
        <Progress value={80} _filledTrack={{ bg: 'black' }} h={1} />
        <VStack h="full">
          <Flex
            width="100%"
            maxW={'100%'}
            flexDirection={{ base: 'column', md: 'row' }}
            alignItems="center"
            justifyContent="center"
            style={{ gap: 12 }}
            px={4}
            py={6}
          >
            <Input
              name="accessCode"
              control={control}
              placeholder="Type the code we sent to you"
              leftIconName="key"
              keyboardType="numeric"
              leftIconColor={colors.muted[400]}
              label="Code"
              errorMessage={errors.accessCode?.message}
              iconErrorMessage="info"
              p={4}
              isFullWidth
              borderColor="muted.200"
              height="full"
            />
            <Stack
              mt={{ md: errors.accessCode?.message ? 2 : 8 }}
              width={{ base: '100%' }}
              maxW={{ base: '100%', md: '20%' }}
            >
              <Button
                py={6}
                bgColor="gray.200"
                maxW={{ base: '100%', md: '100px', lg: '100%' }}
                minW={{ base: '100%', md: '100px' }}
                width="100%"
                onPress={handleResendCode}
                isLoading={isResendCodeDisabled}
                textAlign="center"
              >
                <Text fontWeight={500}>
                  {isResendCodeDisabled ? coolDownTime : 'Resend code'}
                </Text>
              </Button>
            </Stack>
          </Flex>

          <Flex
            width="100%"
            flexDir={{ base: 'column-reverse', lg: 'column' }}
            px={4}
            py={8}
            style={{ gap: 12 }}
            bottom={0}
          >
            <Button
              variant="outline"
              _text={{ fontWeight: 500 }}
              maxWidth={'100%'}
              p={{ base: 6, lg: 4 }}
              onPress={onVerifyCode}
              isLoading={isSubmitting}
              isDisabled={isDisabled}
            >
              Continue
            </Button>
            <Button
              _text={{ fontWeight: 500 }}
              variant="ghost"
              maxWidth={'100%'}
              p={{ base: 6, lg: 4 }}
              onPress={onBack}
            >
              Back
            </Button>
          </Flex>
        </VStack>
      </Stack>
    </Stack>
  );
};
