import { useBreakpointValue } from 'native-base';
import { useEffect, useRef } from 'react';
import { IModalRefProps } from 'ui/components/Modals/Modal/types';

import { ActivityFilterForm } from '~/components/Layouts/v2/SearchLayout/components/Modals/ActivityFilterModal/FilterForm';
import { ActivityFilterFormFilters } from '~/components/Layouts/v2/SearchLayout/components/Modals/ActivityFilterModal/FilterForm/types';
import { RootPortal } from '~/components/RootPortal';
import { SearchFilters, useSearch } from '~/zustand/store/Search';

import DesktopModal from './Overlay/DesktopModal';
import MobileDrawer from './Overlay/MobileDrawer';

export type ActivityFilterModalProps = {
  onSubmit?: (filters: Partial<SearchFilters>) => void;
  submitButtonLabel?: string;
  defaultFilters?: Partial<ActivityFilterFormFilters>;
};

export const ActivityFilterModal = ({
  onSubmit,
  defaultFilters,
  submitButtonLabel,
}: ActivityFilterModalProps) => {
  const { isFilterModalOpen } = useSearch((state) => state);
  const closeFilterModal = useSearch((state) => state.closeFilterModal);
  const isMobile = useBreakpointValue({ base: true, md: false });
  const modalRef = useRef<IModalRefProps>(null);

  useEffect(() => {
    if (!!isFilterModalOpen && !isMobile) {
      modalRef.current?.open();
    }
  }, [isFilterModalOpen, isMobile]);

  const handleCloseModal = () => {
    closeFilterModal();
    if (!isMobile) {
      modalRef.current?.close();
    }
  };
  return (
    <>
      {isFilterModalOpen && (
        <RootPortal>
          {isMobile ? (
            <MobileDrawer isOpen={isFilterModalOpen} onClose={handleCloseModal}>
              <ActivityFilterForm
                onSubmit={onSubmit}
                defaultFilters={defaultFilters}
                submitButtonLabel={submitButtonLabel}
              />
            </MobileDrawer>
          ) : (
            <DesktopModal modalRef={modalRef} onClose={handleCloseModal}>
              <ActivityFilterForm
                onSubmit={onSubmit}
                defaultFilters={defaultFilters}
                submitButtonLabel={submitButtonLabel}
              />
            </DesktopModal>
          )}
        </RootPortal>
      )}
    </>
  );
};
