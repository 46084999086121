import { extendTheme } from 'native-base';

import { colors } from './colors';
import { components } from './components';
import { fontConfig, fonts, lineHeights } from './fonts';

export const theme = extendTheme({
  colors,
  components,
  fontConfig,
  fonts,
  lineHeights,
});
