import { HStack, Text } from 'native-base';

import CommonInputWrapper from '../../../CommonInputWrapper';
import { InputSize } from '../../../inputs/types';
import TextInput from '../../TextInput';
import { useTimeInputController } from './controller';
import { TimeInputProps } from './types';

export const TimeInput = (props: TimeInputProps) => {
  const {
    onBlur,
    errorMessage,
    showMinutes = true,
    isDisabled = false,
  } = props;
  const { handleOnChange, hours, minutes } = useTimeInputController(props);

  return (
    <CommonInputWrapper errorMessage={errorMessage}>
      <HStack alignItems="center" space={2} flex={1}>
        <HStack flex={1} alignItems="center" space="2">
          <TextInput.Base
            value={hours?.toString()}
            onChange={(v) => handleOnChange(v, 0)}
            onBlur={onBlur}
            size={InputSize.lg}
            fontSize="3xl"
            keyboardType="numeric"
            placeholder="00"
            isDisabled={isDisabled}
          />
          <Text px="1" fontSize={'1.87rem'} color="gray.400">
            h
          </Text>
        </HStack>

        {showMinutes && (
          <HStack flex={1} alignItems="center" space="2">
            <TextInput.Base
              value={minutes?.toString()}
              onChange={(v) => handleOnChange(v, 1)}
              onBlur={onBlur}
              size={InputSize.lg}
              fontSize="3xl"
              keyboardType="numeric"
              placeholder="00"
              isDisabled={isDisabled}
            />

            <Text px="1" fontSize={'1.87rem'} color="gray.400">
              m
            </Text>
          </HStack>
        )}
      </HStack>
    </CommonInputWrapper>
  );
};

export default TimeInput;
