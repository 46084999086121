import { Avatar, HStack, Pressable, Text, VStack } from 'native-base';
import Icon from 'ui/components/Icon';
import { colors } from 'ui/theme/colors';

import { AccountData } from '~/components/NavBar/components/AccountDropdown/types';

export type AccountDropDownHeaderProps = {
  accountData?: AccountData;
  onSwitchPersonaPress?: VoidFunction;
  showSwitchPersonaButton?: boolean;
};

export const AccountDropDownHeader = ({
  accountData,
  onSwitchPersonaPress,
  showSwitchPersonaButton = false,
}: AccountDropDownHeaderProps) => {
  const {
    userName,
    userAvatarUrl,
    userNameInitials,
    organizationName,
    organizationLogoUrl,
    organizationNameInitials,
  } = accountData ?? {};
  return (
    <HStack
      p={4}
      space={3}
      borderBottomWidth={1}
      backgroundColor={'gray.50'}
      borderColor={'gray.200'}
      alignItems={'center'}
    >
      <HStack alignItems={'center'}>
        <Avatar
          w={'32px'}
          h={'32px '}
          borderWidth={1}
          borderRadius={'full'}
          borderColor={'gray.300'}
          source={{ uri: userAvatarUrl }}
        >
          {userNameInitials}
        </Avatar>

        <Avatar
          w={'32px'}
          h={'32px '}
          marginLeft={-2}
          borderWidth={1}
          borderColor={'gray.300'}
          borderRadius={'full'}
          source={{ uri: organizationLogoUrl }}
        >
          {organizationNameInitials}
        </Avatar>
      </HStack>

      <VStack>
        <Text fontSize={'sm'} numberOfLines={1} fontWeight={500}>
          {userName || 'Unnamed'}
        </Text>

        <HStack alignItems={'center'}>
          <Text
            fontSize={'xs'}
            fontWeight={500}
            numberOfLines={1}
            color={'gray.500'}
          >
            {organizationName}
          </Text>
          <Text color={'gray.500'} px={1.5}>
            &#8226;
          </Text>
          <Text
            fontSize={'xs'}
            fontWeight={400}
            color={colors.singletons.darkText}
          >
            {accountData?.userProfileRole}
          </Text>
        </HStack>
      </VStack>

      <VStack marginLeft={'auto'} marginRight={0}>
        {showSwitchPersonaButton ? (
          <Pressable
            w="32px"
            h="32px"
            alignItems={'center'}
            justifyContent={'center'}
            borderRadius={100}
            backgroundColor={'gray.100'}
            _hover={{ backgroundColor: 'gray.200' }}
            onPress={onSwitchPersonaPress}
          >
            <Icon
              icon="switch"
              size={'16px'}
              enableAutoCustom
              color={colors.singletons.black}
            />
          </Pressable>
        ) : null}
      </VStack>
    </HStack>
  );
};
