import {
  Box,
  Flex,
  Hidden,
  HStack,
  Pressable,
  Text,
  VStack,
} from 'native-base';
import Icon from 'ui/components/Icon';
import SearchAutoComplete from 'ui/components/v2/SearchInputs/SearchAutoComplete';
import { colors } from 'ui/theme/colors';

import BreadCrumbs from '~/components/Breadcrumbs';
import {
  CenterContainer,
  CenterContainerSize,
} from '~/components/Layouts/Scaffolds/CenterContainer';
import { STATIC_SEARCH_SUGGESTIONS_VALUES } from '~/components/Layouts/v2/SearchLayout/constants/search-suggestions';

import { SearchHeaderController } from './controller';
import { SearchHeaderProps } from './types';

export const SearchHeader = (props: SearchHeaderProps) => {
  const {
    inputLabel,
    breadCrumbsNodes,
    defaultTextValue,
    selectedCollection,
    isCollectionSearch,
    handleExitSection,
    handleSearchInputSubmit,
  } = SearchHeaderController(props);

  return (
    <VStack pt={3} pb={{ base: 2, md: 6 }} zIndex={2}>
      <CenterContainer size={CenterContainerSize.xl}>
        <VStack>
          <BreadCrumbs breadcrumbs={breadCrumbsNodes} />

          <Flex
            mt={isCollectionSearch ? 0 : 3}
            flexDirection={{ base: 'column', md: 'row' }}
            alignItems={{ base: 'flex-start', md: 'flex-end' }}
          >
            {isCollectionSearch && (
              <HStack
                h="fit-content"
                space={3}
                alignItems={'center'}
                justifyContent={'flex-start'}
                mr={{ base: 0, md: 16 }}
                my={{ base: 4, md: 0 }}
              >
                <Pressable
                  _hover={{ backgroundColor: 'gray.200' }}
                  borderRadius={100}
                  alignItems={'center'}
                  justifyContent={'center'}
                  onPress={handleExitSection}
                  px={1}
                  py={1}
                >
                  <Icon icon="arrow-left" size="24px" color={'muted.600'} />
                </Pressable>

                <Text
                  fontSize={'30px'}
                  lineHeight={'125%'}
                  fontWeight={700}
                  color={colors.singletons.black}
                >
                  {selectedCollection?.label || ''}
                </Text>
              </HStack>
            )}

            <HStack
              w="full"
              flex={1}
              alignItems={'flex-end'}
              justifyContent={isCollectionSearch ? 'flex-end' : 'center'}
            >
              <VStack w="full" maxW={{ base: 'unset', md: '640px' }}>
                {!isCollectionSearch && (
                  <Hidden till="md">
                    <Box w="full" mb={4}>
                      <Text
                        textAlign={'center'}
                        color={colors.singletons.black}
                        fontSize={'xl'}
                        fontWeight={500}
                      >
                        Your search results
                      </Text>
                    </Box>
                  </Hidden>
                )}
                <SearchAutoComplete
                  label={inputLabel}
                  disableOnEmptyInput
                  triggerSubmitOnClear
                  onSubmit={handleSearchInputSubmit}
                  defaultValue={defaultTextValue}
                  key={defaultTextValue}
                  placeholder={
                    "Search for anything you'd like to help with — activities, causes, skills..."
                  }
                  suggestionValues={STATIC_SEARCH_SUGGESTIONS_VALUES}
                />
              </VStack>
            </HStack>
          </Flex>
        </VStack>
      </CenterContainer>
    </VStack>
  );
};
