/* eslint-disable @typescript-eslint/no-explicit-any */
import { Flex, HStack, Pressable, Stack, Text } from 'native-base';
import Button from 'ui/components/Button';
import { PopOverOptions } from 'ui/components/PopOverOptions';

import { ActivitySummaryTitle } from '~/components/ActivityCards/CardRecommendations/components/ActivitySummaryTitle';
import { CardRecommendationsHeader } from '~/components/ActivityCards/CardRecommendations/components/CardRecommendationsHeader';
import ModalRemove from '~/components/ModalRemove';
import { RootPortal } from '~/components/RootPortal';
import { LogCollapse } from '~/components/v2/LogCollapse';
import { useOffPlatformLogCardController } from '~/pages/Authenticated/LogActivities/OffPlatformActivities/components/OffPlatformLogCard/controller';

import { POPOVER_OPTIONS } from './constants';
import { OffPlatformCardProps, OffPlatformOptionsActionEnum } from './types';

const appName = 'Off-platform';

export const OffPlatformLogCard = (props: OffPlatformCardProps) => {
  const { measurementDefinition, onLogActivityPress, onOptionsPress } = props;

  const {
    headerText,
    groupedMeasurements,
    handleItemPress,
    deleteModalRef,
    handleCloseModal,
    handleDeleteConfirmation,
    isSubmitting,
  } = useOffPlatformLogCardController(props);

  return (
    <>
      <Pressable
        padding={4}
        borderRadius="xl"
        borderWidth="1px"
        borderColor="gray.300"
        bgColor="gray.100"
        flexShrink="none"
      >
        <Flex
          flexDir={{ base: 'column', lg: 'row' }}
          width="100%"
          justifyContent="space-between"
          style={{ gap: '24px' } as any}
        >
          <Stack maxW={{ base: '100%', lg: '80%' }} width="100%" space={2}>
            <HStack justifyContent="space-between">
              <CardRecommendationsHeader
                activityType={measurementDefinition.typeOfActivity as any}
                headerText={headerText}
                headerTextColor="gray.600"
              />
            </HStack>
            <HStack
              space={4}
              width="100%"
              flexDir={{ base: 'column', lg: 'row' }}
            >
              <Stack width="100%" space={4}>
                <HStack space={3}>
                  <ActivitySummaryTitle
                    activityName={measurementDefinition.titleOfActivity || ''}
                    organizationName={
                      measurementDefinition.organizationName || ''
                    }
                    appName={appName}
                  />
                </HStack>
                {Object.entries(groupedMeasurements).map(
                  ([key, measurements]) => {
                    return (
                      <LogCollapse
                        key={key}
                        measurements={measurements}
                        onAction={handleItemPress}
                      />
                    );
                  },
                )}
              </Stack>
            </HStack>
          </Stack>

          <Stack
            space={4}
            maxWidth={{ base: '100%', lg: '20%' }}
            width="100%"
            alignItems="flex-end"
          >
            <>
              <Stack
                maxWidth={{ base: '100%', lg: 'fit-content' }}
                width="100%"
                flexDir={'column'}
                space={4}
              >
                <Button
                  leftIconName={'plus-circle'}
                  leftIconColor="grey.500"
                  leftIconSize={16}
                  onPress={onLogActivityPress}
                  variant="outline"
                  h={37}
                  px={6}
                >
                  <Text color="gray.500" fontWeight={500} numberOfLines={1}>
                    Log Activity
                  </Text>
                </Button>

                <Flex alignItems={'center'}>
                  <PopOverOptions
                    popovText={'Options'}
                    popovTextPosition={'right'}
                    popovIcon={'more-vertical'}
                    options={POPOVER_OPTIONS({
                      onEndActivity: () =>
                        onOptionsPress &&
                        onOptionsPress(OffPlatformOptionsActionEnum.END),
                      onDeleteActivity: () =>
                        onOptionsPress &&
                        onOptionsPress(OffPlatformOptionsActionEnum.DELETE),
                    })}
                    popTextColor={'gray.600'}
                    popovIconColor={'gray.600'}
                  />
                </Flex>
              </Stack>
            </>
          </Stack>
        </Flex>
      </Pressable>
      <RootPortal>
        <ModalRemove
          modalRef={deleteModalRef}
          headerText="Delete log"
          handleCloseModal={handleCloseModal}
          handleDelete={handleDeleteConfirmation}
          rightBtnLoading={isSubmitting}
          rightBtnText="Delete"
        >
          <Stack space={5}>
            <Text fontWeight={400} fontSize="md" color="gray.500">
              Are you sure that you want to delete this log?
            </Text>

            <Text fontWeight={400} fontSize="md" color="gray.500">
              This action is irreversible and the log will be immediately
              removed from the list.
            </Text>
          </Stack>
        </ModalRemove>
      </RootPortal>
    </>
  );
};
