/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Avatar,
  Box,
  Flex,
  Hidden,
  HStack,
  Pressable,
  Stack,
  Text,
} from 'native-base';
import Icon, { IconEnum } from 'ui/components/Icon';
import { ActivityTypeEnum, MeasurementCategory } from 'ui/enums';
import { getMeasurementTotalLogged } from 'ui/utils/getMeasurementTotalLogged';
import {
  getMeasurementCategoryByPluralLabel,
  mapCompletionMeasurements,
  mapOtherMeasurements,
} from 'ui/utils/measurements';
import { secondsToHoursAndMinutes } from 'ui/utils/secondsToDuration';

import { ActivitySummaryTitle } from '~/components/ActivityCards/CardRecommendations/components/ActivitySummaryTitle';
import ModalRemove from '~/components/ModalRemove';
import { LogProgressAndHistory } from '~/compound-components/log-progress-and-history/log-progress-and-history.composition';
import { getNameInitials } from '~/utils/getInitials';

import { useActivityDetailsController } from './activityDetailsController';
import { LogEventCard } from './LogEventCard';
import { ActivityDetailsProps } from './types';

export const ActivityDetails = ({
  measurementUnit,
  app,
  measurements,
  activityApplication,
  refetchMeasurements,
}: ActivityDetailsProps) => {
  const measurementType = getMeasurementCategoryByPluralLabel(
    measurementUnit?.pluralLabel || '',
  );

  const {
    breakpointValue,
    isExpanded,
    toggleIsExpanded,
    toggleIsEditPressed,
    isEditPressed,
    onPressCancel,
    modalRef,
    handleCloseModal,
    handleDeleteMeasurement,
    isSubmitting,
  } = useActivityDetailsController(refetchMeasurements);

  const { hours, minutes } = secondsToHoursAndMinutes(
    getMeasurementTotalLogged(measurements),
  );

  if (
    activityApplication?.activitySubDocument.activityDefinitionSubDocument
      .type === ActivityTypeEnum.Event
  ) {
    return <LogEventCard activityApplication={activityApplication} app={app} />;
  }

  return (
    <Stack
      width="100%"
      bgColor="gray.50"
      borderColor="gray.300"
      borderRadius="xl"
      borderWidth="1px"
      padding={4}
    >
      <Flex style={{ gap: 12 }} flexDir={{ base: 'column', lg: 'row' }}>
        <Hidden till="lg">
          <Avatar
            borderWidth="1px"
            borderColor="gray.300"
            height={12}
            width={12}
            rounded={12}
            backgroundColor="none"
            source={{
              uri:
                activityApplication?.activitySubDocument
                  .activityDefinitionSubDocument.organizationSubDocument.logo ||
                '',
            }}
            _image={{ rounded: 12 }}
          >
            {getNameInitials(
              activityApplication?.activitySubDocument
                .activityDefinitionSubDocument.organizationSubDocument.name ||
                '',
            )}
          </Avatar>
        </Hidden>
        <Pressable
          style={
            {
              cursor: breakpointValue.hideArrow ? 'pointer' : 'initial',
            } as any
          }
          width={{ base: '100%', md: '90%' }}
          flexShrink="none"
          onPress={() => {
            breakpointValue.hideArrow &&
              measurements?.length &&
              toggleIsExpanded();
          }}
        >
          <Stack width="100%" space={4}>
            <HStack space={3}>
              <ActivitySummaryTitle
                activityName={
                  activityApplication?.activitySubDocument
                    .activityDefinitionSubDocument.title || ''
                }
                appName={app?.name || ''}
                organizationName={
                  activityApplication?.activitySubDocument
                    .activityDefinitionSubDocument.organizationSubDocument
                    .name || ''
                }
              />
              {breakpointValue.hideArrow ? (
                <Box pt={1}>
                  <Icon
                    isCustom
                    size={6}
                    icon={IconEnum.CARET_DOWN}
                    color={'black'}
                  />
                </Box>
              ) : null}
            </HStack>
            <LogProgressAndHistory.Root
              isExpanded={isExpanded}
              isExpandable={!!measurements?.length}
            >
              <LogProgressAndHistory.Card
                goalUnit={
                  activityApplication?.activitySubDocument
                    ?.activityDefinitionSubDocument
                    ?.measurementUnitPluralLabel || ''
                }
                goalValue={
                  activityApplication?.activitySubDocument
                    ?.activityDefinitionSubDocument?.targetAmount || 0
                }
                goalProgress={
                  measurementUnit?.steps && measurementUnit.steps.length > 0
                    ? measurements[0]?.steps?.length || 0
                    : getMeasurementTotalLogged(measurements)
                }
                goalCategory={measurementType}
                onGoingActivityTotalHours={`${hours}h ${minutes}m`}
                isAction={
                  activityApplication?.activitySubDocument
                    ?.activityDefinitionSubDocument?.type ===
                  ActivityTypeEnum.Action
                }
                totalStepsLogged={measurements[0]?.steps?.length || 0}
                steps={measurementUnit?.steps || []}
              >
                <LogProgressAndHistory.CardContent
                  isEditPressed={isEditPressed}
                  listType={measurementType}
                  items={
                    measurementUnit?.steps && measurementUnit.steps.length > 0
                      ? mapCompletionMeasurements(
                          measurementUnit,
                          measurements[0],
                        )
                      : mapOtherMeasurements(
                          measurements,
                          measurementUnit,
                          onPressCancel,
                        )
                  }
                >
                  <LogProgressAndHistory.ContentFooter
                    onPressEdit={toggleIsEditPressed}
                    isStep={!!measurementUnit?.steps?.length}
                  />
                </LogProgressAndHistory.CardContent>
              </LogProgressAndHistory.Card>
            </LogProgressAndHistory.Root>
          </Stack>
        </Pressable>
      </Flex>
      <ModalRemove
        modalRef={modalRef}
        headerText="Cancel log"
        handleCloseModal={handleCloseModal}
        handleDelete={handleDeleteMeasurement}
        rightBtnLoading={isSubmitting}
        rightBtnText="Cancel"
      >
        <Stack space={5}>
          <Text fontWeight={400} fontSize="md" color="gray.500">
            Are you sure you want to cancel this activity log?
          </Text>
        </Stack>
      </ModalRemove>
    </Stack>
  );
};
