import Yup from '~/utils/validations/yup';

export const schema = Yup.object({
  value: Yup.string().required(),
  date: Yup.date().required(),
});

export const stepsSchema = Yup.object({
  steps: Yup.array()
    .of(
      Yup.object({
        title: Yup.string(),
        description: Yup.string(),
        isChecked: Yup.boolean(),
        hasLogged: Yup.boolean(),
      }),
    )
    .test({
      test: (steps) => steps?.some((step) => step.isChecked) ?? false,
      message: 'At least one step has to be selected',
    }),
});
