import { Flex, Text } from 'native-base';

import { BookingDateSelectDateProps } from './booking-date-select-date.types';

export const BookingDateSelectDate = ({
  day,
  month,
}: BookingDateSelectDateProps) => {
  return (
    <Flex w={'2.5rem'} mr={'0.5rem'} justify={'center'} alignItems={'center'}>
      <Text fontWeight="700" fontSize="1.25rem" color="inherit">
        {day}
      </Text>
      <Text
        lineHeight={'150%'}
        fontSize="1rem"
        fontWeight="500"
        color="inherit"
        textTransform={'uppercase'}
      >
        {month}
      </Text>
    </Flex>
  );
};
