/* eslint-disable @typescript-eslint/no-empty-function */
import { Box, HStack, Text } from 'native-base';
import { useMemo } from 'react';
import Icon from 'ui/components/Icon';
import { PopOverOptions } from 'ui/components/PopOverOptions';
import { IOptions } from 'ui/components/PopOverOptions/types';

import {
  makeEmailShareOption,
  makeWhatsappShareOption,
} from '~/components/ShareActivity/constants';

import { IShareActivityProps } from './types';
import { useShareActivity } from './useShareActivity';

export const ShareActivity = ({
  activityLink,
  showEmail = true,
  showWhatsapp = true,
}: IShareActivityProps) => {
  const { handleCopyLinkToClipboard } = useShareActivity(activityLink);

  const menuOptions: IOptions[] = useMemo(() => {
    const options: IOptions[] = [
      {
        title: 'Copy link',
        icon: { icon: 'link', color: 'black', size: 16 },
        action: handleCopyLinkToClipboard,
      },
    ];

    if (!!showWhatsapp) {
      options.push(makeWhatsappShareOption(activityLink));
    }

    if (!!showEmail) {
      options.push(makeEmailShareOption(activityLink));
    }

    return options;
  }, [showWhatsapp, showEmail, activityLink]);

  return (
    <Box>
      <PopOverOptions
        customButton={
          <HStack justifyContent={'flex-end'} alignItems={'center'} space={3}>
            <Text fontSize={'md'} fontWeight={'500'}>
              Share this opportunity
            </Text>
            <Box bg={'gray.100'} rounded={'full'} w={'fit-content'} p={2}>
              <Icon size={20} icon={'share-2'} color={'black'} />
            </Box>
          </HStack>
        }
        customButtomStyles={{
          ml: 'auto',
        }}
        options={menuOptions}
      />
    </Box>
  );
};
