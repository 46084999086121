import { Measurement, MeasurementStep } from 'types/measurements';
import { MeasurementUnit, MeasurementUnitStep } from 'types/measurementUnit';

import { MeasurementCategory } from '../enums/index';
import { formatDate } from './formatter';
import { secondsToHoursAndMinutes } from './secondsToDuration';

export const formatMeasurement = (
  measurement: Measurement,
  measurementUnit: MeasurementUnit,
  onPressCancel: (measurementId: string, activityApplicationId: string) => void,
) => {
  switch (measurement.measurementType) {
    case MeasurementCategory.Weight:
      return {
        date: formatDate(new Date(measurement.measurementDate)),
        quantity: measurement.amount,
        measureUnit: measurement.amount > 1 ? 'kilograms' : 'kilogram',
        onPressCancel: () =>
          onPressCancel(measurement._id, measurement.activityApplication || ''),
      };

    case MeasurementCategory.Time:
      const { hours, minutes } = secondsToHoursAndMinutes(measurement.amount);
      return {
        date: formatDate(new Date(measurement.measurementDate)),
        hours: `${hours}:${minutes}`,
        onPressCancel: () =>
          onPressCancel(measurement._id, measurement.activityApplication || ''),
      };

    case MeasurementCategory.CustomUnit:
      return {
        date: formatDate(new Date(measurement.measurementDate)),
        quantity: measurement.amount,
        measureUnit:
          measurement.amount > 1
            ? measurementUnit.pluralLabel
            : measurementUnit.singularLabel,
        onPressCancel: () =>
          onPressCancel(measurement._id, measurement.activityApplication || ''),
      };
  }
};

export const mapCompletionMeasurements = (
  measurementUnit: MeasurementUnit,
  lastMeasurement: Measurement,
) => {
  return measurementUnit.steps.map(
    (step: MeasurementUnitStep, index: number) => {
      const findStep = lastMeasurement?.steps?.find(
        (currentStep: MeasurementStep) => currentStep.step === index,
      );

      if (findStep) {
        return {
          completedAt: new Date(findStep.completedAt),
          ...step,
        };
      }

      return step;
    },
  );
};

export const mapOtherMeasurements = (
  measurements: Measurement[],
  measurementUnit: MeasurementUnit,
  onCancelPress: (measurementId: string, activityApplicationId: string) => void,
) => {
  return measurements.map((measurement) => {
    return formatMeasurement(measurement, measurementUnit, onCancelPress);
  });
};

export const getPercentageLogged = (totalLogged: number, amount: number) => {
  const value = Math.round((totalLogged / amount) * 100);
  if (value === 0) {
    return 0;
  }
  return value.toFixed(2);
};

export const getMeasurementCategoryByPluralLabel = (pluralLabel: string) => {
  switch (pluralLabel) {
    case 'Steps':
      return MeasurementCategory.Completion;
    case 'Kilograms':
      return MeasurementCategory.Weight;
    case 'Hours':
      return MeasurementCategory.Time;
    default:
      return MeasurementCategory.CustomUnit;
  }
};
