import { Box, Hidden, HStack, Stack, VStack } from 'native-base';
import Illustration, { ILLUSTRATIONS_NAME } from 'ui/components/Illustration';
import Loading from 'ui/components/Loading';

import OffPlatformDefinitionForm from '~/pages/Authenticated/OffPlatformApplication/components/DefinitionForm';
import { OffPlatformFormHeader } from '~/pages/Authenticated/OffPlatformApplication/components/FormHeader';
import OffPlatformLogForm from '~/pages/Authenticated/OffPlatformApplication/components/LogForm';
import { useOffPlatformApplicationController } from '~/pages/Authenticated/OffPlatformApplication/controllers/useOffPlatformApplicationController';

export const OffPlatformApplicationPage = () => {
  const {
    isLoading,
    sectionInfo,
    sectionIndex,
    causeOptions,
    initialLogFormData,
    definitionFormData,
    measurementUnitGroupedOptions,
    isLoadingMeasurementDefinition,
    measurementDefinition,
    handleCancel,
    handleDefinitionSubmit,
    handleLogSubmit,
  } = useOffPlatformApplicationController();

  return (
    <Stack bg={'gray.100'} overflow={'scroll'}>
      <VStack
        minW={'full'}
        minH={'full'}
        alignItems={'center'}
        py={8}
        px={{ base: 0, md: 8, lg: 16 }}
      >
        <HStack
          w={'full'}
          h={'full'}
          space={{ md: '96px' }}
          maxW={'1440px'}
          justifyContent={{ base: 'center', md: 'flex-start' }}
          alignItems={'flex-start'}
          px={[0, 0, 0, 12]}
          paddingLeft={{ base: 0, xl: '96px' }}
        >
          <Hidden till={'lg'}>
            <Stack
              alignItems={'center'}
              justifyContent={'center'}
              w="full"
              h="full"
              maxW={'400px'}
              maxH={760}
            >
              <Illustration
                resizeMode={'contain'}
                w={'100%'}
                maxW={'400px'}
                maxH={'400px'}
                width={'full'}
                height={'full'}
                name={ILLUSTRATIONS_NAME.DATA_MANAGEMENT}
              />
            </Stack>
          </Hidden>

          <VStack
            w="full"
            maxW={{ base: '652px' }}
            minW={{ base: 'unset', md: '460px' }}
            space={0}
            borderRadius={3}
          >
            {isLoadingMeasurementDefinition ? (
              <Loading containerHeight="70vh" />
            ) : (
              <>
                <OffPlatformFormHeader
                  title={sectionInfo.title}
                  subtitle={sectionInfo.subtitle}
                  icon={sectionIndex === 0 ? 'wifi-off' : 'plus-circle'}
                />

                <Box pb={48}>
                  <Stack bgColor={{ base: 'none', md: 'white' }}>
                    {sectionIndex === 0 ? (
                      <OffPlatformDefinitionForm
                        onCancel={handleCancel}
                        onSubmit={handleDefinitionSubmit}
                        measurementUnitOptions={measurementUnitGroupedOptions}
                        initialFormData={definitionFormData}
                        causeOptions={causeOptions}
                        key={sectionIndex}
                      />
                    ) : (
                      <OffPlatformLogForm
                        onCancel={handleCancel}
                        onSubmit={handleLogSubmit}
                        initialLogFormData={initialLogFormData}
                        measurementDefinition={measurementDefinition}
                        isLoading={isLoading}
                        key={sectionIndex}
                      />
                    )}
                  </Stack>
                </Box>
              </>
            )}
          </VStack>
        </HStack>
      </VStack>
    </Stack>
  );
};
