import { yupResolver } from '@hookform/resolvers/yup';
import { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { IModalRefProps } from 'ui/components/Modals/Modal/types';

import { DEFAULT_ECOSYSTEM_ID } from '~/config';
import { SIGN_UP_MESSAGES } from '~/constants/messages.constants';
import { PAGES } from '~/constants/pages.constants';
import { useAppSelector } from '~/hooks/useAppSelector';
import { useRouter } from '~/hooks/useRouter';
import AuthService from '~/services/resources/auth';
import { ISignUpDTO } from '~/types/dtos';

import { schemaValidation } from './constants';
import { ISignUpFormFields } from './types';

export const useSignUpController = () => {
  const { goToRoute, replaceRoute } = useRouter();

  const privacyModalRef = useRef<IModalRefProps>(null);
  const termsModalRef = useRef<IModalRefProps>(null);

  const [isLoading, setIsLoading] = useState(false);

  const { acceptInvite, teamInvite } = useAppSelector(
    ({ redirect }) => redirect,
  );
  const { signInEcosystem, isDefaultSignInEcosystem } = useAppSelector(
    ({ ecosystem }) => ecosystem,
  );

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<ISignUpFormFields>({
    resolver: yupResolver(schemaValidation),
    defaultValues: {
      termsOfService: false,
    },
  });

  const redirectToSignIn = () => {
    replaceRoute(PAGES.Root);
  };

  const onSubmit = handleSubmit(async (data) => {
    try {
      setIsLoading(true);

      const defaultAttributes: ISignUpDTO['attributes'] = {
        name: `${data.firstName} ${data.lastName}`,
        phone_number: '',
        'custom:platform': 'web',
      };

      let response;

      if (acceptInvite.code || teamInvite.code) {
        response = await AuthService.signUpWithEmailAndPassword({
          email: data.email,
          password: data.password.trim(),
          attributes: defaultAttributes,
        });
      } else {
        response = await AuthService.signUpWithEmailAndPassword({
          email: data.email,
          password: data.password.trim(),
          attributes: {
            ...defaultAttributes,
            'custom:ecosystemId': isDefaultSignInEcosystem
              ? DEFAULT_ECOSYSTEM_ID
              : signInEcosystem?._id,
          },
        });
      }

      if (response.user) {
        toast.success('Account has been created!');
        goToRoute(PAGES.AccessCode, {
          state: {
            email: data.email,
            password: data.password.trim(),
          },
        });
      } else {
        throw Error(SIGN_UP_MESSAGES.CREATE_ACCOUNT_ERROR);
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  });

  const handleOpenPrivacyModal = () => privacyModalRef.current?.open();

  const handleOpenTermsModal = () => termsModalRef.current?.open();

  return {
    control,
    errors,
    onSubmit,
    isLoading,
    redirectToSignIn,
    termsModalRef,
    privacyModalRef,
    handleOpenPrivacyModal,
    handleOpenTermsModal,
  };
};
