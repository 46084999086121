import { Spinner, Stack } from 'native-base';

import { LoadingProps } from './types';

export const DEFAULT_BACKGROUND_COLOR = 'rgba(255,255,255,0.7)';

export const Loading = ({
  color,
  height,
  width,
  float = false,
  spinnerSize = 'lg',
  backgroundColor = DEFAULT_BACKGROUND_COLOR,
  showSpinner = true,
}: LoadingProps) => {
  return (
    <Stack
      position={float ? 'absolute' : 'relative'}
      top={0}
      left={0}
      w={width || 'full'}
      h={height || 'full'}
      justifyContent="center"
      backgroundColor={backgroundColor}
      zIndex={9999}
    >
      {showSpinner && <Spinner size={spinnerSize} color={color} />}
    </Stack>
  );
};

export default Loading;
