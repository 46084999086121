import { Hidden, HStack, Pressable, Text } from 'native-base';
import Illustration, { ILLUSTRATIONS_NAME } from 'ui/components/Illustration';
import { colors } from 'ui/theme/colors';

export type ProfileProgressPlaceHolderSnippetProps = {
  text: string;
  onPress?: VoidFunction;
};
export const ProfileProgressPlaceHolderSnippet = ({
  text,
  onPress,
}: ProfileProgressPlaceHolderSnippetProps) => {
  return (
    <Pressable w="full" h="full" flex={1} onPress={onPress}>
      <HStack
        w="full"
        h="full"
        alignItems={'center'}
        justifyContent="space-between"
        space={3}
      >
        <Text
          flex={1}
          fontWeight={400}
          lineHeight={'150%'}
          color={colors.gray[800]}
          fontSize={'xs'}
          numberOfLines={3}
        >
          {text}
        </Text>

        <Hidden till="md">
          <Illustration
            name={ILLUSTRATIONS_NAME.TASK_MANAGEMENT_LIST}
            resizeMode="contain"
            h={'64px'}
            w={'84PX'}
          />
        </Hidden>
      </HStack>
    </Pressable>
  );
};
