import { Controller } from 'react-hook-form';

import BaseSelectGroup from '../BaseSelectGroup';
import { IControlledSelectGroupProps } from './types';

export const ControlledSelectGroup = (props: IControlledSelectGroupProps) => {
  const {
    label,
    name = '',
    helpText,
    errorMessage,
    isRequired = false,
    control,
    options,
    uniqueOptions,
  } = props;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value: values } }) => {
        return (
          <BaseSelectGroup
            options={options}
            uniqueOptions={uniqueOptions}
            values={values}
            onChange={onChange}
            label={label}
            errorMessage={errorMessage}
            isRequired={isRequired}
            helpText={helpText}
          />
        );
      }}
    />
  );
};

export default ControlledSelectGroup;
