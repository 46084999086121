import Yup from '~/utils/validations/yup';

import { IChangePasswordFields } from './types';

export const defaultValues: IChangePasswordFields = {
  currentPassword: '',
  newPassword: '',
};

export const schemaValidation = Yup.object({
  currentPassword: Yup.string().required(),
  newPassword: Yup.string().required(),
});
