export enum CalendarDayState {
  muted = 2,
  blocked = 3,
}

export type CalendarDayConfig = {
  backgroundColor: string;
  borderColor: string;
  fontColor: string;
  tooltipLabel: string;
};
