import { useCallback, useEffect, useRef, useState } from 'react';

import {
  IInputSearchLocationInline,
  LocationObject,
  SugestionObject,
} from './types';

export const useInputSearchLocationInlineController = (
  props: Pick<
    IInputSearchLocationInline,
    'location' | 'onFetch' | 'onDetail' | 'onSelect'
  >,
) => {
  const { onFetch, onSelect, onDetail } = props;
  const [inputValue, setInputValue] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuggestionsLoading, setIsSuggestionsLoading] = useState(false);
  const [suggestions, setSuggestions] = useState<SugestionObject[]>([]);
  const isFetching = useRef(false);
  const debounceTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (!inputValue) return;

    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    debounceTimeoutRef.current = setTimeout(() => {
      if (isFetching.current) return;
      isFetching.current = true;
      setIsSuggestionsLoading(true);

      onFetch(inputValue)
        .then(setSuggestions)
        .finally(() => {
          setIsSuggestionsLoading(false);
          setTimeout(() => {
            isFetching.current = false;
          }, 150); // small buffer to avoid race condition
        });
    }, 300);

    return () => {
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }
    };
  }, [onFetch, inputValue]);

  const handleInputChange = (value: string) => {
    if (props.location) {
      onSelect(null);
    }
    setInputValue(value);
  };

  const handleClear = () => {
    onSelect(null);
    setInputValue('');
  };

  const handleSelection = useCallback(
    async (suggestion?: SugestionObject | null) => {
      setIsSubmitting(true);
      try {
        if (!suggestion?.placeId) throw new Error('Place id not found');

        const data = await onDetail(suggestion.placeId);
        if (!data) throw new Error('Geocode not found');

        const result: LocationObject = {
          lat: data.lat,
          lng: data.lng,
          placeID: suggestion.placeId,
          rawLocation: suggestion.description,
        };
        onSelect(result);
      } catch (error) {
        onSelect(null);
        throw error;
      } finally {
        setIsSubmitting(false);
      }
    },
    [onSelect, onDetail],
  );

  return {
    isSuggestionsLoading,
    inputValue,
    suggestions,
    isSubmitting,
    handleInputChange,
    handleSelection,
    handleClear,
  };
};
