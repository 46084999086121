import { FlatList, Stack, Text } from 'native-base';
import {
  ActivityTypeEnum,
  EEventApplicationType,
  MeasurementCategory,
} from 'ui/enums';
import { getMeasurementTotalLogged } from 'ui/utils/getMeasurementTotalLogged';
import {
  mapCompletionMeasurements,
  mapOtherMeasurements,
} from 'ui/utils/measurements';
import { secondsToHoursAndMinutes } from 'ui/utils/secondsToDuration';

import { ActivityCardRecommendationsSkeletonList } from '~/components/ActivityCards/CardRecommendations';
import { LogActivityCard } from '~/components/LogActivityCard';
import ModalRemove from '~/components/ModalRemove';
import { ActivitiesToLogEmptyState } from '~/pages/Authenticated/LogActivities/components/ActivitiesToLogEmptyState';
import { IActivityApplication } from '~/types/interfaces/activity';
import { IOrganization } from '~/types/interfaces/organization';
import { getLogAvailabiltyByApplication } from '~/utils/activityAvailability/get-log-availability';

import { useActivitiesToDoController } from './activitiesToDoController';
import { ActivitiesToDoProps } from './types';

export const ActivitiesToDo = (props: ActivitiesToDoProps) => {
  const { activityApplications, isLoading } = props;
  const {
    goToRoute,
    getHeaderText,
    handleDeleteMeasurement,
    isEditPressed,
    onPressCancel,
    isSubmitting,
    toggleIsEditPressed,
    modalRef,
    handleCloseModal,
  } = useActivitiesToDoController(props);

  return (
    <>
      {isLoading ? (
        <Stack flexShrink={0} mt={4}>
          <ActivityCardRecommendationsSkeletonList quantity={3} />
        </Stack>
      ) : !!activityApplications?.length && activityApplications?.length > 0 ? (
        <FlatList
          data={activityApplications as any}
          keyExtractor={(item) => item._id}
          renderItem={({ item }: { item: IActivityApplication }) => {
            const measurements = item.measurements ?? [];
            const firstMeasurement = measurements[0] || null;

            const { hours, minutes } = secondsToHoursAndMinutes(
              getMeasurementTotalLogged(measurements),
            );

            const measurementType =
              (item.measurements ?? [])[0]?.measurementType ||
              MeasurementCategory.Time;

            const activityType =
              (item?.activitySubDocument?.activityDefinitionSubDocument
                ?.type as ActivityTypeEnum) || '';

            const measurementUnit = firstMeasurement?.measurementUnit;

            const actionAvailabilty = getLogAvailabiltyByApplication(item);
            const isDisabled = !actionAvailabilty.isAvailable;
            const actionToolTipLabel = actionAvailabilty.message;

            const organizationLogo =
              (item.activitySubDocument?.organization as IOrganization)
                ?.logoThumbnail || '';

            const isTeam =
              item.activitySubDocument.activityDefinitionSubDocument
                .eventApplicationType === EEventApplicationType.Team;

            return (
              <Stack mt={4} flexShrink={0}>
                <LogActivityCard
                  actionToolTipLabel={actionToolTipLabel}
                  activityName={
                    item?.activitySubDocument?.activityDefinitionSubDocument
                      ?.title || ''
                  }
                  goalValue={
                    item?.activitySubDocument?.activityDefinitionSubDocument
                      ?.targetAmount || 0
                  }
                  goalCategory={measurementType}
                  activityType={
                    (item?.activitySubDocument?.activityDefinitionSubDocument
                      ?.type as ActivityTypeEnum) || ''
                  }
                  isTeam={isTeam}
                  organizationLogo={organizationLogo}
                  organizationName={
                    item?.activitySubDocument?.activityDefinitionSubDocument
                      ?.organizationSubDocument?.name || ''
                  }
                  appName={item?.app?.name || ''}
                  headerText={getHeaderText(item?.activitySubDocument)}
                  goalProgress={
                    measurementType === MeasurementCategory.Completion
                      ? firstMeasurement?.steps?.length || 0
                      : getMeasurementTotalLogged(measurements)
                  }
                  goalUnit={
                    item?.activitySubDocument?.activityDefinitionSubDocument
                      ?.measurementUnitPluralLabel || ''
                  }
                  listType={measurementType}
                  steps={firstMeasurement?.steps || []}
                  items={
                    measurementType === MeasurementCategory.Completion
                      ? mapCompletionMeasurements(
                          measurementUnit,
                          firstMeasurement,
                        )
                      : mapOtherMeasurements(
                          measurements,
                          measurementUnit,
                          onPressCancel,
                        )
                  }
                  totalStepsLogged={firstMeasurement?.steps?.length || 0}
                  onGoingActivityTotalHours={
                    measurementType === MeasurementCategory.Time
                      ? `${hours}h ${minutes}m`
                      : undefined
                  }
                  isAction={activityType === ActivityTypeEnum.Action}
                  isDisabled={isDisabled}
                  isExpandable={(measurements ?? []).length > 0}
                  onLogActivityPress={() =>
                    goToRoute(`/my-wallet/log-activity/${item._id}`)
                  }
                  isEditPressed={isEditPressed}
                  onEditPress={toggleIsEditPressed}
                  isStep={!!firstMeasurement?.steps?.length}
                />
              </Stack>
            );
          }}
        />
      ) : (
        <ActivitiesToLogEmptyState pageTabText="to log" />
      )}

      <ModalRemove
        modalRef={modalRef}
        headerText="Cancel log"
        handleCloseModal={handleCloseModal}
        handleDelete={handleDeleteMeasurement}
        rightBtnLoading={isSubmitting}
        rightBtnText="Cancel"
      >
        <Stack space={5}>
          <Text fontWeight={400} fontSize="md" color="gray.500">
            Are you sure you want to cancel this activity log?
          </Text>
        </Stack>
      </ModalRemove>
    </>
  );
};
