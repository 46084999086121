import { useNavigate } from 'react-router-dom';

import { PAGES } from '~/constants/pages.constants';
import { useActivityViewStore } from '~/pages/Authenticated/v2/ActivityView/store';
import { ApplicationHeaderStyleMap } from '~/pages/Authenticated/v2/ActivityView/views/ActivityApplication/constants/application-style-map';
import { useApplicationAvailabilityController } from '~/pages/Authenticated/v2/ActivityView/views/ActivityApplication/controllers/application-availability-controller';
import { App } from '~/types/interfaces/app';
import { AvailabilityMessageStyle } from '~/utils/activityAvailability/types';

export const useActivityApplicationController = () => {
  const navigate = useNavigate();

  const { activityDefinition, activities, selectedActivity, activityType } =
    useActivityViewStore((state) => state);

  const { applicationAvailability } = useApplicationAvailabilityController({
    activityDefinition,
    activity: selectedActivity,
  });

  const onExternalApplication = () => {
    const selectedActivityId = selectedActivity?._id || '';
    const externalApplyLink = selectedActivity?.externalApplyLink || '';
    const activityAppId = (activityDefinition?.app as unknown as App)._id || '';

    navigate(
      PAGES.ExternalApplyLinkRedirect.replace(':id', selectedActivityId),
      { state: { externalApplyLink, activityAppId } },
    );
  };

  const title = applicationAvailability.message;

  const headerStyle = ApplicationHeaderStyleMap.get(
    applicationAvailability.messageStyle || AvailabilityMessageStyle.SUCCESS,
  );

  return {
    title,
    headerStyle,
    activities,
    activityType,
    selectedActivity,
    activityDefinition,
    applicationAvailability,
    onExternalApplication,
  };
};
