import { Divider, Hidden, HStack, Text, VStack } from 'native-base';
import { CustomDatePicker } from 'ui/components/DateTimePickers/DatePicker';

import { LogCollapse } from '~/components/v2/LogCollapse';
import OffPlatformFormFooter from '~/pages/Authenticated/OffPlatformApplication/components/FormFooter';
import { useOffPlatformLogFormController } from '~/pages/Authenticated/OffPlatformApplication/components/LogForm/controller';
import { OffPlatformLogInput } from '~/pages/Authenticated/OffPlatformApplication/components/OffPlatformLogInput';

import { IOffPlatformLogFormProps } from './types';

export const OffPlatformLogForm = (props: IOffPlatformLogFormProps) => {
  const {
    control,
    formState,
    isLoading,
    measurementFields,
    isEditMode,
    groupedMeasurementsHistory,
    onCancelHandler,
    onSubmitHandler,
  } = useOffPlatformLogFormController(props);

  return (
    <VStack w="full" space={4} p={{ base: 4, md: 6 }}>
      <VStack space={4}>
        <HStack alignItems={'center'} space={6} maxW={'40%'}>
          <Text fontWeight={'500'} fontSize={'sm'}>
            Date
          </Text>
          <CustomDatePicker
            inputProps={{
              height: 12,
              padding: 2,
            }}
            control={control}
            name="measurementDate"
            dateFormat="dd/MM/yyyy"
            maxDate={new Date()}
            showTimeSelect={false}
            errorMessage={formState.errors?.measurementDate?.message || ''}
          />
        </HStack>

        <VStack space="4">
          {measurementFields.map(({ measurementUnit }, index) => (
            <OffPlatformLogInput
              control={control}
              key={index}
              index={index}
              measurementUnit={measurementUnit}
              errorMessage={
                formState.errors?.measurements?.[index]?.amount?.message || ''
              }
            />
          ))}
        </VStack>

        {isEditMode && (
          <VStack space={4} mt={6}>
            <VStack space={2}>
              <Text fontSize="md" fontWeight={500}>
                Activity details
              </Text>
              <Hidden from="lg">
                <Divider width="100%" />
              </Hidden>

              {Object.entries(groupedMeasurementsHistory)?.map(
                ([measurementUnitId, measurements]) => {
                  return (
                    <LogCollapse
                      key={measurementUnitId}
                      measurements={measurements}
                    />
                  );
                },
              )}
            </VStack>
          </VStack>
        )}
      </VStack>

      <OffPlatformFormFooter
        isActionDisabled={!formState.isValid}
        actionLabel="Log"
        isLoading={isLoading}
        onAction={onSubmitHandler}
        onCancel={onCancelHandler}
      />
    </VStack>
  );
};

export default OffPlatformLogForm;
