import { HStack, Stack, Text } from 'native-base';
import Button from 'ui/components/Button';
import { colors } from 'ui/theme/colors';

import { useRouter } from '~/hooks/useRouter';

export type MobileNavigationTopBarProps = { title: string };

export const MobileNavigationTopBar = ({
  title,
}: MobileNavigationTopBarProps) => {
  const { isStackEmpty, goBack } = useRouter();

  const shouldShowBackButton = !isStackEmpty;
  return (
    <HStack
      w="100%"
      bg={'gray.100'}
      py={3}
      px={4}
      alignItems={'center'}
      justifyContent="space-between"
    >
      <Stack minWidth={'77px'}>
        {shouldShowBackButton && (
          <Button
            leftIconColor="singletons.black"
            leftIconName={isStackEmpty ? 'home' : 'chevron-left'}
            _pressed={{ backgroundColor: colors.gray[200] }}
            variant="link"
            minWidth="72px"
            onPress={goBack}
          >
            <Text color="singletons.black" fontSize="xs" fontWeight={500}>
              {isStackEmpty ? 'Home' : 'Back'}
            </Text>
          </Button>
        )}
      </Stack>

      <Stack>
        <Text fontSize={'lg'} color={'gray.600'} fontWeight={500}>
          {title}
        </Text>
      </Stack>
      <Stack minWidth={'77px'} />
    </HStack>
  );
};
