import { useBreakpointValue } from 'native-base';
import { useEffect, useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import logoDoitColorful from 'ui/assets/svgs/doit-logo-colorful.svg';

import { HeaderItem } from '~/components/NavBar/navBar.types';
import { PAGES } from '~/constants/pages.constants';
import { useAppSelector } from '~/hooks/useAppSelector';
import { usePermissions } from '~/hooks/usePermissions';
import { useRefetchUserPermissions } from '~/hooks/useRefetchPermissions';
import { getNameInitials } from '~/utils/getInitials';

export const navbarHeightInPx = 72;

export const useNavBarController = ({ isGuest }: { isGuest?: boolean }) => {
  const { pathname: currentPath } = useLocation();
  const params = useParams<Record<string, string>>();
  const navigate = useNavigate();
  const { user, selectedUserProfile } = useAppSelector((state) => state.auth);
  const {
    selectedEcosystem,
    signInEcosystem,
    isDefaultSignInEcosystem,
    guestEcosystem,
  } = useAppSelector(({ ecosystem }) => ecosystem);

  const { handleUpdateDefaultGuestPermissions } = usePermissions();

  useRefetchUserPermissions();

  const pathWithNoParams = Object.values(params).reduce(
    (path, param) => path?.replace('/' + param, ''),
    currentPath,
  );

  const breakpoints = useBreakpointValue({
    base: { showMobileHeader: true },
    md: { showMobileHeader: false },
  });

  const userParsedData = useMemo(() => {
    return {
      userName: user?.name || '',
      userInitials: getNameInitials(user?.name || ''),
      userAvatar: selectedUserProfile?.profilePicture || '',
      ecosystemLogo: selectedEcosystem?.logo || guestEcosystem.logo,
      ecosystemName: selectedEcosystem?.name || guestEcosystem.name,
      ecosystemInitial: getNameInitials(
        selectedEcosystem?.name || guestEcosystem.name,
      ),
      hasUser: user ? true : false,
    };
  }, [user, selectedUserProfile, selectedEcosystem, guestEcosystem]);

  const handleGoToWalletHome = () => {
    navigate(PAGES.Opportunities);
  };

  const handleGoHome = () => {
    navigate(PAGES.Home);
  };

  const handleGoToSettingsHome = () => {
    navigate('/settings/account-settings');
  };

  const { headerItems } = useMemo(() => {
    const headerItems: HeaderItem[] = [
      {
        icon: 'settings',
        name: 'Settings',
        basePath: 'settings',
        onPress: handleGoToSettingsHome,
        permissions: [],
      },
      {
        basePath: 'my-wallet',
        avatarUrl: userParsedData?.userAvatar,
        avatarInitials: userParsedData?.userInitials,
        name: 'My wallet',
        onPress: handleGoToWalletHome,
        permissions: [],
      },
    ];

    return { headerItems };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userParsedData]);

  const headerLogo = isDefaultSignInEcosystem
    ? logoDoitColorful
    : signInEcosystem?.logo;

  useEffect(() => {
    if (isGuest) {
      handleUpdateDefaultGuestPermissions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGuest]);

  return {
    currentPath,
    userParsedData,
    breakpoints,
    handleGoHome,
    handleGoToSettingsHome,
    headerItems,
    selectedEcosystem,
    pathWithNoParams,
    signInEcosystem,
    headerLogo,
    isDefaultSignInEcosystem,
  };
};
