import { useMemo } from 'react';

import { calculateFilterAppliedCount } from '~/components/Layouts/v2/SearchLayout/utils/calculate-filters-applied-count';
import { SearchRelevanceSortBy } from '~/services/resources/activity-definitions/search/types';
import { DEFAULT_SEARCH_FILTERS, useSearch } from '~/zustand/store/Search';

import { sortByWhiteListMap } from './utils';

export const useSearchFeedHeaderController = () => {
  const { appliedFilters } = useSearch((state) => state);

  const applyFilters = useSearch((state) => state.applyFilters);
  const openFilterModal = useSearch((state) => state.openFilterModal);

  const handleOpenFilterModal = () => {
    openFilterModal();
  };

  const handleChangeSortByValue = (sortByValue: SearchRelevanceSortBy) => {
    applyFilters({ relevance: sortByValue });
  };

  const sortByOptions = useMemo(() => {
    return sortByWhiteListMap.filterMatchingKeys({
      activityTypes: appliedFilters.activityTypes,
      attendanceTypes: appliedFilters.attendanceTypes,
    });
  }, [appliedFilters]);

  const filtersCount = useMemo(() => {
    return calculateFilterAppliedCount(appliedFilters, DEFAULT_SEARCH_FILTERS);
  }, [appliedFilters]);

  const sortByValue = appliedFilters.relevance;

  return {
    filtersCount,
    sortByValue,
    sortByOptions,
    handleOpenFilterModal,
    handleChangeSortByValue,
  };
};
