import { HomeWidgetBase, WIDGET_COLORS } from '../Base';
import { useHomeWidgetYourAppsController } from './controller';
import { YourAppsSnippets } from './Snippets';

export const HomeWidgetYourApps = () => {
  const { data, onCardPress, onAppPress } = useHomeWidgetYourAppsController({});

  return (
    <HomeWidgetBase
      minWidth={'240px'}
      maxWidth={'240px'}
      title="Your apps"
      headerBackgroundColor={WIDGET_COLORS.gray}
      onPress={onCardPress}
    >
      <YourAppsSnippets.List apps={data.apps} max={4} onAppPress={onAppPress} />
    </HomeWidgetBase>
  );
};
