import { HomeWidgetBase, WIDGET_COLORS } from '../Base';
import { useHomeWidgetVolunteeringHoursController } from './controller';
import { VolunteeringHoursTabsFactory } from './Factory';

export type HomeWidgetVolunteeringHoursProps = {};

export const HomeWidgetVolunteeringHours =
  ({}: HomeWidgetVolunteeringHoursProps) => {
    const { title, data, isLoading, defaultTabIndex, onCardPress } =
      useHomeWidgetVolunteeringHoursController({});

    return (
      <HomeWidgetBase
        title={title}
        maxWidth={'360px'}
        isLoading={isLoading}
        minWidth={{ base: '252px', md: '360px' }}
        headerBackgroundColor={WIDGET_COLORS.green}
        defaultTabIndex={defaultTabIndex}
        key={defaultTabIndex}
        onPress={onCardPress}
      >
        {VolunteeringHoursTabsFactory({
          data,
          onPress: onCardPress,
        })}
      </HomeWidgetBase>
    );
  };
