import { Box } from 'native-base';
import Icon from 'ui/components/Icon';
import { colors } from 'ui/theme/colors';

import ActivityLocationSearch from '~/pages/Authenticated/v2/ActivityView/components/ActivitySearch/ActivityLocationSearch';
import { useActivityLocationSelectorController } from '~/pages/Authenticated/v2/ActivityView/components/ActivitySelectors/ActivityLocationSelector/controller';
import { ActivityLocationSelectorProps } from '~/pages/Authenticated/v2/ActivityView/components/ActivitySelectors/ActivityLocationSelector/types';
import ActivitySelectorCardBase from '~/pages/Authenticated/v2/ActivityView/components/ActivitySelectors/ActivitySelectorCardBase';

export const ActivityLocationSelector = (
  props: ActivityLocationSelectorProps,
) => {
  const { availableLocations, selectedLocation, isFromHome = false } = props;

  const {
    title,
    isOpen,
    tagLabel,
    hideSelector,
    cardExtraLabels,
    handleLocationSelect,
    onClose,
    onOpen,
  } = useActivityLocationSelectorController(props);

  return (
    <ActivitySelectorCardBase
      title={title}
      cardName="location"
      titlePlaceHolder="Select desired location"
      tagLabel={tagLabel}
      labels={cardExtraLabels}
      trailingContent={
        <Icon
          icon="map-pin"
          size={'24px'}
          color={selectedLocation ? colors.black : colors.gray[400]}
        />
      }
      showPopover={isOpen}
      closePopover={onClose}
      openPopover={onOpen}
      hideSelector={hideSelector}
      popoverContent={
        <Box w={'396px'} maxH="360px" minH={'240px'}>
          {!isFromHome && (
            <ActivityLocationSearch
              key={availableLocations.length}
              locations={availableLocations}
              selectedLocation={selectedLocation}
              onLocationChange={handleLocationSelect}
            />
          )}
        </Box>
      }
    />
  );
};

export default ActivityLocationSelector;
