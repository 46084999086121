import { ActivityRegion } from 'ui/types/activities';

import { useQuery } from '~/hooks/useQuery';
import { IPaginatedResponse } from '~/types';

export const useActivityRegions = () => {
  const { data, isLoading, refetch } = useQuery<
    IPaginatedResponse<ActivityRegion[]>
  >(`/activity-region-public`, {
    key: `activity-region-public-regions`,
    requestOptions: {
      params: {
        limit: 1000,
      },
    },
  });

  return { data, isLoading, refetch };
};
