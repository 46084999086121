import { Slider } from '../Base';
import { SliderOnChangeFunction } from '../types';
import { SingleSliderProps } from './types';

export const SingleSlider = (props: SingleSliderProps) => {
  const { value, onChange, onChangeComplete } = props;

  const handleChange = (newValue: number) => {
    return onChange(newValue);
  };

  return (
    <Slider
      {...props}
      value={value}
      onChange={handleChange as SliderOnChangeFunction}
      onChangeComplete={onChangeComplete as SliderOnChangeFunction}
    />
  );
};

export default SingleSlider;
