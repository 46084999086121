import { sanitizeQueryParams } from 'ui/utils/queryParams';

import { publicApi } from '~/services/api';
import { ACTIVITY_DEFINITION_SEARCH_MODULE_URL } from '~/services/resources/activity-definitions/search/constants';
import { ActivityDefinitionPublicSearchResponse } from '~/services/resources/activity-definitions/search/types';

import { ActionSearchFilters } from './actions-public-search.types';

export class ActivityDefinitionActionsPublicSearch {
  static BASE_URL = `${ACTIVITY_DEFINITION_SEARCH_MODULE_URL}/action`;

  static async findActions(
    filters: ActionSearchFilters,
  ): Promise<ActivityDefinitionPublicSearchResponse[]> {
    try {
      const url = ActivityDefinitionActionsPublicSearch.BASE_URL;
      const queryParams = sanitizeQueryParams(filters as Record<string, any>);
      const { data } = await publicApi.get(url, {
        params: queryParams,
      });

      return data || [];
    } catch (error) {
      return [];
    }
  }
}
