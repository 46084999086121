import { Coordinates } from 'ui/types/interfaces';
import { mapSearchActivityDefinition } from 'ui/utils/activity/mappers';

import { SEARCH_COLLECTION_CONFIG_MAP } from '~/components/Layouts/v2/SearchLayout/constants/search-collections';
import { ActivityCarouselDataSource } from '~/components/v2/ActivityCarousel/types';
import { ActivityDefinitionActionsPublicSearch as ActionSearchApi } from '~/services/resources/activity-definitions/search/actions/actions-public-search';
import { ActivityDefinitionEventsPublicSearch as EventsSearchApi } from '~/services/resources/activity-definitions/search/events/events-public-search';
import { ActivityDefinitionOngoingPublicSearch as OngoingSearchApi } from '~/services/resources/activity-definitions/search/ongoing/ongoing-public-search';
import { SearchCollectionSlug } from '~/types/interfaces/activity-search/types';
import { mapActionsSearchApiFilters } from '~/utils/activity-search/map-actions-search-api-filters';
import {
  mapEventFromHomeSearchApiFilters,
  mapEventInPersonSearchApiFilters,
} from '~/utils/activity-search/map-events-search-api-filters';
import {
  mapFromHomeOngoingSearchApiFilters,
  mapInPersonOngoingSearchApiFilters,
} from '~/utils/activity-search/map-ongoing-search-api-filters';

export const DEFAULT_CAROUSEL_LIMIT = 10;
export const generateCarouselCollectionsDataSource = (
  appIds: string[],
  userPosition: Coordinates,
) => {
  /* In Person events */
  let inPersonEventsDataSource = null;
  const eventsFilters = SEARCH_COLLECTION_CONFIG_MAP.get(
    SearchCollectionSlug.Events,
  )?.filters;
  if (eventsFilters) {
    const filters = {
      ...mapEventInPersonSearchApiFilters(appIds, userPosition, eventsFilters),
      limit: DEFAULT_CAROUSEL_LIMIT,
    };

    inPersonEventsDataSource = new ActivityCarouselDataSource(
      EventsSearchApi.findInPersonEvents(filters).then(
        mapSearchActivityDefinition(userPosition),
      ),
      [SearchCollectionSlug.Events, JSON.stringify(filters)],
    );
  }

  /* Actions */
  let actionsDataSource = null;
  const actionsFilters = SEARCH_COLLECTION_CONFIG_MAP.get(
    SearchCollectionSlug.Actions,
  )?.filters;

  if (actionsFilters) {
    const filters = {
      ...mapActionsSearchApiFilters(appIds, actionsFilters),
      limit: DEFAULT_CAROUSEL_LIMIT,
    };

    actionsDataSource = new ActivityCarouselDataSource(
      ActionSearchApi.findActions(filters).then(
        mapSearchActivityDefinition(userPosition),
      ),
      [SearchCollectionSlug.Actions, JSON.stringify(filters)],
    );
  }

  /* Ongoing */
  let inPersonOngoingDataSource = null;
  const ongoingFilters = SEARCH_COLLECTION_CONFIG_MAP.get(
    SearchCollectionSlug.Ongoing,
  )?.filters;

  if (ongoingFilters) {
    const filters = {
      ...mapInPersonOngoingSearchApiFilters(
        appIds,
        userPosition,
        ongoingFilters,
      ),
      limit: DEFAULT_CAROUSEL_LIMIT,
    };

    inPersonOngoingDataSource = new ActivityCarouselDataSource(
      OngoingSearchApi.findInPersonOngoing(filters).then(
        mapSearchActivityDefinition(userPosition),
      ),
      [SearchCollectionSlug.Ongoing, JSON.stringify(filters)],
    );
  }

  /* From home events */
  let fromHomeEventsDataSource = null;
  const eventsFromHomeFilters = SEARCH_COLLECTION_CONFIG_MAP.get(
    SearchCollectionSlug.EventsFromHome,
  )?.filters;
  if (eventsFromHomeFilters) {
    const filters = {
      ...mapEventFromHomeSearchApiFilters(
        appIds,
        userPosition,
        eventsFromHomeFilters,
      ),
      limit: DEFAULT_CAROUSEL_LIMIT,
    };

    fromHomeEventsDataSource = new ActivityCarouselDataSource(
      EventsSearchApi.findFromHomeEvents(filters).then(
        mapSearchActivityDefinition(userPosition),
      ),
      [SearchCollectionSlug.Events, JSON.stringify(filters)],
    );
  }

  /* Team events */
  let teamEventsEventsDataSource = null;
  const teamEventsFilters = SEARCH_COLLECTION_CONFIG_MAP.get(
    SearchCollectionSlug.TeamEvents,
  )?.filters;
  if (teamEventsFilters) {
    const filters = {
      ...mapEventInPersonSearchApiFilters(
        appIds,
        userPosition,
        teamEventsFilters,
      ),
      limit: DEFAULT_CAROUSEL_LIMIT,
    };

    teamEventsEventsDataSource = new ActivityCarouselDataSource(
      EventsSearchApi.findInPersonEvents(filters).then(
        mapSearchActivityDefinition(userPosition),
      ),
      [SearchCollectionSlug.TeamEvents, JSON.stringify(filters)],
    );
  }

  /* From home ongoing */
  let fromHomeOngoingDataSource = null;
  const remoteOngoingFilters = SEARCH_COLLECTION_CONFIG_MAP.get(
    SearchCollectionSlug.RemoteOngoing,
  )?.filters;

  if (remoteOngoingFilters) {
    const filters = {
      ...mapFromHomeOngoingSearchApiFilters(appIds, remoteOngoingFilters),
      limit: DEFAULT_CAROUSEL_LIMIT,
    };

    fromHomeOngoingDataSource = new ActivityCarouselDataSource(
      OngoingSearchApi.findFromHomeOngoing(filters).then(
        mapSearchActivityDefinition(userPosition),
      ),
      [SearchCollectionSlug.RemoteOngoing, JSON.stringify(filters)],
    );
  }

  return new Map<SearchCollectionSlug, ActivityCarouselDataSource | null>([
    [SearchCollectionSlug.Events, inPersonEventsDataSource],
    [SearchCollectionSlug.Actions, actionsDataSource],
    [SearchCollectionSlug.TeamEvents, teamEventsEventsDataSource],
    [SearchCollectionSlug.Ongoing, inPersonOngoingDataSource],
    [SearchCollectionSlug.EventsFromHome, fromHomeEventsDataSource],
    [SearchCollectionSlug.RemoteOngoing, fromHomeOngoingDataSource],
  ]);
};
