import { Input as UIInput } from 'native-base';

import CommonInputWrapper from '../../../CommonInputWrapper';
import { InputSize } from '../../types';
import { IBaseTextInputProps } from './types';

export const BaseTextInput = (props: IBaseTextInputProps) => {
  const {
    placeholder,
    errorMessage,
    helpText,
    isDisabled,
    isRequired,
    label,
    value,
    size = InputSize.md,
    fontSize = 16,
    keyboardType,
    isReadOnly = false,
    onKeyPress,
    onChange,
    onBlur,
  } = props;

  return (
    <CommonInputWrapper
      label={label}
      isRequired={isRequired}
      isDisabled={isDisabled}
      errorMessage={errorMessage}
      helpText={helpText}
    >
      <UIInput
        width={'100%'}
        value={value}
        height={size}
        isReadOnly={isReadOnly}
        backgroundColor="white"
        onChange={(event: any) => onChange?.(event?.target?.value)}
        type={'text'}
        borderColor={errorMessage ? 'error.400' : undefined}
        fontSize={fontSize}
        placeholder={placeholder}
        onBlur={onBlur}
        onKeyPress={onKeyPress}
        keyboardType={keyboardType}
        isDisabled={isDisabled}
      />
    </CommonInputWrapper>
  );
};
