const DEFAULT_SHARE_MESSAGE = 'Hi! Check out this amazing activity';

export const makeWhatsappShareOption = (activityLink: string) => {
  const encodedMessage = encodeURIComponent(
    `${DEFAULT_SHARE_MESSAGE}: ${activityLink}`,
  );

  const href = `https://wa.me/?text=${encodedMessage}`;
  return {
    title: 'Share by whatsApp',
    icon: {
      icon: 'whatsapp',
      isCustom: true,
      color: 'black',
      size: 4,
    },
    link: { href },
  };
};

export const makeEmailShareOption = (activityLink: string) => {
  const encodedSubject = encodeURIComponent(`${DEFAULT_SHARE_MESSAGE}`);
  const encodedBody = encodeURIComponent(activityLink);
  const href = `mailto:?subject=${encodedSubject}&body=${encodedBody}`;
  return {
    title: 'Share by email',
    icon: { icon: 'mail', color: 'black', size: 16 },
    link: { href },
  };
};
