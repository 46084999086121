import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import { ENVIRONMENT, SENTRY_DNS_KEY } from '~/config';

if (ENVIRONMENT !== 'development') {
  Sentry.init({
    dsn: SENTRY_DNS_KEY,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}
