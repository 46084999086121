import { Flex } from 'native-base';

import { NavBar } from '~/components/NavBar';
import { navbarHeightInPx } from '~/components/NavBar/navBar.controller';

export const UnauthLayout = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  return (
    <Flex bg={'gray.100'} h={'full'}>
      <Flex
        flex={1}
        h={{
          base: `calc(100vh - ${navbarHeightInPx}px)`,
        }}
        minH="100vh"
        flexShrink={0}
      >
        <NavBar />
        <Flex flex={1} bg={'gray.100'} h={'full'}>
          {children}
        </Flex>
      </Flex>
    </Flex>
  );
};
