import './styles.css';

import { Pressable } from 'native-base';

export const CustomCalender = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className={'custom-calendar-container'}>
      <div style={{ position: 'relative' }}>{children}</div>
    </div>
  );
};

export const CustomInput = ({ children }: { children: React.ReactNode }) => {
  return <Pressable>{children}</Pressable>;
};
