import { useAppSelector } from '~/hooks/useAppSelector';
import { useQuery } from '~/hooks/useQuery';
import { InviteInformation } from '~/types/interfaces/inviteMembers';

export const useLoadUsedMemberInvite = (code = '', email = '') => {
  const { isAuthenticated } = useAppSelector(({ auth }) => auth);

  const { data, isLoading } = useQuery<InviteInformation>(
    `invite-members/used`,
    {
      requestOptions: {
        params: {
          code,
          email,
        },
      },
      queryOptions: { enabled: !!code && !!isAuthenticated && !!email },
      key: `used-member-invite-${code}-${email}`,
    },
  );

  return { data, isLoading };
};
