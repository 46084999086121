import { CustomLogInputProps } from './types';

export const useCustomLogInputController = ({
  onChange,
}: CustomLogInputProps) => {
  const handleOnChange = (value: string) => {
    const inputValue = value.replace(/\D/g, '');
    const numberValue = parseInt(inputValue);

    if (isNaN(numberValue)) {
      onChange?.(0);
      return;
    }

    onChange?.(numberValue);
  };

  return { handleOnChange };
};
