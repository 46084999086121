import { useMemo, useRef } from 'react';
import { IModalRefProps } from 'ui/components/Modals/Modal/types';
import { DropDownImperativeRef } from 'ui/components/v2/DropDown/Base/types';

import {
  AccountData,
  AccountMenuOption,
} from '~/components/NavBar/components/AccountDropdown/types';
import { PAGES } from '~/constants/pages.constants';
import { useVolunteerUser } from '~/hooks/user/useVolunteerUser';
import { useRouter } from '~/hooks/useRouter';
import { getNameInitials } from '~/utils/getInitials';

import { menuOptionsMap } from './MenuOptionsMap/MenuOptionsMap';

export const useAccountDropdownController = () => {
  // -- Providers
  const {
    user,
    isAdmin,
    userAppsIds,
    userProfile,
    userProfileRole,
    userOrganization,
    availableUserProfiles,
  } = useVolunteerUser();
  const { goToRoute } = useRouter();

  // --States
  const modalRef = useRef<IModalRefProps>(null);
  const dropDownImperativeRef = useRef<DropDownImperativeRef>(null);

  // Hooks
  const accountData: AccountData = useMemo(() => {
    const userName = user?.name || '';

    const userNameInitials = getNameInitials(userName);
    const userAvatarUrl = userProfile?.profilePicture || '';

    const organizationName = userOrganization?.name || '';
    const organizationLogoUrl = userOrganization?.logo || '';
    const organizationNameInitials = getNameInitials(organizationName);

    const accountData: AccountData = {
      userName,
      userAvatarUrl,
      userNameInitials,
      organizationName,
      organizationLogoUrl,
      organizationNameInitials,
      userProfileRole,
    };
    return accountData;
  }, [user, userProfileRole, userProfile, userOrganization]);

  const optionGroups = useMemo(() => {
    const groups = menuOptionsMap.getOptionsGroups({
      appsCount: userAppsIds?.length,
      userProfileRole,
      isAdmin,
    });

    return groups;
  }, [isAdmin, userAppsIds, userProfileRole]);

  // Handlers
  const handleCloseSignOutModal = () => {
    modalRef.current?.close();
  };

  const handleOpenSignOutModal = () => {
    modalRef.current?.open();
  };

  const handleOptionPress = (options: AccountMenuOption) => {
    dropDownImperativeRef.current?.close();

    if (options.action === 'external') {
      window.open(options.path, '_blank');
      return;
    } else if (options.action === 'custom') {
      if (options.key === 'sign_out') handleOpenSignOutModal();
      return;
    }
    goToRoute(options.path);
  };

  const handleSwitchPersonaPress = () => {
    goToRoute(PAGES.SwitchPersona);
  };

  const showSwitchPersonaButton = availableUserProfiles?.length > 1;

  return {
    modalRef,
    accountData,
    optionGroups,
    dropDownImperativeRef,
    showSwitchPersonaButton,
    handleSwitchPersonaPress,
    handleCloseSignOutModal,
    handleOpenSignOutModal,
    handleOptionPress,
    goToRoute,
  };
};
