import { Hidden, VStack } from 'native-base';

import { useLoadResourceController } from '~/components/Layouts/v2/SearchLayout/controller.ts/resources-controller';
import { MobileAppBar } from '~/components/MobileAppBar/MobileAppBar';
import useScrollRestoration from '~/hooks/useRouter/scroll-restoration';

import { LayoutModals } from './Layers/Modals/LayoutModals';
import { LayoutNavBar } from './Layers/NavBar';

export const SearchLayout = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  useLoadResourceController();
  useScrollRestoration();

  return (
    <VStack flex={1} bg={'gray.100'} minH={'100vh'}>
      {/* Top bar */}
      <LayoutNavBar />

      {/* Content */}
      {children}

      {/* Footer */}
      <Hidden from="md">
        <MobileAppBar />
      </Hidden>

      {/* Overlays */}
      <LayoutModals />
    </VStack>
  );
};

export default SearchLayout;
