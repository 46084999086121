import { HStack, Text, VStack } from 'native-base';
import Tag from 'ui/components/Tags/Tag';
import { MappedRegions } from 'ui/types/activities';

export const RegionTag = (props: { mappedRegion: MappedRegions }) => {
  const { mappedRegion } = props;
  return (
    <VStack space={2} mt={4}>
      <Text fontSize="md">{mappedRegion.mainRegionData.displayName}</Text>

      <HStack flexWrap="wrap">
        {!mappedRegion.hasAllRegions ? (
          (mappedRegion.relatedRegions ?? []).map((relatedRegion) => (
            <Tag text={relatedRegion.displayName} key={relatedRegion._id} />
          ))
        ) : (
          <Tag text={`All of ${mappedRegion.mainRegionData.displayName}`} />
        )}
      </HStack>
    </VStack>
  );
};
