import { StylesConfig } from 'react-select';

import { theme } from '../../../../../theme';
import { colors } from '../../../../../theme/colors';
import { InputSize } from '../../../inputs/types';

export const getSelectBaseStyle = ({
  hasError,
  size = InputSize.md,
  isMulti = false,
  hasLeadingIcon = false,
}: {
  hasError: boolean;
  size?: InputSize;
  isMulti?: boolean;
  hasLeadingIcon?: boolean;
}): StylesConfig => {
  const errorColor = colors.error[600];
  const borderColor = hasError ? errorColor : colors.gray[300];

  return {
    container: (baseStyles) => ({
      ...baseStyles,
      outline: 'none',
      border: 'none',
      width: '100%',
    }),
    control: (baseStyles, state) => ({
      ...baseStyles,
      fontFamily: theme.fontConfig.default,
      fontSize: 16,
      borderRadius: 12,
      width: '100%',
      height: isMulti ? 'auto' : size,
      minHeight: size,
      margin: 0,
      padding: 0,
      boxShadow: state.isFocused ? '0 0 0 2px black' : 'none',
      borderColor: state.isFocused ? 'transparent' : borderColor,
      '&:hover': {
        outline: 'none',
      },
      '&:active': {
        outline: 'none',
        border: 'none',
      },
    }),
    valueContainer: (baseStyles) => ({
      ...baseStyles,
      padding: 6,
      paddingLeft: hasLeadingIcon ? 32 : 16,
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    menuList: (baseStyles) => ({
      ...baseStyles,
      fontFamily: theme.fontConfig.default,
      fontSize: 14,
    }),
    menuPortal: (baseStyles) => ({
      ...baseStyles,
      fontFamily: theme.fontConfig.default,
      fontSize: 14,
      Index: 9999,
    }),
    option: (baseStyles) => ({
      ...baseStyles,
      fontSize: 14,
    }),
  };
};
