import { Outlet, Route } from 'react-router-dom';

import SearchLayout from '~/components/Layouts/v2/SearchLayout';
import { PAGES } from '~/constants/pages.constants';
import { Home } from '~/pages/Authenticated/v2/Home';
import Search from '~/pages/Authenticated/v2/Search';

export const MultipleAppsPersonaRoutes = () => {
  return (
    <>
      <Route
        element={
          <SearchLayout>
            <Outlet />
          </SearchLayout>
        }
      >
        <Route path={PAGES.Home} element={<Home />} />

        <Route path={PAGES.Search}>
          <Route index element={<Search />} />
          <Route path=":collection" element={<Search />} />
        </Route>
      </Route>
    </>
  );
};
