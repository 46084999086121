import { Pressable } from 'native-base';
import { ReactNode } from 'react';

import { BannerSharedProps } from '../types';

export interface BannerBaseProps extends BannerSharedProps {
  children?: ReactNode;
}

export const BannerBase = ({
  show = false,
  backgroundColor,
  children,
  maxWidth,
  onPress,
}: BannerBaseProps) => {
  const handleBannerPress = () => {
    onPress?.();
  };

  if (!show) return null;

  return (
    <Pressable
      w="full"
      flexDir={'column'}
      maxWidth={maxWidth}
      overflow={'hidden'}
      borderRadius={'12px'}
      py={4}
      px={{ base: 4, md: 6 }}
      bgColor={backgroundColor}
      onPress={handleBannerPress}
    >
      {children}
    </Pressable>
  );
};
