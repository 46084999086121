import { HStack, Text, useBreakpointValue } from 'native-base';
import React, { useEffect, useMemo, useState } from 'react';
import Icon from 'ui/components/Icon';
import { colors } from 'ui/theme/colors';

import { ActivityTypeBadgeStyleMap, DEFAULT_BADGE_STYLE } from './constants';
import { ActivityTypeStampProps } from './types';

export const ActivityTypeBadge = (props: ActivityTypeStampProps) => {
  const { activityType, defaultSize = 'md', customName } = props;

  const [size, setSize] = useState(defaultSize);

  const style = useMemo(() => {
    return ActivityTypeBadgeStyleMap.get(activityType) ?? DEFAULT_BADGE_STYLE;
  }, [activityType]);

  const isMobile = useBreakpointValue({
    base: true,
    md: false,
  });

  useEffect(() => {
    setSize(isMobile ? 'sm' : defaultSize);
  }, [isMobile, defaultSize]);

  const iconSize = size === 'sm' ? '12px' : '16px';
  const fontSize = size === 'sm' ? '10px' : 'xs';

  return (
    <HStack
      borderRadius={'100px'}
      py={1}
      px={2}
      backgroundColor={style.backgroundColor}
      alignItems={'center'}
      space={1}
    >
      <Icon
        enableAutoCustom
        icon={style.icon}
        size={iconSize}
        color={colors.darkText}
      />
      <Text
        fontSize={fontSize}
        fontWeight={500}
        color={colors.darkText}
        noOfLines={1}
      >
        {`${customName || style?.text}`}
      </Text>
    </HStack>
  );
};

export default React.memo(ActivityTypeBadge);
