import { Stack } from 'native-base';
import { ReactNode } from 'react';

interface NotificationCardProps {
  children?: JSX.Element | ReactNode;
}

export const NotificationCard = ({ children }: NotificationCardProps) => {
  return (
    <Stack
      bgColor="white"
      borderRadius="2xl"
      borderWidth={1}
      borderColor="gray.300"
      minH="7.375rem"
      height="100%"
      shadow={2}
      width="400px"
      borderTopWidth={12}
      borderTopColor="gray.600"
      padding={4}
      alignItems="center"
      space={4}
    >
      {children}
    </Stack>
  );
};
