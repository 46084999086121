import { Address } from 'ui/types/interfaces/address';
import {
  Organization,
  OrganizationDepartment,
} from 'ui/types/interfaces/organization';

import { IEcosystem } from './ecosystem';
import { IAppPermissionGroups } from './permission';

/* Root */
export interface User {
  _id?: string;
  cognitoId: string;
  sub: string;
  email?: string;
  name?: string;
  email_verified: boolean;
  userProfiles?: UserProfile[];
  emailPreferences: UserEmailPreferences;
  preferences: UserPreferences;
  phoneNumber?: string;
  birthDate?: Date;
  orgAdminOf?: string[];
}

/* Aggregations */
export interface UserPreferences {
  address: Partial<Address> | null;
}

export interface UserEmailPreferences {
  joinConfirmation: boolean;
  bookingConfirmation: boolean;
  applicationConfirmation: boolean;
  bookingReminder: boolean;
  canceledEvent: boolean;
  canceledOngoingOpportunity: boolean;
  thirdParty: boolean;
  survey: boolean;
}

export interface UserProfile {
  _id: string;
  user: string;
  ecosystem: string;
  ecosystemSummary: IEcosystem;
  emailPreferences: UserEmailPreferences;

  // organization: string; // deprecated
  organizationSubDocument?: Organization;

  userOrganization?: string;
  userSummary: UserProfileUserSummary;
  appPermissionGroups: IAppPermissionGroups[];
  profilePicture?: string;
  externalId?: string;
  createdAt: Date;
  updatedAt: Date;

  organizationDepartment?: string;
  organizationDepartmentSubDocument?: OrganizationDepartment;
}

export enum UserProfileRole {
  Personal = 'Personal',
  Employee = 'Employee',
}

/* Object Values */
export interface UserProfileUserSummary {
  _id: string;
  name: string;
  email: string;
  profileImage?: string;
  phoneNumber?: string;
}
