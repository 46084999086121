import { MeasurementUnit } from 'ui/types/measurementUnit';

import { useQuery } from '~/hooks/useQuery';

export const useLoadMeasurementUnit = (id: string) => {
  const { data, isLoading } = useQuery<MeasurementUnit>(
    `measurement-units/${id}`,
    {
      key: `measurement-unit-${id}`,
      queryOptions: { enabled: !!id },
    },
  );

  return { data, isLoading };
};
