import { useQuery } from '~/hooks/useQuery';
import { IPaginatedResponse } from '~/types';
import { App } from '~/types/interfaces/app';

export const useLoadAppsByIds = (appIds: string[]) => {
  const { data: appsResponse, isLoading: isLoadingApps } = useQuery<
    IPaginatedResponse<App[]>
  >(`app`, {
    requestOptions: {
      params: {
        filter: JSON.stringify({
          _id: { $in: appIds },
        }),
      },
    },
    queryOptions: {
      enabled: !!appIds.length,
    },
    key: `paginated-apps-filtered-${appIds}`,
  });

  return {
    apps: appsResponse?.data || [],
    count: appsResponse?.count || 0,
    isLoadingApps,
  };
};
