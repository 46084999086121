import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { PAGES } from '~/constants/pages.constants';
import { useActivityViewStore } from '~/pages/Authenticated/v2/ActivityView/store';
import { IUserActivity } from '~/pages/Authenticated/v2/ActivityView/types';
import { logAvailabilityController } from '~/pages/Authenticated/v2/ActivityView/views/ActivityLog/ActivityLogPanel/logAvailabilityController';
import { parseUserActivitiesToMap } from '~/pages/Authenticated/v2/ActivityView/views/ActivityLog/ActivityLogPanel/parseUserActivitiesToMap';
import { ActivityLogPanelProps } from '~/pages/Authenticated/v2/ActivityView/views/ActivityLog/ActivityLogPanel/types';
import { LogStyleMapByType } from '~/pages/Authenticated/v2/ActivityView/views/ActivityLog/constants/log-style-map';

export const useActivityLogPanelController = (props: ActivityLogPanelProps) => {
  const { appliedActivities } = props;

  const navigate = useNavigate();

  const { activityDefinition, activityType } = useActivityViewStore(
    (state) => state,
  );

  // -- States
  const [appliedActivitiesMap] = useState<Map<string, IUserActivity>>(
    parseUserActivitiesToMap(props.appliedActivities),
  );

  const [selectedAppliedActivity, setSelectedAppliedActivity] =
    useState<IUserActivity | null>(appliedActivities[0]);

  const selectAppliedActivity = (activityId: string) => {
    const activity = appliedActivitiesMap.get(activityId);
    setSelectedAppliedActivity(activity || null);
  };

  const { logAvailability } = logAvailabilityController({
    activityApplication: selectedAppliedActivity
      ? selectedAppliedActivity.userApplication
      : undefined,
  });

  const logLabel = activityType
    ? LogStyleMapByType.get(activityType).getTitle(appliedActivities.length)
    : '';

  const title = logAvailability.message || logLabel;

  const handleLogAction = () => {
    if (!selectedAppliedActivity) return;

    const applicationId = selectedAppliedActivity.userApplication?._id || '';

    const route = PAGES.LogActivity.replace(':id', applicationId);
    navigate(route);
  };

  return {
    handleLogAction,
    selectedAppliedActivity,
    selectAppliedActivity,
    activityDefinition,
    activityType,
    logAvailability,
    title,
  };
};
