import { useQuery } from '~/hooks/useQuery';
import { IPaginatedResponse } from '~/types';
import { ICauseOptions } from '~/types/interfaces/activity';

export const useLoadActivityCategories = (organizationId: string) => {
  const { data, isLoading, refetch } = useQuery<
    IPaginatedResponse<ICauseOptions[]>
  >(`/activity-category`, {
    key: 'activity-category',
    requestOptions: {
      params: {
        filter: JSON.stringify({ relatedTo: ['causeOptions'] }),
      },
    },
    queryOptions: { enabled: !!organizationId },
  });

  return { data, isLoading, refetch };
};

export default useLoadActivityCategories;
