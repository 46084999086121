import { ActivityType } from 'ui/types/activities';
import { Coordinates } from 'ui/types/interfaces';
import { convertMilesToMeters } from 'ui/utils/location/transform-distance';

import {
  FromHomeOngoingSearchRelevanceType,
  InPersonOngoingSearchRelevanceType,
  OngoingFromHomeSearchFilters,
  OngoingInPersonSearchFilters,
} from '~/services/resources/activity-definitions/search/ongoing/ongoing-public-search.types';
import { mapCausesFilters } from '~/utils/activity-search/map-unified-search-api-filters';
import { SearchFilters } from '~/zustand/store/Search';

export const mapInPersonOngoingSearchApiFilters = (
  appIds: string[],
  coordinates: Coordinates | null,
  filters: Partial<SearchFilters>,
): Omit<OngoingInPersonSearchFilters, 'limit' | 'skip'> => {
  const radius = filters?.distanceRadius
    ? convertMilesToMeters(filters.distanceRadius)
    : undefined;

  const { causeOptions } = mapCausesFilters(filters?.causes || []);

  const apiFilters: Omit<OngoingInPersonSearchFilters, 'limit' | 'skip'> = {
    types: [ActivityType.Ongoing],
    relevance: filters.relevance as InPersonOngoingSearchRelevanceType,
    position: coordinates,
    text: filters?.searchText,
    apps: appIds,
    radius,
    categories: {
      causeOptions,
    },
  };

  return apiFilters;
};

export const mapFromHomeOngoingSearchApiFilters = (
  appIds: string[],
  filters: Partial<SearchFilters>,
): Omit<OngoingFromHomeSearchFilters, 'limit' | 'skip'> => {
  const { causeOptions } = mapCausesFilters(filters?.causes || []);

  const apiFilters: Omit<OngoingFromHomeSearchFilters, 'limit' | 'skip'> = {
    types: [ActivityType.Ongoing],
    relevance: filters.relevance as FromHomeOngoingSearchRelevanceType,
    position: null,
    //radius,
    apps: appIds,
    categories: {
      causeOptions,
    },
  };

  return apiFilters;
};
