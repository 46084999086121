import { Stack, Tooltip as NBTooltip } from 'native-base';

import { TooltipProps } from './types';
export const Tooltip = ({ children, ...rest }: TooltipProps) => {
  return (
    <NBTooltip {...rest}>
      {/*Stack makes the reference of children correct, as the Native Base tooltip needs a Native Base children to work, otherwise it will place on top-left of the root view*/}
      <Stack>{children}</Stack>
    </NBTooltip>
  );
};
