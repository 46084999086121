import IAnalytics from '../../types/interfaces/analytics';

class AnalyticsService {
  private analyticsProvider: IAnalytics;

  constructor(provider: IAnalytics) {
    this.analyticsProvider = provider;
  }

  track(event: string, properties?: Record<string, any>, options?: any): void {
    this.analyticsProvider.track(event, properties, options);
  }

  identify(userId: string, properties?: Record<string, any>): void {
    this.analyticsProvider.identify(userId, properties);
  }

  getClient(): any {
    return this.analyticsProvider.getClient();
  }
}

export default AnalyticsService;
