import { Coordinates } from 'ui/types/interfaces';
import { mapSearchActivityDefinition } from 'ui/utils/activity/mappers';

import { SEARCH_COLLECTION_CONFIG_MAP } from '~/components/Layouts/v2/SearchLayout/constants/search-collections';
import { ActivityCarouselDataSource } from '~/components/v2/ActivityCarousel/types';
import { ActivityDefinitionUnifiedPublicSearch } from '~/services/resources/activity-definitions/search/unified/unified-public-search';
import { SearchCollectionSlug } from '~/types/interfaces/activity-search/types';
import { mapActionsSearchApiFilters } from '~/utils/activity-search/map-actions-search-api-filters';
import { mapInPersonOngoingSearchApiFilters } from '~/utils/activity-search/map-ongoing-search-api-filters';

export const DEFAULT_CAROUSEL_LIMIT = 10;

export const generateActionSearchSuggestionDataSource = (
  filters: { position: Coordinates; apps: string[]; searchText?: string },
  setter: (dataSource: ActivityCarouselDataSource | null) => void,
) => {
  const { apps, position, searchText } = filters;

  let actionsDataSource = null;
  const collectionFilters = SEARCH_COLLECTION_CONFIG_MAP.get(
    SearchCollectionSlug.Actions,
  )?.filters;

  if (collectionFilters) {
    const combinedFilters = { ...collectionFilters, searchText };

    const apiFiltersDto = {
      ...mapActionsSearchApiFilters(apps, combinedFilters),
      limit: DEFAULT_CAROUSEL_LIMIT,
    };

    const queryKey = [
      SearchCollectionSlug.Actions,
      JSON.stringify(apiFiltersDto),
      'search-suggestions',
    ];

    actionsDataSource = new ActivityCarouselDataSource(
      ActivityDefinitionUnifiedPublicSearch.findAll(apiFiltersDto).then(
        mapSearchActivityDefinition(position),
      ),
      queryKey,
      ({ isLoading, dataLength }) => {
        if (!dataLength && !isLoading) {
          setter(null);
        }
      },
    );
  }

  setter(actionsDataSource);
};

export const generateInPersonOngoingSearchSuggestionDataSource = (
  filters: { position: Coordinates; apps: string[]; searchText?: string },
  setter: (dataSource: ActivityCarouselDataSource | null) => void,
) => {
  const { apps, position, searchText } = filters;

  let inPersonOngoingDataSource = null;
  const collectionFilters = SEARCH_COLLECTION_CONFIG_MAP.get(
    SearchCollectionSlug.Ongoing,
  )?.filters;

  if (collectionFilters) {
    const combinedFilters = {
      ...collectionFilters,
      searchText,
    };

    const apiFiltersDto = {
      ...mapInPersonOngoingSearchApiFilters(apps, position, combinedFilters),
      limit: DEFAULT_CAROUSEL_LIMIT,
    };

    const queryKey = [
      SearchCollectionSlug.Ongoing,
      JSON.stringify(apiFiltersDto),
      'search-suggestions',
    ];

    inPersonOngoingDataSource = new ActivityCarouselDataSource(
      ActivityDefinitionUnifiedPublicSearch.findAll(apiFiltersDto).then(
        mapSearchActivityDefinition(position),
      ),
      queryKey,
      ({ isLoading, dataLength }) => {
        if (!dataLength && !isLoading) {
          setter(null);
        }
      },
    );
  }

  setter(inPersonOngoingDataSource);
};
