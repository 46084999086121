import FeatherIcon from 'feather-icons-react';
import { Box, Input, ScrollView, Text, VStack } from 'native-base';

import { colors } from '../../theme/colors';
import Loading from '../Loading';
import { useInlineSearchInputController } from './controller';
import { InlineSearchInputProps } from './types';

const InlineSearchInput = (props: InlineSearchInputProps) => {
  const {
    title = '',
    inputIcon = 'map-pin',
    placeholder,
    onSelect,
    optionElement: OptionElement,
    showSearchInput = true,
  } = props;

  const { inputValue, isOptionsLoading, options, handleInputChange } =
    useInlineSearchInputController(props);

  return (
    <VStack w="100%">
      <VStack
        width="100%"
        minH={'fit-content'}
        alignContent="center"
        justifyContent="center"
        background="white"
        borderRadius={12}
        zIndex={9999}
      >
        {title && (
          <Text
            mb={2}
            fontSize={'sm'}
            fontWeight={500}
            color={colors.gray[600]}
          >
            {title}
          </Text>
        )}

        {!!showSearchInput && (
          <Input
            flex={1}
            h="3.25rem"
            width={'100%'}
            value={inputValue}
            onChange={(e: any) => handleInputChange(e.target.value)}
            type={'text'}
            backgroundColor="white"
            placeholder={placeholder || ''}
            px={2}
            fontWeight={!!location ? 500 : 400}
            InputLeftElement={
              <Box ml={2} variant="unstyled">
                <FeatherIcon
                  icon={inputIcon}
                  size={16}
                  color={colors.gray[400]}
                />
              </Box>
            }
          />
        )}
      </VStack>

      <VStack flex={1} overflow={'hidden'}>
        <ScrollView>
          <Box py={showSearchInput ? 3 : 0} flex={1}>
            {isOptionsLoading ? (
              <Loading containerHeight="32px" spinnerSize="sm" />
            ) : (
              (options ?? []).map((option: any, index) => (
                <OptionElement key={index} data={option} onPress={onSelect} />
              ))
            )}
          </Box>
        </ScrollView>
      </VStack>
    </VStack>
  );
};

export default InlineSearchInput;
