import { HStack, Text, VStack } from 'native-base';

import { ActivitySummaryTitleProps } from '~/components/ActivityCards/CardRecommendations/components/ActivitySummaryTitle/activitySummaryTitle.types';

export const ActivitySummaryTitle = ({
  activityName,
  appName,
  organizationName,
}: ActivitySummaryTitleProps) => {
  return (
    <VStack w={'100%'}>
      <HStack
        w={'100%'}
        flexDir={{ base: 'column', lg: 'row' }}
        alignItems={{ base: 'flex-start', lg: 'center' }}
        space={3}
      >
        {!!activityName && (
          <Text
            fontSize={'md'}
            maxW={{ base: '100%', lg: '40%' }}
            minW={{ base: '100%', lg: 'auto' }}
            fontWeight={'bold'}
            mb={{ base: 2, lg: 0 }}
            noOfLines={1}
          >
            {activityName}
          </Text>
        )}

        <HStack space={{ base: 0, lg: 3 }} w={'auto'}>
          <HStack flex={1} space={{ base: 2, lg: 3 }} alignItems={'center'}>
            {!!appName && (
              <>
                <Text fontSize={'md'} color={'gray.600'} fontWeight={500}>
                  •
                </Text>

                <Text
                  fontSize={{ base: 'sm', lg: 'md' }}
                  color={'singletons.darkText'}
                  fontWeight={500}
                  noOfLines={1}
                >
                  {appName}
                </Text>
              </>
            )}

            {!!organizationName && (
              <>
                <Text
                  fontSize={{ base: 'sm', lg: 'md' }}
                  fontWeight={700}
                  color={'gray.600'}
                >
                  •
                </Text>

                <Text
                  fontSize={{ base: 'sm', lg: 'md' }}
                  color={'singletons.darkText'}
                  noOfLines={1}
                >
                  by {organizationName}
                </Text>
              </>
            )}
          </HStack>
        </HStack>
      </HStack>
    </VStack>
  );
};
