import { ActivitySemanticType } from 'ui/types/activities';

import { CoverStyleAtributes } from '~/pages/Authenticated/v2/ActivityView/components/ActivityCover/type';
import { colors } from '~/theme/colors';

export const ActivityCoverStyleMap = new Map<
  ActivitySemanticType,
  CoverStyleAtributes
>([
  [
    ActivitySemanticType.Event,
    {
      backgroundColor: colors.activity.event,
    },
  ],
  [
    ActivitySemanticType.TeamEvent,
    {
      backgroundColor: colors.activity.event,
    },
  ],
  [
    ActivitySemanticType.Action,
    {
      backgroundColor: colors.activity.action,
    },
  ],
  [
    ActivitySemanticType.Ongoing,
    {
      backgroundColor: colors.activity.ongoing,
    },
  ],
]);
