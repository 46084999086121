import { useEffect } from 'react';

import AnalyticsService from '../../services/analytics/analyticsService';
import FirebaseAnalyticsService from '../../services/analytics/firebaseService';
import PostHogAnalytics from '../../services/analytics/posthogService';
import {
  AnalyticsEvents,
  AnalyticsProvider,
} from '../../types/interfaces/analytics';

const provider: AnalyticsProvider =
  (import.meta.env.VITE_ANALYTICS_PROVIDER as AnalyticsProvider) || 'posthog';
let analyticsService: AnalyticsService | null = null;

export const initializeAnalytics = (provider: AnalyticsProvider) => {
  if (analyticsService) {
    console.warn('Analytics service has already been initialized.');
    return;
  }

  let providerInstance;

  if (provider === 'posthog') {
    providerInstance = new PostHogAnalytics();
  } else if (provider === 'firebase') {
    providerInstance = new FirebaseAnalyticsService();
  } else {
    throw new Error('Unsupported analytics provider');
  }

  if (!providerInstance) {
    throw new Error(
      `Analytics ${provider} provider not supported or invalid configuration`,
    );
  }

  if (process.env.NODE_ENV === 'development') {
    console.info(`Analytics provider: ${provider} initialized.`);
  }

  analyticsService = new AnalyticsService(providerInstance);
};

const useAnalytics = () => {
  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      if (!analyticsService) {
        initializeAnalytics(provider);
      }
    }
  }, []);

  const track = <T extends keyof AnalyticsEvents>(
    event: T,
    properties?: AnalyticsEvents[T],
    options?: any,
  ) => {
    if (analyticsService) {
      analyticsService.track(event, properties, options);
    }
  };

  const identify = (userId: string, properties?: Record<string, any>) => {
    if (analyticsService) {
      analyticsService.identify(userId, properties);
    }
  };

  const client = analyticsService?.getClient();

  return { track, identify, client };
};

export default useAnalytics;
