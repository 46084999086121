import React from 'react';
import { Routes } from 'react-router-dom';

import { CommonRoutes } from '~/routes/Common';
import { AuthenticationRoutes } from '~/routes/Unauthenticated/AuthenticationRoutes';

export const UnauthenticatedRoutes = () => {
  return (
    <Routes>
      {CommonRoutes}
      {AuthenticationRoutes}
    </Routes>
  );
};
