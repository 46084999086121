import FeatherIcon from 'feather-icons-react';
import { Box, FormControl, TextArea as NBTextArea } from 'native-base';
import { Controller } from 'react-hook-form';

import { colors } from '../../theme/colors';
import { TextAreaProps } from './types';

const TextArea = ({
  _formControl,
  control,
  name,
  iconErrorSize,
  helperText,
  hasError,
  isInvalid,
  isDisabled,
  isReadOnly,
  isRequired,
  errorMessage,
  iconErrorMessage,
  label,
  autoCompleteType,
  ...restProps
}: TextAreaProps) => {
  return (
    <Box alignItems="center">
      <FormControl
        isInvalid={hasError || errorMessage ? true : isInvalid}
        isDisabled={isDisabled}
        isRequired={isRequired}
        isReadOnly={isReadOnly}
        {..._formControl}
      >
        {label ? <FormControl.Label>{label}</FormControl.Label> : null}
        <Controller
          control={control}
          name={name}
          render={({ field }) => (
            <>
              <NBTextArea
                autoCompleteType={autoCompleteType}
                {...restProps}
                {...field}
                borderColor={hasError || errorMessage ? 'error.400' : undefined}
                placeholderTextColor={
                  hasError || errorMessage ? 'error.400' : undefined
                }
              />
            </>
          )}
        />
        {!hasError && !errorMessage && helperText ? (
          <FormControl.HelperText ml={2}>{helperText}</FormControl.HelperText>
        ) : null}
        {errorMessage ? (
          <FormControl.ErrorMessage
            ml={2}
            leftIcon={
              iconErrorMessage ? (
                <FeatherIcon
                  icon={iconErrorMessage}
                  size={iconErrorSize}
                  color={colors.error[400]}
                />
              ) : undefined
            }
          >
            {errorMessage}
          </FormControl.ErrorMessage>
        ) : null}
      </FormControl>
    </Box>
  );
};

export default TextArea;
