import { yupResolver } from '@hookform/resolvers/yup';
import { Auth } from 'aws-amplify';
import { useMediaQuery } from 'native-base';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { useRouter } from '~/hooks/useRouter';
import AuthService from '~/services/resources/auth';

import { schemaValidation } from './constants';
import { IChangePasswordFields } from './types';

export const useChangePasswordController = () => {
  const { goBack } = useRouter();

  const [isLoading, setIsLoading] = useState(false);

  const [isMobile] = useMediaQuery({
    maxWidth: 480,
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<IChangePasswordFields>({
    resolver: yupResolver(schemaValidation),
  });

  const onSubmit = handleSubmit(async ({ currentPassword, newPassword }) => {
    try {
      setIsLoading(true);
      const user = await Auth.currentAuthenticatedUser();
      await AuthService.changePassword(user, {
        oldPassword: currentPassword.trim(),
        newPassword: newPassword.trim(),
      });
      toast.success('Password changed successfully!');
      goBack();
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  });

  return {
    control,
    errors,
    goBack,
    onSubmit,
    isLoading,
    isMobile,
  };
};
