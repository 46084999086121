import { SearchRelevanceSortBy } from '~/services/resources/activity-definitions/search/types';

export const SORT_MENU_ITEMS = [
  {
    value: SearchRelevanceSortBy.Popular,
    label: 'Popular',
  },
  {
    value: SearchRelevanceSortBy.Upcoming,
    label: 'Upcoming (Start date)',
  },
  {
    value: SearchRelevanceSortBy.Nearest,
    label: 'Nearest (Distance from location)',
  },
  {
    value: SearchRelevanceSortBy.Newest,
    label: 'Newest (Creation date)',
  },
];
