import { Auth } from 'aws-amplify';
import { useDispatch } from 'react-redux';

import { IModalSignOutProps } from '~/components/ModalSignOut/types';
import { PAGES } from '~/constants/pages.constants';
import { useRouter } from '~/hooks/useRouter';
import { authSliceActions } from '~/store/slices/auth';
import { ecosystemSliceActions } from '~/store/slices/ecosystem';
import { permissionsSliceActions } from '~/store/slices/permissions';

export const useModalSignOutController = ({ onClose }: IModalSignOutProps) => {
  // -- Providers
  const dispatch = useDispatch();

  // -- States
  const { replaceRoute } = useRouter();

  // -- Handlers
  const handleSignOut = async () => {
    replaceRoute(PAGES.Root);
    dispatch(authSliceActions.logout({}));
    dispatch(ecosystemSliceActions.resetEcosystem());
    dispatch(permissionsSliceActions.resetPermissionsState());
    await Auth.signOut();
    onClose();
  };
  return { handleSignOut };
};
