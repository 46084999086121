import { useCallback, useEffect, useMemo, useState } from 'react';
import { Coordinates } from 'ui/types/interfaces';

import { useSearchRoutes } from '~/components/Layouts/v2/SearchLayout/hooks/use-search-routes';
import { ActivityCarouselDataSource } from '~/components/v2/ActivityCarousel/types';
import { useVolunteerEcosystemAvailableApps } from '~/hooks/useApps/useVolunteerEcosystemAvailableApps';
import { useUserLocationController } from '~/hooks/useUserLocation/useUsersLocation';
import { generateCarouselCollectionsDataSource } from '~/pages/Authenticated/v2/Home/controllers/utils';
import { SearchCollectionSlug } from '~/types/interfaces/activity-search/types';

export const useCarouselController = () => {
  // Providers
  const { appIds } = useVolunteerEcosystemAvailableApps();
  const { coordinates } = useUserLocationController();
  const { goToSearchCollectionPage } = useSearchRoutes();

  // States
  const [isFilterSet, setIsFilterSet] = useState<boolean | null>(false);
  const [usersFilters, setUsersFilters] = useState<{
    apps: string[];
    position: Coordinates;
  } | null>(null);

  // Hooks
  useEffect(() => {
    if (!appIds?.length || !coordinates) {
      setIsFilterSet(false);
      return;
    }

    setUsersFilters({
      apps: appIds,
      position: coordinates,
    });
    setIsFilterSet(true);
  }, [appIds, coordinates]);

  const dataSourceMap = useMemo(() => {
    if (!usersFilters)
      return new Map<SearchCollectionSlug, ActivityCarouselDataSource>([]);
    const { apps, position } = usersFilters;
    return generateCarouselCollectionsDataSource(apps, position);
  }, [usersFilters]);

  const handleCarouselAction = useCallback(
    (collectionSlug: SearchCollectionSlug) => {
      goToSearchCollectionPage(collectionSlug);
    },
    [goToSearchCollectionPage],
  );

  return {
    isFilterSet,
    usersFilters,
    dataSourceMap,
    handleCarouselAction,
  };
};
