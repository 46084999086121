import { DEFAULT_SEARCH_FILTERS } from '~/zustand/store/Search';

import { ActivityFilterFormFilters } from './types';

export const MakeActivityFilterFormFilters = (
  filters?: Partial<ActivityFilterFormFilters>,
) => {
  const result = {
    ...DEFAULT_SEARCH_FILTERS,
    ...filters,
  } as ActivityFilterFormFilters;
  return result;
};
