import BreadCrumbs from '~/components/Breadcrumbs';
import { useActivityBreadCrumpController } from '~/pages/Authenticated/ViewActivityDetails/components/ActivityBreadCrumb/activityBreadCrumpController';
import { IActivityBreadCrumbControllerProps } from '~/pages/Authenticated/ViewActivityDetails/components/ActivityBreadCrumb/types';

export const ActivitiesBreadCrumb = ({
  activityDefinition,
  appId,
}: IActivityBreadCrumbControllerProps) => {
  const { breadcrumbs } = useActivityBreadCrumpController({
    activityDefinition,
    appId,
  });

  return <BreadCrumbs showBackButton breadcrumbs={breadcrumbs} />;
};
