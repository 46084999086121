import { Auth } from 'aws-amplify';
import { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { IModalRefProps } from 'ui/components/Modals/Modal/types';

import { PAGES } from '~/constants/pages.constants';
import { useAppSelector } from '~/hooks/useAppSelector';
import { useRouter } from '~/hooks/useRouter';
import { authSliceActions } from '~/store/slices/auth';
import { ecosystemSliceActions } from '~/store/slices/ecosystem';
import { permissionsSliceActions } from '~/store/slices/permissions';

export const useAccountSettingsController = () => {
  const privacyModalRef = useRef<IModalRefProps>(null);
  const termsModalRef = useRef<IModalRefProps>(null);
  const modalRef = useRef<IModalRefProps>(null);
  const dispatch = useDispatch();

  const { user } = useAppSelector(({ auth }) => auth);

  const { goToRoute, replaceRoute } = useRouter();

  const handleCloseSignOutModal = () => {
    modalRef.current?.close();
  };

  const handleOpenSignOutModal = () => {
    modalRef.current?.open();
  };

  const handleGoToChangePassword = () => {
    goToRoute(PAGES.ChangePassword);
  };

  const handleSignOut = async () => {
    replaceRoute(PAGES.Root);
    dispatch(authSliceActions.logout());
    dispatch(ecosystemSliceActions.resetEcosystem());
    dispatch(permissionsSliceActions.resetPermissionsState());
    handleCloseSignOutModal();

    await Auth.signOut();
  };

  const handleDeleteAccount = () => goToRoute(PAGES.DeleteAccount);

  const handleOpenPrivacyModal = () => privacyModalRef.current?.open();

  const handleOpenTermsModal = () => termsModalRef.current?.open();

  const handleClosePrivacyModal = () => privacyModalRef.current?.close();

  const handleCloseTermsModal = () => termsModalRef.current?.close();

  return {
    modalRef,
    handleOpenSignOutModal,
    handleCloseSignOutModal,
    handleSignOut,
    handleGoToChangePassword,
    handleDeleteAccount,
    user,
    privacyModalRef,
    termsModalRef,
    handleOpenPrivacyModal,
    handleOpenTermsModal,
    handleClosePrivacyModal,
    handleCloseTermsModal,
  };
};
