import { useEffect } from 'react';
import Loading from 'ui/components/v2/Loading';

import { useSearchRoutes } from '~/components/Layouts/v2/SearchLayout/hooks/use-search-routes';
import { PAGES } from '~/constants/pages.constants';
import { useVolunteerEcosystemAvailableApps } from '~/hooks/useApps/useVolunteerEcosystemAvailableApps';
import { useRouter } from '~/hooks/useRouter';
import { useInApp } from '~/zustand/store/InApp';

import SearchLayout from '..';
export type InAppLayoutProps = {
  children: JSX.Element | JSX.Element[];
};

export const InAppLayout = ({ children }: InAppLayoutProps) => {
  // Providers
  const { params, goToRoute } = useRouter();
  const { goToInApp } = useSearchRoutes();
  const { getAppById, appIds } = useVolunteerEcosystemAvailableApps();

  // Store
  const setApp = useInApp((state) => state.setApp);

  // Handlers
  const unloadApp = () => {
    setApp(null);
  };

  const loadApp = (appId: string) => {
    const app = getAppById(appId);
    if (!app) {
      if (appIds.length) {
        goToInApp(appIds[0]);
        return;
      }
      unloadApp();
      goToRoute(PAGES.Page404);
      return;
    }
    setApp(app);
  };

  // Hooks
  useEffect(() => {
    const { appId } = params;
    if (!appId) return;

    loadApp(appId);

    return () => {
      unloadApp();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  return <SearchLayout>{children}</SearchLayout>;
};
