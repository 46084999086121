import { useEffect, useRef } from 'react';
import { RelatedToEnum } from 'ui/types/activities';

import { useActivityCategories } from '~/hooks/useActivityCategories';
import { useSearch } from '~/zustand/store/Search';

export const useLoadResourceController = () => {
  const setCauses = useSearch((state) => state.setCauses);
  const hasCausesSet = useRef(false);
  const { data: causes } = useActivityCategories(RelatedToEnum.CAUSE_OPTIONS);

  useEffect(() => {
    if (!causes?.length || hasCausesSet.current) return;
    hasCausesSet.current = true;

    const filteredCauses = causes.filter(
      (c) => c.displayName !== 'Sports, art and culture',
    );
    setCauses(filteredCauses);
  }, [setCauses, causes]);

  return { causes };
};
