import { Box, Pressable } from 'native-base';
import { RootPortal } from 'ui/components/v2/RootPortal';

import { useSearchOverlayController } from './controller';
import SearchDrawer from './Drawer';
import SearchDropDown from './Dropdown';
import { SearchOverlayProps } from './types';

export const SearchOverlay = (props: SearchOverlayProps) => {
  const { children, defaultValue, options } = props;
  const {
    isOpen,
    isMobile,
    handleSubmit,
    handleCloseOverlay,
    handleOpenOverlay,
    handleOptionSelect,
  } = useSearchOverlayController(props);

  return (
    <Box w="full" position={'relative'}>
      <Pressable
        w="full"
        accessibilityLabel="More options menu"
        onPress={handleOpenOverlay}
        onFocus={handleOpenOverlay}
        onBlur={handleCloseOverlay}
      >
        {children}
      </Pressable>

      {isMobile ? (
        <RootPortal>
          <SearchDrawer
            isOpen={isOpen}
            options={options}
            defaultValue={defaultValue}
            onClose={handleCloseOverlay}
            onSubmit={handleSubmit}
          />
        </RootPortal>
      ) : (
        <SearchDropDown
          isOpen={isOpen}
          options={options}
          onOptionPress={handleOptionSelect}
          onClose={handleCloseOverlay}
        />
      )}
    </Box>
  );
};

export default SearchOverlay;
