import { useState } from 'react';
import { SearchOption } from 'ui/components/InlineSearchInput/types';

import {
  mapLocationsToOptions,
  parseLocationOption,
} from '~/pages/Authenticated/v2/ActivityView/components/ActivitySearch/ActivityLocationSearch/activity-location-mapper';
import { ActivityLocationSearchProps } from '~/pages/Authenticated/v2/ActivityView/components/ActivitySearch/ActivityLocationSearch/types';

export const useActivityLocationSearchController = (
  props: ActivityLocationSearchProps,
) => {
  const { locations, onLocationChange, selectedLocation } = props;

  const [selectedOption, setSelectedOption] = useState<SearchOption | null>(
    selectedLocation ? parseLocationOption(selectedLocation) : null,
  );

  const loadLocationOptions = (search: string) => {
    return Promise.resolve(mapLocationsToOptions(locations, search));
  };

  const handleLocationSelect = (option: SearchOption | null) => {
    setSelectedOption(option);
    onLocationChange(option ? JSON.parse(option.value) : null);
  };

  return {
    handleLocationSelect,
    loadLocationOptions,
    selectedOption,
  };
};
