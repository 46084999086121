import { HStack, Pressable, Text } from 'native-base';
import Icon from 'ui/components/Icon';
import LoadingLinear from 'ui/components/LoadingLinear';
import { Tooltip } from 'ui/components/Tooltip';
import { colors } from 'ui/theme/colors';

import { SelectAddressButtonProps } from './types';

export const SelectAddressButton = ({
  address,
  iconSize = 16,
  brandColor = colors.darkText,
  toolTipText = 'Discover opportunities based on your location and favourite causes.',
  toolTipWidth = '80%',
  width,
  onPress,
  isLoading = false,
}: SelectAddressButtonProps) => {
  return (
    <Pressable width={width} onPress={onPress} alignItems="flex-start">
      <Tooltip
        bgColor={brandColor || colors.primary[400]}
        width={toolTipWidth}
        mx={8}
        label={toolTipText}
        display={{ base: 'none', lg: 'initial' }}
        overflow={'hidden'}
      >
        <HStack space={2} alignItems={'center'}>
          {isLoading ? (
            <LoadingLinear label="checking location..." />
          ) : (
            <>
              <Icon
                icon={'map-pin'}
                color={brandColor || colors.primary[400]}
                size={iconSize}
              />
              <Text fontSize="sm" numberOfLines={1} flex={1} maxW={200} mr={2}>
                {address || 'Select your location'}
              </Text>
            </>
          )}
          <Icon
            icon="chevron-down"
            color={brandColor || colors.primary[400]}
            size={iconSize}
          />
        </HStack>
      </Tooltip>
    </Pressable>
  );
};
