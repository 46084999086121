import { ActivityType } from 'ui/types/activities';

import { sectionInfo } from './types';

export const activityTypesOptions = [
  { value: ActivityType.Action, label: 'Action' },
  { value: ActivityType.Event, label: 'Event' },
  { value: ActivityType.Ongoing, label: 'Ongoing Opportunity' },
];

export const sections: sectionInfo[] = [
  {
    title: 'New activity off-platform',
    subtitle: 'Enter the activity details',
  },
  {
    title: 'Log action',
    subtitle: 'Enter the amount for the day.',
  },
];
