import Yup from '~/utils/validations/yup';

export const measurementsFieldsSchemaValidation = Yup.object().shape({
  amount: Yup.number()
    .required('This field is required')
    .min(1, 'This field is required'),
  measurementUnitId: Yup.string(),
  _id: Yup.string().optional(),
});

export const schemaValidation = Yup.object().shape({
  measurementDate: Yup.date().required(),
  measurements: Yup.array().of(measurementsFieldsSchemaValidation),
});
