import { HStack, Stack, Text, VStack } from 'native-base';
import Button from 'ui/components/Button';
import Modal from 'ui/components/Modals/Modal';

import { ModalDiscardChangesProps } from '~/components/ModalDiscardChanges/types';

export const ModalDiscardChanges = ({
  handleOnSave,
  handleDiscardChanges,
  modalRef,
  isLoading,
}: ModalDiscardChangesProps) => {
  return (
    <Modal
      ref={modalRef}
      headerText="Save changes"
      headerFontSize={{ base: 'md', lg: 'lg' }}
      modalContentMaxW={{ base: '95vw', lg: '669' }}
      modalContentW={{ base: '95vw', lg: '669' }}
      bodyPadding={'0'}
    >
      <VStack h={{ base: 'full', md: 'auto' }} justifyContent="space-between">
        <Stack px={4} py={10} h="4rem" justifyContent="center">
          <Text fontSize="md">
            Do you want to leave this page without saving the changes?
          </Text>
        </Stack>

        <HStack
          w="full"
          p={4}
          space={{ base: 1, md: 4 }}
          flex={1}
          bg="gray.100"
        >
          <Button flex={0.5} onPress={handleDiscardChanges} variant="ghost">
            Discard changes
          </Button>
          <Button
            flex={0.5}
            onPress={handleOnSave}
            isLoading={isLoading}
            disabled={isLoading}
          >
            Save
          </Button>
        </HStack>
      </VStack>
    </Modal>
  );
};
