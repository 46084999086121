import { Stack, Text, VStack } from 'native-base';

import { ActivityViewSectionProps } from '~/pages/Authenticated/v2/ActivityView/components/ActivityViewSection/types';

export const ActivityViewSection = (props: ActivityViewSectionProps) => {
  const { title, children, hide = false } = props;

  if (!!hide) return null;
  return (
    <VStack space={6}>
      <Stack borderBottomWidth={1} borderBottomColor={'gray.300'} pb={3}>
        <Text
          fontSize={{ base: 'xl', md: 'lg' }}
          fontWeight={500}
          lineHeight={'150%'}
        >
          {title}
        </Text>
      </Stack>

      {children}
    </VStack>
  );
};
