export const STATIC_SEARCH_SUGGESTIONS_VALUES = [
  'Participate in community events',
  'Join group activities',
  'Activities with animals',
  'Help refugees or those in crisis',
  'Support art and culture',
  'Environmental clean-up projects',
  'Visit or assist at care homes',
  'Help with disaster relief efforts',
  'Mentor young students',
  'Assist at local shelters',
  'Plant trees and maintain green spaces',
  'Volunteer at food banks',
  'Teach digital literacy',
  'Organise fundraising events',
  'Assist with healthcare initiatives',
  'Run sports programmes for children',
  'Volunteer at animal shelters',
  'Lead youth groups and activities',
  'Support literacy programmes',
  'Help in schools and libraries',
  'Provide language tutoring',
  'Assist in community kitchens',
  'Organise charity drives',
  'Assist the elderly with technology',
  'Support mental health initiatives',
  'Be a museum or gallery guide',
  'Help build homes for the homeless',
  'Volunteer in hospices',
  'Support women’s refuges',
  'Join local recycling initiatives',
  'Assist in agricultural projects',
  'Help organise charity runs or walks',
  'Provide transport for older people',
  'Assist in job training programmes',
  'Create art programmes for young people',
  'Support refugee integration efforts',
  'Teach life skills to teenagers',
  'Help organise cultural festivals',
  'Tutor children in reading and maths',
  'Work with victims of domestic violence',
  'Volunteer at summer camps',
  'Assist in parks and recreation programmes',
  'Mentor entrepreneurs or small businesses',
  'Help organise blood donation drives',
  'Promote sustainable farming practices',
  'Lead environmental education workshops',
  'Work with veterans and military families',
  'Assist with clean water projects',
  'Coordinate clothing donations',
  'Support disaster preparedness efforts',
  'Volunteer with crisis helplines',
  'Be a reading buddy for children',
  'Create outdoor adventure programmes',
  'Help preserve historical sites',
  'Assist with wildlife rehabilitation',
  'Host arts and crafts workshops',
  'Create safe spaces for LGBTQ+ youth',
  'Help with anti-bullying programmes',
  'Teach financial literacy',
  'Volunteer as a translator',
  'Assist with urban gardening projects',
  'Run after-school tutoring sessions',
  'Organise beach or park clean-ups',
  'Support homeless outreach efforts',
  'Teach cooking or nutrition classes',
  'Provide disaster mental health support',
  'Help victims of human trafficking',
  'Run career development workshops',
  'Support recycling and composting programmes',
  'Work on renewable energy initiatives',
  'Lead fitness or wellness programmes',
  'Help organise arts and music festivals',
  'Coordinate transport for volunteers',
  'Advocate for affordable housing',
  'Provide first aid training',
  'Assist in creating community gardens',
  'Help manage social media for charities',
  'Support prison rehabilitation programmes',
  'Volunteer as a community mediator',
  'Assist with voter registration drives',
  'Help with public health campaigns',
  'Organise toy or book drives',
  'Support campaigns against child labour',
  'Create mindfulness and meditation workshops',
  'Assist with neighbourhood watch programmes',
  'Teach STEM programmes to underserved children',
  'Support climate change awareness campaigns',
  'Help distribute meals to the homeless',
  'Volunteer with older people’s exercise programmes',
  'Advocate for mental health policy changes',
  'Provide childcare for low-income families',
  'Organise community art projects',
  'Assist in legal aid for marginalised groups',
  'Promote responsible pet ownership',
  'Run technology workshops for older people',
  'Help with drug and alcohol prevention',
  'Work on international development projects',
  'Coordinate holiday meal distributions',
  'Run mentorship programmes for foster children',
  'Teach sustainability practices to businesses',
  'Support mental health awareness events',
  'Help with wildlife conservation efforts',
  'Provide respite care for carers',
  'Create inclusive sports leagues',
  'Assist with peer support programmes',
  'Volunteer in youth detention centres',
];
