import { useBreakpointValue } from 'native-base';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useSearchRoutes } from '~/components/Layouts/v2/SearchLayout/hooks/use-search-routes';
import { useRouter } from '~/hooks/useRouter';
import { makeSearchPageBreadCrumbsNodes } from '~/pages/Authenticated/v2/Search/components/Header/utils';
import { useSearch } from '~/zustand/store/Search';

import { SearchHeaderProps } from './types';
export const SearchHeaderController = ({}: SearchHeaderProps) => {
  // Providers
  const { goBack, params, isStackEmpty } = useRouter();
  const applyFilters = useSearch((state) => state.applyFilters);
  const [_, setSearchParams] = useSearchParams();
  const { appliedFilters, selectedCollection } = useSearch((state) => state);

  // Hooks
  const { homePathname, inAppHomePathname, goToHomePage, goToInApp } =
    useSearchRoutes();

  const breadCrumbsNodes = useMemo(
    () =>
      makeSearchPageBreadCrumbsNodes(
        params?.appId ? inAppHomePathname : homePathname,
        selectedCollection?.label || '',
      ),
    [params, homePathname, inAppHomePathname, selectedCollection],
  );

  const inputLabel = useBreakpointValue({ base: 'Search', md: '' });

  // Handlers
  const handleExitSection = () => {
    if (!isStackEmpty) {
      goBack();
      return;
    }

    if (params.appId) {
      goToInApp(params.appId);
    } else {
      goToHomePage();
    }
  };

  const handleSearchInputSubmit = (searchText: string) => {
    if (appliedFilters.searchText !== searchText) {
      applyFilters({ searchText });
      setSearchParams({ search_text: searchText });
    } else {
      if (searchText) {
        toast.info("Your search hasn't changed.");
      }
    }
  };

  const isCollectionSearch = !!selectedCollection;
  const defaultTextValue = appliedFilters.searchText;

  return {
    inputLabel,
    breadCrumbsNodes,
    defaultTextValue,
    selectedCollection,
    isCollectionSearch,
    handleSearchInputSubmit,
    handleExitSection,
  };
};
