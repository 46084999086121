import { api } from '~/services/api';
import { CreateMeasurementOffPlatformDTO } from '~/types/dtos/measurement';

const MODULE_URL = '/measurement';

export default class MeasurementService {
  static async findByUserProfile(userProfileId: string) {
    return await api.get(`${MODULE_URL}/user-profile/${userProfileId}`);
  }

  static async deleteMeasurementOffPlatform(measurementId: string) {
    return await api.delete(
      `${MODULE_URL}/${measurementId}/activity-off-platform`,
    );
  }

  static async createMeasurementOffPlatform(
    payload: CreateMeasurementOffPlatformDTO,
  ) {
    return await api.post(`${MODULE_URL}/activity-off-platform`, payload);
  }

  static async updateMeasurementOffPlatform(
    id: string,
    payload: Partial<CreateMeasurementOffPlatformDTO>,
  ) {
    return await api.put(`${MODULE_URL}/${id}/activity-off-platform`, payload);
  }
}
