import { Actionsheet, Box, Text, VStack } from 'native-base';
import { ReactNode } from 'react';

export type DropDownActionSheetProps = {
  isOpen?: boolean;
  onClose?: VoidFunction;
  title?: string;
  children?: ReactNode;
};

export const DropDownActionSheet = ({
  title,
  isOpen,
  children,
  onClose,
}: DropDownActionSheetProps) => {
  return (
    <Actionsheet isOpen={isOpen} onClose={onClose}>
      <Actionsheet.Content maxW={'480px'} maxH={'420px'} margin={'auto'}>
        <VStack w="full" overflowY={'auto'}>
          {title && (
            <Box w="100%" h={60} px={4} justifyContent="center">
              <Text fontSize="14" color="gray.500">
                {title || ''}
              </Text>
            </Box>
          )}
          {children}
        </VStack>
      </Actionsheet.Content>
    </Actionsheet>
  );
};

export default DropDownActionSheet;
