import Yup from '~/utils/validations/yup';

import { IEmailPreferenceItem, IEmailPreferencesForm } from './types';

export const preferencesList: IEmailPreferenceItem[] = [
  {
    title: 'Join confirmation',
    value: 'joinConfirmation',
    description:
      'You will receive an email whenever your request to join an action is confirmed or approved.',
  },
  {
    title: 'Booking confirmation',
    value: 'bookingConfirmation',
    description:
      'You will receive an email whenever your application to an event is confirmed or approved.',
  },
  {
    title: 'Booking reminder',
    value: 'bookingReminder',
    description:
      'You will receive an email whenever an event you applied to will happen soon.',
  },
  {
    title: 'Cancelled event',
    value: 'canceledEvent',
    description:
      'You will receive an email whenever an event you applied to is cancelled.',
  },
  {
    title: '3rd party',
    value: 'thirdParty',
    description:
      'You will directly receive emails from the charities responsible for the events and actions you apply and join.',
  },
  {
    title: 'Survey',
    value: 'survey',
    description:
      'You will receive a survey email on the day after an event you applied to happened.',
  },
];

export const emailPreferencesFormSchema = Yup.object().shape({
  joinConfirmation: Yup.boolean(),
  bookingConfirmation: Yup.boolean(),
  bookingReminder: Yup.boolean(),
  canceledEvent: Yup.boolean(),
  thirdParty: Yup.boolean(),
  survey: Yup.boolean(),
});

export const defaultPreferences: IEmailPreferencesForm = {
  bookingConfirmation: false,
  bookingReminder: false,
  canceledEvent: false,
  joinConfirmation: false,
  survey: false,
  thirdParty: false,
};
