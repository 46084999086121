import { PresenceTransition, VStack } from 'native-base';
import { ReactNode } from 'react';

export type InAppCarouselCollectionsSectionProps = {
  children?: ReactNode | JSX.Element;
};

export const InAppCarouselCollectionsSection = ({
  children,
}: InAppCarouselCollectionsSectionProps) => {
  return (
    <VStack>
      <PresenceTransition
        visible={true}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { duration: 250 } }}
      >
        {children}
      </PresenceTransition>
    </VStack>
  );
};

export default InAppCarouselCollectionsSection;
