import { useNavigate } from 'react-router-dom';
import { ILLUSTRATIONS_NAME } from 'ui/components/Illustration';

import { PAGES } from '~/constants/pages.constants';
import { GenericConfirmationPage } from '~/pages/Common/GenericConfirmationPage';

export const ActivityApplicationCancelConfirmation = () => {
  const navigate = useNavigate();

  const handleGoBackHome = () => {
    navigate(PAGES.Opportunities, { replace: true });
  };

  const handleGoToMyActivities = () => {
    navigate(PAGES.Activities, { replace: true });
  };

  return (
    <GenericConfirmationPage
      illustrationName={ILLUSTRATIONS_NAME.ERROR_SORRY_STATE_BROKEN}
      onPressPrimaryButton={handleGoBackHome}
      onPressSecondaryButton={handleGoToMyActivities}
      title={'We’re sorry to see you cancel your application'}
      description={
        'Your engagement is greatly appreciated, and we hope you consider joining us for future activities. Let’s find new opportunities?'
      }
    />
  );
};
