import { HStack, Text, VStack } from 'native-base';
import { useEffect, useState } from 'react';
import Button from 'ui/components/Button';
import Icon from 'ui/components/Icon';
import { colors } from 'ui/theme/colors';

import { FilterSectionName } from '~/components/Layouts/v2/SearchLayout/components/Modals/ActivityFilterModal/constants';
import { ActivityTypeFilterSection } from '~/components/Layouts/v2/SearchLayout/components/Modals/ActivityFilterModal/FilterForm/Sections/ActivityTypeFilter.Section';
import { CausesFilterSection } from '~/components/Layouts/v2/SearchLayout/components/Modals/ActivityFilterModal/FilterForm/Sections/CauseFilter.Section';
import { DateRangeFilterSection } from '~/components/Layouts/v2/SearchLayout/components/Modals/ActivityFilterModal/FilterForm/Sections/DateRangeFilter.Section';
import { DistanceFilterSection } from '~/components/Layouts/v2/SearchLayout/components/Modals/ActivityFilterModal/FilterForm/Sections/DistanceFilter.Section';
import { TeamSizeFilterSection } from '~/components/Layouts/v2/SearchLayout/components/Modals/ActivityFilterModal/FilterForm/Sections/TeamSize.Section';
import { WorkspaceFilterSection } from '~/components/Layouts/v2/SearchLayout/components/Modals/ActivityFilterModal/FilterForm/Sections/WorkSpaceFilter.Section';
import {
  ActivityFilterFormFilters,
  ActivityFilterFormProps,
} from '~/components/Layouts/v2/SearchLayout/components/Modals/ActivityFilterModal/FilterForm/types';
import { MakeActivityFilterFormFilters } from '~/components/Layouts/v2/SearchLayout/components/Modals/ActivityFilterModal/FilterForm/utils';
import {
  DEFAULT_SEARCH_FILTERS,
  SearchFilters,
  useSearch,
} from '~/zustand/store/Search';

import SectionWrapper from './components/SectionWrapper';

export const ActivityFilterForm = ({
  onSubmit,
  submitButtonLabel = 'Filter',
  defaultFilters = DEFAULT_SEARCH_FILTERS,
}: ActivityFilterFormProps) => {
  // Providers
  const closeFilterModal = useSearch((state) => state.closeFilterModal);

  // States
  const [initialFilters] = useState<ActivityFilterFormFilters>(
    MakeActivityFilterFormFilters(defaultFilters),
  );

  const [stagedFilters, setStagedFilters] =
    useState<ActivityFilterFormFilters>(initialFilters);

  useEffect(() => {
    setStagedFilters(initialFilters);
  }, [initialFilters]);

  const stageFilters = (searchFilters?: Partial<SearchFilters>) => {
    setStagedFilters((prev) => ({ ...prev, ...searchFilters }));
  };

  const handleSubmit = (filters?: ActivityFilterFormFilters) => {
    onSubmit?.(filters || stagedFilters);
    closeFilterModal();
  };

  const handleReset = () => {
    handleSubmit(DEFAULT_SEARCH_FILTERS);
  };

  return (
    <VStack position={'relative'} space={8}>
      {/* Body */}

      <VStack space={8} p={{ base: 0, md: 6 }} pb={2}>
        <SectionWrapper title="Activity Type">
          <ActivityTypeFilterSection
            defaultActivityTypes={stagedFilters.activityTypes}
            onChange={stageFilters}
          />
        </SectionWrapper>

        <SectionWrapper title="Causes">
          <CausesFilterSection
            defaultCauses={stagedFilters.causes}
            onChange={stageFilters}
          />
        </SectionWrapper>

        <SectionWrapper title="Workplace">
          <WorkspaceFilterSection
            defaultActivityTypes={stagedFilters.activityTypes}
            defaultAttendanceTypes={stagedFilters.attendanceTypes}
            onChange={stageFilters}
          />
        </SectionWrapper>

        <SectionWrapper
          title="Distance radius"
          subTitle="Only for in person activities"
          name={FilterSectionName.location}
          filters={stagedFilters}
        >
          <DistanceFilterSection
            defaultDistanceRadius={stagedFilters.distanceRadius}
            onChange={stageFilters}
          />
        </SectionWrapper>

        <SectionWrapper
          title="Date of the activity"
          subTitle="Only for events and team events"
          name={FilterSectionName.dateRange}
          filters={stagedFilters}
        >
          <DateRangeFilterSection
            defaultStartDate={stagedFilters.startDate}
            defaultEndDate={stagedFilters.endDate}
            onChange={stageFilters}
          />
        </SectionWrapper>

        <SectionWrapper
          title="Team size"
          subTitle="Only for team events"
          name={FilterSectionName.teamSize}
          filters={stagedFilters}
        >
          <TeamSizeFilterSection
            defaultTeamsMinSize={stagedFilters.teamsMinSize}
            defaultTeamsMaxSize={stagedFilters.teamsMaxSize}
            onChange={stageFilters}
          />
        </SectionWrapper>
      </VStack>

      {/* Footer */}
      <VStack
        p={{ base: 0, md: 6 }}
        py={4}
        pb={5}
        space={3}
        bottom={0}
        style={{ columnGap: 12 }}
        position={'sticky'}
        flexDirection={{ base: 'column', md: 'row-reverse' }}
        backgroundColor={'white'}
        maxWidth={'100%'}
        width={'100%'}
        borderTopWidth={1}
        borderColor={'gray.200'}
      >
        <Button
          px={4}
          flex={1}
          onPress={() => handleSubmit()}
          height={'46px'}
          backgroundColor={colors.singletons.black}
        >
          <HStack alignItems={'center'} space={'10px'}>
            <Text color={colors.white} fontWeight={500} fontSize={'md'}>
              {submitButtonLabel}
            </Text>
          </HStack>
        </Button>

        <Button
          flex={1}
          variant={'outline'}
          onPress={handleReset}
          height={'44px'}
        >
          <HStack alignItems={'center'} space={'10px'}>
            <Icon icon={'delete'} size={16} color={colors.darkText} />
            <Text color={colors.darkText} fontWeight={500} fontSize={'md'}>
              Clear All filters
            </Text>
          </HStack>
        </Button>
      </VStack>
    </VStack>
  );
};
