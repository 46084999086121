import React, { useEffect, useMemo, useRef, useState } from 'react';
import { areArraysEqual } from 'ui/utils/validations/compare';

import { ButtonGroupProps } from './types';

export const useButtonGroupController = ({
  children,
  values = [],
  multi = false,
  isLoading = false,
  onChange,
}: ButtonGroupProps) => {
  const isInitialMount = useRef<boolean>(true);
  const prevSelectedValuesRef = useRef<string[]>(values);
  const [selectedValues, setSelectedValues] = useState<string[]>(values);

  // Hooks
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }

    if (!areArraysEqual(prevSelectedValuesRef.current, values)) {
      setSelectedValues(values);
    }
  }, [values]);

  useEffect(() => {
    if (isInitialMount.current) return;

    const timeoutId = setTimeout(() => {
      onChange?.(selectedValues);
    }, 100);

    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValues]);

  const childrenCount = useMemo(
    () => React.Children.count(children),
    [children],
  );

  // Handlers
  const handlePress = (newValue: string) => {
    setSelectedValues((prevSelectedValues: string[]) => {
      let updatedValues: string[] = [];
      if (prevSelectedValues.includes(newValue)) {
        updatedValues = prevSelectedValues.filter((v) => v !== newValue);
      } else {
        updatedValues = multi ? [...prevSelectedValues, newValue] : [newValue];
      }
      prevSelectedValuesRef.current = updatedValues;
      return updatedValues;
    });
  };

  // Render statements
  const shouldRender = !isLoading && !!childrenCount;

  return { shouldRender, childrenCount, handlePress, selectedValues };
};
