import './styles.css';

import { Box } from 'native-base';
import { ResponsiveValue } from 'native-base/lib/typescript/components/types';

import { Slide } from './types';

const SlidesMemorizer = ({
  slides,
  columns,
  space,
  translateX,
}: {
  slides: Slide[];
  columns: number;
  space?: ResponsiveValue<number>;
  translateX?: string;
}) => {
  return (
    <div className="generic-carousel-slider" style={{ transform: translateX }}>
      {slides.map(({ element }, index) => (
        <Box
          key={index}
          h={'full'}
          minW={`calc(100% / ${columns})`}
          flex={`0 0 calc(100% / ${columns})`}
          alignItems={'center'}
          justifyContent={'center'}
          px={space}
        >
          {element}
        </Box>
      ))}
    </div>
  );
};
export default SlidesMemorizer;
