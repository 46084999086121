import Yup from '~/utils/validations/yup';

import { IConfirmEmailFields } from './types';

export const defaultValues: IConfirmEmailFields = {
  password: '',
  confirmPassword: '',
};

export const schemaValidation = Yup.object({
  password: Yup.string().required(),
  confirmPassword: Yup.string()
    .required()
    .oneOf([Yup.ref('password')], 'Passwords must match'),
});
