import Yup from '~/utils/validations/yup';

import { IConfirmEmailFields } from './types';

export const defaultValues: IConfirmEmailFields = {
  email: '',
};

export const schemaValidation = Yup.object({
  email: Yup.string().required(),
});
