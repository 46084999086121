import { useQuery } from '~/hooks/useQuery';
import { IActivity } from '~/types/interfaces/activity';
import { Coordinates } from '~/utils/getCoordinates';

export const useLoadActivitiesByActivityDefinition = (
  activityDefinitionId: string,
  position?: Coordinates,
  isEnabled = true,
) => {
  const params = position ? { position: JSON.stringify(position) } : {};

  const key = `activity-public-activity-definition-${activityDefinitionId}-${JSON.stringify(
    params,
  )}`;

  const { data, isLoading, isFetched } = useQuery<IActivity[]>(
    `activity-public/activity-definition/${activityDefinitionId}`,
    {
      key,
      queryOptions: { enabled: isEnabled },
      requestOptions: { params },
    },
  );
  return { data: data ?? [], isLoading, isFetched };
};
