import { ActivityType } from 'ui/types/activities';

import {
  ActionSearchFilters,
  ActionSearchRelevanceType,
} from '~/services/resources/activity-definitions/search/actions/actions-public-search.types';
import { mapCausesFilters } from '~/utils/activity-search/map-unified-search-api-filters';
import { SearchFilters } from '~/zustand/store/Search';

export const mapActionsSearchApiFilters = (
  appIds: string[],
  filters: Partial<SearchFilters>,
): Omit<ActionSearchFilters, 'limit' | 'skip'> => {
  const { causeOptions } = mapCausesFilters(filters?.causes || []);

  const apiFilters: Omit<ActionSearchFilters, 'limit' | 'skip'> = {
    relevance: filters.relevance as ActionSearchRelevanceType,
    types: [ActivityType.Action],
    text: filters?.searchText,
    position: null,
    apps: appIds,
    categories: {
      causeOptions,
    },
  };

  return apiFilters;
};
