import { useMediaQuery } from 'native-base';

import { PAGES } from '~/constants/pages.constants';
import { useRouter } from '~/hooks/useRouter';

export const useLogActivitySuccessController = () => {
  const [isMobile] = useMediaQuery({ maxWidth: 1240 });

  const { goToRoute } = useRouter();

  const goToActivities = () => {
    goToRoute(PAGES.LogActivities);
  };

  const goToHome = () => {
    goToRoute(PAGES.Root);
  };

  return { isMobile, goToActivities, goToHome };
};
