import {
  LocationSearchProvider,
  LocationSuggestion,
} from 'ui/components/v2/LocationInputs/types';
import { Coordinates } from 'ui/types/interfaces';

import { GOOGLE_PLACE_PROXY_API } from '~/config';
import { api } from '~/services/api';
import { sanitizeUrl } from '~/utils/transformURL';

export class GooglePlacesAutoCompleteAdapter implements LocationSearchProvider {
  static singletonInstance: GooglePlacesAutoCompleteAdapter;

  static getSingleton() {
    if (!this.singletonInstance) {
      this.singletonInstance = new GooglePlacesAutoCompleteAdapter();
    }
    return this.singletonInstance;
  }

  public async onAutoCompleteLoad(text: string): Promise<LocationSuggestion[]> {
    try {
      const url = sanitizeUrl(
        `${GOOGLE_PLACE_PROXY_API}/auto-complete?text=${text}`,
      );
      const result = await api.get(url);

      if (result.status !== 200) return [];

      const resolution = (result?.data?.predictions ?? []).map(
        GooglePlacesAutoCompleteAdapter.MapGoogleAutoCompleteResponse,
      );

      return resolution;
    } catch (error) {
      return [];
    }
  }

  public async onDetailLoad(placeId: string): Promise<Coordinates | null> {
    try {
      const url = sanitizeUrl(
        `${GOOGLE_PLACE_PROXY_API}/details?placeId=${placeId}`,
      );
      const result = await api.get(url);

      if (result.status !== 200) return null;

      const location = result.data?.result?.geometry?.location ?? null;
      return location;
    } catch (error) {
      return null;
    }
  }

  private static MapGoogleAutoCompleteResponse(
    data: Record<string, string>,
  ): LocationSuggestion {
    return {
      label: data?.description || '',
      value: data?.place_id || '',
      details: {
        placeId: data?.place_id || '',
        description: data?.description || '',
      },
    };
  }
}
