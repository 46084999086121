import 'react-modern-drawer/dist/index.css';

import { HStack, Pressable, Text, VStack } from 'native-base';
import Icon from 'ui/components/Icon';
import { colors } from 'ui/theme/colors';

import { useMobileAppBarController } from '~/components/MobileAppBar/mobileAppBar.controller';
import MobileAppDrawer from '~/components/MobileAppDrawer';
import { Permissions } from '~/components/Permissions';

export const MobileAppBar = () => {
  const {
    handlePressAppBarItem,
    currentPath,
    isDrawerOpen,
    handleToggleDrawer,
    appBarItems,
  } = useMobileAppBarController();

  return (
    <>
      <HStack
        w="100%"
        position="fixed"
        pb={3}
        bottom={0}
        zIndex={1}
        alignItems={'center'}
        justifyContent={'center'}
        bg={'white'}
      >
        {appBarItems.map(
          ({
            text,
            icon,
            pagePath,
            isDrawerTab,
            permissions,
            customRender,
          }) => {
            const isAppBarItemHighlighted =
              pagePath === currentPath && !isDrawerOpen;

            return (
              <Permissions.ValidateAll
                key={pagePath || 'more'}
                requiredPermissions={permissions}
              >
                <Pressable
                  pt={4}
                  borderTopWidth={2.4}
                  borderTopColor={
                    isAppBarItemHighlighted ? 'black' : 'gray.200'
                  }
                  alignItems={'center'}
                  flex={1}
                  onPress={() => handlePressAppBarItem(pagePath, isDrawerTab)}
                >
                  <VStack alignItems={'center'} space={1}>
                    {customRender ? (
                      <>{customRender}</>
                    ) : (
                      <>
                        <Icon
                          size={24}
                          icon={icon}
                          color={
                            isAppBarItemHighlighted
                              ? 'black'
                              : colors.gray['500']
                          }
                        />
                        <Text
                          fontSize={'2xs'}
                          color={isAppBarItemHighlighted ? 'black' : 'gray.600'}
                        >
                          {text}
                        </Text>
                      </>
                    )}
                  </VStack>
                </Pressable>
              </Permissions.ValidateAll>
            );
          },
        )}
      </HStack>

      <MobileAppDrawer isOpen={isDrawerOpen} onToggle={handleToggleDrawer} />
    </>
  );
};
