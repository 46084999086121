import { ISlicedCurrentPathProps } from '~/pages/Authenticated/ViewActivityDetails/components/ActivityBreadCrumb/types';

export const slicedCurrentPath = ({
  currentPath,
  isMobile,
}: ISlicedCurrentPathProps) => {
  if (isMobile) {
    return currentPath.length >= 20
      ? `${currentPath.slice(0, 20)}...`
      : currentPath;
  }

  return currentPath.length >= 50
    ? `${currentPath.slice(0, 50)}...`
    : currentPath;
};
