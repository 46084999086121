export const VALIDATION_MESSAGES = {
  DEFAULT: 'Invalid field',
  REQUIRED: 'The field is required',
  EMAIL: 'Invalid email',
  PHONE: 'Invalid phone',
  PASSWORD_NOT_SAFE:
    'Your password must contain more than 8 characters containing: uppercase, lowercase, numbers and special character',
  PASSWORD_COMPARE: 'Passwords do not match, check and try again.',
  MAX_LENGTH: 'The maximum number of characters allowed is ${max}',
  MIN_LENGTH: 'The minimum number of characters allowed is ${min}',
  ONLY_LETTERS_NUMBERS: 'Only letters and numbers',
  INVALID_DATE: 'Invalid date',
};
