import { VStack } from 'native-base';
import { ResponsiveValue } from 'native-base/lib/typescript/components/types';
import { ReactNode } from 'react';

export enum CenterContainerSize {
  lg,
  xl,
}

export const CenterContainerSizeMap = {
  [CenterContainerSize.lg]: '1440px',
  [CenterContainerSize.xl]: '1760px',
};

export type CenterContainerProps = {
  children?: ReactNode;
  maxWidth?: number | string;
  px?: ResponsiveValue<string | number>;
  size?: CenterContainerSize;
};
export const CenterContainer = ({
  children,
  maxWidth,
  px = [2, 4, 8],
  size = CenterContainerSize.lg,
}: CenterContainerProps) => {
  const mw = maxWidth || CenterContainerSizeMap[size];

  return (
    <VStack
      px={px}
      flex={1}
      w="full"
      maxWidth={mw}
      marginLeft={'auto'}
      marginRight={'auto'}
    >
      {children}
    </VStack>
  );
};
