import { useMemo } from 'react';

import { ActivityCarouselProps } from '~/components/v2/ActivityCarousel/types';
import { SearchCollectionSlug } from '~/types/interfaces/activity-search/types';
import { useSearch } from '~/zustand/store/Search';

import { useCarouselsSuggestionsController } from '../../controller';

export const useFeedFooterCarouselsSuggestion = () => {
  // Providers
  const { suggestions } = useSearch((state) => state);

  // Hooks
  const { isFilterSet, carouselsMap } = useCarouselsSuggestionsController();

  const availableCarouselSuggestions = useMemo(() => {
    const { processedCollections, candidateCollections } = suggestions;

    const result: Partial<
      Record<SearchCollectionSlug, ActivityCarouselProps>
    >[] = [];

    for (let i = 0; i < candidateCollections.length; i++) {
      const slug = candidateCollections[i];
      if (!processedCollections.includes(slug) && (carouselsMap as any)[slug]) {
        result.push((carouselsMap as any)[slug]);
      }
    }

    return result;
  }, [carouselsMap, suggestions]);

  return { isFilterSet, availableCarouselSuggestions };
};
