import { GOOGLE_PLACE_PROXY_API } from '~/config';
import { api } from '~/services/api';
import { sanitizeUrl } from '~/utils/transformURL';

export type GooglePlaceSugestions = {
  description: string;
  placeId: string;
};

export type GooglePlaceGeocode = {
  lat: number;
  lng: number;
};

export class GooglePlacesService {
  static async autoComplete(
    addressText: string,
  ): Promise<GooglePlaceSugestions[]> {
    try {
      const url = sanitizeUrl(
        `${GOOGLE_PLACE_PROXY_API}/auto-complete?text=${addressText}`,
      );
      const result = await api.get(url);

      if (result.status !== 200) return [];

      const resolution = (result?.data?.predictions ?? []).map(
        GooglePlacesService.MapGooglePlaceResponse,
      );

      return resolution;
    } catch (error) {
      return [];
    }
  }

  static async getGeocodeByPlaceId(
    placeId: string,
  ): Promise<GooglePlaceGeocode | null> {
    try {
      const url = sanitizeUrl(
        `${GOOGLE_PLACE_PROXY_API}/details?placeId=${placeId}`,
      );
      const result = await api.get(url);

      if (result.status !== 200) return null;

      const location = result.data?.result?.geometry?.location ?? null;
      return location;
    } catch (error) {
      return null;
    }
  }

  private static MapGooglePlaceResponse(data: Record<string, string>) {
    return {
      description: data?.description || '',
      placeId: data?.place_id || '',
    };
  }
}
