import { FlatList, VStack } from 'native-base';
import { useCallback, useMemo } from 'react';

import { useGridController } from './controller';
import GridRow from './GridRows';
import { GridProps } from './types';

export const Grid = (props: GridProps<unknown, unknown>) => {
  const {
    data,
    spanData,
    isLoading,
    renderItem,
    keyExtractor,
    renderSpanItem,
    renderSkeletonItem,
  } = props;

  const { gridColumns, groupedData, spanPerRowsValue, isLoadingForward } =
    useGridController(props);

  /* Render span Components */
  const renderSpanComponent = useCallback(
    (rowIndex: number) => {
      const spanIndex = Math.floor(rowIndex / spanPerRowsValue);
      const spanItem = spanData?.[spanIndex];
      const showSpan = (rowIndex + 1) % spanPerRowsValue === 0 && spanItem;

      if (!showSpan || !renderSpanItem) return null;

      return (
        <div style={{ width: '100%', gridColumn: '1 / -1' }}>
          {renderSpanItem(spanItem, spanIndex)}
        </div>
      );
    },

    [spanPerRowsValue, spanData, renderSpanItem],
  );

  /* Skeleton row */
  const renderSkeletonRow = useMemo(() => {
    return renderSkeletonItem ? (
      <GridRow
        data={Array(gridColumns).fill(null)}
        gridColumns={gridColumns}
        renderItem={() => renderSkeletonItem()}
        renderSkeletonItem={() => renderSkeletonItem()}
      />
    ) : null;
  }, [gridColumns, renderSkeletonItem]);

  /* Grid items row */
  const renderGridRow = useCallback(
    ({ item, index }: { item: unknown[]; index: number }) => (
      <>
        <GridRow
          data={item}
          gridColumns={gridColumns}
          renderItem={renderItem}
          keyExtractor={keyExtractor}
          renderSkeletonItem={renderSkeletonItem}
          showSkeleton={isLoadingForward}
        />
        {renderSpanComponent(index)}
      </>
    ),
    [
      gridColumns,
      renderItem,
      keyExtractor,
      isLoadingForward,
      renderSkeletonItem,
      renderSpanComponent,
    ],
  );

  /* Initial Loading */
  if (isLoading)
    return (
      <FlatList w={'full'} data={[1, 2]} renderItem={() => renderSkeletonRow} />
    );

  /* Empty */
  if (!data.length) return null;

  return (
    <VStack>
      <FlatList
        w={'full'}
        key={gridColumns}
        data={groupedData}
        initialNumToRender={gridColumns}
        maxToRenderPerBatch={gridColumns}
        removeClippedSubviews
        windowSize={2}
        renderItem={renderGridRow}
      />
      {/* Loading forward data */}
      {isLoadingForward && renderSkeletonRow}
    </VStack>
  );
};
