import { useMemo } from 'react';
import { colors } from 'ui/theme/colors';

import ActivityInfoCard from '~/pages/Authenticated/v2/ActivityView/components/ActivityInfoCard';
import { InfoLocationTrailing } from '~/pages/Authenticated/v2/ActivityView/components/ActivityInfoCard/components/InfoLocationTrailing';
import { OngoingApplicationCardProps } from '~/pages/Authenticated/v2/ActivityView/views/ActivityLog/components/OngoingApplicationCard/types';

export const OngoingApplicationCard: React.FC<OngoingApplicationCardProps> = (
  props,
) => {
  const { activity, onPress, isSelected, isFromHome } = props;

  const cardLabels = useMemo(() => {
    const locationLabel = activity?.address?.street || '';
    const labels = [];

    if (isFromHome) {
      labels.push({
        key: 'from-home',
        icon: 'map-pin',
        text: 'From home',
        numberOfLines: 1,
      });
    } else {
      labels.push({
        key: 'address',
        text: locationLabel,
        numberOfLines: 2,
      });
    }

    if (activity?.isOnline === true) {
      labels.push({ key: 'online', icon: 'wifi', text: 'Online' });
    }

    return labels;
  }, [activity, isFromHome]);

  return (
    <ActivityInfoCard
      activity={activity}
      onPress={onPress}
      trailingContent={
        <InfoLocationTrailing
          icon={isFromHome ? 'home' : 'map-pin'}
          color={isSelected ? colors.white : colors.black}
        />
      }
      isSelected={isSelected}
      labels={cardLabels}
    />
  );
};

export default OngoingApplicationCard;
