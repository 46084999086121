import { View, VStack } from 'native-base';
import React from 'react';

import {
  CenterContainer,
  CenterContainerSize,
} from '~/components/Layouts/Scaffolds/CenterContainer';
import { ActivityCarousel } from '~/components/v2/ActivityCarousel';

import { useFeedSpanCarouselsSuggestion } from './controller';
import { FeedSpanCarouselsSuggestionProps } from './types';

export const FeedSpanCarouselsSuggestion = ({
  collectionSlug,
}: FeedSpanCarouselsSuggestionProps) => {
  const { selectedCarousel, isFilterSet } = useFeedSpanCarouselsSuggestion({
    collectionSlug,
  });
  if (!selectedCarousel) return null;

  return (
    <View my={2}>
      <VStack bgColor={'gray.100'} py={8}>
        <CenterContainer size={CenterContainerSize.xl}>
          <ActivityCarousel {...selectedCarousel} isEnabled={!!isFilterSet} />
        </CenterContainer>
      </VStack>
    </View>
  );
};

export default React.memo(FeedSpanCarouselsSuggestion);
