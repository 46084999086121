export enum PAGES {
  Root = '/',

  /* -- New routes -- */
  Home = '/home',
  Search = '/search',
  SearchCollection = '/search/:collection',
  SearchActivityDetails = '/search/activities/:id', // TODO: implement this route

  InApp = '/apps/:appId',
  InAppSearch = '/apps/:appId/search',
  InAppSearchCollection = '/apps/:appId/search/:collection',
  InAppActivityDetails = '/apps/:appId/activities/:id',
  /* -- New routes -- */

  // Unauthenticated routes

  // without slug
  Invitation = '/invite/member',
  InvitationSignup = '/invite/member/:code/signup',
  InvitationSignIn = '/invite/member/:code/sign-in',
  InvitationDeclined = '/invitation-declined',

  // with slug
  InvitationSlug = '/:slug/invite/member',
  InvitationSignupSlug = '/:slug/invite/member/:code/signup',
  InvitationSignInSlug = '/:slug/invite/member/:code/sign-in',

  SignIn = '/sign-in',
  SignUp = '/sign-up',
  ResetPassword = '/reset-password',
  AccessCode = '/access-code',
  AuthorizationPersonalData = '/authorization-personal-data',
  ConfirmEmail = '/confirm-email-reset-password',
  TeamInvitation = '/invite/team',
  GuestOpportunities = '/opportunities',
  GuestActivityDetails = '/activities/:id',

  // Authenticated routes
  // - My Wallet
  Opportunities = '/my-wallet/find-opportunities', // Home page
  Activities = '/my-wallet/activities',
  ExternalApplyLinkRedirect = '/my-wallet/redirect/:id',
  ActivitiesDetails = '/my-wallet/activities/:id',
  LogActivities = '/my-wallet/log-activity',
  LogActivity = '/my-wallet/log-activity/:id',
  LogActivitySuccess = '/my-wallet/log-activity/:id/success',
  AllApps = '/my-wallet/all-apps',
  Profile = '/my-wallet/profile',
  Teams = '/my-wallet/teams',
  CreateTeam = '/my-wallet/teams/create',
  EditTeam = '/my-wallet/teams/edit',
  ViewTeam = '/my-wallet/teams/details',
  // - Activities
  Event = '/my-wallet/event/:eventId',
  EventApplication = '/my-wallet/event/:eventId/apply',
  TeamEventApplication = '/my-wallet/team/:teamId/event/:eventId/apply',
  Action = '/my-wallet/action/:actionId',
  JoinAction = '/my-wallet/action/:actionId/join',
  OngoingOpportunityApplication = '/my-wallet/ongoing-opportunity/:eventId/apply',
  OpportunityApplicationConfirmation = '/my-wallet/apply-confirmation',
  CancelApplicationConfirmation = '/my-wallet/cancel-application-confirmation',
  CancelApplication = '/my-wallet/activity/cancel',
  EndActivity = '/my-wallet/activity/thanks-for-your-application',
  // - Log Activity
  LogAction = '/my-wallet/log-action',
  LogOngoingOpportunity = '/my-wallet/log-ongoing-opportunity',
  LogEvent = '/my-wallet/log-event',

  // OffPlatform Activity
  CreateOffPlatformActivity = '/my-wallet/log-off-platform/create',
  UpdateOffPlatformActivity = '/my-wallet/log-off-platform/:definitionId/edit',
  OffplatformActivitySuccess = '/my-wallet/log-off-platform/success',

  // - Settings
  AccountSettings = '/settings/account-settings',
  ChangePassword = '/settings/change-password',
  EmailPreferences = '/settings/email-preferences',
  DeleteAccount = '/settings/delete-account',
  AccountDeleted = '/account-deleted',

  SwitchPersona = '/switch-persona',

  // Common routes
  Page404 = '/404',
  Page403 = '/403',

  // TODO: Remove this route after finish the compound components
  Test = '/test',

  // MiniApp
  InAppRoot = '/mini-app/:appId',
  InAppSearchView = '/mini-app/:appId/find-opportunities',
}

export const pagesNameDictionary = {
  [PAGES.Activities]: 'Activities',
  [PAGES.Invitation]: 'Invitation',
  [PAGES.InvitationDeclined]: 'Invitation Declined',
  [PAGES.SignIn]: 'Sign In',
  [PAGES.SignUp]: 'Sign Up',
  [PAGES.ResetPassword]: 'Reset Password',
  [PAGES.Opportunities]: 'Opportunities',
  [PAGES.LogActivity]: 'Log Activity',
  [PAGES.LogActivities]: 'Log Activity',
  [PAGES.AllApps]: 'Apps',
  [PAGES.Profile]: 'Profile',
  [PAGES.Event]: 'Event',
  [PAGES.EventApplication]: 'Event Application',
  [PAGES.Action]: 'Action',
  [PAGES.JoinAction]: 'Join Action',
  [PAGES.CancelApplication]: 'Cancel Application',
  [PAGES.EndActivity]: 'End Activity',
  [PAGES.LogAction]: 'Log Action',
  [PAGES.LogOngoingOpportunity]: 'Log Ongoing Opportunity',
  [PAGES.LogEvent]: 'Log Event',
  [PAGES.AccountSettings]: 'Account Settings',
  [PAGES.EmailPreferences]: 'Email Preferences',
  [PAGES.Teams]: 'Teams',
  [PAGES.CreateTeam]: 'New team',
  [PAGES.EditTeam]: 'Edit team',
  [PAGES.Page404]: 'Page 404',
  [PAGES.Page403]: 'Page 403',
  [PAGES.Test]: 'Test',
  // MiniApp
  [PAGES.InAppSearchView]: 'Search',
};
