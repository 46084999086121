import { useEffect, useState } from 'react';

import { useAppSelector } from '~/hooks/useAppSelector';

export const useDebugMode = () => {
  const { isDebugMode } = useAppSelector((state) => state.session);
  return { debugMode: isDebugMode };
};

const useDebugToggle = () => {
  const [showDebugButton, setShowDebugButton] = useState(false);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.shiftKey && event.key === '!') {
        setShowDebugButton((prev) => !prev); // Toggle visibility
      }
    };
    // Add keydown event listener
    window.addEventListener('keydown', handleKeyDown);

    // Clean up event listener
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const show = showDebugButton;
  return { show };
};

export default useDebugToggle;
