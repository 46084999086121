import { MaintenanceAlertBar } from '~/components/v2/MaintenanceAlertBar';
import useFeatureFlag from '~/hooks/useFeatureFlag/useFeatureFlag';
import { FeatureFlagKey } from '~/types/interfaces/feature-flag';

export const AlertsContainer = () => {
  const { isFeatureEnabled } = useFeatureFlag(FeatureFlagKey.maintenance);
  /* const { isFeatureEnabled: isNewNavigationModalEnabled } = useFeatureFlag(
    FeatureFlagKey.newNavigationModal,
  );
 */
  return (
    <>
      {isFeatureEnabled ? <MaintenanceAlertBar defaultShow /> : null}
      {/*       <NewNavigationModal />*/}
    </>
  );
};
