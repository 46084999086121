import { IOffPlatformDefinitionFormFields } from '~/pages/Authenticated/OffPlatformApplication/components/DefinitionForm/types';
import Yup from '~/utils/validations/yup';

export const schemaValidation = Yup.object({
  type: Yup.object().required('The field is required'),
  title: Yup.string().required(),
  description: Yup.string().optional(),
  organizationName: Yup.string().required(),
  causeOptions: Yup.array().min(1).required(),
  isFromHome: Yup.boolean().default(false),
  measurementUnitIds: Yup.array().min(1).required(),
  location: Yup.object().when('isFromHome', {
    is: (isFromHome: boolean) => !isFromHome,
    then: Yup.object().required('Location is required when not from home'),
    otherwise: Yup.object().optional(),
  }),
});

export const OFF_PLATFORM_APPLICATION_FORM_DATA: IOffPlatformDefinitionFormFields =
  {
    type: null,
    title: '',
    description: '',
    organizationName: '',
    causeOptions: [],
    isFromHome: false,
    measurementUnitIds: [],
    location: undefined,
  };
