import { HStack, Text } from 'native-base';
import Icon from 'ui/components/Icon';
import { colors } from 'ui/theme/colors';

import { ActivityAttributeLabelProps } from '~/pages/Authenticated/v2/ActivityView/components/ActivityCardElements/ActivityAttributeLabel/types';

export const ActivityAttributeLabel = ({
  text,
  icon,
  fontColor = colors.darkText,
  iconColor = colors.gray[400],
  hightLight = false,
  numberOfLines = 1,
}: ActivityAttributeLabelProps) => {
  return (
    <HStack space={1.5} alignItems={'center'}>
      {icon && <Icon icon={icon} size={'20px'} color={iconColor} />}
      <Text
        numberOfLines={numberOfLines}
        fontSize={'sm'}
        fontWeight={hightLight ? 500 : 400}
        color={fontColor}
        lineHeight={'100%'}
        flex={1}
      >
        {text}
      </Text>
    </HStack>
  );
};
