import { Hidden, HStack, Text, VStack } from 'native-base';
import Button from 'ui/components/Button';
import { colors } from 'ui/theme/colors';

export type OffPlatformActivitiesHeaderProps = {
  onClick: VoidFunction;
};

export const OffPlatformActivitiesHeader: React.FunctionComponent<
  OffPlatformActivitiesHeaderProps
> = (props: OffPlatformActivitiesHeaderProps) => {
  const { onClick } = props;
  return (
    <HStack
      pb={1}
      justifyContent={'space-between'}
      alignItems={'center'}
      background={colors.white}
      position={'sticky'}
      top={0}
    >
      <VStack>
        <Text
          fontSize={'xl'}
          fontWeight={'500'}
          noOfLines={1}
          color={colors.black}
          lineHeight={'150%'}
        >
          <Hidden till={'lg'}>
            <>Log your activities done outside Do IT </>
          </Hidden>
          <Hidden from={'lg'}>
            <>Off-platform </>
          </Hidden>
        </Text>

        <Hidden from={'lg'}>
          <Text fontSize="xs" fontWeight={'400'} fontStyle={'normal'}>
            Activities done outside Do IT
          </Text>
        </Hidden>
      </VStack>

      <Hidden till={'sm'}>
        <Button
          leftIconName={'wifi-off'}
          leftIconColor="white"
          leftIconSize={16}
          onPress={onClick}
          h={37}
          maxW={'228px'}
        >
          <Text color="white" fontWeight={500}>
            New activity off-platform
          </Text>
        </Button>
      </Hidden>
    </HStack>
  );
};

export default OffPlatformActivitiesHeader;
