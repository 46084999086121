import { Button, Hidden, HStack, Stack, Text, VStack } from 'native-base';
import Illustration from 'ui/components/Illustration';

import { ListEmptyStateProps } from './types';

export const ListEmptyState = ({
  illustrationName,
  text,
  buttonTitle,
  onButtonPressed,
}: ListEmptyStateProps) => {
  return (
    <HStack
      h="full"
      w={'100%'}
      alignItems={'center'}
      justifyContent={'center'}
      space={4}
    >
      <Hidden till={'sm'}>
        <Stack
          height="100%"
          w={'35%'}
          justifyContent="center"
          alignItems="flex-end"
        >
          <Illustration
            name={illustrationName}
            width={{ base: 150, lg: 168 }}
            height={{ base: 150, lg: 168 }}
          />
        </Stack>
      </Hidden>
      <VStack
        w={{
          base: '100%',
          md: '55%',
        }}
        justifyContent="center"
        alignItems={{
          base: 'center',
          md: 'flex-start',
        }}
      >
        <VStack space={6} px={{ base: 4 }}>
          <Stack>
            <Text
              fontSize={{ base: 'xs', lg: 'sm' }}
              textAlign={{ base: 'center', lg: 'left' }}
              color={'gray.500'}
              maxW={{
                md: '65%',
                base: '100%',
              }}
            >
              {text}
            </Text>
          </Stack>
          {buttonTitle && (
            <Button
              alignSelf={{ base: 'center', lg: 'flex-start' }}
              w={'container'}
              onPress={onButtonPressed}
            >
              {buttonTitle}
            </Button>
          )}
        </VStack>
      </VStack>
    </HStack>
  );
};
