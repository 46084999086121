import { Box, Hidden, HStack, Progress, Stack, Text } from 'native-base';
import React from 'react';
import Icon, { IconEnum } from 'ui/components/Icon';
import { MeasurementCategory } from 'ui/enums';
import { colors } from 'ui/theme/colors';
import { secondsToHoursAndMinutes } from 'ui/utils/secondsToDuration';

import { LogProgressAndHistoryCardProps } from '~/compound-components/log-progress-and-history/log-progress-and-history-footer/log-progress-and-history-footer.types';

export const LogProgressAndHistoryCard = ({
  children,
  goalCategory,
  goalUnit,
  goalValue = 0,
  goalProgress = 0,
  onGoingActivityTotalHours,
  steps,
  totalStepsLogged,
  isAction,
}: LogProgressAndHistoryCardProps) => {
  const isStarted = !!goalProgress;
  const isDone = goalProgress >= goalValue;

  const formatMeasurementToStringHours = (value: number) => {
    const { hours, minutes } = secondsToHoursAndMinutes(value);
    return `${hours}:${minutes}`;
  };

  const renderHeader = (children: React.ReactNode) => {
    return (
      <HStack alignItems="center" space={{ base: 0, lg: 4 }}>
        {children}
        <Hidden till="lg">
          <Box pt={1}>
            <Icon
              isCustom
              size={6}
              icon={IconEnum.CARET_DOWN}
              color={'black'}
            />
          </Box>
        </Hidden>
      </HStack>
    );
  };

  if (goalCategory === MeasurementCategory.Time && !isAction) {
    return (
      <>
        <HStack w={'full'} alignItems={'center'} space={2}>
          <HStack space={2} alignItems="center" w="full">
            <Icon icon={'clock'} size={20} color={colors.gray['600']} />
            <Text textTransform={'uppercase'} color={'gray.600'}>
              you&#39;ve done
            </Text>
            <Text fontSize={'lg'} fontWeight={'500'}>
              {onGoingActivityTotalHours || '0h:00m'}
            </Text>
          </HStack>
          <Hidden till="lg">
            <Box pt={1}>
              <Icon
                isCustom
                size={6}
                icon={IconEnum.CARET_DOWN}
                color={'black'}
              />
            </Box>
          </Hidden>
        </HStack>
        {children}
      </>
    );
  }

  if (goalCategory === MeasurementCategory.Completion) {
    return (
      <>
        <HStack
          w={'full'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Text fontWeight={500}>
            {isStarted && isDone ? 'Done!' : `${goalProgress} ${goalUnit}`}
          </Text>
          <HStack alignItems="center" space={4}>
            {renderHeader(
              <HStack space={1} alignItems="center">
                <Icon
                  isCustom
                  icon={IconEnum.TARGET_AND_ARROW}
                  size={4}
                  color={'gray.600'}
                />
                <Text fontSize={'xs'} color={'gray.600'} fontWeight={500}>
                  {`${goalValue} ${goalUnit}`}
                </Text>
              </HStack>,
            )}
          </HStack>
        </HStack>
        <Box h={2} />

        <HStack space={2} width={{ base: '100%', lg: '93%' }}>
          {steps?.map((step, index) => (
            <Stack w={`1/${steps.length}`} key={step.title}>
              <Progress
                value={totalStepsLogged && totalStepsLogged > index ? 20 : 0}
                max={20}
                bg={'gray.300'}
                h={1}
                _filledTrack={{ bg: 'black' }}
              />
            </Stack>
          ))}
        </HStack>

        {children}
      </>
    );
  }

  return (
    <>
      <HStack w={'full'} justifyContent={'space-between'} alignItems={'center'}>
        <Text fontWeight={500}>
          {isStarted && isDone
            ? 'Done!'
            : goalCategory === MeasurementCategory.Time && isAction
            ? `${formatMeasurementToStringHours(goalProgress)} ${goalUnit}`
            : `${goalProgress} ${goalUnit}`}
        </Text>
        {renderHeader(
          <HStack space={1}>
            <Icon
              isCustom
              icon={IconEnum.TARGET_AND_ARROW}
              size={4}
              color={'gray.600'}
            />
            <Text fontSize={'xs'} color={'gray.600'} fontWeight={500}>
              {goalCategory === MeasurementCategory.Time && isAction
                ? `${formatMeasurementToStringHours(goalValue)} ${goalUnit}`
                : `${goalValue} ${goalUnit}`}
            </Text>
          </HStack>,
        )}
      </HStack>
      <Box h={2} />
      <Progress
        width={{ base: '100%', lg: '93%' }}
        value={goalProgress}
        max={goalValue}
        bg={'gray.300'}
        h={1}
        _filledTrack={{ bg: 'black' }}
      />
      {children}
    </>
  );
};
