import { isAfter } from 'date-fns';
import { ActivityType } from 'ui/types/activities';

import {
  ActivityApplicationEnum,
  IActivityApplication,
} from '~/types/interfaces/activity';
import {
  AvailabilityResult,
  Available,
  Unavailable,
} from '~/utils/activityAvailability/types';

const APPLICATION_STATUS_WHITE_LIST = [
  ActivityApplicationEnum.Approved,
  ActivityApplicationEnum.Referral,
];

export const getLogAvailabiltyByApplication = (
  application?: IActivityApplication,
): AvailabilityResult => {
  if (!application) {
    return new Unavailable('You have not applied to this activity yet.');
  }

  if (!application?.app)
    return new Unavailable('This activity is no longer available');

  const activityDefinition =
    application?.activitySubDocument?.activityDefinitionSubDocument;

  const type = (activityDefinition?.type as ActivityType) || '';

  if (application.logHoursDisabled === true) {
    new Unavailable('Log disabled');
  }

  switch (application.status) {
    case ActivityApplicationEnum.Rejected:
      return new Unavailable('Your application has been rejected.');
    case ActivityApplicationEnum.Canceled:
      return new Unavailable('Your application has been cancelled.');
    case ActivityApplicationEnum.Pending:
      return new Unavailable('Your application is waiting for approval.');
    default: {
      if (!APPLICATION_STATUS_WHITE_LIST.includes(application?.status)) {
        return new Unavailable('Unknown status.');
      }
      break;
    }
  }

  // -- Events Only
  if (type === ActivityType.Event) {
    const hasActivityEnded = isAfter(
      new Date(),
      new Date(application.activitySubDocument.endDate),
    );
    if (!hasActivityEnded) {
      return new Unavailable(
        'You will be able to log in as soon as the event occurs.',
      );
    }
  }

  /* 
    -- Rule disabled temporary --
    For the 'action' type, it was defined not to block logs after reaching the goal. 
    However, I imagine that this rule may be reinstated in the future
  
  '''
    if (
      activityDefinition.targetAmount &&
      (activityDefinition?.targetAmount || 0) <=
        (application.measurementTargetAmount || 0)
    ) {
      return new Unavailable('you have already achieved the target.');
    } 
  '''
  */

  return new Available();
};
