import { ILocation } from '../../../types/interfaces';
import { LocationSuggestion } from './types';

export const parseLocationToSuggestion = (
  location?: ILocation | null,
): LocationSuggestion | null => {
  if (!location) return null;

  return {
    value: location.placeID || '',
    label: location.rawLocation || '',
    details: {
      description: location.rawLocation || '',
      placeId: location.placeID || '',
    },
  };
};
