import './styles.css';

import { motion } from 'framer-motion';
import { Box, Pressable, useBreakpointValue, VStack } from 'native-base';
import { useCallback, useMemo } from 'react';
import Icon from 'ui/components/Icon';
import DynamicSvg from 'ui/components/v2/DynamicSvg';
import { colors } from 'ui/theme/colors';

import { RoundButtonProps, RoundButtonSize, RoundButtonSizeMap } from './types';

export const RoundButton = (props: RoundButtonProps) => {
  const {
    icon,
    isActive,
    imageUrl,
    children,
    size = [RoundButtonSize.lg],
    iconColor = colors.singletons.black,
    backgroundColor = `${colors.grey.bg}1A`,
    activeBackgroundColor = colors.singletons.black,
    width = '88px',
    onPress,
  } = props;

  const sizeValue = useBreakpointValue(size);

  const { boxSize, iconSize } = useMemo(() => {
    return RoundButtonSizeMap[sizeValue as RoundButtonSize];
  }, [sizeValue]);

  const handlePress = useCallback(() => {
    onPress?.();
  }, [onPress]);

  const bgColor = isActive ? activeBackgroundColor : backgroundColor;

  const iconFontColor = isActive ? colors.singletons.white : iconColor;

  return (
    <Pressable onPress={handlePress}>
      <div className={'round-button-container'} style={{ width }}>
        <motion.div
          className="round-button-crop"
          initial={{ opacity: 0, rotateY: 0 }}
          animate={{ opacity: 1, rotateY: isActive ? 180 : 0 }}
          whileTap={{
            rotateY: 180,
            transition: { type: 'spring', stiffness: 500, damping: 30 },
          }}
          transition={{
            type: 'spring',
            stiffness: 100,
            duration: 0.8,
            backgroundColor: { duration: 0.5 },
          }}
          style={{
            height: boxSize,
            width: boxSize,
            backgroundColor: bgColor,
            perspective: 1000,
            outlineColor: iconFontColor,
          }}
        >
          {/* Close button */}
          {isActive && (
            <Box
              position={'absolute'}
              top={0}
              right={-6}
              borderRadius={100}
              width={'20px'}
              height={'20px'}
              backgroundColor={'gray.200'}
              alignItems={'center'}
              justifyContent={'center'}
            >
              <Icon
                enableAutoCustom
                icon={'x'}
                size={'16px'}
                color={colors.singletons.black}
              />
            </Box>
          )}
          {/* Icon */}
          <VStack justifyContent={'center'} alignItems={'center'} flex={1}>
            {icon ? (
              <Icon
                enableAutoCustom
                icon={icon}
                size={iconSize}
                color={iconFontColor}
              />
            ) : (
              <>
                <DynamicSvg
                  imageUrl={imageUrl || ''}
                  fillColor={iconFontColor}
                  size={iconSize}
                  className={`icon-image`}
                />
              </>
            )}
          </VStack>
        </motion.div>

        {children}
      </div>
    </Pressable>
  );
};
