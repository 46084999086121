import { Checkbox, HStack, Stack, Text, VStack } from 'native-base';
import { Controller } from 'react-hook-form';
import TextArea from 'ui/components/TextArea';
import Select from 'ui/components/v2/inputs/Select';
import TextInput from 'ui/components/v2/inputs/TextInput';
import LocationAutoComplete from 'ui/components/v2/LocationInputs/LocationAutoComplete';

import { useOffPlatformDefinitionFormController } from '~/pages/Authenticated/OffPlatformApplication/components/DefinitionForm/controller';
import { IOffPlatformDefinitionProps } from '~/pages/Authenticated/OffPlatformApplication/components/DefinitionForm/types';
import OffPlatformFormFooter from '~/pages/Authenticated/OffPlatformApplication/components/FormFooter';
import { GooglePlacesAutoCompleteAdapter } from '~/services/google/google-places-auto-auto-complete';

import { activityTypesOptions } from '../../constants';

const googlePlacesAutoComplete = GooglePlacesAutoCompleteAdapter.getSingleton();

export const OffPlatformDefinitionForm = (
  props: IOffPlatformDefinitionProps,
) => {
  const { causeOptions, measurementUnitOptions } = props;

  const { control, onSubmitHandler, formState, onCancelHandler } =
    useOffPlatformDefinitionFormController(props);

  return (
    <VStack w="full" space={4} p={{ base: 4, md: 6 }}>
      <Select.Controlled
        control={control}
        name="type"
        label="Type of activity"
        placeholder="Choose a type of activity"
        options={activityTypesOptions}
        errorMessage={formState.errors?.type?.message}
        isLoading={!activityTypesOptions.length}
        isRequired
      />
      <TextInput.Controlled
        control={control}
        name="title"
        label="Title"
        placeholder="Title of the activity"
        errorMessage={formState.errors?.title?.message}
        isRequired
      />
      <Stack>
        <TextArea
          control={control}
          name="description"
          label="Description"
          fontSize={16}
          placeholder="Describe the activity (optional)."
          errorMessage={formState.errors?.description?.message}
        />
      </Stack>
      <TextInput.Controlled
        control={control}
        name="organizationName"
        label="Charity"
        placeholder="Enter the name of the charity"
        errorMessage={formState.errors?.organizationName?.message}
        isRequired
      />
      <Select.Controlled
        isMulti
        control={control}
        name="causeOptions"
        label="Causes"
        options={causeOptions}
        errorMessage={formState.errors?.causeOptions?.message}
        isLoading={!causeOptions.length}
        isClearable
        isRequired
      />
      <HStack
        direction={{ base: 'row', lg: 'row' }}
        space={6}
        w="full"
        alignItems={'center'}
        style={{ zIndex: 99 }}
      >
        <Stack w={{ base: '100%' }}>
          <Controller
            name="location"
            control={control}
            render={({ field: { onChange, value } }) => (
              <LocationAutoComplete
                label="Location"
                onChange={onChange}
                defaultValue={value}
                placeholder="Post code / Town"
                provider={googlePlacesAutoComplete}
                errorMessage={formState.errors?.location?.message?.toString()}
                height={'3.5rem'}
                isRequired
              />
            )}
          />
        </Stack>
        <Stack w="160px" h={'full'} marginTop={'32px'}>
          <Controller
            control={control}
            name="isFromHome"
            render={({ field: { name, value, onChange } }) => (
              <Checkbox
                name={name}
                value="FROM_HOME"
                isChecked={value}
                onChange={onChange}
                rounded={'50%'}
              >
                <Text fontSize={'14px'} fontWeight={400}>
                  From home
                </Text>
              </Checkbox>
            )}
          />
        </Stack>
      </HStack>

      <Stack width="full" zIndex={1}>
        <Select.ControlledGroup
          name="measurementUnitIds"
          label="Unit of measurement"
          control={control}
          options={measurementUnitOptions}
          errorMessage={formState.errors?.measurementUnitIds?.message}
          uniqueOptions
          isRequired
        />
      </Stack>

      <OffPlatformFormFooter
        onAction={onSubmitHandler}
        onCancel={onCancelHandler}
      />
    </VStack>
  );
};

export default OffPlatformDefinitionForm;
