import { Button, Flex } from 'native-base';
import Tooltip from 'ui/components/v2/Tooltip';

import { IOffPlatformFormFooterProps } from '~/pages/Authenticated/OffPlatformApplication/components/FormFooter/types';

export const OffPlatformFormFooter = (props: IOffPlatformFormFooterProps) => {
  const {
    actionLabel = 'Continue',
    isLoading = false,
    isActionDisabled = false,
    onAction,
    onCancel,
  } = props;

  return (
    <Flex
      width="full"
      flexDirection={{ base: 'column', md: 'row' }}
      flexDir={{ base: 'column-reverse' }}
      style={{ gap: 16 }}
      mt={'2rem'}
      zIndex={0}
    >
      <Button
        variant="ghost"
        colorScheme="darkText"
        flex={1}
        height={44}
        onPress={onCancel}
      >
        Cancel
      </Button>
      <Tooltip
        width={{ base: '100%', md: '50%' }}
        label={isActionDisabled ? 'Please input a value over zero' : ''}
      >
        <Button
          isDisabled={isActionDisabled}
          isLoading={isLoading}
          flex={1}
          height={44}
          onPress={onAction}
        >
          {actionLabel}
        </Button>
      </Tooltip>
    </Flex>
  );
};

export default OffPlatformFormFooter;
