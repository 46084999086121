import { ActivitySemanticType } from 'ui/types/activities';

import { SearchRelevanceSortBy } from '~/services/resources/activity-definitions/search/types';
import { AttendanceType } from '~/types/interfaces/activity';
import {
  FiltersWhiteListMap,
  FilterWhiteListCanon,
} from '~/utils/activity-search/filter-white-list-map';

export const ALL_ACTIVITY_TYPES = [
  ActivitySemanticType.Event,
  ActivitySemanticType.Ongoing,
  ActivitySemanticType.TeamEvent,
  ActivitySemanticType.Action,
];

export const SORT_BT_WHITE_LIST: Record<
  SearchRelevanceSortBy,
  FilterWhiteListCanon
> = {
  [SearchRelevanceSortBy.Nearest]: {
    activityTypes: [
      ActivitySemanticType.Event,
      ActivitySemanticType.Ongoing,
      ActivitySemanticType.TeamEvent,
    ],
    attendanceTypes: [AttendanceType.Local],
  },

  [SearchRelevanceSortBy.Newest]: {
    activityTypes: ALL_ACTIVITY_TYPES,
    attendanceTypes: [AttendanceType.Local, AttendanceType.Online],
  },

  [SearchRelevanceSortBy.Popular]: {
    activityTypes: [ActivitySemanticType.Event],
    attendanceTypes: [AttendanceType.Local, AttendanceType.Online],
  },

  [SearchRelevanceSortBy.Upcoming]: {
    activityTypes: [ActivitySemanticType.Event, ActivitySemanticType.TeamEvent],
    attendanceTypes: [AttendanceType.Local, AttendanceType.Online],
  },
};

export const sortByWhiteListMap =
  new FiltersWhiteListMap<SearchRelevanceSortBy>(
    Object.entries(SORT_BT_WHITE_LIST) as [
      SearchRelevanceSortBy,
      FilterWhiteListCanon,
    ][],
  );
