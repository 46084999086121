import { useCallback } from 'react';
import { ActivitySemanticType, ActivityType } from 'ui/types/activities';
import {
  ActivityDefinition,
  PrecomputedActivitySummary,
} from 'ui/types/interfaces/activity-definition';
import { MeasurementUnit } from 'ui/types/measurementUnit';
import { mapToActivitySemanticType } from 'ui/utils/activity/mappers';

import { ActionActivityCard } from '../Action';
import { EventActivityCard } from '../Event';
import { OngoingActivityCard } from '../Ongoing';
import { ActivityCardSkeleton } from '../Skeleton';
import { TeamEventActivityCard } from '../TeamEvent';
import { getCoverImageFromActivityDefinition } from './utils';

export type ActivityCardFactoryProps = {
  activityDefinition: ActivityDefinition & { activitiesCount?: number };
  selectedActivitySummary?: PrecomputedActivitySummary;
  onPress?: VoidFunction;
  ongoingNumberOfLines?: number;
};

export const ActivityCardFactory = ({
  activityDefinition,
  selectedActivitySummary,
  ongoingNumberOfLines,
  onPress,
}: ActivityCardFactoryProps) => {
  const renderActivityCard = useCallback(() => {
    // Activity Definition
    const type = activityDefinition.type as ActivityType;
    const activityTitle = activityDefinition.title || '';
    const activityDescription = activityDefinition.description || '';
    const organizationName = activityDefinition?.organizationSubDocument?.name;
    const organizationLogo =
      activityDefinition?.organizationSubDocument?.logoThumbnail || '';

    const coverImageUrl = getCoverImageFromActivityDefinition(
      type,
      activityDefinition?.thumbnailImage ||
        activityDefinition?.coverImage ||
        '',
      activityDefinition?.organizationSubDocument?.logoThumbnail || '',
    );
    const semanticType = mapToActivitySemanticType(
      type,
      activityDefinition?.eventApplicationType,
    );

    // App
    const { appSummary } = activityDefinition;
    const appName = appSummary?.name || '';
    const appLogo = appSummary?.logo || '';

    // Activity Summary
    const activitiesCount = activityDefinition?.activitiesCount || 0;

    const bookingsNumber = selectedActivitySummary?.bookingsNumber || 0;
    const distance = selectedActivitySummary?.distance ?? undefined;

    const isOnline = selectedActivitySummary?.isOnline === true;
    const isFromHome =
      activityDefinition.locationOption == 'From Home' ||
      !selectedActivitySummary?.address;

    switch (semanticType) {
      case ActivitySemanticType.Event: {
        return (
          <EventActivityCard
            activityTitle={activityTitle}
            organizationName={organizationName}
            organizationLogoUrl={organizationLogo}
            appName={appName}
            appLogoUrl={appLogo}
            applicationsCount={bookingsNumber}
            distance={distance}
            activityStartDate={selectedActivitySummary?.startDate}
            coverImageUrl={coverImageUrl}
            activityDatesCount={activitiesCount}
            isFromHome={isFromHome}
            isOnline={isOnline}
            onPress={onPress}
          />
        );
      }

      case ActivitySemanticType.TeamEvent: {
        const teamMaxSize = selectedActivitySummary?.teamsMaxSize || 0;
        const teamMinSize = selectedActivitySummary?.teamsMinSize || 0;

        return (
          <TeamEventActivityCard
            activityTitle={activityTitle}
            organizationName={organizationName}
            organizationLogoUrl={organizationLogo}
            appName={appName}
            appLogoUrl={appLogo}
            applicationsCount={bookingsNumber}
            distance={distance}
            activityStartDate={selectedActivitySummary?.startDate}
            coverImageUrl={coverImageUrl}
            activityDatesCount={activitiesCount}
            isFromHome={isFromHome}
            isOnline={isOnline}
            teamMaxSize={teamMaxSize}
            teamMinSize={teamMinSize}
            onPress={onPress}
          />
        );
      }

      case ActivitySemanticType.Ongoing: {
        return (
          <OngoingActivityCard
            activityTitle={activityTitle}
            organizationName={organizationName}
            organizationLogoUrl={organizationLogo}
            appName={appName}
            appLogoUrl={appLogo}
            applicationsCount={bookingsNumber}
            distance={distance}
            description={activityDescription}
            isFromHome={isFromHome}
            isOnline={isOnline}
            numberOfLines={ongoingNumberOfLines}
            onPress={onPress}
          />
        );
      }

      case ActivitySemanticType.Action: {
        const targetAmount = activityDefinition.targetAmount;
        const causesDisplayNames = (activityDefinition?.causeOptions ?? []).map(
          (cause) => cause.displayName,
        );

        return (
          <ActionActivityCard
            activityTitle={activityTitle}
            organizationName={organizationName}
            organizationLogoUrl={organizationLogo}
            appName={appName}
            appLogoUrl={appLogo}
            applicationsCount={bookingsNumber}
            coverImageUrl={coverImageUrl}
            targetAmount={targetAmount}
            targetAmountMeasurementUnit={
              activityDefinition.measurementUnitSummary as unknown as MeasurementUnit
            }
            causesDisplayNames={causesDisplayNames}
            onPress={onPress}
          />
        );
      }

      default:
        return <ActivityCardSkeleton />;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activityDefinition, selectedActivitySummary, onPress]);

  return <>{renderActivityCard()}</>;
};
