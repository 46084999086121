import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import {
  OFF_PLATFORM_APPLICATION_FORM_DATA,
  schemaValidation,
} from './constants';
import {
  IOffPlatformDefinitionFormFields,
  IOffPlatformDefinitionProps,
} from './types';

export const useOffPlatformDefinitionFormController = ({
  onSubmit,
  onCancel,
  initialFormData,
}: IOffPlatformDefinitionProps) => {
  const { control, handleSubmit, formState, reset } =
    useForm<IOffPlatformDefinitionFormFields>({
      resolver: yupResolver(schemaValidation),
      defaultValues: {
        ...OFF_PLATFORM_APPLICATION_FORM_DATA,
        ...initialFormData,
      },
    });

  const onSubmitHandler = handleSubmit((data) => {
    onSubmit(data);
  });

  const onCancelHandler = () => {
    onCancel();
  };

  return {
    control,
    formState,
    onSubmitHandler,
    onCancelHandler,
  };
};
