import { PAGES } from '~/constants/pages.constants';
import { useAppSelector } from '~/hooks/useAppSelector';
import { useOffPlatformMeasurementDefinitionsInfinityLoad } from '~/hooks/useMeasurementDefinition/useInfinityOffPlatformMeasurementDefinitions';
import { useRouter } from '~/hooks/useRouter';

export const useLogOffPlatformActivitiesController = () => {
  const {
    selectedUserProfile,
    availableAppsFromPermissionGroupsById: availableApps,
  } = useAppSelector(({ auth }) => auth);

  const { goToRoute } = useRouter();

  const {
    data,
    isLoading,
    refetch,
    isRefetching,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useOffPlatformMeasurementDefinitionsInfinityLoad(
    { ecosystemId: selectedUserProfile?.ecosystem || '' },
    !!selectedUserProfile,
  );

  const {
    data: offPlatformActivities = [],
    count: totalOffPlatformActivities = 0,
  } = data ?? {};

  const handleOnScrollEndReached = async () => {
    if (!isRefetching && !isFetchingNextPage && hasNextPage) {
      await fetchNextPage();
    }
  };

  const handleOffPlatformCreate = () => {
    goToRoute(PAGES.CreateOffPlatformActivity);
  };

  return {
    isLoading,
    isFetchingNextPage,
    offPlatformActivities,
    totalOffPlatformActivities,
    handleOnScrollEndReached,
    handleOffPlatformCreate,
    refetch,
  };
};

export default useLogOffPlatformActivitiesController;
