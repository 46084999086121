import { VStack } from 'native-base';
import placeholderImage from 'ui/assets/images/placeholders/placeholder-actions.webp';

import { ActivityBaseCard } from '../Base';
import { ActivityOrganizationSnippet } from '../fragments/Snippets/ActivityOrganizationSnippet';
import { ActivityTargetSnippet } from '../fragments/Snippets/ActivityTargetSnippet';
import { ActivityTitleSnippet } from '../fragments/Snippets/ActivityTitleSnippet';
import { useActionActivityCardController } from './controller';
import { ActionActivityCardProps } from './types';

export const ActionActivityCard = (props: ActionActivityCardProps) => {
  const {
    coverImageUrl,
    organizationName,
    organizationLogoUrl,
    activityTitle,
    targetAmount,
    targetAmountMeasurementUnit,
    onPress,
  } = props;
  const { footerNodes } = useActionActivityCardController(props);

  return (
    <ActivityBaseCard
      onPress={onPress}
      showCoverImage
      footerNodes={footerNodes}
      coverImageUrl={coverImageUrl}
      coverImagePlaceHolder={placeholderImage}
    >
      <VStack pt={3} space={2}>
        <ActivityTitleSnippet title={activityTitle} />

        {targetAmount && (
          <ActivityTargetSnippet
            targetAmount={targetAmount || 0}
            targetAmountMeasurementUnit={targetAmountMeasurementUnit}
          />
        )}

        <ActivityOrganizationSnippet
          organizationName={organizationName}
          organizationLogoUrl={organizationLogoUrl}
        />
      </VStack>
    </ActivityBaseCard>
  );
};
