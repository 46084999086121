import { Text, VStack } from 'native-base';
import { RoundButton } from 'ui/components/v2/Buttons/RoundButton';
import { RoundButtonProps } from 'ui/components/v2/Buttons/RoundButton/types';
import { colors } from 'ui/theme/colors';

import { ButtonGroupItemProps } from '../types';

export interface ButtonGroupRoundButtonProps
  extends RoundButtonProps,
    ButtonGroupItemProps {
  label: string;
}

export const ButtonGroupRoundButton = ({
  icon,
  imageUrl,
  size,
  label,
  isSelected,
  backgroundColor,
  activeBackgroundColor,
  iconColor,
  width,
  onPress,
}: ButtonGroupRoundButtonProps) => {
  return (
    <VStack alignItems={'center'}>
      <RoundButton
        icon={icon}
        size={size}
        width={width}
        iconColor={iconColor}
        imageUrl={imageUrl}
        isActive={isSelected}
        backgroundColor={backgroundColor}
        activeBackgroundColor={activeBackgroundColor}
        onPress={onPress}
      >
        {label && (
          <Text
            numberOfLines={2}
            fontSize={{ base: '10px', sm: 'xs' }}
            fontWeight={500}
            textAlign={'center'}
            color={colors.singletons.darkText}
          >
            {label}
          </Text>
        )}
      </RoundButton>
    </VStack>
  );
};
