import { api } from '~/services/api';
import {
  AddOffPlatformMeasurementsDTO,
  CreateOffPlatformMeasurementDefinitionDTO,
} from '~/types/dtos/measurement-definition';

const MODULE_URL = '/measurement-definition';

export default class MeasurementDefinitionService {
  static async createOffPlatform(
    payload: CreateOffPlatformMeasurementDefinitionDTO,
  ) {
    return await api.post(`${MODULE_URL}/off-platform`, payload);
  }

  static async updateOffPlatform(
    id: string,
    payload: Partial<CreateOffPlatformMeasurementDefinitionDTO>,
  ) {
    return await api.put(`${MODULE_URL}/${id}/off-platform`, payload);
  }

  static async deleteOffPlatform(id: string) {
    return await api.delete(`${MODULE_URL}/${id}/off-platform`);
  }

  static async addOffPlatformMeasurement(
    id: string,
    payload: Partial<AddOffPlatformMeasurementsDTO>,
  ) {
    return await api.post(
      `${MODULE_URL}/${id}/off-platform/measurements`,
      payload,
    );
  }

  static async deleteOffPlatformMeasurement(
    definitionId: string,
    measurementId: string,
  ) {
    return await api.delete(
      `${MODULE_URL}/${definitionId}/off-platform/measurements/${measurementId}`,
    );
  }
}
