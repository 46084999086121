import {
  Box,
  Button,
  Divider,
  HStack,
  Text,
  Tooltip,
  VStack,
} from 'native-base';
import Illustration, { ILLUSTRATIONS_NAME } from 'ui/components/Illustration';

import { useExternalApplyLinkRedirectController } from '~/pages/Authenticated/ExternalApplyLinkRedirect/controller';

export const ExternalApplyLinkRedirect = () => {
  const { displayLink, handleGoBack, handleContinue, isContinueLoading } =
    useExternalApplyLinkRedirectController();

  return (
    <VStack
      w="full"
      h="full"
      justifyContent="space-around"
      alignItems="center"
      alignSelf="center"
    >
      <VStack
        backgroundColor="white"
        borderRadius={{ base: 0, md: 12 }}
        space="lg"
        w={{ base: 'full', md: '2xl' }}
        h={{ base: 'full', md: 'container' }}
      >
        <Text fontSize="xl" px={4} pt={6}>
          Redirect to third-party volunteering platform
        </Text>
        <Divider backgroundColor="coolGray.200" />
        <VStack px={4} space="sm">
          <Text textAlign="justify" color="gray.600">
            This opportunity is hosted by a third-party volunteering platform.
          </Text>
          <Text textAlign="justify" color="gray.600">
            By clicking continue, you accept the transfer of your personal
            information needed to finish your application to said third-party,
            as well as being referred to their external website in a new browser
            window.
          </Text>
          <Text textAlign="justify" color="gray.600">
            Do IT may record this referral for you to keep track of your
            volunteering progress in &quot;Log Activity&quot; later.
          </Text>
          <Text color="gray.600" fontSize="md" textAlign="center" bold>
            You will be redirected to:{'\n'}
            <Tooltip label="You must agree and continue to be redirected to the full link">
              <Text color="blue.500">
                {displayLink}
                <Text letterSpacing="2xl">....</Text>
              </Text>
            </Tooltip>
          </Text>
        </VStack>
        <Box w="full" alignItems="center">
          <Illustration name={ILLUSTRATIONS_NAME.POINTER_UP} w={133} h={154} />
        </Box>
        <HStack
          flexWrap={{ base: 'wrap', md: 'nowrap' }}
          justifyContent="stretch"
          space={8}
          px={4}
          pb={6}
        >
          <Button
            onPress={handleGoBack}
            w="100%"
            flexShrink={1}
            variant="outline"
            colorScheme="coolGray"
            my={{ base: 2, md: 0 }}
            // NOTE from @rovelcio:
            // https://developer.mozilla.org/en-US/docs/Web/CSS/order
            // For whatever reason, the order prop is not recognized by the Button component,
            // even though it is a valid prop and renders correctly on mobile-first layouts.
            // This is likely an issue with NativeBase's types. There are several issues
            // complaining about buttons and typings in the NativeBase repo.
            // Working properly on Chrome, Firefox and Edge.
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            order={{ base: 1, md: 0 }}
          >
            No, go back
          </Button>
          <Button
            onPress={handleContinue}
            isLoading={isContinueLoading}
            w="100%"
            flexShrink={1}
            colorScheme="primary"
            my={{ base: 2, md: 0 }}
          >
            Agree and continue
          </Button>
        </HStack>
      </VStack>
    </VStack>
  );
};
