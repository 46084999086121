import { Box } from 'native-base';
import ReactSelect from 'react-select';
import Icon from 'ui/components/Icon';
import { RootPortal } from 'ui/components/v2/RootPortal';
import { colors } from 'ui/theme/colors';

import CommonInputWrapper from '../../../CommonInputWrapper';
import { ISelectOptionsItem } from '../types';
import { useBaseSelectController } from './controller';
import { SelectActionSheet } from './fragments/SelectActionSheet';
import { IBaseSelectProps } from './types';

export const BaseSelect = (props: IBaseSelectProps) => {
  const {
    value,
    label,
    options,
    helpText,
    isRequired,
    isDisabled,
    errorMessage,
    placeholder,
    isLoading,
    isMulti,
    isClearable = false,
    leadingIcon,
  } = props;

  const {
    isMobile,
    isMenuOpen,
    defaultStyle,
    handleMenuOpen,
    handleOnChange,
    handleMenuClose,
  } = useBaseSelectController(props);

  return (
    <CommonInputWrapper
      isRequired={isRequired}
      label={label}
      errorMessage={errorMessage}
      helpText={helpText}
    >
      <Box flex={1}>
        {leadingIcon && (
          <Box position={'absolute'} left={'12px'} top={'16px'} zIndex={9}>
            <Icon icon={leadingIcon} size={'16px'} color={colors.gray[400]} />
          </Box>
        )}
        <ReactSelect
          menuPortalTarget={document.body}
          options={options}
          styles={defaultStyle}
          isDisabled={isDisabled}
          placeholder={placeholder}
          isLoading={isLoading}
          isClearable={isClearable}
          onMenuOpen={handleMenuOpen}
          onMenuClose={isMobile ? () => null : handleMenuClose}
          closeMenuOnSelect={isMobile ? false : isMenuOpen}
          menuIsOpen={isMobile ? false : isMenuOpen}
          isSearchable
          value={value}
          isMulti={isMulti}
          onChange={(value) =>
            handleOnChange(value as ISelectOptionsItem | ISelectOptionsItem[])
          }
        />
      </Box>

      <RootPortal>
        <SelectActionSheet
          title={placeholder}
          isOpen={isMenuOpen && isMobile}
          onClose={handleMenuClose}
          options={options as any}
          onItemPress={(value) => {
            handleOnChange(value as ISelectOptionsItem | ISelectOptionsItem[]);
            handleMenuClose();
          }}
        />
      </RootPortal>
    </CommonInputWrapper>
  );
};

export default BaseSelect;
