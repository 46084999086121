import { MeasurementCategory } from 'ui/enums';
import { MeasurementUnit } from 'ui/types/measurementUnit';
import { secondsToHoursAndMinutes } from 'ui/utils/secondsToDuration';

import { PopulatedMeasurement } from '~/types/interfaces/measurement';

import { UnitsMap } from './constants';

export const getUnitAbbreviation = (measurementUnit: MeasurementUnit) => {
  const singularLabel = measurementUnit?.singularLabel || '';

  const mapped = UnitsMap[singularLabel];
  if (mapped) return mapped.suffix;

  const firstWord = singularLabel.split(' ')[0];
  if (firstWord.length < 5) return firstWord;

  return singularLabel.charAt(0);
};

export function formatMeasurementAmount(
  amount: number,
  measurementType: MeasurementCategory,
  measurementUnit: MeasurementUnit,
) {
  if (!measurementType || measurementType === MeasurementCategory.Time) {
    const { hours, minutes } = secondsToHoursAndMinutes(amount);
    return `${hours}h ${minutes}m`;
  }

  const unitSuffixLabel =
    amount != 1 ? measurementUnit.pluralLabel : measurementUnit.singularLabel;

  return `${amount} ${unitSuffixLabel}`;
}

export function groupMeasurementsByUnit(measurements: PopulatedMeasurement[]) {
  const sortedMeasurements = measurements.sort((a, b) => {
    if (a.measurementUnit._id < b.measurementUnit._id) return -1;
    if (a.measurementUnit._id > b.measurementUnit._id) return 1;
    return 0;
  });

  const grouped = sortedMeasurements.reduce(
    (group: { [key: string]: PopulatedMeasurement[] }, measurement) => {
      const { measurementUnit } = measurement;
      const key = measurementUnit._id;

      group[key] = group[key] ?? [];
      group[key].push(measurement);
      return group;
    },
    {},
  );

  return grouped;
}
