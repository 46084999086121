import { Avatar, Flex, HStack, Stack, Text, VStack } from 'native-base';
import { FiMail } from 'react-icons/fi';
import { ILLUSTRATIONS_NAME } from 'ui/components/Illustration';
import Loading from 'ui/components/Loading';

import FlowTwoColumnsTemplate from '~/components/Templates/FlowTwoColumnsTemplate';

import { useTeamInvitationController } from './useTeamInvitationController';

export const TeamInvitation = () => {
  const {
    isLoadingSubmit,
    teamInfo,
    isLoading,
    mutateAcceptInvitation,
    mutateRejectInvitation,
    teamLeaderNameInitial,
    teamMembersTotal,
  } = useTeamInvitationController();

  return isLoading ? (
    <Stack h="full" w="full" alignItems="center" justifyContent="center">
      <Loading />
    </Stack>
  ) : (
    <FlowTwoColumnsTemplate
      ilustrationName={ILLUSTRATIONS_NAME.INVITE_MEMBER}
      title={`${teamInfo?.leaderSubDocument.userSummary.name} has invited you to join the team ${teamInfo?.name}`}
      subtitle="When you join a team, you can then book and participate in team events with your new teammates. Will you accept?"
      icon={<FiMail size={32} />}
      primaryButtonText={'Accept invitation'}
      secondaryButtonText="Decline"
      primaryButtonOnPress={mutateAcceptInvitation}
      primaryButtonVariant="outline"
      secondaryButtonOnPress={mutateRejectInvitation}
      isLoading={isLoadingSubmit}
    >
      <Flex m={8} p={4} borderRadius={9} borderWidth={1} borderColor="gray.300">
        <VStack space={4}>
          <Text fontSize="lg" fontWeight={700}>
            {teamInfo?.name}
          </Text>
          <Stack mt={4}>
            <Text fontSize="md" mb={2} fontWeight="medium" color="gray.600">
              Team Leader
            </Text>
            <HStack alignItems="center" space={2}>
              <Avatar
                source={{
                  uri: teamInfo?.leaderSubDocument.userSummary.profileImage,
                }}
                size={10}
                flexShrink={0}
              >
                {teamLeaderNameInitial}
              </Avatar>
              <Text
                fontSize="sm"
                fontWeight="medium"
                color="singletons.darkText"
              >
                {teamInfo?.leaderSubDocument.userSummary.name}
              </Text>
            </HStack>
          </Stack>
          <Stack>
            <Text fontSize="md" mb={2} fontWeight="medium" color="gray.600">
              Ecosystem
            </Text>
            <HStack alignItems="center" space={2}>
              <Avatar
                source={{
                  uri: teamInfo?.ecosystem.logo,
                }}
                size={10}
                flexShrink={0}
              >
                {teamLeaderNameInitial}
              </Avatar>
              <Text
                fontSize="sm"
                fontWeight="medium"
                color="singletons.darkText"
              >
                {teamInfo?.ecosystem.name}
              </Text>
            </HStack>
          </Stack>
          <VStack>
            <Text fontSize="md" color="gray.600" fontWeight="medium" mb={2}>
              Number of members
            </Text>
            <Text fontSize="md" color="gray.600" fontWeight="medium" mb={2}>
              {teamMembersTotal}
            </Text>
          </VStack>
        </VStack>
      </Flex>
    </FlowTwoColumnsTemplate>
  );
};
