import './styles.css';

import { Menu } from 'native-base';
import { FixedSizeList as List } from 'react-window';
import { useClickOutside } from 'ui/hooks/useClickOutside';

import { SearchOptionItem } from '../Item';
import { SearchDropDownProps } from './types';

const DROP_DOWN_ITEM_SIZE = 46;

const SearchDropDown = (props: SearchDropDownProps) => {
  const { isOpen, options, onOptionPress, onClose } = props;

  const handleClickOutside = () => {
    onClose?.();
  };
  const { containerRef } = useClickOutside(handleClickOutside);

  const dropdownHeight = Math.min(options.length * DROP_DOWN_ITEM_SIZE, 322);

  return (
    <>
      {isOpen && !!options.length && (
        <div
          className="search-auto-complete-drop-down-container"
          ref={containerRef}
        >
          <List
            height={dropdownHeight}
            itemCount={options.length}
            itemSize={DROP_DOWN_ITEM_SIZE}
            width={'100%'}
          >
            {({ index, style }) => {
              const option = options[index];
              return (
                <div style={style} key={option.value}>
                  <Menu.Item
                    w="full"
                    p={0}
                    onPress={() => onOptionPress?.(option)}
                  >
                    <SearchOptionItem
                      label={option?.label || option.value}
                      icon="search"
                    />
                  </Menu.Item>
                </div>
              );
            }}
          </List>
        </div>
      )}
    </>
  );
};

export default SearchDropDown;
