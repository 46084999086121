import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { useIntersection } from 'ui/hooks/useIntersection';

import { makeCarouselSlides } from '~/components/v2/ActivityCarousel/utils';

import { ActivityCarouselProps, CarouselActivityDefinitionData } from './types';

export const useActivityCarouselController = ({
  isEnabled,
  dataSource,
  onActionPress,
}: ActivityCarouselProps) => {
  const { onLoad, queryKey, onChange } = dataSource;
  // Providers
  const [wasCarouselIntersected, setWasCarouselIntersected] = useState(false);

  const handleIntersect = () => {
    if (!wasCarouselIntersected) {
      setWasCarouselIntersected(true);
    }
  };

  const elementRef = useIntersection(handleIntersect, true, {
    root: null,
    rootMargin: '0px',
    threshold: 0.3,
  });

  const isQueryEnabled = !!isEnabled && wasCarouselIntersected;
  // Hooks
  const { data, isLoading: isDataLoading } = useQuery<
    CarouselActivityDefinitionData[]
  >({
    queryKey,
    queryFn: () => onLoad,
    enabled: isQueryEnabled,
    staleTime: 5 * 60 * 1000, // Data stays fresh for 5 minutes
    cacheTime: 30 * 60 * 1000, // Data remains in cache for 30 minutes
  });

  // Handlers
  const handleCarouselActionPress = useCallback(() => {
    onActionPress?.();
  }, [onActionPress]);

  const carouselSlides = useMemo(() => {
    if (!data) return [];
    return makeCarouselSlides(data, handleCarouselActionPress);
  }, [data, handleCarouselActionPress]);

  const isLoading = isDataLoading || !isQueryEnabled;

  useEffect(() => {
    onChange?.({
      dataLength: carouselSlides?.length || 0,
      isLoading,
      queryKey: [],
    });
  }, [isLoading, carouselSlides, onChange]);

  const isEmpty = !isLoading && !carouselSlides?.length;

  return {
    data,
    isEmpty,
    isLoading,
    elementRef,
    carouselSlides,
    handleCarouselActionPress,
  };
};
