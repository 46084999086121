import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { usePermissions } from '~/hooks/usePermissions';
import UserService from '~/services/resources/user';

import { useAppSelector } from '../useAppSelector';

const THIRTY_MINUTES_INTERVAL = 60 * 1000 * 30;

export const useRefetchUserPermissions = () => {
  const { isAuthenticated } = useAppSelector(({ auth }) => auth);
  const { handleUpdateUserPermissions } = usePermissions();
  const [enabled, setEnabled] = useState(false);

  useQuery({
    enabled,
    queryKey: ['refetch-permissions'],
    queryFn: async () => await UserService.me(),
    onSuccess: (data) => {
      const userProfile = data.data?.userProfiles && data.data.userProfiles[0];

      handleUpdateUserPermissions(userProfile?._id as string);
    },
    refetchInterval: THIRTY_MINUTES_INTERVAL,
    refetchOnReconnect: true,
  });

  useEffect(() => {
    // Make the initial query fetch have the same delay as the refetch interval. We don't lose the automatic refetch from useQuery this way.
    if (isAuthenticated) {
      setTimeout(() => {
        setEnabled(true);
      }, THIRTY_MINUTES_INTERVAL);
    }
  }, [isAuthenticated]);
};
