export const ValidationsRegex = {
  // @rovelcio source and explanation https://stackoverflow.com/a/58767981
  Password:
    /^(?!\s+)(?!.*\s+$)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[$^*.\[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9$^*.\[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{8,256}$/,
  JustLettersNumbersHyphen: /^[A-Za-z0-9-]+$/u,
  DomainSlugCharacters: /^[a-zA-Z0-9-]+$/,
  DomainSlugCharactersGlobal: /[^a-zA-Z0-9-]/g,
  FullDomainSlug: /^[a-z0-9]+(-[a-z0-9]+)*$/,
  Url: /^(https:\/\/)(www\.)?([a-zA-Z0-9_-]+\.)+[a-zA-Z]{2,}(\/[\w%-]*)*(\?[a-zA-Z0-9_=&%-]*)?(#[\w-]*)?$/,
};

export const validateSlug = (value: string) => {
  if (ValidationsRegex.DomainSlugCharacters.test(value))
    return value.toLowerCase();

  return value
    .replace(ValidationsRegex.DomainSlugCharactersGlobal, '')
    .toLowerCase();
};
