import { useCallback } from 'react';
import { AppPermissions } from 'ui/constants/permission.constants';
import { IAppPermissionGroups } from 'ui/types/interfaces/permission';
import {
  getAvailableUserPermissionsFromApps,
  getUserPermissionsGroupsByAppId,
} from 'ui/utils/permissions';

import { DEFAULT_APP_ID } from '~/config';
import { useAppDispatch } from '~/hooks/useAppDispatch';
import { FindOpportunitiesContentPageTab } from '~/pages/Authenticated/FindOpportunitiesPage/findOpportunities.types';
import { AppPermissionGroupsService } from '~/services/resources/appPermissionGroups';
import { AppsService } from '~/services/resources/apps';
import { authSliceActions } from '~/store/slices/auth';
import { permissionsSliceActions } from '~/store/slices/permissions';
import { App } from '~/types/interfaces/app';

import { useAppSelector } from '../useAppSelector';

export const usePermissions = () => {
  const dispatch = useAppDispatch();
  const { allUserPermissionsFromAvailableApps } = useAppSelector(
    ({ permissions }) => permissions,
  );
  const handleUpdateUserPermissions = useCallback(
    async (userProfileId: string) => {
      try {
        const permissionsReponse =
          await AppPermissionGroupsService.getAppPermissionGroupsFromUserProfile(
            userProfileId,
          );

        const appsFromPermissions = permissionsReponse.data.map(
          (permissionGroup: IAppPermissionGroups) => permissionGroup.app,
        );

        const appPermissionGroups = permissionsReponse.data;

        const appsResponse = await AppsService.findAppsByIds(
          appsFromPermissions,
        );

        const appsList: App[] = appsResponse.data.data;
        const userAppsById = appsList.reduce(
          (acc: Record<string, App>, app: App) => {
            const hasPermission = appPermissionGroups.some(
              (permissionGroup) => {
                const isSameApp = permissionGroup.app === app._id;
                const hasViewAppPermission = permissionGroup.permissions.some(
                  (permission) =>
                    permission.name === AppPermissions.ViewApp.name,
                );
                return isSameApp && hasViewAppPermission;
              },
            );

            if (hasPermission) {
              acc[app._id] = app;
            }

            return acc;
          },
          {},
        );

        const userPermissionsGroupsByAppId =
          getUserPermissionsGroupsByAppId(appPermissionGroups);

        dispatch(
          permissionsSliceActions.setUserPermissionsGroupsByAppId(
            userPermissionsGroupsByAppId,
          ),
        );

        const availableUserPermissions = getAvailableUserPermissionsFromApps(
          appPermissionGroups as IAppPermissionGroups[],
        );

        dispatch(
          permissionsSliceActions.setAvailableActivityPermissionsFromApps(
            availableUserPermissions,
          ),
        );

        dispatch(
          authSliceActions.update({
            availableAppsFromPermissionGroupsById: userAppsById,
          }),
        );
      } catch (error: any) {
        dispatch(
          authSliceActions.update({ availableAppsFromPermissionGroupById: {} }),
        );
      }
    },
    [dispatch],
  );

  const handleUpdateDefaultGuestPermissions = async () => {
    try {
      const appResponse = await AppsService.publicFindById(DEFAULT_APP_ID);

      const app = appResponse.data;
      const defaultAppPermissionGroups =
        appResponse.data.defaultAppPermissionGroups;

      const userPermissionsGroupsByAppId = getUserPermissionsGroupsByAppId(
        defaultAppPermissionGroups,
      );

      dispatch(
        permissionsSliceActions.setUserPermissionsGroupsByAppId(
          userPermissionsGroupsByAppId,
        ),
      );

      const availableUserPermissions = getAvailableUserPermissionsFromApps(
        defaultAppPermissionGroups as IAppPermissionGroups[],
      );

      dispatch(
        permissionsSliceActions.setAvailableActivityPermissionsFromApps(
          availableUserPermissions,
        ),
      );
      dispatch(
        authSliceActions.update({
          availableAppsFromPermissionGroupsById: {
            [app._id]: app,
          },
        }),
      );
    } catch (error: any) {
      dispatch(
        authSliceActions.update({ availableAppsFromPermissionGroupById: {} }),
      );
    }
  };

  const getOpportunitiesTabsByPermissions = (
    tabsList: FindOpportunitiesContentPageTab[],
  ) => {
    const filteredTabsList = tabsList.filter((tab) => {
      let hasPermissions = false;
      allUserPermissionsFromAvailableApps.forEach((permission) => {
        const foundPermission = tab.requiredPermissions.find(
          (requiredPermission) => requiredPermission.name === permission.name,
        );
        if (foundPermission) {
          hasPermissions = true;
        }
      });
      if (hasPermissions) {
        return tab;
      }
    });
    return filteredTabsList;
  };

  return {
    handleUpdateUserPermissions,
    handleUpdateDefaultGuestPermissions,
    getOpportunitiesTabsByPermissions,
  };
};
