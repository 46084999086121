export const DEFAULT_COLUMNS = {
  base: 1,
  sm: 2,
  md: 3,
  lg: 4,
  '2xl': 5,
};

export const DEFAULT_SPAN_PER_ITEMS = {
  base: 12,
  sm: 6,
  md: 4,
  lg: 3,
  '2xl': 3,
};
