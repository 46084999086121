import { VStack } from 'native-base';
import { ForwardedRef, ReactNode } from 'react';
import Modal from 'ui/components/Modals/Modal';
import { ModalImperativeRef } from 'ui/components/v2/Modal/types';

export type ActivityFilterDesktopModalProps = {
  modalRef?: ForwardedRef<ModalImperativeRef>;
  onClose?: VoidFunction;
  children: ReactNode;
};

export const ActivityFilterDesktopModal = ({
  modalRef,
  onClose,
  children,
}: ActivityFilterDesktopModalProps) => {
  return (
    <Modal
      ref={modalRef}
      onClose={onClose}
      modalContentW={{ base: '95vw', lg: '800px' }}
      modalContentH={{ base: '100vh', md: 'fit-content' }}
      modalContentMaxW={{ base: 'calc(100vw - 16px)', lg: '800px' }}
      modalContentMaxH={{
        base: 'calc(100vh - 32px)',
        md: 'calc(100vh - 64px)',
        xl: 'calc(100vh - 128px)',
      }}
      bodyPadding={'0'}
      closeOnOverlayClick
      headerText="Filter"
    >
      <VStack w="full" backgroundColor={'white'} borderRadius={8}>
        {children}
      </VStack>
    </Modal>
  );
};

export default ActivityFilterDesktopModal;
