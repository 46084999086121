import { useMemo } from 'react';

import { ilustrationsConstans } from './constants';
import { IIllustrationControllerProps, ILLUSTRATIONS_NAME } from './types';

const selectIllustration = (illustrationName: ILLUSTRATIONS_NAME) => {
  // TODO: Remove this fallback.
  return (
    ilustrationsConstans[illustrationName] ||
    ilustrationsConstans[ILLUSTRATIONS_NAME.WELLDONE_OK]
  );
};

export const useIllustrationController = ({
  name,
}: IIllustrationControllerProps) => {
  const illustration = useMemo(() => {
    return selectIllustration(name);
  }, [name]);

  return { illustration };
};
