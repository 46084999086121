import { Flex, HStack, Stack } from 'native-base';
import { FiMail } from 'react-icons/fi';
import { ILLUSTRATIONS_NAME } from 'ui/components/Illustration';
import Input from 'ui/components/Input';
import { transformInputValueToLowerCase } from 'ui/utils/formatter';

import FlowTwoColumnsTemplate from '~/components/Templates/FlowTwoColumnsTemplate';
import { colors } from '~/theme/colors';

import { useConfirmEmailController } from './useConfirmEmailController';

export const ConfirmEmail = () => {
  const { control, errors, onSubmit, goBack, isLoading, email } =
    useConfirmEmailController();
  return (
    <Flex minH="full" flexShrink={0}>
      <FlowTwoColumnsTemplate
        ilustrationName={ILLUSTRATIONS_NAME.ACCOUNTS_SECURITY}
        title="Enter your email address"
        subtitle={'We will send you a code to reset your password.'}
        icon={<FiMail size={32} />}
        progress={0}
        primaryButtonText="Continue"
        primaryButtonVariant="outline"
        primaryButtonOnPress={onSubmit}
        secondaryButtonOnPress={goBack}
        secondaryButtonText="Back"
        isLoading={isLoading}
      >
        <Stack
          minH="full"
          space="4"
          m="auto"
          w="full"
          px={6}
          py={8}
          justifyContent={{ base: 'center', md: 'flex-start' }}
        >
          <HStack space={3} flex={2}>
            <Stack flex={1} flexDir="row" alignItems="flex-start">
              <Stack flex={2}>
                <Input
                  name="email"
                  value={email}
                  control={control}
                  placeholder="Type your email addess"
                  label="Email address"
                  isRequired
                  leftIconName="mail"
                  isFullWidth
                  errorMessage={errors.email?.message}
                  iconErrorMessage="info"
                  leftIconColor={colors.muted[400]}
                  onChangeTransform={transformInputValueToLowerCase}
                />
              </Stack>
            </Stack>
          </HStack>
        </Stack>
      </FlowTwoColumnsTemplate>
    </Flex>
  );
};
