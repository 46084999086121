import { Text, VStack } from 'native-base';
import {
  formatDateWeekday,
  formatTimeUtil,
  getDateMonthName,
} from 'ui/utils/formatDates';

import { BookingDateSelectCard } from '~/components/BookingDateSelectCard/BookingDateSelectCard';

import { LogEventCardProps } from './types';

export const LogEventCard = ({
  activityApplication,
  app,
}: LogEventCardProps) => {
  const startDate = activityApplication.activitySubDocument.startDate;
  const endDate = activityApplication.activitySubDocument.endDate;

  const parsedStartDate = new Date(startDate);
  const parsedEndDate = new Date(endDate);

  return (
    <VStack space={4}>
      <BookingDateSelectCard
        activityId={activityApplication.activity || ''}
        bookingDateSelectDetails={[
          {
            iconName: 'calendar',
            text: formatDateWeekday(new Date(parsedStartDate)),
          },
          {
            iconName: 'clock',
            text: `${formatTimeUtil(parsedStartDate)} - ${formatTimeUtil(
              parsedEndDate,
            )}`,
          },
          {
            iconName: 'map-pin',
            text:
              activityApplication?.activitySubDocument?.address?.street || '',
          },
        ]}
        hideActivitySummary={false}
        activityTitle={
          activityApplication?.activitySubDocument.activityDefinitionSubDocument
            .title || ''
        }
        appName={app?.name || ''}
        appId={app?._id || ''}
        organizationName={
          activityApplication.activitySubDocument.activityDefinitionSubDocument
            .organizationSubDocument.name || ''
        }
        organizationLogo={
          activityApplication.activitySubDocument.activityDefinitionSubDocument
            .organizationSubDocument.logo || ''
        }
        day={
          startDate
            ? String(parsedStartDate.getDate()).padStart(2, '0')
            : undefined
        }
        bookedActivityId={activityApplication._id || ''}
        hideCardSelectIcon={true}
        month={
          startDate ? getDateMonthName(parsedStartDate, 'short') : undefined
        }
        isAlreadyBooked={false}
      />
    </VStack>
  );
};
