import { HStack, Pressable, Text } from 'native-base';
import Icon from 'ui/components/Icon';

import { useLogItemController } from '~/components/v2/LogCollapse/components/LogItem/controller';
import { LogItemProps } from '~/components/v2/LogCollapse/components/LogItem/types';
import { colors } from '~/theme/colors';

export const LogItem = (props: LogItemProps) => {
  const {
    amountStr,
    dateStr,
    measurementIcon,
    handleDeleteAction,
    showActionButtons,
  } = useLogItemController(props);

  return (
    <HStack
      p="2"
      w="full"
      space={4}
      justifyContent="space-between"
      backgroundColor="white"
      borderRadius={8}
    >
      <HStack w="full" alignItems="center" space={2}>
        <Icon
          icon="calendar"
          enableAutoCustom
          size="20px"
          color={colors.gray['400']}
        />
        <Text
          numberOfLines={1}
          fontSize="sm"
          textTransform="uppercase"
          color="gray.600"
        >
          {dateStr}
        </Text>
      </HStack>

      <HStack
        w="full"
        justifyContent="flex-start"
        alignItems="center"
        space={2}
      >
        <Icon
          icon={measurementIcon}
          enableAutoCustom
          size="20px"
          color={colors.gray['400']}
        />

        <Text
          color={'gray.600'}
          numberOfLines={1}
          fontSize={'sm'}
          fontWeight={'400'}
        >
          {amountStr}
        </Text>
      </HStack>

      {showActionButtons && (
        <HStack alignItems={'center'} px={3}>
          <Pressable onPress={handleDeleteAction}>
            <Icon size={20} icon={'trash'} color={colors.error[600]} />
          </Pressable>
        </HStack>
      )}
    </HStack>
  );
};
