import { View } from 'native-base';

export const LoadTrigger = ({
  hasNext,
  triggerRef,
}: {
  hasNext?: boolean;
  triggerRef: React.MutableRefObject<HTMLElement | null>;
}) => {
  return <>{hasNext ? <View h={32} w={'full'} ref={triggerRef} /> : null}</>;
};
