import { BookingDateSelectDate } from './booking-date-select-date';
import { BookingDateSelectDetails } from './booking-date-select-details';
import { BookingDateSelectIcon } from './booking-date-select-icon';
import { BookingDateSelectRoot } from './booking-date-select-root';

export const BookingDateSelect = {
  Root: BookingDateSelectRoot,
  Icon: BookingDateSelectIcon,
  Date: BookingDateSelectDate,
  Details: BookingDateSelectDetails,
};
