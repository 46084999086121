import useInfinityLoad from '~/hooks/useInfinityLoad';
import { PopulatedMeasurementDefinition } from '~/types/interfaces/measurement-definition';

import { MeasurementDefinitionLoadProps } from './types';

export function useOffPlatformMeasurementDefinitionsInfinityLoad(
  filter: MeasurementDefinitionLoadProps,
  isEnabled = false,
) {
  const { ecosystemId } = filter;

  const response = useInfinityLoad({
    url: 'measurement-definition/off-platform',
    queryKey: `measurement-definition-off-platform`,
    props: {
      filter: {
        ecosystem: ecosystemId,
      },
      sort: { updatedAt: 'desc' },
    },
    disabled: !isEnabled,
  });

  const serializedData = ((response.data?.pages as any) ?? []).flatMap(
    (page: { responseData: PopulatedMeasurementDefinition }) =>
      page.responseData,
  );

  return { ...response, data: serializedData[0] };
}
