import BreadCrumbs from '~/components/Breadcrumbs';

import { useActivityBreadCrumbsController } from './controller';
import { ActivityBreadCrumbsProps } from './types';

export const ActivityBreadCrumbs = ({
  activityDefinition,
  appId,
}: ActivityBreadCrumbsProps) => {
  const { breadcrumbs } = useActivityBreadCrumbsController({
    activityDefinition,
    appId,
  });

  return <BreadCrumbs breadcrumbs={breadcrumbs} />;
};
