// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const styles: any = {
  resendCodeText: {
    color: 'singletons.darkText',
    fontWeight: 500,
  },
  denyTextButton: {
    color: 'error.600',
  },
};
