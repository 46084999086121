import { Box, Flex } from 'native-base';
import Icon from 'ui/components/Icon';

import { BookingDateSelectIconProps } from './booking-date-select-icon.types';

export const BookingDateSelectIcon = ({
  isSelected,
  isAlreadyBooked,
}: BookingDateSelectIconProps) => {
  const bookedIcon = isAlreadyBooked ? 'check-circle' : '';
  const selectionIcon = isSelected ? 'minus-circle' : 'plus-circle';

  return (
    <div className="booking-date-select-icon">
      <Icon
        icon={bookedIcon || selectionIcon}
        color={isAlreadyBooked ? 'black' : undefined}
        size={24}
      />
    </div>
  );
};
