import { extendTheme } from 'native-base';

export const { fontConfig, fonts, lineHeights } = extendTheme({
  fontConfig: {
    default: 'Poppins',
  },
  lineHeights: {
    l: 38,
  },
  fonts: {
    heading: 'Poppins',
    body: 'Poppins',
    mono: 'Poppins',
  },
});
