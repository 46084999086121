import {
  Box,
  Flex,
  Stack,
  Text,
  useBreakpointValue,
  VStack,
} from 'native-base';
import Button from 'ui/components/Button';
import Illustration, { ILLUSTRATIONS_NAME } from 'ui/components/Illustration';

import { PAGES } from '~/constants/pages.constants';
import { useRouter } from '~/hooks/useRouter';

export const OffplatformActivitySuccess = () => {
  const { goToRoute } = useRouter();

  const breakpointValue = useBreakpointValue({
    base: { transform: '' },
    md: { transform: 'rotateY(180deg)' },
  });

  return (
    <Box flex="1" overflow={'scroll'} py={8} px={{ base: 4, lg: 0 }}>
      <Flex
        flexDir={{ base: 'column', md: 'row' }}
        margin={'auto'}
        w={'full'}
        h={'full'}
        maxW={'1440px'}
        justifyContent={{ base: 'center', md: 'center' }}
        alignItems={'center'}
        style={{
          columnGap: '96px',
          rowGap: '48px',
        }}
      >
        <Stack
          alignItems={'center'}
          justifyContent={'center'}
          w={{ base: '168px', md: 'full' }}
          h={{ base: '168px', md: 'full' }}
          maxW={'400px'}
          maxH={'400px'}
          style={{ transform: breakpointValue.transform }}
        >
          <Illustration
            resizeMode={'contain'}
            w={'100%'}
            maxW={'400px'}
            maxH={'400px'}
            width={{ base: 'full' }}
            height={{ base: 'full' }}
            name={ILLUSTRATIONS_NAME.CONFIRMATION_GREAT}
          />
        </Stack>
        <VStack
          w="full"
          maxW={{ base: '400px' }}
          space={0}
          borderRadius={'12px'}
        >
          <VStack w="full" h="fit-content" space={3}>
            <Text
              maxWidth={{ base: 'unset', md: '340px' }}
              fontWeight={700}
              fontSize={'24px'}
              textAlign={{ base: 'center', md: 'left' }}
            >
              You logged your activity off-platform successfully!
            </Text>
            <Text
              color="gray.400"
              fontWeight={400}
              fontSize={'md'}
              textAlign={{ base: 'center', md: 'left' }}
            >
              Thank you for helping out your community.
            </Text>
          </VStack>

          <VStack width="full" space={2} mt={16}>
            <Button
              leftIconName={'wifi-off'}
              leftIconSize={24}
              flex={1}
              height={44}
              onPress={() => goToRoute(PAGES.CreateOffPlatformActivity)}
            >
              <Text color="white" fontWeight={500} fontSize={'16px'}>
                New activity off-platform
              </Text>
            </Button>

            <Button
              variant="ghost"
              colorScheme="darkText"
              flex={1}
              height={44}
              onPress={() => goToRoute('/my-wallet/log-activity')}
            >
              <Text color="#151518" fontWeight={500} fontSize={'16px'}>
                Go to my log activities
              </Text>
            </Button>
          </VStack>
        </VStack>
      </Flex>
    </Box>
  );
};
