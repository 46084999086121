import { useDisclose } from 'native-base';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { getAppBarItems } from '~/components/MobileAppBar/utils';
import { PAGES } from '~/constants/pages.constants';
import { useAppSelector } from '~/hooks/useAppSelector';
import { useRouter } from '~/hooks/useRouter';
import { ExecutionPlatform } from '~/store/slices/inApp/types';
import { getNameInitials } from '~/utils/getInitials';

export const useMobileAppBarController = () => {
  const { goToRoute } = useRouter();
  const { pathname: currentPath } = useLocation();
  const { platform, selectedApp } = useAppSelector((state) => state.inApp);
  const { user, selectedUserProfile } = useAppSelector((state) => state.auth);
  const { onToggle: handleToggleDrawer, isOpen: isDrawerOpen } = useDisclose();
  const { selectedEcosystem, guestEcosystem } = useAppSelector(
    ({ ecosystem }) => ecosystem,
  );

  const userParsedData = useMemo(() => {
    return {
      userName: user?.name || '',
      userInitials: getNameInitials(user?.name || ''),
      userAvatar: selectedUserProfile?.profilePicture || '',
      ecosystemLogo: selectedEcosystem?.logo || guestEcosystem.logo,
      ecosystemName: selectedEcosystem?.name || guestEcosystem.name,
      ecosystemInitial: getNameInitials(
        selectedEcosystem?.name || guestEcosystem.name,
      ),
      hasUser: user ? true : false,
    };
  }, [user, selectedUserProfile, selectedEcosystem, guestEcosystem]);

  const handleNavigate = (pagePath: string | null) => {
    if (!pagePath) return;

    if (pagePath === currentPath) {
      if (isDrawerOpen) return handleToggleDrawer();
    }
    goToRoute(pagePath);
    if (isDrawerOpen) handleToggleDrawer();
  };

  const handlePressAppBarItem = (
    pagePath: string | null,
    isDrawerTab?: boolean,
  ) => {
    if (isDrawerTab) return handleToggleDrawer();

    handleNavigate(pagePath);
  };

  const appBarItems = useMemo(() => {
    let customHomeItem;

    if (selectedApp && platform === ExecutionPlatform.WEBVIEW) {
      customHomeItem = {
        pagePath: PAGES.InAppRoot.replace(':appId', selectedApp?._id || ''),
        text: 'Home',
        icon: 'home',
        permissions: [],
      };
    }

    return getAppBarItems({ userParsedData, customHomeItem });
  }, [userParsedData, platform, selectedApp]);

  return {
    handlePressAppBarItem,
    handleToggleDrawer,
    isDrawerOpen,
    currentPath,
    userParsedData,
    appBarItems,
  };
};
