import { Avatar, Box, Text } from 'native-base';

import { UserParsedData } from '~/components/NavBar/navBar.types';

export const CustomOptionsItem = ({
  userParsedData,
}: {
  userParsedData?: UserParsedData;
}) => {
  return (
    <Box pt={'3px'} pb={1}>
      <Avatar
        bg="gray.400"
        borderColor={'gray.300'}
        borderWidth={1}
        w={9}
        h={9}
        source={{ uri: userParsedData?.userAvatar }}
      >
        <Text color={'singletons.white'} fontSize={'xs'}>
          {userParsedData?.userInitials}
        </Text>
      </Avatar>
    </Box>
  );
};
