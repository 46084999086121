import { useQuery } from '~/hooks/useQuery';
import { App } from '~/types/interfaces/app';

export const useLoadAppById = (id: string) => {
  const { data, isLoading } = useQuery<App>(`app/${id}`, {
    queryOptions: {
      enabled: !!id,
    },
    key: `get-app-${id}`,
  });

  return { data, isLoading };
};
