import { HStack, Pressable, VStack } from 'native-base';
import Icon from 'ui/components/Icon';
import { colors } from 'ui/theme/colors';

import { InfoLabel } from '~/pages/Authenticated/v2/ActivityView/components/ActivityInfoCard/components/InfoLabel';
import { ActivityInfoCardProps } from '~/pages/Authenticated/v2/ActivityView/components/ActivityInfoCard/types';

export const ActivityInfoCard: React.FC<ActivityInfoCardProps> = (props) => {
  const { activity, isSelected, onPress, trailingContent, labels = [] } = props;

  return (
    <VStack
      px={3}
      py={4}
      minH={'108px'}
      borderRadius={'6px'}
      justifyContent={'center'}
      backgroundColor={isSelected ? colors.gray[600] : colors.gray[50]}
      my={1.5}
    >
      <Pressable onPress={() => onPress?.(activity._id)}>
        <HStack space={3}>
          <VStack
            w={16}
            overflow={'hidden'}
            alignItems={'center'}
            justifyContent={'center'}
            borderRightColor={colors.gray[200]}
            borderRightWidth={1}
          >
            {trailingContent}
          </VStack>

          <VStack w="full" space={2} justifyContent={'space-between'}>
            {labels.map(({ key, icon, text, numberOfLines }, index: number) => {
              return (
                <InfoLabel
                  key={key}
                  hightLight={index === 0}
                  icon={icon}
                  text={text}
                  numberOfLines={numberOfLines}
                  iconColor={isSelected ? colors.white : colors.gray[400]}
                  fontColor={isSelected ? colors.white : colors.darkText}
                />
              );
            })}
          </VStack>

          <VStack
            overflow={'hidden'}
            alignItems={'center'}
            justifyContent={'center'}
            w={8}
          >
            <Icon
              size={24}
              icon="check-circle"
              color={isSelected ? colors.white : colors.black}
            />
          </VStack>
        </HStack>
      </Pressable>
    </VStack>
  );
};

export default ActivityInfoCard;
