import { Box, PresenceTransition, Stack, Text } from 'native-base';
import { useState } from 'react';
import Button from 'ui/components/Button';
import { useWatchElementSize } from 'ui/hooks/useWatchElementSize';

import { ArticlePreviewProps } from '~/components/ArticlePreview/types';
import { colors } from '~/theme/colors';

const ArticlePreview = (props: ArticlePreviewProps) => {
  const { maxHeight = 140, text } = props;
  const { ref, dimensions: textDimensions } = useWatchElementSize();

  // -- States
  const [isOpen, setIsOpen] = useState(false);

  // -- Handlers
  const onButtonToggle = () => {
    setIsOpen((prev) => !prev);
  };

  // -- Computed
  const viewPortMaxHeight = isOpen ? 'unset' : maxHeight;
  const showButton = textDimensions.height > maxHeight;
  const normalizedText = text.trim();

  return (
    <>
      <Box>
        <Stack space="2">
          <PresenceTransition
            visible={true}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 250 } }}
          >
            <Stack overflow="hidden">
              {/* <Stack maxH={viewPortMaxHeight} overflow="hidden"> */}
              <Text
                ref={ref}
                fontSize={'sm'}
                lineHeight={'150%'}
                maxHeight={'full'}
                h={'fit-content'}
                color={colors.darkText}
              >
                {normalizedText}
              </Text>
            </Stack>
          </PresenceTransition>

          {/* {showButton ? (
            <Button
              h={9}
              mt={2}
              onPress={onButtonToggle}
              bgColor={colors.gray[100]}
              rightIconSize={16}
              rightIconName={!!isOpen ? 'chevron-up' : 'chevron-down'}
              rightIconColor={colors.singletons.black}
            >
              <Text
                color={colors.singletons.black}
                fontSize={12}
                fontWeight="medium"
              >
                {isOpen ? ' Show less' : 'Read more'}
              </Text>
            </Button>
          ) : null} */}
        </Stack>
      </Box>
    </>
  );
};

export default ArticlePreview;
