export enum ESliceNames {
  AUTH = 'auth',
  FORGOT_PASSWORD = 'forgotPassword',
  ECOSYSTEM = 'ecosystem',
  IN_APP = 'inApp',
  REDIRECT = 'redirect',
  MEASUREMENTS_DATA = 'measurementsData',
  PERMISSIONS = 'permissions',
  NOTIFICATION = 'notification',
  SESSION = 'session',
}
