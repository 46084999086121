import { useMemo } from 'react';

import { useUserLocationController } from '~/hooks/useUserLocation/useUsersLocation';
import { IActivity } from '~/types/interfaces/activity';
import {
  getActivityDaysToBegin,
  getActivityDistance,
  getActivityStartDateStr,
} from '~/utils/activity/activity-getters';

export type useActivityControllerProps = {
  activity?: IActivity;
};

export type useActivityControllerResult = {
  distance: number;
  startDateStr: string;
  daysToBegin: number;
};

export const useActivityController = (
  props: useActivityControllerProps,
): useActivityControllerResult => {
  const { activity } = props;
  const { location } = useUserLocationController();

  const distance = useMemo(
    () => getActivityDistance(activity, location),
    [activity, location],
  );

  const startDateStr = useMemo(
    () => getActivityStartDateStr(activity),
    [activity],
  );

  const daysToBegin = useMemo(
    () => getActivityDaysToBegin(activity),
    [activity],
  );

  return { distance, startDateStr, daysToBegin };
};
