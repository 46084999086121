import { colors } from 'ui/theme/colors';

export const POPOVER_OPTIONS = ({
  onEndActivity,
  onDeleteActivity,
}: {
  onEndActivity: VoidFunction;
  onDeleteActivity: VoidFunction;
}) => [
  /* 

    // !! TODO: Implement End offplatform activity option

    {
      icon: {
        color: colors.error[600],
        icon: 'x-circle',
        size: 22,
      },
      title: 'End activity',
      titleColor: 'error.600',
      action: onEndActivity,
    }, 

  */
  {
    icon: {
      color: colors.error[600],
      icon: 'trash',
      size: 22,
    },
    titleColor: 'error.600',

    title: 'Delete All Logs',
    action: onDeleteActivity,
  },
];
