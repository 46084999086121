import { Pressable, Text, VStack } from 'native-base';
import { useMemo } from 'react';
import Tooltip from 'ui/components/v2/Tooltip';
import { formatTimeUtil } from 'ui/utils/formatDates';

import { CalendarDateTimeStyleMap } from '~/components/CalendarInline/components/CalendarDateTime/constants';
import { CalendarDateTimeProps } from '~/components/CalendarInline/components/CalendarDateTime/types';
import { getDateTimeState } from '~/components/CalendarInline/components/CalendarDateTime/utils';
import { colors } from '~/theme/colors';

export const CalendarDateTime = (props: CalendarDateTimeProps) => {
  const { date, tooltipMessage, isBlocked, isSelected, onPress } = props;

  const style = CalendarDateTimeStyleMap.get(
    getDateTimeState(isBlocked, isSelected),
  );

  const time = useMemo(() => {
    return formatTimeUtil(date);
  }, [date]);

  const isDisabled = !!isBlocked || !!isSelected;

  const tooltipLabel = tooltipMessage || style?.tooltipLabel || '';

  return (
    <Tooltip width={'30%'} label={tooltipLabel}>
      <Pressable
        isDisabled={isDisabled}
        style={{ cursor: isDisabled ? 'not-allowed' : 'pointer' } as any}
        onPress={() => onPress(date)}
        mb={2}
      >
        {({ isHovered }) => (
          <VStack
            backgroundColor={style?.backgroundColor}
            py={2}
            borderRadius={'6px'}
            alignItems={'center'}
            justifyContent={'center'}
            borderWidth={1}
            borderColor={isHovered ? colors.black : style?.borderColor}
          >
            <Text color={style?.fontColor} fontWeight={400} fontSize={'md'}>
              {time}
            </Text>
          </VStack>
        )}
      </Pressable>
    </Tooltip>
  );
};

export default CalendarDateTime;
