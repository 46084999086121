import { useMemo } from 'react';
import { Info } from 'ui/components/Cards/CardPropInfo/types';
import { ESpaceOptionsKeys } from 'ui/enums';

import {
  ActivityAboutProps,
  EXPECTED_SPACE_OPTIONS,
} from '~/pages/Authenticated/v2/ActivityView/components/ActivityAbout/types';
import { capitalizeFirstLetter } from '~/utils/text-transform';

export const useActivityAboutController = (props: ActivityAboutProps) => {
  const { spaceOptions } = props;

  const getSpaceOptionsInfo = (spaceOption: ESpaceOptionsKeys): Info => {
    const foundSpaceOption = EXPECTED_SPACE_OPTIONS.find(
      (v) => String(v).toUpperCase() === spaceOption.toUpperCase(),
    );

    let label = capitalizeFirstLetter(foundSpaceOption || '');
    if (foundSpaceOption === ESpaceOptionsKeys.Both) {
      label = `${capitalizeFirstLetter(
        ESpaceOptionsKeys.Indoor,
      )} and ${capitalizeFirstLetter(ESpaceOptionsKeys.Outdoor)}`;
    }

    return {
      icon: foundSpaceOption !== ESpaceOptionsKeys.Indoor ? 'sun' : 'home',
      info: label,
    };
  };

  const spaceOptionsInfo = useMemo(() => {
    if (!spaceOptions) return null;
    return getSpaceOptionsInfo(spaceOptions);
  }, [spaceOptions]);

  return { spaceOptionsInfo };
};
