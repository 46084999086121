import { useQuery } from 'react-query';

import { FeaturedActivityDefinitionService } from '~/services/resources/featured-activity-definitions';
import { FeaturedActivityDefinitionResponse } from '~/services/resources/featured-activity-definitions/types';

export const useLoadFeaturedActivityDefinitionByEcosystem = (
  ecosystemId?: string,
) => {
  const { data, isLoading } = useQuery<FeaturedActivityDefinitionResponse>({
    queryKey: `${FeaturedActivityDefinitionService.BASE_URL}-ecosystem-${ecosystemId}`,
    queryFn: () =>
      FeaturedActivityDefinitionService.findByEcosystem(ecosystemId || ''),
    enabled: !!ecosystemId,
  });

  return {
    data,
    isLoading,
  };
};
