import { App } from 'ui/types/interfaces/app';

import { useSearchRoutes } from '~/components/Layouts/v2/SearchLayout/hooks/use-search-routes';
import { PAGES } from '~/constants/pages.constants';
import { useVolunteerEcosystemAvailableApps } from '~/hooks/useApps/useVolunteerEcosystemAvailableApps';
import { useRouter } from '~/hooks/useRouter';
import {
  IHomeWidgetController,
  IHomeWidgetControllerResult,
} from '~/pages/Authenticated/v2/Home/components/Widget/types';

export interface HomeWidgetYourAppsController
  extends IHomeWidgetControllerResult<{ apps: App[] }> {
  onAppPress: (app: App) => void;
}

export const useHomeWidgetYourAppsController: IHomeWidgetController<
  HomeWidgetYourAppsController
> = () => {
  const { goToRoute } = useRouter();
  const { goToInApp } = useSearchRoutes();

  const { apps } = useVolunteerEcosystemAvailableApps();

  const handleCardPress = () => {
    const url = PAGES.AllApps;
    goToRoute(url);
  };

  const handleAppPress = (app: App) => {
    goToInApp(app._id);
  };

  return {
    data: { apps },
    onCardPress: handleCardPress,
    onAppPress: handleAppPress,
  };
};
