import { useEffect, useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import {
  ActivityViewStateFull,
  useActivityViewStore,
} from '~/pages/Authenticated/v2/ActivityView/store';
import { IUserActivity } from '~/pages/Authenticated/v2/ActivityView/types';
import { ViewActivityDetailsProps } from '~/pages/Authenticated/ViewActivityDetails/types';

/*
    -- useActivityViewController --

	This controller is responsible for finding an activity 
  whenever the selectedId changes in SearchParams.
*/

export const useActivityViewController = (props: ViewActivityDetailsProps) => {
  // -- Providers
  const { appId } = useParams();
  const { activities, activitiesMap, activityDefinition, activityType } =
    useActivityViewStore((state) => state) as ActivityViewStateFull;

  const { selectedActivity } = useActivityViewStore((state) => state);
  const setSelectedActivity = useActivityViewStore(
    (state) => state.setSelectedActivity,
  );
  // -- Hooks
  const [search] = useSearchParams();
  const selectedActivityId = useMemo(() => search.get('selected'), [search]);

  // Select activity
  useEffect(() => {
    let selectedActivity: IUserActivity | undefined;

    if (activitiesMap.size > 0) {
      const key = selectedActivityId || activitiesMap.keys().next().value;
      selectedActivity = activitiesMap.get(key);
    }
    setSelectedActivity(selectedActivity);
  }, [activitiesMap, selectedActivityId, setSelectedActivity]);

  return {
    activityDefinition,
    selectedActivity,
    activityType,
    activities,
    appId,
  };
};
