import { useEffect, useMemo, useRef, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AppVisibility } from 'ui/enums';
import { Team } from 'ui/types/teams';
import { getTeamMembersTotal } from 'ui/utils/teams';
import { compareCaseInsensitive } from 'ui/utils/validations/compare';

import { PAGES } from '~/constants/pages.constants';
import { useAppSelector } from '~/hooks/useAppSelector';
import { useAuth } from '~/hooks/useAuth';
import { useRouter } from '~/hooks/useRouter';
import TeamService from '~/services/resources/teams';
import { redirectSliceActions } from '~/store/slices/redirect';
import { ITeamInviteData } from '~/types/interfaces/team';
import { getNameInitials } from '~/utils/getInitials';

export const useTeamInvitationController = () => {
  // Providers
  const { goToRoute, replaceRoute } = useRouter();
  const { code } = useParams();

  const { isAuthenticated, user } = useAppSelector(({ auth }) => auth);
  const dispatch = useDispatch();

  // States
  const accessDenied = useRef(false);

  // utils
  const handleInvalidInvite = (message = '', path = PAGES.Root) => {
    if (!!message) toast.error(message);
    dispatch(redirectSliceActions.removeTeamInviteRedirect());
    goToRoute(path);
  };

  const validateInviteVisibility = (_inviteData: ITeamInviteData) => {
    const visibility = String(_inviteData?.visibility);
    const isPrivate = compareCaseInsensitive(visibility, AppVisibility.Private);
    const usUserOwner = user?.email === _inviteData?.receiverEmail;

    if (isPrivate && !usUserOwner) {
      handleInvalidInvite(
        'Incorrect account for the team invitation. Please check your account and try the link again.',
      );
    }
  };

  // hooks
  const { data: inviteData, isLoading: isLoadingInvite } = useQuery({
    queryKey: [`${TeamService.URL}/code/${code}`, code],
    queryFn: async () =>
      await TeamService.getInvitationDataByCode(code as string),
    onSuccess: (data: ITeamInviteData) => {
      if (!data) handleInvalidInvite('This invite has expired.');
      validateInviteVisibility(data);
    },
    onError: () => handleInvalidInvite('Invite not found.'),
    enabled: !!code && !!isAuthenticated,
  });

  const { data: teamData, isLoading: isLoadingTeam } = useQuery({
    queryKey: ['team', inviteData?.team || ''],
    queryFn: () =>
      TeamService.findAll({
        filter: JSON.stringify({ _id: inviteData?.team || '' }),
        populate: JSON.stringify(['ecosystem']),
      }),
    onSuccess: (data) => {
      if (!data?.data?.[0])
        handleInvalidInvite('The team has already been deleted!');
    },
    onError: () => handleInvalidInvite('The team has already been deleted!'),
    enabled: !!inviteData && !!isAuthenticated,
  });

  // Handlers
  const {
    mutate: mutateAcceptInvitation,
    isLoading: isLoadingAcceptInvitation,
  } = useMutation({
    mutationFn: async () => {
      const visibility = String(inviteData?.visibility).toUpperCase();
      if (visibility === AppVisibility.Public.toUpperCase()) {
        return TeamService.acceptPublicInvitation(String(code));
      }
      return TeamService.acceptInvitation(code as string);
    },
    onSuccess: async () => {
      toast.success('You have joined the team!');
      handleInvalidInvite('', PAGES.SwitchPersona);
    },
  });

  const {
    mutate: mutateRejectInvitation,
    isLoading: isLoadingRejectInvitation,
  } = useMutation({
    mutationFn: async () => TeamService.rejectInvitation(code as string),
    onSuccess: () => {
      handleInvalidInvite(
        'You have rejected the team invitation!',
        PAGES.Teams,
      );
    },
  });

  const handleRedirectToAuthflow = () => {
    toast.info(
      'You need to sign in or sign up to accept the team  invitation!',
    );
    dispatch(
      redirectSliceActions.addTeamInviteRedirect({
        hasInvite: true,
        code: code,
      }),
    );
    replaceRoute(PAGES.Root);
  };

  useEffect(() => {
    if (!isAuthenticated && accessDenied.current == false) {
      accessDenied.current = true;
      handleRedirectToAuthflow();
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  /* Computed values */

  const teamInfo = useMemo(() => teamData?.data?.[0] || null, [teamData]);

  const teamLeaderNameInitial = useMemo(() => {
    if (!teamInfo) return '';
    return getNameInitials(
      teamInfo?.leaderSubDocument?.userSummary?.name as string,
    );
  }, [teamInfo]);

  const teamMembersTotal = useMemo(() => {
    if (!teamInfo) return 0;
    return getTeamMembersTotal(teamInfo as unknown as Team);
  }, [teamInfo]);

  const isLoadingSubmit =
    isLoadingAcceptInvitation || isLoadingRejectInvitation;

  const isLoading = isLoadingInvite || isLoadingTeam;

  return {
    isAuthenticated,
    teamInfo,
    isLoading,
    isLoadingSubmit,
    mutateAcceptInvitation,
    mutateRejectInvitation,
    teamLeaderNameInitial,
    teamMembersTotal,
  };
};
