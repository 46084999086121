export const transformURL = (url: string) => {
  if (!url.startsWith('http://') && !url.startsWith('https://')) {
    return `https://${url}`;
  }
  return url;
};

export function sanitizeUrl(url: string): string {
  return url.replace(/\/$/, '');
}

interface QueryParams {
  [key: string]: string | number | boolean | undefined;
}

export function generateUrlWithQueryParams(
  baseUrl: string,
  queryParams: QueryParams,
) {
  const params = [];

  for (const key in queryParams) {
    if (queryParams.hasOwnProperty(key)) {
      const value = queryParams[key];

      if (value !== undefined) {
        params.push(`${key}=${encodeURIComponent(String(value))}`);
      }
    }
  }

  let url = baseUrl;
  if (params.length > 0) {
    url += `?${params.join('&')}`;
  }

  return url;
}
