import { ITeam } from '~/types/interfaces/team';

export const mapTeamToOptions = (teams: ITeam[], search: string) => {
  const searchText = search.trim() || '';
  let options = teams.map(parseTeamOption);

  if (searchText.length > 0) {
    options = options.filter((opt) => {
      const regex = new RegExp(searchText, 'i');
      return (opt?.label || '').match(regex);
    });
  }
  return Promise.resolve(options);
};

export const parseTeamOption = (team: ITeam) => {
  return {
    value: JSON.stringify(team),
    label: team?.name || '',
  };
};
