import { sanitizeQueryParams } from 'ui/utils/queryParams';

import { publicApi } from '~/services/api';
import { ACTIVITY_DEFINITION_SEARCH_MODULE_URL } from '~/services/resources/activity-definitions/search/constants';
import { ActivityDefinitionPublicSearchResponse } from '~/services/resources/activity-definitions/search/types';

import { UnifiedSearchFilters } from './unified-public-search.types';

export class ActivityDefinitionUnifiedPublicSearch {
  static BASE_URL = `${ACTIVITY_DEFINITION_SEARCH_MODULE_URL}/unified`;

  static async findAll(
    filters: Partial<UnifiedSearchFilters>,
  ): Promise<ActivityDefinitionPublicSearchResponse[]> {
    try {
      const url = ActivityDefinitionUnifiedPublicSearch.BASE_URL;
      const queryParams = sanitizeQueryParams(filters as Record<string, any>);
      const { data } = await publicApi.get(url, {
        params: queryParams,
      });

      const responseData = data || [];
      return responseData;
    } catch (error) {
      return [];
    }
  }
}
