import { useDisclose } from 'native-base';

import { ActivityTeamSelectorProps } from '~/pages/Authenticated/v2/ActivityView/components/ActivitySelectors/ActivityTeamSelector/types';
import { ITeam } from '~/types/interfaces/team';

export const useActivityTeamSelectorController = (
  props: ActivityTeamSelectorProps,
) => {
  const {
    onOpen: customOnOpen,
    onTeamChange,
    onNewTeam,
    teamMinSize,
    teamMaxSize,
  } = props;
  const { isOpen, onClose, onOpen } = useDisclose();

  const handleTeamSelect = (neTeam: ITeam | null) => {
    onTeamChange(neTeam);
    onClose();
  };

  const handleCreateNewTeam = () => {
    onNewTeam();
  };

  const teamsSizeStr = `${teamMinSize || 0} - ${teamMaxSize || 0} person team`;

  return {
    isOpen,
    teamsSizeStr,
    onClose,
    handleTeamSelect,
    onOpen: customOnOpen || onOpen,
    handleCreateNewTeam,
  };
};
