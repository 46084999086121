import { Box, FlatList, Hidden, Stack, Text } from 'native-base';
import { useCallback } from 'react';

import { ActivityCardRecommendationsSkeletonList } from '~/components/ActivityCards/CardRecommendations';
import ModalRemove from '~/components/ModalRemove';
import { PAGES } from '~/constants/pages.constants';
import { OffplatormActivitiesEmptyState } from '~/pages/Authenticated/LogActivities/components/OffplatformActivitiesEmptyState';
import OffPlatformActivitiesHeader from '~/pages/Authenticated/LogActivities/OffPlatformActivities/components/OffPlatformActivitiesHeader';
import { OffPlatformLogCard } from '~/pages/Authenticated/LogActivities/OffPlatformActivities/components/OffPlatformLogCard';
import { useOffPlatformActivitiesController } from '~/pages/Authenticated/LogActivities/OffPlatformActivities/useOffPlatformActivitiesController';
import { PopulatedMeasurementDefinition } from '~/types/interfaces/measurement-definition';

import { OffPlatformActivitiesProps } from './types';

export const OffPlatformActivities = (props: OffPlatformActivitiesProps) => {
  const { isLoading, measurements, refetchActivity } = props;
  const {
    modalRef,
    isSubmitting,
    showNoMoreActivitiesWarning,
    goToRoute,
    handleOptions,
    handleCloseModal,
    handleDeleteMeasurementDefinition,
    handleOffPlatformCreate,
  } = useOffPlatformActivitiesController(props);

  const renderItem = useCallback(
    ({ item }: { item: PopulatedMeasurementDefinition }) => {
      const updateUrl = PAGES.UpdateOffPlatformActivity.replace(
        ':definitionId',
        item?._id,
      );

      return (
        <Stack mt={4} flexShrink={0}>
          <OffPlatformLogCard
            measurementDefinition={item}
            onLogActivityPress={() => goToRoute(updateUrl)}
            onOptionsPress={(action) => handleOptions(action, item._id)}
            onRefresh={refetchActivity}
          />
        </Stack>
      );
    },
    [goToRoute, handleOptions],
  );

  return (
    <>
      <Box h={'full'}>
        <Hidden till={'sm'}>
          <OffPlatformActivitiesHeader onClick={handleOffPlatformCreate} />
        </Hidden>

        {isLoading ? (
          <Stack flexShrink={0} mt={4}>
            <ActivityCardRecommendationsSkeletonList quantity={3} />
          </Stack>
        ) : !!measurements?.length && measurements?.length > 0 ? (
          <FlatList
            position={'relative'}
            data={measurements}
            keyExtractor={(item) => item._id}
            renderItem={renderItem}
            pb={{ base: '64px', md: 'unset' }}
          />
        ) : (
          <OffplatormActivitiesEmptyState />
        )}
      </Box>

      {showNoMoreActivitiesWarning && (
        <Text
          alignSelf={'center'}
          fontSize={'md'}
          color={'gray.400'}
          pt={4}
          pb={6}
        >
          No more activities.
        </Text>
      )}

      <ModalRemove
        modalRef={modalRef}
        headerText="Delete all logs"
        handleCloseModal={handleCloseModal}
        handleDelete={handleDeleteMeasurementDefinition}
        rightBtnLoading={isSubmitting}
        rightBtnText="Delete"
      >
        <Stack space={5}>
          <Text fontWeight={400} fontSize="md" color="gray.500">
            Are you sure that you want to delete all logs?
          </Text>

          <Text fontWeight={400} fontSize="md" color="gray.500">
            This action is irreversible and the logs will be immediately removed
            from the list.
          </Text>
        </Stack>
      </ModalRemove>
    </>
  );
};

export default OffPlatformActivities;
