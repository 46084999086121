import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { colors } from 'ui/theme/colors';

import { useVolunteerUser } from '~/hooks/user/useVolunteerUser';
import { useInApp } from '~/zustand/store/InApp';

export const useInAppControllerSearchController = () => {
  // Providers
  const { appId } = useParams();
  const { app } = useInApp((state) => state);
  const profileProgressBannerRenderTimesRef = useRef(0);
  const [showProfileProgressBanner, setShowProfileProgressBanner] =
    useState(false);

  const { isSingleAppPersona } = useVolunteerUser();

  useEffect(() => {
    if (
      !!isSingleAppPersona &&
      profileProgressBannerRenderTimesRef.current === 0
    ) {
      setShowProfileProgressBanner(true);
      profileProgressBannerRenderTimesRef.current++;
    }
  }, [showProfileProgressBanner, isSingleAppPersona]);

  // Handlers
  const handleCloseProfileProgressBanner = () => {
    setShowProfileProgressBanner(false);
  };

  const appBrandColor = app?.brandColor || colors.singletons.black;

  return {
    app,
    appId,
    appBrandColor,
    showProfileProgressBanner,
    handleCloseProfileProgressBanner,
  };
};
