import { useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ActivitySemanticType } from 'ui/types/activities';
import { MeasurementUnit } from 'ui/types/measurementUnit';

import { PAGES } from '~/constants/pages.constants';
import { ApplicationActionStyleMapByType } from '~/pages/Authenticated/v2/ActivityView/views/ActivityApplication/constants/application-style-map';
import { useActivityApplicationController } from '~/pages/Authenticated/v2/ActivityView/views/ActivityApplication/controllers/application-controller';
import { getActivityLogAmount } from '~/utils/activity/activity-definition-getters';
import { generateUrlWithQueryParams } from '~/utils/transformURL';

const styleMap = ApplicationActionStyleMapByType.get(
  ActivitySemanticType.Action,
);

export const useActionActivityApplicationController = () => {
  // -- Providers
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const {
    selectedActivity,
    activityDefinition,
    applicationAvailability,
    onExternalApplication,
    title,
    headerStyle,
  } = useActivityApplicationController();

  // -- Handlers
  const handleApplication = () => {
    if (!selectedActivity) return;

    if (selectedActivity?.externalApplyLink) {
      onExternalApplication();
      return;
    }

    const app = searchParams.get('app') || '';
    const baseUrl = PAGES.JoinAction.replace(':actionId', selectedActivity._id);
    const route = generateUrlWithQueryParams(baseUrl, { app });
    navigate(route);

    return;
  };

  const actionLabel = `${styleMap!.actionLabel} ${
    selectedActivity?.externalApplyLink ? ' (by referral)' : ''
  }`;

  const goalText = useMemo(() => {
    const measurementUnit =
      activityDefinition?.measurementUnit as unknown as MeasurementUnit;

    const amount = Number(activityDefinition?.targetAmount);

    return measurementUnit ? getActivityLogAmount(measurementUnit, amount) : '';
  }, [activityDefinition]);

  return {
    title,
    headerStyle,
    selectedActivity,
    handleApplication,
    applicationAvailability,
    actionLabel,
    goalText,
  };
};
