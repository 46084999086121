import { CalendarDateTimeState } from '~/components/CalendarInline/components/CalendarDateTime/types';

export function getDateTimeState(
  isBlocked: boolean,
  isSelected: boolean,
): CalendarDateTimeState {
  if (isBlocked) return CalendarDateTimeState.blocked;

  return !!isSelected
    ? CalendarDateTimeState.selected
    : CalendarDateTimeState.available;
}
