import { useEffect, useMemo, useRef, useState } from 'react';

import {
  ActivityViewStateFull,
  useActivityViewStore,
} from '~/pages/Authenticated/v2/ActivityView/store';
import { IUserActivity } from '~/pages/Authenticated/v2/ActivityView/types';

export const useActivityInteractiveTabsController = () => {
  // -- Providers
  const { activities, activityDefinition, selectedActivity } =
    useActivityViewStore((state) => state) as ActivityViewStateFull;

  // -- States
  const [tabIndex, setTabIndex] = useState(0);
  const appliedActivitiesCountRef = useRef(0);

  // -- Hooks
  const appliedActivities = useMemo(() => {
    if (!activities.length) return [];
    const result = activities.filter((a: IUserActivity) => !!a.userApplication);
    return result;
  }, [activities]);

  useEffect(() => {
    if (appliedActivities.length != appliedActivitiesCountRef.current) {
      appliedActivitiesCountRef.current = appliedActivities.length;
      setTabIndex(1);
    }
  }, [appliedActivities]);

  // -- Handlers
  const gotToTab = (tabIndex: number) => {
    setTabIndex(tabIndex);
  };

  // -- Render Statements
  const hasApplicationsToLog = (appliedActivities ?? []).length > 0;
  const hasActivitiesToApply = activities.length > 0;

  return {
    activities,
    selectedActivity,
    tabIndex,
    gotToTab,
    appliedActivities,
    activityDefinition,
    hasApplicationsToLog,
    hasActivitiesToApply,
  };
};
