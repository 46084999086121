import { Box, Center, Text } from 'native-base';
import Button from 'ui/components/Button';
import Illustration, { ILLUSTRATIONS_NAME } from 'ui/components/Illustration';

import { usePage403Controller } from '~/components/Page403/usePage403Controller';

const Page403 = () => {
  const { goToOpportunities, isAuthenticated, goToSignIn, buttonText } =
    usePage403Controller();
  return (
    <Center p={6} minH="100vh" bg="singletons.lightText">
      <Box alignItems="center" justifyContent="center">
        <Illustration
          name={ILLUSTRATIONS_NAME.DOIT_SOMETHING_WENT_WRONG}
          width={208}
          height={186}
        />
        <Text
          fontSize="2xl"
          maxW={480}
          textAlign="center"
          mt={20}
          fontWeight={500}
          color="grey.900"
        >
          Ooops!
        </Text>
        <Text
          fontSize="MD"
          maxW={380}
          textAlign="center"
          mt={3}
          fontWeight={400}
          color="grey.900"
        >
          {`Sorry, you are not allowed to perform this action.`}
        </Text>

        <Button onPress={goToOpportunities} mt={8}>
          {buttonText}
        </Button>
        {!isAuthenticated && (
          <Button onPress={goToSignIn} mt={4} variant="ghost">
            Return to Sign in
          </Button>
        )}
      </Box>
    </Center>
  );
};

export default Page403;
