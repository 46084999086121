import { User, UserProfile, UserProfileRole } from 'ui/types/interfaces/user';

import { useVolunteerOrganization } from '~/hooks/organization/useVolunteerOrganization';
import { useVolunteerEcosystemAvailableApps } from '~/hooks/useApps/useVolunteerEcosystemAvailableApps';
import { useAppSelector } from '~/hooks/useAppSelector';
import { useUserProfile } from '~/zustand/store/UserProfile';

export const useVolunteerUser = () => {
  const { isCompany, organization } = useVolunteerOrganization();
  const { isSingleAppPersona, appIds } = useVolunteerEcosystemAvailableApps();

  const { availableUserProfiles } = useUserProfile((state) => state);

  const { selectedUserProfile, user: currentUser } = useAppSelector(
    ({ auth }) => auth,
  );

  const userProfile = selectedUserProfile as unknown as UserProfile;
  const user = currentUser as unknown as User;

  const userProfileRole = !!isCompany
    ? UserProfileRole.Employee
    : UserProfileRole.Personal;

  const isAdmin = !!user.orgAdminOf?.length;
  const isEmployee = !!isCompany;

  return {
    user,
    isAdmin,
    isEmployee,
    userProfile,
    userProfileRole,
    isSingleAppPersona,
    userAppsIds: appIds,
    userOrganization: organization,
    availableUserProfiles,
  };
};
