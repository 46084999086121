import { useMemo } from 'react';

import { OffPlatformLogInputProps } from '~/pages/Authenticated/OffPlatformApplication/components/OffPlatformLogInput/types';
import { getUnitAbbreviation } from '~/utils/measurements/format';

export const useOffPlatformLogInputController = (
  props: OffPlatformLogInputProps,
) => {
  const { measurementUnit } = props;

  const unitLabels = useMemo(() => {
    const suffix = getUnitAbbreviation(measurementUnit);
    return { suffix, plural: measurementUnit?.pluralLabel || '' };
  }, [measurementUnit]);

  return { unitLabels };
};
