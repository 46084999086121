import {
  HStack,
  Pressable,
  Skeleton,
  Text,
  useBreakpointValue,
} from 'native-base';
import { ReactNode } from 'react';
import { colors } from 'ui/theme/colors';

export const PrimaryCarouselTitle = ({
  text,
  onPress,
  isLoading = false,
  children,
}: {
  text?: string;
  onPress?: VoidFunction;
  isLoading?: boolean;
  children?: ReactNode;
}) => {
  return (
    <HStack alignItems={'center'}>
      <Pressable onPress={onPress} w="full">
        <Text
          fontSize={{ base: 'xl', md: '2xl' }}
          fontWeight={500}
          color={colors.singletons.darkText}
        >
          {isLoading ? (
            <Skeleton.Text lines={1} h={4} width="50%" maxW={'200px'} />
          ) : (
            text
          )}
        </Text>
      </Pressable>

      {children}
    </HStack>
  );
};

export const PrimaryCarouselSubTitle = ({
  text,
  smallText,
  isLoading = false,
}: {
  text: string;
  smallText: string;
  isLoading?: boolean;
}) => {
  const subTitle = useBreakpointValue({ base: smallText, md: text });
  return (
    <Text
      fontSize={{ base: 'sm', md: 'md' }}
      color={'gray.600'}
      fontWeight={400}
    >
      {isLoading ? (
        <Skeleton.Text lines={1} h={2} width="80%" maxW={'420px'} />
      ) : (
        subTitle
      )}
    </Text>
  );
};
