import { CarouselTag } from 'ui/components/v2/Carousel/PrimaryCarousel/types';
import { SearchActivityDefinition } from 'ui/types/interfaces/activity-definition';

export type CarouselActivityDefinitionData = SearchActivityDefinition;

export type ActivityCarouselChangeFunction = (e: {
  dataLength: number;
  isLoading: boolean;
  queryKey: string[];
}) => void;
export class ActivityCarouselDataSource {
  constructor(
    public onLoad: Promise<CarouselActivityDefinitionData[]>,
    public queryKey: string[],
    public onChange?: ActivityCarouselChangeFunction,
  ) {}
}

export type ActivityCarouselProps = {
  title?: string;
  headerTags?: CarouselTag[];
  subTitle?: { small?: string; large: string };
  isEnabled?: boolean;
  onActionPress?: VoidFunction;
  dataSource: ActivityCarouselDataSource;
  marginBottom?: number | string;
  marginTop?: number | string;
};
