import { HStack, Text } from 'native-base';

import CommonInputWrapper from '../../../CommonInputWrapper';
import TextInput from '../../TextInput';
import { InputSize } from '../../types';
import { useCustomLogInputController } from './controller';
import { CustomLogInputProps } from './types';

export const CustomLogInput = (props: CustomLogInputProps) => {
  const { onBlur, value, unitLabel, errorMessage } = props;
  const { handleOnChange } = useCustomLogInputController(props);

  return (
    <CommonInputWrapper errorMessage={errorMessage}>
      <HStack alignItems="center" space={2}>
        <TextInput.Base
          value={String(value)}
          onChange={handleOnChange}
          onBlur={onBlur}
          size={InputSize.lg}
          fontSize="3xl"
        />
        <Text fontSize={'1.87rem'} fontWeight={400} color="gray.400" ml="2">
          {unitLabel}
        </Text>
      </HStack>
    </CommonInputWrapper>
  );
};

export default CustomLogInput;
