import { getAppPermissionsFromAppPermissionsGroups } from 'ui/utils/permissions';

import { useAppSelector } from '~/hooks/useAppSelector';

import { ValidateAppPermissionsProps } from './types';

export const useValidateAppPermissionsController = ({
  appIdToValidate,
}: Pick<ValidateAppPermissionsProps, 'appIdToValidate'>) => {
  const { userPermissionsGroupsByAppId } = useAppSelector(
    ({ permissions }) => permissions,
  );

  const availableUserPermissionsForTheAppActivity =
    getAppPermissionsFromAppPermissionsGroups({
      appId: appIdToValidate,
      permissionGroupsByAppId: userPermissionsGroupsByAppId,
    });

  return { availableUserPermissionsForTheAppActivity };
};
