import { yupResolver } from '@hookform/resolvers/yup';
import { useMediaQuery } from 'native-base';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { COMMON_MESSAGES } from '~/constants/messages.constants';
import { PAGES } from '~/constants/pages.constants';
import { useRouter } from '~/hooks/useRouter';
import AuthService from '~/services/resources/auth';

import { schemaValidation } from './constants';
import { IConfirmEmailFields } from './types';

export const useResetPasswordController = () => {
  const { goToRoute, goBack, location } = useRouter();
  const [userEmail] = useState(location?.state?.email || '');
  const [accessCode] = useState(location?.state?.accessCode || '');

  const [isLoading, setIsLoading] = useState(false);

  const [isMobile] = useMediaQuery({
    maxWidth: 480,
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IConfirmEmailFields>({
    resolver: yupResolver(schemaValidation),
  });

  const onSubmit = handleSubmit(async (data) => {
    try {
      setIsLoading(true);

      const response = await AuthService.confirmForgotPassword({
        email: userEmail,
        code: accessCode,
        newPassword: data.password.trim(),
      });

      if (response) {
        goToRoute(PAGES.Root);
        toast.success(COMMON_MESSAGES.PASSWORD_RESETED);
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  });

  return {
    control,
    errors,
    goBack,
    onSubmit,
    isLoading,
    isMobile,
  };
};
