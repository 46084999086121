import { Image, Stack } from 'native-base';
import RocketAnimation from 'ui/assets/animations/rocket_animation.gif';
import DoitLogo from 'ui/assets/illustrations/BlackDoitLogo.svg';
import { PoweredByDoit } from 'ui/components/v2/Labels/PoweredByDoit';

import { SplashScreenProps } from './types';

export const SplashScreen = ({ imageURL }: SplashScreenProps) => {
  return (
    <Stack
      bgColor="white"
      width="100%"
      height="100%"
      alignItems="center"
      justifyContent="center"
      minH="100vh"
      space={12}
    >
      <Stack alignItems="center" space={2}>
        <Image
          width="15rem"
          height="15rem"
          resizeMode="contain"
          source={{ uri: imageURL || DoitLogo }}
        />
      </Stack>

      <Stack space={2} alignItems="center">
        <Image
          source={{ uri: RocketAnimation }}
          width="9.5rem"
          height="9.5rem"
        />
        <PoweredByDoit />
      </Stack>
    </Stack>
  );
};
