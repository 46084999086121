// todo: check location
import { OrganizationSubDocumentDTO } from 'doit-bho-volunteer/src/types/dtos/oportunity';
import { IActivityCategoryDTO } from 'org-admin/src/types/dtos';
import {
  ActivityTypeEnum,
  EEventApplicationType,
  ESpaceOptionsKeys,
  LocationOptionsEnum,
} from 'ui/enums';
import { ActivityRegion } from 'ui/types/activities';
import { MeasurementUnit } from 'ui/types/measurementUnit';

import { App } from '~/types/interfaces/app';
import { Measurement } from '~/types/interfaces/measurement';
import { IOrganization } from '~/types/interfaces/organization';
import { ITeam } from '~/types/interfaces/team';
import { IUserProfile } from '~/types/interfaces/user';

export interface IAddress {
  location: {
    type: string;
    coordinates: number[];
  };
  lat?: number;
  lng?: number;
  address?: string;
  street?: string;
  rawLocation?: string;
  startDate?: Date;
  endDatee?: Date;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
}
// todo: check location
export interface ICauseOptions {
  deleted?: boolean;
  displayName: string;
  relatedTo: string;
  images: [];
  app: string;
  _id: string;
  createdAt?: string;
  updatedAt?: string;
}

export interface IBaseActivityDefinitionSubDocument {
  title: string;
  description: string;
  type: string;
  periodOptions: [];
  causeOptions: ICauseOptions[];
  requirementOptions: IActivityCategoryDTO[];
  coverImage: string;
  carouselImages: string[];
  isApprovalRequired: boolean;
  ecosystem: string;
  organization: string;
  organizationSubDocument: OrganizationSubDocumentDTO;
  eventApplicationType: EEventApplicationType;
  app: App | string;
  publishedApps: string[];
  volunteerRewards: string[];
  volunteerRequirements: string[];
  targetAmount: number;
  measurementUnit: MeasurementUnit | string;
  measurementUnitPluralLabel: string;
  _id: string;
  createdAt: string;
  updatedAt: string;
  __v?: number;
}

type EventType = 'National' | 'Local';

export enum AttendanceType {
  Online = 'Home',
  Local = 'Local',
}

export interface IActivityDefinitionSubDocument
  extends IBaseActivityDefinitionSubDocument {
  attendanceType: string;
  durationInHours: string;
  spaceOptions: string;
  typeOfWork: string;
  eventType: ActivityTypeEnum;
  locationOption: LocationOptionsEnum;
}

export interface IActivity {
  deleted: boolean;
  _id: string;
  startDate: Date;
  volunteerNumber?: number;
  bookingsNumber?: number;
  attendeesNumber?: number;
  endDate: Date;
  dueDate?: Date;
  isVolunteerNumberLimited: boolean;
  noVolunteerLimit: boolean;
  eventType: EventType;
  address: IAddress;
  activityDefinitionSubDocument: IActivityDefinitionSubDocument;
  activityDefinition: string;
  createdAt: string;
  updatedAt: string;
  __v?: number;
  isOnline: true;
  meetingLink: string;
  externalApplyLink?: string;
  teamsMaxSize?: number;
  teamsMinSize?: number;
  teamsNumber?: number;
  app: App | string;
  regions?: ActivityRegion[] | null;
  organization: string | IOrganization;
}

export interface IActivitySummary {
  _id: string;
  startDate: Date;
  endDate: Date;
  address: IAddress;
}

export interface PrecomputedActivitySummary extends IActivitySummary {
  distance?: number;
  relevanceOrder: number[];
}

export interface IActivityDefinition {
  _id: string;
  title: string;
  description: string;
  type: string;
  causeOptions: ICauseOptions[];
  coverImage: string;
  thumbnailImage?: string;
  carouselImages: [];
  isApprovalRequired: false;
  ecosystem: string;
  organization: string;
  app: string;
  publishedApps: string[];
  volunteerRewards: string[];
  volunteerRequirements: string[];
  periodOptions: [];
  requirementOptions: IActivityCategoryDTO[];
  organizationSubDocument: IOrganization;
  activitiesSummary: IActivitySummary[];
  measurementUnit?: string;
  measurementUnitPluralLabel?: string;
  targetAmount?: number;
  eventApplicationType: EEventApplicationType;
  createdAt: string;
  updatedAt: string;
  locationOption: LocationOptionsEnum;
  spaceOptions: ESpaceOptionsKeys;
  __v: number;
}

export interface IPopulatedActivityDefinition
  extends Omit<IActivityDefinition, 'app'> {
  app: App;
}

export enum ActivityApplicationEnum {
  Approved = 'Approved',
  Canceled = 'Canceled',
  Rejected = 'Rejected',
  Referral = 'Referral',
  Pending = 'Pending',
}

export interface IActivityApplicationDeleteModal {
  activityId: string;
  attendanceId: string;
  userName: string;
}

export type IEvent = IActivityDefinition;
export type IPlaces = IActivity;

export interface IActivityApplication {
  _id: string;
  applicationDate: Date;
  cancelApplicationDate: Date;
  status: ActivityApplicationEnum;
  isAttendanceConfirmed: boolean;
  userProfileSubDocument: IUserProfile;
  teamSubDocument: ITeam;
  activitySubDocument: IActivity;
  userProfile: string;
  user: string;
  activity: string;
  organization: IOrganization;
  ecosystem: string;
  measurementTargetAmount: number;
  measurementTargetReached: boolean;
  applicationType: EEventApplicationType;
  statusHistory: [];
  createdAt: string;
  updatedAt: string;
  logHoursDisabled: boolean;
  app: App;
  measurements: Measurement[];
  __v: number;
}
