import { useCallback, useEffect, useMemo, useRef } from 'react';

import { GenericCarouselProps } from './types';

export const useGenericCarouselController = ({
  currentIndex,
  slides,
  columns,
  space,
}: GenericCarouselProps) => {
  const scrollRef = useRef<HTMLDivElement | null>(null);

  // Handlers
  const scrollToStart = useCallback(() => {
    if (scrollRef.current && scrollRef.current.scrollLeft !== 0) {
      scrollRef.current.scrollTo({ left: 0, behavior: 'smooth' });
    }
  }, []);

  useEffect(() => {
    scrollToStart();
  }, [scrollToStart, currentIndex]);

  const translateX = `translateX(-${(currentIndex * 100) / columns}%)`;

  const horizontalMargin = useMemo(() => {
    if (!space) return { base: 0 };

    return Object.fromEntries(
      Object.entries(space).map(([key, value]) => [key, value * -1]),
    );
  }, [space]);

  return {
    slides,
    columns,
    scrollRef,
    currentIndex,
    translateX,
    horizontalMargin,
  };
};
