import { Box, PresenceTransition, Text, VStack } from 'native-base';
import { ReactNode, useMemo } from 'react';
import { colors } from 'ui/theme/colors';

import { FilterSectionName } from '~/components/Layouts/v2/SearchLayout/components/Modals/ActivityFilterModal/constants';
import { ActivityFilterFormFilters } from '~/components/Layouts/v2/SearchLayout/components/Modals/ActivityFilterModal/FilterForm/types';
import { isSectionEnabled } from '~/components/Layouts/v2/SearchLayout/utils/calculate-filters-applied-count';

export type FilterFormSectionWrapperProps = {
  title: string;
  subTitle?: string;
  name?: FilterSectionName;
  filters?: ActivityFilterFormFilters;
  children?: ReactNode | JSX.Element;
};

export const FilterFormSectionWrapper = ({
  name,
  title,
  subTitle,
  children,
  filters,
}: FilterFormSectionWrapperProps) => {
  const showContent = useMemo(() => {
    if (!name) return true;
    return isSectionEnabled(name, filters);
  }, [name, filters]);

  if (!showContent) return null;

  return (
    <PresenceTransition
      visible={true}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 500 } }}
    >
      <VStack>
        <VStack
          space={1}
          borderBottomWidth={1}
          borderColor="gray.200"
          pb={2}
          mb={{ base: 4, md: 6 }}
        >
          <Text
            fontSize={'sm'}
            fontWeight={500}
            color={colors.singletons.darkText}
          >
            {title}
          </Text>
          {subTitle && (
            <Text fontSize={'xs'} fontWeight={400} color={'gray.600'}>
              {subTitle}
            </Text>
          )}
        </VStack>
        <Box>{children}</Box>
      </VStack>
    </PresenceTransition>
  );
};

export default FilterFormSectionWrapper;
