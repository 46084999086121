import { isValid as isValidDate } from 'date-fns';
import { ILocation } from 'ui/types/interfaces';
import { formatDateByEnglishType } from 'ui/utils/formatter';

import { IActivity } from '~/types/interfaces/activity';
import { calculateDistance } from '~/utils/calculate-distance';

export const getActivityDistance = (
  activity?: IActivity,
  location?: ILocation | null,
) => {
  if (!activity || !location) return -1;

  let distance = -1;
  const activityCoordinates = activity?.address?.location?.coordinates;
  if (location && activityCoordinates) {
    const coordinatesObject = {
      lat: activityCoordinates[1],
      lng: activityCoordinates[0],
    };
    distance = calculateDistance(coordinatesObject, {
      lat: location.lat,
      lng: location.lng,
    });
  }
  return distance;
};

export const getActivityStartDateStr = (activity?: IActivity) => {
  if (!activity?.startDate || !isValidDate(new Date(activity.startDate))) {
    return '--/--/--';
  }
  return `${formatDateByEnglishType(new Date(activity.startDate), 'british')}`;
};

export const getActivityDaysToBegin = (activity?: IActivity) => {
  if (!activity?.startDate || !isValidDate(new Date(activity.startDate))) {
    return -1;
  }
  const currentDate = new Date();
  const days = Math.floor(
    (new Date(activity.startDate).getTime() - currentDate.getTime()) /
      (24 * 3600 * 1000),
  );
  return days;
};

export const getActivitySpotsAvailable = (activity?: IActivity) => {
  /* nullable value will be considered as unlimited spots  */
  if (!activity || !activity?.isVolunteerNumberLimited) return;

  return (
    Number(activity.volunteerNumber || 0) - Number(activity.bookingsNumber || 0)
  );
};
