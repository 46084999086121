import {
  HStack,
  Pressable,
  Text,
  useBreakpointValue,
  VStack,
} from 'native-base';
import { useMemo } from 'react';
import Icon from 'ui/components/Icon';
import Tooltip from 'ui/components/v2/Tooltip';
import { colors } from 'ui/theme/colors';
import {
  secondsToHoursAndMinutes,
  secondsToHoursMinutesString,
} from 'ui/utils/secondsToDuration';

export type VolunteeringHoursInfoData = {
  loggedSeconds?: number;
  companyGoal?: number;
};

export type VolunteeringHoursInfoSnippetProps = {
  data: VolunteeringHoursInfoData;
  onPress?: VoidFunction;
};

export const VolunteeringHoursInfoSnippet = ({
  data,
  onPress,
}: VolunteeringHoursInfoSnippetProps) => {
  const { loggedSeconds = 0, companyGoal = 0 } = data;
  const iconSize = useBreakpointValue({
    base: '20px',
    md: '24px',
  });

  const formatted = useMemo(() => {
    const companyGoalInHours = secondsToHoursAndMinutes(companyGoal).hours;
    const loggedSecondsInHours = secondsToHoursMinutesString(loggedSeconds);
    return {
      companyGoal: `${companyGoalInHours}h`,
      loggedHours: loggedSecondsInHours,
    };
  }, [loggedSeconds, companyGoal]);

  return (
    <Pressable
      w="full"
      h="full"
      flex={1}
      flexDir={'row'}
      alignItems={'center'}
      justifyContent="space-between"
      onPress={onPress}
    >
      <VStack space={1}>
        <HStack alignItems="center" space={2}>
          <Icon icon="clock" size={iconSize} color={colors.success[700]} />
          <Text
            fontSize={{ base: 'lg', md: '2xl' }}
            lineHeight={'125%'}
            fontWeight="bold"
          >
            {formatted.loggedHours}
          </Text>
        </HStack>
        <Text
          lineHeight={{ base: '150%', md: '125%' }}
          fontSize={{ base: '10px', md: 'sm' }}
          color="gray.600"
          fontWeight={400}
        >
          You&apos;ve logged so far
        </Text>
      </VStack>

      <VStack
        alignItems="flex-start"
        backgroundColor={'gray.100'}
        p={2}
        borderRadius={8}
      >
        <Text fontSize={{ base: 'xs', md: 'sm' }} color="gray.500">
          Company goal
        </Text>
        <HStack
          w="full"
          justifyContent={'space-between'}
          alignItems="center"
          space={2}
        >
          <Text
            fontSize={{ base: 'lg', md: 'xl' }}
            lineHeight={'125%'}
            fontWeight={500}
          >
            {formatted.companyGoal}
          </Text>
          <Tooltip label="Your company’s annual target for volunteering hours.">
            <Icon icon="help-circle" size="20px" color={colors.gray[600]} />
          </Tooltip>
        </HStack>
      </VStack>
    </Pressable>
  );
};
