import { useInfiniteQuery } from 'react-query';

import { DEFAULT_APP_ID } from '~/config';
import { api } from '~/services/api';

import { GenericObject } from '../types';
import {
  EQueryKeys,
  getQueryKey,
} from './useInfinityActivityApplication.utils';

type UseInfinityActivityApplicationResponse = Promise<{
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  responseData: { data: any[]; count: number };
  skip: number;
}>;

const ITEMS_PER_PAGE = 10;

interface IGetPaginatedActivityApplicationProps {
  pageParam?: number;
  params: GenericObject;
  disabled?: boolean;
}

const getPaginatedActivityApplication = async (
  props: IGetPaginatedActivityApplicationProps,
): UseInfinityActivityApplicationResponse => {
  const { pageParam = 0, params, disabled } = props;
  if (disabled) return { responseData: { data: [], count: 0 }, skip: 0 };

  const auxParams = {
    skip: pageParam,
    limit: ITEMS_PER_PAGE,
  };

  if (params?.filter) {
    auxParams['filter'] = JSON.stringify({
      'activitySubDocument.deleted': false,
      ...params.filter,
    });
  }

  if (params?.populate) {
    auxParams['populate'] = JSON.stringify(params.populate);
  }

  if (params?.sort) {
    auxParams['sort'] = JSON.stringify(params.sort);
  }

  const { data: responseData } = await api.get(`activity-application`, {
    params: auxParams,
  });

  return {
    responseData,
    skip: pageParam + ITEMS_PER_PAGE,
  };
};

export default function useInfinityActivityApplication(
  params: GenericObject,
  disabled = false,
) {
  const queryKey = getQueryKey<GenericObject>(
    EQueryKeys.INFINITY_QUERY_ACTIVITY_APPLICATION,
    params,
  );

  return useInfiniteQuery(
    queryKey,
    ({ pageParam }) =>
      getPaginatedActivityApplication({
        pageParam,
        params,
        disabled,
      }),
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.responseData.data.length < ITEMS_PER_PAGE)
          return undefined;

        return lastPage.skip;
      },
    },
  );
}
