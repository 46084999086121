export type KeyParams = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

export type GenericObject = { [key: string]: unknown };

export const getQueryKey = <T extends KeyParams>(key: string, params?: T) => {
  return [key, ...(params ? [params] : [])];
};
