import { yupResolver } from '@hookform/resolvers/yup';
import { useBreakpointValue } from 'native-base';
import { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { IModalRefProps } from 'ui/components/Modals/Modal/types';

import { useAppSelector } from '~/hooks/useAppSelector';
import { useAuth } from '~/hooks/useAuth';
import { useListenToBrowserBackAction } from '~/hooks/useListenToBrowserBackAction';
import { useRouter } from '~/hooks/useRouter';
import {
  defaultPreferences,
  emailPreferencesFormSchema,
} from '~/pages/Authenticated/Settings/EmailPreferences/constants';
import { IEmailPreferencesForm } from '~/pages/Authenticated/Settings/EmailPreferences/types';
import { UserProfileService } from '~/services/resources/user-profile';

export const useEmailPreferencesController = () => {
  const { selectedUserProfile } = useAppSelector(({ auth }) => auth);

  const { handleGetUserInformation } = useAuth();
  const { goBack } = useRouter();

  const resetPreferencesCofirmModalRef = useRef<IModalRefProps>(null);
  const saveOrDiscardModalRef = useRef<IModalRefProps>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingReset, setIsLoadingReset] = useState(false);

  const quantityOfColumns = useBreakpointValue({
    base: 1,
    xl: 2,
  });
  const {
    control,
    reset,
    handleSubmit,
    formState: { isSubmitting, isDirty },
  } = useForm<IEmailPreferencesForm>({
    resolver: yupResolver(emailPreferencesFormSchema),
    defaultValues: selectedUserProfile?.emailPreferences,
  });

  const onResetPreferences = async () => {
    try {
      setIsLoadingReset(true);
      await UserProfileService.updateUserProfileEmailPreferences({
        userProfileId: selectedUserProfile?._id as string,
        emailPreferences: defaultPreferences,
      });
      await handleGetUserInformation({ shouldFetchAttributes: false });
      reset(defaultPreferences);
      handleCloseResetPreferencesModal();
      goBack();
      toast.success('Your preferences has been reset!');
    } catch (error: any) {
      toast.error(error?.message);
    } finally {
      setIsLoadingReset(false);
    }
  };

  const onSubmitHandler = handleSubmit(
    async (values: IEmailPreferencesForm) => {
      try {
        setIsLoading(true);

        await UserProfileService.updateUserProfileEmailPreferences({
          userProfileId: selectedUserProfile?._id as string,
          emailPreferences: values,
        });
        await handleGetUserInformation({ shouldFetchAttributes: false });
        reset(values);
        goBack();
        toast.success('Changes successfully saved!');
      } catch (error: any) {
        toast.error(error?.message);
      } finally {
        setIsLoading(false);
      }
    },
  );

  const handleOpenSaveOrDiscardModal = () => {
    saveOrDiscardModalRef.current?.open();
  };

  const handleCloseSaveOrDiscardModal = () =>
    saveOrDiscardModalRef.current?.close();

  const handleDiscardChanges = () => {
    goBack();
    handleCloseSaveOrDiscardModal();
  };

  const handleOpenResetPreferencesModal = () => {
    resetPreferencesCofirmModalRef.current?.open();
  };

  const handleCloseResetPreferencesModal = () =>
    resetPreferencesCofirmModalRef.current?.close();

  // Hook to listen to the browser back action, to avoid leaving the form with unsaved changes.
  useListenToBrowserBackAction({
    isEnabled: isDirty,
    onBackAction: handleOpenSaveOrDiscardModal,
  });

  return {
    isLoading,
    isLoadingReset,
    control,
    isSubmitting,
    isDirty,
    onSubmitHandler,
    onResetPreferences,
    quantityOfColumns,
    handleCloseSaveOrDiscardModal,
    handleOpenSaveOrDiscardModal,
    saveOrDiscardModalRef,
    handleDiscardChanges,
    resetPreferencesCofirmModalRef,
    handleOpenResetPreferencesModal,
    handleCloseResetPreferencesModal,
  };
};
