import { HStack, Image, Text, VStack } from 'native-base';
import { useMemo } from 'react';
import { ActivityTypeBadge } from 'ui/components/v2/Badges/ActivityTypeBadge';
import { colors } from 'ui/theme/colors';

import { ActivityHeadlineProps } from '~/pages/Authenticated/v2/ActivityView/components/ActivityHeadline/type';

export const ActivityHeadline = (props: ActivityHeadlineProps) => {
  const { type, title, app, daysToBegin, organization } = props;
  const appLogo = app?.logo || '';
  const appName = app?.name || '';
  const organizationName = organization?.name || '';

  const daysToBeginStr = useMemo(() => {
    if (daysToBegin < 0) return '';
    return daysToBegin === 0 ? 'Today' : `Coming up in ${daysToBegin} days`;
  }, [daysToBegin]);

  return (
    <VStack space={4}>
      <HStack space={3} alignItems={'center'}>
        <ActivityTypeBadge activityType={type} />
        {daysToBegin > -1 && (
          <Text fontSize={'sm'} fontWeight={400} color={colors.gray[600]}>
            {daysToBeginStr}
          </Text>
        )}
      </HStack>

      <Text fontSize={{ base: 'xl', md: '3xl' }} fontWeight={700}>
        {title}
      </Text>

      <HStack alignItems={'center'} space={2}>
        <Image
          source={{ uri: appLogo }}
          borderWidth={1}
          borderColor={'gray.300'}
          alt="app logo"
          w={8}
          h={8}
          borderRadius={'8'}
        />
        <HStack space={{ base: 0, lg: 3 }} w={'auto'}>
          <HStack flex={1} space={{ base: 2, lg: 3 }} alignItems={'center'}>
            {!!appName && (
              <Text
                fontSize={{ base: 'sm', lg: 'md' }}
                color={'singletons.darkText'}
                fontWeight={500}
                noOfLines={1}
              >
                {appName}
              </Text>
            )}

            {!!organizationName && (
              <>
                <Text
                  fontSize={{ base: 'sm', lg: 'md' }}
                  fontWeight={700}
                  color={'gray.600'}
                >
                  •
                </Text>

                <Text
                  fontSize={{ base: 'sm', lg: 'md' }}
                  color={'singletons.darkText'}
                  noOfLines={1}
                >
                  by {organizationName}
                </Text>
              </>
            )}
          </HStack>
        </HStack>
      </HStack>
    </VStack>
  );
};

export default ActivityHeadline;
