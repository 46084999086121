export const REDUX_KEY = 'doit-admin';
export const REDUX_WHITELIST = [
  'auth',
  'forgotPassword',
  'measurementsData',
  'permissions',
  'notification',
  'ecosystem',
  'session',
];
