import { Box, HStack, Text, useBreakpointValue, VStack } from 'native-base';
import { SearchAutoComplete } from 'ui/components/v2/SearchInputs/SearchAutoComplete';

import { STATIC_SEARCH_SUGGESTIONS_VALUES } from '~/components/Layouts/v2/SearchLayout/constants/search-suggestions';
import { colors } from '~/theme/colors';

export type SearchPanelProps = {
  defaultSearchValue?: string;
  onSubmit?: (searchValue: string) => void;
};

export const SearchPanel = ({
  defaultSearchValue,
  onSubmit,
}: SearchPanelProps) => {
  const handleSearchSubmit = (searchValue: string) => {
    onSubmit?.(searchValue);
  };

  const title = useBreakpointValue({
    base: 'Search',
    md: 'Let’s explore opportunities',
  });

  const placeHolder = useBreakpointValue({
    base: "Search for anything you'd like to help with",
    md: "Search for anything you'd like to help with — activities, causes, skills...",
  });

  return (
    <VStack
      w="full"
      px={{ base: 4, md: 12 }}
      py={{ base: 4, md: 6 }}
      space={{ base: 2, md: 4 }}
      background={colors.singletons.white}
      borderTopRadius={{ base: 0, md: '32px' }}
    >
      <Box w="full">
        <Text
          textAlign={{ base: 'left', md: 'center' }}
          color={{ base: 'gray.600', md: colors.singletons.darkText }}
          fontSize={{ base: 'sm', md: '2xl' }}
          fontWeight={500}
        >
          {title}
        </Text>
      </Box>

      <HStack background={'white'}>
        <SearchAutoComplete
          disableOnEmptyInput
          defaultValue={defaultSearchValue}
          onSubmit={handleSearchSubmit}
          placeholder={placeHolder}
          suggestionValues={STATIC_SEARCH_SUGGESTIONS_VALUES}
        />
      </HStack>
    </VStack>
  );
};
