import { useEffect } from 'react';
import { UserProfile } from 'ui/types/interfaces/user';

import { useAppSelector } from '~/hooks/useAppSelector';
import { useQuery } from '~/hooks/useQuery';
import { useUserProfile } from '~/zustand/store/UserProfile';

export const useLoadUserProfileResources = () => {
  const { user } = useAppSelector(({ auth }) => auth);
  const { availableUserProfiles } = useUserProfile((state) => state);

  const setAvailableUserProfiles = useUserProfile(
    (state) => state.setAvailableUserProfiles,
  );

  // TODO: CREATE A HOOK and service
  const { data: userProfiles } = useQuery<UserProfile[]>(
    `user-profile/user/${user?._id}`,
    { queryOptions: { enabled: !!user?._id && !availableUserProfiles.length } },
  );

  const handleSetAvailableUserProfiles = (userProfiles: UserProfile[]) => {
    setAvailableUserProfiles(userProfiles);
  };
  useEffect(() => {
    if (!userProfiles?.length) return;
    handleSetAvailableUserProfiles(userProfiles);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfiles]);
};
