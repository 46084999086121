import { AuthLayout } from '~/components/Layouts/AuthLayout';
export const AuthLayoutNoSideBar = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  return (
    <AuthLayout hideWebSideBar hideMobileNavBar>
      {children}
    </AuthLayout>
  );
};
