import { VStack } from 'native-base';
import { PrimaryCarousel } from 'ui/components/v2/Carousel/PrimaryCarousel';

import { useActivityCarouselController } from './controller';
import { MakeActivityCarouselItemSkeletonSlides } from './Item/ItemSkeleton';
import { ActivityCarouselProps } from './types';

const skeletonSlides = MakeActivityCarouselItemSkeletonSlides({ rows: 5 });

export const ActivityCarousel = (props: ActivityCarouselProps) => {
  const { title, subTitle, marginBottom, headerTags } = props;
  const {
    isEmpty,
    isLoading,
    elementRef,
    carouselSlides,
    handleCarouselActionPress,
  } = useActivityCarouselController(props);

  if (isEmpty) return null;

  return (
    <VStack mb={marginBottom} ref={elementRef}>
      <PrimaryCarousel
        headerTags={headerTags}
        title={title || ''}
        subTitle={subTitle || { large: '', small: '' }}
        isLoading={isLoading}
        slides={isLoading ? skeletonSlides : carouselSlides}
        onTitlePress={handleCarouselActionPress}
        onViewAllPress={handleCarouselActionPress}
      />
    </VStack>
  );
};
