import { Hidden } from 'native-base';

import { DesktopNavBar } from '~/components/Layouts/v2/SearchLayout/components/DesktopNavBar';

import { useSearchLayoutNavBarController } from './controller';

export const DESKTOP_NAVBAR_HEIGHT = 72;

export const LayoutNavBar = () => {
  const { ecosystemLogoUrl, ecosystemName, handleGoHome } =
    useSearchLayoutNavBarController({});

  return (
    <>
      <Hidden till="md">
        <DesktopNavBar
          height={DESKTOP_NAVBAR_HEIGHT}
          ecosystemLogoUrl={ecosystemLogoUrl}
          ecosystemName={ecosystemName}
          onLogoPress={handleGoHome}
        />
      </Hidden>
    </>
  );
};
