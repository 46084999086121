import {
  Box,
  Divider,
  Flex,
  Hidden,
  HStack,
  Link,
  Stack,
  Text,
  useMediaQuery,
  VStack,
} from 'native-base';
import Button from 'ui/components/Button';
import Icon from 'ui/components/Icon';
import Modal from 'ui/components/Modals/Modal';

import ModalPrivacyPolicy from '~/components/ModalPrivacyPolicy';
import ModalTermsOfService from '~/components/ModalTermsOfService';
import { Tab } from '~/components/Tabs';
import { colors } from '~/theme/colors';

import { useAccountSettingsController } from './accountSettingsController';
export const AccountSettings = () => {
  const {
    modalRef,
    handleOpenSignOutModal,
    handleCloseSignOutModal,
    handleSignOut,
    handleGoToChangePassword,
    handleDeleteAccount,
    user,
    privacyModalRef,
    termsModalRef,
    handleOpenTermsModal,
    handleOpenPrivacyModal,
  } = useAccountSettingsController();

  const [isMobile] = useMediaQuery({
    maxWidth: 480,
  });
  return (
    <Stack
      flex={1}
      w="full"
      minH="full"
      flexDir="column"
      bg="white"
      flexShrink={{ base: 0, md: 1 }}
      rounded={8}
    >
      <Hidden till="md">
        <Stack>
          <Tab
            key={'AccountSettings'}
            label="Account Settings"
            description="Email, password and other account settings"
            icon="settings"
            isActive
          />
        </Stack>
      </Hidden>

      <Stack h="full" p={4} space={12} flexShrink={{ base: 0, md: 1 }}>
        <Stack direction={'column'} space={6}>
          <Stack flexDir="column" space={4}>
            <Text fontSize="lg" fontWeight="medium">
              Email and Password
            </Text>
            <Divider />
          </Stack>

          <Stack
            flex={1}
            direction={{ base: 'column', md: 'row' }}
            space={{ base: 8, md: 16 }}
          >
            <VStack space={2} flex={{ base: 'auto', md: 0.5 }}>
              <Text fontWeight="medium" color="gray.600">
                Email address
              </Text>
              <HStack w={'100%'} space={2} alignItems="center" flexWrap="wrap">
                <Icon icon="mail" color={colors.gray[400]} size={16} />
                <Text color="singletons.darkText">{user?.email}</Text>
              </HStack>
            </VStack>
            <VStack space={2} flex={{ base: 'auto', md: 0.5 }}>
              <Text fontWeight="medium" color="gray.600">
                Password
              </Text>
              <HStack
                w="full"
                alignItems="center"
                flexWrap="wrap"
                justifyContent="space-between"
              >
                <HStack space={2} alignItems="center">
                  <Icon icon="lock" color={colors.gray[400]} size={16} />
                  <Text color="singletons.darkText">******</Text>
                </HStack>

                <Button
                  minW="160px"
                  w="160px"
                  variant="outline"
                  onPress={handleGoToChangePassword}
                >
                  <Text fontSize="sm" fontWeight="medium">
                    Change password
                  </Text>
                </Button>
              </HStack>
            </VStack>
          </Stack>
        </Stack>

        <Stack
          flex={1}
          direction={{ base: 'column', md: 'row' }}
          space={{ base: 0, md: 16 }}
        >
          <Stack
            direction="column"
            flex={{ base: 'auto', md: 0.5 }}
            space={6}
            mb={{ base: 10, md: 0 }}
          >
            <Stack direction="column" space={4}>
              <Text fontSize="lg" fontWeight="medium">
                Sign out
              </Text>
              <Divider />
            </Stack>
            <Button
              minW="220px"
              w="220px"
              variant="outline"
              leftIcon={<Icon icon="log-out" size={16} />}
              onPress={handleOpenSignOutModal}
            >
              <Text fontSize="sm" fontWeight="medium">
                Sign out your account
              </Text>
            </Button>
          </Stack>
          <Stack direction="column" flex={{ base: 'auto', md: 0.5 }} space={6}>
            <Stack direction="column" space={4}>
              <Text fontSize="lg" fontWeight="medium">
                Delete account
              </Text>
              <Divider />
            </Stack>
            <VStack space={8}>
              <HStack p={3} w="full" bg="warning.100" space={2} flexShrink={0}>
                <VStack w={{ base: '8%', md: '6%' }}>
                  <Icon
                    icon="alert-triangle"
                    color={colors.warning[700]}
                    size={24}
                  />
                </VStack>
                <VStack space={1} flex={{ base: '92%', md: '94%' }}>
                  <Stack>
                    <Text fontSize="md" fontWeight="medium" color="gray.800">
                      Important notice!
                    </Text>
                  </Stack>
                  <Stack>
                    <Text fontSize="sm" color="gray.800">
                      Deleting your account will result in losing access to all
                      registered activities, bookings and loggings.{' '}
                    </Text>
                  </Stack>
                </VStack>
              </HStack>
              <Button
                minW="160px"
                w="160px"
                variant="outline"
                color="error.600"
                borderColor="error.400"
                leftIcon={<Icon icon="trash" size={16} />}
                onPress={handleDeleteAccount}
                py={2}
              >
                <Text fontSize="sm" fontWeight="medium" color="error.600">
                  Delete account
                </Text>
              </Button>
            </VStack>
          </Stack>
        </Stack>
        <Stack
          w="full"
          alignItems="center"
          direction={{ base: 'column', md: 'row' }}
          py={{ base: 4, md: 6 }}
          px={{ base: 4, md: 40 }}
          mb={{ base: 16, md: 0 }}
          justifyContent={{ base: 'space-between', md: 'space-around' }}
          bg="white"
        >
          <Link color="darkText" onPress={handleOpenTermsModal}>
            Terms of service
          </Link>
          <Link color="darkText" onPress={handleOpenPrivacyModal}>
            Privacy policy.
          </Link>
        </Stack>
      </Stack>

      <Modal
        headerText="Sign out"
        ref={modalRef}
        modalContentMaxW="700"
        leftBtnAction={handleCloseSignOutModal}
        leftBtnText="No, go back"
        rightBtnAction={handleSignOut}
        rightBtnText="Yes, sign out"
        rightBtnBgColor={colors.error[600]}
      >
        <Text color={'gray.500'} fontSize="sm">
          {`Do you want to sign out your account?${'\n'}${'\n'}You're always welcome back to check in whenever you'd like.`}
        </Text>
      </Modal>
      <ModalPrivacyPolicy modalRef={privacyModalRef} />
      <ModalTermsOfService modalRef={termsModalRef} />
    </Stack>
  );
};
