import { ActivityCard } from 'ui/components/v2/Cards/ActivityCard';
import { ActivityDefinition } from 'ui/types/interfaces/activity-definition';

import { useSearchRoutes } from '~/components/Layouts/v2/SearchLayout/hooks/use-search-routes';

import { ActivityCarouselItemProps } from './types';

export const ActivityCarouselItem = ({
  activityDefinition,
}: ActivityCarouselItemProps) => {
  const { goToActivityDetails } = useSearchRoutes();

  const handleCardPress = () => {
    if (!activityDefinition?._id) return;

    const id = activityDefinition._id;
    const selectedActivityId = activityDefinition.selectedActivity?._id;
    goToActivityDetails(id, selectedActivityId);
  };

  if (!activityDefinition) return null;
  const { selectedActivity } = activityDefinition;

  return (
    <ActivityCard.Factory
      activityDefinition={activityDefinition as unknown as ActivityDefinition}
      selectedActivitySummary={selectedActivity}
      onPress={handleCardPress}
      ongoingNumberOfLines={8}
    />
  );
};
