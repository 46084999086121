import { HStack, Text } from 'native-base';
import Button from 'ui/components/Button';
import Icon from 'ui/components/Icon';
import { colors } from 'ui/theme/colors';

export type TeamSelectorNavButtonProps = {
  icon?: string;
  label?: string;
  isDisabled?: boolean;
  onPress: VoidFunction;
};

export const TeamSelectorNavButton = (props: TeamSelectorNavButtonProps) => {
  const {
    onPress,
    isDisabled,
    icon = 'plus-circle',
    label = 'New Team',
  } = props;

  return (
    <HStack justifyContent={'flex-end'} marginBottom={0} marginTop={'auto'}>
      <Button
        variant="ghost"
        isDisabled={isDisabled}
        bg={colors.gray[200]}
        _hover={{ bg: colors.gray[100] }}
        onPress={onPress}
        minW={32}
      >
        <HStack alignItems={'center'} space={2} width={'fit-content'}>
          <Icon icon={icon} color={colors.black} size={16} />
          <Text
            fontWeight={'500'}
            numberOfLines={1}
            color={colors.black}
            fontSize={'sm'}
          >
            {label || ''}
          </Text>
        </HStack>
      </Button>
    </HStack>
  );
};

export default TeamSelectorNavButton;
