import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { useAppDispatch } from '~/hooks/useAppDispatch';
import { useAppSelector } from '~/hooks/useAppSelector';
import { useRouter } from '~/hooks/useRouter';
import {
  schema,
  stepsSchema,
} from '~/pages/Authenticated/LogActivity/LogAction/schema';
import {
  LogActionProps,
  LogFormFields,
} from '~/pages/Authenticated/LogActivity/LogAction/types';
import { ActivityApplicationsService } from '~/services/resources/activity-applications';
import { measurementsDataSliceActions } from '~/store/slices/measurementsData';
import { getMeasurementsMap } from '~/utils/getMeasurementsMap';

export const useLogActionController = ({
  activityApplication,
  measurements,
  measurementUnit,
}: LogActionProps) => {
  const { goBack, goToRoute } = useRouter();

  const { selectedUserProfile } = useAppSelector(({ auth }) => auth);
  const dispatch = useAppDispatch();

  const isSteps = !!measurementUnit?.steps?.length;
  const measurementUnitName = measurementUnit?.pluralLabel ?? 'Hours';

  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors, isValid, isDirty, isSubmitting },
  } = useForm<LogFormFields>({
    resolver: yupResolver(isSteps ? stepsSchema : schema),
    mode: 'onChange',
    defaultValues: {
      date: new Date(),
      steps: [],
    },
  });

  const { fields } = useFieldArray({ control, name: 'steps' });
  const watchDate = watch('date');

  const submitLogSteps = async (values: LogFormFields) => {
    const payload = {
      steps: values.steps
        .filter((step) => step.isChecked)
        .map((_step, index) => ({
          step: index,
          completedAt: new Date(),
        })),
    };

    if (measurements?.length) {
      return ActivityApplicationsService.updateMeasurementSteps(
        activityApplication._id,
        payload,
      );
    }

    return ActivityApplicationsService.createMeasurementSteps(
      activityApplication._id,
      payload,
    );
  };

  const submitLogAmount = async (values: LogFormFields) => {
    if (!values.value || !activityApplication) return;
    const payload = {
      amount: +values.value,
      measurementDate: values.date,
    };

    return ActivityApplicationsService.createMeasurementAmount(
      activityApplication._id,
      payload,
    );
  };

  const onSubmit = handleSubmit(async (values: LogFormFields) => {
    try {
      if (isSteps) {
        await submitLogSteps(values);
      } else {
        await submitLogAmount(values);
      }
      if (selectedUserProfile?._id) {
        const { mapApplicationsData } = await getMeasurementsMap(
          selectedUserProfile._id,
        );
        dispatch(
          measurementsDataSliceActions.setMeasurementsData({
            ...mapApplicationsData,
          }),
        );
      }

      goToRoute(`/my-wallet/log-activity/${activityApplication._id}/success`);
    } catch (err) {
      toast.error('An error occurred when trying to log activity.');
      console.error('An error occurred when trying to log activity.', err);
    }
  });

  useEffect(() => {
    // Set default values. Get steps saved and restore ui state.
    const steps = measurementUnit?.steps?.map((step, index) => {
      const isChecked =
        measurements &&
        measurements[0]?.steps?.some(
          (measurementStep) => measurementStep.step === index,
        );

      return {
        title: step.title,
        description: step.description,
        isChecked,
        hasLogged: isChecked,
      };
    });

    reset({
      date: new Date(),
      steps: steps ?? [],
    });
  }, [measurementUnit, measurements, reset]);

  return {
    fields,
    isValid,
    isSubmitting,
    watchDate,
    control,
    measurementUnitName,
    isDirty,
    isSteps,
    errors,
    goBack,
    onSubmit,
  };
};
