import { HStack, Text, VStack } from 'native-base';
import { useMemo } from 'react';
import Icon from 'ui/components/Icon';
import { colors } from 'ui/theme/colors';
import { MeasurementUnit } from 'ui/types/measurementUnit';
import { formatMeasurementAmount } from 'ui/utils/measurements/format';

export type ActivityTargetSnippetProps = {
  targetAmount: number;
  targetAmountMeasurementUnit?: MeasurementUnit;
};
export const ActivityTargetSnippet = ({
  targetAmount,
  targetAmountMeasurementUnit,
}: ActivityTargetSnippetProps) => {
  const formattedTargetAmount = useMemo(() => {
    if (!targetAmount) return '';
    return formatMeasurementAmount(
      targetAmount,
      targetAmountMeasurementUnit,
      true,
    );
  }, [targetAmount, targetAmountMeasurementUnit]);

  return (
    <HStack space={2} alignItems={'center'}>
      <Icon
        color={colors.gray[400]}
        icon="target_and_arrow"
        enableAutoCustom
        size={'20px'}
      />

      <VStack space={1}>
        <Text
          fontSize={'sm'}
          color={'gray.600'}
          fontWeight={500}
          numberOfLines={1}
        >
          {formattedTargetAmount}
        </Text>
      </VStack>
    </HStack>
  );
};
