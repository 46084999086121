import { View, VStack } from 'native-base';

import {
  CenterContainer,
  CenterContainerSize,
} from '~/components/Layouts/Scaffolds/CenterContainer';
import { ActivityCarousel } from '~/components/v2/ActivityCarousel';

import { useFeedFooterCarouselsSuggestion } from './controller';

export const FeedFooterCarouselsSuggestion = () => {
  const { isFilterSet, availableCarouselSuggestions } =
    useFeedFooterCarouselsSuggestion();

  if (!availableCarouselSuggestions?.length) return null;

  return (
    <View>
      {availableCarouselSuggestions.map((props, index) => {
        return (
          <VStack mt={6} key={index} space={8} bgColor={'gray.100'} py={8}>
            <CenterContainer size={CenterContainerSize.xl}>
              <ActivityCarousel isEnabled={!!isFilterSet} {...(props as any)} />
            </CenterContainer>
          </VStack>
        );
      })}
    </View>
  );
};
