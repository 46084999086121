import { Box, Hidden, HStack, Text, VStack } from 'native-base';
import { GenericCarousel } from 'ui/components/v2/Carousel/GenericCarousel';

import { PrimaryCarouselNavButton } from './components/NavButton';
import {
  PrimaryCarouselSubTitle,
  PrimaryCarouselTitle,
} from './components/Title';
import { ViewAllButton } from './components/ViewAllButton';
import { usePrimaryCarouselController } from './controller';
import { PrimaryCarouselProps } from './types';

export const PrimaryCarousel = (props: PrimaryCarouselProps) => {
  const {
    slides,
    headerTags,
    isLoading,
    title,
    subTitle,
    onTitlePress,
    onViewAllPress,
  } = props;
  const {
    space,
    currentIndex,
    isNextDisabled,
    isPrevDisabled,
    columnsInViewPort,
    handleNext,
    handlePrev,
  } = usePrimaryCarouselController(props);

  const renderHeaderTags = () => {
    if (!headerTags?.length) return null;

    return (
      <HStack px={4} space={2}>
        {headerTags.map((tag, index) => {
          return (
            <Box
              p={1}
              px={2}
              key={index}
              borderRadius={12}
              background={'amber.100'}
            >
              <Text fontSize={'xs'} fontWeight={500}>
                + {tag.label}
              </Text>
            </Box>
          );
        })}
      </HStack>
    );
  };

  return (
    <VStack w="full">
      {/* Header */}

      <HStack w="full" justifyContent={'space-between'} mb={{ base: 3, sm: 4 }}>
        <VStack w="full" space={{ base: 1, md: 2 }}>
          <PrimaryCarouselTitle
            isLoading={isLoading}
            text={title}
            onPress={onTitlePress}
          >
            {renderHeaderTags()}
          </PrimaryCarouselTitle>

          <PrimaryCarouselSubTitle
            isLoading={isLoading}
            text={subTitle.large}
            smallText={subTitle.small || subTitle.large || ''}
          />
        </VStack>

        <Hidden till="sm">
          <HStack
            minW={'fit-content'}
            space={{ base: 2, lg: 4 }}
            alignItems={'flex-end'}
            ml={6}
          >
            <PrimaryCarouselNavButton
              iconName="chevron-left"
              onPress={handlePrev}
              isDisabled={isPrevDisabled}
            />
            <PrimaryCarouselNavButton
              iconName="chevron-right"
              onPress={handleNext}
              isDisabled={isNextDisabled}
            />
          </HStack>
        </Hidden>

        {!isLoading && (
          <Hidden from="sm">
            <ViewAllButton onPress={onViewAllPress} />
          </Hidden>
        )}
      </HStack>

      {/* Body */}
      <VStack>
        <GenericCarousel
          slides={slides}
          currentIndex={currentIndex}
          columns={columnsInViewPort}
          space={space}
        />
      </VStack>
    </VStack>
  );
};
