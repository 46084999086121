import { useState } from 'react';
import { mask } from 'remask';

export const useDateController = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleChangeOpenCalendar = (isOpen: boolean) => {
    setIsOpen(isOpen);
  };

  function maskDate(e: any) {
    const value = e.target.value;
    const maskedValue = mask(value, '99/99/9999');
    e.target.value = maskedValue;
  }

  return { isOpen, maskDate, handleChangeOpenCalendar };
};
