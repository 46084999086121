import { api } from '~/services/api';

export class InsightsService {
  static BASE_URL = '/volunteer/insights';

  static createUserProfileEngagement(userProfileId: string) {
    return api.post(
      `${this.BASE_URL}/user-profile/${userProfileId}/engagement`,
    );
  }

  static async getUserProfileTotalTimeLogged(
    userProfileId: string,
  ): Promise<number> {
    try {
      const { data } = await api.get(
        `${this.BASE_URL}/user-profile/${userProfileId}/total-time-logged`,
      );

      return data?.total || 0;
    } catch (error) {
      return 0;
    }
  }
}
