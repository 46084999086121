import { FlatList, Flex, Hidden, Stack, Text } from 'native-base';
import { MutableRefObject } from 'react';
import Button from 'ui/components/Button';
import { IModalRefProps } from 'ui/components/Modals/Modal/types';

import { ModalDiscardChanges } from '~/components/ModalDiscardChanges';
import ModalRemove from '~/components/ModalRemove';
import { Tab } from '~/components/Tabs';
import { EmailPreferencesInput } from '~/pages/Authenticated/Settings/EmailPreferences/components/EmailPreferencesInput';
import { preferencesList } from '~/pages/Authenticated/Settings/EmailPreferences/constants';
import { useEmailPreferencesController } from '~/pages/Authenticated/Settings/EmailPreferences/useEmailPreferencesController';

export const EmailPreferences = () => {
  const {
    control,
    isLoading,
    isLoadingReset,
    onSubmitHandler,
    onResetPreferences,
    quantityOfColumns,
    handleCloseSaveOrDiscardModal,
    saveOrDiscardModalRef,
    handleDiscardChanges,
    resetPreferencesCofirmModalRef,
    handleOpenResetPreferencesModal,
    handleCloseResetPreferencesModal,
  } = useEmailPreferencesController();
  return (
    <>
      <Flex
        flex={1}
        h={{ base: 'auto', md: 'full' }}
        w={{ base: 'auto', md: 'full' }}
        bg="white"
      >
        <Hidden till="md">
          <Stack flexShrink={0}>
            <Tab
              key={'UserApps'}
              label="Email Preferences"
              description="View and manage your email preferences"
              icon="emaile"
              isActive
            />
          </Stack>
        </Hidden>
        <Stack flex={1} mt={{ base: 0, md: 2 }} mb={{ base: 28, md: 0 }}>
          <FlatList
            key={quantityOfColumns}
            p={6}
            keyExtractor={(item) => item.value}
            data={preferencesList}
            numColumns={quantityOfColumns}
            columnWrapperStyle={
              (quantityOfColumns || 0) > 1 ? { gap: 56 } : undefined
            }
            renderItem={({ item }) => (
              <EmailPreferencesInput control={control} preference={item} />
            )}
          />
        </Stack>
        <Stack
          position={{ base: 'fixed', lg: 'relative' }}
          bottom={{ base: 16, sm: 0 }}
          w="full"
          alignSelf="center"
          direction={{ base: 'column-reverse', md: 'row' }}
          p={{ base: 4, md: 6 }}
          space={4}
          justifyContent={{ base: 'space-between', md: 'center' }}
          bg="white"
        >
          <Button
            minW={{ base: 'full', md: '200px' }}
            w={{ base: 'full', md: '200px' }}
            minH="44px"
            variant="ghost"
            isLoading={isLoadingReset}
            onPress={handleOpenResetPreferencesModal}
          >
            <Text
              color="singletons.darkText"
              fontSize={{ base: 'md', md: 'sm' }}
              fontWeight="medium"
            >
              Reset preferences
            </Text>
          </Button>
          <Button
            isLoading={isLoading}
            onPress={onSubmitHandler}
            minW={{ base: 'full', md: '200px' }}
            w={{ base: 'full', md: '200px' }}
            minH="44px"
          >
            <Text
              color="white"
              fontSize={{ base: 'md', md: 'sm' }}
              fontWeight="medium"
            >
              Save changes
            </Text>
          </Button>
        </Stack>
      </Flex>
      <ModalDiscardChanges
        modalRef={saveOrDiscardModalRef}
        handleOnSave={onSubmitHandler}
        handleCloseModal={handleCloseSaveOrDiscardModal}
        handleDiscardChanges={handleDiscardChanges}
        isLoading={isLoading}
      />
      <ModalRemove
        modalRef={resetPreferencesCofirmModalRef}
        headerText="Reset preferences"
        handleCloseModal={handleCloseResetPreferencesModal}
        handleDelete={onResetPreferences}
        rightBtnLoading={isLoadingReset}
        rightBtnText="Reset"
        customRightButtonProps={{ bg: 'singletons.darkText' }}
      >
        <Stack space={5}>
          <Text fontWeight={400} fontSize="md" color="gray.500">
            Do you want to reset your email preferences?
          </Text>
        </Stack>
      </ModalRemove>
    </>
  );
};
