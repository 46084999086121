import { api, publicApi } from '~/services/api';

export class AppsService {
  static URL = 'app';
  static PUBLIC_URL = 'app-public';

  static async findAppsByIds(appIds: string[]) {
    return await api.get(this.URL, {
      params: {
        filter: JSON.stringify({
          _id: { $in: appIds },
        }),
      },
    });
  }
  static async findById(appId: string) {
    return await api.get(`${this.URL}/${appId}`);
  }
  static async publicFindAppsByIds(appIds: string[]) {
    return await publicApi.get(this.PUBLIC_URL, {
      params: {
        filter: JSON.stringify({
          _id: { $in: appIds },
        }),
      },
    });
  }
  static async publicFindById(appId: string) {
    return await publicApi.get(`${this.PUBLIC_URL}/${appId}`);
  }
}
