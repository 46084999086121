import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ESliceNames } from '~/store/types';

import { IForgotPasswordState } from './types';

const initialState: IForgotPasswordState = {
  accessCode: undefined,
  currentStep: 0,
  email: undefined,
  password: undefined,
};

export const forgotPasswordSlice = createSlice({
  name: ESliceNames.FORGOT_PASSWORD,
  initialState,
  reducers: {
    nextStep: (state) => {
      return { ...state, currentStep: state.currentStep + 1 };
    },
    backStep: (state) => {
      if (state.currentStep) {
        return { ...state, currentStep: state.currentStep - 1 };
      }
    },
    update: (
      state,
      { payload }: PayloadAction<Partial<IForgotPasswordState>>,
    ) => {
      return {
        ...state,
        ...payload,
      };
    },
    clear: () => initialState,
  },
});

export const {
  reducer: forgotPasswordSliceReducer,
  actions: forgotPasswordSliceActions,
} = forgotPasswordSlice;
