import { Divider, HStack, Menu, Text, View, VStack } from 'native-base';
import React from 'react';
import Icon from 'ui/components/Icon';
import { colors } from 'ui/theme/colors';

import { AccountDropDownHeader } from './Header';
import { AccountDropDownMenuProps, AccountMenuOption } from './types';

export const AccountDropDownMenu = (props: AccountDropDownMenuProps) => {
  const {
    onPress,
    onSwitchPersonaPress,
    showSwitchPersonaButton,
    optionGroups,
    accountData,
  } = props;

  return (
    <View
      borderWidth={1}
      borderRadius={12}
      borderColor={'gray.200'}
      w={{ base: 0, md: 400 }}
      overflow={'hidden'}
    >
      <VStack p={0} pb={2} backgroundColor={colors.singletons.white}>
        <AccountDropDownHeader
          accountData={accountData}
          onSwitchPersonaPress={onSwitchPersonaPress}
          showSwitchPersonaButton={showSwitchPersonaButton}
        />

        <VStack mt={2}>
          {optionGroups?.map((options: AccountMenuOption[], index: number) => {
            return (
              <React.Fragment key={index}>
                {index > 0 && <Divider my={2} />}
                {options?.map((option: AccountMenuOption) => (
                  <Menu.Item
                    p={0}
                    key={option.key}
                    onPress={() => onPress(option)}
                  >
                    <HStack space={3} py={3} minW={340}>
                      <Icon
                        size={'24px'}
                        icon={option.icon}
                        color={option.iconColor ?? colors.gray[600]}
                        enableAutoCustom
                      />
                      <Text fontSize={'md'} color={colors.gray[600]}>
                        {option.label}
                      </Text>
                    </HStack>
                  </Menu.Item>
                ))}
              </React.Fragment>
            );
          })}
        </VStack>
      </VStack>
    </View>
  );
};
