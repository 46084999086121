import {
  Checkbox,
  Hidden,
  HStack,
  Link,
  Progress,
  Stack,
  Text,
} from 'native-base';
import Button from 'ui/components/Button';
import { transformURL } from 'ui/helpers/transformURL';

import { colors } from '~/theme/colors';

import { AcceptTermsSectionProps } from './types';

export const AcceptTermsSection = ({
  isLoadingSubmit,
  isTermsAndConditionsAccepted,
  invitationInfo,
  handleAcceptInvitation,
  handleDeclineInvitation,
  toggleIsTermsAndConditionsAccepted,
}: AcceptTermsSectionProps) => {
  return (
    <>
      <Hidden from="lg">
        <HStack space={2} justifyContent="center">
          <Progress width="10px" />
          <Progress
            width="30px"
            bgColor={
              invitationInfo?.composeSettings?.brandColor || colors.gray[900]
            }
          />
        </HStack>
      </Hidden>
      <Stack alignItems={{ base: 'center', lg: 'initial' }}>
        <Stack mt={{ base: 4, lg: 0 }}>
          <Text fontSize={{ base: 'lg', lg: '2xl' }} fontWeight={500}>
            You have been invited to join!
          </Text>
        </Stack>

        <Text mt={{ base: 2, lg: 4 }} fontSize={{ base: 'xs', lg: 'lg' }}>
          Will you accept the invitation?
        </Text>

        <Stack width="100%">
          {invitationInfo?.sharerOrganization?.termsOfServicesLink ? (
            <HStack alignItems="center" space="3" mt="10">
              <Checkbox
                _checked={{ bgColor: 'gray.900', borderColor: 'gray.900' }}
                value="true"
                isChecked={isTermsAndConditionsAccepted}
                onChange={toggleIsTermsAndConditionsAccepted}
              >
                <Text
                  fontSize={{ base: 'xs', lg: 'sm' }}
                  maxW="400px"
                  width="100%"
                >
                  By accepting this invitation, you are accepting the{' '}
                  <Text underline>
                    <Link
                      isExternal
                      color="gray.800"
                      href={transformURL(
                        invitationInfo.sharerOrganization.termsOfServicesLink,
                      )}
                    >
                      terms and conditions.
                    </Link>
                  </Text>
                </Text>
              </Checkbox>
            </HStack>
          ) : null}
          <Stack
            mt={{ base: 20, lg: 8 }}
            space={2}
            maxW={{ base: '100%', lg: '500px' }}
          >
            <Button
              onPress={handleAcceptInvitation}
              padding={{ base: 5, lg: 6 }}
              bgColor="gray.900"
              _text={{ fontWeight: 500 }}
              isDisabled={!isTermsAndConditionsAccepted}
              isLoading={isLoadingSubmit}
            >
              Accept invitation
            </Button>
            <Button
              onPress={handleDeclineInvitation}
              padding={{ base: 5, lg: 6 }}
              variant="ghost"
              colorScheme="error"
              _text={{ color: 'error.500', fontWeight: 500 }}
            >
              Decline
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};
