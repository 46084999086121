import { useMemo } from 'react';
import { CauseOptions } from 'ui/types/interfaces/activity-category';

import { ActivitySearchCategory } from '~/types/interfaces/activity-search/types';
import { useInApp } from '~/zustand/store/InApp';
import { useSearch } from '~/zustand/store/Search';

import { getInAppSearchCategoryMap } from './utils';

export const useCategoriesSliderController = () => {
  // Providers
  const { causes } = useSearch((state) => state);
  const { selectedCategory, app } = useInApp((state) => state);

  const setSelectedCategory = useInApp((state) => state.setSelectedCategory);

  // Hooks
  const searchCategoriesMap = useMemo(() => {
    if (causes.length === 0) return null;
    return getInAppSearchCategoryMap(causes as CauseOptions[]);
  }, [causes]);

  // Handlers
  const handleCategoryChange = (category: ActivitySearchCategory | null) => {
    setSelectedCategory(category);
  };

  const brandColor = app?.brandColor || '';

  return {
    brandColor,
    selectedCategory,
    searchCategoriesMap,
    handleCategoryChange,
  };
};
