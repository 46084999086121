import { useMediaQuery } from 'native-base';
import { useMemo } from 'react';
import { ActivitySemanticType, ActivityType } from 'ui/types/activities';
import { mapToActivitySemanticType } from 'ui/utils/activity/mappers';

import { BreadCrumbsNode } from '~/components/Breadcrumbs/types';
import { PAGES } from '~/constants/pages.constants';
import { slicedCurrentPath } from '~/pages/Authenticated/ViewActivityDetails/components/ActivityBreadCrumb/helper/sliceCurrentPath';
import { IActivityBreadCrumbControllerProps } from '~/pages/Authenticated/ViewActivityDetails/components/ActivityBreadCrumb/types';
import { SearchCollectionSlug } from '~/types/interfaces/activity-search/types';

const breadCrumbsConfigDictionary: Record<
  ActivitySemanticType,
  BreadCrumbsNode
> = {
  [ActivitySemanticType.Event]: {
    icon: 'calendar',
    label: 'Events',
    extra: { collection: SearchCollectionSlug.Events },
  },
  [ActivitySemanticType.TeamEvent]: {
    icon: 'users',
    label: 'Team Events',
    extra: { collection: SearchCollectionSlug.TeamEvents },
  },
  [ActivitySemanticType.Action]: {
    icon: 'hand-pointer',
    isCustom: true,
    label: 'Actions',
    extra: { collection: SearchCollectionSlug.Actions },
  },
  [ActivitySemanticType.Ongoing]: {
    icon: 'repeat',
    label: 'Ongoing',
    extra: { collection: SearchCollectionSlug.Ongoing },
  },
};

export const useActivityBreadCrumpController = ({
  activityDefinition,
  appId,
}: IActivityBreadCrumbControllerProps) => {
  const [isMobile] = useMediaQuery({ maxWidth: 1240 });

  const breadcrumbs = useMemo(() => {
    const { eventApplicationType, type, title } = activityDefinition;

    const rootNodeLabel = slicedCurrentPath({ currentPath: title, isMobile });

    const semanticType = mapToActivitySemanticType(
      type as ActivityType,
      eventApplicationType,
    );
    const breadcrumbsConfig = breadCrumbsConfigDictionary[semanticType];

    const nodes: BreadCrumbsNode[] = [
      {
        path: '#',
        label: rootNodeLabel,
      },
    ];

    if (appId) {
      const { label, icon, isCustom, extra } = breadcrumbsConfig;
      nodes.unshift({
        icon,
        label,
        isCustom,
        path: PAGES.InAppSearchCollection.replace(':appId', appId).replace(
          ':collection',
          extra?.collection || '',
        ),
      });
    }
    return nodes;
  }, [activityDefinition, appId, isMobile]);

  return {
    breadcrumbs,
  };
};
