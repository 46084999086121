import { Avatar, Flex, Skeleton, Stack, Text } from 'native-base';
import { useState } from 'react';
import { BrandedBackground } from 'ui/components/BrandedBackground';
import { getNameInitials } from 'ui/utils/getNameInititals';

import { colors } from '../../theme/colors';
import { SignInBrandingProps } from './types';

export const SignInBranding = ({
  brandColor = colors.gray[900],
  logo,
  ecosystemName,
  backgroundImage,
  organizationName = '',
  height = '100vh',
  isBigHelpOutBranded = false,
}: SignInBrandingProps) => {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <Stack
      width="100%"
      backgroundColor={isLoading ? 'none' : brandColor}
      minH="100%"
    >
      <Stack display={isLoading ? 'flex' : 'none'} width="100%" height="100%">
        <Skeleton width="100%" height="100%" />
      </Stack>
      <BrandedBackground
        isLoading={isLoading}
        onLoaded={() => setIsLoading(false)}
        height={height}
        backgroundImage={backgroundImage}
        isBigHelpOutBranded={isBigHelpOutBranded}
      />
      <Flex
        flexDirection={{ base: 'row', lg: 'column' }}
        alignItems={{ base: 'center', lg: 'initial' }}
        padding={{ base: 6, lg: 12 }}
        position="absolute"
        top={0}
        style={{ gap: 24 }}
      >
        <Avatar
          source={{ uri: logo }}
          width={{ base: 54, lg: 125 }}
          height={{ base: 54, lg: 125 }}
        >
          {getNameInitials(organizationName)}
        </Avatar>
        <Stack space={2}>
          <Text
            color="white"
            fontWeight={500}
            fontSize={{ base: 'lg', lg: '3xl' }}
          >
            Welcome to
          </Text>
          <Text
            color="white"
            fontWeight={700}
            fontSize={{ base: 'xl', lg: '5xl' }}
          >
            {ecosystemName}
          </Text>
          <Text
            color="white"
            fontWeight={500}
            fontSize={{ base: 'sm', lg: '3xl' }}
          >
            by {organizationName}
          </Text>
        </Stack>
      </Flex>
    </Stack>
  );
};
