import { Box, HStack, Popover, Text, VStack } from 'native-base';
import { colors } from 'ui/theme/colors';

import { ActivityAttributeLabel } from '~/pages/Authenticated/v2/ActivityView/components/ActivityCardElements/ActivityAttributeLabel/indext';
import ActivitySelectorNavButton from '~/pages/Authenticated/v2/ActivityView/components/ActivitySelectors/ActivitySelectorCardBase/ActivitySelectorNavButton';
import { useActivitySelectorCardBaseController } from '~/pages/Authenticated/v2/ActivityView/components/ActivitySelectors/ActivitySelectorCardBase/controller';
import { ActivitySelectorCardBaseProps } from '~/pages/Authenticated/v2/ActivityView/components/ActivitySelectors/ActivitySelectorCardBase/types';

export const ActivitySelectorCardBase: React.FC<
  ActivitySelectorCardBaseProps
> = (props) => {
  const {
    cardName,
    description,
    descriptionIcon,
    tagLabel,
    title,
    titleIcon,
    titlePlaceHolder,
    trailingContent,
    popoverContent,
    closePopover,
    showPopover,
    isDisabled = false,
    hideSelector = false,
    customNavButton,
    labels,
  } = props;

  const { handleOpen, popoverViewport, triggerRef } =
    useActivitySelectorCardBaseController(props);

  return (
    <Popover
      isOpen={showPopover}
      onClose={closePopover}
      trigger={(triggerOptions) => (
        <VStack
          {...triggerOptions}
          px={3}
          py={4}
          minH={'108px'}
          justifyContent={'center'}
          borderColor={colors.gray[200]}
          borderBottomWidth={1}
          ref={triggerRef}
        >
          <HStack space={3}>
            <VStack
              w={'4rem'}
              minW={'4rem'}
              maxW={'4rem'}
              overflow={'hidden'}
              alignItems={'center'}
              justifyContent={'center'}
            >
              {trailingContent}
            </VStack>

            <VStack w="full" space={1} justifyContent={'space-between'}>
              <ActivityAttributeLabel
                icon={titleIcon}
                iconColor={colors.gray[400]}
                text={title || titlePlaceHolder || ''}
                fontColor={!title ? colors.gray[400] : colors.gray[600]}
                hightLight
              />

              {description && (
                <ActivityAttributeLabel
                  icon={descriptionIcon}
                  iconColor={colors.gray[400]}
                  text={description}
                  fontColor={colors.gray[600]}
                />
              )}

              {!!labels?.length &&
                labels.map((label) => (
                  <ActivityAttributeLabel
                    key={label.key}
                    text={label.text}
                    icon={label.icon}
                    iconColor={colors.gray[400]}
                    fontColor={colors.gray[600]}
                  />
                ))}

              {tagLabel && (
                <Box
                  mt={1}
                  borderColor={colors.gray[200]}
                  borderRadius={40}
                  w="fit-content"
                  py={'2px'}
                  px={2}
                  borderWidth={1}
                >
                  <Text numberOfLines={2} fontSize={'xs'} fontWeight={500}>
                    {tagLabel}
                  </Text>
                </Box>
              )}
            </VStack>

            {!hideSelector && (
              <>
                {customNavButton || (
                  <ActivitySelectorNavButton
                    onPress={handleOpen}
                    isDisabled={isDisabled}
                    label={`Change ${cardName}`}
                  />
                )}
              </>
            )}
          </HStack>
        </VStack>
      )}
    >
      <VStack
        top={0}
        w={popoverViewport.width}
        left={`${popoverViewport.left}px`}
        borderBottomRadius={12}
        overflow={'hidden'}
        borderWidth={1}
        borderTopWidth={0}
        borderColor={colors.gray[300]}
        style={{
          transform: `translateY(${popoverViewport.top}px)`,
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        }}
      >
        <Popover.Body py={3} px={2} backgroundColor={colors.white}>
          {popoverContent}
        </Popover.Body>
      </VStack>
    </Popover>
  );
};

export default ActivitySelectorCardBase;
