import { useSearchRoutes } from '~/components/Layouts/v2/SearchLayout/hooks/use-search-routes';
import { useAppSelector } from '~/hooks/useAppSelector';

export const useSearchLayoutNavBarController = ({}: {}) => {
  const { goToHomePage } = useSearchRoutes();

  const { user, selectedUserProfile } = useAppSelector((state) => state.auth);

  const { selectedEcosystem } = useAppSelector(({ ecosystem }) => ecosystem);

  const handleGoHome = () => {
    goToHomePage();
  };

  const ecosystemLogoUrl = selectedEcosystem?.logo || '';
  const ecosystemName = selectedEcosystem?.name || '';

  return {
    user,
    ecosystemName,
    ecosystemLogoUrl,
    selectedEcosystem,
    selectedUserProfile,
    handleGoHome,
  };
};
