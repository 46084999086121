import { useMemo } from 'react';
import { ISelectOptionsItem } from 'ui/components/v2/inputs/Select/types';
import { MeasurementUnit } from 'ui/types/measurementUnit';

import { useAppSelector } from '~/hooks/useAppSelector';
import { useLoadMeasurementUnitsByOrganization } from '~/hooks/useLoadMeasurementUnit/useLoadMeasurementUnitsByOrganization';

export const useMeasurementUnitsController = () => {
  const { selectedUserProfile } = useAppSelector(({ auth }) => auth);

  const { data: measurementUnitsData } = useLoadMeasurementUnitsByOrganization({
    organizationId: String(
      typeof selectedUserProfile?.ecosystemSummary?.organization === 'string'
        ? selectedUserProfile?.ecosystemSummary?.organization
        : selectedUserProfile?.ecosystemSummary?.organization?._id,
    ),
  });

  // Raw measurement units
  const measurementUnits = useMemo(() => {
    return (measurementUnitsData?.data ?? []).filter(
      (v) => !v.pluralLabel.includes('Steps'),
    );
  }, [measurementUnitsData]);

  // Grouped Select options
  const measurementUnitGroupedOptions = useMemo(() => {
    const grouped = measurementUnits.reduce(
      (group: { [key: string]: MeasurementUnit[] }, measurementUnit) => {
        const { category } = measurementUnit;
        group[category] = group[category] ?? [];
        group[category].push(measurementUnit);
        return group;
      },
      {},
    );

    return Object.entries(grouped).map(([key, values]) => {
      const options: ISelectOptionsItem[] = values.map((value) => ({
        value: value._id,
        label: value.pluralLabel,
      }));
      return { label: key, options: options };
    });
  }, [measurementUnits]);

  const getMeasurementUnitById = (id: string): MeasurementUnit | null => {
    return measurementUnits.find((m) => m._id == id) ?? null;
  };

  const mapMeasurementUnitFromSelectOption = (
    options: ISelectOptionsItem[],
  ): any[] => {
    const ids = options.map((opt) => opt.value);
    return measurementUnits.filter((unit) => ids.includes(unit._id));
  };

  return {
    getMeasurementUnitById,
    mapMeasurementUnitFromSelectOption,
    measurementUnitGroupedOptions,
  };
};
