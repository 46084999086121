import { Divider, Flex, HStack, Stack, Text, VStack } from 'native-base';
import { FiCheckSquare, FiUserCheck } from 'react-icons/fi';
import { ILLUSTRATIONS_NAME } from 'ui/components/Illustration';
import Modal from 'ui/components/Modals/Modal';

import FlowTwoColumnsTemplate from '~/components/Templates/FlowTwoColumnsTemplate';
import { colors } from '~/theme/colors';

import { authorizationList } from './constants';
import { styles } from './styles';
import { useAuthorizationController } from './useAuthorizationController';

const AuthorizationPersonaData = () => {
  const {
    handleAllow,
    handleDeny,
    denyModalRef,
    openDenyModal,
    closeDenyModal,
  } = useAuthorizationController();
  return (
    <Flex minH="full" flexShrink={0} bgColor="gray.100">
      <FlowTwoColumnsTemplate
        ilustrationName={ILLUSTRATIONS_NAME.THUMBS_UP}
        title="Authorization for the use of personal data"
        subtitle="Please review the following carefully before proceeding."
        icon={<FiUserCheck size={32} />}
        primaryButtonText="Allow"
        secondaryButtonTextStyle={styles.denyTextButton}
        primaryButtonOnPress={handleAllow}
        secondaryButtonOnPress={openDenyModal}
        secondaryButtonText="Deny"
      >
        <Flex flex={1} flexShrink={0} flexGrow={1} w="full" px={4} py={8}>
          <Text fontSize="sm" color="singletone.darkText">
            In order to provide you with our services, we need to access and
            utilise certain personal information from your profile, which
            includes:
          </Text>
          <Flex flexShrink={0} my={4}>
            {authorizationList.map((item) => (
              <VStack my={1} space={1} key={item.title}>
                <HStack
                  space={4}
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Stack>
                    <FiCheckSquare
                      color={colors.singletons.darkText}
                      size={16}
                    />
                  </Stack>
                  <Text fontWeight={500} w="100%">
                    {item.title}{' '}
                    {item?.description ? (
                      <Text fontWeight={300}>{item.description}</Text>
                    ) : null}
                  </Text>
                </HStack>
                <Divider />
              </VStack>
            ))}
          </Flex>
          <Text fontSize="sm" color="singletone.darkText">
            Rest assured, the Do IT platform is committed to handling this
            information in accordance with our terms of service and privacy
            policy. Your privacy and data security are our top priorities.
          </Text>
        </Flex>
      </FlowTwoColumnsTemplate>
      <Modal
        ref={denyModalRef}
        headerText="Deny authorization"
        leftBtnAction={closeDenyModal}
        leftBtnText="No, go back"
        rightBtnAction={handleDeny}
        rightBtnText="Yes, deny"
        modalContentMaxW={{ base: '90vw', lg: '669' }}
        modalContentW={{ base: '90vw', lg: '669' }}
        _overlay={{ style: { position: 'fixed' } as any }}
      >
        <Stack space={5} py={5}>
          <Text fontWeight={400} fontSize="md" color="gray.500">
            Are you sure about this action?
          </Text>

          <Text fontWeight={400} fontSize="md" color="gray.500">
            If don&apos;t accept the terms, you will be redirected to the app
            home as a guest.
          </Text>
        </Stack>
      </Modal>
    </Flex>
  );
};

export default AuthorizationPersonaData;
