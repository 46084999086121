import { HStack, Text, VStack } from 'native-base';
import Icon from 'ui/components/Icon';
import { transformURL } from 'ui/helpers/transformURL';
import { colors } from 'ui/theme/colors';

interface DescriptionLabelProps {
  iconName: string;
  title: string;
  description: string;
  isLink?: boolean;
}

export const DescriptionLabel = ({
  iconName,
  title,
  description,
  isLink,
}: DescriptionLabelProps) => {
  return (
    <VStack space="3" pr={'3'}>
      <HStack alignItems="center" space="2">
        <Icon icon={iconName} size={22} color={colors.gray[400]} />
        <Text fontSize="md" color="gray.600">
          {title}
        </Text>
      </HStack>
      {isLink ? (
        <a href={transformURL(description)} target="_blank" rel="noreferrer">
          <Text underline>{description}</Text>
        </a>
      ) : (
        <Text color="singletons.darkText" fontSize="sm" variant="link">
          {description}
        </Text>
      )}
    </VStack>
  );
};
