import yup from 'ui/utils/validations/yup';

export const findOpportunitiesPageSearchSchema = yup.object().shape({
  search: yup.string().optional(),
  city_or_postcode: yup
    .object()
    .shape({
      lat: yup.number().required(),
      lng: yup.number().required(),
      placeID: yup.string(),
      rawLocation: yup.string().required(),
    })
    .optional(),
});
