import { useBreakpointValue } from 'native-base';
import { useState } from 'react';

import { SearchOption } from '../types';
import { SearchOverlayProps } from './types';

export const useSearchOverlayController = ({
  onSubmit,
}: SearchOverlayProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const isMobile = useBreakpointValue({ base: true, sm: false });

  const handleOpenOverlay = () => {
    if (!isOpen) {
      setIsOpen(true);
    }
  };

  const handleCloseOverlay = () => {
    setIsOpen(false);
  };

  const handleSubmit = (submitValue: string) => {
    onSubmit?.(submitValue);
    handleCloseOverlay();
  };

  const handleOptionSelect = (option: SearchOption) => {
    handleSubmit(option.value);
  };

  return {
    isOpen,
    isMobile,
    handleSubmit,
    handleOpenOverlay,
    handleCloseOverlay,
    handleOptionSelect,
  };
};
