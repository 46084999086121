import { Divider, Flex, Hidden, Text, VStack } from 'native-base';
import Icon from 'ui/components/Icon';

import { IOffPlatformFormHeaderProps } from './types';

export const OffPlatformFormHeader = (props: IOffPlatformFormHeaderProps) => {
  const { icon, subtitle, title } = props;

  return (
    <VStack>
      <Flex
        style={{ gap: 16 }}
        flexDirection={{ base: 'column', md: 'row' }}
        bgColor={{ base: 'none', md: 'white' }}
        alignItems={{ md: 'center' }}
        padding={{ base: 2, md: 4 }}
        px={4}
      >
        <Hidden till="md">
          <Icon icon={icon} size={32} />
        </Hidden>

        <VStack>
          <Text fontSize={{ base: 'lg', md: '2xl' }} fontWeight={500}>
            {title}
          </Text>

          <Text
            mt={{ base: 2, md: 0 }}
            lineHeight={'1.25em'}
            color="gray.500"
            fontSize="md"
            fontWeight={400}
          >
            {subtitle}
          </Text>
        </VStack>
      </Flex>
      <Hidden till="lg">
        <Divider height="3px" bgColor="darkText" />
      </Hidden>
    </VStack>
  );
};

export default OffPlatformFormHeader;
