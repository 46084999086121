import { Text } from 'native-base';
import Modal from 'ui/components/Modals/Modal';
import { colors } from 'ui/theme/colors';

import { useModalSignOutController } from '~/components/ModalSignOut/controller';
import { IModalSignOutProps } from '~/components/ModalSignOut/types';

const ModalSignOut = (props: IModalSignOutProps) => {
  const { modalRef, onClose } = props;

  const { handleSignOut } = useModalSignOutController(props);

  return (
    <Modal
      headerText="Sign out"
      ref={modalRef}
      modalContentMaxW="700"
      leftBtnAction={onClose}
      leftBtnText="No, go back"
      rightBtnAction={handleSignOut}
      rightBtnText="Yes, sign out"
      rightBtnBgColor={colors.error[600]}
    >
      <Text color={'gray.500'} fontSize="sm">
        {`Do you want to sign out your account?${'\n'}${'\n'}You're always welcome back to check in whenever you'd like.`}
      </Text>
    </Modal>
  );
};

export default ModalSignOut;
