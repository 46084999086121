import { HStack, Image } from 'native-base';

import { ActivityImagesCarouselProps } from '~/pages/Authenticated/v2/ActivityView/components/ActivityImagesCarousel/types';

export const ActivityImagesCarousel = ({
  carouselImages,
}: ActivityImagesCarouselProps) => {
  if (!carouselImages.length) return null;

  return (
    <HStack overflowX={'scroll'}>
      {carouselImages.map((imageUrl) => {
        return (
          <Image
            mr={2}
            key={imageUrl}
            flexShrink={0}
            borderRadius={12}
            alt="activity image"
            w={'calc(100% - 20px)'}
            source={{ uri: imageUrl }}
            h={{ base: 240, md: 448 }}
          />
        );
      })}
    </HStack>
  );
};

export default ActivityImagesCarousel;
