import { Flex, Hidden, Stack, Text, VStack } from 'native-base';
import Button from 'ui/components/Button';
import Illustration, { ILLUSTRATIONS_NAME } from 'ui/components/Illustration';

import { NavBar } from '~/components/NavBar';

interface ActionsProps {
  onPressPrimaryButton: () => void;
  onPressSecondaryButton: () => void;
}

const Actions = ({
  onPressPrimaryButton,
  onPressSecondaryButton,
}: ActionsProps) => {
  return (
    <VStack space={3} mt={{ base: 0, md: 12 }}>
      <Stack w={'full'}>
        <Button onPress={onPressPrimaryButton}>Find more opportunities</Button>
      </Stack>
      <Stack w={'full'}>
        <Button variant={'ghost'} onPress={onPressSecondaryButton}>
          View my activities
        </Button>
      </Stack>
    </VStack>
  );
};

interface GenericConfirmationPage extends ActionsProps {
  title: string;
  description: string;
  illustrationName: ILLUSTRATIONS_NAME;
}

export const GenericConfirmationPage = ({
  title,
  description,
  illustrationName,
  onPressPrimaryButton,
  onPressSecondaryButton,
}: GenericConfirmationPage) => {
  return (
    <Flex h={'100vh'} bg={'singletons.white'}>
      <NavBar hideCloseButton={false} onPressClose={onPressPrimaryButton} />

      <VStack
        space={9}
        py={6}
        px={4}
        bg={'gray.100'}
        h={'full'}
        justifyContent={'space-between'}
      >
        <VStack
          w={{ base: 'unset', md: 'full' }}
          h={'full'}
          flexDir={{
            base: 'column',
            md: 'row',
          }}
          justifyContent={'center'}
          alignItems={'center'}
          px={{ base: 0, sm: 12, md: 24, lg: 40 }}
        >
          <Stack alignItems={'center'} my={6} mr={{ base: 0, md: 20 }}>
            <Illustration
              alignItems={'revert'}
              justifyContent={'revert'}
              name={illustrationName}
              size={'2xl'}
              resizeMode={'contain'}
              h={{ base: 180, md: 380 }}
              w={{ base: 180, md: 380 }}
            />
          </Stack>
          <VStack
            maxW={{ base: '100%', lg: '450px' }}
            alignItems={{ base: 'center', md: 'unset' }}
            space={3}
            w={{ base: 'unset', md: '90%', lg: '70%' }}
          >
            <Text
              textAlign={{ base: 'center', md: 'left' }}
              fontSize={{ base: 'xl', md: '2xl' }}
              fontWeight={700}
            >
              {title}
            </Text>
            <Text
              textAlign={{ base: 'center', md: 'left' }}
              w={'80%'}
              color={'gray.500'}
              fontSize={'md'}
            >
              {description}
            </Text>

            <Hidden till={'md'}>
              <Actions
                onPressPrimaryButton={onPressPrimaryButton}
                onPressSecondaryButton={onPressSecondaryButton}
              />
            </Hidden>
          </VStack>
        </VStack>
        <Hidden from={'md'}>
          <Actions
            onPressPrimaryButton={onPressPrimaryButton}
            onPressSecondaryButton={onPressSecondaryButton}
          />
        </Hidden>
      </VStack>
    </Flex>
  );
};
