import { Box, Input as UIInput, Pressable } from 'native-base';
import { useRef } from 'react';
import { NativeSyntheticEvent, TextInputKeyPressEventData } from 'react-native';
import Icon from 'ui/components/Icon';
import { colors } from 'ui/theme/colors';

import CommonInputWrapper from '../../CommonInputWrapper';
import { SearchTextInputProps } from './types';

export const SearchTextInput = (props: SearchTextInputProps) => {
  const {
    placeholder,
    errorMessage,
    helpText,
    isDisabled,
    isRequired,
    label,
    value,
    fontSize = 14,
    keyboardType,
    showIcon = false,
    clearButtonOffset = 0,
    paddingRight = 10,
    onBlur,
    onChange,
    onClear,
    onSubmit,
  } = props;

  const inputRef = useRef<HTMLInputElement>(null);

  const handleKeyPress = (
    e: NativeSyntheticEvent<TextInputKeyPressEventData>,
  ) => {
    if (e.nativeEvent.key === 'Enter') {
      onSubmit?.(value || '');
    }
  };

  const handleClearButton = () => {
    onChange?.('');
    onClear?.();
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  return (
    <CommonInputWrapper
      label={label}
      isRequired={isRequired}
      isDisabled={isDisabled}
      errorMessage={errorMessage}
      helpText={helpText}
    >
      <Box width="full">
        {showIcon && (
          <Box
            position={'absolute'}
            top={0}
            bottom={0}
            left={3}
            zIndex={1}
            margin="auto"
            h={'fit-content'}
          >
            <Icon color={colors.muted[400]} icon="search" size={'16px'} />
          </Box>
        )}
        <UIInput
          ref={inputRef}
          width="full"
          type={'text'}
          paddingLeft={showIcon ? 9 : 0}
          defaultValue={value}
          backgroundColor={colors.singletons.lightText}
          onChange={(event: any) => onChange?.(event?.target?.value)}
          borderColor={errorMessage ? 'error.400' : colors.muted[200]}
          fontSize={fontSize}
          placeholder={placeholder}
          onBlur={onBlur}
          keyboardType={keyboardType}
          isDisabled={isDisabled}
          borderRadius={999}
          borderWidth={1}
          height={{ base: '46px', sm: '56px' }}
          onKeyPress={handleKeyPress}
          paddingRight={paddingRight}
        />

        {!!value?.length && (
          <Pressable
            position={'absolute'}
            top={0}
            bottom={0}
            right={3}
            zIndex={1}
            margin="auto"
            h={'fit-content'}
            marginRight={clearButtonOffset}
            onPress={handleClearButton}
          >
            <Icon color={colors.muted[400]} icon="x" size={'16px'} />
          </Pressable>
        )}
      </Box>
    </CommonInputWrapper>
  );
};
