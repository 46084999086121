import { endOfDay, isValid, startOfDay } from 'date-fns';
import { EEventApplicationType, LocationOptionsEnum } from 'ui/enums';
import { ActivitySemanticType, ActivityType } from 'ui/types/activities';
import { Coordinates } from 'ui/types/interfaces';
import { mapAttributesFromActivitySemanticType } from 'ui/utils/activity/mappers';
import { convertMilesToMeters } from 'ui/utils/location/transform-distance';

import {
  FilterSectionName,
  filterSectionsWhiteListMap as whiteListMap,
} from '~/components/Layouts/v2/SearchLayout/components/Modals/ActivityFilterModal/constants';
import { UnifiedSearchFilters } from '~/services/resources/activity-definitions/search/unified/unified-public-search.types';
import { AttendanceType } from '~/types/interfaces/activity';
import { SearchFilters } from '~/zustand/store/Search';

export const mapDateFilters = (
  startDate?: Date | null,
  endDate?: Date | null,
) => {
  let normalizedStartDate;
  let normalizedEndDate;

  if (startDate && isValid(startDate)) {
    normalizedStartDate = startOfDay(startDate);
  }

  if (endDate && isValid(endDate)) {
    normalizedEndDate = endOfDay(endDate);
  }

  return [normalizedStartDate, normalizedEndDate];
};

export const mapTypeFilters = (activityTypes: ActivitySemanticType[]) => {
  const types: ActivityType[] = [];
  const eventApplicationTypesSet = new Set<EEventApplicationType>();

  activityTypes?.map((semanticType) => {
    const { activityType, applicationType } =
      mapAttributesFromActivitySemanticType(semanticType);

    types.push(activityType);
    eventApplicationTypesSet.add(applicationType);
  });

  const eventApplicationTypes = Array.from(eventApplicationTypesSet);
  return { types, eventApplicationTypes };
};

export const mapTeamFilters = (appliedFilters: Partial<SearchFilters>) => {
  const teamsMinSize = appliedFilters?.teamsMinSize;
  const teamsMaxSize = appliedFilters?.teamsMaxSize;

  return [teamsMinSize, teamsMaxSize];
};

export const mapCausesFilters = (causeIds: string[]) => {
  const causeOptions = [];

  if (causeIds?.length) {
    for (const causeId of causeIds) {
      causeOptions.push({ _id: causeId });
    }
  }
  return { causeOptions };
};

export const mapLocationOptionsFilter = (appliedFilters: SearchFilters) => {
  const locationOptions: LocationOptionsEnum[] = [];

  if (appliedFilters.attendanceTypes?.length) {
    for (const attendanceType of appliedFilters.attendanceTypes) {
      if (attendanceType === AttendanceType.Local) {
        locationOptions.push(
          ...[
            LocationOptionsEnum.InPerson,
            LocationOptionsEnum.SingleLocation,
            LocationOptionsEnum.MultipleLocations,
          ],
        );
      } else if (attendanceType === AttendanceType.Online) {
        locationOptions.push(LocationOptionsEnum.FromHome);
      }
    }
  }
  return { locationOptions };
};

export const mapUnifiedSearchApiFilters = (
  appIds: string[],
  coordinates: Coordinates | null,
  filters: SearchFilters,
): Omit<UnifiedSearchFilters, 'limit' | 'skip'> => {
  const whiteLitsCanon = {
    activityTypes: filters.activityTypes,
    attendanceTypes: filters.attendanceTypes,
  };

  /* Map type attributes */
  const { types, eventApplicationTypes } = mapTypeFilters(
    filters.activityTypes || [],
  );

  /* Validate if cannon filters allow location (radius) filter */
  let radius;
  let position = null;
  if (whiteListMap.match(FilterSectionName.location, whiteLitsCanon)) {
    radius = convertMilesToMeters(filters.distanceRadius);
    position = coordinates;
  }

  /* Validate if cannon filters allow Date range filters */
  let startDate, endDate;
  if (whiteListMap.match(FilterSectionName.dateRange, whiteLitsCanon)) {
    const [_startDate, _endDate] = mapDateFilters(
      filters.startDate,
      filters.endDate,
    );
    startDate = _startDate;
    endDate = _endDate;
  }

  /* Validate if cannon filters allow Team filters */
  let teamsMinSize, teamsMaxSize;
  if (whiteListMap.match(FilterSectionName.teamSize, whiteLitsCanon)) {
    const [_teamsMinSize, _teamsMaxSize] = mapTeamFilters(filters);
    teamsMinSize = _teamsMinSize;
    teamsMaxSize = _teamsMaxSize;
  }

  /* Map causes */
  const { causeOptions } = mapCausesFilters(filters?.causes || []);

  /* Map attendance types to location Options */
  const { locationOptions } = mapLocationOptionsFilter(filters);

  const apiFilters: Omit<UnifiedSearchFilters, 'limit' | 'skip'> = {
    radius,
    types: types.length ? types : undefined,
    position,
    endDate,
    startDate,
    teamsMaxSize,
    teamsMinSize,
    apps: appIds,
    locationOptions,
    text: filters.searchText,
    relevance: filters.relevance,
    eventApplicationTypes,
    categories: {
      causeOptions,
      // periodOptions /** -- missing in design -- **/
      // requirementOptions /** -- missing in design -- **/
    },
    // regions: [], /** -- missing in design -- **/
    // showOnline, /** -- missing in design -- **/
  };

  return apiFilters;
};
