import { Box, Text, Tooltip, VStack } from 'native-base';
import Button from 'ui/components/Button';

import { useActivityApplicationFooterController } from '~/pages/Authenticated/v2/ActivityView/components/ActivityApplicationFooter/controller';
import { ActivityApplicationFooterProps } from '~/pages/Authenticated/v2/ActivityView/components/ActivityApplicationFooter/types';

export const ActivityApplicationFooter: React.FC<
  ActivityApplicationFooterProps
> = (props) => {
  const {
    onCancel,
    actionLabel,
    tooltipMessage,
    isActionDisabled = false,
  } = props;

  const { handleAction } = useActivityApplicationFooterController(props);

  return (
    <VStack mt={8} space={3}>
      <Tooltip
        label={tooltipMessage}
        display={!tooltipMessage ? 'none' : 'unset'}
      >
        <Box>
          <Button
            onPress={handleAction}
            isDisabled={isActionDisabled}
            bg={'singletons.black'}
            h={'2.5rem'}
            color={'white'}
          >
            <Text fontWeight={'500'} color={'inherit'} fontSize={'md'}>
              {actionLabel || ''}
            </Text>
          </Button>
        </Box>
      </Tooltip>

      {onCancel && (
        <Button w="full" variant="link" onPress={onCancel}>
          <Text color="singleton.black" fontWeight={500} fontSize="md">
            Back
          </Text>
        </Button>
      )}
    </VStack>
  );
};

export default ActivityApplicationFooter;
