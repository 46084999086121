import { useEffect, useRef } from 'react';

import { SEARCH_COLLECTION_CONFIG_MAP } from '~/components/Layouts/v2/SearchLayout/constants/search-collections';
import { PAGES } from '~/constants/pages.constants';
import { useRouter } from '~/hooks/useRouter';
import { SearchCollectionSlug } from '~/types/interfaces/activity-search/types';
import { useInApp } from '~/zustand/store/InApp';
import { useSearch } from '~/zustand/store/Search';

export const useCollectionController = () => {
  // Providers
  const { params, goToRoute } = useRouter();

  const { app, selectedCategory } = useInApp((state) => state);
  const applyFilters = useSearch((state) => state.applyFilters);

  const selectCollection = useSearch((state) => state.selectCollection);

  // Computed Values
  const collectionSlugEntryRef = useRef<string | undefined>();
  const collectionSlug = params?.collection || '';
  const isAppSearch = !!params?.appId && app?._id;

  const handleSelectCollection = (collectionSlug?: string) => {
    if (collectionSlugEntryRef.current === collectionSlug) return;
    collectionSlugEntryRef.current = collectionSlug;

    // No collection selected (search root)
    if (!collectionSlug) {
      selectCollection(null);
      return;
    }

    // Send back to Search root if invalid collection slug were given
    const collectionConfig = SEARCH_COLLECTION_CONFIG_MAP.get(
      collectionSlug as SearchCollectionSlug,
    );
    if (!collectionConfig) {
      goToRoute(PAGES.Search);
      return;
    }

    // Define collection & set filters preset
    selectCollection(collectionConfig);
    if (collectionConfig.filters) {
      applyFilters(collectionConfig.filters, true);
    }

    // If inApp Search and there is a selected Category, apply it filters
    if (isAppSearch && selectedCategory?.filters) {
      applyFilters(selectedCategory?.filters);
    }
  };

  // Hooks
  useEffect(() => {
    handleSelectCollection(collectionSlug);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionSlug]);

  return { collectionSlug };
};
