import {
  OffPlatformTeamMember,
  Team,
  TeamMember,
  TeamMembersSummary,
  TeamMemberStatus,
} from '../types/teams';
import { UserProfile } from '../types/user';

const LEADER_COUNT = 1;
export const getTeamMembersTotal = (team: Team) => {
  if (!team?.membersStatuses) return 0;

  const offPlatformTotal = Object.values(team?.offPlatformMembers ?? []).reduce(
    (acc, offPlatform) =>
      offPlatform?.status !== TeamMemberStatus.REJECTED ? acc + 1 : acc,
    0,
  );

  const total = Object.values(team.membersStatuses).reduce(
    (acc, status) => (status !== TeamMemberStatus.REJECTED ? acc + 1 : acc),
    0,
  );

  return total + offPlatformTotal + LEADER_COUNT;
};

export const groupTeamMembersByStatus = (team: Team) => {
  const statusGroups: { [key in TeamMemberStatus]: TeamMember[] } = {
    [TeamMemberStatus.PENDING]: [],
    [TeamMemberStatus.REJECTED]: [],
    [TeamMemberStatus.ACCEPTED]: [],
  };

  // Add Off-platform members to pending array
  if (team.offPlatformMembers) {
    for (const offPlatformMember of team.offPlatformMembers ?? []) {
      const parsedMember = mapOffPlatformMemberToTeamMember(offPlatformMember);
      statusGroups[parsedMember.status].push(parsedMember);
    }
  }

  // Add members to its status array
  for (const userProfile of team.membersSubDocument ?? []) {
    let memberSummary = (team.membersSummary ?? {})[userProfile._id];
    const status = (team.membersStatuses ?? {})[
      userProfile._id
    ] as TeamMemberStatus;

    if (!memberSummary) memberSummary = { status };

    const parsed = mapUserProfileToTeamMember(userProfile, memberSummary);
    statusGroups[status].push(parsed);
  }

  return statusGroups;
};

export const mapUserProfileToTeamMember = (
  userProfile: UserProfile,
  membersSummary?: TeamMembersSummary,
): TeamMember => {
  return {
    _id: userProfile._id,
    name: userProfile.userSummary.name,
    email: userProfile.userSummary.email,
    profilePicture: userProfile.profilePicture,
    status: membersSummary?.status || TeamMemberStatus.ACCEPTED,
    joinedAt: membersSummary?.updatedAt,
    isInternalMember: true,
  };
};

const mapOffPlatformMemberToTeamMember = (
  offPlatformMember: OffPlatformTeamMember,
): TeamMember => {
  return {
    _id: offPlatformMember._id,
    email: offPlatformMember.email,
    status: offPlatformMember.status || TeamMemberStatus.PENDING,
  };
};
