import { ActivityType } from 'ui/types/activities';

import { SearchCollectionSlug } from '~/types/interfaces/activity-search/types';
import { mapTypeFilters } from '~/utils/activity-search/map-unified-search-api-filters';
import { SearchFilters } from '~/zustand/store/Search';

export const mapSuggestionCollections = (
  appliedFilters: SearchFilters,
): SearchCollectionSlug[] => {
  const { types } = mapTypeFilters(appliedFilters.activityTypes || []);

  const suggestionsSlugs = [];
  if (!types.includes(ActivityType.Action)) {
    suggestionsSlugs.push(SearchCollectionSlug.Actions);
  }

  if (!types.includes(ActivityType.Ongoing)) {
    suggestionsSlugs.push(SearchCollectionSlug.Ongoing);
  }

  return suggestionsSlugs;
};
