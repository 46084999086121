import { PAGES } from '~/constants/pages.constants';

export const _getHomePathname = () => {
  const homePath = PAGES.Home;
  return homePath;
};

export const _getInAppHomePathname = (appId: string) => {
  return PAGES.InApp.replace(':appId', appId);
};

export const _getSearchRootPathname = (appId?: string) => {
  const path = appId
    ? PAGES.InAppSearch.replace(':appId', appId)
    : PAGES.Search;

  return path;
};

export const _getActivityDetailsPathname = (
  activityDefinitionId: string,
  appId?: string,
) => {
  const path = appId
    ? PAGES.InAppActivityDetails.replace(':appId', appId).replace(
        ':id',
        activityDefinitionId,
      )
    : PAGES.ActivitiesDetails.replace(':id', activityDefinitionId);

  return path;
};

export const _getSearchCollectionPathname = (
  collectionSlug: string,
  appId?: string,
) => {
  if (appId) {
    return PAGES.InAppSearchCollection.replace(':appId', appId).replace(
      ':collection',
      collectionSlug,
    );
  }
  return PAGES.SearchCollection.replace(':collection', collectionSlug);
};
