import { useMemo } from 'react';
import { OrganizationType } from 'ui/types/interfaces/organization';

import { useAppSelector } from '~/hooks/useAppSelector';
import { useLoadOrganization } from '~/hooks/useLoadOrganization';

export const useVolunteerOrganization = () => {
  const { selectedUserProfile } = useAppSelector(({ auth }) => auth);

  const organizationId = useMemo(() => {
    const organizationFromEcosystem =
      selectedUserProfile?.ecosystemSummary?.organization;

    let id;
    if (typeof organizationFromEcosystem == 'string') {
      id = organizationFromEcosystem as string;
    } else {
      id = organizationFromEcosystem?._id;
    }

    return id;
  }, [selectedUserProfile]);

  const { data: organization, isLoading } = useLoadOrganization(organizationId);

  const isNFP = organization?.type === OrganizationType.NFP;
  const isCompany = organization?.type === OrganizationType.COMPANY;

  return { isLoading, organization, isNFP, isCompany };
};
