import { VStack } from 'native-base';

import { CenterContainer } from '~/components/Layouts/Scaffolds/CenterContainer';

import { HomeSearchPanel } from './SearchPanel';
import { WidgetGrid } from './Widgetgrid';

export type HomeHeaderProps = {};

export const HomeHeader = ({}: HomeHeaderProps) => {
  return (
    <VStack
      pt={{ base: 3, md: 8 }}
      backgroundColor={'gray.800'}
      space={{ base: 4, md: 10 }}
      zIndex={2}
    >
      <CenterContainer maxWidth="1300px" px={0}>
        <WidgetGrid />
      </CenterContainer>

      <CenterContainer maxWidth={'800px'} px={0}>
        <HomeSearchPanel />
      </CenterContainer>
    </VStack>
  );
};
