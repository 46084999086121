import { Auth } from 'aws-amplify';
import { useState } from 'react';
import { toast } from 'react-toastify';

import { COMMON_MESSAGES } from '~/constants/messages.constants';
import { PAGES } from '~/constants/pages.constants';
import { useAppDispatch } from '~/hooks/useAppDispatch';
import { useAppSelector } from '~/hooks/useAppSelector';
import { useRouter } from '~/hooks/useRouter';
import UserService from '~/services/resources/user';
import { authSliceActions } from '~/store/slices/auth';
import { ecosystemSliceActions } from '~/store/slices/ecosystem';

export const useDeleteAccountController = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useAppSelector(({ auth }) => auth);
  const { goToRoute, goBack } = useRouter();
  const dispatch = useAppDispatch();
  const handleConfirmDeleteAccount = async () => {
    try {
      setIsLoading(true);
      if (!user?._id) throw new Error('User not found');

      await UserService.deleteById(user._id);
      await Auth.deleteUser();

      goToRoute(PAGES.AccountDeleted);
      setTimeout(() => {
        dispatch(authSliceActions.logout());
        dispatch(ecosystemSliceActions.resetEcosystem());
      }, 500);
    } catch (err) {
      toast.error(COMMON_MESSAGES.ACCOUNT_DELETE_ERROR);
    } finally {
      setIsLoading(false);
    }
  };
  return { handleConfirmDeleteAccount, isLoading, goBack };
};
