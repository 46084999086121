import { useEffect, useRef } from 'react';
import { IModalRefProps } from 'ui/components/Modals/Modal/types';

import { useAppSelector } from '~/hooks/useAppSelector';

export const useEnablePreferenceLocationModal = () => {
  const { user, isLoading: isUserLoading } = useAppSelector(({ auth }) => auth);

  const locationModalRef = useRef<IModalRefProps>(null);

  const handleCloseModal = () => {
    locationModalRef.current?.close();
  };

  useEffect(() => {
    if (user && !isUserLoading && !user?.preferences?.address) {
      locationModalRef.current?.open();
    }
  }, [user, isUserLoading]);

  return { handleCloseModal, locationModalRef };
};
