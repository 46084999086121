import { Box, Text, useBreakpointValue, VStack } from 'native-base';
import { ActivityTypeBadge } from 'ui/components/v2/Badges/ActivityTypeBadge';
import { ActivitySemanticType } from 'ui/types/activities';

import { ActivityBaseCard } from '../Base';
import { ActivityOrganizationSnippet } from '../fragments/Snippets/ActivityOrganizationSnippet';
import { ActivityTitleSnippet } from '../fragments/Snippets/ActivityTitleSnippet';
import { useOngoingActivityCardController } from './controller';
import { OngoingActivityCardProps } from './types';

export const OngoingActivityCard = (props: OngoingActivityCardProps) => {
  const {
    organizationName,
    organizationLogoUrl,
    activityTitle,
    description,
    numberOfLines = 4,
    onPress,
  } = props;
  const { footerNodes } = useOngoingActivityCardController(props);

  const minHeight = useBreakpointValue({
    base: 12 * 1.5 * numberOfLines,
    md: 14 * 1.5 * numberOfLines,
  });

  return (
    <ActivityBaseCard
      onPress={onPress}
      footerNodes={footerNodes}
      showCoverImage={false}
    >
      <VStack pb={1} space={2}>
        <Box mb={1} w="fit-content">
          <ActivityTypeBadge
            activityType={ActivitySemanticType.Ongoing}
            customName="LONG-TERM"
          />
        </Box>

        <ActivityTitleSnippet title={activityTitle} size="lg" />

        <ActivityOrganizationSnippet
          organizationName={organizationName}
          organizationLogoUrl={organizationLogoUrl}
        />

        <Text
          fontWeight={400}
          numberOfLines={numberOfLines}
          fontSize={{ base: 'xs', md: 'sm' }}
          minH={minHeight}
          color={'gray.600'}
        >
          {description}
        </Text>
      </VStack>
    </ActivityBaseCard>
  );
};
