import { Avatar, HStack, Pressable, Stack, Text } from 'native-base';
import Button from 'ui/components/Button';
import Icon from 'ui/components/Icon';
import { colors } from 'ui/theme/colors';

import { MobileAppBar } from '~/components/MobileAppBar/MobileAppBar';
import { AuthenticatedMobileHeaderProps } from '~/components/NavBar/navBar.types';
import { PAGES, pagesNameDictionary } from '~/constants/pages.constants';
import { useRouter } from '~/hooks/useRouter';

export const MobileNavBar = ({
  hideMobileNavBar = false,
  hideBackButton = true,
  hideCloseButton = true,
  userParsedData,
  currentPath,
  onPressClose,
}: AuthenticatedMobileHeaderProps) => {
  const { goBack, isStackEmpty, isRootPage } = useRouter();

  if (!userParsedData) return null;

  // TODO: Fix types
  const title = pagesNameDictionary[currentPath as PAGES];

  const shouldShowBackButton = !isRootPage || (isRootPage && !isStackEmpty);

  if (!hideBackButton) {
    return (
      <>
        <HStack
          w="100%"
          bg={'gray.100'}
          py={3}
          px={4}
          alignItems={'center'}
          justifyContent="space-between"
        >
          <Stack minWidth={'77px'}>
            {shouldShowBackButton && (
              <Button
                leftIconColor="singletons.black"
                leftIconName={isStackEmpty ? 'home' : 'chevron-left'}
                _pressed={{ backgroundColor: colors.gray[200] }}
                variant="link"
                minWidth="72px"
                onPress={goBack}
              >
                <Text color="singletons.black" fontSize="xs" fontWeight={500}>
                  {isStackEmpty ? 'Home' : 'Back'}
                </Text>
              </Button>
            )}
          </Stack>

          <Stack>
            <Text fontSize={'lg'} color={'gray.600'} fontWeight={500}>
              {title}
            </Text>
          </Stack>
          <Stack minWidth={'77px'} />
        </HStack>
        {!hideMobileNavBar && <MobileAppBar />}
      </>
    );
  }

  if (!hideCloseButton) {
    return (
      <HStack
        bg={'gray.100'}
        w="100%"
        h="72px"
        py={3}
        px={4}
        alignItems={'center'}
        justifyContent={'flex-end'}
      >
        <Pressable
          flexDir={'row'}
          p={2}
          alignItems={'center'}
          justifyContent={'flex-end'}
          onPress={onPressClose}
        >
          <HStack space={1} alignItems={'center'}>
            <Text fontSize={'xs'} fontWeight={500}>
              Close
            </Text>
            <Icon size={16} icon={'x'} />
          </HStack>
        </Pressable>
      </HStack>
    );
  }

  return (
    <>
      <HStack
        display={{ base: 'flex', md: 'unset' }}
        w="100%"
        h="72px"
        top={0}
        py={3}
        px={4}
        alignItems={'center'}
        justifyContent="space-between"
        bg={'gray.100'}
      >
        <Stack w={'40%'} justifyContent={'flex-start'} alignItems="center">
          <Avatar.Group flexDir={'row'} pl={4} max={2}>
            <Avatar
              borderColor={'gray.300'}
              bg="gray.400"
              source={{ uri: userParsedData.ecosystemLogo }}
            >
              {userParsedData.ecosystemInitial}
            </Avatar>
            <Avatar
              bg="gray.400"
              borderColor={'gray.300'}
              source={{ uri: userParsedData.userAvatar }}
            >
              {userParsedData.userInitials}
            </Avatar>
          </Avatar.Group>
        </Stack>

        <Stack w={'full'} justifyContent={'center'} alignItems={'center'}>
          <Text fontSize={'lg'} color={'gray.600'} fontWeight={500}>
            {title}
          </Text>
        </Stack>

        <Stack w={'40%'} />
      </HStack>

      {!hideMobileNavBar && <MobileAppBar />}
    </>
  );
};
