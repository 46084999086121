import './styles.css';

import { useEffect, useMemo, useState } from 'react';
const DynamicSvg = ({
  imageUrl,
  fillColor,
  className,
  size = '24px',
}: {
  imageUrl: string;
  fillColor?: string;
  className?: string;
  size?: string;
}) => {
  const [svgContent, setSvgContent] = useState('');

  useEffect(() => {
    const fetchSvg = async () => {
      const response = await fetch(imageUrl);
      const svgText = await response.text();

      const cleanedSvg = svgText.replace(/fill=".*?"/g, '');
      setSvgContent(cleanedSvg);
    };

    fetchSvg();
  }, [imageUrl]);

  const filledSvgContent = useMemo(() => {
    if (!svgContent) return null;

    return svgContent.replace('<svg', `<svg style="fill: ${fillColor}"`);
  }, [svgContent, fillColor]);

  return (
    <image
      style={{ width: size, height: size }}
      className={`dymanic-svg ${className}`}
      dangerouslySetInnerHTML={{ __html: filledSvgContent || '' }}
    />
  );
};

export default DynamicSvg;
