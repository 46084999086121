import { Box, Flex, useBreakpointValue } from 'native-base';
import { DatePicker } from 'ui/components/v2/DatePicker/Base';

import { SearchFilters } from '~/zustand/store/Search';

export type DateRangeFilterSectionProps = {
  defaultEndDate: Date | null;
  defaultStartDate: Date | null;
  onChange?: (newFilters: Partial<SearchFilters>) => void;
};

export const DateRangeFilterSection = ({
  defaultStartDate,
  defaultEndDate,
  onChange,
}: DateRangeFilterSectionProps) => {
  const isMobile = useBreakpointValue({ base: true, sm: false });

  const handleChange = (date: Date | null, name?: string) => {
    if (!name) return;
    onChange?.({ [name]: date });
  };

  return (
    <Flex w="full" style={{ gap: 24 }} flexDirection={'row'}>
      <Box flex={1}>
        <DatePicker
          usePortal={isMobile}
          portalId="modal-root"
          label="From"
          name={'startDate'}
          value={defaultStartDate}
          onChange={handleChange}
          placeholder="Select Date"
        />
      </Box>

      <Box flex={1}>
        <DatePicker
          usePortal={isMobile}
          portalId="modal-root"
          label="To"
          name={'endDate'}
          value={defaultEndDate}
          onChange={handleChange}
          placeholder="Select Date"
          dateFormat="dd/MM/yyyy"
        />
      </Box>
    </Flex>
  );
};
