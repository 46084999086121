import FeatherIcon from 'feather-icons-react';
import { HStack, Link, Stack, Text } from 'native-base';

import { colors } from '../../../theme/colors';
import { CardInfoProps } from './types';

const CardPropInfo = ({
  iconColor = colors.gray[500],
  textColor = 'gray.500',
  icon,
  info,
  isLink = false,
  link,
  ...props
}: CardInfoProps) => {
  return (
    <HStack space={2} justifyContent="space-between" {...props}>
      <Stack width="100%" maxW="20px">
        <FeatherIcon icon={icon} size="20px" color={iconColor} />
      </Stack>
      <Stack w="full">
        <Text
          color={textColor}
          fontSize="sm"
          fontWeight={400}
          overflow={'hidden'}
        >
          {isLink ? (
            <Link href={link} isExternal>
              {info}
            </Link>
          ) : (
            info
          )}
        </Text>
      </Stack>
    </HStack>
  );
};

export default CardPropInfo;
