import { Image } from 'native-base';

import { IIllustrationProps, ILLUSTRATIONS_NAME } from './types';
import { useIllustrationController } from './useIllustrationController';

const Illustration = ({ name, ...restProps }: IIllustrationProps) => {
  const { illustration } = useIllustrationController({ name });

  return <Image alt="illustration" src={illustration} {...restProps} />;
};

export default Illustration;
export { ILLUSTRATIONS_NAME };
