import { NativeBaseProvider } from 'native-base';

import { ReactFunctionComponent } from '~/types/interfaces';

import { theme } from './index';

const ThemeProvider = ({ children }: ReactFunctionComponent) => {
  return <NativeBaseProvider theme={theme}>{children}</NativeBaseProvider>;
};

export default ThemeProvider;
