import { Box, Skeleton, VStack } from 'native-base';

export const ButtonGroupRoundButtonSkeleton = () => {
  return (
    <Box
      backgroundColor="white"
      w="88px"
      justifyContent={'center'}
      alignItems={'center'}
    >
      <VStack space={2}>
        <Skeleton h={'56px'} w={'56px'} rounded={100} />

        <Skeleton.Text lines={1} h={2} width="100%" />
      </VStack>
    </Box>
  );
};
