import { Box, HStack, Stack, Text, useBreakpointValue } from 'native-base';
import Button from 'ui/components/Button';
import Icon from 'ui/components/Icon';
import { colors } from 'ui/theme/colors';

import { FilterButtonBaseProps } from './types';

export const FilterButtonBase = (props: FilterButtonBaseProps) => {
  const { icon, label, onPress, outlined = false, filterCount } = props;

  const isMobile = useBreakpointValue({ base: true, sm: false });

  return (
    <Button
      position={'relative'}
      w="fit-content"
      minW={0}
      variant={outlined && !isMobile ? 'outline' : 'ghost'}
      px={{ base: 0, sm: 4 }}
      py={{ base: 0, md: 5 }}
      onPress={onPress}
      pointerEvents={!onPress ? 'none' : 'auto'}
    >
      <HStack alignItems={'center'} space={'10px'}>
        {icon && (
          <Icon
            icon={icon}
            enableAutoCustom
            size={isMobile ? '16px' : ' 24px'}
            color={colors.singletons.black}
          />
        )}
        <Text
          fontSize={{ base: 'xs', sm: 'md' }}
          fontWeight={500}
          color={colors.singletons.black}
        >
          {label}
        </Text>
      </HStack>

      {!!filterCount && !isMobile ? (
        <Box
          w={'18px'}
          h={'18px'}
          borderRadius={100}
          position={'absolute'}
          top={'-12px'}
          right={'-22px'}
          backgroundColor={colors.singletons.black}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Text
            color={colors.white}
            lineHeight={'150%'}
            fontSize={'10px'}
            textAlign={'center'}
            fontWeight={500}
          >
            {filterCount}
          </Text>
        </Box>
      ) : null}
    </Button>
  );
};
