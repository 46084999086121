import { DEFAULT_MEASUREMENT_UNIT_TIME_ID } from '~/config';
import { useLoadActivityApplication } from '~/hooks/useLoadActivityApplication';
import { useLoadAppById } from '~/hooks/useLoadAppById';
import { useLoadMeasurementsByActivityApplication } from '~/hooks/useLoadMeasurementsByActivityApplication';
import { useLoadMeasurementUnit } from '~/hooks/useLoadMeasurementUnit';
import { useRouter } from '~/hooks/useRouter';

export const useLogActivityController = () => {
  const { params, goToRoute } = useRouter();

  const { data: activityApplication, isLoading: isLoadingActivityApplication } =
    useLoadActivityApplication(params.id || '');

  const activitySubDocument = activityApplication?.activitySubDocument;
  const activityType = activitySubDocument?.activityDefinitionSubDocument.type;
  const measurementUnitId =
    activitySubDocument?.activityDefinitionSubDocument?.measurementUnit;

  const { data: measurementUnit, isLoading: isLoadingMeasurementUnit } =
    useLoadMeasurementUnit(
      (measurementUnitId as string) || DEFAULT_MEASUREMENT_UNIT_TIME_ID,
    );

  const {
    data: measurements,
    isLoading: isLoadingMeasurements,
    refetch: refetchMeasurements,
  } = useLoadMeasurementsByActivityApplication(activityApplication?._id || '');

  const { data: app, isLoading: isLoadingApp } = useLoadAppById(
    (activityApplication?.activitySubDocument?.app as string) || '',
  );

  const isLoading =
    isLoadingActivityApplication ||
    isLoadingMeasurementUnit ||
    isLoadingMeasurements ||
    isLoadingApp;

  return {
    isLoading,
    activityType,
    app,
    activityApplication,
    params,
    goToRoute,
    measurementUnit,
    measurements,
    refetchMeasurements,
  };
};
