import { ActivityType } from 'ui/types/activities';

export const getCoverImageFromActivityDefinition = (
  type: ActivityType,
  coverImage?: string,
  organizationLogo?: string,
) => {
  const coverImageUrl =
    type === ActivityType.Ongoing ? organizationLogo : coverImage;

  return coverImageUrl || '';
};
