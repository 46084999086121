import { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { IModalRefProps } from 'ui/components/Modals/Modal/types';

import { PAGES } from '~/constants/pages.constants';
import { useRouter } from '~/hooks/useRouter';
import { OffPlatformActivitiesProps } from '~/pages/Authenticated/LogActivities/OffPlatformActivities/types';
import MeasurementDefinitionService from '~/services/resources/measurementDefinition';

export const useOffPlatformActivitiesController = (
  props: OffPlatformActivitiesProps,
) => {
  const { refetchActivity, measurements, totalMeasurements } = props;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [definitionId, setDefinitionId] = useState('');
  const { goToRoute } = useRouter();

  const modalRef = useRef<IModalRefProps>(null);

  const handleOptions = (action: string, _definitionId: string) => {
    if (action === 'delete') {
      setDefinitionId(_definitionId);
      modalRef?.current?.open();
      return;
    }
  };

  const handleCloseModal = () => {
    modalRef?.current?.close();
  };

  const handleDeleteMeasurementDefinition = async () => {
    if (!definitionId) return;

    try {
      setIsSubmitting(true);
      await MeasurementDefinitionService.deleteOffPlatform(definitionId);
      handleCloseModal();
      toast.success(`Log canceled with success.`);
      refetchActivity();
    } catch (error) {
      toast.error(`Error on trying to cancel log.`);
    } finally {
      setIsSubmitting(false);
    }
  };

  const showNoMoreActivitiesWarning =
    (measurements ?? [])?.length > 0 &&
    totalMeasurements <= measurements.length;

  const handleOffPlatformCreate = () => {
    goToRoute(PAGES.CreateOffPlatformActivity);
  };

  return {
    handleDeleteMeasurementDefinition,
    isSubmitting,
    modalRef,
    handleCloseModal,
    handleOptions,
    goToRoute,
    showNoMoreActivitiesWarning,
    handleOffPlatformCreate,
  };
};
