export type SearchCategoryIcon = {
  iconName?: string;
  imageUrl?: string;
};

export class SearchCategory<E, F> {
  constructor(
    public key: E,
    public label: string,
    public icon: SearchCategoryIcon,
    public filters: Partial<F>,
  ) {}
}
