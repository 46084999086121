import {
  FilterSectionName,
  filterSectionsWhiteListMap,
} from '~/components/Layouts/v2/SearchLayout/components/Modals/ActivityFilterModal/constants';
import { SearchFilters } from '~/zustand/store/Search';

export function isSectionEnabled(
  sectionName: FilterSectionName,
  filters?: Partial<SearchFilters>,
) {
  if (!filters) return false;

  return filterSectionsWhiteListMap.match(sectionName, {
    activityTypes: filters?.activityTypes || [],
    attendanceTypes: filters?.attendanceTypes || [],
  });
}

export const calculateFilterAppliedCount = (
  appliedFilters: Partial<SearchFilters>,
  defaultFilters: SearchFilters,
) => {
  const causesCount = appliedFilters?.causes?.length || 0;
  const activityTypesCount = appliedFilters.activityTypes?.length || 0;
  const attendanceTypesCount =
    appliedFilters.attendanceTypes?.length == 2 ? 0 : 1;

  let filtersCount = causesCount + activityTypesCount + attendanceTypesCount;

  if (isSectionEnabled(FilterSectionName.dateRange, appliedFilters)) {
    if (
      !!appliedFilters.startDate &&
      defaultFilters.startDate !== appliedFilters.startDate
    ) {
      filtersCount++;
    }

    if (
      !!appliedFilters.endDate &&
      defaultFilters.endDate !== appliedFilters.endDate
    ) {
      filtersCount++;
    }
  }

  if (isSectionEnabled(FilterSectionName.location, appliedFilters)) {
    if (defaultFilters.distanceRadius !== appliedFilters.distanceRadius) {
      filtersCount++;
    }
  }

  if (isSectionEnabled(FilterSectionName.teamSize, appliedFilters)) {
    const teamsSizeChanged =
      defaultFilters.teamsMaxSize !== appliedFilters.teamsMaxSize ||
      defaultFilters.teamsMinSize !== appliedFilters.teamsMinSize;

    if (teamsSizeChanged) {
      filtersCount++;
    }
  }

  return filtersCount;
};
