import { PAGES } from '~/constants/pages.constants';
import { useAppSelector } from '~/hooks/useAppSelector';
import { useRouter } from '~/hooks/useRouter';

export const usePage403Controller = () => {
  const { goToRoute } = useRouter();

  const { isAuthenticated } = useAppSelector(({ auth }) => auth);

  const goToOpportunities = () => {
    if (isAuthenticated) {
      goToRoute(PAGES.Opportunities);
    } else {
      goToRoute(PAGES.GuestOpportunities);
    }
  };

  const goToSignIn = () => {
    goToRoute(PAGES.Root);
  };

  const buttonText = isAuthenticated
    ? 'See other opportunities'
    : 'Go to Doit.life';

  return { goToOpportunities, goToSignIn, isAuthenticated, buttonText };
};
