import { Amplify } from 'aws-amplify';

import { COGNITO_AUTHENTICATION_FLOW } from '~/enums';

Amplify.configure({
  authenticationFlowType: COGNITO_AUTHENTICATION_FLOW.CUSTOM_AUTH,
  Auth: {
    region: import.meta.env.VITE_AWS_REGION,
    userPoolId: import.meta.env.VITE_AWS_USER_POOL_ID,
    userPoolWebClientId: import.meta.env.VITE_AWS_USER_POOL_WEB_CLIENT_ID,
    mandatorySignIn: true,
  },
});
