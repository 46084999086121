import {
  Box,
  HStack,
  ScrollView,
  Stack,
  Text,
  Tooltip,
  VStack,
} from 'native-base';
import Button from 'ui/components/Button';
import { colors } from 'ui/theme/colors';
import { ActivitySemanticType } from 'ui/types/activities';

import { ActivityPanel } from '~/pages/Authenticated/v2/ActivityView/components/ActivityPanel';
import { useActivityLogPanelController } from '~/pages/Authenticated/v2/ActivityView/views/ActivityLog/ActivityLogPanel/controller';
import { ActivityLogPanelProps } from '~/pages/Authenticated/v2/ActivityView/views/ActivityLog/ActivityLogPanel/types';
import ActionApplicationCard from '~/pages/Authenticated/v2/ActivityView/views/ActivityLog/components/ActionApplicationCard';
import EventApplicationCard from '~/pages/Authenticated/v2/ActivityView/views/ActivityLog/components/EventApplicationCard';
import OngoingApplicationCard from '~/pages/Authenticated/v2/ActivityView/views/ActivityLog/components/OngoingApplicationCard';
import TeamEventApplicationCard from '~/pages/Authenticated/v2/ActivityView/views/ActivityLog/components/TeamEventApplicationCard';
import { IActivityDefinition } from '~/types/interfaces/activity';

export const ActivityLogPanel: React.FC<ActivityLogPanelProps> = (
  props: ActivityLogPanelProps,
) => {
  const { activitiesTotal, appliedActivities, onGoBackPress } = props;

  const {
    handleLogAction,
    selectAppliedActivity,
    selectedAppliedActivity,
    activityDefinition,
    logAvailability,
    activityType,
    title,
  } = useActivityLogPanelController(props);

  return (
    <ActivityPanel
      title={title}
      headerBackground={colors.violet[200]}
      titleColor={colors.violet[900]}
    >
      <VStack space={3}>
        <Box maxH={'420px'}>
          <ScrollView>
            <VStack w="full">
              {appliedActivities.map((activity) => {
                switch (activityType) {
                  case ActivitySemanticType.Action:
                    return (
                      <ActionApplicationCard
                        key={activity._id}
                        activity={activity}
                        activityDefinition={
                          activityDefinition as IActivityDefinition
                        }
                      />
                    );
                  case ActivitySemanticType.Ongoing:
                    return (
                      <OngoingApplicationCard
                        key={activity._id}
                        activity={activity}
                        isSelected={
                          selectedAppliedActivity?._id === activity._id
                        }
                        onPress={selectAppliedActivity}
                      />
                    );
                  case ActivitySemanticType.TeamEvent:
                    return (
                      <TeamEventApplicationCard
                        key={activity._id}
                        activity={activity}
                        onPress={selectAppliedActivity}
                        isSelected={
                          selectedAppliedActivity?._id === activity._id
                        }
                      />
                    );
                  default:
                    return (
                      <EventApplicationCard
                        key={activity._id}
                        activity={activity}
                        onPress={selectAppliedActivity}
                        isSelected={
                          selectedAppliedActivity?._id === activity._id
                        }
                      />
                    );
                }
              })}
            </VStack>
          </ScrollView>
        </Box>

        {appliedActivities.length < activitiesTotal && (
          <Stack
            w="full"
            alignItems={'center'}
            borderRadius={'12px'}
            backgroundColor={colors.gray[100]}
          >
            <Button
              w="full"
              variant="ghost"
              colorScheme="darkText"
              onPress={onGoBackPress}
            >
              <Text color="singleton.black" fontWeight={500} fontSize="sm">
                Book more dates
              </Text>
            </Button>
          </Stack>
        )}

        <HStack mt={6}>
          <Tooltip
            label={logAvailability.message}
            display={!logAvailability.message ? 'none' : 'unset'}
          >
            <Button
              onPress={handleLogAction}
              isDisabled={!logAvailability.isAvailable}
              bg={'singletons.black'}
              h={'2.5rem'}
              color={'white'}
            >
              <Text fontWeight={'500'} color={'inherit'} fontSize={'md'}>
                Log activity
              </Text>
            </Button>
          </Tooltip>
          <Box></Box>
        </HStack>
      </VStack>
    </ActivityPanel>
  );
};

export default ActivityLogPanel;
