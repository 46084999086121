import { mountStoreDevtool } from 'simple-zustand-devtools';
import { App } from 'ui/types/interfaces/app';
import { create, useStore } from 'zustand';
import { useShallow } from 'zustand/react/shallow';

import { ActivitySearchCategory } from '~/types/interfaces/activity-search/types';

import { InAppSlice, InAppState } from './types';
export * from './types';

const IN_APP_INITIAL_STATE: InAppState = {
  app: null,
  selectedCategory: null,
  isLoading: false,
  isAppLoading: false,
};

export const createInAppSlice = (set: any) => ({
  ...IN_APP_INITIAL_STATE,

  setApp: (app: App | null) => set((state: InAppState) => ({ ...state, app })),
  setIsLoading: (isLoading: boolean) =>
    set((state: InAppState) => ({ ...state, isLoading })),
  setIsAppLoading: (isAppLoading: boolean) =>
    set((state: InAppState) => ({ ...state, isAppLoading })),
  setSelectedCategory: (category: ActivitySearchCategory | null) =>
    set((state: InAppState) => ({ ...state, selectedCategory: category })),
  clear: () => set(() => IN_APP_INITIAL_STATE),
});

export const useInAppSlice = create<InAppSlice>((set) => ({
  ...createInAppSlice(set),
}));

if (process.env.NODE_ENV === 'development') {
  mountStoreDevtool('Store', useInAppSlice);
}

export function useInApp<T>(selector: (state: InAppSlice) => T): T {
  return useStore(useInAppSlice, useShallow(selector));
}
