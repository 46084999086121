import { api } from '~/services/api';

import { FEATURED_ACTIVITY_DEFINITION_MODULE_URL } from './constants';
import { FeaturedActivityDefinitionResponse } from './types';

export class FeaturedActivityDefinitionService {
  static BASE_URL = `${FEATURED_ACTIVITY_DEFINITION_MODULE_URL}/ecosystems`;

  static async findByEcosystem(
    ecosystemId: string,
  ): Promise<FeaturedActivityDefinitionResponse> {
    try {
      const url = `${FeaturedActivityDefinitionService.BASE_URL}/${ecosystemId}`;
      const { data } = await api.get(url);
      return data || null;
    } catch (error) {
      return null;
    }
  }
}
