import { useMemo } from 'react';

import { MakeFooterLocationNode, MakeTeamSizeTextNode } from '../utils';
import { TeamEventActivityCardProps } from './types';

export const useEventActivityCardController = ({
  isOnline,
  isFromHome,
  distance,
  teamMaxSize,
  teamMinSize,
}: TeamEventActivityCardProps) => {
  const footerNodes = useMemo(() => {
    const nodes = [MakeTeamSizeTextNode(teamMinSize, teamMaxSize)];
    const location = MakeFooterLocationNode(distance, isFromHome, isOnline);

    if (location) {
      nodes.unshift(location);
    }
    return nodes;
  }, [isOnline, isFromHome, distance, teamMinSize, teamMaxSize]);

  return { footerNodes };
};
