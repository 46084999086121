import {
  Box,
  Checkbox,
  Flex,
  HStack,
  Link,
  Stack,
  Text,
  useMediaQuery,
  VStack,
} from 'native-base';
import { Controller } from 'react-hook-form';
import { FiUser } from 'react-icons/fi';
import { ILLUSTRATIONS_NAME } from 'ui/components/Illustration';
import Input from 'ui/components/Input';
import { transformInputValueToLowerCase } from 'ui/utils/formatter';

import ModalPrivacyPolicy from '~/components/ModalPrivacyPolicy';
import ModalTermsOfService from '~/components/ModalTermsOfService';
import FlowTwoColumnsTemplate from '~/components/Templates/FlowTwoColumnsTemplate';
import { useSignUpController } from '~/pages/Unauthenticated/SignUp/CreateAccount/useSignUpController';
import { colors } from '~/theme/colors';

import { styles } from './styles';

const CreateAccount = () => {
  const {
    control,
    errors,
    onSubmit,
    isLoading,
    redirectToSignIn,
    termsModalRef,
    privacyModalRef,
    handleOpenPrivacyModal,
    handleOpenTermsModal,
  } = useSignUpController();
  const [isMobile] = useMediaQuery({
    maxWidth: 480,
  });

  return (
    <Flex minH="full" flexShrink={0} bgColor="gray.100">
      <FlowTwoColumnsTemplate
        ilustrationName={ILLUSTRATIONS_NAME.POINTER_UP}
        title="Hello there! Let’s create your account."
        subtitle="To begin, please provide some information and create a password"
        icon={<FiUser size={32} />}
        primaryButtonText="Continue"
        secondaryButtonText="I already have an account"
        primaryButtonOnPress={onSubmit}
        primaryButtonVariant="outline"
        secondaryButtonOnPress={redirectToSignIn}
        isLoading={isLoading}
        progress={40}
      >
        <Stack space="4" w="full" px={6} py={styles.paddingY}>
          <HStack space={3}>
            <Box flex="1">
              <Input
                name="firstName"
                control={control}
                placeholder={!isMobile ? 'Type your first name' : 'First Name'}
                label={!isMobile ? 'First Name' : ''}
                isRequired
                isFullWidth
                errorMessage={errors.firstName?.message}
                iconErrorMessage="info"
                leftIconColor={colors.muted[400]}
              />
            </Box>
            <Box flex="1">
              <Input
                isFullWidth
                name="lastName"
                control={control}
                placeholder={!isMobile ? 'Type your last name' : 'Last Name'}
                type="text"
                label={!isMobile ? 'Last Name' : ''}
                leftIconColor={colors.muted[400]}
                isRequired
                errorMessage={errors.lastName?.message}
                iconErrorMessage="info"
              />
            </Box>
          </HStack>

          <HStack space={3}>
            <Box flex="1">
              <Input
                name="email"
                control={control}
                placeholder={
                  !isMobile ? 'Type your your email address' : 'Email address'
                }
                label={!isMobile ? 'Email address' : ''}
                isRequired
                errorMessage={errors.email?.message}
                iconErrorMessage="info"
                leftIconName="mail"
                leftIconColor={colors.muted[400]}
                onChangeTransform={transformInputValueToLowerCase}
              />
            </Box>
          </HStack>

          <Input
            name="password"
            control={control}
            placeholder={!isMobile ? 'Create a password' : 'Password'}
            type="password"
            label={!isMobile ? 'Password' : ''}
            leftIconName="lock"
            leftIconColor={colors.muted[400]}
            isRequired
            errorMessage={errors.password?.message}
            iconErrorMessage="info"
          />

          <Input
            name="confirmPassword"
            control={control}
            placeholder="Cofirm password"
            type="password"
            label={!isMobile ? 'Confirm Password' : ''}
            leftIconName="lock"
            leftIconColor={colors.muted[400]}
            isRequired
            errorMessage={errors.confirmPassword?.message}
            iconErrorMessage="info"
            showPasswordRules={true}
          />

          <HStack space={4} alignItems="center" mt={12}>
            <Controller
              control={control}
              name="termsOfService"
              render={({ field: { onChange, value } }) => (
                <VStack>
                  <HStack alignItems="center" space="3" mt="10">
                    <Checkbox
                      id={'termsOfService'}
                      onChange={onChange}
                      name="termsOfService"
                      value="true"
                      isChecked={value}
                    >
                      <Text fontSize={14} fontWeight="400" width="100%">
                        I have read and accept the{' '}
                        <Link
                          color="darkText"
                          onPress={() => {
                            // TODO: Think of a better solution to avoid checking the checkbox when clicking on the terms or privacy policy.
                            onChange('true');
                            handleOpenTermsModal();
                          }}
                        >
                          Terms of service
                        </Link>{' '}
                        and{' '}
                        <Link
                          color="darkText"
                          onPress={() => {
                            // TODO: Think of a better solution to avoid checking the checkbox when clicking on the terms or privacy policy.
                            onChange('true');
                            handleOpenPrivacyModal();
                          }}
                        >
                          Privacy policy.
                        </Link>
                      </Text>
                    </Checkbox>
                  </HStack>
                  {errors.termsOfService?.message ? (
                    <Text color="error.600" fontSize={12} mt={1}>
                      {errors.termsOfService?.message}
                    </Text>
                  ) : null}
                </VStack>
              )}
            />
          </HStack>
        </Stack>
      </FlowTwoColumnsTemplate>
      <ModalPrivacyPolicy modalRef={privacyModalRef} />
      <ModalTermsOfService modalRef={termsModalRef} />
    </Flex>
  );
};

export default CreateAccount;
