import { SearchCategory } from 'ui/types/interfaces/search/types';

import { SearchFilters } from '~/zustand/store/Search';

/* Search categories  */
export enum ActivitySearchCategoryEnum {
  events = 'events',
  actions = 'actions',
  ongoing = 'ongoing',
  teamEvents = 'team-events',
  nearMyLocation = 'near-my-location',
  remoteOpportunities = 'remote-opportunities',
}

export type ActivitySearchCategoryKey = ActivitySearchCategoryEnum | string;

export class ActivitySearchCategory extends SearchCategory<
  ActivitySearchCategoryKey,
  SearchFilters
> {}

/* Search collections */
export enum SearchCollectionSlug {
  Events = 'events',
  Actions = 'actions',
  Ongoing = 'ongoing',
  TeamEvents = 'team-events',
  RemoteOngoing = 'remote-ongoing',
  EventsFromHome = 'events-from-home',
}

export type SearchCollectionConfig = {
  slug: SearchCollectionSlug;
  label: string;
  filters: Partial<SearchFilters>;
  title: {
    large: string;
    small?: string;
  };
  subTitle: {
    large: string;
    small?: string;
  };
};
