import { HStack, Pressable, Text, VStack } from 'native-base';
import Icon from 'ui/components/Icon';
import { colors } from 'ui/theme/colors';

export interface VerboseTabProps {
  label: string;
  icon: string;
  subtitle: string;
  isActive?: boolean;
  borderRadius?: number;
  backGroundColor?: string;
  activeBackGroundColor?: string;
  activeFontColor?: string;
  onPress?: () => void;
}

export const VerboseTab = (props: VerboseTabProps) => {
  const {
    icon,
    label,
    subtitle,
    isActive,
    backGroundColor = 'transparent',
    activeFontColor = colors.singletons.white,
    activeBackGroundColor = colors.singletons.black,
    borderRadius,
    onPress,
  } = props;

  return (
    <Pressable flex={1} onPress={onPress} w={'100%'}>
      <HStack
        bg={isActive ? 'singletons.white' : 'transparent'}
        borderRadius={borderRadius}
        borderBottomColor={isActive ? 'singletons.black' : backGroundColor}
        backgroundColor={isActive ? activeBackGroundColor : backGroundColor}
        alignItems={'center'}
        space={3}
        py={4}
        px={4}
      >
        <Icon
          icon={icon}
          color={isActive ? activeFontColor : colors.gray[400]}
          size={23}
        />

        <VStack
          flex={1}
          overflow={'hidden'}
          alignItems={{
            base: 'flex-start',
            md: 'flex-start',
          }}
          justifyContent={'center'}
        >
          <Text
            fontSize={{ base: 'sm', md: 'md' }}
            fontWeight={500}
            color={isActive ? activeFontColor : colors.gray[500]}
          >
            {label}
          </Text>

          <Text
            fontSize={'sm'}
            fontWeight={400}
            color={isActive ? activeFontColor : colors.gray[600]}
          >
            {subtitle}
          </Text>
        </VStack>
      </HStack>
    </Pressable>
  );
};

export default VerboseTab;
