import { useBreakpointValue } from 'native-base';
import { useState } from 'react';

interface LogActivityCardControllerParams {
  isExpandable?: boolean;
}

export const useLogActivityCardController = ({
  isExpandable,
}: LogActivityCardControllerParams) => {
  const [isExpanded, setIsExpanded] = useState(false);

  // TODO: Fix this breakpoint hidearrow logic, its very acopladed to the component
  // this value should come from the component, not of the window size.
  const breakpointValue = useBreakpointValue({
    lg: {
      hideArrow: true,
      contentWidth: 'full',
    },
    base: {
      hideArrow: false,
      contentWidth: 'calc(100% - 2rem)',
    },
  });

  const toggleIsExpanded = () => {
    if (!isExpandable) return;

    setIsExpanded((prev) => !prev);
  };

  return { breakpointValue, toggleIsExpanded, isExpanded };
};
