import { Text, VStack } from 'native-base';
import React from 'react';
import Tooltip from 'ui/components/v2/Tooltip';

import { CalendarDayStyleMap } from '~/components/CalendarInline/components/CalendarDay/constants';
import { CalendarDayState } from '~/components/CalendarInline/components/CalendarDay/types';

export type CalendarDayProps = {
  dayOfMonth: number;
  tooltipMessage?: string;
};
/* NOTE: TODAY IT RENDER ONLY BLOCKED DATES */
export const CalendarDay = (props: CalendarDayProps) => {
  const { dayOfMonth, tooltipMessage } = props;

  const style = CalendarDayStyleMap.get(CalendarDayState.blocked);
  const tooltipLabel = tooltipMessage || style?.tooltipLabel || '';

  return (
    <span>
      <Tooltip label={tooltipLabel} topOffSet={34}>
        <VStack
          w="40px"
          h="40px"
          alignItems={'center'}
          justifyContent={'center'}
          borderRadius={6}
          borderColor={style?.borderColor}
          backgroundColor={style?.backgroundColor}
        >
          <Text color={style?.fontColor} fontSize={'md'} mb={1}>
            {dayOfMonth}
          </Text>
        </VStack>
      </Tooltip>
    </span>
  );
};

export default React.memo(CalendarDay);
