import { EEventApplicationType } from 'ui/enums';
import { ActivityType } from 'ui/types/activities';

import { IUserActivity } from '~/pages/Authenticated/v2/ActivityView/types';
import { IActivityDefinition } from '~/types/interfaces/activity';
import {
  AvailabilityMessageStyle,
  AvailabilityResult,
  Available,
  Unavailable,
} from '~/utils/activityAvailability/types';

const getAvailableResult = (activity: IUserActivity) => {
  const { volunteerNumber = 0, bookingsNumber = 0 } = activity;

  if (activity.isVolunteerNumberLimited === true && volunteerNumber > 0) {
    if (bookingsNumber >= volunteerNumber / 2) {
      return new Available(
        'Last spots available',
        AvailabilityMessageStyle.WARNING,
      );
    }
  }

  if (bookingsNumber === 0) {
    return new Available('Be the first one to book!');
  }

  return new Available('Spots available');
};

export const getApplicationAvailability = (
  activityDefinition?: IActivityDefinition,
  activity?: IUserActivity,
  selectedTeamId?: string,
): AvailabilityResult => {
  if (!activity || !activityDefinition)
    return new Unavailable('Select an Activity to continue');

  if (!!activity.userApplication)
    return new Unavailable('You have already applied to this activity.');

  if (!activityDefinition?.app)
    return new Unavailable('This activity is no longer available');

  const CURRENT_DATE = new Date().toISOString();

  const type = activityDefinition.type;

  const { volunteerNumber = 0, bookingsNumber = 0 } = activity;

  // There is no restriction to 'Actions';
  if (type === ActivityType.Action) {
    return new Available('Be the first one to join!');
  }

  // When due date is expired;
  if (new Date(activity.dueDate as Date) < new Date(CURRENT_DATE)) {
    return new Unavailable('Applications are closed.');
  }

  // When volunteers limit was exceeded
  if (activity.isVolunteerNumberLimited === true && volunteerNumber > 0) {
    if (bookingsNumber >= volunteerNumber) {
      return new Unavailable('Volunteer limit exceeded.');
    }
  }

  if (type === ActivityType.Event) {
    const isTeamEvent =
      activityDefinition.eventApplicationType === EEventApplicationType.Team;

    if (isTeamEvent && !selectedTeamId) {
      return new Unavailable('You must select a team to continue.');
    }
  }

  return getAvailableResult(activity);
};
