import { Hidden, HStack, Pressable, Text } from 'native-base';
import Illustration, { ILLUSTRATIONS_NAME } from 'ui/components/Illustration';
import { colors } from 'ui/theme/colors';

const desktopText =
  "Don't forget to log your volunteering\nhours and track your impact!";
const mobileText =
  "Don't forget to log your volunteering hours and track your impact!";

export const VolunteeringHoursPlaceHolderSnippet = ({
  onPress,
}: {
  onPress?: VoidFunction;
}) => {
  return (
    <Pressable
      w="full"
      h="full"
      flex={1}
      alignItems={'center'}
      justifyContent="space-between"
      onPress={onPress}
    >
      <HStack
        w="full"
        h="full"
        flex={1}
        alignItems={'center'}
        justifyContent="space-between"
        space={3}
      >
        <Text
          flex={1}
          fontWeight={400}
          lineHeight={'150%'}
          color={colors.gray[800]}
          fontSize={'xs'}
          numberOfLines={3}
        >
          <Hidden till="md">
            <>{desktopText}</>
          </Hidden>

          <Hidden from="md">
            <>{mobileText}</>
          </Hidden>
        </Text>

        <Hidden till="md">
          <Illustration
            name={ILLUSTRATIONS_NAME.CLOCK}
            resizeMode="contain"
            h={'64px'}
            w={'64px'}
          />
        </Hidden>
      </HStack>
    </Pressable>
  );
};
