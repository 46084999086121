import { Box, Hidden, VStack } from 'native-base';

import { RootPortal } from '~/components/RootPortal';
import MobileTrayDrawer from '~/components/TrayDrawer';
import ActivityApplicationFooter from '~/pages/Authenticated/v2/ActivityView/components/ActivityApplicationFooter';
import { ActivityPanel } from '~/pages/Authenticated/v2/ActivityView/components/ActivityPanel';
import ActivityLocationSearch from '~/pages/Authenticated/v2/ActivityView/components/ActivitySearch/ActivityLocationSearch';
import ActivityLocationSelector from '~/pages/Authenticated/v2/ActivityView/components/ActivitySelectors/ActivityLocationSelector';
import { useOngoingActivityApplicationController } from '~/pages/Authenticated/v2/ActivityView/views/ActivityApplication/components/OngoingApplicationPanel/controller';
import { OngoingApplicationPanelProps } from '~/pages/Authenticated/v2/ActivityView/views/ActivityApplication/components/OngoingApplicationPanel/types';

export const OngoingApplicationPanel: React.FC<OngoingApplicationPanelProps> = (
  props,
) => {
  const { showGoBackButton, onGoBackPress } = props;

  const {
    title,
    filters,
    isMobile,
    isFromHome,
    actionLabel,
    headerStyle,
    selectedActivity,
    showLocationDrawer,
    availableLocations,
    applicationAvailability,
    spotsAvailable,
    handleApplication,
    handleNewLocation,
    openMobileLocationDrawer,
    closeMobileLocationDrawer,
  } = useOngoingActivityApplicationController();

  return (
    <>
      <ActivityPanel
        title={title}
        titleColor={headerStyle!.fontColor}
        headerBackground={headerStyle!.backgroundColor}
      >
        <ActivityLocationSelector
          isFromHome={isFromHome}
          availableLocations={availableLocations}
          selectedLocation={filters.location}
          onLocationChange={handleNewLocation}
          onOpen={isMobile ? openMobileLocationDrawer : undefined}
          availableSpotsCount={spotsAvailable}
          showAvailableSpots
        />

        <ActivityApplicationFooter
          isActionDisabled={!applicationAvailability.isAvailable}
          tooltipMessage={applicationAvailability.message}
          actionLabel={actionLabel}
          onAction={handleApplication}
          onCancel={showGoBackButton ? onGoBackPress : undefined}
        />
      </ActivityPanel>

      <RootPortal>
        <Hidden from="lg">
          <MobileTrayDrawer
            title=""
            isOpen={showLocationDrawer}
            onClose={closeMobileLocationDrawer}
            size={462}
          >
            <VStack alignItems={'center'}>
              <Box w={'full'} maxW={'768px'}>
                <ActivityLocationSearch
                  locations={availableLocations}
                  selectedLocation={filters.location}
                  onLocationChange={handleNewLocation}
                  key={selectedActivity?._id}
                />
              </Box>
            </VStack>
          </MobileTrayDrawer>
        </Hidden>
      </RootPortal>
    </>
  );
};

export default OngoingApplicationPanel;
