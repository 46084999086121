import { FormControl, HStack, Text, VStack } from 'native-base';

import { CommonInputWrapperProps } from './types';

const CommonInputWrapper = (props: CommonInputWrapperProps) => {
  const { errorMessage, label, helpText, isRequired, children } = props;

  return (
    <VStack w="100%">
      {label && (
        <FormControl.Label margin={0}>
          {label}
          {isRequired ? <Text color="error.600">*</Text> : null}
        </FormControl.Label>
      )}

      <HStack
        flex={1}
        width="100%"
        alignContent="center"
        justifyContent="center"
        zIndex={9999}
      >
        {children}
      </HStack>

      {helpText && !errorMessage && (
        <Text
          mt={2}
          ml={2}
          display={{ base: 'none', md: 'flex' }}
          fontWeight={400}
          fontSize="xs"
          color="muted.500"
        >
          {helpText}
        </Text>
      )}

      {errorMessage && (
        <Text fontWeight={400} fontSize="xs" color="error.500" mt="1" ml="2">
          {errorMessage}
        </Text>
      )}
    </VStack>
  );
};

export default CommonInputWrapper;
