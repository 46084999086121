import { ReactNode } from 'react';
import ReactDOM from 'react-dom';

export type RootPortalProps = {
  portalId?: string;
  children: ReactNode | any;
};

export const RootPortal = (props: RootPortalProps) => {
  const { portalId = 'modal-root', children } = props;
  return (
    <>
      {ReactDOM.createPortal(
        children,
        document.getElementById(portalId) as any,
      )}
    </>
  );
};
