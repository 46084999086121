import { Avatar, Box, HStack, Pressable, Text, VStack } from 'native-base';
import { getAvatarText } from 'ui/utils/getAvatarText';

import { AppCardProps } from '~/pages/Authenticated/UserApps/components/AppCard/types';

export const AppCard = ({ app, onPress }: AppCardProps) => {
  return (
    <HStack
      w="full"
      justifyContent="flex-start"
      alignItems="center"
      h="fit-content"
      overflow={'hidden'}
      my={{ base: 0, md: 2 }}
      p={0}
    >
      <Pressable onPress={onPress} flex={1} p={2}>
        <VStack alignItems="center" space={2}>
          <Box
            overflow={'hidden'}
            h="96px"
            w="96px"
            borderWidth={1}
            borderColor="gray.200"
            borderRadius="xl"
          >
            <Avatar
              h="full"
              w="full"
              bgColor="muted.100"
              _text={{ color: 'darkText' }}
              source={{ uri: app.logo || '' }}
              _image={{ borderRadius: 0 }}
              borderRadius={0}
            >
              {getAvatarText(app?.name || '')}
            </Avatar>
          </Box>

          <Text
            noOfLines={2}
            textAlign={'center'}
            fontSize="sm"
            color="singletons.darkText"
            fontWeight="500"
            height={'3em'}
          >
            {app.name}
          </Text>
        </VStack>
      </Pressable>
    </HStack>
  );
};
