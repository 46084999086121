import { Box, HStack, Pressable, Stack, Text } from 'native-base';
import React from 'react';
import Icon from 'ui/components/Icon';
import { colors } from 'ui/theme/colors';

import { useBreadCrumbsController } from '~/components/Breadcrumbs/controller';
import { BreadCrumbsProps } from '~/components/Breadcrumbs/types';

export const BreadCrumbs: React.FunctionComponent<BreadCrumbsProps> = (
  props: BreadCrumbsProps,
) => {
  const { handlePress, breadCrumbsStack, isStackEmpty, showBackButton } =
    useBreadCrumbsController(props);

  return (
    <Box py={2}>
      <HStack alignItems={'center'}>
        {!isStackEmpty && showBackButton ? (
          <Pressable onPress={() => handlePress('-1')}>
            <Stack direction="row" alignItems={'center'} space={2}>
              <Text fontSize={'xs'} fontWeight={500} color={colors.gray[500]}>
                Back
              </Text>
              <Text fontSize={12} fontWeight={500} color={colors.gray[500]}>
                |
              </Text>
            </Stack>
          </Pressable>
        ) : null}

        {breadCrumbsStack.map((b, i) => {
          const isLastOne = i === breadCrumbsStack.length - 1;
          return (
            <HStack key={i} alignItems={'center'}>
              <Pressable onPress={() => handlePress(b.path || '')}>
                <HStack alignItems={'center'} space={2} px={2}>
                  {b.icon && (
                    <Icon
                      icon={b.icon}
                      size={b.iconSize || '16px'}
                      enableAutoCustom
                      color={isLastOne ? '#151518' : colors.gray[500]}
                    />
                  )}
                  <Text
                    fontSize={'xs'}
                    fontWeight={500}
                    numberOfLines={1}
                    maxW={{ base: '180px', md: '480px' }}
                    color={isLastOne ? '#151518' : colors.gray[500]}
                  >
                    {b.label}
                  </Text>
                </HStack>
              </Pressable>

              {i < breadCrumbsStack.length - 1 && (
                <Box mx={0}>
                  <Icon
                    size={12}
                    icon="chevron-right"
                    color={colors.gray[500]}
                  />
                </Box>
              )}
            </HStack>
          );
        })}
      </HStack>
    </Box>
  );
};

export default BreadCrumbs;
