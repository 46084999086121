import { Center } from 'native-base';
import React from 'react';

import { BookingCardActionContentRootProps } from './booking-card-action-content-root.types';

export const BookingCardActionContentRoot = ({
  children,
}: BookingCardActionContentRootProps) => {
  return (
    <Center w={'full'} p={3.5} py={6}>
      {children}
    </Center>
  );
};
