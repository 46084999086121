import { AppSwitcherOption } from 'ui/components/v2/AppInputs/AppSwitcher/types';
import { App } from 'ui/types/interfaces/app';

export const mapAppToSwitcherOption = (
  app: App | null,
): AppSwitcherOption | null => {
  if (!app) return null;

  return { value: app._id, label: app.name || 'Unnamed', logo: app.logo || '' };
};
