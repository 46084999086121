import { QueryFunction, useInfiniteQuery } from 'react-query';
import { sanitizeQueryParams } from 'ui/utils/queryParams';

import { api } from '~/services/api';

/* This is a generic adapter fo react query useInfiniteQuery */

const DEFAULT_ITEMS_PER_PAGE = 16;

export type PaginationParams = {
  skip: number;
  limit: number;
};
export type UseInfinityLoadProps = {
  url?: string;
  queryKey: string;
  customQueryFunction?: (p: PaginationParams) => Promise<unknown[]>;
  disabled?: boolean;
  props?: Record<string, unknown>;
  itemsPerPage?: number;
};

export default function useInfinityLoad({
  url,
  queryKey,
  props = {},
  disabled = false,
  customQueryFunction,
  itemsPerPage = DEFAULT_ITEMS_PER_PAGE,
}: UseInfinityLoadProps) {
  const handleQueryFunction: QueryFunction = async ({ pageParam = 0 }) => {
    if (disabled) return { responseData: [], skip: 0 };

    const paginationParams: PaginationParams = {
      skip: pageParam,
      limit: itemsPerPage,
    };

    let responseData;

    if (customQueryFunction) {
      responseData = await customQueryFunction(paginationParams);
    } else if (url) {
      const params = sanitizeQueryParams(props, paginationParams);
      const { data } = await api.get(url, { params });
      responseData = data;
    }

    return {
      responseData,
      skip: pageParam + itemsPerPage,
    };
  };

  return useInfiniteQuery([queryKey, props], handleQueryFunction, {
    getNextPageParam: (lastPage: any) => {
      if ((lastPage.responseData ?? []).length < itemsPerPage) return undefined;
      return lastPage.skip;
    },
  });
}
