import * as Yup from 'yup';

export const rulesKeys = {
  noLeadingOrTrailingSpace: 'No leading/trailing spaces',
  haveAtLeastOneNumber: 'At least 1 number',
  haveAtLeastOneUppercase: 'At least 1 uppercase letter',
  haveAtLeastOneLowercase: 'At least 1 lowercase letter',
  haveAtLeastOneSpecialChar: `At least 1 special character from the list: \[ \] { } ( ) ? " ! @ # % & / \\ , > < ' : ; | _ ~ \` = + \-`,
};

export const rulesArray = [
  rulesKeys.noLeadingOrTrailingSpace,
  rulesKeys.haveAtLeastOneNumber,
  rulesKeys.haveAtLeastOneUppercase,
  rulesKeys.haveAtLeastOneLowercase,
  rulesKeys.haveAtLeastOneSpecialChar,
];

const yupString = Yup.string();

export const passwordRules = {
  [rulesKeys.noLeadingOrTrailingSpace]: yupString.matches(/^\S(.*\S)?$/),
  [rulesKeys.haveAtLeastOneNumber]: yupString.matches(/[0-9]/),
  [rulesKeys.haveAtLeastOneUppercase]: yupString.matches(/[A-Z]/),
  [rulesKeys.haveAtLeastOneLowercase]: yupString.matches(/[a-z]/),
  [rulesKeys.haveAtLeastOneSpecialChar]: yupString.matches(
    /[\[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]/,
  ),
};
