import { PAGES } from '~/constants/pages.constants';
import { useRouter } from '~/hooks/useRouter';

export const useAccountDeletedController = () => {
  const { replaceRoute, goToRoute } = useRouter();

  const handleGoToSignUp = () => {
    replaceRoute(PAGES.Root);
    goToRoute(PAGES.SignUp);
  };

  const handleGoToHome = () => {
    replaceRoute(PAGES.Root);
    goToRoute(PAGES.GuestOpportunities);
  };

  return { handleGoToSignUp, handleGoToHome };
};
