import { HStack, Stack, Text } from 'native-base';
import Icon from 'ui/components/Icon';
import { colors } from 'ui/theme/colors';

import { SearchOptionItemProps } from './types';

export const SearchOptionItem = (props: SearchOptionItemProps) => {
  const { label, icon, highlight = false } = props;

  return (
    <HStack px={0} py={3} w="full" alignItems={'center'}>
      {icon && (
        <Stack mr={3}>
          <Icon icon={icon} size={16} color={colors.gray[400]} />{' '}
        </Stack>
      )}
      <Text
        fontSize={'sm'}
        lineHeight={'150%'}
        fontWeight={!highlight ? 400 : 500}
        color={colors.darkText}
        numberOfLines={1}
      >
        {label}
      </Text>
    </HStack>
  );
};

export default SearchOptionItem;
