import { COMMON_MESSAGES } from '~/constants/messages.constants';
import Yup from '~/utils/validations/yup';

import { ISignUpFormFields } from './types';

export const defaultValues: ISignUpFormFields = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  confirmPassword: '',
  termsOfService: false,
};

export const schemaValidation = Yup.object({
  firstName: Yup.string().required(),
  lastName: Yup.string().required(),
  email: Yup.string()
    .transform((value: string) => value?.trim() || '')
    .email()
    .required(),
  password: Yup.string().password().required(),
  confirmPassword: Yup.string().test(
    'password',
    'Passwords must match',
    function (value) {
      return this.parent.password === value;
    },
  ),
  termsOfService: Yup.boolean()
    .required()
    .oneOf([true], COMMON_MESSAGES.ACCEPT_TERMS),
});
