import { HStack, Pressable, Text } from 'native-base';

import { colors } from '../../../theme/colors';
import Icon from '../../Icon';
import { SugestionObject } from '../types';

export const SuggestionItem = (props: {
  data: SugestionObject;
  onPress: (data: SugestionObject) => void;
  highlight?: boolean;
}) => {
  const { data, onPress, highlight = false } = props;
  return (
    <Pressable w="full" onPress={() => onPress(data)}>
      <HStack alignItems={'center'} space={3}>
        {data?.icon && (
          <Icon icon={data.icon} size={16} color={colors.gray[400]} />
        )}
        <Text
          py={3}
          fontSize={'sm'}
          lineHeight={'150%'}
          fontWeight={!highlight ? 400 : 500}
          color={colors.darkText}
          numberOfLines={3}
        >
          {data.description}
        </Text>
      </HStack>
    </Pressable>
  );
};

export default SuggestionItem;
