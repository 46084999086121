import { Avatar, HStack, Text } from 'native-base';
import Icon from 'ui/components/Icon';
import DropDownBase from 'ui/components/v2/DropDown/Base';
import { colors } from 'ui/theme/colors';

import ModalSignOut from '~/components/ModalSignOut';
import { useAccountDropdownController } from '~/components/NavBar/components/AccountDropdown/controller';
import { AccountDropDownMenu } from '~/components/NavBar/components/AccountDropdown/Menu';
import { RootPortal } from '~/components/RootPortal';

export const AccountDropdown = () => {
  const {
    modalRef,
    accountData,
    optionGroups,
    dropDownImperativeRef,
    showSwitchPersonaButton,
    handleOptionPress,
    handleCloseSignOutModal,
    handleSwitchPersonaPress,
  } = useAccountDropdownController();

  return (
    <>
      <DropDownBase
        ref={dropDownImperativeRef}
        popoverMaxH={'unset'}
        popoverBorderRadius={12}
        popoverTop={4}
        overlayContent={
          <AccountDropDownMenu
            accountData={accountData}
            optionGroups={optionGroups}
            onPress={handleOptionPress}
            onSwitchPersonaPress={handleSwitchPersonaPress}
            showSwitchPersonaButton={showSwitchPersonaButton}
          />
        }
      >
        <HStack
          px={2}
          py={2}
          pr={3}
          space={2}
          borderWidth={1}
          borderRadius={'24px'}
          alignItems={'center'}
          borderColor={colors.gray[200]}
        >
          <Avatar
            w={'32px'}
            h={'32px '}
            borderRadius={'full'}
            source={{ uri: accountData?.userAvatarUrl }}
          >
            {accountData?.userNameInitials}
          </Avatar>
          <Text fontWeight={500} fontSize={'sm'} marginRight={1}>
            My account
          </Text>
          <Icon icon="chevron-down" size={'16px'} color={colors.gray[500]} />
        </HStack>
      </DropDownBase>

      <RootPortal>
        <ModalSignOut modalRef={modalRef} onClose={handleCloseSignOutModal} />
      </RootPortal>
    </>
  );
};
