import { Box, HStack, Modal as NBModal, Text } from 'native-base';
import { forwardRef } from 'react';
import Button from 'ui/components/Button';
import Icon from 'ui/components/Icon';

import useModalController from './controller';
import { styles } from './styles';
import { ModalProps } from './types';

// NB Modal was not working with <React.StrictMode>
const Modal = forwardRef(function Modal(props: ModalProps, ref) {
  const {
    selectedItemId,
    children,
    headerText,
    headerTextColor,
    headerFontSize,
    leftBtnAction,
    leftBtnText,
    leftVariant,
    lefttBtnBgColor,
    leftBtnProps,
    leftBtnTextColor,
    rightBtnAction,
    rightBtnText,
    rightVariant,
    rightBtnBgColor,
    rightBtnProps,
    rightBtnTextColor,
    rightBtnWidth,
    btnFontWeight,
    btnFontSize,
    modalContentMaxW,
    modalContentMaxH,
    modalContentW,
    modalContentH,
    modalBgColor,
    showModal,
    handleClose,
    modalRef,
    showGroupButton,
    hideFooter,
    placement,
    rightBtnLoading,
    hideCloseBtn,
    bodyPadding = '16px',
    headerIconName,
    isHeaderIconCustom,
    modalContentProps,
    topOffset = 0,
    ...restModalProps
  } = useModalController(props, ref);

  const RightButton = () => (
    <Button
      onPress={() => !!rightBtnAction && rightBtnAction(selectedItemId)}
      variant={rightVariant}
      bgColor={rightBtnBgColor}
      isLoading={rightBtnLoading}
      {...rightBtnProps}
      w="100%"
      rounded={12}
    >
      <Text
        color={rightBtnTextColor}
        fontWeight={btnFontWeight}
        fontSize={{ base: 'sm', lg: 'md' }}
      >
        {rightBtnText}
      </Text>
    </Button>
  );

  const GroupButtons = () => (
    <Box w="full">
      <HStack justifyContent={'center'} space="2">
        <Box w="1/2">
          <Button
            onPress={leftBtnAction}
            variant={leftVariant}
            bgColor={lefttBtnBgColor}
            {...leftBtnProps}
          >
            <Text color={leftBtnTextColor} fontSize={{ base: 'sm', lg: 'md' }}>
              {leftBtnText}
            </Text>
          </Button>
        </Box>
        <Box w="1/2">
          <RightButton />
        </Box>
      </HStack>
    </Box>
  );

  const Footer = () => (
    <NBModal.Footer borderTopColor={'transparent'}>
      {showGroupButton ? (
        <GroupButtons />
      ) : (
        <HStack w={rightBtnWidth}>
          <RightButton />
        </HStack>
      )}
    </NBModal.Footer>
  );

  return (
    <NBModal
      isOpen={showModal}
      onClose={handleClose}
      ref={modalRef}
      maxH="100vh"
      height="100%"
      position="sticky"
      top={topOffset}
      {...restModalProps}
    >
      <NBModal.Content
        maxW={modalContentMaxW}
        maxH={modalContentMaxH}
        w={modalContentW}
        h={modalContentH}
        bgColor={modalBgColor}
        {...styles[placement]}
        {...modalContentProps}
      >
        {hideCloseBtn ? null : (
          <NBModal.CloseButton marginTop={'6px'} marginRight={'4px'} />
        )}
        {headerText ? (
          <>
            {headerIconName ? (
              <NBModal.Header bgColor={modalBgColor} flexShrink={0}>
                <HStack
                  w="full"
                  space={3}
                  alignItems="baseline"
                  color={headerTextColor}
                >
                  <Icon
                    icon={headerIconName}
                    color={headerTextColor}
                    size={headerFontSize as string}
                    isCustom={isHeaderIconCustom}
                  />
                  <Text
                    color={headerTextColor}
                    fontWeight={btnFontWeight}
                    fontSize={headerFontSize}
                  >
                    {headerText}
                  </Text>
                </HStack>
              </NBModal.Header>
            ) : (
              <NBModal.Header
                bgColor={modalBgColor}
                borderBottomColor={{ base: 'transparent', lg: 'gray.200' }}
                flexShrink={0}
              >
                <Text
                  color={headerTextColor}
                  fontWeight={btnFontWeight}
                  fontSize={headerFontSize}
                >
                  {headerText}
                </Text>
              </NBModal.Header>
            )}
          </>
        ) : null}
        <NBModal.Body padding={bodyPadding} pt={0}>
          {children}
        </NBModal.Body>
        {hideFooter ? null : <Footer />}
      </NBModal.Content>
    </NBModal>
  );
});

export default Modal;
