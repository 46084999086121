import { Text } from 'native-base';
import React from 'react';

import { BookingCardActionContentTitleProps } from './booking-card-action-content-title.types';

export const BookingCardActionContentTitle = ({
  text,
}: BookingCardActionContentTitleProps) => {
  return <Text fontSize="md">{text}</Text>;
};
