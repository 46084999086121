import { ILocation } from 'ui/types/interfaces';

import { IAddress } from '~/types/interfaces/activity';
import { getCoordinatesFromAddress } from '~/utils/getCoordinates';

export function getLocationFromAddress(address: IAddress): ILocation {
  return {
    ...getCoordinatesFromAddress(address),
    rawLocation: address?.street || '',
  };
}

export function isLocationEqual(locA: ILocation, locB: ILocation): boolean {
  return JSON.stringify(locA) === JSON.stringify(locB);
}

export function getAddressFromLocation(location?: ILocation): IAddress | null {
  if (!location) return null;
  return {
    location: {
      type: 'Point',
      coordinates: [location?.lng, location?.lat],
    },
    street: location?.rawLocation,
  };
}
