import { IconProps } from 'react-toastify';
import Icon from 'ui/components/Icon';

import { colors } from '~/theme/colors';

export const contextClass = {
  success: colors.success[100],
  error: colors.error[100],
  info: 'bg-gray-600',
  warning: 'bg-orange-400',
  default: 'bg-indigo-600',
  dark: 'bg-white-600 font-gray-300',
};

export const renderToastItem = ({ type }: IconProps) => {
  switch (type) {
    case 'success':
      return <Icon icon="check-circle" color={colors.success[700]} size={20} />;
    case 'error':
      return <Icon icon="x-circle" color={colors.error[700]} size={20} />;
    case 'warning':
      return <Icon icon="alert-circle" color={colors.warning[700]} size={20} />;
    case 'info':
      return <Icon icon="info" color={colors.info[700]} size={20} />;
    default:
      return null;
  }
};
