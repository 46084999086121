import { Box, Button, Switch, Text } from 'native-base';
import React from 'react';
import Icon from 'ui/components/Icon';
import Tooltip from 'ui/components/v2/Tooltip';
import { colors } from 'ui/theme/colors';

import { useAppDispatch } from '~/hooks/useAppDispatch';
import { useAppSelector } from '~/hooks/useAppSelector';
import { sessionSlice } from '~/store/slices/session';

const DebugButton: React.FC = () => {
  const dispatch = useAppDispatch();
  const { isDebugMode } = useAppSelector((state) => state.session);

  const toggleDebugMode = (): void => {
    dispatch(sessionSlice.actions.toggleDebugMode());
  };

  return (
    <Box position="fixed" bottom={{ base: 24, md: 8 }} left={2} zIndex={10}>
      <Tooltip label="Debug">
        <Button
          w={12}
          h={12}
          borderRadius={100}
          onPress={toggleDebugMode}
          bg={isDebugMode ? colors.singletons.black : 'transparent'}
          justifyContent="center"
          alignItems="center"
          shadow={2}
          borderWidth={1}
          borderColor={'gray.200'}
        >
          <Icon
            size={16}
            color={isDebugMode ? colors.yellow[400] : colors.gray[400]}
            icon={isDebugMode ? 'zap' : 'zap-off'}
          />
        </Button>
      </Tooltip>
    </Box>
  );
};

export default DebugButton;
