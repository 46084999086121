import { LogProgressAndHistoryCard } from '~/compound-components/log-progress-and-history/log-progress-and-history-card/log-progress-and-history-card';
import { LogProgressAndHistoryCardContent } from '~/compound-components/log-progress-and-history/log-progress-and-history-content/log-progress-and-history-content';
import { LogProgressAndHistoryCardContentFooter } from '~/compound-components/log-progress-and-history/log-progress-and-history-footer/log-progress-and-history-footer';
import { LogProgressAndHistoryRoot } from '~/compound-components/log-progress-and-history/log-progress-and-history-root/log-progress-and-history-root';

export const LogProgressAndHistory = {
  Root: LogProgressAndHistoryRoot,
  Card: LogProgressAndHistoryCard,
  CardContent: LogProgressAndHistoryCardContent,
  ContentFooter: LogProgressAndHistoryCardContentFooter,
};
