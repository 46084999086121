import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ILocation } from 'ui/types/interfaces';

import { SessionState } from '~/store/slices/session/types';
import { ESliceNames } from '~/store/types';

const initialState: SessionState = {
  location: null,
  isLocationModalOpen: false,
  isDebugMode: false,
};

export const sessionSlice = createSlice({
  name: ESliceNames.SESSION,
  initialState,
  reducers: {
    clear: () => ({ ...initialState }),
    setLocation: (state, { payload }: PayloadAction<ILocation | null>) => {
      return { ...state, location: payload };
    },
    openLocationModal: (state) => ({ ...state, isLocationModalOpen: true }),
    closeLocationModal: (state) => ({ ...state, isLocationModalOpen: false }),
    toggleDebugMode: (state) => {
      return { ...state, isDebugMode: !state.isDebugMode };
    },
  },
});

export const { reducer: sessionSliceReducer, actions: sessionSliceActions } =
  sessionSlice;
