import { Flex, Hidden, HStack, Progress, Stack, Text } from 'native-base';
import Button from 'ui/components/Button';
import { InviteCompose } from 'ui/components/InviteCompose';
import Loading from 'ui/components/Loading';
import { PoweredByDoit } from 'ui/components/v2/Labels/PoweredByDoit';

import { useAcceptInviteController } from '~/pages/Common/AcceptInvite/useAcceptInviteController';
import { colors } from '~/theme/colors';

import { AcceptTermsSection } from './components/AcceptTermsSection';

export const AcceptInvite = () => {
  const {
    handleAcceptInvitation,
    handleDeclineInvitation,
    isLoadingPage,
    invitationInfo,
    isAuthenticated,
    isSubmitting,
    toggleIsTermsAndConditionsAccepted,
    isTermsAndConditionsAccepted,
    currentStep,
    handleNextStep,
  } = useAcceptInviteController();

  if (isLoadingPage || !isAuthenticated) {
    return <Loading />;
  }

  return (
    <Flex
      minH="100vh"
      flexDir={{ base: 'column', lg: 'row' }}
      height="100%"
      width="100%"
    >
      <Stack minH="100vh" width={{ base: '100%', lg: '55%' }}>
        <InviteCompose
          title={invitationInfo?.composeSettings?.title || ''}
          message={invitationInfo?.composeSettings?.message || ''}
          brandColor={invitationInfo?.composeSettings?.brandColor}
          organizationLogo={invitationInfo?.sharerOrganization?.logo || ''}
          organizationName={invitationInfo?.sharerOrganization?.name || ''}
          logo={
            invitationInfo?.composeSettings?.logo ||
            invitationInfo?.sharerOrganization?.logo
          }
          backgroundImage={invitationInfo?.composeSettings?.backgroundImage}
        />
      </Stack>
      <Stack
        width={{ base: '100%', lg: '45%' }}
        position={{ base: 'absolute', lg: 'relative' }}
        justifyContent={{ base: 'initial', lg: 'center' }}
        paddingLeft={{ base: 5, lg: '6.3rem' }}
        paddingRight={{ base: 5, lg: '6.3rem' }}
        padding={{ base: 5, lg: 0 }}
        minH={{ base: '55%', lg: '100vh' }}
        bgColor={{ base: 'white', lg: colors.singletons.lightText }}
        borderTopRadius={60}
        bottom={0}
      >
        {currentStep === 0 ? (
          <Hidden from="lg">
            <>
              <HStack space={2} justifyContent="center">
                <Progress
                  width="30px"
                  bgColor={
                    invitationInfo?.composeSettings?.brandColor ||
                    colors.gray[900]
                  }
                />
                <Progress width="10px" />
              </HStack>
              <Stack padding={5}>
                <Text>{invitationInfo?.composeSettings?.message || ''}</Text>
              </Stack>
              <Stack position="absolute" bottom={0} width="90%" padding={5}>
                <Button
                  variant="outline"
                  padding={5}
                  rightIconName="arrow-right"
                  rightIconSize={22}
                  rightIconColor={colors.gray[900]}
                  _text={{
                    fontWeight: 500,
                  }}
                  onPress={handleNextStep}
                >
                  Next
                </Button>
              </Stack>
            </>
          </Hidden>
        ) : null}

        {currentStep === 1 ? (
          <Hidden from="lg">
            <AcceptTermsSection
              handleAcceptInvitation={handleAcceptInvitation}
              handleDeclineInvitation={handleDeclineInvitation}
              isLoadingSubmit={isSubmitting}
              toggleIsTermsAndConditionsAccepted={
                toggleIsTermsAndConditionsAccepted
              }
              isTermsAndConditionsAccepted={isTermsAndConditionsAccepted}
              invitationInfo={invitationInfo}
            />
          </Hidden>
        ) : null}

        <Hidden till="lg">
          <AcceptTermsSection
            handleAcceptInvitation={handleAcceptInvitation}
            handleDeclineInvitation={handleDeclineInvitation}
            isLoadingSubmit={isSubmitting}
            toggleIsTermsAndConditionsAccepted={
              toggleIsTermsAndConditionsAccepted
            }
            isTermsAndConditionsAccepted={isTermsAndConditionsAccepted}
            invitationInfo={invitationInfo}
          />
        </Hidden>

        <Hidden till="lg">
          <HStack
            position="absolute"
            alignItems="center"
            bottom={0}
            right={0}
            padding={12}
          >
            <PoweredByDoit />
          </HStack>
        </Hidden>
      </Stack>
    </Flex>
  );
};
