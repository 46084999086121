import { useBreakpointValue } from 'native-base';
import { useEffect, useState } from 'react';

import { PAGES } from '~/constants/pages.constants';
import { useUserProfileTotalTimeLogged } from '~/hooks/insights/useUserProfileTotalTimeLogged';
import { useVolunteerOrganization } from '~/hooks/organization/useVolunteerOrganization';
import { useAppSelector } from '~/hooks/useAppSelector';
import { useRouter } from '~/hooks/useRouter';
import {
  IHomeWidgetController,
  IHomeWidgetControllerResult,
} from '~/pages/Authenticated/v2/Home/components/Widget/types';
import { VolunteeringHoursInfoData } from '~/pages/Authenticated/v2/Home/components/Widget/VolunteeringHours/Snippets/InfoSnippet';

export interface HomeWidgetVolunteeringHoursController
  extends IHomeWidgetControllerResult<VolunteeringHoursInfoData | null> {
  title: string;
}

export const useHomeWidgetVolunteeringHoursController: IHomeWidgetController<
  HomeWidgetVolunteeringHoursController
> = () => {
  //Providers
  const { selectedUserProfile } = useAppSelector(({ auth }) => auth);
  const { goToRoute } = useRouter();
  //States
  const [defaultTabIndex, setDefaultTabIndex] = useState(0);
  const [volunteeringHoursInfo, setVolunteeringHoursInfo] =
    useState<VolunteeringHoursInfoData | null>(null);

  // Hooks
  const { organization, isLoading: isOrganizationLoading } =
    useVolunteerOrganization();

  const { data: timeLogged, isLoading: isTimeLoggedLoading } =
    useUserProfileTotalTimeLogged(selectedUserProfile?._id);

  useEffect(() => {
    if (!organization || isTimeLoggedLoading) return;

    const { organizationSettings } = organization;

    const loggedSeconds = timeLogged || 0;
    const companyGoal = organizationSettings?.measurementGoal || 0;

    setVolunteeringHoursInfo({ companyGoal, loggedSeconds });
    if (loggedSeconds > 0) {
      setDefaultTabIndex(1);
    }
  }, [organization, timeLogged, isTimeLoggedLoading]);

  const handleGoToLogActivities = () => {
    const url = PAGES.CreateOffPlatformActivity;
    goToRoute(url);
  };

  const title = useBreakpointValue({
    base: 'Log your hours',
    md: 'Log your volunteering hours',
  });

  const isLoading = isOrganizationLoading || isTimeLoggedLoading;

  return {
    title,
    isLoading,
    defaultTabIndex,
    data: volunteeringHoursInfo,
    onCardPress: handleGoToLogActivities,
  };
};
