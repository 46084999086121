import { Avatar, Box, HStack, Text, VStack } from 'native-base';
import { colors } from 'ui/theme/colors';

import { DescriptionLabel } from '~/pages/Authenticated/v2/ActivityView/components/ActivityOrganizationBio/fragments/DescriptionLabel';
import { ActivityOrganizationBioProps } from '~/pages/Authenticated/v2/ActivityView/components/ActivityOrganizationBio/types';
import { getNameInitials } from '~/utils/getInitials';

export const ActivityOrganizationBio = ({
  organization,
}: ActivityOrganizationBioProps) => {
  const organizationLogo =
    organization.logoThumbnail || organization.logo || '';

  return (
    <VStack
      space={8}
      p={6}
      borderWidth={1}
      borderColor={'gray.300'}
      rounded={24}
    >
      <HStack alignItems={'center'} space={4} flex={1}>
        <Box w={20} h={20}>
          <Avatar
            size={'full'}
            borderRadius={'full'}
            source={{ uri: organizationLogo }}
          >
            {getNameInitials(`${organization.name}`)}
          </Avatar>
        </Box>

        <Box flex={1}>
          <Text
            fontWeight={500}
            fontSize={{ base: 'lg', lg: 'xl' }}
            color={colors.darkText}
          >
            {organization.name}
          </Text>
        </Box>
      </HStack>

      {!!organization.description && (
        <Text
          fontWeight={400}
          fontSize={'sm'}
          isTruncated
          noOfLines={10}
          color={colors.darkText}
        >
          {organization.description}
        </Text>
      )}

      {!!organization.purpose && (
        <DescriptionLabel
          iconName="target"
          title="Purpose"
          description={organization.purpose || ''}
        />
      )}

      {!!organization?.websiteLink && (
        <DescriptionLabel
          iconName="globe"
          title="Website"
          description={organization?.websiteLink}
          isLink
        />
      )}

      <DescriptionLabel
        iconName="mail"
        title="Email address"
        description={organization?.contactEmail || ''}
      />

      <DescriptionLabel
        iconName="phone"
        title="Phone number"
        description={organization?.contactPhoneNumber || ''}
      />
    </VStack>
  );
};

export default ActivityOrganizationBio;
