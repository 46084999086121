import { ActivityRegionType, AdvertiseRegionOptionsEnum } from 'ui/enums';
import { ActivityRegion, MappedRegions } from 'ui/types/activities';

export const createNationWithRegionsDictionary = (
  nations: ActivityRegion[],
  regions: ActivityRegion[],
): { [nation: string]: ActivityRegion[] } => {
  const nationRegionsDictionary = nations.reduce((acc, nations) => {
    const nationRegions = regions.filter(
      (region) => region.relatedTo === nations.displayName,
    );

    return {
      ...acc,
      [nations.displayName]: nationRegions,
    };
  }, {});

  return nationRegionsDictionary;
};

export const mapActivityRegionsForLocationDetails = (
  allRegions: ActivityRegion[],
  activityRegions?: ActivityRegion[] | null,
) => {
  if (!activityRegions) return null;

  const allNationsFromAllRegions = allRegions?.filter(
    (regionItem) => regionItem.type === ActivityRegionType.Nation,
  );
  const allNationsFromActivityRegions = activityRegions?.filter(
    (regionItem) => regionItem.type === ActivityRegionType.Nation,
  );

  const nationsWithAllRegionsDictionary = createNationWithRegionsDictionary(
    allNationsFromAllRegions,
    allRegions,
  );

  const nationsWithActivityRegionsDictionary =
    createNationWithRegionsDictionary(
      allNationsFromActivityRegions,
      activityRegions,
    );

  const mappedRegions: MappedRegions[] = allNationsFromActivityRegions.map(
    (nation) => {
      const hasAllRegions =
        nationsWithActivityRegionsDictionary[nation.displayName].length ===
        nationsWithAllRegionsDictionary[nation.displayName].length;

      return {
        mainRegionData: nation,
        relatedRegions:
          nationsWithActivityRegionsDictionary[nation.displayName],
        hasAllRegions,
      };
    },
  );

  return mappedRegions;
};
export const getActivityAdvertiseOption = (
  allRegions: ActivityRegion[],
  activityRegions?: ActivityRegion[] | null,
) => {
  if (activityRegions) {
    if (activityRegions.length === allRegions.length) {
      return AdvertiseRegionOptionsEnum.AnywhereInUK;
    }
    return AdvertiseRegionOptionsEnum.SelectRegions;
  }
  return AdvertiseRegionOptionsEnum.AnywhereInUK;
};
