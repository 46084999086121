import { useCallback, useEffect, useState } from 'react';

import { useSearchRoutes } from '~/components/Layouts/v2/SearchLayout/hooks/use-search-routes';
import { ActivityCarouselDataSource } from '~/components/v2/ActivityCarousel/types';
import { useUserLocationController } from '~/hooks/useUserLocation/useUsersLocation';
import {
  InAppCarouselCollectionName,
  inAppCarouselCollectionsWhiteListMap,
} from '~/pages/Authenticated/v2/InApp/components/CarouselCollections/constants';
import { PageDefaultFilters } from '~/pages/Authenticated/v2/InApp/components/CarouselCollections/types';
import { SearchCollectionSlug } from '~/types/interfaces/activity-search/types';
import { useInApp } from '~/zustand/store/InApp';

import {
  generateActionDataSource,
  generateFromHomeEventsDataSource,
  generateFromHomeOngoingDataSource,
  generateInPersonEventsDataSource,
  generateInPersonOngoingDataSource,
  generateTeamEventsDataSource,
} from './utils';

export const useInAppCarouselsSuggestionController = () => {
  // Providers
  const { goToSearchCollectionPage } = useSearchRoutes();
  const { coordinates } = useUserLocationController();
  const { app, selectedCategory } = useInApp((state) => state);
  const [showEmptyState, setShowEmptyState] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // States
  const [inPersonEventsDataSource, setInPersonEventsDataSource] =
    useState<ActivityCarouselDataSource | null>(null);

  const [fromHomeEventsDataSource, setFromHomeEventsDataSource] =
    useState<ActivityCarouselDataSource | null>(null);

  const [teamEventsDataSource, setTeamEventsDataSource] =
    useState<ActivityCarouselDataSource | null>(null);

  const [actionsDataSource, setActionsDataSource] =
    useState<ActivityCarouselDataSource | null>(null);

  const [inPersonOngoingDataSource, setInPersonOngoingDataSource] =
    useState<ActivityCarouselDataSource | null>(null);

  const [fromHomeOngoingDataSource, setFromHomeOngoingDataSource] =
    useState<ActivityCarouselDataSource | null>(null);

  const [pageFilters, setPageFilters] = useState<PageDefaultFilters | null>(
    null,
  );

  useEffect(() => {
    setShowEmptyState(false);
  }, [selectedCategory]);

  // Hooks
  useEffect(() => {
    if (!app?._id || !coordinates) return;
    const categoryFilters = selectedCategory?.filters ?? {};

    requestAnimationFrame(() => {
      setIsLoading(true);
    });

    setPageFilters({
      apps: [app?._id],
      position: coordinates,
      categoryFilters,
    });
  }, [app, selectedCategory, coordinates, setIsLoading]);

  useEffect(() => {
    if (!pageFilters) return;

    const { categoryFilters } = pageFilters;

    const isDataSourceEnabled = (key: InAppCarouselCollectionName) => {
      if (!categoryFilters) return true;

      // Verify if there is a canon config to given section
      const canon = inAppCarouselCollectionsWhiteListMap.get(key);
      if (!canon) return false;

      return inAppCarouselCollectionsWhiteListMap.match(key, {
        activityTypes: categoryFilters?.activityTypes || [],
        attendanceTypes: categoryFilters?.attendanceTypes || [],
      });
    };

    /* Events data source */
    setInPersonEventsDataSource(null);
    if (isDataSourceEnabled(SearchCollectionSlug.Events)) {
      generateInPersonEventsDataSource(
        pageFilters,
        setInPersonEventsDataSource,
      );
    }

    /* From home events data source */
    setFromHomeEventsDataSource(null);
    if (isDataSourceEnabled(SearchCollectionSlug.EventsFromHome)) {
      generateFromHomeEventsDataSource(
        pageFilters,
        setFromHomeEventsDataSource,
      );
    }

    /* Team events */
    setTeamEventsDataSource(null);
    if (isDataSourceEnabled(SearchCollectionSlug.TeamEvents)) {
      generateTeamEventsDataSource(pageFilters, setTeamEventsDataSource);
    }

    /* Action data source */
    setActionsDataSource(null);
    if (isDataSourceEnabled(SearchCollectionSlug.Actions)) {
      generateActionDataSource(pageFilters, setActionsDataSource);
    }

    /* Ongoing data source */
    setInPersonOngoingDataSource(null);
    if (isDataSourceEnabled(SearchCollectionSlug.Ongoing)) {
      generateInPersonOngoingDataSource(
        pageFilters,
        setInPersonOngoingDataSource,
      );
    }

    /* Remote Ongoing data source */
    setFromHomeOngoingDataSource(null);
    if (isDataSourceEnabled(SearchCollectionSlug.RemoteOngoing)) {
      generateFromHomeOngoingDataSource(
        pageFilters,
        setFromHomeOngoingDataSource,
      );
    }

    setTimeout(() => {
      setIsLoading(false);
    }, 250);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageFilters]);

  const handleCarouselRedirect = useCallback(
    (collectionSlug: SearchCollectionSlug) => {
      goToSearchCollectionPage(collectionSlug);
    },
    [goToSearchCollectionPage],
  );

  useEffect(() => {
    const _isDataSourcesMounted =
      !!inPersonEventsDataSource ||
      !!fromHomeEventsDataSource ||
      !!inPersonOngoingDataSource ||
      !!fromHomeOngoingDataSource ||
      !!teamEventsDataSource ||
      !!actionsDataSource;

    if (!isLoading && !_isDataSourcesMounted) {
      setShowEmptyState(true);
    }
  }, [
    isLoading,
    actionsDataSource,
    fromHomeEventsDataSource,
    fromHomeOngoingDataSource,
    inPersonEventsDataSource,
    inPersonOngoingDataSource,
    teamEventsDataSource,
  ]);

  return {
    isLoading,
    showEmptyState,
    selectedCategory,
    actionsDataSource,
    teamEventsDataSource,
    inPersonEventsDataSource,
    fromHomeEventsDataSource,
    inPersonOngoingDataSource,
    fromHomeOngoingDataSource,
    isFilterSet: !!pageFilters,
    handleCarouselRedirect,
  };
};
