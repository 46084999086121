import { useEffect, useState } from 'react';
import { calculateUserProfileProgress } from 'ui/utils/userProfile/calculate-user-profile-progress';

import { PAGES } from '~/constants/pages.constants';
import { useVolunteerOrganization } from '~/hooks/organization/useVolunteerOrganization';
import { useVolunteerUser } from '~/hooks/user/useVolunteerUser';
import { useRouter } from '~/hooks/useRouter';
import {
  IHomeWidgetController,
  IHomeWidgetControllerResult,
} from '~/pages/Authenticated/v2/Home/components/Widget/types';

export type HomeWidgetProfileProgressController = IHomeWidgetControllerResult<{
  progressValue: number;
}>;

export const useHomeWidgetProfileProgressController: IHomeWidgetController<
  HomeWidgetProfileProgressController
> = () => {
  // Providers
  const { goToRoute } = useRouter();
  const { organization } = useVolunteerOrganization();
  const { userProfile, user } = useVolunteerUser();
  const [progressValue, setProgressValue] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  // Hooks
  useEffect(() => {
    if (!userProfile || !user || !organization) return;

    const _progressValue = calculateUserProfileProgress(
      user,
      userProfile,
      organization,
    );

    setProgressValue(_progressValue);
    setIsLoading(false);
  }, [userProfile, user, organization]);

  // Handlers
  const handleGoToUserProfilePage = () => {
    goToRoute(PAGES.Profile);
  };

  return {
    isLoading,
    defaultTabIndex: progressValue === 100 || progressValue === 0 ? 0 : 1,
    data: { progressValue: progressValue },
    onCardPress: handleGoToUserProfilePage,
  };
};
