export function secondsToHours(seconds: number) {
  let hours = 0;
  let minutes = 0;
  if (isNaN(seconds)) {
    return { hours, minutes };
  }

  hours = Math.floor(seconds / 3600);
  minutes = Math.floor((seconds % 3600) / 60);

  return { hours, minutes };
}
