import { Flex, HStack, Stack, Text } from 'native-base';
import Illustration, { ILLUSTRATIONS_NAME } from 'ui/components/Illustration';

import { BrandedBackgroundProps } from './types';

export const BrandedBackground = ({
  isLoading,
  onLoaded,
  height,
  backgroundImage,
  isBigHelpOutBranded = false,
}: BrandedBackgroundProps) => {
  return (
    <>
      {isBigHelpOutBranded ? (
        <Flex height={height}>
          <Illustration
            display={isLoading ? 'none' : 'flex'}
            name={ILLUSTRATIONS_NAME.LOGIN_BACKGROUND}
            source={backgroundImage ? { uri: backgroundImage } : undefined}
            width="100%"
            resizeMode="cover"
            style={{ opacity: 0.35 }}
            onLoad={onLoaded}
            flex={isLoading ? 1 : 5 / 6}
          />
          {!isLoading && (
            <Stack
              minH={{
                base: 0,
                lg: '100px',
              }}
              width={'100%'}
              flex={1 / 6}
              backgroundColor="white"
              alignItems={'center'}
            >
              <Text
                fontSize={'lg'}
                paddingTop={4}
                color="gray.600"
                fontWeight={400}
              >
                Official partners of The Big Help Out
              </Text>
              <HStack
                width="100%"
                justifyContent={'center'}
                space="1/6"
                alignItems={'center'}
              >
                <Illustration
                  display={isLoading ? 'none' : 'flex'}
                  name={ILLUSTRATIONS_NAME.BHO_PARTNER_ASDA_LOGO}
                  height={{
                    base: 20,
                    lg: 100,
                  }}
                  width={{
                    base: 20,
                    lg: 100,
                  }}
                  resizeMode="contain"
                />
                <Illustration
                  display={isLoading ? 'none' : 'flex'}
                  name={ILLUSTRATIONS_NAME.BHO_PARTNER_DPD_LOGO}
                  height={{
                    base: 20,
                    lg: 100,
                  }}
                  width={{
                    base: 20,
                    lg: 120,
                  }}
                  resizeMode="contain"
                />
                <Illustration
                  display={isLoading ? 'none' : 'flex'}
                  name={ILLUSTRATIONS_NAME.BHO_PARTNER_CTM_LOGO}
                  height={{
                    base: 20,
                    lg: 100,
                  }}
                  width={{
                    base: 20,
                    lg: 180,
                  }}
                  resizeMode="contain"
                />
              </HStack>
            </Stack>
          )}
        </Flex>
      ) : (
        <Illustration
          display={isLoading ? 'none' : 'flex'}
          name={ILLUSTRATIONS_NAME.LOGIN_BACKGROUND}
          source={backgroundImage ? { uri: backgroundImage } : undefined}
          width="100%"
          height={height}
          resizeMode="cover"
          style={{ opacity: 0.35 }}
          onLoad={onLoaded}
        />
      )}
    </>
  );
};
