import { Hidden, PresenceTransition, View, VStack } from 'native-base';
import { colors } from 'ui/theme/colors';

import {
  CenterContainer,
  CenterContainerSize,
} from '~/components/Layouts/Scaffolds/CenterContainer';
import { MobileNavBar } from '~/components/Layouts/v2/SearchLayout/components/MobileNavBar';
import { ProfileProgressBanner } from '~/components/v2/Banners/ProfileProgress';
import { InAppCarouselsCollections } from '~/pages/Authenticated/v2/InApp/components/CarouselCollections';
import CategoriesSlider from '~/pages/Authenticated/v2/InApp/components/CategoriesSlider';

import { InAppHeader } from './components/Header';
import { useInAppControllerSearchController } from './controllers/controller';

export const InApp = () => {
  const {
    appId,
    appBrandColor,
    showProfileProgressBanner,
    handleCloseProfileProgressBanner,
  } = useInAppControllerSearchController();

  return (
    <PresenceTransition
      visible={true}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 250 } }}
    >
      <View key={appId} backgroundColor={colors.singletons.white}>
        <Hidden from="md">
          <MobileNavBar />
        </Hidden>

        <InAppHeader />

        <VStack py={4}>
          <CenterContainer size={CenterContainerSize.xl}>
            <CategoriesSlider />

            {showProfileProgressBanner && (
              <VStack mt={8}>
                <ProfileProgressBanner
                  backgroundColor={appBrandColor}
                  onProgressCompleted={handleCloseProfileProgressBanner}
                  show={true}
                />
              </VStack>
            )}
          </CenterContainer>
        </VStack>

        <VStack pb={12} minH={'100vh'} bg={colors.singletons.white}>
          <CenterContainer size={CenterContainerSize.xl}>
            <InAppCarouselsCollections />
          </CenterContainer>
        </VStack>
      </View>
    </PresenceTransition>
  );
};
