import { ActivitySemanticType } from 'ui/types/activities';

import { BreadCrumbsNode } from '~/components/Breadcrumbs/types';
import { SearchCollectionSlug } from '~/types/interfaces/activity-search/types';

export const collectionsBreadCrumbsNodeMap: Record<
  ActivitySemanticType,
  BreadCrumbsNode
> = {
  [ActivitySemanticType.Event]: {
    icon: 'calendar',
    label: 'Events',
    payload: { collection: SearchCollectionSlug.Events },
  },
  [ActivitySemanticType.TeamEvent]: {
    icon: 'users',
    label: 'Team Events',
    payload: { collection: SearchCollectionSlug.TeamEvents },
  },
  [ActivitySemanticType.Action]: {
    icon: 'hand-pointer',
    label: 'Actions',
    payload: { collection: SearchCollectionSlug.Actions },
  },
  [ActivitySemanticType.Ongoing]: {
    icon: 'repeat',
    label: 'Ongoing',
    payload: { collection: SearchCollectionSlug.Ongoing },
  },
};
