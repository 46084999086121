import { Hidden, PresenceTransition, View, VStack } from 'native-base';
import { colors } from 'ui/theme/colors';

import { MobileNavBar } from '~/components/Layouts/v2/SearchLayout/components/MobileNavBar';
import { ActivityFilterModal } from '~/components/Layouts/v2/SearchLayout/components/Modals/ActivityFilterModal';
import { useDebugMode } from '~/utils/debug/use-debug-mode';

import { SearchHeader } from './components/Header';
import { SearchFeed } from './components/SearchFeed';
import { useSearchController } from './controllers/controller';

export const Search = () => {
  const { debugMode } = useDebugMode();
  const { collection, appliedFilters, handleFiltersModalSubmit } =
    useSearchController();

  return (
    <PresenceTransition
      style={{ width: '100%' }}
      visible
      initial={{ opacity: 0, translateY: 0.75 }}
      animate={{ opacity: 1, translateY: 1, transition: { duration: 300 } }}
    >
      <View key={collection} bg={colors.singletons.white} minH={'calc(100vh)'}>
        <Hidden from="md">
          <MobileNavBar />
        </Hidden>

        {/* Header */}
        <SearchHeader />

        {/* Body */}
        <VStack pt={{ base: 0, md: 4 }}>
          <SearchFeed debugMode={debugMode} />
        </VStack>

        {/* Filter Modal */}
        <ActivityFilterModal
          defaultFilters={appliedFilters}
          onSubmit={handleFiltersModalSubmit}
        />
      </View>
    </PresenceTransition>
  );
};

export default Search;
