import { Avatar, HStack, Stack, Text } from 'native-base';
import Icon from 'ui/components/Icon';
import { DropDown } from 'ui/components/v2/DropDown';
import { colors } from 'ui/theme/colors';
import { getAvatarText } from 'ui/utils/getAvatarText';

import { useAppSwitcherController } from './controller';
import { AppSwitcherPopOver } from './popover';
import { AppSwitcherProps } from './types';

export const AppSwitcher = (props: AppSwitcherProps) => {
  const { options, selectedOption, dropDownImperativeRef, handleOptionPress } =
    useAppSwitcherController(props);

  const hasMultipleApps = options.length > 1;

  return (
    <DropDown.Base
      ref={dropDownImperativeRef}
      popoverAnchor="left"
      popoverMaxH="unset"
      popoverMaxW="344px"
      overlayContent={
        hasMultipleApps ? (
          <AppSwitcherPopOver
            options={options}
            selected={selectedOption}
            onOptionPress={handleOptionPress}
            onAllAppsPress={props.onAllAppsPress}
          />
        ) : null
      }
    >
      <HStack space={3} alignItems={'center'} minW={'258px'}>
        <Avatar
          w={{ base: 10, md: 12 }}
          h={{ base: 10, md: 12 }}
          borderWidth={1}
          borderColor={colors.gray[300]}
          borderRadius={'12px'}
          bgColor="muted.100"
          _text={{ color: 'darkText' }}
          source={{ uri: selectedOption?.logo || '' }}
          _image={{ borderRadius: 12 }}
        >
          {getAvatarText(selectedOption?.label || '')}
        </Avatar>

        <Stack space={0.5}>
          <Text
            lineHeight={'125%'}
            fontSize={{ base: 'lg', md: 'xl' }}
            fontWeight={{ base: 500, md: 700 }}
            color={{ base: colors.gray[600], md: colors.darkText }}
          >
            {selectedOption?.label || ''}
          </Text>
        </Stack>

        {hasMultipleApps ? (
          <Icon icon="chevron-down" size={'16px'} color={colors.gray[500]} />
        ) : null}
      </HStack>
    </DropDown.Base>
  );
};

export default AppSwitcher;
