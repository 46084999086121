import { ActivityCategory, RelatedToEnum } from 'ui/types/activities';

import { useQuery } from '~/hooks/useQuery';
import { IPaginatedResponse } from '~/types';

export const useActivityCategories = (relatedTo: RelatedToEnum) => {
  const { data, isLoading, refetch } = useQuery<
    IPaginatedResponse<ActivityCategory[]>
  >(`/activity-category`, {
    key: `activity-categories-${relatedTo}`,
    requestOptions: {
      params: {
        filter: JSON.stringify({ relatedTo }),
        limit: 1000,
      },
    },
  });

  const uniqueData: Set<string> = new Set();
  const uniqueDataArray: ActivityCategory[] = [];

  data?.data?.forEach((current) => {
    if (current.displayName && !uniqueData.has(current.displayName)) {
      uniqueData.add(current.displayName);
      uniqueDataArray.push(current);
    }
  });

  return { data: uniqueDataArray, isLoading, refetch };
};
