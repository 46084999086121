import 'rc-slider/assets/index.css';

import { HStack, Text, VStack } from 'native-base';
import RcSlider from 'rc-slider';
import CommonInputWrapper from 'ui/components/v2/CommonInputWrapper';

import { SliderProps } from '../types';
import { sliderStyle } from './constants';
import { handleRender } from './ToolTip';

export const Slider = (props: SliderProps) => {
  const {
    label,
    value,
    range = false,
    min = 1,
    max = 100,
    helpText,
    unityLabel,
    isRequired,
    errorMessage,
    onChange,
    step = 2,
    onChangeComplete,
  } = props;

  return (
    <CommonInputWrapper
      isRequired={isRequired}
      label={label}
      errorMessage={errorMessage}
      helpText={helpText}
    >
      <VStack w="full">
        <RcSlider
          step={step}
          range={range}
          value={value}
          min={min}
          max={max}
          allowCross={false}
          defaultValue={value}
          styles={sliderStyle}
          handleRender={handleRender}
          onChange={onChange}
          onChangeComplete={onChangeComplete}
        />

        <HStack justifyContent={'space-between'} mt={4}>
          <Text color="gray.900" fontSize="xs" fontWeight="500">
            {min} {unityLabel}
          </Text>
          <Text color="gray.900" fontSize="xs" fontWeight="500">
            {max} {unityLabel}
          </Text>
        </HStack>
      </VStack>
    </CommonInputWrapper>
  );
};

export default Slider;
