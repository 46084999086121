import { useQuery } from '~/hooks/useQuery';
import { IActivityApplication } from '~/types/interfaces/activity';

export const useLoadActivityApplication = (id: string) => {
  const { data, isLoading } = useQuery<IActivityApplication>(
    `activity-application/${id}`,
    {
      key: `activity-application-${id}`,
      queryOptions: { enabled: !!id },
    },
  );

  return { data, isLoading };
};
