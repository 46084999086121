import { Slider } from '../Base';
import { SliderOnChangeFunction } from '../types';
import { MultiRangeSliderProps } from './types';

export const MultiRangeSlider = (props: MultiRangeSliderProps) => {
  const { value, onChange, onChangeComplete } = props;

  const rangeValue = value as unknown as number[];

  const handleChange = (newValues: number[]) => {
    return onChange(newValues);
  };

  return (
    <Slider
      {...props}
      range
      value={rangeValue}
      onChange={handleChange as SliderOnChangeFunction}
      onChangeComplete={onChangeComplete as SliderOnChangeFunction}
    />
  );
};

export default MultiRangeSlider;
