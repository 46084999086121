import { useBreakpointValue } from 'native-base';
import { useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { LocationOptionsEnum } from 'ui/enums';
import { ActivitySemanticType } from 'ui/types/activities';
import { ILocation } from 'ui/types/interfaces';

import { PAGES } from '~/constants/pages.constants';
import { useActivityViewStore } from '~/pages/Authenticated/v2/ActivityView/store';
import { ApplicationActionStyleMapByType } from '~/pages/Authenticated/v2/ActivityView/views/ActivityApplication/constants/application-style-map';
import { useActivityApplicationController } from '~/pages/Authenticated/v2/ActivityView/views/ActivityApplication/controllers/application-controller';
import { getActivitySpotsAvailable } from '~/utils/activity/activity-getters';
import { getLocationFromAddress, isLocationEqual } from '~/utils/getLocation';
import { generateUrlWithQueryParams } from '~/utils/transformURL';

const styleMap = ApplicationActionStyleMapByType.get(
  ActivitySemanticType.Ongoing,
);

export const useOngoingActivityApplicationController = () => {
  // -- Providers
  const navigate = useNavigate();
  const [searchParams, setSearch] = useSearchParams();

  const filters = useActivityViewStore((state) => state.filters);

  const {
    activities,
    selectedActivity,
    activityDefinition,
    applicationAvailability,
    onExternalApplication,
    title,
    headerStyle,
  } = useActivityApplicationController();

  // -- States
  const [showLocationDrawer, setShowLocationDrawer] = useState(false);

  // -- Hooks
  const isMobile = useBreakpointValue({ base: true, lg: false });

  const availableLocations = useMemo(() => {
    const result: ILocation[] = [];

    if (activities.length == 0) return result;

    for (const activity of activities) {
      const activityLocation = getLocationFromAddress(activity.address);
      // Prevent current selected location from list
      const locationIsSelected =
        filters?.location &&
        isLocationEqual(activityLocation, filters.location);
      if (locationIsSelected) continue;

      // Ensure only unique locations will be shown
      const sameLocation = result.some((someLocation) =>
        isLocationEqual(someLocation, activityLocation),
      );
      if (!sameLocation) result.push(activityLocation);
    }
    return result;
  }, [activities, filters]);

  // -- Handlers
  const handleApplication = () => {
    if (!selectedActivity) return;

    if (selectedActivity?.externalApplyLink) {
      onExternalApplication();
      return;
    }

    const app = searchParams.get('app') || '';
    const baseUrl = PAGES.OngoingOpportunityApplication.replace(
      ':eventId',
      selectedActivity._id,
    );
    const route = generateUrlWithQueryParams(baseUrl, { app });
    navigate(route);
  };

  const handleNewLocation = (newLocation: ILocation | null) => {
    if (!newLocation) return;

    for (const activity of activities) {
      const activityLocation = getLocationFromAddress(activity.address);

      const locationMatches =
        location && isLocationEqual(newLocation, activityLocation);

      if (locationMatches) {
        const params = new URLSearchParams(searchParams);
        params.set('selected', activity._id);
        setSearch(params.toString());
        break;
      }
    }
  };

  const openMobileLocationDrawer = () => {
    setShowLocationDrawer(true);
  };

  const closeMobileLocationDrawer = () => {
    setShowLocationDrawer(false);
  };

  // -- Computed
  const actionLabel = `${styleMap!.actionLabel} ${
    selectedActivity?.externalApplyLink ? ' (by referral)' : ''
  }`;

  const spotsAvailable = getActivitySpotsAvailable(selectedActivity);

  // --Render statements
  const isFromHome =
    activityDefinition?.locationOption === LocationOptionsEnum.FromHome;

  return {
    filters,
    isMobile,
    actionLabel,
    selectedActivity,
    applicationAvailability,
    availableLocations,
    showLocationDrawer,
    spotsAvailable,
    handleApplication,
    openMobileLocationDrawer,
    closeMobileLocationDrawer,
    handleNewLocation,
    isFromHome,
    location,
    title,
    headerStyle,
  };
};
