import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ActivityType } from 'ui/types/activities';

import { PAGES } from '~/constants/pages.constants';
import { useLoadActivityCategories } from '~/hooks/useActivityResources/useLoadActivityCategories';
import { useAppSelector } from '~/hooks/useAppSelector';
import { useLoadMeasurementDefinitionById } from '~/hooks/useMeasurementDefinition/useLoadMeasurementDefinitionById';
import { useRouter } from '~/hooks/useRouter';
import { IOffPlatformDefinitionFormFields } from '~/pages/Authenticated/OffPlatformApplication/components/DefinitionForm/types';
import { IOffPlatformLogFields } from '~/pages/Authenticated/OffPlatformApplication/components/LogForm/types';
import { sections } from '~/pages/Authenticated/OffPlatformApplication/constants';
import { useMeasurementUnitsController } from '~/pages/Authenticated/OffPlatformApplication/controllers/useMeasurementUnitsController';
import { getInitialLogFields } from '~/pages/Authenticated/OffPlatformApplication/utils';
import MeasurementDefinitionService from '~/services/resources/measurementDefinition';
import {
  CreateMeasurementOffPlatformDTO,
  ICategoryDTO,
} from '~/types/dtos/measurement';
import { CreateOffPlatformMeasurementDefinitionDTO } from '~/types/dtos/measurement-definition';
import { getAddressFromLocation } from '~/utils/getLocation';

export const useOffPlatformApplicationController = () => {
  // Providers
  const navigate = useNavigate();
  const { params, goToRoute } = useRouter();
  const { selectedUserProfile } = useAppSelector(({ auth }) => auth);

  // States
  const [isLoading, setIsLoading] = useState(false);
  const [sectionIndex, setSectionIndex] = useState(0);

  const [definitionFormData, setDefinitionFormData] =
    useState<IOffPlatformDefinitionFormFields | null>(null);

  const [initialLogFormData, setInitialLogFormData] =
    useState<IOffPlatformLogFields>();

  // Hooks
  const { measurementUnitGroupedOptions, mapMeasurementUnitFromSelectOption } =
    useMeasurementUnitsController();

  const { data: causeOptionsData } = useLoadActivityCategories(
    String(selectedUserProfile?.organization),
  );

  const {
    data: measurementDefinition,
    isLoading: isLoadingMeasurementDefinition,
  } = useLoadMeasurementDefinitionById(params.definitionId);

  const sectionInfo = useMemo(() => sections[sectionIndex], [sectionIndex]);

  const causeOptions = useMemo(() => {
    return (causeOptionsData?.data ?? []).map((cause) => ({
      value: cause._id,
      label: cause.displayName,
    }));
  }, [causeOptionsData]);

  useEffect(() => {
    if (!measurementDefinition) return;
    const measurementUnits = [
      ...new Map(
        measurementDefinition.measurements.map((measurement) => [
          measurement.measurementUnit._id,
          measurement.measurementUnit,
        ]),
      ).values(),
    ];
    setInitialLogFormData(getInitialLogFields(measurementUnits));
    setSectionIndex(1);
  }, [measurementDefinition, isLoadingMeasurementDefinition]);

  // Handlers
  const handleCancel = () => {
    if (sectionIndex === 0 || !!params.definitionId) return navigate(-1);
    setSectionIndex((prev) => (prev > 0 ? prev - 1 : 0));
  };

  const handleDefinitionSubmit = (data: IOffPlatformDefinitionFormFields) => {
    setDefinitionFormData(data);
    const measurementsUnits = mapMeasurementUnitFromSelectOption(
      data.measurementUnitIds,
    );
    setInitialLogFormData(getInitialLogFields(measurementsUnits));
    setSectionIndex(1);
  };

  const handleLogSubmit = async (data: IOffPlatformLogFields) => {
    const { measurementDate, measurements } = data;

    const onCreate = async () => {
      if (!definitionFormData) {
        setSectionIndex(0);
        return;
      }

      const causesIds = definitionFormData.causeOptions.map((opt) => opt.value);
      const causeOptions = (causeOptionsData?.data ?? []).filter(
        (cause: ICategoryDTO) => causesIds.includes(cause?._id),
      );

      const definitionData = {
        typeOfActivity: definitionFormData.type?.value as ActivityType,
        titleOfActivity: definitionFormData.title,
        organizationName: definitionFormData.organizationName,
        description: definitionFormData.description,
        address: getAddressFromLocation(definitionFormData.location),
        ecosystemId: String(selectedUserProfile?.ecosystem),
        userId: String(selectedUserProfile?.user),
        causeOptions: causeOptions,
        appId: null,
      };

      const measurementsDto: CreateMeasurementOffPlatformDTO[] =
        measurements.map(({ amount, measurementUnit }) => {
          return {
            ...definitionData,
            isRemote: definitionFormData.isFromHome === true,
            measurementUnitId: measurementUnit._id,
            measurementDate,
            amount,
          };
        });

      const createMeasurementDefinitionData: CreateOffPlatformMeasurementDefinitionDTO =
        { ...definitionData, measurements: measurementsDto };

      await MeasurementDefinitionService.createOffPlatform(
        createMeasurementDefinitionData,
      );
      goToRoute(PAGES.OffplatformActivitySuccess);
    };

    const onUpdate = async () => {
      if (!measurementDefinition) return;

      const measurementsDto: any = measurements.map(
        (measurementFormData, i) => {
          const previousMeasurement = measurementDefinition.measurements.find(
            (measurement) => measurement._id === measurementFormData._id,
          );

          return {
            ...previousMeasurement,
            measurementDate,
            _id: measurementFormData._id,
            amount: measurementFormData.amount || 0,
            measurementUnitId: measurementFormData.measurementUnit._id,
            userId: String(selectedUserProfile?.user),
            ecosystemId: String(selectedUserProfile?.ecosystem),
          };
        },
      );

      await MeasurementDefinitionService.updateOffPlatform(
        String(params?.definitionId),
        {
          measurements: measurementsDto,
        },
      );

      goToRoute(PAGES.OffplatformActivitySuccess);
      return;
    };

    const onLog = async () => {
      if (!measurementDefinition) return;
      const newMeasurements = measurements.filter((m) => !!m.amount);

      const newMeasurementsDto: any[] = newMeasurements.map(
        ({ amount, measurementUnit }) => {
          return {
            appId: null,
            typeOfActivity:
              measurementDefinition.typeOfActivity as ActivityType,
            titleOfActivity: measurementDefinition.titleOfActivity || '',
            organizationName: measurementDefinition.organizationName || '',
            description: measurementDefinition.description || '',
            address: measurementDefinition.address || null,
            ecosystemId: String(selectedUserProfile?.ecosystem),
            userId: String(selectedUserProfile?.user),
            causeOptions:
              measurementDefinition.causeOptions as unknown as ICategoryDTO[],
            isRemote: measurementDefinition.isRemote,
            measurementUnitId: measurementUnit._id,
            measurementDate,
            amount,
          };
        },
      );

      await MeasurementDefinitionService.addOffPlatformMeasurement(
        String(params?.definitionId),
        { measurements: newMeasurementsDto },
      );

      goToRoute(PAGES.OffplatformActivitySuccess);
      return;
    };

    try {
      setIsLoading(true);

      if (!!measurementDefinition) {
        await onLog();
      } else {
        await onCreate();
      }
    } catch (err) {
      toast.error('An error occurred when trying to log activity.');
    } finally {
      setIsLoading(false);
    }
  };

  return {
    params,
    isLoading,
    sectionIndex,
    causeOptions,
    measurementDefinition,
    isLoadingMeasurementDefinition,
    measurementUnitGroupedOptions,
    initialLogFormData,
    definitionFormData,
    sectionInfo,
    goToRoute,
    handleCancel,
    handleLogSubmit,
    handleDefinitionSubmit,
  };
};
