import { useMemo } from 'react';
import { ActivityType } from 'ui/types/activities';
import { mapToActivitySemanticType } from 'ui/utils/activity/mappers';

import { useSearchRoutes } from '~/components/Layouts/v2/SearchLayout/hooks/use-search-routes';

import { collectionsBreadCrumbsNodeMap } from './constants';
import { ActivityBreadCrumbsProps } from './types';

export const useActivityBreadCrumbsController = ({
  activityDefinition,
  appId,
}: ActivityBreadCrumbsProps) => {
  const { homePathname, inAppHomePathname, getSearchCollectionPathname } =
    useSearchRoutes();

  const breadcrumbs = useMemo(() => {
    const { eventApplicationType, type, title } = activityDefinition;

    const semanticType = mapToActivitySemanticType(
      type as ActivityType,
      eventApplicationType,
    );

    const collectionNode = collectionsBreadCrumbsNodeMap[semanticType];
    collectionNode.path = getSearchCollectionPathname(
      collectionNode?.payload?.collection || '',
      appId,
    );

    return [
      {
        icon: 'home',
        label: 'Home',
        path: appId ? inAppHomePathname : homePathname,
      },
      collectionNode,
      {
        label: title,
        path: '#',
      },
    ];
  }, [
    appId,
    homePathname,
    activityDefinition,
    inAppHomePathname,
    getSearchCollectionPathname,
  ]);

  return {
    breadcrumbs,
  };
};
