import { useMemo } from 'react';

import { MakeFooterLocationNode, MakeSignUpTextNode } from '../utils';
import { OngoingActivityCardProps } from './types';

export const useOngoingActivityCardController = ({
  isOnline,
  isFromHome,
  distance,
  applicationsCount,
}: OngoingActivityCardProps) => {
  const footerNodes = useMemo(() => {
    const nodes = [MakeSignUpTextNode(applicationsCount)];
    const location = MakeFooterLocationNode(distance, isFromHome, isOnline);
    if (location) {
      nodes.unshift(location);
    }

    return nodes;
  }, [distance, isFromHome, isOnline, applicationsCount]);

  return { footerNodes };
};
