import {
  Box,
  Hidden,
  HStack,
  Image,
  Progress,
  Text,
  useBreakpointValue,
  VStack,
} from 'native-base';
import { useEffect } from 'react';
import TaskManagementListImage from 'ui/assets/illustrations/TaskManagementList.svg';
import { BannerBase } from 'ui/components/v2/Banners/Base';
import { BannerSharedProps } from 'ui/components/v2/Banners/types';
import { colors } from 'ui/theme/colors';

import { PAGES } from '~/constants/pages.constants';
import { useUserProfileProgress } from '~/hooks/user/useUserProfileProgress';
import { useRouter } from '~/hooks/useRouter';

const TITLE = 'Click here to build your volunteer profile';
const DESCRIPTION = `Complete your volunteer profile to make sure that charities know your interests and you get matched\nwith the opportunities available. Share your skills, prefereable causes, and availability. `;
const MOBILE_DESCRIPTION = `Complete your volunteer profile to make sure that charities know your interests and you get matched.`;

export type ProfileProgressBannerProps = BannerSharedProps;

export const ProfileProgressBanner = ({
  onProgressCompleted,
  backgroundColor = colors.singletons.black,
  maxWidth,
}: ProfileProgressBannerProps) => {
  const { goToRoute } = useRouter();
  const { isLoading, progressValue } = useUserProfileProgress();

  useEffect(() => {
    if (!isLoading && progressValue === 100) {
      onProgressCompleted?.();
    }
  }, [isLoading, progressValue]);

  const handleRedirectToProfile = () => {
    goToRoute(PAGES.Profile);
  };

  const description = useBreakpointValue({
    base: MOBILE_DESCRIPTION,
    md: DESCRIPTION,
  });

  return (
    <BannerBase
      show={!isLoading && progressValue < 100}
      maxWidth={maxWidth}
      isLoading={isLoading}
      backgroundColor={backgroundColor}
      onPress={handleRedirectToProfile}
    >
      <HStack space={{ base: 0, md: 12 }}>
        <VStack w="full" h="full" justifyContent={'center'} space={1}>
          <Box>
            <Text
              color={colors.singletons.white}
              fontWeight={500}
              fontSize={{ base: 'lg', md: '2xl' }}
              lineHeight={'150%'}
            >
              {TITLE}
            </Text>
          </Box>

          <Box>
            <Text
              fontSize={{ base: 'xs', md: 'lg' }}
              fontWeight={400}
              lineHeight={'150%'}
              color={colors.singletons.white}
            >
              {description}
            </Text>
          </Box>

          {/* progress bar */}
          <VStack w="full" space={1} mt={3}>
            <Text
              color={colors.singletons.white}
              fontWeight={500}
              fontSize={{ base: 'sm', md: '20px' }}
              lineHeight={'150%'}
            >
              {progressValue.toFixed(0)}%
            </Text>

            <Progress
              backgroundColor={'rgba(255, 255, 255, 0.50)'}
              _filledTrack={{
                backgroundColor: colors.singletons.white,
              }}
              w={'full'}
              value={progressValue}
            />
          </VStack>
        </VStack>

        <Hidden till="md">
          <VStack justifyContent={'center'}>
            <Image
              height={'128px'}
              width={'166px'}
              alt={`App Logo`}
              resizeMode="contain"
              source={{ uri: TaskManagementListImage }}
            />
          </VStack>
        </Hidden>
      </HStack>
    </BannerBase>
  );
};
