import { IBadgeProps } from 'native-base';
import { ColorType } from 'native-base/lib/typescript/components/types';
import { CSSProperties } from 'react';
import { colors } from 'ui/theme/colors';

import { IconEnum } from '../../Icon';
export type TagIcon =
  | 'star'
  | 'clock'
  | 'check-circle'
  | 'x-circle'
  | 'puzzle'
  | 'repeat'
  | 'puzzle'
  | 'map-pin'
  | 'file-text'
  | 'alert-triangle'
  | 'calendar'
  | 'check'
  | 'tag'
  | 'award'
  | 'sparkles'
  | 'x';

export type TagType =
  | 'success'
  | 'pending'
  | 'danger'
  | 'info'
  | 'events'
  | 'actions'
  | 'ongoingActivities'
  | 'actionsJoined'
  | 'todo'
  | 'to do'
  | 'booked'
  | 'eventsAttended'
  | 'done'
  | 'attend'
  | 'not attend'
  | 'notattend'
  | 'rejected'
  | 'near to you'
  | 'organisations'
  | 'outOffPlatform'
  | 'child org';

export interface CustomTagProps {
  text: string | JSX.Element;
  icon?: TagIcon | IconEnum;
  tagTextColor?: ColorType;
  tagBgColor?: ColorType;
  leftIconSize?: number;
  fontSize?: number;
  type?: TagType;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  textTransform?: any;
  fontWeight?: string;
  showIcon?: boolean;
  showCustomIcon?: boolean;
  customIcon?: IconEnum;
  alignSelf?: CSSProperties['alignSelf'];
  tagProps?: IBadgeProps;
  marginLeft?: number;
}

export const TAG_BG_COLOR = {
  success: colors.secondary[400],
  pending: colors.warning[100],
  danger: colors.tertiary[400],
  info: colors.gray[300],
  todo: colors.warning[200],
  'to do': colors.warning[200],
  events: colors.secondary[400],
  actions: colors.tertiary[400],
  booked: colors.info[100],
  approved: colors.info[100],
  done: colors.success[100],
  rejected: colors.error[200],
  'not attend': colors.error[200],
  notattend: colors.error[200],
  attend: colors.secondary[200],
  'child org': colors.gray[300],
  ongoingActivities: colors.purple[200],
  actionsJoined: colors.tertiary[200],
  eventsAttended: colors.secondary[200],
  'near to you': colors.darkText,
  organisations: colors.darkText,
  outOffPlatform: colors.darkText,
};

export const TAG_ICON = {
  success: 'check-circle',
  pending: 'clock',
  danger: 'alert-circle',
  info: 'star',
  todo: 'clock',
  'to do': 'clock',
  events: 'star',
  actions: 'star',
  booked: 'check-circle',
  approved: 'check-circle',
  done: 'check-circle',
  rejected: 'x-circle',
  'not attend': 'x-circle',
  notattend: 'x-circle',
  attend: 'check-circle',
  ongoingActivities: '',
  actionsJoined: 'check-circle',
  eventsAttended: 'check-circle',
  'near to you': 'map-pin',
  organisations: 'check-circle',
  outOffPlatform: 'check-circle',
  'child org': '',
};

export const TAG_TEXT_COLOR = {
  success: colors.white,
  pending: colors.darkText,
  danger: colors.white,
  info: colors.info[800],
  todo: colors.darkText,
  'to do': colors.darkText,
  booked: colors.darkText,
  approved: colors.darkText,
  done: colors.darkText,
  rejected: colors.darkText,
  'not attend': colors.darkText,
  notattend: colors.darkText,
  attend: colors.darkText,
  events: colors.white,
  actions: colors.white,
  'child org': colors.darkText,
  actionsJoined: colors.darkText,
  eventsAttended: colors.darkText,
  'near to you': colors.darkText,
  organisations: colors.darkText,
  outOffPlatform: colors.white,
  ongoingActivities: colors.white,
};
