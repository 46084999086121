import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { sendMessageToReactNativeWebview } from '~/pages/SignInMobile';
import { ESliceNames } from '~/store/types';
import { IAuthResponseDTO } from '~/types/dtos';

import { IAuthState } from './types';

const initialState: IAuthState = {
  accessToken: undefined,
  refreshToken: undefined,
  isLoading: false,
  hasError: false,
  user: undefined,
  userAttributes: undefined,
  isAuthenticated: false,
  selectedUserProfile: undefined,
  redirectToSwitchProfile: true,
  availableAppsFromPermissionGroupsById: {},
};

export const authSlice = createSlice({
  name: ESliceNames.AUTH,
  initialState,
  reducers: {
    signIn: (state, { payload }: PayloadAction<IAuthResponseDTO>) => {
      // const [currentEcosystem] = payload.userProfiles;

      return {
        ...state,
        accessToken: payload.signInUserSession.accessToken.jwtToken,
        refreshToken: payload.signInUserSession.refreshToken.token,
        isAuthenticated: false, // We should now consider authenticated when user is done loading
        user: {
          _id: payload._id,
          email: payload.username,
          name: payload.attributes?.name || 'No name',
          email_verified: payload.attributes.email_verified,
          sub: payload.attributes.sub,
          userProfiles: payload.userProfiles,
        },
        userAttributes: {
          ...payload.attributes,
        },
      };
    },
    logout: () => {
      /**
       * This is a workaround to logout the user from the webview in react native
       * @see https://github.com/react-native-webview/react-native-webview/blob/4197bb42ce79406ff20c7e5637f78e44ca45474c/docs/Reference.md#postmessagestr
       */
      sendMessageToReactNativeWebview('logout');
      return initialState;
    },
    update: (state, { payload }: PayloadAction<IAuthState>) => {
      return {
        ...state,
        ...payload,
      };
    },

    setIsLoading: (state, { payload }: PayloadAction<boolean>) => {
      return {
        ...state,
        isLoading: payload,
      };
    },
  },
});

export const { reducer: authSliceReducer, actions: authSliceActions } =
  authSlice;
