import { Box, Hidden, useBreakpointValue, VStack } from 'native-base';
import { colors } from 'ui/theme/colors';
import { ActivitySemanticType } from 'ui/types/activities';

import CalendarInline from '~/components/CalendarInline';
import { RootPortal } from '~/components/RootPortal';
import MobileTrayDrawer from '~/components/TrayDrawer';
import ActivityApplicationFooter from '~/pages/Authenticated/v2/ActivityView/components/ActivityApplicationFooter';
import { ActivityPanel } from '~/pages/Authenticated/v2/ActivityView/components/ActivityPanel';
import ActivityLocationSearch from '~/pages/Authenticated/v2/ActivityView/components/ActivitySearch/ActivityLocationSearch';
import ActivityTeamSearch from '~/pages/Authenticated/v2/ActivityView/components/ActivitySearch/ActivityTeamSearch';
import ActivityDateSelector from '~/pages/Authenticated/v2/ActivityView/components/ActivitySelectors/ActivityDateSelector';
import ActivityLocationSelector from '~/pages/Authenticated/v2/ActivityView/components/ActivitySelectors/ActivityLocationSelector';
import ActivityTeamSelector from '~/pages/Authenticated/v2/ActivityView/components/ActivitySelectors/ActivityTeamSelector';
import TeamSelectorNavButton from '~/pages/Authenticated/v2/ActivityView/components/ActivitySelectors/ActivityTeamSelector/TeamSelectorNavButton';
import { EventApplicationPanelProps } from '~/pages/Authenticated/v2/ActivityView/views/ActivityApplication/components/EventApplicationPanel/types';

import { useEventActivityApplicationController } from './controller';
export const EventApplicationPanel: React.FC<EventApplicationPanelProps> = (
  props,
) => {
  const { showGoBackButton, onGoBackPress } = props;

  const {
    title,
    headerStyle,
    styleMap,
    filters,
    isFromHome,
    activityType,
    blockedDates,
    availableTeams,
    availableDates,
    availableLocations,
    applicationAvailability,
    handleNewDate,
    handleNewLocation,
    handleApplication,
    selectedActivity,
    showCalendarDrawer,
    showLocationDrawer,
    showTeamDrawer,
    closeMobileCalendarDrawer,
    closeMobileLocationDrawer,
    closeMobileTeamDrawer,
    openMobileCalendarDrawer,
    openMobileLocationDrawer,
    openMobileTeamDrawer,
    handleNewTeam,
    handleCreateNewTeam,
    selectedTeam,
    spotsAvailable,
  } = useEventActivityApplicationController();

  const isMobile = useBreakpointValue({ base: true, lg: false });

  return (
    <>
      <ActivityPanel
        isLoaded={!!selectedActivity}
        title={title}
        titleColor={headerStyle!.fontColor}
        headerBackground={headerStyle!.backgroundColor}
      >
        <VStack
          borderWidth={1}
          borderRadius={'12px'}
          borderColor={colors.gray[200]}
          overflow={'hidden'}
        >
          <ActivityLocationSelector
            isFromHome={isFromHome}
            availableLocations={availableLocations}
            selectedLocation={filters.location}
            onLocationChange={handleNewLocation}
            onOpen={isMobile ? openMobileLocationDrawer : undefined}
          />

          <ActivityDateSelector
            availableSpotsCount={spotsAvailable}
            showAvailableSpots={activityType !== ActivitySemanticType.TeamEvent}
            blockedDates={blockedDates}
            availableDates={availableDates}
            selectedDate={filters.startDate}
            onDateChange={handleNewDate}
            onOpen={isMobile ? openMobileCalendarDrawer : undefined}
          />

          {activityType === ActivitySemanticType.TeamEvent && (
            <ActivityTeamSelector
              onTeamChange={handleNewTeam}
              selectedTeam={selectedTeam}
              teamsAvailable={availableTeams}
              onOpen={isMobile ? openMobileTeamDrawer : undefined}
              onNewTeam={handleCreateNewTeam}
              teamMinSize={selectedActivity?.teamsMinSize}
              teamMaxSize={selectedActivity?.teamsMaxSize}
            />
          )}
        </VStack>

        <ActivityApplicationFooter
          isActionDisabled={!applicationAvailability.isAvailable}
          actionLabel={styleMap?.actionLabel || ''}
          onAction={handleApplication}
          tooltipMessage={applicationAvailability.message}
          onCancel={showGoBackButton ? onGoBackPress : undefined}
        />
      </ActivityPanel>

      <RootPortal>
        <Hidden from="lg">
          <>
            <MobileTrayDrawer
              title=""
              isOpen={showCalendarDrawer}
              onClose={closeMobileCalendarDrawer}
              size={462}
            >
              <VStack alignItems={'center'}>
                <Box w={'full'} maxW={'500px'}>
                  <CalendarInline
                    date={filters.startDate}
                    onChange={handleNewDate}
                    blockedDates={blockedDates}
                    dates={availableDates}
                    key={selectedActivity?._id}
                  />
                </Box>
              </VStack>
            </MobileTrayDrawer>

            <MobileTrayDrawer
              title=""
              isOpen={showLocationDrawer}
              onClose={closeMobileLocationDrawer}
              size={462}
            >
              <VStack alignItems={'center'}>
                <Box w={'full'} maxW={'768px'}>
                  <ActivityLocationSearch
                    locations={availableLocations}
                    selectedLocation={filters.location}
                    onLocationChange={handleNewLocation}
                    key={selectedActivity?._id}
                  />
                </Box>
              </VStack>
            </MobileTrayDrawer>

            {activityType === ActivitySemanticType.TeamEvent && (
              <MobileTrayDrawer
                title=""
                isOpen={showTeamDrawer}
                onClose={closeMobileTeamDrawer}
                size={462}
              >
                <VStack alignItems={'center'} overflowY={'auto'} space={2}>
                  <Box flex={1} w={'full'} maxW="768px">
                    <ActivityTeamSearch
                      key={selectedTeam?.name}
                      teams={availableTeams}
                      selectedTeam={selectedTeam}
                      onTeamChange={handleNewTeam}
                    />
                  </Box>
                  <Box w="full" maxW="768px">
                    <TeamSelectorNavButton onPress={handleCreateNewTeam} />
                  </Box>
                </VStack>
              </MobileTrayDrawer>
            )}
          </>
        </Hidden>
      </RootPortal>
    </>
  );
};

export default EventApplicationPanel;
