import { ESpaceOptionsKeys } from 'ui/enums';

import { ICauseOptions } from '~/types/interfaces/activity';

export interface ActivityAboutProps {
  causeOptions: ICauseOptions[];
  spaceOptions?: ESpaceOptionsKeys;
  description: string;
}

export const EXPECTED_SPACE_OPTIONS = [
  ESpaceOptionsKeys.Both,
  ESpaceOptionsKeys.Indoor,
  ESpaceOptionsKeys.Outdoor,
];
