import { useEffect, useState } from 'react';
import { MeasurementCategory } from 'ui/enums';
import { MeasurementUnit } from 'ui/types/measurementUnit';
import { formatDateByEnglishType } from 'ui/utils/formatter';

import { MeasurementCategoryIconMap } from '~/components/v2/LogCollapse/constants';
import { formatMeasurementAmount } from '~/utils/measurements/format';

import { LogItemProps } from './types';

export const useLogItemController = ({
  measurement,
  onAction,
  showActionButtons = false,
}: LogItemProps) => {
  const [dateStr, setDateStr] = useState('');
  const [amountStr, setAmountStr] = useState('');
  const [measurementIcon, setMeasurementIcon] = useState('');

  useEffect(() => {
    const { amount, measurementType, measurementUnit, measurementDate } =
      measurement;

    const amountStr = formatMeasurementAmount(
      amount,
      measurementType,
      measurementUnit as unknown as MeasurementUnit,
    );

    const measurementIcon =
      MeasurementCategoryIconMap[measurementType as MeasurementCategory] ||
      'clock';

    const measurementDateStr = measurementDate
      ? formatDateByEnglishType(new Date(measurementDate), 'british')
      : '';

    setAmountStr(amountStr);
    setDateStr(measurementDateStr || '');
    setMeasurementIcon(measurementIcon);
  }, [measurement]);

  const handleDeleteAction = () => {
    onAction?.('delete');
  };

  return {
    dateStr,
    amountStr,
    measurementIcon,
    handleDeleteAction,
    showActionButtons,
  };
};
