import { ILocation } from 'ui/types/interfaces';

import { IAddress } from '~/types/interfaces/activity';

export type Coordinates = {
  lat: number;
  lng: number;
};

export function getCoordinatesFromAddress(address: IAddress): Coordinates {
  return {
    lat: address?.location?.coordinates[1] || 0,
    lng: address?.location?.coordinates[0] || 0,
  };
}

export function getCoordinatesFromLocation(location: ILocation): Coordinates {
  return { lat: location.lat, lng: location.lng };
}
