import { mapSearchActivityDefinition } from 'ui/utils/activity/mappers';

import { SEARCH_COLLECTION_CONFIG_MAP } from '~/components/Layouts/v2/SearchLayout/constants/search-collections';
import { ActivityCarouselDataSource } from '~/components/v2/ActivityCarousel/types';
import { PageDefaultFilters } from '~/pages/Authenticated/v2/InApp/components/CarouselCollections/types';
import { ActivityDefinitionActionsPublicSearch as ActionSearchApi } from '~/services/resources/activity-definitions/search/actions/actions-public-search';
import { ActivityDefinitionEventsPublicSearch as EventsSearchApi } from '~/services/resources/activity-definitions/search/events/events-public-search';
import { ActivityDefinitionOngoingPublicSearch as OngoingSearchApi } from '~/services/resources/activity-definitions/search/ongoing/ongoing-public-search';
import { SearchCollectionSlug } from '~/types/interfaces/activity-search/types';
import { mapActionsSearchApiFilters } from '~/utils/activity-search/map-actions-search-api-filters';
import {
  mapEventFromHomeSearchApiFilters,
  mapEventInPersonSearchApiFilters,
} from '~/utils/activity-search/map-events-search-api-filters';
import {
  mapFromHomeOngoingSearchApiFilters,
  mapInPersonOngoingSearchApiFilters,
} from '~/utils/activity-search/map-ongoing-search-api-filters';

export const DEFAULT_CAROUSEL_LIMIT = 10;

export const generateInPersonEventsDataSource = (
  filters: PageDefaultFilters,
  setter: (dataSource: ActivityCarouselDataSource | null) => void,
) => {
  const { apps, categoryFilters, position } = filters;

  /* In Person events */
  let inPersonEventsDataSource = null;
  const collectionFilters = SEARCH_COLLECTION_CONFIG_MAP.get(
    SearchCollectionSlug.Events,
  )?.filters;

  if (collectionFilters) {
    const combinedFilters = {
      ...collectionFilters,
      ...(categoryFilters ?? {}),
    };

    const filters = {
      ...mapEventInPersonSearchApiFilters(apps, position, combinedFilters),
      limit: DEFAULT_CAROUSEL_LIMIT,
    };

    inPersonEventsDataSource = new ActivityCarouselDataSource(
      EventsSearchApi.findInPersonEvents(filters).then(
        mapSearchActivityDefinition(position),
      ),
      [SearchCollectionSlug.Events, JSON.stringify(filters), 'in-app'],
      ({ isLoading, dataLength }) => {
        if (!dataLength && !isLoading) {
          setter(null);
        }
      },
    );
  }

  setter(inPersonEventsDataSource);
};

export const generateFromHomeEventsDataSource = (
  filters: PageDefaultFilters,
  setter: (dataSource: ActivityCarouselDataSource | null) => void,
) => {
  const { apps, categoryFilters, position } = filters;

  let fromHomeEventsDataSource = null;

  const collectionFilters = SEARCH_COLLECTION_CONFIG_MAP.get(
    SearchCollectionSlug.EventsFromHome,
  )?.filters;

  if (collectionFilters) {
    const combinedFilters = {
      ...collectionFilters,
      ...(categoryFilters ?? {}),
    };

    const filters = {
      ...mapEventFromHomeSearchApiFilters(apps, position, combinedFilters),
      limit: DEFAULT_CAROUSEL_LIMIT,
    };

    fromHomeEventsDataSource = new ActivityCarouselDataSource(
      EventsSearchApi.findFromHomeEvents(filters).then(
        mapSearchActivityDefinition(position),
      ),
      [SearchCollectionSlug.Events, JSON.stringify(filters), 'in-app'],
      ({ isLoading, dataLength }) => {
        if (!dataLength && !isLoading) {
          setter(null);
        }
      },
    );
  }
  setter(fromHomeEventsDataSource);
};

export const generateTeamEventsDataSource = (
  filters: PageDefaultFilters,
  setter: (dataSource: ActivityCarouselDataSource | null) => void,
) => {
  const { apps, position, categoryFilters } = filters;

  /* Team events */
  let teamEventsDataSource = null;
  const collectionFilters = SEARCH_COLLECTION_CONFIG_MAP.get(
    SearchCollectionSlug.TeamEvents,
  )?.filters;

  if (collectionFilters) {
    const combinedFilters = {
      ...collectionFilters,
      ...(categoryFilters ?? {}),
    };

    const filters = {
      ...mapEventInPersonSearchApiFilters(apps, position, combinedFilters),
      limit: DEFAULT_CAROUSEL_LIMIT,
    };

    teamEventsDataSource = new ActivityCarouselDataSource(
      EventsSearchApi.findInPersonEvents(filters).then(
        mapSearchActivityDefinition(position),
      ),
      [SearchCollectionSlug.TeamEvents, JSON.stringify(filters), 'in-app'],
      ({ isLoading, dataLength }) => {
        if (!dataLength && !isLoading) {
          setter(null);
        }
      },
    );
  }

  setter(teamEventsDataSource);
};

export const generateActionDataSource = (
  filters: PageDefaultFilters,
  setter: (dataSource: ActivityCarouselDataSource | null) => void,
) => {
  const { apps, position, categoryFilters } = filters;

  let actionsDataSource = null;
  const collectionFilters = SEARCH_COLLECTION_CONFIG_MAP.get(
    SearchCollectionSlug.Actions,
  )?.filters;

  if (collectionFilters) {
    const combinedFilters = {
      ...collectionFilters,
      ...(categoryFilters ?? {}),
    };

    const filters = {
      ...mapActionsSearchApiFilters(apps, combinedFilters),
      limit: DEFAULT_CAROUSEL_LIMIT,
    };

    actionsDataSource = new ActivityCarouselDataSource(
      ActionSearchApi.findActions(filters).then(
        mapSearchActivityDefinition(position),
      ),
      [SearchCollectionSlug.Actions, JSON.stringify(filters), 'in-app'],
      ({ isLoading, dataLength }) => {
        if (!dataLength && !isLoading) {
          setter(null);
        }
      },
    );
  }

  setter(actionsDataSource);
};

export const generateInPersonOngoingDataSource = (
  filters: PageDefaultFilters,
  setter: (dataSource: ActivityCarouselDataSource | null) => void,
) => {
  const { apps, position, categoryFilters } = filters;

  let inPersonOngoingDataSource = null;
  const collectionFilters = SEARCH_COLLECTION_CONFIG_MAP.get(
    SearchCollectionSlug.Ongoing,
  )?.filters;

  if (collectionFilters) {
    const combinedFilters = {
      ...collectionFilters,
      ...(categoryFilters ?? {}),
    };

    const filters = {
      ...mapInPersonOngoingSearchApiFilters(apps, position, combinedFilters),
      limit: DEFAULT_CAROUSEL_LIMIT,
    };

    inPersonOngoingDataSource = new ActivityCarouselDataSource(
      OngoingSearchApi.findInPersonOngoing(filters).then(
        mapSearchActivityDefinition(position),
      ),
      [SearchCollectionSlug.Ongoing, JSON.stringify(filters), 'in-app'],
      ({ isLoading, dataLength }) => {
        if (!dataLength && !isLoading) {
          setter(null);
        }
      },
    );
  }

  setter(inPersonOngoingDataSource);
};

export const generateFromHomeOngoingDataSource = (
  filters: PageDefaultFilters,
  setter: (dataSource: ActivityCarouselDataSource | null) => void,
) => {
  const { apps, position, categoryFilters } = filters;

  let fromHomeOngoingDataSource = null;
  const collectionFilters = SEARCH_COLLECTION_CONFIG_MAP.get(
    SearchCollectionSlug.RemoteOngoing,
  )?.filters;

  if (collectionFilters) {
    const combinedFilters = {
      ...collectionFilters,
      ...(categoryFilters ?? {}),
    };

    const filters = {
      ...mapFromHomeOngoingSearchApiFilters(apps, combinedFilters),
      limit: DEFAULT_CAROUSEL_LIMIT,
    };

    fromHomeOngoingDataSource = new ActivityCarouselDataSource(
      OngoingSearchApi.findFromHomeOngoing(filters).then(
        mapSearchActivityDefinition(position),
      ),
      [SearchCollectionSlug.RemoteOngoing, JSON.stringify(filters), 'in-app'],
      ({ isLoading, dataLength }) => {
        if (!dataLength && !isLoading) {
          setter(null);
        }
      },
    );
  }

  setter(fromHomeOngoingDataSource);
};
