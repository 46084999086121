import { debounce } from 'lodash';
import { useBreakpointValue } from 'native-base';
import { useCallback, useEffect, useState } from 'react';

import { DEFAULT_COLUMNS_TOKENS, DEFAULT_SPACE_TOKENS } from './constants';
import { PrimaryCarouselProps } from './types';
import { calculateIndex } from './utils';

export const usePrimaryCarouselController = ({
  columnsTokens = DEFAULT_COLUMNS_TOKENS,
  space = DEFAULT_SPACE_TOKENS,
  slides,
}: PrimaryCarouselProps) => {
  // States
  const [currentIndex, setCurrentIndex] = useState(0);
  const [{ isPrevDisabled, isNextDisabled }, setNavButtonState] = useState({
    isPrevDisabled: true,
    isNextDisabled: false,
  });

  // Hooks
  const columnsInViewPort = useBreakpointValue(columnsTokens);

  // Handlers
  const handleChangeIndex = useCallback(
    (newIndex: number) => {
      const total = slides.length;
      const { index, isPrevEnabled, isNextEnabled } = calculateIndex(
        newIndex,
        columnsInViewPort,
        total,
      );

      setCurrentIndex(index);
      setNavButtonState({
        isPrevDisabled: !isPrevEnabled,
        isNextDisabled: !isNextEnabled,
      });
    },
    [columnsInViewPort, slides],
  );

  // Debounced version of the handler
  const handleChangeIndexDebounced = useCallback(
    debounce(handleChangeIndex, 100),
    [handleChangeIndex],
  );

  const handlePrev = useCallback(() => {
    const step = columnsInViewPort * -1;
    const newIndex = currentIndex + step;
    handleChangeIndexDebounced(newIndex);
  }, [columnsInViewPort, currentIndex, handleChangeIndexDebounced]);

  const handleNext = useCallback(() => {
    const step = columnsInViewPort;
    const newIndex = currentIndex + step;
    handleChangeIndexDebounced(newIndex);
  }, [columnsInViewPort, currentIndex, handleChangeIndexDebounced]);

  // Rollback to the state every time screen width changes
  useEffect(() => {
    if (columnsInViewPort !== undefined) {
      handleChangeIndex(0);
    }
  }, [columnsInViewPort, handleChangeIndex]);

  useEffect(() => {
    return () => {
      handleChangeIndexDebounced.cancel();
    };
  }, [handleChangeIndexDebounced]);

  return {
    space,
    currentIndex,
    isPrevDisabled,
    isNextDisabled,
    columnsInViewPort,
    handlePrev,
    handleNext,
  };
};
