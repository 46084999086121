import 'react-toastify/dist/ReactToastify.css';

import { PostHogProvider } from 'posthog-js/react';
import { QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';

import { store } from '~/store';
import ThemeProvider from '~/theme/ThemeProvider';
import { ToastStyledContainer } from '~/theme/ToastContainer';
import { ReactFunctionComponent } from '~/types/interfaces';

import { queryClient } from './config/react-query.config';
import { renderToastItem } from './config/toast';
import useAnalytics from './hooks/useAnalytics/useAnalytics';

const Providers = ({ children }: ReactFunctionComponent) => {
  const { client: posthogClient } = useAnalytics();

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider>
        <PostHogProvider client={posthogClient}>
          <Provider store={store}>{children}</Provider>
          <ToastStyledContainer
            closeButton={false}
            autoClose={3000}
            icon={renderToastItem}
            hideProgressBar
          />
        </PostHogProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
};

export default Providers;
