import { Box, Hidden, VStack } from 'native-base';

import ActivityAbout from '~/pages/Authenticated/v2/ActivityView/components/ActivityAbout';
import ActivityDistanceLabel from '~/pages/Authenticated/v2/ActivityView/components/ActivityDistanceLabel';
import ActivityExternalApply from '~/pages/Authenticated/v2/ActivityView/components/ActivityExternalApply';
import { useActivityFeedController } from '~/pages/Authenticated/v2/ActivityView/components/ActivityFeed/controller';
import { ActivityFeedProps } from '~/pages/Authenticated/v2/ActivityView/components/ActivityFeed/type';
import ActivityHeadline from '~/pages/Authenticated/v2/ActivityView/components/ActivityHeadline';
import ActivityImagesCarousel from '~/pages/Authenticated/v2/ActivityView/components/ActivityImagesCarousel';
import ActivityLocation from '~/pages/Authenticated/v2/ActivityView/components/ActivityLocation';
import ActivitySpecifications from '~/pages/Authenticated/v2/ActivityView/components/ActivitySpecifications';
import { ActivityViewSection } from '~/pages/Authenticated/v2/ActivityView/components/ActivityViewSection';
import { useActivityController } from '~/pages/Authenticated/v2/ActivityView/controllers/activity-controller';
import { App } from '~/types/interfaces/app';

export const ActivityFeed = (props: ActivityFeedProps) => {
  const { activityDefinition, selectedActivity, children } = props;

  const {
    activityType,
    requirementOptions,
    volunteerRequirements,
    volunteerRewards,
    wasExternallyApplied,
    hasVolunteerRequirements,
  } = useActivityFeedController(props);

  const { daysToBegin, distance } = useActivityController({
    activity: selectedActivity,
  });

  return (
    <VStack>
      {/* HEADLINE */}
      <ActivityHeadline
        type={activityType}
        distance={distance}
        daysToBegin={daysToBegin}
        title={activityDefinition.title}
        app={activityDefinition?.app as unknown as App}
        organization={activityDefinition.organizationSubDocument}
      />

      <Hidden from="lg">
        <Box mt={8}>{children}</Box>
      </Hidden>

      {distance != -1 && (
        <Box mt={8}>
          <ActivityDistanceLabel distance={distance} />
        </Box>
      )}

      {/* BODY */}
      <VStack space={6} mt={12}>
        <ActivityViewSection title="About the opportunity">
          <ActivityAbout
            causeOptions={activityDefinition.causeOptions}
            spaceOptions={activityDefinition.spaceOptions}
            description={activityDefinition.description || ''}
          />
        </ActivityViewSection>

        <ActivityViewSection
          hide={!wasExternallyApplied}
          title="External apply link"
        >
          <ActivityExternalApply
            externalApplyLink={selectedActivity?.externalApplyLink}
          />
        </ActivityViewSection>

        <ActivityViewSection
          hide={!requirementOptions.length}
          title="You will need"
        >
          <ActivitySpecifications specifications={requirementOptions} />
        </ActivityViewSection>

        {!!hasVolunteerRequirements && (
          <>
            <ActivityViewSection
              hide={!volunteerRequirements.length}
              title="You will need to bring"
            >
              <ActivitySpecifications specifications={volunteerRequirements} />
            </ActivityViewSection>

            <ActivityViewSection
              hide={!volunteerRewards.length}
              title="You will receive"
            >
              <ActivitySpecifications specifications={volunteerRewards} />
            </ActivityViewSection>
          </>
        )}

        {(activityDefinition?.carouselImages ?? []).length > 0 && (
          <ActivityImagesCarousel
            carouselImages={activityDefinition.carouselImages}
          />
        )}

        <ActivityLocation
          locationOption={activityDefinition.locationOption}
          address={selectedActivity?.address}
          meetingLink={selectedActivity?.meetingLink || ''}
          regions={selectedActivity?.regions ?? []}
        />
      </VStack>
    </VStack>
  );
};

export default ActivityFeed;
