import { HStack, Stack } from 'native-base';
import React from 'react';
import CardPropInfo from 'ui/components/Cards/CardPropInfo';

import { CardRecommendationsInfoContentProps } from '~/components/ActivityCards/CardRecommendations/components/CardRecommendationsInfoContent/cardRecommendationsInfoContent.types';

export const CardRecommendationsInfoContent = ({
  infos,
  ...cardInfoProps
}: CardRecommendationsInfoContentProps) => {
  return (
    <HStack alignItems={'center'} space={3}>
      {infos.map(({ info, icon }) => {
        if (!info) return null;
        return (
          <Stack key={info} space={2} w={'100%'}>
            <CardPropInfo
              key={info}
              justifyContent={'flex-start'}
              icon={icon}
              info={info}
              {...cardInfoProps}
            />
          </Stack>
        );
      })}
    </HStack>
  );
};
