import { Box, Pressable } from 'native-base';
import { ForwardedRef, forwardRef } from 'react';
import { RootPortal } from 'ui/components/v2/RootPortal';

import { useDropDownBaseController } from './controller';
import { DropDownActionSheet } from './Overlay/ActionSheet';
import { DropDownPopOver } from './Overlay/PopOver';
import { DropDownBaseProps, DropDownImperativeRef } from './types';

export const DropDownBase = forwardRef(function ImperativeDropDownBase(
  props: DropDownBaseProps,
  ref: ForwardedRef<DropDownImperativeRef>,
) {
  const {
    isOpen,
    isMobile,
    containerRef,
    handleCloseOverlay,
    handleToggleOverlay,
  } = useDropDownBaseController(props, ref);

  return (
    <Box w="full" position={'relative'} ref={containerRef}>
      {/* Trigger content */}
      <Pressable
        w="full"
        accessibilityLabel="More options menu"
        onPress={handleToggleOverlay}
      >
        {props.children}
      </Pressable>

      {/* Overlay */}
      {isMobile ? (
        <RootPortal>
          <DropDownActionSheet isOpen={isOpen} onClose={handleCloseOverlay}>
            {props.overlayContent}
          </DropDownActionSheet>
        </RootPortal>
      ) : (
        <DropDownPopOver
          isOpen={isOpen}
          anchor={props.popoverAnchor}
          onClose={handleCloseOverlay}
          top={props.popoverTop}
          maxW={props.popoverMaxW}
          maxH={props.popoverMaxH}
          borderRadius={props.popoverBorderRadius}
        >
          {props.overlayContent}
        </DropDownPopOver>
      )}
    </Box>
  );
});

export default DropDownBase;
