import { colors } from 'ui/theme/colors';

import {
  CalendarDateTimeState,
  CalendarDateTimeStyle,
} from '~/components/CalendarInline/components/CalendarDateTime/types';

export const CalendarDateTimeStyleMap = new Map<
  CalendarDateTimeState,
  CalendarDateTimeStyle
>([
  [
    CalendarDateTimeState.available,
    {
      backgroundColor: '#f5f5f4',
      borderColor: 'transparent',
      fontColor: '#151518',
      tooltipLabel: '',
    },
  ],
  [
    CalendarDateTimeState.selected,
    {
      backgroundColor: colors.black,
      borderColor: 'transparent',
      fontColor: colors.white,
      tooltipLabel: 'Date Selected',
    },
  ],
  [
    CalendarDateTimeState.blocked,
    {
      backgroundColor: colors.gray[600],
      borderColor: 'transparent',
      fontColor: colors.white,
      tooltipLabel: 'Already booked',
    },
  ],
]);
