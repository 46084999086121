import { EEventApplicationType } from 'ui/enums';
import { SearchActivityDefinition } from 'ui/types/interfaces/activity-definition';
import { safeDateFormat } from 'ui/utils/formatDates';

export const DebugContainer = ({
  item,
}: {
  item: SearchActivityDefinition;
}) => {
  const {
    score = '',
    searchScore = '',
    vectorScore = '',
    distance = '',
    startDate,
    bookingsNumber,
    address,
  } = item.selectedActivity ?? {};

  const startDateStr = safeDateFormat(startDate, 'yyyy-MM-dd');
  const createAtStr = safeDateFormat(item.createdAt, 'yyyy-MM-dd');

  return (
    <div className="debug-container">
      <span>type: {item.type}</span>
      <span>_id: {item._id}</span>
      <span style={{ color: 'red' }}>app: {item?.appSummary?.name || ''}</span>

      <span>createdAt: {createAtStr}</span>
      <span>startDate: {startDateStr}</span>
      <span>distance: {distance}</span>
      <span>address: {address?.street}</span>
      <hr />
      <span style={{ color: 'green' }}>score: {score}</span>
      <span style={{ color: 'red' }}>searchScore: {searchScore}</span>
      <span style={{ color: 'blue' }}>semanticScore: {vectorScore}</span>
      <span>HostingOrganization: {item.organizationSubDocument?.name}</span>
      <span>
        Is a group activity?{' '}
        {item.eventApplicationType === EEventApplicationType.Team
          ? 'Yes'
          : 'No'}
      </span>
      <span>
        Causes:{' '}
        {item?.causeOptions?.map((cause) => cause.displayName).join(', ')}
      </span>
      <span>Type of location: {item.locationOption}</span>
      <span>bookingsNumber: {bookingsNumber || 0}</span>
    </div>
  );
};
