import { useMemo, useState } from 'react';
import { MeasurementUnit } from 'ui/types/measurementUnit';

import { LogItemActions } from '~/components/v2/LogCollapse/components/LogItem/types';
import { ILogCollapseProps } from '~/components/v2/LogCollapse/types';
import { PopulatedMeasurement } from '~/types/interfaces/measurement';
import { formatMeasurementAmount } from '~/utils/measurements/format';

import { MeasurementCategoryIconMap } from './constants';

export const useLogCollapseController = ({
  measurements,
  onAction,
}: ILogCollapseProps) => {
  const { measurementUnit, measurementType } = measurements[0];

  //States
  const [showCollapsed, setShowCollapsed] = useState(false);
  const [showEditButton, setShowEditButton] = useState(false);

  // hooks
  const amountStr = useMemo(() => {
    if (!measurements.length) return 0;

    const amount = measurements.reduce((acc, curr) => acc + curr.amount, 0);
    return formatMeasurementAmount(
      amount,
      measurementType,
      measurementUnit as unknown as MeasurementUnit,
    );
  }, [measurements]);

  // Handlers
  const toggleCollapsedContent = () => {
    setShowCollapsed((prev) => !prev);
  };

  const toggleEditAction = () => {
    setShowEditButton((prev) => !prev);
  };

  const handleLogAction = (
    action: LogItemActions,
    measurement: PopulatedMeasurement,
  ) => {
    onAction?.(action, measurement);
  };

  // Computed
  const measurementIcon =
    MeasurementCategoryIconMap[measurementType] || 'clock';

  const showCollapsedTrigger = !!measurements.length;

  return {
    measurementIcon,
    amountStr,
    measurements,
    showCollapsed,
    showCollapsedTrigger,
    showEditButton,
    handleLogAction,
    toggleEditAction,
    toggleCollapsedContent,
  };
};
