import { Box, HStack } from 'native-base';
import { useParams } from 'react-router-dom';

import { ShareActivity } from '~/components/ShareActivity';
import { ActivityHeaderProps } from '~/pages/Authenticated/v2/ActivityView/components/ActivityHeader/type';
import { makeActivityLink } from '~/pages/Authenticated/v2/ActivityView/utils';
import { IActivityDefinitionSubDocument } from '~/types/interfaces/activity';

import { ActivityBreadCrumbs } from '../ActivityBreadCrumbs';

export const ActivityHeader = (props: ActivityHeaderProps) => {
  const { activityDefinition } = props;
  const { appId } = useParams();

  return (
    <HStack justifyContent={'space-between'}>
      <ActivityBreadCrumbs
        appId={appId}
        activityDefinition={
          activityDefinition as unknown as IActivityDefinitionSubDocument
        }
      />
      <Box>
        <ShareActivity
          activityLink={makeActivityLink(activityDefinition._id)}
        />
      </Box>
    </HStack>
  );
};

export default ActivityHeader;
