import { BHO_HELP_CENTER_URL } from 'ui/constants/commonUrl.constants';

import { DrawerMenuItemSection } from '~/components/MobileAppDrawer/types';
import { DOIT_ORG_ADMIN_URL } from '~/config';
import { PAGES } from '~/constants/pages.constants';
import { MyWalletMenuPermissions } from '~/constants/volunteer.permissions.constants';

export const drawerMenuSections: DrawerMenuItemSection[] = [
  {
    section: '',
    items: [
      {
        text: 'Teams',
        icon: 'users',
        pagePath: PAGES.Teams,
        permissions: MyWalletMenuPermissions.Teams,
      },
      {
        text: 'Log activity',
        icon: 'plus-circle',
        pagePath: PAGES.LogActivities,
        permissions: MyWalletMenuPermissions.LogActivity,
      },
      {
        pagePath: PAGES.Opportunities,
        text: 'Opportunities',
        icon: 'list',
        permissions: MyWalletMenuPermissions.Opportunities,
      },
    ],
  },
  {
    section: 'Settings',
    items: [
      {
        text: 'Account Settings',
        icon: 'settings',
        pagePath: PAGES.AccountSettings,
        permissions: [],
      },
      {
        text: 'Email Preferences',
        icon: 'mail',
        pagePath: PAGES.EmailPreferences,
        permissions: [],
      },
    ],
  },
  {
    section: '',
    items: [
      {
        text: 'Help centre',
        icon: 'question',
        pagePath: BHO_HELP_CENTER_URL,
        permissions: [],
        action: 'external',
      },
      {
        text: 'Go to my admin area',
        icon: 'external-link',
        pagePath: DOIT_ORG_ADMIN_URL,
        permissions: [],
        action: 'external',
      },
      {
        text: 'Sign out',
        icon: 'log-out',
        pagePath: PAGES.EmailPreferences,
        permissions: [],
        action: 'custom',
      },
    ],
  },
];
