import { useQuery } from 'react-query';

import { InsightsService } from '~/services/resources/insights/InsightsService';

export const useUserProfileTotalTimeLogged = (userProfileId?: string) => {
  const { data, isLoading } = useQuery<number>({
    queryKey: `${InsightsService.BASE_URL}-user-profile-${userProfileId}`,
    queryFn: () =>
      InsightsService.getUserProfileTotalTimeLogged(userProfileId || ''),
    enabled: !!userProfileId,
  });

  return {
    data,
    isLoading,
  };
};
