import { useMemo, useState } from 'react';

import { colors } from '../../theme/colors';
import { ICustomInputProps } from './types';

export const useInputController = ({
  type,
  label,
  _formControl,
  hasError,
  control,
  isNumeric,
  name,
  isInvalid,
  leftIconErrorSize = 16,
  onChange,
  defaultCountry = 'GB',
  value,
  isDisabled,
  isRequired,
  isReadOnly,
  errorMessage,
  helperText,
  iconErrorMessage,
  leftIconName,
  leftIconColor = colors.gray[500],
  leftIconSize = 16,
  rightIconColor = colors.gray[500],
  rightIconSize = 16,
  rightIconName,
  isShowPasswordToggle = true,
  showPasswordRules,
  helpIcon,
  helpIconColor,
  handleOpenHelpIcon,
  labelColor = colors.gray[500],
  onChangeTransform,
  ...restProps
}: ICustomInputProps) => {
  const [currentType, setCurrentType] = useState(type);

  const toggleShowPasswordType = () => {
    setCurrentType((prev) => (prev === 'password' ? 'text' : 'password'));
  };

  const isPasswordField = useMemo(() => type === 'password', [type]);

  return {
    leftIconName,
    leftIconColor,
    leftIconSize,
    label,
    _formControl,
    hasError,
    isInvalid,
    isDisabled,
    onChange,
    value,
    isNumeric,
    isRequired,
    isReadOnly,
    errorMessage,
    defaultCountry,
    iconErrorMessage,
    rightIconName,
    helperText,
    rightIconColor,
    control,
    name,
    rightIconSize,
    leftIconErrorSize,
    currentType,
    isShowPasswordToggle,
    isPasswordField,
    toggleShowPasswordType,
    showPasswordRules,
    helpIcon,
    helpIconColor,
    handleOpenHelpIcon,
    labelColor,
    restProps,
    onChangeTransform,
  };
};
