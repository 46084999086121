import { Box, HStack, Skeleton, View, VStack } from 'native-base';

export const ActivityCardSkeleton = ({}) => {
  return (
    <Box py={4} px={2} borderRadius="md" backgroundColor="white" w="full">
      <VStack space={3}>
        <View h={'0'} w={'full'} position={'relative'} paddingBottom={'68%'}>
          <Skeleton position={'absolute'} h={'100%'} w={'full'} rounded="8" />
        </View>
        <VStack space={2}>
          <Skeleton.Text h={7} space={2} lines={2} width="80%" />
        </VStack>

        <HStack space={2} alignItems={'center'}>
          <Skeleton h={'20px'} w={'20px'} rounded="full" />
          <Skeleton.Text h={2} lines={1} width="30%" />
        </HStack>

        <Skeleton.Text lines={1} h={2} width="60%" />
      </VStack>
    </Box>
  );
};
