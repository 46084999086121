import { colors } from '../../../../../../../theme/colors';
import { SelectGroupActionVariants } from './types';

export const SelectGroupActionButtonStyleMap: Record<
  SelectGroupActionVariants,
  {
    fontColor: string;
    borderColor: string;
    actionLabel: string;
    iconName: string;
  }
> = {
  add: {
    fontColor: colors.singletons.black,
    borderColor: colors.singletons.black,
    actionLabel: 'Add',
    iconName: 'plus-circle',
  },
  delete: {
    fontColor: colors.error[600],
    borderColor: colors.error[400],
    actionLabel: 'Delete',
    iconName: 'trash',
  },
};
