import { Navigate, Route, useLocation, useParams } from 'react-router-dom';

import { PAGES } from '~/constants/pages.constants';
import { useVolunteerEcosystemAvailableApps } from '~/hooks/useApps/useVolunteerEcosystemAvailableApps';

const AppRedirect = ({ to }: { to: string }) => {
  const { appIds } = useVolunteerEcosystemAvailableApps();
  const singleAppId = appIds[0];

  const params = useParams();
  const location = useLocation();

  const path = Object.entries(params).reduce((path, [key, value]) => {
    return path.replace(`:${key}`, value as string);
  }, to);

  const searchParams = new URLSearchParams(location.search).toString();
  const redirectTo = searchParams ? `${path}?${searchParams}` : path;

  return <Navigate to={redirectTo.replace(':appId', singleAppId)} replace />;
};

export const SingleAppPersonaRoutes = () => {
  return (
    <>
      <Route path={PAGES.Home} element={<AppRedirect to={PAGES.InApp} />} />

      <Route
        path={PAGES.Search}
        element={<AppRedirect to={PAGES.InAppSearch} />}
      />

      <Route
        path={PAGES.SearchCollection}
        element={<AppRedirect to={PAGES.InAppSearchCollection} />}
      />
    </>
  );
};
