import { Stack, Text } from 'native-base';
import Illustration, { ILLUSTRATIONS_NAME } from 'ui/components/Illustration';

import { colors } from '../../theme/colors';
import Button from '../Button';
import { RedirectPageProps } from './types';

export const RedirectPage = ({
  title,
  text,
  onButtonPress,
  buttonColor = colors.primary[600],
  buttonWidth = '50%',
  textWidth = '60%',
  buttonText = 'Go to home',
}: RedirectPageProps) => {
  return (
    <Stack
      space={10}
      height="80vh"
      alignItems="center"
      justifyContent="center"
      p={5}
    >
      <Illustration
        maxWidth="313px"
        maxHeight="255px"
        width="100%"
        height="100%"
        name={ILLUSTRATIONS_NAME.LOADING}
      />
      <Stack space={3} alignItems="center">
        {title ? (
          <Text fontWeight="500" fontSize="2xl">
            {title}
          </Text>
        ) : null}

        {text ? (
          <Text
            mt={4}
            maxW={textWidth}
            textAlign="center"
            fontSize="md"
            fontWeight={400}
          >
            {text}
          </Text>
        ) : null}
        <Button
          bgColor={buttonColor}
          width={buttonWidth}
          onPress={onButtonPress}
        >
          {buttonText}
        </Button>
      </Stack>
    </Stack>
  );
};
