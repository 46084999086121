import { EEventApplicationType, MeasurementCategory } from 'ui/enums';
import { ActivitySemanticType } from 'ui/types/activities';
import { MeasurementUnit } from 'ui/types/measurementUnit';
import { secondsToHoursAndMinutes } from 'ui/utils/secondsToDuration';

import { IActivityDefinition } from '~/types/interfaces/activity';

export const getActivityDefinitionSemanticType = (
  activityDefinition: IActivityDefinition,
): ActivitySemanticType => {
  return activityDefinition.eventApplicationType === EEventApplicationType.Team
    ? ActivitySemanticType.TeamEvent
    : (activityDefinition?.type as ActivitySemanticType);
};

export const getActivityLogAmount = (
  measurementUnit: MeasurementUnit,
  amount: number,
) => {
  let targetAmount = amount;

  if (
    measurementUnit.category === MeasurementCategory.Time &&
    measurementUnit.pluralLabel === 'Hours'
  ) {
    targetAmount = Number(secondsToHoursAndMinutes(targetAmount).hours);
  }

  const unitLabel =
    targetAmount != 1
      ? measurementUnit.pluralLabel
      : measurementUnit.singularLabel;

  const value = `${targetAmount} ${unitLabel}`;
  return value;
};
