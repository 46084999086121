/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { ILocation } from 'ui/types/interfaces';
import { Address } from 'ui/types/interfaces/address';

import { useAppSelector } from '~/hooks/useAppSelector';
import { sessionSlice } from '~/store/slices/session';
import { getCoordinatesFromLocation } from '~/utils/getCoordinates';
import { getLocationFromAddress } from '~/utils/getLocation';

export const useUserLocationController = () => {
  // -- Providers
  const dispatch = useDispatch();
  const { user } = useAppSelector(({ auth }) => auth);
  const { location, isLocationModalOpen } = useAppSelector(
    ({ session }) => session,
  );
  // -- Hooks
  useEffect(() => {
    // -- Set User's Preferred location location if no location were found on session */
    if (!!location || !user?.preferences?.address) return;
    const userPreferredLocation = getLocationFromAddress(
      user.preferences?.address as unknown as Address,
    );
    dispatch(sessionSlice.actions.setLocation(userPreferredLocation));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, user]);

  // -- Handlers
  const openLocationModal = () => {
    dispatch(sessionSlice.actions.openLocationModal());
  };

  const closeLocationModal = () => {
    dispatch(sessionSlice.actions.closeLocationModal());
  };

  const handleLocationChange = (newLocation?: ILocation) => {
    dispatch(sessionSlice.actions.setLocation(newLocation || null));
  };

  const clearLocation = () => {
    dispatch(sessionSlice.actions.setLocation(null));
  };

  const coordinates = useMemo(() => {
    if (!location) return null;
    return getCoordinatesFromLocation(location);
  }, [location]);

  return {
    location,
    coordinates,
    isLoading: false,
    showLocationModal: isLocationModalOpen,
    openLocationModal,
    closeLocationModal,
    handleLocationChange,
    clearLocation,
  };
};
