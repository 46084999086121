import { HStack, Pressable, Stack } from 'native-base';

import { useLogProgressAndHistoryRootController } from '~/compound-components/log-progress-and-history/log-progress-and-history-root/log-progress-and-history.controller';

import { LogProgressRootProps } from './log-progress-and-history-root.types';

export const LogProgressAndHistoryRoot = ({
  children,
  isExpandable,
  isExpanded,
}: LogProgressRootProps) => {
  const { toggleIsExpanded, breakpointValue, isInsideExpanded } =
    useLogProgressAndHistoryRootController();

  return (
    <div
      className={`log-progress-root${
        isInsideExpanded || isExpanded ? '-expanded' : ''
      }`}
    >
      <Pressable
        onPress={() => {
          !breakpointValue.hideArrow && isExpandable && toggleIsExpanded();
        }}
        w={'full'}
      >
        <HStack
          w={'full'}
          bg={'gray.100'}
          py={2}
          px={3}
          borderWidth={1}
          borderColor={'gray.300'}
          borderRadius={'xl'}
          justifyContent={'space-between'}
          space={4}
        >
          <Stack w={breakpointValue.contentWidth}>{children}</Stack>
        </HStack>
      </Pressable>
    </div>
  );
};
