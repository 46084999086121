export enum COGNITO_AUTHENTICATION_FLOW {
  USER_SRP_AUTH = 'USER_SRP_AUTH',
  USER_PASSWORD_AUTH = 'USER_PASSWORD_AUTH',
  CUSTOM_AUTH = 'CUSTOM_AUTH',
}

export enum EcosystemVisibilityTypeEnum {
  PRIVATE = 'private',
  PUBLIC = 'public',
}
