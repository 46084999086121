import { ActivitySemanticType } from 'ui/types/activities';

export const defaultLogTitle = (num: number) =>
  `You have booked ${num} date${num > 1 ? 's' : ' '} `;

export const LogStyleMapByType = new Map<ActivitySemanticType, any>([
  [
    ActivitySemanticType.Event,
    {
      getTitle: defaultLogTitle,
    },
  ],
  [
    ActivitySemanticType.TeamEvent,
    {
      getTitle: defaultLogTitle,
    },
  ],
  [
    ActivitySemanticType.Action,
    {
      getTitle: () => 'You have already joined!',
    },
  ],
  [
    ActivitySemanticType.Ongoing,
    {
      getTitle: defaultLogTitle,
    },
  ],
]);
