export const styles = {
  top: {
    marginBottom: 'auto',
    marginTop: { base: 0, lg: 20 },
  },
  bottom: {
    marginBottom: 20,
    marginTop: 'auto',
  },
  left: {
    marginLeft: 0,
    marginRight: 'auto',
  },
  right: {
    marginLeft: 'auto',
    marginRight: 0,
  },
  center: {},
};
