import { HStack, Text } from 'native-base';
import React from 'react';

import { ScaffoldHeaderProps } from '~/components/Scaffold/ScaffoldHeader/scaffoldHeader.types';

export const ScaffoldHeader = ({
  title,
  customHeaderElement,
  rightElement,
}: ScaffoldHeaderProps) => {
  if (customHeaderElement) return <>{customHeaderElement}</>;

  return (
    <HStack
      space={6}
      mx={{ base: 4, md: 2 }}
      justifyContent={'space-between'}
      flexDir={{
        base: 'column',
        lg: 'row',
      }}
      zIndex={1}
    >
      {!!title && (
        <Text
          fontSize={{ base: 'xl', md: '2xl' }}
          fontWeight={'bold'}
          mb={{
            base: 4,
            lg: 0,
          }}
        >
          {title}
        </Text>
      )}
      {rightElement}
    </HStack>
  );
};
