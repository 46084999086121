export const calculateIndex = (
  givenIndex: number,
  columns: number,
  total: number,
) => {
  let resultIndex = givenIndex;

  const firstIndex = 0;
  const lastIndex = total - columns;

  if (givenIndex < firstIndex) {
    resultIndex = firstIndex;
  } else if (givenIndex > lastIndex) {
    resultIndex = lastIndex;
  }

  const isPrevEnabled = isPrevIndexAllowed(resultIndex);
  const isNextEnabled = isNextIndexAllowed(resultIndex, columns, total);

  return { index: resultIndex, isPrevEnabled, isNextEnabled };
};

export const isPrevIndexAllowed = (currentIndex: number) => {
  const firstIndex = 0;
  if (currentIndex <= firstIndex) {
    return false;
  }
  return true;
};

export const isNextIndexAllowed = (
  currentIndex: number,
  columns: number,
  total: number,
) => {
  const lastIndex = total - columns;
  if (currentIndex >= lastIndex) {
    return false;
  } else if (total < columns) {
    return false;
  }

  return true;
};
