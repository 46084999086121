import { HStack, Text } from 'native-base';
import { useMemo } from 'react';
import Icon from 'ui/components/Icon';
import { colors } from 'ui/theme/colors';

import { convertMetersToMiles } from '~/pages/Authenticated/InApp/utils/metersToMiles';
import { ActivityDistanceLabelProps } from '~/pages/Authenticated/v2/ActivityView/components/ActivityDistanceLabel/type';

const NO_ADDRESS = -1;

export const ActivityDistanceLabel = (props: ActivityDistanceLabelProps) => {
  const { distance } = props;

  const distanceInMilesStr = useMemo(() => {
    if (distance < 0) return '';

    const distanceInMiles = convertMetersToMiles(distance);
    if (distanceInMiles === 0) return 'Near you';

    return `${String(
      distanceInMiles < 1
        ? distanceInMiles.toFixed(1)
        : Math.ceil(distanceInMiles),
    )} miles from you`;
  }, [distance]);

  if (distance === NO_ADDRESS) return <></>;

  return (
    <HStack space={2} alignItems={'center'}>
      <Icon size={'24px'} icon="map-pin" color={colors.gray[400]} />
      <Text fontSize={'md'} fontWeight={500} color={colors.gray[600]}>
        {distanceInMilesStr}
      </Text>
    </HStack>
  );
};

export default ActivityDistanceLabel;
