import { useAppSelector } from '~/hooks/useAppSelector';

export const useApplyPermissionsController = () => {
  const { allUserPermissionsFromAvailableApps } = useAppSelector(
    ({ permissions }) => permissions,
  );

  return {
    allUserPermissionsFromAvailableApps,
  };
};
