import { AppVisibility } from 'ui/enums';

import { DEFAULT_APP_ID, DEFAULT_ECOSYSTEM_ID } from '~/config';
import { App } from '~/types/interfaces/app';

export const doitVolunteersEcosystem = {
  _id: DEFAULT_ECOSYSTEM_ID,
  name: 'Life',
  description: 'Do IT Volunteering',
  owner: '64093b4b2eed5dcadba50884',
  organization: '64093b512eed5dcadba50889',
  logo: 'https://s3.eu-west-2.amazonaws.com/doit2.0-production-uploads/OrganizationPicture/4868792e-a31b-4bed-a43c-c209ea90a813_2024-01-26T15:38:47.539Z.jpeg',
  visibility: 'public',
};

export const fallBackAppsById: Record<string, Partial<App>> = {
  [DEFAULT_APP_ID as string]: {
    _id: DEFAULT_APP_ID as string,
    name: 'The Big Help Out App',
    description: 'The Big Help Out App',
    visibility: AppVisibility.Public,
    termsWebpage: 'https://thebighelpout.org.uk/terms-and-conditions/',
    ecosystem: '656f0ccc4cf1118d88f84511',
    organization: '656f0ccc4cf1118d88f8450e',
    logo: 'https://s3.eu-west-2.amazonaws.com/doit2.0-production-static/bho-logo.png',
  },
};
