import { Stack, Text, VStack } from 'native-base';
import Button from 'ui/components/Button';
import CardPropInfo from 'ui/components/Cards/CardPropInfo';
import Tag from 'ui/components/Tags/Tag';
import { AdvertiseRegionOptionsEnum, LocationOptionsEnum } from 'ui/enums';

import { RegionTag } from '~/pages/Authenticated/v2/ActivityView/components/ActivityLocation/RegionTag';
import { ActivityViewSection } from '~/pages/Authenticated/v2/ActivityView/components/ActivityViewSection';
import { getGoogleMapLink } from '~/utils/getGoogleMapLink';

import { useActivityLocationController } from './controller';
import { ActivityLocationProps } from './types';

export const ActivityLocation = (props: ActivityLocationProps) => {
  const {
    coordinates,
    mappedRegions,
    advertiseRegionOption,
    remoteAddress,
    streetAddress,
    isFromHome,
  } = useActivityLocationController(props);

  const shouldRenderMapInfo = !isFromHome && !!streetAddress;
  const shouldRenderMeetingLink = !!remoteAddress;
  const shouldRenderLocationSection =
    shouldRenderMapInfo || shouldRenderMapInfo || !isFromHome;
  const shouldRenderRegions = isFromHome;

  return (
    <VStack>
      <ActivityViewSection hide={!shouldRenderLocationSection} title="Location">
        <VStack space={6}>
          {shouldRenderMapInfo && (
            <CardPropInfo
              icon={'map'}
              info={streetAddress}
              justifyContent={'flex-start'}
            />
          )}
          {shouldRenderMeetingLink && (
            <CardPropInfo
              icon={'video'}
              info={remoteAddress}
              link={remoteAddress}
              justifyContent={'flex-start'}
              isLink
            />
          )}

          {!isFromHome ? (
            <a
              style={{ textDecoration: 'none', color: 'unset' }}
              target="_blank"
              href={getGoogleMapLink(coordinates)}
              rel="noreferrer"
            >
              <Button
                bg={'gray.100'}
                leftIconName={'external-link'}
                leftIconColor={'singletons.black'}
              >
                <Text fontSize={12} color={'singletons.black'}>
                  View map
                </Text>
              </Button>
            </a>
          ) : null}
        </VStack>
      </ActivityViewSection>

      <ActivityViewSection hide={!shouldRenderRegions} title="Regions">
        {advertiseRegionOption == AdvertiseRegionOptionsEnum.SelectRegions ? (
          mappedRegions?.map((mappedRegion) => (
            <RegionTag
              mappedRegion={mappedRegion}
              key={mappedRegion.mainRegionData._id}
            />
          ))
        ) : (
          <VStack>
            <Tag text="Anywhere in UK" />
          </VStack>
        )}
      </ActivityViewSection>
    </VStack>
  );
};

export default ActivityLocation;
