export const splitNameAndSurname = (name: string): [string, string] => {
  const [firstName, surname] = name.split(' ');

  return [firstName, surname];
};

export const getNameInitials = (name: string): string => {
  if (!name) return '';

  const [firstName, lastName] = splitNameAndSurname(name);

  const firstNameInitial = firstName ? firstName.charAt(0).toUpperCase() : '';
  const lastNameInitial = lastName ? lastName.charAt(0).toUpperCase() : '';
  return `${firstNameInitial}${lastNameInitial}`;
};

// TODO This method must be removed, we should use getNameInitials instead.
/**
 * @deprecated
 */
export const getInitials = (firstName: string, lastName: string): string => {
  if (!firstName || !lastName) return '';

  const firstNameInitial = firstName.charAt(0).toUpperCase();
  const lastNameInitial = lastName.charAt(0).toUpperCase();
  return firstNameInitial + lastNameInitial;
};
