import { ITheme } from 'native-base/lib/typescript/theme/index';
import { ReactNode } from 'react';

export enum RoundButtonSize {
  md,
  lg,
}

export const RoundButtonSizeMap = {
  [RoundButtonSize.lg]: { boxSize: '56px', iconSize: '24px' },
  [RoundButtonSize.md]: { boxSize: '52px', iconSize: '22px' },
};

export type RoundButtonProps = {
  icon?: string;
  imageUrl?: string;
  backgroundColor?: string;
  activeBackgroundColor?: string;
  iconColor?: string;
  isActive?: boolean;
  children?: ReactNode;
  width?: string | number;
  onPress?: VoidFunction;
  size?:
    | {
        [key in keyof ITheme['breakpoints']]?: RoundButtonSize;
      }
    | Array<RoundButtonSize>;
};
