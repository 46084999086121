import { Flex, Text } from 'native-base';
import { FiFrown } from 'react-icons/fi';
import { ILLUSTRATIONS_NAME } from 'ui/components/Illustration';

import FlowTwoColumnsTemplate from '~/components/Templates/FlowTwoColumnsTemplate';

import { useInvitationDeclinedController } from './useInvitationDeclinedController';

const InvitationDeclined = () => {
  const { isMobile, handleDecline } = useInvitationDeclinedController();
  return (
    <FlowTwoColumnsTemplate
      ilustrationName={ILLUSTRATIONS_NAME.ERROR_SORRY_STATE_BROKEN}
      title={'We are sorry to hear that you declined the invitation'}
      icon={<FiFrown size={32} />}
      primaryButtonText="Go to Doit.life"
      primaryButtonOnPress={handleDecline}
    >
      <Flex
        flex={1}
        bgColor={isMobile ? 'transparent' : 'white'}
        minH={{ base: '45vh', lg: '70vh' }}
        borderRadius={9}
        mt={12}
        px={{ base: 4, md: 8 }}
      >
        <Text
          fontSize={isMobile ? 'md' : 'xl'}
          mb={2}
          color="singletone.darkText"
        >
          We genuinely appreciate your thoughtful consideration and look forward
          to the opportunity of welcoming you back to our fold in the near
          future. Until then, take care and stay well!
        </Text>
      </Flex>
    </FlowTwoColumnsTemplate>
  );
};

export default InvitationDeclined;
