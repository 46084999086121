import { Flex } from 'native-base';
import { useState } from 'react';
import { colors } from 'ui/theme/colors';
import { ActivitySemanticType } from 'ui/types/activities';

import VerboseTab from '~/components/TabsNavigator/components/VerboseTab';
import { AttendanceType } from '~/types/interfaces/activity';
import { SearchFilters } from '~/zustand/store/Search';

export const ATTENDANCE_TYPE_TABS = [
  {
    value: AttendanceType.Local,
    icon: 'map-pin',
    title: 'In person',
    subtitle: 'Activities with specific locations',
  },
  {
    value: AttendanceType.Online,
    icon: 'home',
    title: 'From Home',
    subtitle: 'Activities you can do remotely',
  },
];

export type WorkspaceFilterSectionProps = {
  defaultAttendanceTypes: AttendanceType[];
  defaultActivityTypes: ActivitySemanticType[];
  onChange?: (newFilters: Partial<SearchFilters>) => void;
};

export const WorkspaceFilterSection = ({
  defaultActivityTypes,
  defaultAttendanceTypes,
  onChange,
}: WorkspaceFilterSectionProps) => {
  const [selectedTabs, setSelectedTabs] = useState<AttendanceType[]>(
    defaultAttendanceTypes,
  );

  const handleTabPress = (newAttendanceType: AttendanceType) => {
    setSelectedTabs((prevSelectedTabs: AttendanceType[]) => {
      let attendanceTypes: AttendanceType[] = [];

      if (
        prevSelectedTabs.length == 1 &&
        prevSelectedTabs.includes(newAttendanceType)
      ) {
        attendanceTypes = [AttendanceType.Local, AttendanceType.Online];
      } else {
        attendanceTypes = [newAttendanceType];
      }

      /* update parent */
      const filterPayload: Partial<SearchFilters> = {
        attendanceTypes,
      };

      if (attendanceTypes.includes(AttendanceType.Local)) {
        filterPayload.activityTypes = defaultActivityTypes.filter(
          (type) => type !== ActivitySemanticType.Action,
        );
      }

      onChange?.(filterPayload);
      return attendanceTypes;
    });
  };

  return (
    <Flex
      flexDir={{ base: 'column', md: 'row' }}
      w="full"
      style={{ rowGap: 12, columnGap: 24 }}
      justifyContent={'space-between'}
    >
      {ATTENDANCE_TYPE_TABS.map((tab) => {
        const isAllActive = selectedTabs.length === ATTENDANCE_TYPE_TABS.length;

        const isActive = !isAllActive && selectedTabs.includes(tab.value);

        return (
          <VerboseTab
            key={tab.value}
            icon={tab.icon}
            borderRadius={12}
            label={tab.title}
            subtitle={tab.subtitle}
            backGroundColor={colors.gray[100]}
            isActive={isActive}
            onPress={() => handleTabPress(tab.value)}
          />
        );
      })}
    </Flex>
  );
};
