import { Text, VStack } from 'native-base';
import { colors } from 'ui/theme/colors';

export type ActivityTitleSnippetProps = {
  title: string;
  size?: 'md' | 'lg';
};
export const ActivityTitleSnippet = ({
  title,
  size = 'md',
}: ActivityTitleSnippetProps) => {
  const fontSize =
    size === 'lg' ? { base: 'md', md: '20px' } : { base: 'sm', md: 'md' };

  const minHeight =
    size === 'lg' ? { base: '41px', md: '52px' } : { base: '31px', md: '41px' };

  return (
    <VStack space={1}>
      <Text
        fontSize={fontSize}
        color={colors.singletons.darkText}
        fontWeight={500}
        numberOfLines={2}
        minHeight={minHeight}
        lineHeight={'130%'}
      >
        {title}
      </Text>
    </VStack>
  );
};
