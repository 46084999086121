import { AxiosResponse } from 'axios';
import { IAppPermissionGroups } from 'ui/types/interfaces/permission';

import { api } from '~/services/api';

export class AppPermissionGroupsService {
  static URL = 'app-permission-group';

  static async getAppPermissionGroupsFromUserProfile(
    userProfileId: string,
  ): Promise<AxiosResponse<IAppPermissionGroups[]>> {
    return await api.get(`${this.URL}/user-profile/${userProfileId}`);
  }
}
