import { Avatar, Hidden, HStack, Skeleton, Stack, Text } from 'native-base';
import { useState } from 'react';
import Illustration, { ILLUSTRATIONS_NAME } from 'ui/components/Illustration';
import { getNameInitials } from 'ui/utils/getNameInititals';

import { colors } from '../../theme/colors';
import { InviteComposeProps } from './types';

export const InviteCompose = ({
  brandColor = colors.gray[900],
  message,
  organizationLogo,
  organizationName = '',
  title,
  backgroundImage,
  logo,
}: InviteComposeProps) => {
  const [isLoading, setIsLoading] = useState(true);
  return (
    <Stack
      width="100%"
      backgroundColor={isLoading ? 'none' : brandColor}
      height="100%"
      alignItems={{ base: 'center', lg: 'initial' }}
    >
      <Stack display={isLoading ? 'flex' : 'none'} width="100%" height="100%">
        <Skeleton width="100%" height="100%" />
      </Stack>
      <Illustration
        display={isLoading ? 'none' : 'flex'}
        name={ILLUSTRATIONS_NAME.LOGIN_BACKGROUND}
        source={backgroundImage ? { uri: backgroundImage } : undefined}
        width="100%"
        height="100%"
        resizeMode="cover"
        style={{ opacity: 0.35 }}
        onLoad={() => setIsLoading(false)}
      />
      <Stack
        alignItems={{ base: 'center', lg: 'initial' }}
        padding={12}
        position="absolute"
        top={0}
        space={8}
      >
        <Avatar
          source={{ uri: logo }}
          width={{ base: '80px', lg: '125px' }}
          height={{ base: '80px', lg: '125px' }}
        >
          {getNameInitials(organizationName)}
        </Avatar>
        <Stack space={2} alignItems={{ base: 'center', lg: 'initial' }}>
          <Text
            textAlign={{ base: 'center', lg: 'initial' }}
            color="white"
            fontWeight={500}
            fontSize={{ base: 'xl', lg: '3xl' }}
          >
            {title}
          </Text>
          <Hidden from="lg">
            <Text color="white" fontSize="lg">
              By {organizationName}
            </Text>
          </Hidden>
        </Stack>
      </Stack>
      <Hidden till="lg">
        <Stack
          backgroundColor={brandColor}
          height="40%"
          bottom={0}
          position="absolute"
          borderTopRadius="60px"
          borderColor={brandColor}
          borderWidth="1"
          padding={12}
          width="100%"
          overflowY="hidden"
        >
          <Text color="white" fontWeight={500} fontSize="lg">
            {message}
          </Text>
          <HStack mt={10} space={2} alignItems="center">
            <Avatar size={10} source={{ uri: organizationLogo }}>
              {getNameInitials(organizationName)}
            </Avatar>
            <Text color="white" fontWeight={500} fontSize="lg">
              {organizationName}
            </Text>
          </HStack>
        </Stack>
      </Hidden>
    </Stack>
  );
};
