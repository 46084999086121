import './styles.css';

import { HStack, ScrollView } from 'native-base';

import { CompanyExclusiveContent } from '~/components/Wrappers/CompanyExclusiveContent';
import { HomeWidget } from '~/pages/Authenticated/v2/Home/components/Widget';

export const WidgetGrid = () => {
  return (
    <div className="widget-grid-container">
      <ScrollView
        px={4}
        horizontal
        contentContainerStyle={{
          flexGrow: 1,
          justifyContent: 'center',
          alignItems: 'center',
        }}
        scrollEventThrottle={16}
        showsHorizontalScrollIndicator={false}
      >
        <HStack
          w="fit-content"
          minW={'full'}
          h="full"
          alignItems={'center'}
          justifyContent={'center'}
          space={{ base: 3, md: 4 }}
        >
          <CompanyExclusiveContent>
            <HomeWidget.FeaturedActivity />
          </CompanyExclusiveContent>

          <CompanyExclusiveContent>
            <HomeWidget.VolunteeringHours />
          </CompanyExclusiveContent>

          <HomeWidget.ProfileProgress />

          <HomeWidget.YourApps />
        </HStack>
      </ScrollView>
    </div>
  );
};
