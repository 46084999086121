import { VStack } from 'native-base';
import placeholderImage from 'ui/assets/images/placeholders/placeholder-teamevents.webp';

import { ActivityBaseCard } from '../Base';
import { ActivityDateSnippet } from '../fragments/Snippets/ActivityDateSnippet';
import { ActivityOrganizationSnippet } from '../fragments/Snippets/ActivityOrganizationSnippet';
import { ActivityTitleSnippet } from '../fragments/Snippets/ActivityTitleSnippet';
import { useEventActivityCardController } from './controller';
import { TeamEventActivityCardProps } from './types';

export const TeamEventActivityCard = (props: TeamEventActivityCardProps) => {
  const {
    coverImageUrl,
    organizationName,
    organizationLogoUrl,
    activityStartDate,
    activityDatesCount,
    activityTitle,
    onPress,
  } = props;
  const { footerNodes } = useEventActivityCardController(props);

  return (
    <ActivityBaseCard
      onPress={onPress}
      showCoverImage
      footerNodes={footerNodes}
      coverImageUrl={coverImageUrl}
      coverImagePlaceHolder={placeholderImage}
    >
      <VStack pt={3} space={2}>
        <ActivityTitleSnippet title={activityTitle} />

        <ActivityDateSnippet
          date={activityStartDate}
          datesCount={activityDatesCount}
        />

        <ActivityOrganizationSnippet
          organizationName={organizationName}
          organizationLogoUrl={organizationLogoUrl}
        />
      </VStack>
    </ActivityBaseCard>
  );
};
