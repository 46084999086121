import { Flex, Hidden, HStack, Stack, Text, VStack } from 'native-base';
import Input from 'ui/components/Input';

import { ActivityCardRecommendationsSkeletonList } from '~/components/ActivityCards/CardRecommendations';
import { Scaffold } from '~/components/Scaffold';
import { Tab } from '~/components/Tabs';
import OffPlatformActivities from '~/pages/Authenticated/LogActivities/OffPlatformActivities';
import OffPlatformActivitiesFooter from '~/pages/Authenticated/LogActivities/OffPlatformActivities/components/OffPlatformActivitiesFooter';

import { ActivitiesToDo } from './ActivitiesToDo';
import { useLogActivitiesController } from './useLogActivitiesController';

export const LogActivities = () => {
  const {
    currentTabIndex,
    isLoading,
    activityApplications,
    control,
    tabs,
    handleChangeTab,
    handleOnScrollEndReached,
    refetchActivityApplication,
    offPlatformController,
    shouldRenderSkeleton,
    shoudlRenderNoMoreActivities,
  } = useLogActivitiesController();

  return (
    <Scaffold onScrollReachEnd={handleOnScrollEndReached}>
      <VStack flex={1}>
        <HStack width={'100%'} space={2} justifyContent="space-between">
          {tabs.map((tab, index) => {
            return (
              <Tab
                key={index}
                isActive={index === currentTabIndex}
                onPress={() => handleChangeTab(index)}
                {...tab}
              />
            );
          })}
        </HStack>

        <VStack
          bg={'singletons.white'}
          px={4}
          py={{ base: 3, lg: 6 }}
          flex={1}
          rounded={8}
          roundedTopRight={0}
        >
          <Hidden from={'sm'}>
            <Stack mb={'6'} mt={'4'}>
              <Text fontWeight={500} fontSize={'md'}>
                {tabs[currentTabIndex].label}
              </Text>
              <Text color={'gray.600'} fontSize={'xs'}>
                {tabs[currentTabIndex].description}
              </Text>
            </Stack>
          </Hidden>

          {currentTabIndex === 0 ? (
            <>
              <Stack
                w={{ base: '100%', lg: '40%', xl: '35%' }}
                justifyContent={'flex-end'}
                mb={{ base: 4, lg: 0 }}
              >
                <Input
                  h={10}
                  control={control}
                  name="text"
                  placeholder="Search activity"
                  leftIconName="search"
                />
              </Stack>
              <ActivitiesToDo
                refetch={refetchActivityApplication}
                isLoading={isLoading}
                activityApplications={activityApplications}
              />
            </>
          ) : null}

          {currentTabIndex === 1 ? (
            <OffPlatformActivities
              isLoading={offPlatformController.isLoading}
              measurements={offPlatformController.offPlatformActivities}
              refetchActivity={offPlatformController.refetch}
              totalMeasurements={
                offPlatformController.totalOffPlatformActivities
              }
            />
          ) : null}

          <Flex mt={4}>
            {shouldRenderSkeleton ? (
              <ActivityCardRecommendationsSkeletonList quantity={3} />
            ) : null}

            {shoudlRenderNoMoreActivities && currentTabIndex === 0 && (
              <Text
                alignSelf={'center'}
                fontSize={'md'}
                color={'gray.400'}
                pt={4}
                pb={6}
              >
                No more activities.
              </Text>
            )}
          </Flex>
        </VStack>
      </VStack>
      {currentTabIndex === 1 && (
        <Hidden from={'sm'}>
          <OffPlatformActivitiesFooter
            onClick={offPlatformController.handleOffPlatformCreate}
          />
        </Hidden>
      )}
    </Scaffold>
  );
};
