import { Stack } from 'native-base';
import Modal from 'ui/components/Modals/Modal';

import { IModalTermsOfServiceProps } from './types';

const ModalTermsOfService = ({ modalRef }: IModalTermsOfServiceProps) => (
  <Modal
    headerText="Terms of service"
    ref={modalRef}
    modalContentW={{ base: '100vw', lg: '80vw' }}
    modalContentMaxW={{ base: '100vw', lg: '80vw' }}
    modalContentH={{ base: '100vh', lg: '90vh' }}
    modalContentMaxH={{ base: '100vh', lg: '90vh' }}
    // Fix responsive modal on mobile scroll view.
    _overlay={{ style: { position: 'fixed' } as any }}
  >
    <Stack py="2" alignSelf="center">
      <div
        style={{
          flexDirection: 'column',
          boxSizing: 'border-box',
          display: 'flex',
        }}
      >
        <h2>
          PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE USING THE DO
          IT PLATFORM
        </h2>
        <h3>What&apos;s in these terms?</h3>
        <p>
          These terms tell you the rules for using the Do IT Platform made
          available to you through Doit.life (<b>our site / Platform</b>).
        </p>
        <h3>Who we are and how to contact us</h3>
        <p>
          Doit.life is a site operated by Do IT Life Social Enterprise Ltd
          trading as Do IT (&quot;<b>We</b>&quot;). We are registered as a
          social enterprise in England and Wales with company number 10709829
          and have our registered office at Studio 2, Neckinger Mills, 162-164
          abbey Street, London SE1 2AN.
        </p>
        <p>
          To contact us, please email{' '}
          <a href="mailto:data@doit.life">data@doit.life</a>.
        </p>
        <h2>
          <u>PLEASE NOTE:</u>
        </h2>
        <h3>
          1.
          <u>
            The content on our Platform has been created by other Users and our
            partners
          </u>
        </h3>
        <p>
          Do IT is a platform to facilitate social wellbeing, by connecting our
          users, like you, to other like-minded people, businesses, charities
          and other organisations (Platform Users).
        </p>
        <p>
          All content on the Do IT Platform is provided for information only. We
          are not responsible for the content made available on the Platform, or
          which you make available to others as a User. The views expressed by
          other users on our site do not represent our views or values. We do
          not carry out any verification of the content or any other information
          made available on the Platform by you or any other Users and do not
          guarantee the accuracy or completeness of the information.
          <b>
            You should take care when engaging with any other User of the
            Platform.
          </b>
        </p>
        <h3>
          <u>
            We are not party to any contract or discussions between you and
            other Users
          </u>
        </h3>
        <p>
          We do not take any enquiries from Users in relation to any services
          made available to you through the Platform. We do not engage in any
          correspondence between you and any other Users and do not participate
          in any part of any transaction or part of any contract between you or
          any other User. We cannot ensure that any User will perform against
          any agreement you have entered into or complete any transaction with
          you. If you have a query regarding a service, you must field this
          directly with the other User. Your consumer rights apply.
        </p>
        <h3>
          <u>We are here to help if another User is breaching our terms</u>
        </h3>
        <p>
          If you do believe that any other User is misusing the Platform or you
          have a complaint about their behaviour, please contact us.
        </p>
        <h3>
          <u>By using our Platform, you accept these terms</u>
        </h3>
        <p>
          By using our site, you confirm that you accept these terms of use and
          that you agree to comply with them. If you do not agree to these
          terms, you must not use our site.
        </p>
        <h3>
          <u>There are other terms that may apply to you</u>
        </h3>
        <p>
          These terms of use refer to the following additional terms, which also
          apply to your use of our site:
        </p>
        <ul>
          <li>
            Our Privacy Policy available at{' '}
            <a href="https://doit.life/privacy-policy">
              https://doit.life/privacy-policy
            </a>
          </li>
        </ul>
        <h3>
          2. <u>Information on our Platform</u>
        </h3>
        <p>
          Although we make reasonable efforts to ensure that other Users update
          the information on our Platform, we make no representations,
          warranties or guarantees, whether express or implied, that the content
          on our site is accurate, complete or up to date. The content on our
          site is provided for general information only. It is not intended to
          amount to advice on which you should rely. You must obtain
          professional or specialist advice before taking, or refraining from,
          any action on the basis of the content on our Platform.
        </p>
        <h3>
          3. <u>Account Registration</u>
        </h3>
        <p>
          In order to access some features of our Platform you will need to
          create an account and complete our registration form. You agree to
          ensure that the details provided by you on registration are true,
          accurate, current and complete. It is your responsibility to update
          your information and inform us of any changes to your details. At our
          sole discretion, we may refuse your application for registration and
          your access to our Platform at any time without liability.
        </p>
        <p>
          You must keep Your account username and password combination secret
          and secure. We will not be liable for any unauthorised use of your
          account and you will remain solely responsible for any activity
          occurring under your profile whether with or without your permission.
          You must notify us immediately if you believe Your account has been
          compromised or subject to unauthorised use.
        </p>
        <p>
          It’s important that you provide us with accurate, complete and
          up-to-date information for your account and you agree to update such
          information, as needed, to keep it accurate, complete and up-to-date.
          If you don’t, you will not be able to enjoy all the benefits of our
          Platform.
        </p>
        <p>
          <b>We do not back-up your content and data used on the Platform.</b>
        </p>
        <h3>
          4.
          <u>How we may use your personal information</u>
        </h3>
        <p>
          Your use of our Platform may involve the transmission to us of certain
          personally identifiable information. Our policies with respect to the
          collection and use of such personally identifiable information are
          governed according to our Privacy Policy available at{' '}
          <a href="https://doit.life/privacy-policy">
            https://doit.life/privacy-policy
          </a>
          .
        </p>
        <p>
          You understand that through your use of our Platforms you acknowledge
          the collection, use and sharing of this information as described in
          our Privacy Policy. If you don’t agree with the Privacy Policy, then
          you must stop using our Services.
        </p>
        <h3>
          5.
          <u>We may make changes to our site or these terms</u>
        </h3>
        <p>
          We amend these terms and may update and change our Platform and the
          services it provides from time to time
        </p>
        <h3>
          <u>We may suspend or withdraw access to our Platform</u>
        </h3>
        <p>
          We take all reasonable measures to ensure that the Platform is
          operational everyday but we do not guarantee that the Platform, or any
          content on it, or its functionality will always be available or be
          uninterrupted. We may suspend or withdraw, or restrict the
          availability of, all or any part of our site for business and
          operational reasons. We will try to give you reasonable notice of any
          suspension or withdrawal.
        </p>
        <p>
          We are not responsible to any User for any delays, delivery failures,
          or any other loss or damage resulting from the transfer of data over
          communications networks and facilities, including the internet and You
          acknowledge that access to the internet, mobile phone networks or
          other communication media necessary for the provision and receipt of
          services made available to you through our Platform is inherently
          associated with risks including viruses, data security, piracy,
          availability of services and reliability of data transmission.
        </p>
        <p>
          The Platform is provided to you “as is” and we expressly disclaim all
          warranties, conditions or other terms, whether express, implied or
          statutory, including without limitation, warranties, conditions or
          other terms regarding merchantability, fitness for a particular
          purpose, design, condition, capacity, performance, title, and
          non-infringement.
        </p>
        <h3>
          6.
          <u>
            We are not responsible for websites made available to you through
            our Platform
          </u>
        </h3>
        <p>
          Where the Platform contains links to other sites and resources
          provided by third parties, these links are provided for your
          information only. Such links should not be interpreted as approval or
          endorsement by us of those linked websites or the information you may
          obtain from them.
        </p>
        <p>
          Unless otherwise stated, Do IT have no control over the content of
          third party sites or resources which are made available through the
          Platform and accept no liability or responsibility for them or your
          use or inability to use them, the web sites they link to or their
          contents, their accuracy or reliability. You should be aware that the
          use and availability of certain parts of the Platform may be dependent
          on third-party service providers over whom we have no control. It is
          your responsibility to refer to the terms of use and privacy policies
          of any third party for more information.
        </p>
        <p>
          If in doubt, you should contact the relevant third party you have
          engaged with in respect to any questions about their products and
          services or any issues with them.
        </p>
        <h2>
          <u>PLEASE NOTE:</u>
        </h2>
        <h3>
          <u>Your interactions with other Users</u>
        </h3>
        <p>
          Do IT is not a party to any agreement between you and any other user
          of the Do IT Platform.
        </p>
        <p>
          The Platform is made available to you to support, enable or assist you
          in accessing the Services which may be made available to you through
          it. Use of the Platform is entirely at Your own risk.
        </p>
        <p>
          Neither Do IT nor any of our Service Provider Partners (“Do IT”) shall
          have any liability or obligation whatsoever in relation to the
          unavailability of, or use or misuse of the Platform by any third-party
          or in respect to any contract entered into by You with any such third
          party.
        </p>
        <h3>
          <u>Your responsibility to be diligent</u>
        </h3>
        <p>
          Any contract entered into and any transaction completed through the
          Platform is between you and the relevant third party. Do IT is not
          involved in any communications or transactions between users of the
          Platform (nor does it screen, audit or approve any content made
          available to You through the Platform).
        </p>
        <h3>
          <u>
            You are responsible for reviewing any contractual terms (or Privacy
            Policy) applicable to your use of any services you access through
            the Platform and ensuring that those services are suitable for you
          </u>
        </h3>
        <p>
          Your interactions with organizations and/or individuals found on or
          through the Platform, and any other terms, conditions, warranties or
          representations associated with such dealings, are solely between You
          and the other user.
        </p>
        <p>
          You should make whatever investigation you feel necessary or
          appropriate before proceeding with any offline or online transaction
          with any other User of the Platform whether they are a Service
          Provider or otherwise. Do IT is not liable for the behaviour or
          statements of a user whether as a receiver or provider of services. We
          do not guarantee the quality, availability, timeliness, fitness for
          purpose or completeness of any services made available to you through
          the Platform.
        </p>
        <p>
          You accept all responsibility for and agree to release Do IT (to the
          extent permissible by law and without prejudice to your consumer
          rights) entirely from any claims, liabilities, disputes, actions or
          proceedings arising from or connected to your interaction with any
          third party through your use of the Platform, or as a result of any
          transactions, interactions, conduct or communications between you and
          other user(s) of the Platform.
        </p>
        <p>
          You assume all risks associates with dealing with other users of the
          Platform with whom you come in contact with through your use of
          services made accessible to you.
        </p>
        <h3>
          <u>
            We do not exclude or limit in any way our liability to you where it
            would be unlawful to do so
          </u>
        </h3>
        <h3>
          7.
          <u>
            Our Community Guidelines: Uploading content to the Platform and
            engaging with other Users
          </u>
        </h3>
        <p>
          Whenever you make use of a feature that allows you to upload content
          to the Platform, or to make contact with other users of the Platform,
          you must comply our Community Guidelines set out below.
        </p>
        <p>
          You warrant that all your contributions comply with those standards,
          and you will be liable to us and indemnify us for any breach of that
          warranty. This means you will be responsible for any loss or damage we
          suffer as a result of your breach of warranty.
        </p>
        <p>
          We have the right to remove any content you make available through the
          Platform, without liability, if, in our opinion, that content does not
          comply with the standards set by us. We my suspend or terminate our
          agreement with you or any access to the Services, without liability,
          if in our opinion You are not complying with the spirit of this
          agreement or our Platform.
        </p>
        <h3>
          8.
          <u>
            Our rights to use your content to provide services on the Platform
          </u>
        </h3>
        <p>
          Any content or material you upload to the Platform (except where
          detailed on the Platform, or where that information relates to
          Personal Data (including sensitive personal data) will be considered
          non-confidential and non-proprietary. You retain all of your ownership
          rights in your content, but you are required to grant us a limited
          licence to use, store and copy that content and to distribute and make
          it available to third parties.
        </p>
        <p>
          We also have the right to disclose your identity to any third party
          who is claiming that any content posted or uploaded by you to our site
          constitutes a violation of their intellectual property rights, or of
          their right to privacy.
        </p>
        <h3>
          9.
          <u>
            We are not responsible for viruses and you must not introduce them
          </u>
        </h3>
        <p>
          We do not guarantee that our site will be secure or free from bugs or
          viruses. You are responsible for configuring your information
          technology, computer programmes and platform to access our site. You
          should use your own virus protection software.
        </p>
        <h3>
          10. <u>Termination</u>
        </h3>
        <p>
          We may terminate your access to our Platform immediately by written
          notice to You for any reason. We may also suspend or permanently
          terminate your access to the Platform at any time without notice to
          you if in our opinion you have breached the terms of any agreement we
          have with you or the spirit of that agreement, or you are engaging in
          any activity which is damaging or potentially damaging to our business
          or other users. In the event of termination, your access to the
          Platform will be immediately suspended and all content deleted.
        </p>
        <h3>
          11.
          <u>Our responsibility for loss or damage suffered by you</u>
        </h3>
        <p>
          <b>
            We are responsible to you for foreseeable loss and damage caused by
            us.
          </b>
          If we fail to comply with these terms, we are responsible for loss or
          damage you suffer that is a foreseeable result of our breaking this
          contract or our failing to use reasonable care and skill, but we are
          not responsible for any loss or damage that is not foreseeable. Loss
          or damage is foreseeable if either it is obvious that it will happen
          or if, at the time your Subscription was made, both we and you knew it
          might happen, for example, if you discussed it with us during the
          sales process.
        </p>
        <p>
          <b>
            We do not exclude or limit in any way our liability to you where it
            would be unlawful to do so.
          </b>
          This includes liability for death or personal injury caused by our
          negligence or the negligence of our employees, agents or
          subcontractors; for fraud or fraudulent misrepresentation.
        </p>
        <h3>
          12. <u>Other important terms</u>
        </h3>
        <p>
          <b>We may transfer this agreement to someone else.</b>
          We may transfer our rights and obligations under these terms to
          another organisation. We will contact you to let you know if we plan
          to do this.
        </p>
        <p>
          <b>
            If a court finds part of this contract illegal, the rest will
            continue in force.
          </b>
          Each of the paragraphs of these terms operates separately. If any
          court or relevant authority decides that any of them are unlawful, the
          remaining paragraphs will remain in full force and effect.
        </p>
        <p>
          <b>
            Even if we delay in enforcing this contract, we can still enforce it
            later.
          </b>
          If we do not insist immediately that you do anything you are required
          to do under these terms, or if we delay in taking steps against you in
          respect of your breaking this contract, that will not mean that you do
          not have to do those things and it will not prevent us taking steps
          against you at a later date. For example, if you miss a payment and we
          do not chase you but we continue to provide Subscription Services, we
          can still require you to make the payment at a later date.
        </p>
        <p>
          <b>
            Which laws apply to this contract and where you may bring legal
            proceedings as a consumer.
          </b>
          These terms are governed by English law and you can bring legal
          proceedings in respect of Subscription Services in the English courts.
          If you live in Scotland you can bring legal proceedings in respect of
          Subscription Services in either the Scottish or the English courts. If
          you live in Northern Ireland you can bring legal proceedings in
          respect of Subscription Services in either the Northern Irish or the
          English courts.
        </p>
        <h3>Our Intellectual Property Rights</h3>
        <p>
          Unless otherwise indicated, the Platform and any content on it are the
          property of Do IT Life Social Enterprise Ltd or our licensors and are
          protected by copyright, trademark and other laws of the United Kingdom
          and foreign countries. Nothing in these terms grants you any right,
          title or interest in any intellectual property rights of any kind to
          use it. You may make copies for personal, non-commercial use.
        </p>
        <p>
          Do IT, and the Do IT logo (“Do IT Marks”) are trade marks of Do IT
          Life Social Enterprise Ltd. The Do IT Marks may not be copied,
          imitated or used, in whole or in part, without prior written
          permission. All other trade marks, logos, trade names and any other
          proprietary designations are the trade marks or registered trade marks
          of their respective owners and may not be used without permission of
          the applicable trade mark holder.
        </p>
        <h1>
          <u>For Volunteer Recruiters</u>
        </h1>
        <p>Volunteer recruiters are Platform Users.</p>
        <h3>Volunteering responsibly</h3>
        <p>
          Please make sure that you&apos;re recruiting volunteers for the right
          reasons.
        </p>
        <p>Volunteering is:</p>
        <ul>
          <li>An activity that someone does freely, by choice.</li>
          <li>
            An activity that anyone with the required skills can apply to do.
          </li>
          <li>
            An activity that benefits the community, as well as the volunteer.
          </li>
        </ul>
        <p>
          We reserve the right to remove any opportunity that does not meet
          these requirements.
        </p>
        <p>
          <b>
            The volunteer recruiter agrees and warrants that it shall ensure and
            shall be solely responsible for ensuring that the following are in
            place before commencing with the provision of volunteering
            opportunities:
          </b>
        </p>
        <ul>
          <li>
            A suitable volunteer policy or policies, that is/are appropriate for
            the volunteers’ ages.
          </li>
          <li>
            A suitable risk assessment policy, that is appropriate for the
            volunteers’ ages and the opportunities offered by the Recruiter.
          </li>
          <li>
            Where the recruiter is offering opportunities to any volunteers
            under the age of 18, a suitable child protection policy and
            measures.
          </li>
          <li>
            Where the recruiter is offering opportunities to any volunteers who
            are vulnerable adults, a suitable vulnerable adults’ policy and
            appropriate measures.
          </li>
          <li>A suitable data protection policy and appropriate measures.</li>
          <li>A suitable health and safety policy.</li>
          <li>
            Suitable public liability and/or employers liability insurance
            covering volunteers and the types of activities they will be doing.
          </li>
        </ul>
        <p>
          <b>
            The recruiter warrants that it shall comply with all relevant laws,
            statutory instruments, regulations and related guidance produced by
            relevant government and statutory organisations when providing the
            opportunities, including without limitation:
          </b>
        </p>
        <ul>
          <li>Equal opportunities legislation.</li>
          <li>Employment legislation.</li>
          <li>The Disability Discrimination Act 1996.</li>
          <li>The Race Relations Act 1976 (as amended).</li>
          <li>The Sex Discrimination Act 1975.</li>
          <li>
            The General Data Protection Regulation (GDPR) and all relevant
            associated statutory instruments and guidance from the Information
            Commissioner in relation to the use and treatment of Personal Data.
          </li>
          <li>
            The Health and Safety at Work etc Act 1974, the Health and Safety
            Work Regulations 1999, and all relevant associated statutory
            instruments.
          </li>
          <li>
            Principles and guidance produced by the Health and Safety Executive.
          </li>
          <li>
            All applicable child protection and vulnerable adults’ legislation,
            including (without limitation) the Safeguarding Vulnerable Groups
            Act 2006.
          </li>
        </ul>
        <p>
          <b>
            DBS Disclosures: where the recruiter is directly engaging with
            volunteers under the age of 18 and/or that are vulnerable adults, it
            warrants that it will obtain all necessary DBS disclosure checks.
          </b>
        </p>
        <h1>
          <u>Community Guidelines</u>
        </h1>
        <h3>Acceptable Use of the Platform</h3>
        <p>
          You may use the Platform only for lawful purposes. You may not use the
          Platform:
        </p>
        <ul>
          <li>
            In any way that breaches any applicable local, national or
            international law or regulation.
          </li>
          <li>
            In any way that is unlawful or fraudulent, or has any unlawful or
            fraudulent purpose or effect.
          </li>
          <li>
            For the purpose of harming or attempting to harm minors in any way.
          </li>
          <li>To bully, insult, intimidate or humiliate any person.</li>
          <li>
            To send, knowingly receive, upload, download, use or re-use any
            material which does not comply with our content standards (as set
            out below).
          </li>
          <li>
            To transmit, or procure the sending of, any unsolicited or
            unauthorised advertising or promotional material or any other form
            of similar solicitation (spam).
          </li>
          <li>
            To knowingly transmit any data, send or upload any material that
            contains viruses, Trojan horses, worms, time-bombs, keystroke
            loggers, spyware, adware or any other harmful programs or similar
            computer code designed to adversely affect the operation of any
            computer software or hardware.
          </li>
        </ul>
        <p>
          <b>You also agree:</b>
        </p>
        <ul>
          <li>
            Not to reproduce, duplicate, copy or re-sell any part of our
            Platform in contravention of the provisions of our terms of use.
          </li>
          <li>
            Not to access without authority, interfere with, damage or disrupt:
            <ul>
              <li> any part of our Platform; </li>
              <li>any equipment or network on which our site is stored;</li>
              <li>any software used in the provision of our site; or</li>
              <li>
                any equipment or network or software owned or used by any third
                party.
              </li>
            </ul>
          </li>
        </ul>
        <h3>Content standards</h3>
        <p>
          These content standards apply to any and all material which you
          contribute to the Platform (<b>Contribution</b>), and to any
          interactive services associated with it.
        </p>
        <p>
          The Content Standards must be complied with in spirit as well as to
          the letter. The standards apply to each part of any Contribution as
          well as to its whole.
        </p>
        <p>
          We will determine, in our discretion, whether a Contribution breaches
          the Content Standards.
        </p>
        <p>
          <b>A Contribution must:</b>
        </p>
        <ul>
          <li>Be accurate (where it states facts).</li>
          <li>Be genuinely held (where it states opinions).</li>
          <li>
            Comply with the law applicable in England and Wales and in any
            country from which it is posted.
          </li>
        </ul>
        <p>
          <b>A Contribution must not:</b>
        </p>
        <ul>
          <li> Be defamatory of any person. </li>
          <li>Be obscene, offensive, hateful or inflammatory.</li>
          <li>Bully, insult, intimidate or humiliate.</li>
          <li>Promote sexually explicit material.</li>
          <li> Promote violence. </li>
          <li>
            Promote discrimination based on race, sex, religion, nationality,
            disability, sexual orientation or age.
          </li>
          <li>
            Infringe any copyright, database right or trade mark of any other
            person.
          </li>
          <li>Be likely to deceive any person.</li>
          <li>
            Breach any legal duty owed to a third party, such as a contractual
            duty or a duty of confidence.
          </li>
          <li> Promote any illegal activity. </li>
          <li> Be in contempt of court. </li>
          <li>
            Be threatening, abuse or invade another&apos;s privacy, or cause
            annoyance, inconvenience or needless anxiety.
          </li>
          <li>
            Be likely to harass, upset, embarrass, alarm or annoy any other
            person.
          </li>
          <li>
            Impersonate any person, or misrepresent your identity or affiliation
            with any person.
          </li>
          <li>
            Give the impression that the Contribution emanates from us, if this
            is not the case.
          </li>
          <li>
            Advocate, promote, incite any party to commit, or assist any
            unlawful or criminal act such as (by way of example only) copyright
            infringement or computer misuse.
          </li>
          <li>
            Contain a statement which you know or believe, or have reasonable
            grounds for believing, that members of the public to whom the
            statement is, or is to be, published are likely to understand as a
            direct or indirect encouragement or other inducement to the
            commission, preparation or instigation of acts of terrorism.
          </li>
          <li>
            Contain any advertising or promote any services or web links to
            other sites.
          </li>
        </ul>
        <h3>Breach of these terms</h3>
        <p>
          When we consider that a breach of these terms has occurred, we may
          take such action as we deem appropriate.
          <br /> Failure to comply with these terms may result in our taking all
          or any of the following actions:
        </p>
        <ul>
          <li>
            Immediate, temporary or permanent withdrawal of your right to use
            the Platform.
          </li>
          <li>
            Immediate, temporary or permanent removal of any Contribution
            uploaded by you to the Platform.
          </li>
          <li> Issue of a warning to you. </li>
          <li>
            Legal proceedings against you for reimbursement of all costs on an
            indemnity basis (including, but not limited to, reasonable
            administrative and legal costs) resulting from the breach.
          </li>
          <li>Further legal action against you.</li>
          <li>
            Disclosure of such information to law enforcement authorities as we
            reasonably feel is necessary or as required by law.
          </li>
        </ul>
      </div>
    </Stack>
  </Modal>
);

export default ModalTermsOfService;
