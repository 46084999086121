import Button from 'ui/components/Button';
import Icon from 'ui/components/Icon';
import { colors } from 'ui/theme/colors';

export type CategoriesSliderNavButtonProps = {
  iconName: string;
  onPress?: VoidFunction;
  isDisabled?: boolean;
  anchor?: 'left' | 'right';
};
export const CategoriesSliderNavButton = ({
  onPress,
  iconName,
  isDisabled,
  anchor,
}: CategoriesSliderNavButtonProps) => {
  return (
    <Button
      p={0}
      minW={'unset'}
      variant="link"
      h={{ base: '32px', md: '32px' }}
      w={{ base: '32px', md: '32px' }}
      borderRadius={100}
      onPress={onPress}
      justifyContent={'center'}
      alignItems={'center'}
      isDisabled={isDisabled}
      backgroundColor={'rgba(0, 0, 0, 0.70)'}
      marginRight={anchor === 'right' ? -2 : 'unset'}
      marginLeft={anchor === 'left' ? -2 : 'unset'}
      right={anchor === 'right' ? 0 : 'unset'}
      left={anchor === 'left' ? 0 : 'unset'}
      position={'absolute'}
      top={0}
      bottom={0}
      margin="auto"
      zIndex={2}
    >
      <Icon icon={iconName} size={18} color={colors.singletons.white} />
    </Button>
  );
};

export default CategoriesSliderNavButton;
