import { ActivitiesTabPage } from '~/pages/Authenticated/LogActivities/types';

export const logActivitiesContentPageTabs = (
  totalActivitiesTodo: number,
  totalOffplatformActivities: number,
) => {
  return [
    {
      badgeNumber: totalActivitiesTodo,
      icon: 'plus-circle',
      label: 'Activities to log',
      description: 'Log your activities in progress',
      page: ActivitiesTabPage.ActivitiesToDo,
    },
    {
      badgeNumber: totalOffplatformActivities,
      icon: 'wifi-off',
      label: 'Off-platform',
      description: 'Activities done outside Do IT',
      page: ActivitiesTabPage.OffPlatformActivities,
    },
  ];
};
