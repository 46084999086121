import { mountStoreDevtool } from 'simple-zustand-devtools';
import { UserProfile } from 'ui/types/interfaces/user';
import { createStore } from 'zustand';
import { shallow } from 'zustand/shallow';
import { useStoreWithEqualityFn as useStore } from 'zustand/traditional';

import { UserProfileProps, UserProfileState } from './types';

const SEARCH_INITIAL_STATE: UserProfileProps = {
  availableUserProfiles: [],
};

type SearchStore = ReturnType<typeof createUserProfileStore>;

export const createUserProfileStore = (
  initProps?: Partial<UserProfileProps>,
) => {
  const DEFAULT_PROPS: UserProfileProps = {
    ...SEARCH_INITIAL_STATE,
    ...initProps,
  };

  return createStore<UserProfileState>()((set) => ({
    ...DEFAULT_PROPS,
    setAvailableUserProfiles: (availableUserProfiles: UserProfile[]) =>
      set((state: UserProfileProps) => ({ ...state, availableUserProfiles })),
  }));
};

const useUserProfileStore: SearchStore = createUserProfileStore();

if (process.env.NODE_ENV === 'development') {
  mountStoreDevtool('Store', useUserProfileStore);
}

export function useUserProfile<T>(selector: (state: UserProfileState) => T): T {
  return useStore(useUserProfileStore, selector, shallow);
}
