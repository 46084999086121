import { useQuery } from '~/hooks/useQuery';
import { PopulatedMeasurementDefinition } from '~/types/interfaces/measurement-definition';

export const useLoadMeasurementDefinitionById = (
  measurementDefinitionId?: string,
) => {
  const key = `measurement-definition/${measurementDefinitionId}`;

  const { data, isLoading, refetch, isFetched } =
    useQuery<PopulatedMeasurementDefinition>(key, {
      key,
      queryOptions: { enabled: !!measurementDefinitionId },
      requestOptions: {
        params: {
          populate: JSON.stringify([
            {
              model: 'Measurement',
              path: 'measurements',
              populate: {
                model: 'MeasurementUnits',
                path: 'measurementUnit',
              },
            },
          ]),
        },
      },
    });

  return { data: data || null, isLoading, isFetched, refetch };
};
