import { HStack, Stack } from 'native-base';
import { Controller } from 'react-hook-form';
import Input from 'ui/components/Input';
import LocationAutoComplete from 'ui/components/v2/LocationInputs/LocationAutoComplete';

import { HeaderSearchFormProps } from '~/pages/Authenticated/FindOpportunitiesPage/findOpportunities.types';
import { GooglePlacesAutoCompleteAdapter } from '~/services/google/google-places-auto-auto-complete';

const googlePlacesAutoComplete = GooglePlacesAutoCompleteAdapter.getSingleton();

export const HeaderSearchForm = ({ control }: HeaderSearchFormProps) => {
  return (
    <HStack
      flex={1}
      justifyContent={'flex-end'}
      flexDir={{
        base: 'column',
        lg: 'row',
      }}
      px={0.5}
      space={3}
    >
      <Stack
        w={{ base: '100%', lg: '360px', xl: '400px' }}
        justifyContent={'flex-end'}
        mb={{ base: 4, lg: 0 }}
      >
        <Input
          h={'3rem'}
          fontSize={'sm'}
          control={control}
          name={'search'}
          placeholder={'Search activity'}
          leftIconName={'search'}
        />
      </Stack>

      <Stack
        w={{
          base: '100%',
          lg: '360px',
          xl: '400px',
        }}
      >
        <Controller
          control={control}
          name={'city_or_postcode' as never}
          render={({ field: { onChange } }) => (
            <LocationAutoComplete
              onChange={onChange}
              placeholder="City or Postcode"
              provider={googlePlacesAutoComplete}
            />
          )}
        />
      </Stack>
    </HStack>
  );
};
