import { AnimatePresence, motion } from 'framer-motion';
import { Button as UIButton, Text } from 'native-base';
import Icon from 'ui/components/Icon';
import { colors } from 'ui/theme/colors';

export type BackToTopButtonProps = {
  label?: string;
  onPress?: VoidFunction;
};

export const BackToTopButton = ({
  label = 'Back to top',
  onPress,
}: BackToTopButtonProps) => {
  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0, y: 128 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ type: 'spring', stiffness: 100, duration: 1 }}
      >
        <UIButton
          p={5}
          py={6}
          w="fit-content"
          borderRadius={100}
          onPress={onPress}
          variant="solid"
          backgroundColor={colors.singletons.white}
          shadow="4"
          leftIcon={
            <Icon
              size={16}
              enableAutoCustom
              icon="arrow-up"
              color={colors.singletons.black}
            />
          }
        >
          <Text
            fontWeight={500}
            fontSize={'sm'}
            color={colors.singletons.black}
          >
            {label}
          </Text>
        </UIButton>
      </motion.div>
    </AnimatePresence>
  );
};
