import { Hidden, HStack, useBreakpointValue } from 'native-base';
import { StyleProp, ViewStyle } from 'react-native';
import Button from 'ui/components/Button';
import Icon from 'ui/components/Icon';
import { FlatFilterButton } from 'ui/components/v2/Buttons/FilterButton/Flat';
import Tooltip from 'ui/components/v2/Tooltip';
import { colors } from 'ui/theme/colors';

import CommonInputWrapper from '../../CommonInputWrapper';
import { SearchTextInput } from '../SearchTextInput';
import { useActivitySearchAutoCompleteController } from './controller';
import { SearchOverlay } from './Overlay';
import { SearchAutoCompleteProps } from './types';

export const SearchAutoComplete = (props: SearchAutoCompleteProps) => {
  const {
    label,
    helpText,
    placeholder,
    errorMessage,
    filtersCount,
    isDisabled = false,
    isRequired = false,
    showFilterButton = false,
    buttonBackGroundColor = colors.singletons.black,
    onFilterButtonPress,
  } = props;

  const {
    options,
    isMobile,
    inputValue,
    isSubmitButtonDisabled,
    handleSubmit,
    handleInputClear,
    handleInputValueChange,
  } = useActivitySearchAutoCompleteController(props);

  const clearButtonOffset = useBreakpointValue({
    base: showFilterButton ? 10 : 0,
    sm: showFilterButton ? '88px' : 12,
  });

  const inputPaddingRight = useBreakpointValue({
    base: showFilterButton ? '76px' : 10,
    sm: showFilterButton ? '124px' : '86px',
  });

  return (
    <CommonInputWrapper
      label={label}
      isRequired={isRequired}
      isDisabled={isDisabled}
      errorMessage={errorMessage}
      helpText={helpText}
    >
      <HStack w="full" alignItems={'center'} flex={1}>
        <SearchOverlay
          options={options || []}
          defaultValue={inputValue}
          onSubmit={handleSubmit}
        >
          <SearchTextInput
            value={inputValue}
            placeholder={placeholder}
            onChange={handleInputValueChange}
            onClear={handleInputClear}
            onSubmit={handleSubmit}
            showIcon={isMobile}
            clearButtonOffset={clearButtonOffset}
            paddingRight={inputPaddingRight}
          />
        </SearchOverlay>

        <HStack marginRight={2} position={'absolute'} right={0}>
          {showFilterButton && (
            <FlatFilterButton
              onPress={onFilterButtonPress}
              filtersCount={filtersCount}
              iconColor={buttonBackGroundColor}
            />
          )}

          <Hidden till="sm">
            <Tooltip
              label={isSubmitButtonDisabled ? 'Start typing to explore' : ''}
            >
              <Button
                w="40px"
                h="40px"
                minW={'unset'}
                borderRadius={999}
                onPress={() => handleSubmit()}
                isDisabled={isSubmitButtonDisabled}
                backgroundColor={
                  buttonBackGroundColor || colors.singletons.black
                }
                style={
                  {
                    cursor: isSubmitButtonDisabled ? 'default' : 'pointer',
                  } as StyleProp<ViewStyle>
                }
              >
                <Icon
                  size={'16px'}
                  color={colors.singletons.white}
                  icon="search"
                />
              </Button>
            </Tooltip>
          </Hidden>
        </HStack>
      </HStack>
    </CommonInputWrapper>
  );
};

export default SearchAutoComplete;
