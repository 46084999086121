import { useMemo } from 'react';
import { FilterButton } from 'ui/components/v2/Buttons/FilterButton';
import { DropDownMenuItem } from 'ui/components/v2/DropDown/Menu';

import { SORT_MENU_ITEMS } from '~/components/Layouts/v2/SearchLayout/components/SortByButton/constants';
import { SearchRelevanceSortBy } from '~/services/resources/activity-definitions/search/types';

export interface SortByButtonProps {
  value?: SearchRelevanceSortBy;
  onChange?: (sortBy: SearchRelevanceSortBy) => void;
  sortByOptions?: SearchRelevanceSortBy[];
}

export const SortByButton = ({
  value,
  onChange,
  sortByOptions,
}: SortByButtonProps) => {
  const handleItemPress = (item?: DropDownMenuItem) => {
    if (!item) return;
    onChange?.(item.value as SearchRelevanceSortBy);
  };

  const options = useMemo(() => {
    const items: DropDownMenuItem<SearchRelevanceSortBy>[] = [];
    if (!sortByOptions?.length) return items;

    for (const item of SORT_MENU_ITEMS) {
      if (sortByOptions?.includes(item.value)) {
        items.push(item);
      }
    }
    return items;
  }, [sortByOptions]);

  return (
    <FilterButton.DropDown
      label="Sort by"
      icon="switch"
      value={value as string}
      options={options}
      onChange={handleItemPress}
    />
  );
};
