import {
  Badge,
  Box,
  HStack,
  Pressable,
  Stack,
  Text,
  VStack,
} from 'native-base';
import React, { ReactNode } from 'react';
import Icon from 'ui/components/Icon';
import { colors } from 'ui/theme/colors';
import { formatDate } from 'ui/utils/formatter';

import {
  ListItemInfoSectionProps,
  LogProgressAndHistoryCardContentProps,
  LogProgressAndHistoryCardContentVariations,
} from '~/compound-components/log-progress-and-history/log-progress-and-history-content/log-progress-and-history-content.types';

export const LogProgressCardContentSteps = ({
  items,
}: Pick<LogProgressAndHistoryCardContentProps<'Completion'>, 'items'>) => {
  return (
    <Stack space={4} width="100%">
      {items?.map((item, index) => {
        return (
          <Stack
            padding={2}
            borderRadius="md"
            bgColor={item.completedAt ? 'white' : 'gray.100'}
            key={index}
            w="full"
          >
            <HStack space={4} alignItems="center">
              <Badge
                alignItems="center"
                minWidth={27}
                borderRadius="full"
                bgColor={item.completedAt ? 'darkText' : 'gray.400'}
              >
                <Text color="white" fontWeight={500}>
                  {index + 1}
                </Text>
              </Badge>
              <HStack
                width="full"
                alignItems="center"
                justifyContent="space-between"
                flex={1}
              >
                <Stack space={1}>
                  <Text
                    color={item.completedAt ? 'darkText' : 'gray.500'}
                    fontWeight={500}
                    fontSize="sm"
                  >
                    {item.title}
                  </Text>
                  <Text
                    color={item.completedAt ? 'darkText' : 'gray.500'}
                    fontWeight={400}
                    fontSize="xs"
                  >
                    {item?.description || ''}
                  </Text>
                </Stack>
              </HStack>

              <Stack>
                <Icon
                  icon="check-circle"
                  size={24}
                  color={item.completedAt ? colors.darkText : colors.gray[300]}
                />
              </Stack>
            </HStack>
          </Stack>
        );
      })}
    </Stack>
  );
};

const ListItemInfoSection = ({
  icon,
  text,
  justifyContent = 'flex-start',
  onPressCancel,
}: ListItemInfoSectionProps) => {
  return (
    <HStack
      space={1}
      alignItems={'center'}
      justifyContent={justifyContent}
      minW={{
        base: icon === 'trash' ? '1/1' : '1/2',
        lg: '1/3',
      }}
    >
      {icon !== 'trash' ? (
        <Icon
          size={20}
          icon={icon}
          color={icon === 'trash' ? colors.error[600] : colors.gray[400]}
        />
      ) : null}
      <Text fontSize={'sm'} color={'gray.600'} py={0.5} minW={10}>
        {text}
      </Text>
      {icon === 'trash' ? (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        <Pressable onPress={onPressCancel as any}>
          <Icon
            size={20}
            icon={icon}
            color={icon === 'trash' ? colors.error[600] : colors.gray[400]}
          />
        </Pressable>
      ) : null}
    </HStack>
  );
};

const LogProgressCardItem = ({
  infos,
}: {
  infos?: ListItemInfoSectionProps[];
}) => {
  return (
    <HStack w={'full'} bg={'white'} py={1} px={2} space={4} borderRadius={'sm'}>
      {infos?.map((info, index) => {
        return (
          <ListItemInfoSection
            key={info.icon}
            icon={info.icon}
            text={info.text}
            justifyContent={
              index === 2 ? 'flex-end' : index === 1 ? 'center' : 'flex-start'
            }
            onPressCancel={info.onPressCancel}
          />
        );
      })}
    </HStack>
  );
};

const LogProgressCardContentUnits = ({
  items,
  isEditPressed,
}: Pick<
  LogProgressAndHistoryCardContentProps<'Weight' | 'Custom unit'>,
  'items' | 'isEditPressed'
>) => {
  return (
    <VStack space={2} py={3} width="100%">
      {items?.map((item, index) => {
        const infos = [
          {
            icon: 'calendar',
            text: item?.date
              ? formatDate(new Date(item?.date), 'DD/MM/YYYY') || ''
              : '',
            onPressCancel: item?.onPressCancel,
          },
          {
            icon: 'plus-circle',
            text: `${item.quantity} ${item.measureUnit}`,
            onPressCancel: item?.onPressCancel,
          },
        ];
        if (isEditPressed) {
          infos.push({
            icon: 'trash',
            text: '',
            onPressCancel: item?.onPressCancel,
          });
        }
        return <LogProgressCardItem key={index} infos={infos} />;
      })}
    </VStack>
  );
};

const LogProgressCardContentOngoingEvent = ({
  items,
  isEditPressed,
}: Pick<
  LogProgressAndHistoryCardContentProps<'Time'>,
  'items' | 'isEditPressed'
>) => {
  return (
    <VStack space={2} py={3}>
      {items?.map((item, index) => {
        const infos = [
          {
            icon: 'calendar',
            text: item?.date
              ? `${formatDate(new Date(item.date), 'DD/MM/YYYY')}`
              : '',
            onPressCancel: item?.onPressCancel,
          },
          {
            icon: 'clock',
            text: `${item.hours}`,
            onPressCancel: item?.onPressCancel,
          },
        ];

        if (isEditPressed) {
          infos.push({
            icon: 'trash',
            text: '',
            onPressCancel: item?.onPressCancel,
          });
        }
        return <LogProgressCardItem key={index} infos={infos} />;
      })}
    </VStack>
  );
};

const logProgressContentDictionary: Record<
  LogProgressAndHistoryCardContentVariations,
  JSX.Element | ReactNode
> = {
  'Custom unit': <LogProgressCardContentUnits />,
  Weight: <LogProgressCardContentUnits />,
  Completion: <LogProgressCardContentSteps />,
  Time: <LogProgressCardContentOngoingEvent />,
};

export const LogProgressAndHistoryCardContent = <
  Variation extends LogProgressAndHistoryCardContentVariations,
>({
  children,
  items = [],
  listType,
  isEditPressed,
}: LogProgressAndHistoryCardContentProps<Variation>) => {
  const content = logProgressContentDictionary[listType];

  return (
    <div className={`log-progress-content`}>
      <Box pt={3}>
        {React.cloneElement(content as React.ReactElement, {
          items,
          isEditPressed,
        })}
      </Box>
      {children}
    </div>
  );
};
