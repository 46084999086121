import { format } from 'date-fns';
import moment, { Moment } from 'moment';
import { ImageZoneImageItem } from 'ui/components/Dropzone/ImageZoneMini/types';
import { EnglishTypes } from 'ui/types/englishType';

export const formatDate = (date: Date | string, format = 'MM/DD/YYYY') => {
  if (!date) return null;
  return moment(date).format(format);
};

export const formatDateByEnglishType = (
  date: Date | string,
  englishType: EnglishTypes = 'american',
) => {
  if (!date) return null;

  let momentFormat: string;
  switch (englishType) {
    case 'american':
      momentFormat = 'MM/DD/YYYY';
      break;
    case 'british':
      momentFormat = 'DD/MM/YYYY';
      break;
  }

  return moment(date).format(momentFormat);
};

export const formattedTime = (date: Date | Moment, formatString = 'hh:mm') => {
  return format(new Date(date as Date), formatString);
};

export const formattedDate = (
  date: Date | Moment,
  formatString = 'dd/MM/yyyy hh:mm',
  undefinedFallback = '01/01/1969',
) => {
  if (date) {
    return format(new Date(date as Date), formatString);
  }

  return undefinedFallback;
};

// de-DE format the number with periods.
export const formatNumber = (number: number, locale = 'de-DE') => {
  if (!number) return 0;
  return number.toLocaleString(locale);
};

export const convertCarouselImagesToImageZone = (
  carouselImages?: string[] | ImageZoneImageItem[],
) => {
  if (!carouselImages) return;
  const imageZoneMiniImages = carouselImages.map((carouselImage, index) => {
    if (typeof carouselImage === 'string') {
      const normalizedFile: ImageZoneImageItem = {
        id: index,
        isFile: false,
        imageUrl: carouselImage,
      };
      return normalizedFile;
    } else return carouselImage;
  });

  return imageZoneMiniImages;
};

export const transformInputValueToLowerCase = (value: string) =>
  value.toLowerCase();
