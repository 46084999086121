import {
  AppPermissions,
  MemberPermissions,
} from 'ui/constants/permission.constants';
import { IPermission } from 'ui/types/interfaces/permission';

// Menu Items Permissions

const OpportunitiesMenuPermissions: Partial<IPermission>[] = [];
const ActivitiesMenuPermissions: Partial<IPermission>[] = [];
const LogActivityMenuPermissions: Partial<IPermission>[] = [];
const TeamsMenuPermissions: Partial<IPermission>[] = [];
const UserProfileMenuPermissions: Partial<IPermission>[] = [];
const AppsMenuPermissions: Partial<IPermission>[] = [];

export const MyWalletMenuPermissions = {
  Opportunities: OpportunitiesMenuPermissions,
  Activities: ActivitiesMenuPermissions,
  LogActivity: LogActivityMenuPermissions,
  Teams: TeamsMenuPermissions,
  Profile: UserProfileMenuPermissions,
  Apps: AppsMenuPermissions,
};

// Opportunities Tabs Permissions

const EventsTabPermissions: Partial<IPermission>[] = [
  MemberPermissions.ViewEvents,
];

const TeamEventsTabPermissions: Partial<IPermission>[] = [
  MemberPermissions.ViewTeamEvents,
];

const ActionsTabPermissions: Partial<IPermission>[] = [
  MemberPermissions.ViewAction,
];

const OngoingOpportunitiesTabPermissions: Partial<IPermission>[] = [
  MemberPermissions.ViewOngoingOpportunities,
];

export const OpportunitiesTabsPermissions = {
  Events: EventsTabPermissions,
  TeamEvents: TeamEventsTabPermissions,
  Actions: ActionsTabPermissions,
  OngoingOpportunities: OngoingOpportunitiesTabPermissions,
};
