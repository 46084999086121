import { convertMetersToMiles } from 'ui/utils/location/transform-distance';
export const MakeFooterLocationNode = (
  distance?: number,
  isFromHome?: boolean,
  isOnline?: boolean,
) => {
  if (isFromHome && !isOnline) return;

  if (isOnline) {
    return { label: 'Online', icon: 'wifi' };
  } else if (distance !== undefined && !isNaN(distance)) {
    const miles = convertMetersToMiles(distance);

    const distanceLabel =
      miles < 1 ? 'Near You' : `${String(Math.ceil(miles))} miles from you`;

    return {
      label: distanceLabel,
      icon: 'map-pin',
    };
  }
};

export const MakeSignUpTextNode = (applicationsCount?: number) => ({
  label: !applicationsCount
    ? 'Be the first one to sign-up!'
    : `${applicationsCount} sign ups`,
});

export const MakeTeamSizeTextNode = (
  teamMinSize?: number,
  teamMaxSize?: Number,
) => {
  if (!teamMinSize && !teamMaxSize) return { label: '' };
  return {
    label: `${teamMinSize}-${teamMaxSize} person team`,
    icon: 'users',
  };
};
