import { useEffect, useState } from 'react';

import {
  CompletionMeasurementsCardProps,
  IUserApplicationSteps,
} from '~/pages/Authenticated/v2/ActivityView/views/ActivityLog/components/ActionApplicationCard/CompletionMeasurements/types';

export const useCompletionMeasurementsCardController = (
  props: CompletionMeasurementsCardProps,
) => {
  const { activity, measurementUnit } = props;
  const application = activity.userApplication;

  // States
  const [expandSteps, setExpandSteps] = useState(false);
  const [progress, setProgress] = useState({ count: 0, total: 0 });
  const [steps, setSteps] = useState<IUserApplicationSteps[]>([]);

  // -- Hooks
  useEffect(() => {
    const originalSteps = measurementUnit.steps || [];
    const applicationSteps = (application?.measurements || [])[0]?.steps || [];

    const newSteps: IUserApplicationSteps[] = [];

    originalSteps.forEach((step, index) => {
      const completedStep = applicationSteps.find(
        (appStep) => appStep.step === index,
      );

      newSteps.push({
        title: step.title,
        description: step.description || '',
        completedAt: completedStep ? completedStep.completedAt : undefined,
      });
    });

    setSteps(newSteps);
    setProgress({
      count: applicationSteps.length,
      total: newSteps.length,
    });
  }, [measurementUnit, application]);

  // -- Handlers
  const toggleSteps = () => {
    setExpandSteps((prev) => !prev);
  };

  return {
    steps,
    progress,
    expandSteps,
    toggleSteps,
  };
};
