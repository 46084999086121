import { Pressable, VStack } from 'native-base';
import { ReactNode } from 'react';

export const EmptyAppTile = ({
  children,
  onPress,
}: {
  onPress: VoidFunction;
  children: ReactNode;
}) => {
  return (
    <Pressable
      width={'full'}
      minH={'full'}
      overflowX={'hidden'}
      borderColor={'gray.300'}
      onPress={onPress}
      p={1}
      borderRadius={8}
    >
      <VStack width={'full'} alignItems={'center'} space={2}>
        <VStack
          borderRadius={8}
          overflow={'hidden'}
          w={{ base: '32px', md: '40px' }}
          h={{ base: '32px', md: '40px' }}
          borderColor="gray.300"
          borderWidth={1}
          alignItems={'center'}
          justifyContent={'center'}
        >
          {children}
        </VStack>
      </VStack>
    </Pressable>
  );
};
