import { useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { IModalRefProps } from 'ui/components/Modals/Modal/types';

import { PAGES } from '~/constants/pages.constants';
import { useAppDispatch } from '~/hooks/useAppDispatch';
import { useAppSelector } from '~/hooks/useAppSelector';
import { useLoadUserProfiles } from '~/hooks/useLoadUserProfiles';
import { useSwitchNotifyPersona } from '~/hooks/useNotify/switchNotifyPersonaNotify';
import { usePermissions } from '~/hooks/usePermissions';
import { useRouter } from '~/hooks/useRouter';
import { authSliceActions } from '~/store/slices/auth';
import { ecosystemSliceActions } from '~/store/slices/ecosystem';
import { IEcosystem } from '~/types/interfaces/ecosystem';
import { IOrganization } from '~/types/interfaces/organization';
import { IUserProfile } from '~/types/interfaces/user';

const DEBOUNCE_TIME = 500;

let debounceTimeout: NodeJS.Timeout | null;

export const useSwitchPersonaController = () => {
  const [searchText, setSearchText] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [isChangingPersona, setIsChangingPersona] = useState(false);

  const { selectedEcosystem } = useAppSelector(({ ecosystem }) => ecosystem);
  const { user } = useAppSelector(({ auth }) => auth);

  const { userProfiles, isLoading } = useLoadUserProfiles();
  const { handleUpdateUserPermissions } = usePermissions();

  const { handleNotifyPersona } = useSwitchNotifyPersona();

  const modalRef = useRef<IModalRefProps>(null);
  const dispatch = useAppDispatch();
  const { goToRoute, goBack } = useRouter();

  const { control } = useForm();

  const handleOnChangeText = (searchText: string) => {
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    debounceTimeout = setTimeout(() => {
      setSearchText(searchText);
      debounceTimeout = null;
    }, DEBOUNCE_TIME);
  };

  const filteredUserProfiles = useMemo(() => {
    if (searchText === '') {
      return userProfiles || [];
    }

    const filteredProfiles = userProfiles?.filter((profile) => {
      setIsSearching(true);
      return (
        profile._id.toLowerCase().includes(searchText.toLowerCase()) ||
        (profile.ecosystem as unknown as IEcosystem).name
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        (profile.ecosystemSummary.organization as unknown as IOrganization).name
          .toLowerCase()
          .includes(searchText.toLowerCase())
      );
    });

    setIsSearching(false);
    return filteredProfiles;
  }, [searchText, userProfiles]);

  const onCloseModal = () => {
    modalRef.current?.close();
  };

  const openModal = () => {
    modalRef.current?.open();
  };

  const handleChangePersona = async (userProfile: IUserProfile) => {
    const ecosystem = userProfile.ecosystem as unknown as IEcosystem;

    setIsChangingPersona(true);

    // NOTE: we should simplify all this dispatch into one
    dispatch(ecosystemSliceActions.resetEcosystem());

    dispatch(
      ecosystemSliceActions.selectEcosystem({
        ecosystem,
        isDefaultEcosystem: false,
      }),
    );

    dispatch(
      authSliceActions.update({
        selectedUserProfile: {
          ...userProfile,
          ecosystem: ecosystem._id,
        },
        redirectToSwitchProfile: false,
      }),
    );

    try {
      await handleUpdateUserPermissions(userProfile._id);
    } finally {
      setIsChangingPersona(false);
      handleNotifyPersona(userProfile);
      goToRoute(PAGES.Root);
    }
  };

  const handleGoBack = () => {
    if (window.history.state.idx > 1) {
      goBack();
    } else {
      goToRoute(PAGES.Root);
    }
  };

  return {
    control,
    isLoading,
    user,
    selectedEcosystem,
    filteredUserProfiles,
    isSearching,
    modalRef,
    isChangingPersona,
    handleGoBack,
    handleOnChangeText,
    onCloseModal,
    openModal,
    handleChangePersona,
  };
};
