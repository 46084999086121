import { useImperativeHandle, useMemo, useRef, useState } from 'react';

import { ModalProps } from './types';

const useModalController = (
  {
    isOpen,
    children,
    headerText,
    headerTextColor = 'gray.900',
    headerFontSize = '24px',
    leftBtnAction,
    leftBtnText,
    leftVariant = 'ghost',
    rightBtnAction,
    rightBtnText = 'close',
    rightBtnTextColor = 'white',
    rightVariant,
    btnFontWeight = '500',
    btnFontSize = '16',
    modalContentMaxW,
    modalBgColor = 'coolGray.50',
    modalFooterBgColor,
    placement = 'center',
    rightBtnLoading = false,
    headerIconName,
    isHeaderIconCustom = false,
    ...rest
  }: ModalProps,
  // FIXME: add type for this ref
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref: any,
) => {
  const modalRef = useRef(null);
  const [selectedItemId, setSelectedItemId] = useState('');
  const [showModal, setShowModal] = useState(isOpen);

  const showGroupButton = useMemo(() => {
    return !!leftBtnAction && !!leftBtnText;
  }, [leftBtnAction, leftBtnText]);

  const hideFooter = useMemo(() => {
    return !showGroupButton && !rightBtnAction;
  }, [showGroupButton, rightBtnAction]);

  useImperativeHandle(
    ref,
    () => {
      return {
        open(itemId?: string) {
          if (itemId) {
            setSelectedItemId(itemId);
          }

          handleOpen();
        },
        close() {
          handleClose();
          setSelectedItemId('');
        },
      };
    },
    [],
  );

  const handleOpen = () => {
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  return {
    selectedItemId,
    handleOpen,
    handleClose,
    children,
    headerText,
    headerTextColor,
    headerFontSize,
    leftBtnAction,
    leftBtnText,
    leftVariant,
    rightBtnAction,
    rightBtnText,
    rightVariant,
    rightBtnTextColor,
    btnFontWeight,
    btnFontSize,
    modalContentMaxW,
    modalBgColor,
    modalFooterBgColor,
    showModal,
    modalRef,
    showGroupButton,
    hideFooter,
    placement,
    rightBtnLoading,
    headerIconName,
    isHeaderIconCustom,
    ...rest,
  };
};

export default useModalController;
