import { ActivitySemanticType } from 'ui/types/activities';

import { SEARCH_COLLECTION_CONFIG_MAP } from '~/components/Layouts/v2/SearchLayout/constants/search-collections';
import { AttendanceType } from '~/types/interfaces/activity';
import { SearchCollectionSlug } from '~/types/interfaces/activity-search/types';
import {
  FiltersWhiteListMap,
  FilterWhiteListCanon,
} from '~/utils/activity-search/filter-white-list-map';

export type InAppCarouselCollectionName =
  | SearchCollectionSlug.Events
  | SearchCollectionSlug.EventsFromHome
  | SearchCollectionSlug.TeamEvents
  | SearchCollectionSlug.Actions
  | SearchCollectionSlug.Ongoing
  | SearchCollectionSlug.RemoteOngoing;

export const IN_APP_CAROUSEL_COLLECTIONS_WHITE_LIST_CONFIG: Record<
  InAppCarouselCollectionName,
  FilterWhiteListCanon
> = {
  [SearchCollectionSlug.Events]: {
    activityTypes: [ActivitySemanticType.Event],
    attendanceTypes: [AttendanceType.Local],
  },
  [SearchCollectionSlug.EventsFromHome]: {
    activityTypes: [ActivitySemanticType.Event],
    attendanceTypes: [AttendanceType.Online],
  },
  [SearchCollectionSlug.TeamEvents]: {
    activityTypes: [ActivitySemanticType.TeamEvent],
    attendanceTypes: [AttendanceType.Local],
  },
  [SearchCollectionSlug.Actions]: {
    activityTypes: [ActivitySemanticType.Action],
    attendanceTypes: [AttendanceType.Online],
  },
  [SearchCollectionSlug.Ongoing]: {
    activityTypes: [ActivitySemanticType.Ongoing],
    attendanceTypes: [AttendanceType.Local],
  },
  [SearchCollectionSlug.RemoteOngoing]: {
    activityTypes: [ActivitySemanticType.Ongoing],
    attendanceTypes: [AttendanceType.Online],
  },
};

export const inAppCarouselCollectionsWhiteListMap =
  new FiltersWhiteListMap<InAppCarouselCollectionName>(
    Object.entries(IN_APP_CAROUSEL_COLLECTIONS_WHITE_LIST_CONFIG) as [
      InAppCarouselCollectionName,
      FilterWhiteListCanon,
    ][],
  );

export const collectionsLabels = Object.fromEntries(
  Array.from(SEARCH_COLLECTION_CONFIG_MAP).map(([slug, config]) => [
    slug,
    {
      title: config?.title?.large || '',
      subtitle: config.subTitle,
    },
  ]),
);
