import { useMemo } from 'react';

import { ActivityCarousel } from '~/components/v2/ActivityCarousel';
import { ActivityCarouselDataSource } from '~/components/v2/ActivityCarousel/types';

export const CarouselSkeletonOverflow = ({
  isLoading,
}: {
  isLoading: boolean;
}) => {
  const renderFakerCarouselsOverflow = useMemo(() => {
    return (
      <div className={`fake-carousel-overflow ${isLoading ? 'show' : ''}`}>
        <ActivityCarousel
          isEnabled={true}
          marginBottom={10}
          dataSource={
            new ActivityCarouselDataSource(
              new Promise((res) => setTimeout(() => res([]), 99999)),
              ['test123'],
            )
          }
        />
      </div>
    );
  }, [isLoading]);

  return <>{renderFakerCarouselsOverflow}</>;
};
