import { useBreakpointValue } from 'native-base';
import { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { IModalRefProps } from 'ui/components/Modals/Modal/types';

import { useAppDispatch } from '~/hooks/useAppDispatch';
import { useAppSelector } from '~/hooks/useAppSelector';
import { ActivityApplicationsService } from '~/services/resources/activity-applications';
import { measurementsDataSliceActions } from '~/store/slices/measurementsData';
import { getMeasurementsMap } from '~/utils/getMeasurementsMap';

export const useActivityDetailsController = (
  refetchMeasurements: () => void,
) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isEditPressed, setIsEditPressed] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [measurementId, setMeasurementId] = useState('');

  const dispatch = useAppDispatch();
  const { selectedUserProfile } = useAppSelector(({ auth }) => auth);

  const modalRef = useRef<IModalRefProps>(null);

  const breakpointValue = useBreakpointValue({
    lg: {
      hideArrow: false,
      contentWidth: 'full',
    },
    base: {
      hideArrow: true,
      contentWidth: 'calc(100% - 2rem)',
    },
  });

  const toggleIsExpanded = () => {
    setIsExpanded((prev) => !prev);
  };

  const toggleIsEditPressed = () => {
    setIsEditPressed((prev) => !prev);
  };

  const onPressCancel = (id: string) => {
    setMeasurementId(id);
    modalRef?.current?.open();
  };

  const handleCloseModal = () => {
    modalRef?.current?.close();
  };

  const handleDeleteMeasurement = async () => {
    if (!measurementId) return;
    try {
      setIsSubmitting(true);
      await ActivityApplicationsService.deleteMeasurementUnit(measurementId);
      toast.success(`Log canceled with success.`);
      handleCloseModal();
      refetchMeasurements();
      if (selectedUserProfile?._id) {
        const { mapApplicationsData } = await getMeasurementsMap(
          selectedUserProfile._id,
        );
        dispatch(
          measurementsDataSliceActions.setMeasurementsData({
            ...mapApplicationsData,
          }),
        );
      }
    } catch (error) {
      toast.error(`Error on trying to cancel log.`);
    } finally {
      setIsSubmitting(false);
    }
  };

  return {
    breakpointValue,
    toggleIsExpanded,
    isExpanded,
    isEditPressed,
    toggleIsEditPressed,
    onPressCancel,
    modalRef,
    handleCloseModal,
    handleDeleteMeasurement,
    isSubmitting,
  };
};
