import { Box, Image } from 'native-base';
import { useMemo } from 'react';

import { ActivityCoverStyleMap } from '~/pages/Authenticated/v2/ActivityView/components/ActivityCover/constants';
import {
  ActivityCoverProps,
  CoverStyleAtributes,
} from '~/pages/Authenticated/v2/ActivityView/components/ActivityCover/type';
import { colors } from '~/theme/colors';

export const ActivityCover = (props: ActivityCoverProps) => {
  const { coverImage, activityType } = props;

  const style = useMemo(() => {
    if (!activityType) return { backgroundColor: colors.red[400] };
    return ActivityCoverStyleMap.get(activityType) as CoverStyleAtributes;
  }, [activityType]);

  return coverImage ? (
    <Image
      alt={'activity cover image'}
      source={{ uri: coverImage }}
      w={'full'}
      h={{ base: '240', lg: '496px' }}
      mb={{ base: -10, lg: -56 }}
    />
  ) : (
    <Box bg={style.backgroundColor} w={'full'} h={{ base: '20' }} mb={-10} />
  );
};

export default ActivityCover;
