import { useEffect, useRef } from 'react';

import { SearchCollectionSlug } from '~/types/interfaces/activity-search/types';
import { useSearch } from '~/zustand/store/Search';

import { useCarouselsSuggestionsController } from '../../controller';
import { FeedSpanCarouselsSuggestionProps } from './types';

export const useFeedSpanCarouselsSuggestion = ({
  collectionSlug,
}: FeedSpanCarouselsSuggestionProps) => {
  // Providers
  const setProcessedCollection = useSearch(
    (state) => state.setProcessedCollection,
  );

  // States
  const processedSlugsRef = useRef<SearchCollectionSlug | null>(null);

  // Hooks
  const { isFilterSet, carouselsMap } = useCarouselsSuggestionsController();

  const selectedCarousel = (carouselsMap as any)[collectionSlug];
  useEffect(() => {
    if (
      !collectionSlug ||
      !selectedCarousel ||
      processedSlugsRef.current === collectionSlug
    )
      return;

    setProcessedCollection(collectionSlug);
    processedSlugsRef.current = collectionSlug;
  }, [selectedCarousel, setProcessedCollection, collectionSlug]);

  return { isFilterSet, selectedCarousel };
};
