import { ReactNode } from 'react';
import ReactDOM from 'react-dom';

export type RootPortalProps = {
  id?: string;
  children: ReactNode | any;
};

export const RootPortal = (props: RootPortalProps) => {
  const { id = 'modal-root', children } = props;
  return (
    <>{ReactDOM.createPortal(children, document.getElementById(id) as any)}</>
  );
};
