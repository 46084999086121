import { IUserActivity } from '~/pages/Authenticated/v2/ActivityView/types';
import { IActivity, IActivityApplication } from '~/types/interfaces/activity';

/* 
  -- Activity Mapper to UserActivity
  Here we map the user's applications according to their respective activities.
*/

export const mapUserActivities = (
  activities: IActivity[],
  activitiesApplications: IActivityApplication[],
) => {
  const userActivities = [...activities];
  if (activitiesApplications.length == 0) return userActivities;

  for (const application of activitiesApplications) {
    const activityIndex = userActivities.findIndex(
      (a) => a._id === application.activity,
    );

    if (activityIndex != -1) {
      (userActivities[activityIndex] as IUserActivity).userApplication =
        application;
    }
  }
  return userActivities;
};
