import { VStack } from 'native-base';
import { IconEnum } from 'ui/components/Icon';
import { colors } from 'ui/theme/colors';

import { BookingCardActionContent } from '~/compound-components/booking-selection-card/booking-card-action-content/booking-card-action-content.composition';
import ActivityApplicationFooter from '~/pages/Authenticated/v2/ActivityView/components/ActivityApplicationFooter';
import { ActivityPanel } from '~/pages/Authenticated/v2/ActivityView/components/ActivityPanel';
import { useActionActivityApplicationController } from '~/pages/Authenticated/v2/ActivityView/views/ActivityApplication/components/ActionApplicationPanel/controller';
import { ActionApplicationPanelProps } from '~/pages/Authenticated/v2/ActivityView/views/ActivityApplication/components/ActionApplicationPanel/types';

export const ActionApplicationPanel: React.FC<
  ActionApplicationPanelProps
> = () => {
  const {
    applicationAvailability,
    handleApplication,
    actionLabel,
    goalText,
    title,
  } = useActionActivityApplicationController();

  return (
    <ActivityPanel
      title={title}
      titleColor={colors.gray[900]}
      headerBackground={colors.gray[200]}
    >
      <VStack
        borderWidth={1}
        borderRadius={'12px'}
        borderColor={colors.gray[200]}
        overflow={'hidden'}
      >
        <BookingCardActionContent.Root>
          <BookingCardActionContent.Title text={'You need to achieve:'} />
          <BookingCardActionContent.Goal
            text={goalText}
            icon={IconEnum.TARGET_AND_ARROW}
          />
        </BookingCardActionContent.Root>
      </VStack>

      <ActivityApplicationFooter
        isActionDisabled={!applicationAvailability.isAvailable}
        tooltipMessage={applicationAvailability.message}
        actionLabel={actionLabel}
        onAction={handleApplication}
      />
    </ActivityPanel>
  );
};

export default ActionApplicationPanel;
