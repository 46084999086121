import { useQuery } from '~/hooks/useQuery';
import { Measurement } from '~/types/interfaces/measurement';

export const useLoadMeasurementsByActivityApplication = (
  activityApplicationId: string,
) => {
  const { data, isLoading, refetch } = useQuery<Measurement[]>(
    `measurement/activity-application/${activityApplicationId}`,
    {
      key: `measurement-by-activity-application-${activityApplicationId}`,
      queryOptions: { enabled: !!activityApplicationId },
    },
  );

  return { data, isLoading, refetch };
};
