import { BHO_HELP_CENTER_URL } from 'ui/constants/commonUrl.constants';
import { colors } from 'ui/theme/colors';

import { AccountMenuOptionsKeys } from '~/components/NavBar/components/AccountDropdown/MenuOptionsMap/types';
import { AccountMenuOption } from '~/components/NavBar/components/AccountDropdown/types';
import { DOIT_ORG_ADMIN_URL } from '~/config';
import { PAGES } from '~/constants/pages.constants';

export const menuOptions: Record<AccountMenuOptionsKeys, AccountMenuOption> = {
  [AccountMenuOptionsKeys.Home]: {
    key: AccountMenuOptionsKeys.Home,
    label: 'Home',
    icon: 'home',
    path: PAGES.Home,
    action: 'router',
  },
  [AccountMenuOptionsKeys.Apps]: {
    key: AccountMenuOptionsKeys.Apps,
    label: 'Apps',
    icon: 'grid',
    path: PAGES.AllApps,
    action: 'router',
  },
  [AccountMenuOptionsKeys.Activities]: {
    key: AccountMenuOptionsKeys.Activities,
    label: 'Activities',
    icon: 'calendar',
    path: PAGES.Activities,
    action: 'router',
  },
  [AccountMenuOptionsKeys.Teams]: {
    key: AccountMenuOptionsKeys.Teams,
    label: 'Teams',
    icon: 'users',
    path: PAGES.Teams,
    action: 'router',
  },
  [AccountMenuOptionsKeys.LogActivity]: {
    key: AccountMenuOptionsKeys.LogActivity,
    label: 'Log activity',
    icon: 'plus-circle',
    path: PAGES.LogActivities,
    action: 'router',
  },
  [AccountMenuOptionsKeys.Opportunities]: {
    key: AccountMenuOptionsKeys.Opportunities,
    label: 'Opportunities',
    icon: 'list',
    path: PAGES.Opportunities,
    action: 'router',
  },
  [AccountMenuOptionsKeys.Profile]: {
    key: AccountMenuOptionsKeys.Profile,
    label: 'Profile',
    icon: 'user',
    path: PAGES.Profile,
    action: 'router',
  },
  [AccountMenuOptionsKeys.Settings]: {
    key: AccountMenuOptionsKeys.Settings,
    label: 'Settings',
    icon: 'settings',
    path: PAGES.AccountSettings,
    action: 'router',
  },
  [AccountMenuOptionsKeys.SwitchPersona]: {
    key: AccountMenuOptionsKeys.SwitchPersona,
    label: 'Switch persona',
    icon: 'switch',
    path: PAGES.SwitchPersona,
    action: 'router',
  },
  [AccountMenuOptionsKeys.GoToMyAdminArea]: {
    key: AccountMenuOptionsKeys.GoToMyAdminArea,
    label: 'Go to my admin area',
    icon: 'external-link',
    path: DOIT_ORG_ADMIN_URL,
    action: 'external',
  },
  [AccountMenuOptionsKeys.HelpCentre]: {
    key: AccountMenuOptionsKeys.HelpCentre,
    label: 'Help centre',
    icon: 'question',
    path: BHO_HELP_CENTER_URL,
    action: 'external',
  },
  [AccountMenuOptionsKeys.SignOut]: {
    key: AccountMenuOptionsKeys.SignOut,
    label: 'Sign out',
    icon: 'log-out',
    iconColor: colors.error[600],
    path: PAGES.AccountSettings,
    action: 'custom',
  },
};
