import { useQuery } from '~/hooks/useQuery';
import { ITeam } from '~/types/interfaces/team';

export const useLoadUserTeams = (
  userProfileId: string,
  isEnabled = false,
  limit = 999,
) => {
  const { data, isLoading } = useQuery<{ data: ITeam[] }>(
    `team/me/${userProfileId}`,
    {
      key: `team-me-${userProfileId}`,
      queryOptions: { enabled: isEnabled },
      requestOptions: { params: { limit } },
    },
  );

  return { isLoading, data: data?.data ?? [] };
};
