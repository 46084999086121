import { Controller } from 'react-hook-form';

import BaseSelect from '../BaseSelect';
import { IControlledSelectProps } from './types';

export const ControlledSelect = (props: IControlledSelectProps) => {
  const {
    control,
    name = '',
    label,
    options,
    helpText,
    isRequired,
    isDisabled,
    errorMessage,
    placeholder,
    isLoading,
    isMulti,
    isClearable,
  } = props;

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => {
        return (
          <BaseSelect
            errorMessage={errorMessage}
            placeholder={placeholder}
            isClearable={isClearable}
            isRequired={isRequired}
            isDisabled={isDisabled}
            isLoading={isLoading}
            onChange={onChange}
            helpText={helpText}
            options={options}
            isMulti={isMulti}
            label={label}
            value={value}
          />
        );
      }}
    />
  );
};

export default ControlledSelect;
