import { useDisclose } from 'native-base';
import { useMemo } from 'react';
import { ILocation } from 'ui/types/interfaces';

import { ActivityLocationSelectorProps } from '~/pages/Authenticated/v2/ActivityView/components/ActivitySelectors/ActivityLocationSelector/types';
import { AttributeLabel } from '~/pages/Authenticated/v2/ActivityView/components/ActivitySelectors/ActivitySelectorCardBase/types';
import { padNumberWithZeros } from '~/utils/formatNumber';

export const useActivityLocationSelectorController = (
  props: ActivityLocationSelectorProps,
) => {
  const {
    isFromHome = false,
    selectedLocation,
    availableLocations,
    onOpen: customOnOpen,
    onLocationChange,
    availableSpotsCount,
    showAvailableSpots = false,
  } = props;
  const { isOpen, onClose, onOpen } = useDisclose();

  const handleLocationSelect = (location: ILocation | null) => {
    onLocationChange(location);
    onClose();
  };

  const cardExtraLabels: AttributeLabel[] = useMemo(() => {
    if (!showAvailableSpots) return [];

    let spotsStr =
      availableSpotsCount === 0 ? 'No spots available' : 'Unlimited spots';

    if (!!availableSpotsCount) {
      spotsStr = `${padNumberWithZeros(availableSpotsCount)} spots available`;
    }

    return [{ key: 'spots', text: spotsStr, icon: 'user-check' }];
  }, [showAvailableSpots, availableSpotsCount]);

  // -- Computed values
  const placesTotal = availableLocations.length;

  const title = !!isFromHome
    ? 'From Home'
    : selectedLocation?.rawLocation || '';

  const tagLabel = getTagLabel(isFromHome, placesTotal);

  // -- Render Statements
  const hideSelector = isFromHome || availableLocations.length == 0;

  return {
    isOpen,
    title,
    tagLabel,
    hideSelector,
    cardExtraLabels,
    handleLocationSelect,
    onOpen: customOnOpen || onOpen,
    onClose,
  };
};

const getTagLabel = (isFromHome: boolean, placesTotal: number) => {
  if (isFromHome) return 'Remote';

  return placesTotal > 0
    ? `+ ${placesTotal} place${placesTotal !== 1 ? 's' : ''}`
    : 'Single location';
};
