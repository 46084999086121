import { useMemo } from 'react';

import { PermissionsBooleanProps, ValidationTypeEnum } from './types';
import {
  validateGenericPermissions,
  validateSpecificAppPermissions,
} from './utils';

export const usePermissionBooleanController = ({
  requiredPermissions,
  availablePermissions,
  validationType,
}: Pick<
  PermissionsBooleanProps,
  'requiredPermissions' | 'availablePermissions' | 'validationType'
>) => {
  const hasValidPermission = useMemo(() => {
    switch (validationType) {
      case ValidationTypeEnum.Generic:
        return validateGenericPermissions({
          requiredPermissions,
          availablePermissions,
        });
      case ValidationTypeEnum.SpecificApp:
        return validateSpecificAppPermissions({
          requiredPermissions,
          availablePermissions,
        });
      default:
        return false;
    }
  }, [requiredPermissions, availablePermissions, validationType]);

  return { hasValidPermission };
};
