import { useBreakpointValue } from 'native-base';
import { useMemo, useState } from 'react';

import { ISelectOptionsItem } from '../types';
import { getSelectBaseStyle } from './styles';
import { IBaseSelectProps } from './types';

export const useBaseSelectController = ({
  onChange,
  errorMessage,
  isMulti,
  size,
  leadingIcon,
}: IBaseSelectProps) => {
  // States
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Hooks
  const isMobile = useBreakpointValue({
    base: true,
    md: false,
  });

  const defaultStyle = useMemo(
    () =>
      getSelectBaseStyle({
        hasError: !!errorMessage,
        hasLeadingIcon: !!leadingIcon,
        size,
        isMulti,
      }),
    [errorMessage, size, isMulti, leadingIcon],
  );

  // Handlers
  const handleOnChange = (
    option: ISelectOptionsItem | ISelectOptionsItem[],
  ) => {
    onChange?.(option);
  };

  const handleMenuOpen = () => {
    setIsMenuOpen(true);
  };

  const handleMenuClose = () => {
    setIsMenuOpen(false);
  };

  return {
    handleOnChange,
    defaultStyle,
    handleMenuOpen,
    handleMenuClose,
    isMobile,
    isMenuOpen,
  };
};
