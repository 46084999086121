import { EEventApplicationType } from 'ui/enums';

export const getLabelByApplicationType = (
  eventType?: EEventApplicationType,
) => {
  const labelsMapping: Record<EEventApplicationType, string> = {
    [EEventApplicationType.Individual]: 'Yes, register for event',
    [EEventApplicationType.Team]: 'Yes, register for team event',
  };

  if (eventType) {
    return labelsMapping[eventType];
  }

  return 'Yes, register for event';
};
