import { HStack, Pressable, Text, VStack } from 'native-base';
import { useMemo } from 'react';
import Icon from 'ui/components/Icon';
import { Team } from 'ui/types/teams';
import { getTeamMembersTotal } from 'ui/utils/teams';

import EventApplicationCard from '~/pages/Authenticated/v2/ActivityView/views/ActivityLog/components/EventApplicationCard';
import { TeamEventApplicationCardProps } from '~/pages/Authenticated/v2/ActivityView/views/ActivityLog/components/TeamEventApplicationCard/types';
import { colors } from '~/theme/colors';

export const TeamEventApplicationCard: React.FC<
  TeamEventApplicationCardProps
> = (props) => {
  const { activity, isSelected, onPress } = props;

  const team = activity.userApplication?.teamSubDocument;
  const teamName = team?.name;

  const membersCount = useMemo(() => {
    return !!team ? getTeamMembersTotal(team as unknown as Team) : 0;
  }, [team]);

  const membersCountStr = `${membersCount} MEMBER${
    membersCount !== 1 ? 'S' : ''
  }`;

  return (
    <Pressable onPress={() => onPress?.(activity._id)}>
      <VStack
        w="full"
        borderWidth={1}
        borderColor={colors.gray[200]}
        borderRadius={16}
        px={3}
        py={2}
        marginBottom={3}
        backgroundColor={isSelected ? colors.gray[600] : colors.gray[50]}
      >
        <HStack alignItems={'center'} w={'100%'} space={2}>
          <Icon
            icon={'users'}
            size={16}
            color={isSelected ? colors.white : colors.gray[500]}
          />

          <Text
            fontWeight={500}
            fontSize={'sm'}
            color={isSelected ? colors.white : colors.gray[600]}
          >
            Team
          </Text>
        </HStack>

        <HStack
          alignItems={'center'}
          w={'100%'}
          space={3}
          mt={1}
          borderBottomColor={colors.gray[200]}
          borderBottomWidth={1}
          borderStyle={'dashed'}
          pb={3}
        >
          <Text
            fontWeight={700}
            fontSize={'sm'}
            color={isSelected ? colors.white : colors.darkText}
          >
            {teamName}
          </Text>
          <Text
            flexDir={'row'}
            color={'singletons.black'}
            bg={'gray.200'}
            fontSize={'xs'}
            borderRadius={'full'}
            p={1}
            py={0.5}
            px={2}
          >
            {membersCountStr}
          </Text>
        </HStack>

        <EventApplicationCard {...props} />
      </VStack>
    </Pressable>
  );
};

export default TeamEventApplicationCard;
