import { VStack } from 'native-base';
import { RootPortal } from 'ui/components/v2/RootPortal';

import { BaseAlertBar } from '../Base';
import { AlertBarProps } from '../types';

export interface FloatAlertBarProps extends AlertBarProps {
  bottomOffset?: number | string;
}

export const FloatAlertBar = ({
  bottomOffset = 0,
  backgroundColor,
  children,
  logoSrc,
  onAction,
}: FloatAlertBarProps) => {
  return (
    <RootPortal>
      <VStack
        left={0}
        position={'fixed'}
        bottom={bottomOffset}
        width={'100%'}
        alignItems={'center'}
      >
        <BaseAlertBar
          backgroundColor={backgroundColor}
          logoSrc={logoSrc}
          showActionButton={false}
          onAction={onAction}
          maxWidth={'calc(100% - 32px)'}
          borderRadius={8}
        >
          {children}
        </BaseAlertBar>
      </VStack>
    </RootPortal>
  );
};
