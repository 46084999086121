import { PresenceTransition, VStack } from 'native-base';
import { ReactNode } from 'react';

export type HomeSectionProps = {
  children?: ReactNode | JSX.Element;
};

export const HomeSection = ({ children }: HomeSectionProps) => {
  return (
    <VStack px={2}>
      <PresenceTransition
        visible={true}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { duration: 500 } }}
      >
        {children}
      </PresenceTransition>
    </VStack>
  );
};

export default HomeSection;
