import { useEffect, useState } from 'react';

import { InlineSearchInputProps, SearchOption } from './types';

export const useInlineSearchInputController = (
  props: InlineSearchInputProps,
) => {
  const { onFetch, onSelect } = props;

  const [inputValue, setInputValue] = useState('');

  const [isOptionsLoading, setIsOptionsLoading] = useState(false);
  const [options, setOptions] = useState<SearchOption[]>([]);

  const handleLoad = (text: string) => {
    setIsOptionsLoading(true);
    onFetch(text)
      .then(setOptions)
      .finally(() => {
        setIsOptionsLoading(false);
      });
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      handleLoad(inputValue);
    }, 150);

    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  useEffect(() => {
    /*  Gambifix pra trigar a pesquisa on mount */
    setInputValue(' ');
  }, []);

  const handleInputChange = (value: string) => {
    setInputValue(value);
  };

  const handleClear = () => {
    onSelect(null);
    setInputValue('');
  };

  const handleSelection = (option?: SearchOption | null) => {
    onSelect(option ?? null);
  };

  return {
    options,
    inputValue,
    isOptionsLoading,
    handleInputChange,
    handleSelection,
    handleClear,
  };
};
