import { useNavigate } from 'react-router-dom';

import { PAGES } from '~/constants/pages.constants';
import { useAppSelector } from '~/hooks/useAppSelector';
import { ActivityApplicationFooterProps } from '~/pages/Authenticated/v2/ActivityView/components/ActivityApplicationFooter/types';

export const useActivityApplicationFooterController = (
  props: ActivityApplicationFooterProps,
) => {
  const { onAction } = props;

  const navigate = useNavigate();
  const { selectedUserProfile } = useAppSelector(({ auth }) => auth);

  const handleAction = () => {
    if (!selectedUserProfile) {
      navigate(PAGES.Root);
      return;
    }
    onAction();
  };

  return { handleAction };
};
