import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { SearchPanel } from '~/components/v2/SearchPanel';
import { PAGES } from '~/constants/pages.constants';
import { useRouter } from '~/hooks/useRouter';
import { generateUrlWithQueryParams } from '~/utils/transformURL';

export type HomeSearchPanelProps = {};

export const HomeSearchPanel = ({}: HomeSearchPanelProps) => {
  // Providers
  const [searchParams] = useSearchParams();
  const { goToRoute } = useRouter();

  // hooks
  const defaultSearchValue = useMemo(() => {
    return searchParams.get('search_text') || '';
  }, [searchParams]);

  const handleSearchSubmit = (searchValue: string) => {
    goToRoute(
      generateUrlWithQueryParams(PAGES.Search, {
        search_text: searchValue,
      }),
    );
  };

  return (
    <SearchPanel
      defaultSearchValue={defaultSearchValue}
      onSubmit={handleSearchSubmit}
    />
  );
};
