import { Text, VStack } from 'native-base';
import { useMemo } from 'react';
import { DateFormatter } from 'ui/utils/formatDates';

import { InfoDateTrailingProps } from '~/pages/Authenticated/v2/ActivityView/components/ActivityInfoCard/components/types';

export const InfoDateTrailing = ({
  date,
  fontColor,
}: InfoDateTrailingProps) => {
  const dateLabels = useMemo(() => {
    const dateFormatter = DateFormatter(date);
    return {
      dayStr: dateFormatter.getDay(),
      monthStr: dateFormatter.getMonth(),
    };
  }, [date]);

  return (
    <VStack height={'fit-content'} alignItems={'center'} pr={2}>
      <>
        <Text
          color={fontColor}
          fontSize={'xl'}
          fontWeight={700}
          lineHeight={'125%'}
        >
          {dateLabels.dayStr}
        </Text>
        <Text color={fontColor} fontSize={'md'} fontWeight={500}>
          {dateLabels.monthStr}
        </Text>
      </>
    </VStack>
  );
};
