import './booking-date-select-root.css';

import { Flex } from 'native-base';

import { BookingDateSelectRootProps } from './booking-date-select-root.types';

export const BookingDateSelectRoot = ({
  isSelected,
  children,
  ...rest
}: BookingDateSelectRootProps) => {
  return (
    <div
      className={`booking-date-select-root${isSelected ? '--selected' : ''}`}
    >
      <Flex
        overflow={'hidden'}
        borderRadius="xl"
        borderColor={isSelected ? 'gray.600' : 'gray.200'}
        borderWidth={'1px'}
        minH="6.5rem"
        p="0.75rem"
        alignItems="center"
        justify={'space-between'}
        flexDir="row"
        bg={isSelected ? 'gray.600' : 'gray.50'}
        {...rest}
      >
        {children}
      </Flex>
    </div>
  );
};
