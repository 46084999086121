import { Box, Flex, Stack, Text } from 'native-base';
import Button from 'ui/components/Button';
import Illustration, { ILLUSTRATIONS_NAME } from 'ui/components/Illustration';

import { useLogActivitySuccessController } from './useLogActivitySuccess';

export const LogActivitySuccess = () => {
  const { isMobile, goToActivities, goToHome } =
    useLogActivitySuccessController();
  return (
    <Flex width="full" alignItems="center" overflowY="auto" bgColor="gray.100">
      <Box
        minH="100vh"
        w={{ base: '100%', md: 'min(90%, 1200px)' }}
        justifyContent={{ md: 'center' }}
        alignItems="center"
        padding={{ base: 0, md: 4 }}
        flexShrink="none"
        mt={{ base: 10, md: 0 }}
      >
        <Flex
          width="100%"
          alignItems="center"
          justifyContent="center"
          flexDirection={{ base: 'column', md: 'row' }}
          style={{ gap: isMobile ? 40 : 100 }}
        >
          <Illustration
            style={
              {
                transform: isMobile ? 'rotateY(0deg)' : 'rotateY(180deg)',
                //eslint-disable-next-line
              } as any
            }
            name={ILLUSTRATIONS_NAME.CONFIRMATION_GREAT}
            width={{ base: '130px', md: '300px' }}
            height={{ base: '168px', md: '400px' }}
          />
          <Flex
            width={{ base: '100%', md: '50%' }}
            padding={{ base: 4, md: 0 }}
          >
            <Stack space={3}>
              <Text
                maxWidth="320px"
                width="100%"
                color="gray.900"
                fontWeight={700}
                fontSize={{ base: 'xl', md: '2xl' }}
                textAlign={{ base: 'center', md: 'initial' }}
              >
                You logged your activity successfully!
              </Text>
              <Text
                color="gray.500"
                fontSize="md"
                textAlign={{ base: 'center', md: 'initial' }}
                maxW={{ base: '340px', md: '100%' }}
                width="100%"
              >
                Thank you for helping out your community.
              </Text>
            </Stack>
            <Stack mt={16} space={3}>
              <Button maxW="400px" width="100%" onPress={goToActivities}>
                Log more activities
              </Button>
              <Button
                variant="ghost"
                colorScheme="darkText"
                maxW="400px"
                width="100%"
                onPress={goToHome}
              >
                Explore oportunities
              </Button>
            </Stack>
          </Flex>
        </Flex>
      </Box>
    </Flex>
  );
};
