import { MeasurementUnit } from 'ui/types/measurementUnit';

import { IOffPlatformLogFields } from '~/pages/Authenticated/OffPlatformApplication/components/LogForm/types';

const measurementDate = new Date();

export const getInitialLogFields = (
  measurementUnits: MeasurementUnit[],
): IOffPlatformLogFields => {
  const measurements = measurementUnits.map((measurementUnit) => ({
    measurementUnit,
    amount: 0,
  }));

  return { measurements, measurementDate };
};
