import { PrimaryCarouselEmptyState } from 'ui/components/v2/Carousel/PrimaryCarousel/components/EmptyState';

import { ActivityCarouselItem } from './Item';
import { CarouselActivityDefinitionData } from './types';

export const makeCarouselSlides = (
  data: CarouselActivityDefinitionData[],
  onPress: VoidFunction,
) => {
  if (!data) return [];

  const slides = data.map((activityDefinition) => ({
    element: <ActivityCarouselItem activityDefinition={activityDefinition} />,
  }));

  if (!!slides.length) {
    slides.push({
      element: (
        <PrimaryCarouselEmptyState
          label="Didn’t find what you’re looking for?"
          actionLabel="View all activities"
          onAction={onPress}
        />
      ),
    });
  }

  return slides;
};
