export enum TextTransform {
  UPPERCASE = 'uppercase',
  LOWERCASE = 'lowercase',
}

//BHO
export enum EActivityAttendanceTypeKeys {
  Home = 'Home',
  Local = 'Local',
}
//DoIT V1
export enum EActivityAttendanceTypes {
  InPerson = 'Local',
  Remote = 'Home',
}

export enum EActivityLocationTypeEnum {
  National = 'National',
  Local = 'Local',
}

export enum AppVisibility {
  Public = 'public',
  Private = 'private',
}

export enum GenericFormTabs {
  About = 0,
  Media = 1,
  WhenWhere = 2,
}

//TODO: Rename this when possible
export enum ETypeOfWorkKeys {
  Alone = 'alone',
  Group = 'group',
  Both = 'both',
}

//TODO: Rename this when possible
export enum ESpaceOptionsKeys {
  Indoor = 'indoor',
  Outdoor = 'outdoor',
  Both = 'both',
}

//TODO: Renamethis when possible
export enum EActivityCategories {
  Volunteering = 'Volunteering',
}

//TODO: Remove this when possible
export enum EEventApplicationType {
  Individual = 'Individual',
  Team = 'Team',
}

export enum PermissionType {
  PARTNER = 'PARTNER',
  MEMBER = 'MEMBER',
}

export enum PermissionCategories {
  Events = 'Events',
  Actions = 'Actions',
  OngoingOpportunity = 'OngoingOpportunity',
  TeamEvents = 'TeamEvents',
  AppSharing = 'AppSharing',
  App = 'App',
}

export enum PermissionConditionValueTypesEnum {
  OBJECT_ID = 'ObjectId',
  STRING = 'String',
  NUMBER = 'Number',
  BOOLEAN = 'Boolean',
  DATE = 'Date',
  QUANTITATIVE = 'Quantitative',
}

export enum TeamEventsConditionFieldsEnum {
  ACTIVITIES_LIMIT = 'ACTIVITIES_LIMIT',
}

export enum ActivityTypeEnum {
  Event = 'Event',
  Action = 'Action',
  OngoingOpportunity = 'OngoingOpportunity',
}

export enum MeasurementCategory {
  Weight = 'Weight',
  Completion = 'Completion',
  Time = 'Time',
  CustomUnit = 'Custom unit',
}

export enum ActivityApplicationEnum {
  Approved = 'Approved',
  Canceled = 'Canceled',
  Rejected = 'Rejected',
  Pending = 'Pending',
  Referral = 'Referral',
}

export enum OrganizationTypeEnum {
  COMPANY = 'COMPANY',
  NFP = 'NFP',
}

export enum EcosystemVisibilityTypeEnum {
  PRIVATE = 'private',
  PUBLIC = 'public',
}

export enum InviteCodeVisibilityEnum {
  Public = 'Public',
  Private = 'Private',
}

export enum PrimaryActionLabelEnum {
  OngoingOpportunity = 'Apply for this opportunity',
  Event = 'Register for this event',
}

export enum LocationOptionsEnum {
  SingleLocation = 'Single Location',
  MultipleLocations = 'Multiple Locations',
  FromHome = 'From Home',
  // Incorrect type but some activities have this for some reason or problem.
  InPerson = 'In Person',
}

export enum LocationDataStatus {
  OK = 'OK',
  ZERO_RESULTS = 'ZERO_RESULTS',
}

export enum AdvertiseRegionOptionsEnum {
  AnywhereInUK = 'Anywhere in UK',
  SelectRegions = 'Select regions',
}

export enum ActivityRegionType {
  Region = 'Region',
  Nation = 'Nation',
}
