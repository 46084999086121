import { Box, HStack, Text, VStack } from 'native-base';

import { ActivitySpecificationsProps } from '~/pages/Authenticated/v2/ActivityView/components/ActivitySpecifications/types';

export const ActivitySpecifications = ({
  specifications,
}: ActivitySpecificationsProps) => {
  return (
    <VStack space={4}>
      {specifications.length > 0 && (
        <HStack
          w="100%"
          alignSelf="center"
          flexWrap="wrap"
          alignItems="center"
          space="3"
          style={{ rowGap: 4 }}
        >
          {specifications.map((spec, index) => (
            <Box px="2" py="1" bg="info.100" borderRadius="full" key={index}>
              <Text fontSize="sm" color="info.800">
                {spec?.toUpperCase()}
              </Text>
            </Box>
          ))}
        </HStack>
      )}
    </VStack>
  );
};

export default ActivitySpecifications;
