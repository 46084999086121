import { Flex, HStack, Stack, VStack } from 'native-base';
import { FiLock } from 'react-icons/fi';
import { ILLUSTRATIONS_NAME } from 'ui/components/Illustration';
import Input from 'ui/components/Input';

import FlowTwoColumnsTemplate from '~/components/Templates/FlowTwoColumnsTemplate';
import { colors } from '~/theme/colors';

import { useResetPasswordController } from './useResetPasswordController';

export const ResetPassword = () => {
  const { control, errors, onSubmit, goBack, isLoading, isMobile } =
    useResetPasswordController();
  return (
    <Flex minH="full" flexShrink={0}>
      <FlowTwoColumnsTemplate
        ilustrationName={ILLUSTRATIONS_NAME.ACCOUNTS_SECURITY}
        title="Reset password"
        subtitle={'Please create a strong password to secure your account.'}
        icon={<FiLock size={32} />}
        progress={80}
        primaryButtonText="Continue"
        primaryButtonVariant="outline"
        primaryButtonOnPress={onSubmit}
        primaryButtonProps={{ borderColor: 'gray.400' }}
        primaryButtonTextStyle={{ fontSize: 'md' }}
        secondaryButtonTextStyle={{ fontSize: 'md' }}
        secondaryButtonOnPress={goBack}
        secondaryButtonText="Back"
        isLoading={isLoading}
      >
        <Stack
          flexBasis={{ base: '100%', md: 'auto' }}
          minH={{ base: 'auto', lg: 'full' }}
          space="4"
          w="full"
          px={6}
          py={8}
          justifyContent={{ base: 'center', md: 'flex-start' }}
        >
          <VStack space={4}>
            <Stack>
              <Input
                name="password"
                control={control}
                placeholder="Create new password"
                label={!isMobile ? 'New password' : ''}
                isRequired
                leftIconName="lock"
                isFullWidth
                errorMessage={
                  errors.password?.message || errors.confirmPassword?.message
                }
                iconErrorMessage="info"
                leftIconColor={colors.muted[400]}
                type="password"
                labelColor="gray.600"
              />
            </Stack>

            <Stack flex={1}>
              <Input
                name="confirmPassword"
                control={control}
                placeholder="Repeat Password"
                label={!isMobile ? 'Confirm password' : ''}
                isRequired
                leftIconName="lock"
                isFullWidth
                leftIconColor={colors.muted[400]}
                type="password"
                showPasswordRules
                labelColor="gray.600"
              />
            </Stack>
          </VStack>
        </Stack>
      </FlowTwoColumnsTemplate>
    </Flex>
  );
};
