import FeatherIcon from 'feather-icons-react';
import { Badge, Flex, Text } from 'native-base';

import Icon, { IconEnum } from '../../Icon';
import useTagController from './controller';
import { CustomTagProps } from './types';

const Tag = (props: CustomTagProps) => {
  const {
    textStyle,
    tagTextColor,
    bgColor,
    fontWeight,
    text,
    _icon,
    leftIconSize,
    textTransform,
    showCustomIcon,
    alignSelf,
  } = useTagController(props);

  return (
    <Badge
      rounded="full"
      background={bgColor}
      m={1}
      zIndex={1}
      variant="solid"
      alignSelf={alignSelf}
      _text={textStyle}
      flexShrink={0}
      leftIcon={
        showCustomIcon ? (
          <Icon
            size={leftIconSize}
            color={tagTextColor}
            icon={_icon as IconEnum}
            isCustom
          />
        ) : (
          <FeatherIcon icon={_icon} size={leftIconSize} color={tagTextColor} />
        )
      }
      {...props.tagProps}
    >
      <Flex direction="row" mx={1} alignItems="center">
        <Text
          color={tagTextColor}
          fontWeight={fontWeight}
          textTransform={textTransform}
          fontSize={textStyle.fontSize}
        >
          {text}
        </Text>
      </Flex>
    </Badge>
  );
};

export default Tag;
