import { ActionActivityCard } from './Action';
import { ActivityBaseCard } from './Base';
import { EventActivityCard } from './Event';
import { ActivityCardFactory } from './Factory';
import { OngoingActivityCard } from './Ongoing';
import { ActivityCardSkeleton } from './Skeleton';
import { TeamEventActivityCard } from './TeamEvent';

export const ActivityCard = {
  Base: ActivityBaseCard,
  Event: EventActivityCard,
  TeamEvent: TeamEventActivityCard,
  Action: ActionActivityCard,
  Ongoing: OngoingActivityCard,
  Factory: ActivityCardFactory,
  Skeleton: ActivityCardSkeleton,
};

export default ActivityCard;
