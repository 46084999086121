import { Flex, HStack, Switch, Text } from 'native-base';
import { Controller } from 'react-hook-form';

import { EmailPreferenceInputProps } from '~/pages/Authenticated/Settings/EmailPreferences/components/EmailPreferencesInput/types';

export const EmailPreferencesInput = ({
  preference,
  control,
}: EmailPreferenceInputProps) => {
  return (
    <Flex flex={1} key={preference.value}>
      <HStack alignItems="center" justifyContent="space-between" mt={6}>
        <Text fontSize={16}>{preference.title}</Text>
        <Controller
          control={control}
          name={preference.value}
          render={({ field: { onChange, value } }) => (
            <Switch
              isChecked={value}
              offTrackColor="gray.400"
              offThumbColor="gray.100"
              onTrackColor="primary.100"
              onThumbColor="primary.400"
              onToggle={onChange}
            />
          )}
        />
      </HStack>
      <Text maxWidth="full" mt={2} color="gray.500" fontSize={14}>
        {preference.description}
      </Text>
    </Flex>
  );
};
