import { isValid as isDateValid } from 'date-fns';
import { ActivityType } from 'ui/types/activities';
import { Coordinates } from 'ui/types/interfaces';
import {
  ActivitySummary,
  PrecomputedActivitySummary,
} from 'ui/types/interfaces/activity-definition';
import { calculateDistance } from 'ui/utils/location/calculate-distance';
import { getCoordinatesFromAddress } from 'ui/utils/location/getCoordinates';

function sortDescending<T>(arr: T[], getter: (item: T) => number) {
  return arr.slice().sort((a, b) => getter(b) - getter(a));
}

export function processActivitySummaries(
  activitiesSummary: ActivitySummary[],
  userPosition?: Coordinates | null,
): PrecomputedActivitySummary[] {
  if (!activitiesSummary.length) return [];

  const currentDate = new Date();
  const computedActivitySummary = [];

  for (const summary of activitiesSummary) {
    let distance = 0;
    let timeToBegin = 0;

    // Pre compute distance from user
    if (summary?.address && userPosition) {
      const activityCoordinates = getCoordinatesFromAddress(summary?.address);
      if (activityCoordinates) {
        distance = calculateDistance(activityCoordinates, userPosition);
      }
    }

    // Pre compute time(secs) to the activity begin
    if (summary?.startDate) {
      const startDate = new Date(summary?.startDate);
      if (!isDateValid(startDate)) continue;

      const startDateTimeStamp = startDate.getTime();
      timeToBegin = startDateTimeStamp - currentDate.getTime();
    }

    const computedItem = {
      ...summary,
      distance,
      timeToBegin,
      relevanceOrder: [],
    };

    computedActivitySummary.push(computedItem);
  }

  return computedActivitySummary;
}

export function getActivitySummaryByScore(
  activitySummaries: ActivitySummary[],
  type: ActivityType,
  userPosition?: Coordinates | null,
): PrecomputedActivitySummary | undefined {
  if (!activitySummaries.length) return;

  if (type === ActivityType.Action)
    return activitySummaries[0] as PrecomputedActivitySummary;

  const computedSummaries = processActivitySummaries(
    activitySummaries,
    userPosition,
  );

  const sortedActivities = sortDescending(computedSummaries, (summary) =>
    summary.score === undefined ? 0 : summary.score || 100,
  );

  return sortedActivities[0];
}
