import { HStack, Text, VStack } from 'native-base';
import { AppTile } from 'ui/components/v2/Tiles/AppTile';
import { EmptyAppTile } from 'ui/components/v2/Tiles/AppTile/EmptyAppTile';
import { colors } from 'ui/theme/colors';
import { App } from 'ui/types/interfaces/app';

export type YourAppsListSnippetProps = {
  apps: App[];
  max?: number;
  onAppPress?: (app: App) => void;
};

export const YourAppsListSnippet = ({
  apps,
  max = 4,
  onAppPress,
}: YourAppsListSnippetProps) => {
  const appsCount = apps.length;

  const hiddenAppsCount = appsCount > max ? appsCount - max + 1 : 0;
  const limit = max - (hiddenAppsCount > 0 ? 1 : 0);

  return (
    <VStack w="full" flex={1} h="full" justifyContent={'center'} space={1}>
      {apps?.length ? (
        <VStack space={4}>
          <HStack
            flexWrap={'wrap'}
            overflow={'hidden'}
            justifyContent={'space-evenly'}
          >
            {apps.map((app, i) => {
              if (i < limit)
                return (
                  <VStack key={app._id} w={`calc(100% / ${max})`}>
                    <AppTile
                      name={app?.name || ''}
                      logoUrl={app?.logo || ''}
                      onPress={() => onAppPress?.(app)}
                      showName={false}
                      height={'40px'}
                      width={'40px'}
                    />
                  </VStack>
                );
            })}

            {hiddenAppsCount > 0 && (
              <VStack w={`calc(100% / ${max})`}>
                <EmptyAppTile onPress={() => null}>
                  <Text
                    fontSize={'md'}
                    color={colors.gray[500]}
                    fontWeight={400}
                  >
                    +{hiddenAppsCount}
                  </Text>
                </EmptyAppTile>
              </VStack>
            )}
          </HStack>
        </VStack>
      ) : null}
    </VStack>
  );
};
