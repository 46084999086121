import { useMemo, useRef } from 'react';
import { IModalRefProps } from 'ui/components/Modals/Modal/types';

import { MobileAppDrawerProps } from '~/components/MobileAppDrawer/types';
import { MenuItem } from '~/components/Sidebar/sidebar.types';
import { PAGES } from '~/constants/pages.constants';
import { useAppSelector } from '~/hooks/useAppSelector';
import { useRouter } from '~/hooks/useRouter';
import { getNameInitials } from '~/utils/getInitials';

export const useMobileAppDrawerController = (props: MobileAppDrawerProps) => {
  const { isOpen, onToggle } = props;

  // -- Providers
  const { goToRoute } = useRouter();

  const { user, selectedUserProfile } = useAppSelector((state) => state.auth);
  const { selectedEcosystem, guestEcosystem } = useAppSelector(
    ({ ecosystem }) => ecosystem,
  );

  // -- States
  const modalRef = useRef<IModalRefProps>(null);

  // -- Hooks
  const userParsedData = useMemo(() => {
    return {
      userName: user?.name || '',
      userInitials: getNameInitials(user?.name || ''),
      userAvatar: selectedUserProfile?.profilePicture || '',
      ecosystemLogo: selectedEcosystem?.logo || guestEcosystem.logo,
      ecosystemName: selectedEcosystem?.name || guestEcosystem.name,
      ecosystemInitial: getNameInitials(
        selectedEcosystem?.name || guestEcosystem.name,
      ),
      hasUser: user ? true : false,
    };
  }, [user, selectedUserProfile, selectedEcosystem, guestEcosystem]);

  // -- Handlers
  const handleNavigate = (pagePath: string) => {
    goToRoute(pagePath);
    if (isOpen) onToggle();
  };

  const handleCloseSignOutModal = () => {
    modalRef.current?.close();
  };

  const handleOpenSignOutModal = () => {
    modalRef.current?.open();
  };

  const handleMenuItemPress = (item: MenuItem) => {
    const { pagePath, text, action } = item;
    if (!pagePath) return;

    if (action === 'external') {
      window.open(pagePath, '__blank');
      return;
    } else if (text === 'Sign out') {
      handleOpenSignOutModal();
      return;
    }
    handleNavigate(pagePath);
  };

  const onSwitchProfilePress = () => {
    goToRoute(PAGES.SwitchPersona);
  };

  return {
    modalRef,
    userParsedData,
    onSwitchProfilePress,
    handleMenuItemPress,
    handleCloseSignOutModal,
  };
};
