import { Hidden, VStack } from 'native-base';

import { ActivityMobileFooterProps } from '~/pages/Authenticated/v2/ActivityView/components/ActivityMobileFooter/types';

export const ActivityMobileFooter = ({
  children,
}: ActivityMobileFooterProps) => {
  return (
    <Hidden from={'md'}>
      <VStack
        borderTopWidth={1}
        borderTopColor={'gray.300'}
        position={'fixed'}
        bottom={0}
        bg={'singletons.white'}
        w={'full'}
        p={4}
        space={3}
      >
        {children}
      </VStack>
    </Hidden>
  );
};

export default ActivityMobileFooter;
