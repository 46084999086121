import { useEffect, useState } from 'react';
import { calculateUserProfileProgress } from 'ui/utils/userProfile/calculate-user-profile-progress';

import { useVolunteerOrganization } from '~/hooks/organization/useVolunteerOrganization';
import { useVolunteerUser } from '~/hooks/user/useVolunteerUser';

export const useUserProfileProgress = () => {
  // Providers
  const { organization, isLoading: isOrganizationLoading } =
    useVolunteerOrganization();

  const { userProfile, user } = useVolunteerUser();

  // States
  const [progressValue, setProgressValue] = useState(0);
  const [isCommutating, setIsCommutating] = useState(true);

  useEffect(() => {
    if (!userProfile || !user || !organization) return;

    const _progressValue = calculateUserProfileProgress(
      user,
      userProfile,
      organization,
    );

    setProgressValue(_progressValue);
    setIsCommutating(false);
  }, [userProfile, user, organization]);

  const isLoading = isCommutating || isOrganizationLoading;
  return {
    isLoading,
    progressValue,
  };
};
