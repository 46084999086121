import {
  Box,
  HStack,
  PresenceTransition,
  Pressable,
  Progress,
  Stack,
  Text,
} from 'native-base';
import Icon, { IconEnum } from 'ui/components/Icon';

import { LogProgressCardContentSteps } from '~/compound-components/log-progress-and-history/log-progress-and-history-content/log-progress-and-history-content';
import { useCompletionMeasurementsCardController } from '~/pages/Authenticated/v2/ActivityView/views/ActivityLog/components/ActionApplicationCard/CompletionMeasurements/controller';
import { CompletionMeasurementsCardProps } from '~/pages/Authenticated/v2/ActivityView/views/ActivityLog/components/ActionApplicationCard/CompletionMeasurements/types';

export const CompletionMeasurementsCard: React.FC<
  CompletionMeasurementsCardProps
> = (props) => {
  const { progress, steps, expandSteps, toggleSteps } =
    useCompletionMeasurementsCardController(props);

  return (
    <Box w={'full'} borderRadius={8} bg={'gray.100'} px={4} pt={2} pb={4}>
      <HStack justifyContent="space-between" py={2}>
        <HStack alignItems="center">
          <Text fontSize={'xs'} color={'gray.600'} fontWeight={500}>
            {`${progress.count} Steps`}
          </Text>
        </HStack>

        <HStack alignItems="center" space={{ base: 0, lg: 4 }}>
          <HStack space={1} alignItems="center">
            <Icon
              isCustom
              icon={IconEnum.TARGET_AND_ARROW}
              size={4}
              color={'gray.600'}
            />
            <Text fontSize={'xs'} color={'gray.600'} fontWeight={500}>
              {`${progress.total} Steps`}
            </Text>
          </HStack>

          <Pressable onPress={toggleSteps}>
            <Box>
              <Icon
                isCustom
                size={6}
                icon={IconEnum.CARET_DOWN}
                color={'black'}
              />
            </Box>
          </Pressable>
        </HStack>
      </HStack>
      <HStack space={2} width={'100%'}>
        {steps?.map((step) => {
          return (
            <Stack w={`1/${steps.length}`} key={step.title}>
              <Progress
                value={!!step.completedAt ? 100 : 0}
                max={100}
                bg={'gray.300'}
                h={1}
                _filledTrack={{ bg: 'black' }}
              />
            </Stack>
          );
        })}
      </HStack>
      {expandSteps && (
        <PresenceTransition
          visible
          initial={{ opacity: 0, translateY: 0.75 }}
          animate={{
            opacity: 1,
            translateY: 1,
            transition: { duration: 100 },
          }}
        >
          <Box pt={2}>
            {!!steps.length && <LogProgressCardContentSteps items={steps} />}
          </Box>
        </PresenceTransition>
      )}
    </Box>
  );
};

export default CompletionMeasurementsCard;
