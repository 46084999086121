import posthog from 'posthog-js';

const posthogConfig = {
  apiKey: import.meta.env.VITE_POSTHOG_API_KEY,
  host: import.meta.env.VITE_POSTHOG_HOST,
};

export const initializePostHog = () => {
  return posthog.init(posthogConfig.apiKey, {
    api_host: posthogConfig.host,
    person_profiles: 'identified_only',
  });
};

export const analytics = posthog;
