import { Box, HStack, Text, VStack } from 'native-base';
import React from 'react';

import { CardRecommendationsRequirementSectionProps } from '~/components/ActivityCards/CardRecommendations/components/CardRecommendationsRequirementSection/cardRecommendationsRequerimentSection.types';

export const CardRecommendationsRequirementSection = ({
  requirementOptions,
}: CardRecommendationsRequirementSectionProps) => {
  return (
    <HStack display={{ base: 'none', md: 'flex' }}>
      <VStack mt={requirementOptions?.length > 0 ? 3 : 0}>
        {requirementOptions?.length > 0 && (
          <Text color="gray.600" fontSize="sm">
            You will need
          </Text>
        )}
        <HStack
          w="100%"
          alignSelf="center"
          flexWrap="wrap"
          alignItems="center"
          space="3"
        >
          {requirementOptions?.map((requirement) => (
            <Box
              mt="2"
              px="2"
              py="1"
              bg="info.100"
              borderRadius="full"
              key={requirement._id}
            >
              <Text fontSize="sm" color="info.800">
                {requirement?.displayName?.toUpperCase()}
              </Text>
            </Box>
          ))}
        </HStack>
      </VStack>
    </HStack>
  );
};
