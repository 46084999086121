import './config/sentry';
import './config/amplify';
import './config/firebase';
import './App.css';

import { AlertsContainer } from '~/components/v2/Alerts';
import DebugButton from '~/components/v2/Buttons/DebugButton';
import Providers from '~/Providers';
import { Routes } from '~/routes';
import useDebugToggle from '~/utils/debug/use-debug-mode';

const App = () => {
  const { show: showDebugButton } = useDebugToggle();

  return (
    <Providers>
      <AlertsContainer />
      <Routes />
      {showDebugButton ? <DebugButton /> : null}
    </Providers>
  );
};

export default App;
