import { ActivityType } from 'ui/types/activities';

import { IUseSearchController } from '~/pages/Authenticated/InApp/Explorer/controllers/interface';
import {
  ActivitySearchSection,
  SortByAttendanceTypeMap,
} from '~/pages/Authenticated/InApp/Explorer/types';
import { AttendanceType } from '~/types/interfaces/activity';

export type IOption = { label: string; value: string };

export enum SortByType {
  POPULAR = 'Popular',
  NEAREST = 'Nearest',
  UPCOMING = 'Upcoming',
  NEWEST = 'Newest',
  CLOSEST = 'Closest',
}

export type SortByOption = {
  value: SortByType;
  label: string;
  details: string;
};

export type MiniAppSearchViewProps = {
  sectionType: ActivitySearchSection;
  activityType: ActivityType;
  causeOptions: IOption[];
  filterWhiteList: FilterWhiteList;
  sortByOptions: SortByAttendanceTypeMap;
  searchController: IUseSearchController;
  onSearchSectionChange: (searchSection: ActivitySearchSection) => void;
};

// -- Modal states
export type ModalsState = {
  sort: boolean;
  location: boolean;
  search: boolean;
  filter: boolean;
  type: boolean;
  causes: boolean;
};

export type ModalsStateActionName = '$CLS' | keyof ModalsState;

// -- Filter whitelist

export type WhiteListOption =
  | boolean
  | { [AttendanceType.Local]?: boolean; [AttendanceType.Online]?: boolean };

export type FilterWhiteList = {
  sortBy: WhiteListOption;
  dateRange: WhiteListOption;
  distanceRadius: WhiteListOption;
  teamSize: WhiteListOption;
  withinArea: WhiteListOption;
  showOnline: WhiteListOption;
};
