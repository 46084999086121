import AppleStore from '../../assets/icons/apple.svg';
import GooglePlay from '../../assets/icons/google-play.svg';
import AccountsSecurityComputerSecure from '../../assets/illustrations/AccountsSecurityComputerSecure.png';
import Alert from '../../assets/illustrations/Alert.svg';
import BallonBear from '../../assets/illustrations/BallonBear.svg';
import BigHelpOutHeaderLogo from '../../assets/illustrations/BigHelpOutHeaderLogo.svg';
import BlackDoitLogo from '../../assets/illustrations/BlackDoitLogo.svg';
import BusinessTaskManager from '../../assets/illustrations/BusinessTaskManager.png';
import ClockImage from '../../assets/illustrations/Clock.svg';
import ComingSoonSiteUpdate from '../../assets/illustrations/ComingSoonSiteUpdate.svg';
import ConfirmationGreat from '../../assets/illustrations/ConfirmationGreat.svg';
import DataManagement from '../../assets/illustrations/DataManagement.svg';
import DoitSomethingWentWrong from '../../assets/illustrations/DoitSomethingWentWrong.svg';
import DoitSuccess from '../../assets/illustrations/DoitSuccess.svg';
import DoitTransparentLogo from '../../assets/illustrations/DoitTransparentLogo.svg';
import DoitVolunteerSignUpName from '../../assets/illustrations/DoitVolunteerSignUpName.svg';
import Dots from '../../assets/illustrations/Dots.svg';
import EmptyNotFound from '../../assets/illustrations/EmptyNotFound.svg';
import ErrorSorryStateBroken from '../../assets/illustrations/ErrorSorryStateBroken.svg';
import ErrorWebDevelopment from '../../assets/illustrations/ErrorWebDevelopment.png';
import InvitationDeclined from '../../assets/illustrations/InvitationDeclined.svg';
import InviteMember from '../../assets/illustrations/InviteMember.svg';
import Loading from '../../assets/illustrations/Loading.svg';
import LoadingWaiting from '../../assets/illustrations/LoadingWaiting.svg';
import LogActivityStart from '../../assets/illustrations/LogActivityStart.svg';
import LogActivityStep1 from '../../assets/illustrations/LogActivityStep1.svg';
import LogActivityStep2 from '../../assets/illustrations/LogActivityStep2.svg';
import LogActivityStep3 from '../../assets/illustrations/LogActivityStep3.svg';
import LoginPassword from '../../assets/illustrations/LoginPassword.svg';
import Mailer from '../../assets/illustrations/Mailer.svg';
import ManSittingWithBearAndBalloons from '../../assets/illustrations/ManSittingWithBearAndBalloons.svg';
import MapMarker from '../../assets/illustrations/MapMarker.svg';
import NearBy from '../../assets/illustrations/NearBy.svg';
import NewMessageEmail from '../../assets/illustrations/NewMessageEmail.svg';
import NewMessageEmailCorporate from '../../assets/illustrations/NewMessageEmailCorporate.svg';
import NewMessageEmailGreen from '../../assets/illustrations/NewMessageEmailGreen.svg';
import NoNotificationsNoMessage from '../../assets/illustrations/NoNotificationsNoMessage.svg';
import NoSignalConnectionError from '../../assets/illustrations/NoSignalConnectionError.svg';
import NoSignalConnectionErrorBlack from '../../assets/illustrations/NoSignalConnectionErrorBlack.svg';
import Notification from '../../assets/illustrations/Notification.svg';
import PaperPlane from '../../assets/illustrations/PaperPlane.svg';
import Parachute from '../../assets/illustrations/Parachute.svg';
import PaymentFaildPayError from '../../assets/illustrations/PaymentFaildPayError.svg';
import PointerUp from '../../assets/illustrations/PointerUp.svg';
import ProblemNoConnection from '../../assets/illustrations/ProblemNoConnection.svg';
import ProblemNoConnectionGreen from '../../assets/illustrations/ProblemNoConnectionGreen.svg';
import SearchNotFound from '../../assets/illustrations/SearchNotFound.svg';
import SearchNotFoundVolunteer from '../../assets/illustrations/SearchNotFoundVolunteer.svg';
import SomethingWentWrong from '../../assets/illustrations/SomethingWentWrong.svg';
import SuccessComplete from '../../assets/illustrations/SuccessComplete.svg';
import SuccessCompleteCorporate from '../../assets/illustrations/SuccessCompleteCorporate.svg';
import SuccessCompleteGreen from '../../assets/illustrations/SuccessCompleteGreen.svg';
import SuccessCompleteWeb from '../../assets/illustrations/SuccessCompleteWeb.svg';
import TaskManagementList from '../../assets/illustrations/TaskManagementList.svg';
import TechnicalErrorBreakdown from '../../assets/illustrations/TechnicalErrorBreakdown.svg';
import TheBigHelpOut from '../../assets/illustrations/TheBigHelpOut.svg';
import ThumbsUp from '../../assets/illustrations/ThumbsUp.svg';
import UpdateConstructionSettings from '../../assets/illustrations/UpdateConstructionSettings.svg';
import UpdateConstructionSettingsGreen from '../../assets/illustrations/UpdateConstructionSettingsGreen.svg';
import WelldoneOk from '../../assets/illustrations/WelldoneOk.svg';
import InAppCoverImage from '../../assets/images/in-app-cover-image-fullhd.webp';
import LoginBackground from '../../assets/images/login-background.jpg';
import BHOPartnerASDALogo from '../../assets/svgs/bho_partner_asda_logo.svg';
import BHOPartnerCTMLogo from '../../assets/svgs/bho_partner_ctm_logo.svg';
import BHOPartnerDPDLogo from '../../assets/svgs/bho_partner_dpd_logo.svg';
import { ILLUSTRATIONS_NAME } from './types';

export const ilustrationsConstans = {
  [ILLUSTRATIONS_NAME.SEARCH_NOT_FOUND]: SearchNotFound,
  [ILLUSTRATIONS_NAME.PROBLEM_NO_CONNECTION]: ProblemNoConnection,
  [ILLUSTRATIONS_NAME.PROBLEM_NO_CONNECTION_GREEN]: ProblemNoConnectionGreen,
  [ILLUSTRATIONS_NAME.UPDATE_CONSTRUCTION_SETTINGS]: UpdateConstructionSettings,
  [ILLUSTRATIONS_NAME.UPDATE_CONSTRUCTION_SETTINGS_GREEN]:
    UpdateConstructionSettingsGreen,
  [ILLUSTRATIONS_NAME.COMING_SOON_SITE_UPDATE]: ComingSoonSiteUpdate,
  [ILLUSTRATIONS_NAME.LOGIN_PASSWORD]: LoginPassword,
  [ILLUSTRATIONS_NAME.NO_SIGNAL_CONNECTION_ERROR]: NoSignalConnectionError,
  [ILLUSTRATIONS_NAME.NO_SIGNAL_CONNECTION_ERROR_BLACK]:
    NoSignalConnectionErrorBlack,
  [ILLUSTRATIONS_NAME.NO_NOTIFICATIONS_NO_MESSAGE]: NoNotificationsNoMessage,
  [ILLUSTRATIONS_NAME.TECHINICAL_ERROR]: TechnicalErrorBreakdown,
  [ILLUSTRATIONS_NAME.NEW_MESSAGE]: NewMessageEmail,
  [ILLUSTRATIONS_NAME.NEW_MESSAGE_GREEN]: NewMessageEmailGreen,
  [ILLUSTRATIONS_NAME.LOADING_WAITING]: LoadingWaiting,
  [ILLUSTRATIONS_NAME.PAYMENT_FAILD_PAY_ERROR]: PaymentFaildPayError,
  [ILLUSTRATIONS_NAME.SUCCESS_COMPLETE]: SuccessComplete,
  [ILLUSTRATIONS_NAME.SUCCESS_COMPLETE_GREEN]: SuccessCompleteGreen,
  [ILLUSTRATIONS_NAME.WELLDONE_OK]: WelldoneOk,
  [ILLUSTRATIONS_NAME.MAP_MARKER]: MapMarker,
  [ILLUSTRATIONS_NAME.EMPTY_NOT_FOUND]: EmptyNotFound,
  [ILLUSTRATIONS_NAME.SOMETHING_WENT_WRONG]: SomethingWentWrong,
  [ILLUSTRATIONS_NAME.LOADING]: Loading,
  [ILLUSTRATIONS_NAME.NOTIFICATION]: Notification,
  [ILLUSTRATIONS_NAME.THE_BIG_HELP_OUT]: TheBigHelpOut,
  [ILLUSTRATIONS_NAME.BALLON_BEAR]: BallonBear,
  [ILLUSTRATIONS_NAME.SUCCESS_COMPLETE_WEB]: SuccessCompleteWeb,
  [ILLUSTRATIONS_NAME.BIG_HELP_OUT_HEADER_LOGO]: BigHelpOutHeaderLogo,
  [ILLUSTRATIONS_NAME.APPLE_STORE]: AppleStore,
  [ILLUSTRATIONS_NAME.GOOGLE_PLAY]: GooglePlay,
  [ILLUSTRATIONS_NAME.SEARCH_NOT_FOUND_VOLUNTEER]: SearchNotFoundVolunteer,
  [ILLUSTRATIONS_NAME.NEW_EMAIL_MESSAGE]: NewMessageEmailCorporate,
  [ILLUSTRATIONS_NAME.SUCCESS_COMPLETE_CORPORATE]: SuccessCompleteCorporate,
  [ILLUSTRATIONS_NAME.DOIT_VOLUNTEER_SIGNUP_NAME]: DoitVolunteerSignUpName,
  [ILLUSTRATIONS_NAME.DOIT_SOMETHING_WENT_WRONG]: DoitSomethingWentWrong,
  [ILLUSTRATIONS_NAME.DOIT_SUCCESS]: DoitSuccess,
  [ILLUSTRATIONS_NAME.INVITATION_DECLINED]: InvitationDeclined,
  [ILLUSTRATIONS_NAME.MAN_SITTING_WITH_BEAR_AND_BALLOONS]:
    ManSittingWithBearAndBalloons,
  [ILLUSTRATIONS_NAME.LOG_ACTIVITY_START]: LogActivityStart,
  [ILLUSTRATIONS_NAME.LOG_ACTIVITY_STEP_1]: LogActivityStep1,
  [ILLUSTRATIONS_NAME.LOG_ACTIVITY_STEP_2]: LogActivityStep2,
  [ILLUSTRATIONS_NAME.LOG_ACTIVITY_STEP_3]: LogActivityStep3,
  [ILLUSTRATIONS_NAME.DOTS]: Dots,
  [ILLUSTRATIONS_NAME.NEW_EMAIL_COORP]: NewMessageEmailCorporate,
  [ILLUSTRATIONS_NAME.BLACK_DOIT_LOGO]: BlackDoitLogo,
  [ILLUSTRATIONS_NAME.DOIT_TRANSPARENT_LOGO]: DoitTransparentLogo,
  [ILLUSTRATIONS_NAME.ALERT]: Alert,
  [ILLUSTRATIONS_NAME.ERROR_SORRY_STATE_BROKEN]: ErrorSorryStateBroken,
  [ILLUSTRATIONS_NAME.INVITE_MEMBER]: InviteMember,
  [ILLUSTRATIONS_NAME.THUMBS_UP]: ThumbsUp,
  [ILLUSTRATIONS_NAME.MAILER]: Mailer,
  [ILLUSTRATIONS_NAME.PARACHUTE]: Parachute,
  [ILLUSTRATIONS_NAME.POINTER_UP]: PointerUp,
  [ILLUSTRATIONS_NAME.CONFIRMATION_GREAT]: ConfirmationGreat,
  [ILLUSTRATIONS_NAME.DATA_MANAGEMENT]: DataManagement,
  [ILLUSTRATIONS_NAME.ACCOUNTS_SECURITY]: AccountsSecurityComputerSecure,
  [ILLUSTRATIONS_NAME.ERROR_WEB_DEVELOPMENT]: ErrorWebDevelopment,
  [ILLUSTRATIONS_NAME.BUSINESS_TASK_MANAGER]: BusinessTaskManager,
  [ILLUSTRATIONS_NAME.LOGIN_BACKGROUND]: LoginBackground,
  [ILLUSTRATIONS_NAME.NEAR_BY]: NearBy,
  [ILLUSTRATIONS_NAME.IN_APP_COVER_IMAGE]: InAppCoverImage,
  [ILLUSTRATIONS_NAME.BHO_PARTNER_ASDA_LOGO]: BHOPartnerASDALogo,
  [ILLUSTRATIONS_NAME.BHO_PARTNER_CTM_LOGO]: BHOPartnerCTMLogo,
  [ILLUSTRATIONS_NAME.BHO_PARTNER_DPD_LOGO]: BHOPartnerDPDLogo,
  [ILLUSTRATIONS_NAME.CLOCK]: ClockImage,
  [ILLUSTRATIONS_NAME.TASK_MANAGEMENT_LIST]: TaskManagementList,
  [ILLUSTRATIONS_NAME.PAPER_PLANE]: PaperPlane,
};
