import { PermissionsWrapper } from 'ui/components/PermissionsWrapper';
import { ValidationTypeEnum } from 'ui/components/PermissionsWrapper/PermissionsBoolean/types';

import { ValidateAppPermissionsProps } from './types';
import { useValidateAppPermissionsController } from './useValidateAppPermissionsController';

export const ValidateAppPermissions = ({
  appIdToValidate,
  requiredPermissions,
  disablePermissionsValidation,
  children,
  fallbackComponent,
}: ValidateAppPermissionsProps) => {
  const { availableUserPermissionsForTheAppActivity } =
    useValidateAppPermissionsController({ appIdToValidate });

  return (
    <PermissionsWrapper.Boolean
      validationType={ValidationTypeEnum.SpecificApp}
      bypassPermissions={disablePermissionsValidation}
      availablePermissions={availableUserPermissionsForTheAppActivity}
      requiredPermissions={requiredPermissions}
      fallbackComponent={fallbackComponent}
    >
      {children}
    </PermissionsWrapper.Boolean>
  );
};
