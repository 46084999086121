import { Avatar, Stack, Text } from 'native-base';
import React from 'react';

import { CardRecommendationsAvatarProps } from '~/components/ActivityCards/CardRecommendations/components/CardRecommendationsAvatar/cardRecommendationsAvatar.types';

export const CardRecommendationsAvatar = ({
  logo,
  initials,
}: CardRecommendationsAvatarProps) => {
  return (
    <Stack minW={12} pr={2} display={{ base: 'none', lg: 'flex' }}>
      <Avatar
        bg="gray.400"
        borderColor={'gray.300'}
        borderWidth={1}
        source={{ uri: logo }}
      >
        <Text color={'singletons.white'} fontSize={'xs'}>
          {initials}
        </Text>
      </Avatar>
    </Stack>
  );
};
