import 'react-phone-number-input/style.css';

import FeatherIcon from 'feather-icons-react';
import { Box, FormControl, HStack, Input as NBInput, Link } from 'native-base';
import { forwardRef, MutableRefObject, Ref } from 'react';
import { Controller } from 'react-hook-form';
import ReactPhoneNumberInput from 'react-phone-number-input';

import { colors } from '../../theme/colors';
import Icon from '../Icon';
import PasswordRulesList from './PasswordRulesList';
import { styles } from './styles';
import { ICustomInputProps } from './types';
import { useInputController } from './useInputController';

const Input = (
  { isFullWidth, isPhoneInput, ...rest }: ICustomInputProps,
  ref: Ref<HTMLInputElement>,
) => {
  const {
    leftIconName,
    leftIconColor,
    leftIconSize,
    _formControl,
    rightIconName,
    control,
    name,
    leftIconErrorSize,
    rightIconColor,
    helperText,
    rightIconSize,
    currentType,
    isNumeric,
    hasError,
    isInvalid,
    defaultCountry,
    isDisabled,
    isReadOnly,
    isRequired,
    errorMessage,
    iconErrorMessage,
    isPasswordField,
    isShowPasswordToggle,
    toggleShowPasswordType,
    showPasswordRules,
    label,
    restProps,
    helpIcon,
    helpIconColor = colors.primary[400],
    handleOpenHelpIcon,
    labelColor,
    onChangeTransform,
  } = useInputController(rest);

  return (
    <Box alignItems="center" w={isFullWidth ? 'full' : undefined}>
      <FormControl
        isInvalid={hasError || errorMessage ? true : isInvalid}
        isDisabled={isDisabled}
        isRequired={isRequired}
        isReadOnly={isReadOnly}
        {..._formControl}
      >
        <HStack space={2} alignItems="center">
          {label ? (
            <FormControl.Label _text={{ color: labelColor }}>
              {label}
            </FormControl.Label>
          ) : null}
          {helpIcon && handleOpenHelpIcon ? (
            <div onClick={handleOpenHelpIcon} style={styles.divIcon}>
              <Icon color={helpIconColor} size="16" icon="help-circle" />
            </div>
          ) : null}
        </HStack>
        <Controller
          control={control}
          name={name}
          defaultValue=""
          render={({ field: { onChange, value, ...restField } }) => {
            // @mycatdoitbetter // TODO: Move this components to a separate file.
            // or just outside this main component.
            if (isPhoneInput) {
              return (
                <ReactPhoneNumberInput
                  {...restField}
                  value={value}
                  defaultCountry={defaultCountry}
                  onChange={onChange}
                  placeholder={restProps.placeholder}
                  // @mycatdoitbetter // TODO: Handle this inline props properly.
                  style={{
                    marginLeft: 4,
                  }}
                  numberInputProps={{
                    style: {
                      padding: 16,
                      fontSize: 12,
                      fontFamily: 'Poppins',
                      borderRadius: '12px',
                      border: `solid 1px ${
                        hasError || errorMessage
                          ? colors.error[400]
                          : colors.muted[300]
                      }`,
                    },
                  }}
                />
              );
            }

            return (
              <>
                <NBInput
                  {...restProps}
                  {...restField}
                  ref={ref as MutableRefObject<HTMLInputElement>}
                  value={value}
                  // Native base uses a different typing that is used in web (HTMLInputElement) and ends up giving a typo.
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  onChange={(newValue: any) => {
                    if (onChangeTransform) {
                      const changedValues = onChangeTransform(
                        newValue.target.value,
                      );
                      onChange(changedValues);
                      return;
                    }
                    if (isNumeric && newValue?.target?.value) {
                      const _newValue =
                        Number(newValue.target.value || 0) || value;
                      onChange(_newValue);
                      return;
                    }
                    onChange(newValue);
                  }}
                  type={currentType}
                  borderColor={
                    hasError || errorMessage ? 'error.400' : undefined
                  }
                  backgroundColor="white"
                  InputRightElement={
                    isPasswordField && isShowPasswordToggle ? (
                      <Link
                        mr={2}
                        variant="unstyled"
                        onPress={toggleShowPasswordType}
                      >
                        <FeatherIcon
                          icon={currentType === 'password' ? 'eye' : 'eye-off'}
                          size={rightIconSize}
                          color={
                            hasError || errorMessage
                              ? colors.error[700]
                              : rightIconColor
                          }
                        />
                      </Link>
                    ) : rightIconName ? (
                      <Box mr={2}>
                        <FeatherIcon
                          icon={rightIconName}
                          size={rightIconSize}
                          color={
                            hasError || errorMessage
                              ? colors.error[700]
                              : rightIconColor
                          }
                        />
                      </Box>
                    ) : undefined
                  }
                  InputLeftElement={
                    leftIconName ? (
                      <Box ml={2} variant="unstyled">
                        <FeatherIcon
                          icon={leftIconName}
                          size={leftIconSize}
                          color={
                            hasError || errorMessage
                              ? colors.error[700]
                              : leftIconColor
                          }
                        />
                      </Box>
                    ) : undefined
                  }
                />
                {isPasswordField && showPasswordRules ? (
                  <PasswordRulesList value={value} />
                ) : null}
              </>
            );
          }}
        />
        {!hasError && !errorMessage && helperText ? (
          <FormControl.HelperText ml={2}>{helperText}</FormControl.HelperText>
        ) : null}
        {errorMessage ? (
          <FormControl.ErrorMessage
            ml={2}
            leftIcon={
              iconErrorMessage ? (
                <FeatherIcon
                  icon={iconErrorMessage}
                  size={leftIconErrorSize}
                  color={colors.error[400]}
                />
              ) : undefined
            }
          >
            {errorMessage}
          </FormControl.ErrorMessage>
        ) : null}
      </FormControl>
    </Box>
  );
};

export default forwardRef(Input);
