import { useMemo } from 'react';

import { BreadCrumbsProps } from '~/components/Breadcrumbs/types';
import { useRouter } from '~/hooks/useRouter';

export const useBreadCrumbsController = (props: BreadCrumbsProps) => {
  const { breadcrumbs, showBackButton = false } = props;
  const { goBack, goToRoute, isStackEmpty } = useRouter();

  const handlePress = (value: string) => {
    if (!value) return;

    if (value === '-1') {
      goBack();
      return;
    }
    goToRoute(value);
  };

  const breadCrumbsStack = useMemo(() => {
    const breadcrumbsResult = [...breadcrumbs];
    return breadcrumbsResult;
  }, [breadcrumbs]);

  return { handlePress, breadCrumbsStack, showBackButton, isStackEmpty };
};
