import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { InviteCodeVisibilityEnum } from 'ui/enums';

import { DEFAULT_APP_ID, DOIT_APP_ID } from '~/config';
import { PAGES } from '~/constants/pages.constants';
import { useAppSelector } from '~/hooks/useAppSelector';
import { useAuth } from '~/hooks/useAuth';
import { useLoadAppsByIds } from '~/hooks/useLoadAppsByIds';
import { useLoadUsedMemberInvite } from '~/hooks/useLoadUsedMemberInvite';
import { useQuery } from '~/hooks/useQuery';
import { useRouter } from '~/hooks/useRouter';
import InviteMembersService from '~/services/resources/inviteMembers';
import { ecosystemSliceActions } from '~/store/slices/ecosystem';
import { redirectSliceActions } from '~/store/slices/redirect';
import { InviteInformation } from '~/types/interfaces/inviteMembers';

export const useAcceptInviteController = () => {
  const {
    goToRoute,
    replaceRoute,
    params: { code },
    location,
  } = useRouter();

  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [isTermsAndConditionsAccepted, setIsTermsAndConditionsAccepted] =
    useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const dispatch = useDispatch();

  const {
    isAuthenticated,
    user,
    isLoading: isLoadingUser,
  } = useAppSelector(({ auth }) => auth);

  const { handleGetUserAfterAcceptInvite } = useAuth();

  const { data: invitationInfo, isLoading } = useQuery<InviteInformation>(
    `/invite-members/code/${code}`,
    {
      method: 'GET',
      key: `/invite-members/code/${code}`,
      queryOptions: {
        enabled: !!code && !!isAuthenticated,
      },
      requestOptions: {
        params: {
          populate: JSON.stringify(['sharerOrganization']),
        },
      },
    },
  );

  const { data: usedInviteCode, isLoading: isLoadingUsedInviteCode } =
    useLoadUsedMemberInvite(invitationInfo?.code, user?.email);

  const appsFromInvite = useMemo(
    () =>
      invitationInfo?.configurations[0].appsData.map(
        (appData) => appData.appId,
      ),
    [invitationInfo],
  );

  const { apps, isLoadingApps } = useLoadAppsByIds(appsFromInvite || []);

  const handleAcceptInvitation = async () => {
    if (isAuthenticated && invitationInfo) {
      setIsLoadingSubmit(true);
      await InviteMembersService.acceptInvitation(invitationInfo?.code);
      dispatch(ecosystemSliceActions.resetEcosystem());
      dispatch(redirectSliceActions.removeAcceptInviteRedirect());
      setIsLoadingSubmit(false);

      const ecosystemId = invitationInfo?.configurations[0]?.ecosystemId?._id;
      const appId = getAppId(invitationInfo);
      await handleGetUserAfterAcceptInvite(appId, ecosystemId);
    }
  };

  const getAppId = (invite?: InviteInformation) => {
    if (!invite) return;
    const appsMap =
      invite?.configurations?.flatMap((c) =>
        c.appsData.flatMap((app) => app.appId),
      ) || [];

    const bhoAppId = appsMap.find((app) => app === DEFAULT_APP_ID);
    const doitAppId = appsMap.find((app) => app === DOIT_APP_ID);
    let appId = '';
    if (appsMap.length === 1 && bhoAppId) {
      appId = bhoAppId;
    } else if (appsMap.length === 2 && bhoAppId && doitAppId) {
      appId = bhoAppId;
    }

    return appId;
  };

  const handleDeclineInvitation = () => goToRoute(PAGES.InvitationDeclined);

  const handleRedirectToAuthflow = () => {
    const inviteURL = `${location.pathname}`;
    dispatch(
      redirectSliceActions.addAcceptInviteRedirect({
        hasInvite: true,
        code: code,
        inviteURL,
      }),
    );
    replaceRoute(PAGES.Root);
    goToRoute(`${inviteURL}/signup`);
  };

  const toggleIsTermsAndConditionsAccepted = () => {
    setIsTermsAndConditionsAccepted(
      (isTermsAndConditionsAccepted) => !isTermsAndConditionsAccepted,
    );
  };

  const isLoadingPage = isLoading || isLoadingApps || isLoadingUsedInviteCode;

  const isSubmitting = useMemo(() => {
    return isLoadingSubmit || !!isLoadingUser;
  }, [isLoadingSubmit, isLoadingUser]);

  const handleNextStep = () => {
    if (currentStep === 1) return;
    setCurrentStep((prev) => prev + 1);
  };

  useEffect(() => {
    if (invitationInfo && !isLoading) {
      if (invitationInfo.isUsed) {
        replaceRoute(PAGES.Root);
        dispatch(redirectSliceActions.removeAcceptInviteRedirect());
        toast.error('This invite has expired or has been already accepted.');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invitationInfo, isLoading]);

  useEffect(() => {
    if (!isAuthenticated) {
      handleRedirectToAuthflow();
    } else {
      if (
        isAuthenticated &&
        invitationInfo?.visibility === InviteCodeVisibilityEnum.Private &&
        user?.email !== invitationInfo?.receiverEmail &&
        !isLoading
      ) {
        toast.error(
          'Incorrect account for the ecosystem invitation. Please check your account and try the link again.',
        );

        dispatch(redirectSliceActions.removeAcceptInviteRedirect());
        replaceRoute(PAGES.Root);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, invitationInfo, user]);

  useEffect(() => {
    if (
      invitationInfo &&
      !invitationInfo.sharerOrganization.termsOfServicesLink
    ) {
      // make hidden checkbox checked to turn button active
      setIsTermsAndConditionsAccepted(true);
    }
  }, [invitationInfo]);

  useEffect(() => {
    if (usedInviteCode) {
      const appId = getAppId(usedInviteCode);
      handleGetUserAfterAcceptInvite(appId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usedInviteCode]);

  return {
    invitationInfo,
    isLoadingPage,
    apps,
    isAuthenticated,
    isLoadingSubmit,
    isTermsAndConditionsAccepted,
    currentStep,
    usedInviteCode,
    isSubmitting,
    handleNextStep,
    handleDeclineInvitation,
    handleAcceptInvitation,
    handleRedirectToAuthflow,
    toggleIsTermsAndConditionsAccepted,
  };
};
