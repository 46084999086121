export const COMMON_MESSAGES = {
  UPDATE_SUCCESS: 'Updated data',
  UNABLE_CHANGE_DATA: 'Unable to change data',
  UNABLE_SEND_REQUEST: 'Unable to send request',
  UNABLE_SEND_ACCESS_CODE: 'Unable to send access code',
  UNABLE_SAVE_DATA: 'Unable to save data',
  PASSWORD_CHANGED: 'Password changed',
  PASSWORD_RESETED: 'Password reset successfully!',
  PASSWORD_RULES:
    'Your password must contain more than 8 characters (uppercase, lowercase, numbers and special characters)',
  ERROR_CHANGING_PASSWORD: 'Error when changing password',
  ACCESS_CODE_SENT: 'Code sent!',
  SESSION_EXPIRED: 'Session expired, sign in again.',
  ACCEPT_TERMS: 'You must accept the terms to proceed.',
  UNKNOWN_ERROR: 'We had a problem please try again',
  SUCCESSFUL_LOGIN: 'successful login',
  LINK_COPIED: 'Link copied',
  EMAIL_CHANGED: 'Email changed',
  CHANGE_EMAIL_ERROR:
    'An error ocurred while trying to change email, please try again.',
  CODE_VERIFIED_WITH_SUCCESS: 'Code verified with success',
  ACCOUNT_DELETE_ERROR: 'Error while trying to delete the account.',
  ACCOUNT_VERIFIED: 'Account verified. You can now reset your password.',
  ACCOUNT_UNVERIFIED_SEND_MESSAGE:
    'Looks like your account was not verified yet. We sent you another code. Please check your email.',
};

export const SIGN_UP_MESSAGES = {
  CREATE_ACCOUNT_ERROR: 'An error occurred while trying to create the account.',
  ACCOUNT_CREATED_SUCCESSFULLY: 'Account created successfully',
  EMAIL_ALREADY_EXISTS: 'Email already exists',
  VERIFY_CODE_ERROR:
    'An error occurred while trying to verify the code, please confirm that the code is correct and try again.',
  UNABLE_TO_JOIN_ECOSYSTEM:
    'Unable to join the ecosystem, please try again later.',
};

export const ECOSYSTEM_INVITATION_MESSAGES = {
  INVITATION_ACCEPTED: 'You have been accepted to this organization',
  INVITATION_ERROR: 'An error occurred while trying to accept the invitation.',
};

export const RECOVERY_PASSWORD = {
  INVALID_CODE: 'Invalid verification code provided, please try again.',
};

export const ACTIVITIES_MESSAGES = {
  ERROR_FETCHING_ACTIVITIES: 'There was a problem fetching the activities.',
};

export const USER_MESSAGES = {
  ERROR_ON_UPLOAD: `We encountered an issue while uploading your profile picture You can try again in a few minutes or submit without one.`,
  PROFILE_UPDATED: 'Data updated successfully.',
};
