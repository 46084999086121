import { HStack, Pressable, Text } from 'native-base';
import Icon from 'ui/components/Icon';
import { SearchOptionElement } from 'ui/components/InlineSearchInput/types';
import { colors } from 'ui/theme/colors';

export const LocationSearchOption = (props: SearchOptionElement) => {
  const { data, onPress } = props;
  return (
    <Pressable onPress={() => onPress(data)} _hover={{ bg: 'gray.100' }}>
      <HStack alignItems={'center'} space={3} px={1}>
        <Icon icon={'map-pin'} size={16} color={colors.gray[400]} />
        <Text
          py={3}
          fontSize={'sm'}
          lineHeight={'150%'}
          fontWeight={500}
          color={colors.darkText}
          flex={1}
        >
          {data.label}
        </Text>
      </HStack>
    </Pressable>
  );
};

export default LocationSearchOption;
