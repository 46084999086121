import { HStack, Text } from 'native-base';

import Button from '../../../../../../Button';
import Icon from '../../../../../../Icon';
import { SelectGroupActionButtonStyleMap } from './constants';
import { SelectGroupActionButtonProps } from './types';

export const SelectGroupActionButton = ({
  variant,
  isDisabled,
  onPress,
}: SelectGroupActionButtonProps) => {
  const style = SelectGroupActionButtonStyleMap[variant];

  return (
    <Button
      width={'100px'}
      height={'100%'}
      variant="outline"
      backgroundColor={'white'}
      borderColor={style.borderColor}
      isDisabled={isDisabled}
      onPress={onPress}
    >
      <HStack
        space={'10px'}
        p={0}
        alignItems={'center'}
        justifyContent={'center'}
      >
        <Icon
          size={'16px'}
          icon={style.iconName}
          color={style.fontColor}
          enableAutoCustom
        />
        <Text fontSize={'md'} color={style.fontColor}>
          {style.actionLabel}
        </Text>
      </HStack>
    </Button>
  );
};

export default SelectGroupActionButton;
