import { HomeWidgetBase, WIDGET_COLORS } from '../Base';
import { useHomeWidgetProfileProgressController } from './controller';
import { ProfileProgressSnippets } from './Snippets';

export type HomeWidgetProfileProgressProps = {};

export const HomeWidgetProfileProgress =
  ({}: HomeWidgetProfileProgressProps) => {
    const { data, isLoading, defaultTabIndex, onCardPress } =
      useHomeWidgetProfileProgressController({});

    return (
      <HomeWidgetBase
        isLoading={isLoading}
        title="Build your profile"
        maxWidth={'312px'}
        minWidth={{ base: '240px', md: '304px' }}
        headerBackgroundColor={WIDGET_COLORS.red}
        defaultTabIndex={defaultTabIndex}
        key={defaultTabIndex}
        onPress={onCardPress}
      >
        {data.progressValue === 100 ? (
          <ProfileProgressSnippets.PlaceHolder
            onPress={onCardPress}
            text="To make sure you get matched with opportunities, keep your Profile updated."
          />
        ) : (
          <ProfileProgressSnippets.PlaceHolder
            onPress={onCardPress}
            text="Complete your profile to make sure you get matched with the opportunities available."
          />
        )}

        <ProfileProgressSnippets.Info
          progressValue={data.progressValue}
          onPress={onCardPress}
        />
      </HomeWidgetBase>
    );
  };
