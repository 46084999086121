import { Flex, ScrollView, VStack } from 'native-base';
import React from 'react';

import { useScaffoldController } from '~/components/Scaffold/scaffold.controller';
import { ScaffoldProps } from '~/components/Scaffold/scaffold.types';
import { ScaffoldHeader } from '~/components/Scaffold/ScaffoldHeader';

export const Scaffold = ({
  children,
  title,
  rightElement,
  customHeaderElement,
  onScrollReachEnd,
  ...VStackProps
}: ScaffoldProps) => {
  useScaffoldController({
    onScrollReachEnd,
  });

  return (
    <ScrollView
      w={'100%'}
      flexShrink={2}
      overflow={'hidden'}
      overflowY={'scroll'}
      scrollEventThrottle={2000}
      showsVerticalScrollIndicator={false}
      contentContainerStyle={{ flexGrow: 1 }}
    >
      <VStack
        flexGrow={1}
        flexShrink={2}
        space={{
          base: 6,
          lg: 8,
        }}
        {...VStackProps}
      >
        {!title && !rightElement && !customHeaderElement ? null : (
          <ScaffoldHeader
            title={title}
            rightElement={rightElement}
            customHeaderElement={customHeaderElement}
          />
        )}
        <Flex flexGrow={1}>{children}</Flex>
      </VStack>
    </ScrollView>
  );
};
