import { useState } from 'react';
import { SearchOption } from 'ui/components/InlineSearchInput/types';

import {
  mapTeamToOptions,
  parseTeamOption,
} from '~/pages/Authenticated/v2/ActivityView/components/ActivitySearch/ActivityTeamSearch/activity-team-mapper';
import { ActivityTeamSearchProps } from '~/pages/Authenticated/v2/ActivityView/components/ActivitySearch/ActivityTeamSearch/types';

export const useActivityTeamSearchController = (
  props: ActivityTeamSearchProps,
) => {
  const { onTeamChange, selectedTeam, teams } = props;

  const [selectedOption, setSelectedOption] = useState<SearchOption | null>(
    selectedTeam ? parseTeamOption(selectedTeam) : null,
  );

  const loadTeamsOptions = (search: string) => {
    return Promise.resolve(mapTeamToOptions(teams, search));
  };

  const handleTeamSelect = (option: SearchOption | null) => {
    setSelectedOption(option);
    onTeamChange(option ? JSON.parse(option.value) : null);
  };

  return {
    handleTeamSelect,
    loadTeamsOptions,
    selectedOption,
  };
};
