import { HStack, Pressable, Stack, Text } from 'native-base';
import React from 'react';
import Icon from 'ui/components/Icon';
import { colors } from 'ui/theme/colors';

import { useActivityExternalApplyController } from '~/pages/Authenticated/v2/ActivityView/components/ActivityExternalApply/controller';

import { ActivityExternalApplyProps } from './types';

export const ActivityExternalApply = ({
  externalApplyLink = '',
}: ActivityExternalApplyProps) => {
  const { handleOpenExternalApplyLink } = useActivityExternalApplyController();

  return (
    <Stack space={5}>
      <Pressable onPress={() => handleOpenExternalApplyLink(externalApplyLink)}>
        <HStack alignItems="center" space={2}>
          <Icon icon="external-link" size={18} color={colors.gray[500]} />
          <Text underline color="gray.600">
            View external apply
          </Text>
        </HStack>
      </Pressable>
    </Stack>
  );
};

export default React.memo(ActivityExternalApply);
