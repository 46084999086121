import './styles.css';

import { Flex, useBreakpointValue, VStack } from 'native-base';
import React, { useMemo } from 'react';

import { ButtonGroupRoundButton } from './Buttons/Round';
import { useButtonGroupController } from './controller';
import { ButtonGroupProps } from './types';

export const ButtonGroup = (props: ButtonGroupProps) => {
  const {
    children,
    gridColumns = 3,
    mode = 'row',
    flexWrap = 'wrap',
    renderSkeleton,
  } = props;

  const { selectedValues, shouldRender, handlePress } =
    useButtonGroupController(props);

  const renderButtons = useMemo(() => {
    return React.Children.map(children, (child) => {
      if (!React.isValidElement(child)) return child;
      const isSelected = selectedValues.includes(child.props.value);
      return React.cloneElement(child, {
        isSelected,
        onPress: () => handlePress(child.props.value),
      });
    });
  }, [children, handlePress, selectedValues]);

  const columnGap = useBreakpointValue({ base: 4, sm: 16, md: 32 });

  const gridColumnsCount = useBreakpointValue(gridColumns as any);

  const gridTemplateColumns = useMemo(() => {
    return `repeat(${gridColumnsCount}, 1fr)`;
  }, [gridColumnsCount]);

  return (
    <VStack flex={1} w="full">
      {mode === 'row' ? (
        <Flex
          flex={1}
          style={{ gap: columnGap, rowGap: 24 }}
          flexWrap={flexWrap}
          flexDirection={'row'}
          alignItems="flex-start"
          justifyContent={{ base: 'space-between', sm: 'flex-start' }}
        >
          {shouldRender ? renderButtons : renderSkeleton?.() || null}
        </Flex>
      ) : (
        <div className="button-group-grid" style={{ gridTemplateColumns }}>
          {shouldRender ? renderButtons : renderSkeleton?.() || null}
        </div>
      )}
    </VStack>
  );
};

ButtonGroup.RoundButton = ButtonGroupRoundButton;
