import { CaptureOptions, PostHog, Properties } from 'posthog-js';

import { initializePostHog } from '~/config/posthog';
import IAnalytics from '~/types/interfaces/analytics';

class PostHogAnalyticsService implements IAnalytics {
  private client: PostHog | undefined;

  constructor() {
    this.client = initializePostHog();
  }

  track(
    event: string,
    properties?: Properties,
    options?: CaptureOptions,
  ): void {
    this.client?.capture(event, properties, options);
  }

  identify(userId: string, properties?: Properties): void {
    this.client?.identify(userId, properties);
  }

  getClient(): PostHog | undefined {
    return this.client;
  }
}

export default PostHogAnalyticsService;
