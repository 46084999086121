import { isAfter } from 'date-fns';
import { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { IModalRefProps } from 'ui/components/Modals/Modal/types';
import { formattedDate } from 'ui/utils/formatter';

import { useRouter } from '~/hooks/useRouter';
import { ActivitiesToDoProps } from '~/pages/Authenticated/LogActivities/ActivitiesToDo/types';
import { ActivityApplicationsService } from '~/services/resources/activity-applications';
import { IActivity } from '~/types/interfaces/activity';

export const useActivitiesToDoController = (props: ActivitiesToDoProps) => {
  const { refetch } = props;
  const { goToRoute } = useRouter();

  const modalRef = useRef<IModalRefProps>(null);

  const [isEditPressed, setIsEditPressed] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [measurementId, setMeasurementId] = useState('');

  const toggleIsEditPressed = () => {
    setIsEditPressed((prev) => !prev);
  };

  const getHeaderText = (activity?: IActivity) => {
    if (activity?.externalApplyLink) {
      return `External application`;
    }
    if (!activity?.endDate) return '';
    const hasActivityEnded = isAfter(new Date(), new Date(activity.endDate));
    return hasActivityEnded
      ? `Ended on ${formattedDate(new Date(activity.endDate), 'dd/MM/yyyy')}`
      : '';
  };

  const onPressCancel = (
    measurementId: string,
    activityApplicationId: string,
  ) => {
    setMeasurementId(measurementId);
    modalRef?.current?.open();
  };

  const handleCloseModal = () => {
    modalRef?.current?.close();
  };

  const handleDeleteMeasurement = async () => {
    if (!measurementId) return;
    try {
      setIsSubmitting(true);
      await ActivityApplicationsService.deleteMeasurementUnit(measurementId);
      handleCloseModal();
      toast.success(`Log cancelled with success.`);
      refetch();
    } catch (error) {
      toast.error(`Error on trying to cancel log.`);
    } finally {
      setIsSubmitting(false);
    }
  };

  return {
    getHeaderText,
    goToRoute,
    toggleIsEditPressed,
    isEditPressed,
    onPressCancel,
    handleDeleteMeasurement,
    isSubmitting,
    modalRef,
    handleCloseModal,
  };
};
