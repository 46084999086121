import { useClipboard } from 'native-base';
import { toast } from 'react-toastify';

export const useShareActivity = (link: string) => {
  const { onCopy } = useClipboard();

  const handleCopyLinkToClipboard = () => {
    onCopy(link);
    toast.success('Link copied to clipboard.');
  };

  return {
    handleCopyLinkToClipboard,
  };
};
