import { CauseOptions } from 'ui/types/interfaces/activity-category';

import { DEFAULT_IN_APP_SEARCH_CATEGORIES } from '~/pages/Authenticated/v2/InApp/constants';
import { ActivitySearchCategory } from '~/types/interfaces/activity-search/types';

export const getInAppSearchCategoryMap = (
  causes?: CauseOptions[],
): Map<string, ActivitySearchCategory> => {
  const categoryTagsMap = new Map<string, ActivitySearchCategory>();

  for (const category of DEFAULT_IN_APP_SEARCH_CATEGORIES) {
    categoryTagsMap.set(category.key, category);
  }

  if (!causes?.length) {
    return categoryTagsMap;
  }

  for (const cause of causes) {
    const causeCategory = new ActivitySearchCategory(
      cause._id,
      cause.displayName,
      { imageUrl: cause?.icon || '' },
      { causes: [cause._id] },
    );
    categoryTagsMap.set(cause._id, causeCategory);
  }

  return categoryTagsMap;
};
