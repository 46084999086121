import { Box, Text, VStack } from 'native-base';
import Icon from 'ui/components/Icon';
import { colors } from 'ui/theme/colors';

import CalendarInline from '~/components/CalendarInline';
import { useActivityDateSelectorController } from '~/pages/Authenticated/v2/ActivityView/components/ActivitySelectors/ActivityDateSelector/controller';
import { ActivityDateSelectorProps } from '~/pages/Authenticated/v2/ActivityView/components/ActivitySelectors/ActivityDateSelector/types';
import ActivitySelectorCardBase from '~/pages/Authenticated/v2/ActivityView/components/ActivitySelectors/ActivitySelectorCardBase';

export const ActivityDateSelector: React.FC<ActivityDateSelectorProps> = (
  props: ActivityDateSelectorProps,
) => {
  const { selectedDate, availableDates, blockedDates } = props;
  const {
    formattedDate,
    isOpen,
    hideSelector,
    tagLabel,
    cardExtraLabels,
    onOpen,
    onClose,
    handleCalendarChange,
  } = useActivityDateSelectorController(props);

  return (
    <ActivitySelectorCardBase
      cardName="date"
      title={formattedDate.weekDay}
      titleIcon="calendar"
      titlePlaceHolder="Select desired date"
      description={formattedDate.hours}
      descriptionIcon="clock"
      tagLabel={tagLabel}
      labels={cardExtraLabels}
      trailingContent={
        <VStack height={'fit-content'} alignItems={'center'}>
          {!selectedDate ? (
            <>
              <Icon icon="calendar" size={'24px'} color={colors.gray[400]} />
            </>
          ) : (
            <>
              <Text
                color={colors.black}
                fontSize={'xl'}
                fontWeight={700}
                lineHeight={'125%'}
              >
                {formattedDate.day}
              </Text>
              <Text color={colors.black} fontSize={'md'} fontWeight={500}>
                {formattedDate.month}
              </Text>
            </>
          )}
        </VStack>
      }
      showPopover={isOpen}
      closePopover={onClose}
      openPopover={onOpen}
      hideSelector={hideSelector}
      popoverContent={
        <Box w={'396px'}>
          <CalendarInline
            date={selectedDate}
            onChange={handleCalendarChange}
            dates={availableDates}
            blockedDates={blockedDates}
          />
        </Box>
      }
    />
  );
};

export default ActivityDateSelector;
