import { Box, Spinner, Stack, Text } from 'native-base';
import logoDoitColorful from 'ui/assets/svgs/doit-logo-colorful.svg';
import Illustration, { ILLUSTRATIONS_NAME } from 'ui/components/Illustration';

import { useSplashScreenController } from './splashScreenController';

const SplashScreen = () => {
  const {} = useSplashScreenController();
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      h="100vh"
      w="100%"
      bgColor="singletone.lightText"
    >
      <Box>
        <Illustration name={ILLUSTRATIONS_NAME.PARACHUTE} w={139} h={120} />
      </Box>
      <Box>
        <img
          src={logoDoitColorful}
          width="auto"
          height="124px"
          alt="logo doIT"
        />
      </Box>
      <Box>
        <Text
          color="grey.700"
          fontWeight={700}
          fontSize="lg"
          mb={3}
          textAlign="center"
          mt={6}
        >
          Welcome to Do IT!
        </Text>
        <Text maxW="230px" textAlign="center">
          Explore a world of opportunities to make a difference.
        </Text>
      </Box>
      <Box mt={4}>
        <Spinner size="lg" />
      </Box>
    </Stack>
  );
};

export default SplashScreen;
