import { isEqual as isDateEqual, isSameDay } from 'date-fns';
import { useEffect, useState } from 'react';

import {
  CalendarInlineProps,
  DateSlot,
  getDateSlotKey,
} from '~/components/CalendarInline/types';

export const useCalendarInlineController = (props: CalendarInlineProps) => {
  const { date, onChange, dates, blockedDates } = props;

  const [calendarMap, setCalendarMap] = useState<Map<string, [DateSlot]>>(
    new Map(),
  );

  const [selectedDate, setSelectedDate] = useState<Date | null>(date);
  const [hightLightDates, setHightLightDates] = useState<Date[]>([]);
  const [dateTimes, setDateTimes] = useState<Date[]>([]);

  useEffect(() => {
    if (!dates) return;

    const newCalendarMap = new Map();
    const newHightLightDates = [];
    for (const date of dates) {
      const blockedDate = blockedDates.find(({ date: blockedDate }) =>
        isDateEqual(blockedDate, date),
      );

      if (!blockedDate) newHightLightDates.push(date);

      const dateSlotNode = {
        date,
        isBlocked: !!blockedDate,
        tooltipMessage: blockedDate?.message,
      };

      const key = getDateSlotKey(date);
      if (newCalendarMap.get(key)) {
        newCalendarMap.get(key).push(dateSlotNode);
      } else {
        newCalendarMap.set(key, [dateSlotNode]);
      }
    }

    setCalendarMap(newCalendarMap);
    setHightLightDates(newHightLightDates);
  }, [dates, blockedDates, date]);

  const getCalendarSlotNode = (date: Date) => {
    const key = getDateSlotKey(date);
    const dateSlot = calendarMap.get(key) ?? [];
    const dateNode = dateSlot.find((node) => isDateEqual(node.date, date));
    return dateNode;
  };

  const getCalenderDateSlotStatus = (date: Date) => {
    const key = getDateSlotKey(date);
    const dateSlot = calendarMap.get(key) ?? [];
    if (dateSlot && dateSlot.length > 0) {
      const isAllNodeBlocked = (calendarMap.get(key) ?? []).every(
        (some) => some.isBlocked === true,
      );
      return { isBlocked: isAllNodeBlocked, tooltipMessage: 'Closed' };
    }
    return { isBlocked: false };
  };

  const handleDateChange = (newDate: Date) => {
    setSelectedDate(newDate);
  };

  const handleDateSubmit = (finalDate: Date) => {
    onChange(finalDate);
  };

  useEffect(() => {
    if (!selectedDate) return;
    const sameDayDates = dates.filter((date) => isSameDay(selectedDate, date));
    setDateTimes(sameDayDates ?? []);
  }, [selectedDate, dates]);

  return {
    handleDateChange,
    handleDateSubmit,
    getCalendarSlotNode,
    getCalenderDateSlotStatus,
    hightLightDates,
    selectedDate,
    calendarMap,
    dateTimes,
  };
};
