import { useMemo } from 'react';
import { AdvertiseRegionOptionsEnum, LocationOptionsEnum } from 'ui/enums';
import {
  getActivityAdvertiseOption,
  mapActivityRegionsForLocationDetails,
} from 'ui/utils/activityRegions';

import { useActivityRegions } from '~/hooks/useActivityRegions/useActivityRegions';
import { getCoordinatesFromAddress } from '~/utils/getCoordinates';

import { ActivityLocationProps } from './types';

export const useActivityLocationController = (props: ActivityLocationProps) => {
  const { regions, address, meetingLink, locationOption } = props;
  const { data: regionsData } = useActivityRegions();

  const advertiseRegionOption = useMemo(() => {
    const regionsList = regionsData?.data ?? [];
    if (regionsList.length == 0) return AdvertiseRegionOptionsEnum.AnywhereInUK;
    return getActivityAdvertiseOption(regionsList, regions);
  }, [regionsData, regions]);

  const mappedRegions = useMemo(() => {
    const regionsList = regionsData?.data ?? [];
    if (regionsList.length == 0) return [];
    return mapActivityRegionsForLocationDetails(regionsList, regions);
  }, [regionsData, regions]);

  const coordinates = address ? getCoordinatesFromAddress(address) : undefined;

  // -- Render statements
  const isFromHome = locationOption === LocationOptionsEnum.FromHome;

  // -- Computed values
  const streetAddress = address?.street || '';
  const remoteAddress = meetingLink || '';

  return {
    coordinates,
    streetAddress,
    remoteAddress,
    mappedRegions,
    advertiseRegionOption,
    isFromHome,
  };
};
