import { Flex, HStack, Stack, Text, VStack } from 'native-base';
import Icon from 'ui/components/Icon';
import Tag from 'ui/components/Tags/Tag';
import { Tooltip } from 'ui/components/Tooltip';
import { colors } from 'ui/theme/colors';

import {
  BookingDateSelectDetailItemProps,
  BookingDateSelectDetailsProps,
  BookingDateSelectSpotsProps,
} from './booking-date-select-details.types';

const BookingDateSelectDetailItem = ({
  iconName,
  text,
  ...textProps
}: BookingDateSelectDetailItemProps) => {
  return (
    <HStack space={2} flexDir={'row'} w={'100%'}>
      <div className="booking-date-select-detail-item-icon">
        <Icon size={20} icon={iconName} />
      </div>
      <Text {...textProps} color="inherit" w={'100%'} noOfLines={1}>
        {text}
      </Text>
    </HStack>
  );
};

export const BookingDateSelectSpots = ({
  numberOfSpots,
}: BookingDateSelectSpotsProps) => {
  return (
    <Stack>
      <Tag
        text={`${numberOfSpots} SPOTS AVAILABLE`}
        tagBgColor={colors.info[100]}
        tagTextColor={colors.singletons.darkText}
      />
    </Stack>
  );
};

export const BookingDateSelectDetails = ({
  bookingDateSelectDetails,
  // TODO: Remove numberOfSpots
  // This prop should not be where, it must como from the bookingDateSelectDetails.
  numberOfSpots,
  isVolunteerNumberLimited,
}: BookingDateSelectDetailsProps) => {
  return (
    <Flex
      minH="5rem"
      borderLeftColor="gray.300"
      borderLeftWidth="1px"
      pl="0.75rem"
      w={'100%'}
    >
      <VStack space={'0.5rem'} w={'100%'} justifyContent={'space-between'}>
        {bookingDateSelectDetails?.map((details) => {
          return (
            <Tooltip label={details.text} key={details.iconName}>
              <BookingDateSelectDetailItem
                iconName={details.iconName}
                text={details.text}
              />
            </Tooltip>
          );
        })}
        {isVolunteerNumberLimited && numberOfSpots ? (
          <BookingDateSelectSpots numberOfSpots={numberOfSpots} />
        ) : null}
      </VStack>
    </Flex>
  );
};
