import { useMemo } from 'react';

import { secondsToHours } from '../../../../../utils/time-parser';
import { TimeInputProps } from './types';

export const useTimeInputController = ({
  onChange,
  value,
  max,
}: TimeInputProps) => {
  const { hours, minutes } = useMemo(() => secondsToHours(value), [value]);

  const handleOnChange = (value: string, inputIndex: number) => {
    const maxSeconds = max ? max * 3600 : null;

    const inputValue = value.replace(/\D/g, '');
    const numberValue = parseInt(inputValue);

    // Convert to seconds
    let seconds = 0;
    if (inputIndex === 0) {
      const minutesInSeconds = minutes * 60;
      seconds = numberValue * 3600 + minutesInSeconds;
    } else {
      const hoursInSeconds = hours * 3600;
      if (isNaN(numberValue)) return onChange?.(hoursInSeconds);
      seconds = numberValue * 60 + hoursInSeconds;
    }

    // Max value
    if (maxSeconds && maxSeconds < seconds) {
      onChange?.(maxSeconds);
      return;
    }

    onChange?.(seconds);
  };

  return { handleOnChange, hours, minutes };
};
