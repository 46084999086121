import { PopulatedMeasurementDefinition } from '~/types/interfaces/measurement-definition';

export enum OffPlatformOptionsActionEnum {
  END = 'end',
  DELETE = 'delete',
}

export type OffPlatformCardProps = {
  measurementDefinition: PopulatedMeasurementDefinition;
  onLogActivityPress: VoidFunction;
  onOptionsPress?: (action: OffPlatformOptionsActionEnum) => void;
  onRefresh?: VoidFunction;
};
