import 'react-datepicker/dist/react-datepicker.css';
import './style.css';

import FeatherIcon from 'feather-icons-react';
import { FormControl, Input, Pressable } from 'native-base';
import DatePicker from 'react-datepicker';
import { Controller } from 'react-hook-form';

import { colors } from '../../../theme/colors';
import { IDatePickerProps } from './types';
import { useDateController } from './useDateController';

export const CustomDatePicker = (props: IDatePickerProps) => {
  const { portalId } = props;
  const {
    control,
    name,
    placeholder,
    label,
    errorMessage,
    helperText,
    isRequired,
    hasError,
    isDisabled,
    isReadOnly,
    isInvalid,
    dateFormat,
    showTimeSelect,
    timeIntervals,
    maxDate,
    inputProps,
    usePortal = false,
    onChangeDate,
  } = props;
  const { isOpen, handleChangeOpenCalendar, maskDate } = useDateController();

  return (
    <FormControl
      isRequired={isRequired}
      isReadOnly={isReadOnly}
      isDisabled={isDisabled}
      isInvalid={hasError || errorMessage ? true : isInvalid}
    >
      {label ? <FormControl.Label>{label}</FormControl.Label> : null}
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => (
          <DatePicker
            portalId={portalId || 'root-portal'}
            withPortal={usePortal}
            placeholderText={placeholder}
            selected={value}
            onChange={(date) => {
              typeof onChangeDate === 'function' && onChangeDate(date);
              onChange(date);
            }}
            dateFormat={dateFormat}
            showTimeSelect={showTimeSelect}
            timeIntervals={timeIntervals}
            maxDate={maxDate}
            customInput={
              <Input
                {...inputProps}
                onKeyPress={(e) => maskDate(e)}
                isRequired={isRequired}
                value={value}
                placeholder={placeholder}
                bgColor="singletons.white"
                InputRightElement={
                  <Pressable
                    mr={4}
                    onPress={() => handleChangeOpenCalendar(!isOpen)}
                  >
                    <FeatherIcon
                      icon="calendar"
                      size={15}
                      color={
                        errorMessage ? colors.error[700] : colors.muted[400]
                      }
                    />
                  </Pressable>
                }
              />
            }
          />
        )}
      />
      {!hasError && !errorMessage && helperText ? (
        <FormControl.HelperText ml={2}>{helperText}</FormControl.HelperText>
      ) : null}
      {errorMessage ? (
        <FormControl.ErrorMessage ml={2}>
          {errorMessage}
        </FormControl.ErrorMessage>
      ) : null}
    </FormControl>
  );
};
