import { Button, HStack, Text } from 'native-base';
import Icon from 'ui/components/Icon';
import { colors } from 'ui/theme/colors';

export const ViewAllButton = ({ onPress }: { onPress?: VoidFunction }) => {
  return (
    <Button
      w="fit-content"
      minW={'unset'}
      variant="ghost"
      onPress={onPress}
      borderColor={colors.singletons.black}
      justifyContent={'center'}
      alignItems={'center'}
      marginBottom={0}
      marginTop={'auto'}
      p={2}
    >
      <HStack alignItems={'center'} space={'8px'}>
        <Text
          color={colors.singletons.black}
          fontSize={'sm'}
          fontWeight={500}
          lineHeight={'100%'}
        >
          View all
        </Text>
        <Icon icon={'chevron-right'} size={16} />
      </HStack>
    </Button>
  );
};
