import { HStack, Text } from 'native-base';
import { DropDown } from 'ui/components/v2/DropDown';
import { colors } from 'ui/theme/colors';

import { FilterButtonBase } from '../Base';
import { FilterButtonDropDownProps } from './types';

export const FilterButtonDropDown = ({
  value,
  onChange,
  options,
}: FilterButtonDropDownProps) => {
  return (
    <DropDown.Menu selected={value} onItemPress={onChange} items={options}>
      <HStack alignItems={'center'} space={2}>
        <FilterButtonBase label="Sort by" icon="switch" />
        {value && (
          <Text
            fontSize={{ base: '10px', sm: 'sm' }}
            fontWeight={400}
            color={colors.gray[600]}
          >
            {value}
          </Text>
        )}
      </HStack>
    </DropDown.Menu>
  );
};
