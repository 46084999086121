import { useInfiniteQuery } from 'react-query';

import { useAppSelector } from '~/hooks/useAppSelector';
import { api, publicApi } from '~/services/api';

import { GenericObject, getQueryKey } from '../types';
import {
  EQueryKeys,
  IBaseGetPaginatedData,
  UseInfinityActivityResponse,
} from './useInfinityActivity.types';

const ITEMS_PER_PAGE = 10;

const getPaginatedActivity = async ({
  pageParam = 0,
  params,
  disabled,
}: IBaseGetPaginatedData): UseInfinityActivityResponse => {
  if (disabled) return { data: [], skip: 0 };

  const { data } = await publicApi.get('activity-public/search', {
    params: { ...params, skip: pageParam },
  });

  return {
    data,
    skip: pageParam + ITEMS_PER_PAGE,
  };
};

export default function useInfinityActivity(
  params: GenericObject,
  disabled = false,
) {
  const queryKey = getQueryKey<GenericObject>(
    EQueryKeys.INFINITY_QUERY_ACTIVITIES,
    params,
  );

  return useInfiniteQuery(
    queryKey,
    ({ pageParam }) => getPaginatedActivity({ pageParam, params, disabled }),
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.data.length < ITEMS_PER_PAGE) return undefined;

        return lastPage.skip;
      },
    },
  );
}
