import {
  PermissionCategories,
  PermissionConditionValueTypesEnum,
  PermissionType,
  TeamEventsConditionFieldsEnum,
} from 'ui/enums';
import {
  IPermission,
  IPermissionCategory,
} from 'ui/types/interfaces/permission';
import { createPermissions } from 'ui/utils/permissions';

//-----Activity Permission Categories-----//

export const EventsCategory: IPermissionCategory = {
  name: PermissionCategories.Events,
  description:
    'Permissions related to events, including publishing and getting reports',
  displayName: 'Events',
};

export const ActionsCategory: IPermissionCategory = {
  name: PermissionCategories.Actions,
  description:
    'Permissions related to actions, including publishing and getting reports',
  displayName: 'Actions',
};

export const OngoingOpportunityCategory: IPermissionCategory = {
  name: PermissionCategories.OngoingOpportunity,
  description:
    'Permissions related to ongoing opportunities, including publishing and getting reports',
  displayName: 'Ongoing Opportunities',
};

export const TeamEventsCategory: IPermissionCategory = {
  name: PermissionCategories.TeamEvents,
  description:
    'Permissions related to team events, including publishing and getting reports',
  displayName: 'Team Events',
};

//-----Activity Permissions-----//

export const ActivityApplyToEventPermission: Partial<IPermission> = {
  name: 'activity_apply_to_events',
  description: 'Allow volunteers to apply to events',
  type: PermissionType.MEMBER,
  restrictionType: PermissionConditionValueTypesEnum.BOOLEAN,
  category: EventsCategory,
};

export const ActivityViewEventsPermission = {
  name: 'activity_view_events',
  description: 'Allow viewing events',
  type: PermissionType.MEMBER,
  restrictionType: PermissionConditionValueTypesEnum.BOOLEAN,
  category: EventsCategory,
};

export const ActivityApplyToTeamEventsPermission: Partial<IPermission> = {
  name: 'activity_apply_to_team_events',
  description: 'Allow volunteers to apply to team events',
  type: PermissionType.MEMBER,
  restrictionType: PermissionConditionValueTypesEnum.BOOLEAN,
  category: TeamEventsCategory,
};

export const ActivityViewTeamEventsPermission = {
  name: 'activity_view_team_events',
  description: 'Allow viewing team events',
  type: PermissionType.MEMBER,
  restrictionType: PermissionConditionValueTypesEnum.BOOLEAN,
  category: TeamEventsCategory,
};

export const ActivityApplyToActionPermission: Partial<IPermission> = {
  name: 'activity_apply_to_actions',
  description: 'Allow volunteers to apply to actions',
  type: PermissionType.MEMBER,
  restrictionType: PermissionConditionValueTypesEnum.BOOLEAN,
  category: ActionsCategory,
};

export const ActivityViewActionsPermission = {
  name: 'activity_view_actions',
  description: 'Allow viewing actions',
  type: PermissionType.MEMBER,
  restrictionType: PermissionConditionValueTypesEnum.BOOLEAN,
  category: ActionsCategory,
};

export const ActivityApplyToOngoingPermission: Partial<IPermission> = {
  name: 'activity_apply_to_ongoing_opportunities',
  description: 'Allow volunteers to apply to ongoing opportunities',
  type: PermissionType.MEMBER,
  restrictionType: PermissionConditionValueTypesEnum.BOOLEAN,
  category: OngoingOpportunityCategory,
};

export const ActivityViewOngoingOpportunitiesPermission = {
  name: 'activity_view_ongoing_opportunities',
  description: 'Allow viewing ongoing opportunities',
  type: PermissionType.MEMBER,
  restrictionType: PermissionConditionValueTypesEnum.BOOLEAN,
  category: OngoingOpportunityCategory,
};

export const ActivityLimitTeamEventsPermission: Partial<IPermission> = {
  name: 'activity_limit_team_events',
  description: 'Limit team events',
  type: PermissionType.MEMBER,
  restrictionType: PermissionConditionValueTypesEnum.QUANTITATIVE,
  conditions: [
    {
      field: TeamEventsConditionFieldsEnum.ACTIVITIES_LIMIT,
      operator: '$lte',
      value: null,
      defaultValue: null,
      valueType: PermissionConditionValueTypesEnum.NUMBER,
    },
  ],
  sharingSettings: {
    editConflictResolutionMethod: 'UseLowestValue',
    denyEdition: false,
    isRequired: true,
    isShared: false,
  },
  category: TeamEventsCategory,
  conflictResolutionMethod: 'UseHighestValue',
  mustHave: false,
};

export const MemberPermissions = createPermissions({
  ApplyToAction: ActivityApplyToActionPermission,
  ApplyToEvent: ActivityApplyToEventPermission,
  ApplyToOngoing: ActivityApplyToOngoingPermission,
  ApplyToTeamEvent: ActivityApplyToTeamEventsPermission,
  ViewEvents: ActivityViewEventsPermission,
  ViewTeamEvents: ActivityViewTeamEventsPermission,
  ViewAction: ActivityViewActionsPermission,
  ViewOngoingOpportunities: ActivityViewOngoingOpportunitiesPermission,
  LimitTeamEvents: ActivityLimitTeamEventsPermission,
});

//------Partner Activity Permissions------//

export const ActivityPublishEventsPermission: Partial<IPermission> = {
  name: 'activity_publish_events',
  description: 'Allow publishing events',
  type: PermissionType.PARTNER,
  restrictionType: PermissionConditionValueTypesEnum.BOOLEAN,
  category: EventsCategory,
};

export const ActivityPublishActionsPermission: Partial<IPermission> = {
  name: 'activity_publish_actions',
  description: 'Allow publishing actions',
  type: PermissionType.PARTNER,
  restrictionType: PermissionConditionValueTypesEnum.BOOLEAN,
  category: ActionsCategory,
};

export const ActivityPublishOngoingOpportunitiesPermission: Partial<IPermission> =
  {
    name: 'activity_publish_ongoing_opportunities',
    description: 'Allow publishing ongoing opportunities',
    type: PermissionType.PARTNER,
    restrictionType: PermissionConditionValueTypesEnum.BOOLEAN,
    category: OngoingOpportunityCategory,
  };

export const ActivityPublishTeamEventsPermission: Partial<IPermission> = {
  name: 'activity_publish_team_events',
  description: 'Allow publishing team events',
  type: PermissionType.PARTNER,
  restrictionType: PermissionConditionValueTypesEnum.BOOLEAN,
  category: TeamEventsCategory,
};

export const PartnerActivityPermissions = createPermissions({
  PublishEvents: ActivityPublishEventsPermission,
  PublishActions: ActivityPublishActionsPermission,
  PublishOngoingOpportunities: ActivityPublishOngoingOpportunitiesPermission,
  PublishTeamEvents: ActivityPublishTeamEventsPermission,
});

//-----App Permissions Categories-----//

export const AppCategory: IPermissionCategory = {
  name: PermissionCategories.App,
  description: 'Permissions related to app',
  displayName: 'App',
};

export const ViewAppPermission = {
  name: 'allow_view_app',
  description: 'Allow view app',
  type: PermissionType.MEMBER,
  restrictionType: PermissionConditionValueTypesEnum.BOOLEAN,
  category: AppCategory,
};

export const AppPermissions = createPermissions({
  ViewApp: ViewAppPermission,
});
