import Blocks from '../../assets/icons/Blocks.svg';
import CaretDown from '../../assets/icons/caret-down.svg';
import CaretUp from '../../assets/icons/caret-up.svg';
import ClockArrow from '../../assets/icons/clock-arrow.svg';
import CSVSheet from '../../assets/icons/csv-sheet.svg';
import FileImport from '../../assets/icons/file-import.svg';
import FolderArrowUp from '../../assets/icons/folder-arrow-up.svg';
import HandPointer from '../../assets/icons/hand-pointer.svg';
import HouseHeart from '../../assets/icons/house-heart.svg';
import Puzzle from '../../assets/icons/puzzle.svg';
import PuzzleActive from '../../assets/icons/puzzle-active.svg';
import PuzzleTertiary from '../../assets/icons/puzzle-tertiary.svg';
import Question from '../../assets/icons/question.svg';
import Sparkles from '../../assets/icons/sparkles.svg';
import Switch from '../../assets/icons/switch.svg';
import TargetAndArrow from '../../assets/icons/target-and-arrow.svg';
import UserClock from '../../assets/icons/user-clock.svg';
import Whatsapp from '../../assets/icons/whatsapp.svg';
import { IconEnum, ICustomIcon } from './types';

export const IconSVG: ICustomIcon = {
  [IconEnum.PUZZLE]: Puzzle,
  [IconEnum.PUZZLE_ACTIVE]: PuzzleActive,
  [IconEnum.PUZZLE_TERTIARY]: PuzzleTertiary,
  [IconEnum.CSV_SHEET]: CSVSheet,
  [IconEnum.WHATSAPP]: Whatsapp,
  [IconEnum.BLOCKS]: Blocks,
  [IconEnum.SPARKLES]: Sparkles,
  [IconEnum.USER_CLOCK]: UserClock,
  [IconEnum.TARGET_AND_ARROW]: TargetAndArrow,
  [IconEnum.CARET_DOWN]: CaretDown,
  [IconEnum.CARET_UP]: CaretUp,
  [IconEnum.CLOCK_ARROW]: ClockArrow,
  [IconEnum.FILE_INPURT]: FileImport,
  [IconEnum.FOLDER_ARROW_UP]: FolderArrowUp,
  [IconEnum.HAND_POINTER]: HandPointer,
  [IconEnum.SWITCH]: Switch,
  [IconEnum.QUESTION]: Question,
  [IconEnum.HOUSE_HEART]: HouseHeart,
};
