import { useBreakpointValue } from 'native-base';
import { ForwardedRef, useImperativeHandle, useState } from 'react';
import { useClickOutside } from 'ui/hooks/useClickOutside';

import { DropDownBaseProps, DropDownImperativeRef } from './types';

export const useDropDownBaseController = (
  {}: DropDownBaseProps,
  ref: ForwardedRef<DropDownImperativeRef>,
) => {
  const [isOpen, setIsOpen] = useState(false);
  const isMobile = useBreakpointValue({ base: true, md: false });

  const handleOpenOverlay = () => {
    setIsOpen(true);
  };

  const handleToggleOverlay = () => {
    setIsOpen((prev) => !prev);
  };

  const handleCloseOverlay = () => {
    setIsOpen(false);
  };

  const { containerRef } = useClickOutside(() => {
    if (!!isMobile) return;
    handleCloseOverlay();
  });

  useImperativeHandle(
    ref,
    (): DropDownImperativeRef => ({
      open: () => handleOpenOverlay,
      close: () => handleCloseOverlay(),
    }),
    [],
  );

  return {
    isOpen,
    isMobile,
    containerRef,
    handleOpenOverlay,
    handleCloseOverlay,
    handleToggleOverlay,
  };
};
