import { useCallback, useEffect, useRef } from 'react';

/**
 * useIntersection
 *
 * This custom React hook helps monitor when a target element is in view using the Intersection Observer API.
 * It takes an `onIntersect` callback function that triggers when the target element becomes visible within the viewport
 *
 **/

interface IntersectionOptions {
  root?: Element | null;
  rootMargin?: string;
  threshold?: number | number[];
}

export const useIntersection = (
  onIntersect: () => void,
  stopOnIntersection = false,
  options: IntersectionOptions = {
    root: null,
    rootMargin: '0px',
    threshold: 0.5, // Deve chamar onIntersect quando target está 50% visível
  },
) => {
  const ref = useRef<HTMLElement | null>(null);
  const wasIntersecting = useRef(false);

  const handleIntersect = useCallback(() => {
    if (!wasIntersecting.current) {
      wasIntersecting.current = true;
      onIntersect?.();
    }
  }, [onIntersect]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          handleIntersect();

          if (stopOnIntersection && ref.current) {
            observer.unobserve(ref.current);
          }
        } else {
          wasIntersecting.current = false;
        }
      });
    }, options);

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [options, handleIntersect]);

  return ref;
};

export default useIntersection;
