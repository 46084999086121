import { useMemo } from 'react';
import { colors } from 'ui/theme/colors';

import { PasswordRuleProps } from './types';

const usePasswordRuleController = ({ rule, success }: PasswordRuleProps) => {
  const color = useMemo(
    () => (success ? colors.success[600] : colors.error[700]),
    [success],
  );
  const icon = useMemo(
    () => (success ? 'check-circle' : 'x-circle'),
    [success],
  );
  return {
    rule,
    color,
    icon,
  };
};

export default usePasswordRuleController;
