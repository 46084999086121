import { Box, Pressable, Progress, Text, VStack } from 'native-base';
import { colors } from 'ui/theme/colors';

import { WIDGET_COLORS } from '../../Base';

export type ProfileProgressInfoSnippetProps = {
  progressValue: number;
  onPress?: VoidFunction;
};

export const ProfileProgressInfoSnippet = ({
  onPress,
  progressValue,
}: ProfileProgressInfoSnippetProps) => {
  return (
    <Pressable w="full" h="full" flex={1} onPress={onPress}>
      <VStack w="full" h="full" justifyContent={'center'} space={1}>
        <Box>
          <Text
            color={colors.singletons.black}
            fontWeight={500}
            fontSize={'lg'}
            lineHeight={'125%'}
          >
            {progressValue.toFixed(0)}%
          </Text>
        </Box>

        {/* progress bar */}
        <Box w="full">
          <Progress
            backgroundColor={colors.gray['200']}
            _filledTrack={{
              backgroundColor: WIDGET_COLORS.red,
            }}
            w={'full'}
            value={progressValue}
          />
        </Box>

        <Box mt={1}>
          <Text
            lineHeight={'125%'}
            fontSize="sm"
            color="gray.600"
            fontWeight={400}
          >
            {'You’ve completed so far'}
          </Text>
        </Box>
      </VStack>
    </Pressable>
  );
};
