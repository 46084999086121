import { Controller } from 'react-hook-form';

import { BaseTextInput } from '../BaseTextInput';
import { IControlledTextInputProps } from './types';

export const ControlledTextInput = (props: IControlledTextInputProps) => {
  const {
    placeholder,
    errorMessage,
    helpText,
    isDisabled,
    isRequired,
    label,
    control,
    name = '',
  } = props;

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <BaseTextInput
          value={value}
          onChange={onChange}
          label={label}
          isRequired={isRequired}
          errorMessage={errorMessage}
          placeholder={placeholder}
          helpText={helpText}
          isDisabled={isDisabled}
        />
      )}
    />
  );
};
