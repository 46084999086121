import { createContext } from 'react';
import { ActivitySemanticType } from 'ui/types/activities';
import { ILocation } from 'ui/types/interfaces';

import { createActivityViewStore } from '~/pages/Authenticated/v2/ActivityView/store';
import { IUserActivity } from '~/pages/Authenticated/v2/ActivityView/types';
import { IActivityDefinition } from '~/types/interfaces/activity';

export type ActivityViewFilters = {
  location: ILocation | null;
  startDate: Date | null;
};

export interface ActivityViewStateProps {
  activityDefinition?: IActivityDefinition;
  activityType?: ActivitySemanticType;
  selectedActivity?: IUserActivity;
  activities: IUserActivity[];
  activitiesMap: Map<string, IUserActivity>;
  filters: ActivityViewFilters;
  showCalendarDrawer: boolean;
  showLocationDrawer: boolean;
  showTeamDrawer: boolean;
}

export type ActivityViewStateFull = Required<ActivityViewStateProps>;

export interface ActivityViewState extends ActivityViewStateProps {
  setFilters: (filters: ActivityViewFilters) => void;
  setSelectedActivity: (selectedActivity?: IUserActivity) => void;
  setShowCalendarDrawer: (value: boolean) => void;
  setShowLocationDrawer: (value: boolean) => void;
  setShowTeamDrawer: (value: boolean) => void;
}

export type ActivityViewStore = ReturnType<typeof createActivityViewStore>;

export const ActivityViewContext = createContext<ActivityViewStore | null>(
  null,
);
