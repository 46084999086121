import { Avatar, HStack, Pressable, Stack, Text } from 'native-base';
import { Tooltip } from 'ui/components/Tooltip';
import { colors } from 'ui/theme/colors';
import { getNameInitials } from 'ui/utils/getNameInititals';

import { SwitchPersonaCardProps } from './types';

export const SwitchPersonaCard = ({
  ecosystemName,
  organizationName,
  userName,
  ecosystemLogo,
  isSelectedEcosystem,
  userImage,
  onPress,
}: SwitchPersonaCardProps) => {
  return (
    <Pressable onPress={onPress}>
      <Stack
        width={{ base: '160px', lg: '216px' }}
        height={{ base: '180px', lg: '202px' }}
        borderWidth={1}
        borderRadius="3xl"
        borderColor="gray.300"
      >
        <Stack
          width="100%"
          height="35px"
          bgColor="gray.600"
          borderTopRadius="3xl"
        />
        <Stack width="100%" mt={4} position="absolute">
          <HStack justifyContent="center" alignItems="center">
            <Avatar.Group>
              <Avatar
                source={{ uri: userImage }}
                borderWidth={1}
                borderColor="gray.300"
                bgColor="gray.700"
              >
                {getNameInitials(userName)}
              </Avatar>
              <Avatar
                zIndex={1}
                source={{ uri: ecosystemLogo }}
                borderWidth={1}
                borderColor="gray.300"
              >
                {getNameInitials(ecosystemName)}
              </Avatar>
            </Avatar.Group>
          </HStack>
        </Stack>
        <Stack
          alignItems="center"
          paddingTop={8}
          paddingX={4}
          paddingBottom={4}
          space={1}
        >
          <Stack height="36px">
            <Tooltip label={ecosystemName}>
              <Text
                fontSize={{ base: 'xs', lg: 'sm' }}
                numberOfLines={1}
                color={colors.singletons.darkText}
                fontWeight={500}
              >
                {ecosystemName}
              </Text>
            </Tooltip>
          </Stack>

          <Tooltip label={organizationName}>
            <Text
              fontSize={{ base: '2xs', lg: 'xs' }}
              numberOfLines={1}
              color="gray.500"
              fontWeight={500}
            >
              By {organizationName}
            </Text>
          </Tooltip>
        </Stack>
        {isSelectedEcosystem ? (
          <Stack
            borderRadius="6.25rem"
            paddingBottom={4}
            alignItems="center"
            marginTop="auto"
          >
            <Stack
              borderWidth={1}
              borderColor="gray.300"
              width="55%"
              alignItems="center"
              borderRadius="3xl"
            >
              <Text
                color="gray.500"
                fontSize={{ base: '2xs', lg: 'xs' }}
                fontWeight={500}
              >
                MAIN PROFILE
              </Text>
            </Stack>
          </Stack>
        ) : null}
      </Stack>
    </Pressable>
  );
};
