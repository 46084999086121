import './styles.css';

import { View, VStack } from 'native-base';
import { CarouselTag } from 'ui/components/v2/Carousel/PrimaryCarousel/types';

import SearchFeedEmptyState from '~/components/Layouts/v2/SearchLayout/components/EmptyState';
import { ActivityCarousel } from '~/components/v2/ActivityCarousel';
import { CarouselSkeletonOverflow } from '~/pages/Authenticated/v2/InApp/components/CarouselCollections/CarouselSkeleton';
import { SearchCollectionSlug } from '~/types/interfaces/activity-search/types';
import { useDebugMode } from '~/utils/debug/use-debug-mode';

import { collectionsLabels } from './constants';
import { useInAppCarouselsSuggestionController } from './controller';
import Section from './Section';

export const InAppCarouselsCollections = () => {
  const {
    isFilterSet,
    isLoading,
    showEmptyState,
    selectedCategory,
    actionsDataSource,
    teamEventsDataSource,
    inPersonEventsDataSource,
    inPersonOngoingDataSource,
    fromHomeEventsDataSource,
    fromHomeOngoingDataSource,
    handleCarouselRedirect,
  } = useInAppCarouselsSuggestionController();

  const { debugMode } = useDebugMode();

  const renderCarouselTag = () => {
    if (!selectedCategory || !debugMode) return undefined;
    const tag: CarouselTag = { label: selectedCategory.label };
    return [tag];
  };

  return (
    <View mt={{ base: 6, md: 8 }}>
      <VStack pb={2} position={'relative'}>
        <CarouselSkeletonOverflow isLoading={isLoading} />

        {inPersonEventsDataSource && (
          <Section>
            <ActivityCarousel
              headerTags={renderCarouselTag()}
              dataSource={inPersonEventsDataSource}
              title={collectionsLabels[SearchCollectionSlug.Events].title}
              subTitle={collectionsLabels[SearchCollectionSlug.Events].subtitle}
              isEnabled={isFilterSet}
              marginBottom={10}
              onActionPress={() =>
                handleCarouselRedirect(SearchCollectionSlug.Events)
              }
            />
          </Section>
        )}

        {actionsDataSource && (
          <Section>
            <ActivityCarousel
              headerTags={renderCarouselTag()}
              title={collectionsLabels[SearchCollectionSlug.Actions].title}
              subTitle={
                collectionsLabels[SearchCollectionSlug.Actions].subtitle
              }
              dataSource={actionsDataSource}
              isEnabled={isFilterSet}
              marginBottom={10}
              onActionPress={() =>
                handleCarouselRedirect(SearchCollectionSlug.Actions)
              }
            />
          </Section>
        )}

        {teamEventsDataSource && (
          <Section>
            <ActivityCarousel
              headerTags={renderCarouselTag()}
              title={collectionsLabels[SearchCollectionSlug.TeamEvents].title}
              subTitle={
                collectionsLabels[SearchCollectionSlug.TeamEvents].subtitle
              }
              dataSource={teamEventsDataSource}
              isEnabled={isFilterSet}
              marginBottom={10}
              onActionPress={() =>
                handleCarouselRedirect(SearchCollectionSlug.TeamEvents)
              }
            />
          </Section>
        )}

        {inPersonOngoingDataSource && (
          <Section>
            <ActivityCarousel
              headerTags={renderCarouselTag()}
              title={collectionsLabels[SearchCollectionSlug.Ongoing].title}
              subTitle={
                collectionsLabels[SearchCollectionSlug.Ongoing].subtitle
              }
              dataSource={inPersonOngoingDataSource}
              isEnabled={isFilterSet}
              marginBottom={10}
              onActionPress={() =>
                handleCarouselRedirect(SearchCollectionSlug.Ongoing)
              }
            />
          </Section>
        )}

        {fromHomeEventsDataSource && (
          <Section>
            <ActivityCarousel
              headerTags={renderCarouselTag()}
              title={
                collectionsLabels[SearchCollectionSlug.EventsFromHome].title
              }
              subTitle={
                collectionsLabels[SearchCollectionSlug.EventsFromHome].subtitle
              }
              dataSource={fromHomeEventsDataSource}
              isEnabled={isFilterSet}
              marginBottom={10}
              onActionPress={() =>
                handleCarouselRedirect(SearchCollectionSlug.EventsFromHome)
              }
            />
          </Section>
        )}

        {fromHomeOngoingDataSource && (
          <Section>
            <ActivityCarousel
              headerTags={renderCarouselTag()}
              title={
                collectionsLabels[SearchCollectionSlug.RemoteOngoing].title
              }
              subTitle={
                collectionsLabels[SearchCollectionSlug.RemoteOngoing].subtitle
              }
              dataSource={fromHomeOngoingDataSource}
              isEnabled={isFilterSet}
              marginBottom={10}
              onActionPress={() =>
                handleCarouselRedirect(SearchCollectionSlug.RemoteOngoing)
              }
            />
          </Section>
        )}

        {showEmptyState && (
          <VStack pt={10}>
            <SearchFeedEmptyState />
          </VStack>
        )}
      </VStack>
    </View>
  );
};
