import { ActivitySemanticType } from 'ui/types/activities';

import { AttendanceType } from '~/types/interfaces/activity';
import {
  FiltersWhiteListMap,
  FilterWhiteListCanon,
} from '~/utils/activity-search/filter-white-list-map';

export enum FilterSectionName {
  dateRange = 'dateRange',
  location = 'location',
  teamSize = 'teamSize',
}

export const FILTER_SECTIONS_WHITE_LIST: Record<
  FilterSectionName,
  FilterWhiteListCanon
> = {
  [FilterSectionName.dateRange]: {
    activityTypes: [ActivitySemanticType.Event, ActivitySemanticType.TeamEvent],
    attendanceTypes: [AttendanceType.Local, AttendanceType.Online],
  },

  [FilterSectionName.location]: {
    activityTypes: [
      ActivitySemanticType.Event,
      ActivitySemanticType.Ongoing,
      ActivitySemanticType.TeamEvent,
    ],
    attendanceTypes: [AttendanceType.Local],
  },

  [FilterSectionName.teamSize]: {
    activityTypes: [ActivitySemanticType.TeamEvent],
    attendanceTypes: [AttendanceType.Local, AttendanceType.Online],
  },
};

export const filterSectionsWhiteListMap =
  new FiltersWhiteListMap<FilterSectionName>(
    Object.entries(FILTER_SECTIONS_WHITE_LIST) as [
      FilterSectionName,
      FilterWhiteListCanon,
    ][],
  );
