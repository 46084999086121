import { IActivity } from '~/types/interfaces/activity';

export enum EQueryKeys {
  INFINITY_QUERY_ACTIVITIES = 'infinity-query-activities',
}

export type KeyParams = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

export interface IPagination<T> {
  data: T;
  skip: number;
}

export type UseInfinityActivityResponse = Promise<
  IPagination<{ data: IActivity[] }>
>;

export type GenericObject = { [key: string]: unknown };

export interface IBaseGetPaginatedData {
  pageParam?: number;
  params: GenericObject;
  disabled?: boolean;
}

export interface BasePaginatedInfinityQuery
  extends Omit<IBaseGetPaginatedData, 'params'> {
  url: string;
  params?: GenericObject;
}
