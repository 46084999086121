import { HStack, Text } from 'native-base';
import logoDoitColorful from 'ui/assets/svgs/doit-logo-colorful.svg';

export type PoweredByDoitProps = {
  size?: 'md' | 'lg';
};
export const PoweredByDoit = ({ size = 'lg' }: PoweredByDoitProps) => {
  const imageHeight = size === 'lg' ? 46 : 22;
  return (
    <HStack space={2} alignItems="center">
      <Text fontWeight={500} color="gray.400">
        Powered by
      </Text>
      <img
        src={logoDoitColorful}
        height={imageHeight}
        width="auto"
        alt="logo doIT"
        style={{
          opacity: 0.5,
          filter: 'grayscale(100%)',
        }}
      />
    </HStack>
  );
};
