import { useCallback, useEffect, useMemo, useState } from 'react';

import { useSearchRoutes } from '~/components/Layouts/v2/SearchLayout/hooks/use-search-routes';
import {
  ActivityCarouselDataSource,
  ActivityCarouselProps,
} from '~/components/v2/ActivityCarousel/types';
import { useVolunteerEcosystemAvailableApps } from '~/hooks/useApps/useVolunteerEcosystemAvailableApps';
import { useUserLocationController } from '~/hooks/useUserLocation/useUsersLocation';
import {
  generateActionSearchSuggestionDataSource,
  generateInPersonOngoingSearchSuggestionDataSource,
} from '~/pages/Authenticated/v2/Search/components/CarouselSuggestions/utils';
import { SearchCollectionSlug } from '~/types/interfaces/activity-search/types';
import { useInApp } from '~/zustand/store/InApp';
import { useSearch } from '~/zustand/store/Search';

export const useCarouselsSuggestionsController = () => {
  // Providers
  const { app } = useInApp((state) => state);

  const { goToSearchCollectionPage } = useSearchRoutes();
  const { appIds } = useVolunteerEcosystemAvailableApps();
  const { coordinates } = useUserLocationController();
  const { appliedFilters } = useSearch((state) => state);

  // States
  const [actionsDataSource, setActionsDataSource] =
    useState<ActivityCarouselDataSource | null>(null);

  const [ongoingDataSource, setOngoingDataSource] =
    useState<ActivityCarouselDataSource | null>(null);

  // Hooks
  const usersFilters = useMemo(() => {
    if (!appIds || !coordinates) return null;
    const appsToFilters = app ? [app._id] : appIds;
    return {
      apps: appsToFilters,
      position: coordinates,
      searchText: appliedFilters.searchText,
    };
  }, [app, appIds, appliedFilters, coordinates]);

  useEffect(() => {
    if (!usersFilters) return;

    // Actions data source
    generateActionSearchSuggestionDataSource(
      usersFilters,
      setActionsDataSource,
    );

    // In-person ongoing data source
    generateInPersonOngoingSearchSuggestionDataSource(
      usersFilters,
      setOngoingDataSource,
    );
  }, [usersFilters]);

  const handleCarouselAction = useCallback(
    (collectionSlug: SearchCollectionSlug) => {
      goToSearchCollectionPage(collectionSlug, {
        search_text: appliedFilters.searchText,
      });
    },
    [goToSearchCollectionPage, appliedFilters],
  );

  const carouselsMap = useMemo(() => {
    const result: Partial<Record<SearchCollectionSlug, ActivityCarouselProps>> =
      {};

    if (actionsDataSource) {
      result[SearchCollectionSlug.Actions] = {
        title: 'Actions you may like',
        subTitle: {
          large:
            'Contribute towards specific targets through action-oriented opportunities',
          small: 'Contribute towards specific targets',
        },
        dataSource: actionsDataSource,
        onActionPress: () => handleCarouselAction(SearchCollectionSlug.Actions),
      };
    }

    if (ongoingDataSource) {
      result[SearchCollectionSlug.Ongoing] = {
        title: 'Ongoing opportunities you may like',
        subTitle: {
          large:
            'Commit to long-term volunteering opportunities for sustained impact',
          small: 'Commit to long-term volunteering opportunities',
        },
        dataSource: ongoingDataSource,
        onActionPress: () => handleCarouselAction(SearchCollectionSlug.Ongoing),
      };
    }

    return result;
  }, [actionsDataSource, ongoingDataSource, handleCarouselAction]);

  return {
    isFilterSet: !!usersFilters,
    carouselsMap,
  };
};
