import { PermissionBoolean } from './PermissionsBoolean';

export const PermissionsWrapper = {
  /**
 * Component to hide or show children depending on the permissions available.
 *
 * @remarks
 * You can bypass the permissions requirements with the bypassPermissions prop.

 * @param validationType - Type of permissions validation. Default to Generic. In case you need to validate
 * app permissions, change it to SpecificApp.
 * @param availablePermissions - Your available permissions to compare with the requiredPermissions for the children.
 * @param requiredPermissions - Required permissions to show the children.
 * @param fallbackComponent - In case you need to show something different for the user if they don't have the required
 * permission instead.
 * @param bypassPermissions - This enables you to ignore the permissions validation in case the user doesn't need the validation.
 * Example: you are the owner of the system or are a guest user that doesn't have permisisons.
 **/
  Boolean: PermissionBoolean,
};
