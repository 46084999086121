import { differenceInDays } from 'date-fns';
import { useBreakpointValue, useDisclose } from 'native-base';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Info } from 'ui/components/Cards/CardPropInfo/types';
import {
  ActivityTypeEnum,
  EEventApplicationType,
  ESpaceOptionsKeys,
} from 'ui/enums';
import { formatDateToEnGbLocale, formatTimeUtil } from 'ui/utils/formatDates';
import { formatDate } from 'ui/utils/formatter';
import { secondsToHoursAndMinutes } from 'ui/utils/secondsToDuration';

import { CardRecommendationsControllerParams } from '~/components/ActivityCards/CardRecommendations/cardRecommendations.types';
import { PAGES } from '~/constants/pages.constants';
import { useAppSelector } from '~/hooks/useAppSelector';
import { IActivity } from '~/types/interfaces/activity';
import { getNameInitials } from '~/utils/getInitials';

export const spaceOptionsDictionary = {
  [ESpaceOptionsKeys.Indoor]: 'Indoor',
  [ESpaceOptionsKeys.Outdoor]: 'Outdoor',
  [ESpaceOptionsKeys.Both]: 'Both',
};

const getDaysUntilEventStart = (startDate: Date): number => {
  return differenceInDays(new Date(startDate), new Date());
};

export const getDaysSincePost = (createdAt: string): number => {
  return differenceInDays(new Date(createdAt), new Date());
};

export const getSpaceOptionsInfoCardData = (
  spaceOptions: ESpaceOptionsKeys,
): Info => {
  const isBothSpaceOptions = spaceOptions === ESpaceOptionsKeys.Both;
  const isOutdoorSpaceOptions = spaceOptions === ESpaceOptionsKeys.Outdoor;

  const indoorSpaceOptionLabel =
    spaceOptionsDictionary[ESpaceOptionsKeys.Indoor];

  const outdoorSpaceOptionLabel =
    spaceOptionsDictionary[ESpaceOptionsKeys.Outdoor];

  const spaceOptionLabel = isBothSpaceOptions
    ? `${indoorSpaceOptionLabel} and ${outdoorSpaceOptionLabel}`
    : spaceOptionsDictionary[spaceOptions];

  return {
    icon: isBothSpaceOptions || isOutdoorSpaceOptions ? 'sun' : 'home',
    info: spaceOptionLabel,
  };
};

export const getHiddenInfos = (
  { activityDefinitionSubDocument, startDate, endDate, address }: IActivity,
  showOnlyTheFirstItemOnEachRow: boolean,
): Info[][] => {
  const { spaceOptions, causeOptions, type } = activityDefinitionSubDocument;
  const castedSpaceOptions = spaceOptions as ESpaceOptionsKeys;

  const spaceOptionLabelInfo = getSpaceOptionsInfoCardData(castedSpaceOptions);

  const firstRow: Info[] = [];

  if (type === ActivityTypeEnum.Event) {
    firstRow.push({
      icon: 'clock',
      info: `${formatTimeUtil(new Date(startDate))} - ${formatTimeUtil(
        new Date(endDate),
      )}`,
    });
  }

  firstRow.push({
    icon: 'heart',
    info: `Cause: ${causeOptions
      .map(({ displayName }) => displayName)
      .join(', ')}`,
  });

  const secondRow: Info[] = [];

  if (address?.street) {
    secondRow.push({
      icon: 'map',
      info: `${address?.street}`,
    });
  }

  if (spaceOptionLabelInfo) {
    secondRow.push(spaceOptionLabelInfo);
  }

  const rows = [
    showOnlyTheFirstItemOnEachRow ? [firstRow[0]] : firstRow,
    showOnlyTheFirstItemOnEachRow ? [secondRow[0]] : secondRow,
  ];

  return rows.filter(Boolean);
};

export const getShownInfos = (
  activityType: ActivityTypeEnum,
  activityStartDate: Date,
  activityEndDate: Date,
  targetAmount?: number,
  measurementUnitPluralLabel?: string,
  street?: string,
): Info[] => {
  const infos: Info[] = [];

  if (activityType === ActivityTypeEnum.Action && targetAmount) {
    const isHourTarget = measurementUnitPluralLabel === 'Hours';
    const parsedTargetAmount = isHourTarget
      ? secondsToHoursAndMinutes(targetAmount).hours
      : targetAmount;

    infos.push({
      icon: 'target',
      info: `${parsedTargetAmount} ${measurementUnitPluralLabel}`,
    });
  }

  if (activityType === ActivityTypeEnum.Event) {
    const localeStartDate = formatDateToEnGbLocale(new Date(activityStartDate));

    const localeEndDate = formatDateToEnGbLocale(new Date(activityEndDate));

    infos.push({
      icon: 'calendar',
      info: `${localeStartDate} - ${localeEndDate}`,
    });
  }

  if (activityType === ActivityTypeEnum.OngoingOpportunity && !!street) {
    infos.push({
      icon: 'map',
      info: street || '',
    });
  }

  return infos;
};

// TODO: Move to utils
const getEnglishPluralForDays = (quantity: number) => {
  return quantity === 1 ? 'day' : 'days';
};

export const getHeaderText = (
  startDate: Date,
  createdAt: string,
  activityType: string,
  endDate?: Date,
  externalApplyLink?: string,
) => {
  const isEvent = activityType === ActivityTypeEnum.Event;

  if (externalApplyLink) {
    return 'External application';
  }

  const quantityOfDaysUntilEventStart = getDaysUntilEventStart(startDate);

  const quantityOfDaysSincePost = getDaysSincePost(createdAt);

  const isEventEnded = isEvent && endDate && new Date(endDate) < new Date();

  if (isEventEnded) {
    return `Ended on ${formatDate(new Date(endDate), 'DD/MM/YYYY')}`;
  }

  if (isEvent && quantityOfDaysUntilEventStart > 0) {
    const dayString = getEnglishPluralForDays(quantityOfDaysUntilEventStart);

    return `Coming up in ${quantityOfDaysUntilEventStart} ${dayString}`;
  }

  if (quantityOfDaysSincePost === 0) {
    return 'Published today';
  }

  const dayString = getEnglishPluralForDays(quantityOfDaysSincePost);

  return `Published ${quantityOfDaysSincePost * -1} ${dayString} ago`;
};

export const useCardRecommendationsController = ({
  activity,
}: CardRecommendationsControllerParams) => {
  const navigate = useNavigate();
  const { isOpen, onToggle } = useDisclose();
  const hideCauseAndSpaceInfos = useBreakpointValue({
    base: true,
    md: false,
  });

  const { availableAppsFromPermissionGroupsById } = useAppSelector(
    ({ auth }) => auth,
  );

  const eventSummary = useMemo(() => {
    const { activityDefinitionSubDocument } = activity;
    const { organizationSubDocument } = activityDefinitionSubDocument;

    const headerText = getHeaderText(
      activity.startDate,
      activity.createdAt,
      activityDefinitionSubDocument.type,
    );

    const organizationInitials = getNameInitials(organizationSubDocument.name);

    const shownInfos = getShownInfos(
      activityDefinitionSubDocument.type as ActivityTypeEnum,
      activity.startDate,
      activity.endDate,
      activityDefinitionSubDocument.targetAmount,
      activityDefinitionSubDocument.measurementUnitPluralLabel,
      activity?.address?.street,
    );

    const hiddenInfos = getHiddenInfos(activity, hideCauseAndSpaceInfos);

    const appName =
      availableAppsFromPermissionGroupsById &&
      availableAppsFromPermissionGroupsById[
        activityDefinitionSubDocument.app as string
      ].name;

    return {
      headerText,
      activityName: activityDefinitionSubDocument.title,
      activityType: activityDefinitionSubDocument.type,
      appName,
      organizationInitials: organizationInitials,
      organizationName: organizationSubDocument.name,
      organizationLogo: organizationSubDocument.logo,
      shownInfos: shownInfos,
      hiddenInfos: hiddenInfos,
      requirementOptions: activityDefinitionSubDocument.requirementOptions,
      isTeamEvent:
        activity.activityDefinitionSubDocument.eventApplicationType ===
        EEventApplicationType.Team,
    };
  }, [activity, hideCauseAndSpaceInfos, availableAppsFromPermissionGroupsById]);

  const handleGoToActivityDetail = (activityDefinitionId: string) => {
    const route = PAGES.ActivitiesDetails.replace(':id', activityDefinitionId);

    navigate(route);
  };

  const handleGoToGuestActivityDetail = (activityDefinitionId: string) => {
    const route = PAGES.GuestActivityDetails.replace(
      ':id',
      activityDefinitionId,
    );

    navigate(route);
  };
  return {
    isOpen,
    onToggle,
    eventSummary,
    handleGoToActivityDetail,
    handleGoToGuestActivityDetail,
  };
};
