import './style.css';

import { Avatar, HStack, Stack, Text } from 'native-base';
import { toast } from 'react-toastify';
import { NotificationCard } from 'ui/components/NotificationCard';
import { IEcosystem } from 'ui/types/interfaces/ecosystem';
import { getNameInitials } from 'ui/utils/getNameInititals';

import { useAppSelector } from '~/hooks/useAppSelector';
import { IOrganization } from '~/types/interfaces/organization';
import { IUserProfile } from '~/types/interfaces/user';

export const useSwitchNotifyPersona = () => {
  const { user } = useAppSelector((state) => state.auth);

  const handleNotifyPersona = (userProfile: IUserProfile) => {
    const ecosystem = userProfile.ecosystem as unknown as IEcosystem;
    const organizationName = (
      userProfile.ecosystemSummary.organization as unknown as IOrganization
    ).name;

    toast(
      <NotificationCard>
        <Text fontSize="sm" color="gray.500" fontWeight={500}>
          Hi, {user?.name || ''}! Welcome back to
        </Text>
        <HStack
          space={3}
          width="100%"
          alignItems="center"
          justifyContent="center"
        >
          <Avatar.Group>
            <Avatar source={{ uri: userProfile?.profilePicture }}>
              {getNameInitials(user?.name || '')}
            </Avatar>
            <Avatar source={{ uri: ecosystem.logo }} zIndex={1}>
              {getNameInitials(ecosystem.name)}
            </Avatar>
          </Avatar.Group>
          <Stack maxW="9rem" width="100%" space={1}>
            <Text
              numberOfLines={1}
              fontSize="md"
              fontWeight={500}
              color="darkText"
            >
              {ecosystem.name}
            </Text>
            <Text numberOfLines={1} fontWeight={500} color="gray.500">
              By {organizationName}
            </Text>
          </Stack>
        </HStack>
      </NotificationCard>,
      {
        className: 'custom-toast',
        bodyClassName: 'custom-toast-body',
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      },
    );
  };

  return { handleNotifyPersona };
};
