import {
  Box,
  Center,
  PresenceTransition,
  Skeleton,
  Text,
  VStack,
} from 'native-base';
import React from 'react';
import { colors } from 'ui/theme/colors';

export type ActivityPanelProps = {
  children: React.ReactNode;
  title: string;
  titleColor: string;
  isLoaded?: boolean;
  headerBackground: string;
};

export const ActivityPanel = (props: ActivityPanelProps) => {
  const {
    children,
    headerBackground,
    title,
    titleColor,
    isLoaded = true,
  } = props;
  return (
    <VStack
      borderWidth={1}
      borderRadius={'24px'}
      borderColor={colors.gray[300]}
      overflow={'hidden'}
    >
      <Skeleton height={'400'} isLoaded={isLoaded}>
        <Center p="0.5rem" bg={headerBackground} w="full" h="40px">
          <Text color={titleColor} fontWeight={'500'} fontSize="1rem">
            {title}
          </Text>
        </Center>

        <Box p={3}>
          <PresenceTransition
            visible
            style={{ width: '100%' }}
            initial={{ opacity: 0.5 }}
            animate={{ opacity: 1, transition: { duration: 250 } }}
          >
            {children}
          </PresenceTransition>
        </Box>
      </Skeleton>
    </VStack>
  );
};
