import {
  AppPermissions,
  MemberPermissions,
  PartnerActivityPermissions,
} from 'ui/constants/permission.constants';
import { ActivityType } from 'ui/types/activities';
import { PopulatedAppPermissionGroup } from 'ui/types/interfaces/app-permissions-group';
import {
  IAppPermissionGroups,
  IAppPermissionGroupsAppPopulate,
  IPermission,
} from 'ui/types/interfaces/permission';

export function createPermissions<
  T extends Record<string, Partial<IPermission>>,
>(permissions: T): T {
  return permissions;
}

export const getUserPermissionsGroupsByAppId = (
  appPermissionGroups?: IAppPermissionGroups[],
) => {
  if (!appPermissionGroups) return {};
  if (appPermissionGroups && appPermissionGroups.length === 0) return {};

  const userPermissionsByAppId = appPermissionGroups?.reduce(
    (record, permissionGroup) => {
      const hasPreviousRecord = record[permissionGroup.app];

      const previousRecordValue =
        hasPreviousRecord && Object.values(record[permissionGroup.app]);

      if (previousRecordValue) {
        record[permissionGroup.app] = [...previousRecordValue, permissionGroup];
      } else {
        record[permissionGroup.app] = [permissionGroup];
      }

      return record;
    },
    {} as Record<string, IAppPermissionGroups[]>,
  );

  return userPermissionsByAppId;
};

export const getAvailableUserPermissionsFromApps = (
  appPermissionGroups: IAppPermissionGroups[],
) => {
  const permissions: Partial<IPermission>[] = [];

  appPermissionGroups.forEach((appPermissionGroup) => {
    appPermissionGroup.permissions.forEach((permission) => {
      const defaultMemberPermissions = Object.values(MemberPermissions);
      const defaultAppPermissions = Object.values(AppPermissions);
      defaultMemberPermissions.forEach((defaultActivityPermission) => {
        if (!permissions.includes(defaultActivityPermission)) {
          if (permission.name === defaultActivityPermission.name) {
            permissions.push(defaultActivityPermission);
            return;
          }
        }
      });

      defaultAppPermissions.forEach((defaultAppPermission) => {
        if (!permissions.includes(defaultAppPermission)) {
          if (permission.name === defaultAppPermission.name) {
            permissions.push(defaultAppPermission);
            return;
          }
        }
      });
    });
  });

  return permissions;
};
export const getAvailablePartnerPermissionsFromApps = (
  appPermissionGroups: IAppPermissionGroupsAppPopulate[],
) => {
  const permissions: Partial<IPermission>[] = [];

  appPermissionGroups.forEach((appPermissionGroup) => {
    appPermissionGroup.permissions.forEach((permission) => {
      const defaultActivityPermissions = Object.values(
        PartnerActivityPermissions,
      );
      defaultActivityPermissions.forEach((defaultActivityPermission) => {
        if (!permissions.includes(defaultActivityPermission)) {
          if (permission.name === defaultActivityPermission.name) {
            permissions.push(defaultActivityPermission);
            return;
          }
        }
      });
    });
  });

  return permissions;
};

export const getApplyPermissionForActivityType = ({
  activityType,
  isTeamEvent,
}: {
  activityType: ActivityType;
  isTeamEvent: boolean;
}) => {
  if (isTeamEvent) {
    return [MemberPermissions.ApplyToTeamEvent];
  }
  switch (activityType) {
    case ActivityType.Event:
      return [MemberPermissions.ApplyToEvent];
    case ActivityType.Action:
      return [MemberPermissions.ApplyToAction];
    case ActivityType.Ongoing:
      return [MemberPermissions.ApplyToOngoing];
    default:
      return [];
  }
};

export const getViewPermissionForActivityType = ({
  activityType,
  isTeamEvent,
}: {
  activityType: ActivityType;
  isTeamEvent: boolean;
}) => {
  if (isTeamEvent) {
    return [MemberPermissions.ViewTeamEvents];
  }
  switch (activityType) {
    case ActivityType.Event:
      return [MemberPermissions.ViewEvents];
    case ActivityType.Action:
      return [MemberPermissions.ViewAction];
    case ActivityType.Ongoing:
      return [MemberPermissions.ViewOngoingOpportunities];
    default:
      return [];
  }
};

export const getPublishPermissionForActivityType = ({
  activityType,
  isTeamEvent,
}: {
  activityType: ActivityType;
  isTeamEvent: boolean;
}) => {
  if (isTeamEvent) {
    return [PartnerActivityPermissions.PublishTeamEvents];
  }
  switch (activityType) {
    case ActivityType.Event:
      return [PartnerActivityPermissions.PublishEvents];
    case ActivityType.Action:
      return [PartnerActivityPermissions.PublishActions];
    case ActivityType.Ongoing:
      return [PartnerActivityPermissions.PublishOngoingOpportunities];
    default:
      return [];
  }
};

export const getAppPermissionsFromAppPermissionsGroups = ({
  appId,
  permissionGroupsByAppId,
}: {
  appId: string;
  permissionGroupsByAppId: Record<string, IAppPermissionGroupsAppPopulate[]>;
}) => {
  if (!permissionGroupsByAppId[appId]) return [];
  return permissionGroupsByAppId[appId].flatMap(
    (appPermissionGroups) => appPermissionGroups.permissions,
  );
};

export const getFilteredAppsListByPermissions = ({
  availableApps,
  availableAppPermissionGroupsByApp,
  permissionsToValidate,
}: {
  availableApps: string[];
  availableAppPermissionGroupsByApp: Record<
    string,
    IAppPermissionGroupsAppPopulate[]
  >;
  permissionsToValidate: Partial<IPermission>[];
}) => {
  const mappedApps: string[] = [];
  availableApps.forEach((app) => {
    const availableUserPermissionsForTheApp =
      getAppPermissionsFromAppPermissionsGroups({
        appId: app,
        permissionGroupsByAppId: availableAppPermissionGroupsByApp,
      });
    const hasPermissions = permissionsToValidate.some((requiredPermission) =>
      availableUserPermissionsForTheApp.find((availableUserPermissions) => {
        return availableUserPermissions.name === requiredPermission.name;
      }),
    );

    if (hasPermissions) {
      mappedApps.push(app);
    }
  });

  return mappedApps;
};

export const getPartnerPermissionsGroupsByAppId = (
  appPermissionGroups?: IAppPermissionGroupsAppPopulate[],
) => {
  if (!appPermissionGroups) return {};
  if (appPermissionGroups && appPermissionGroups.length === 0) return {};

  const userPermissionsByAppId = appPermissionGroups?.reduce(
    (record, permissionGroup) => {
      const hasPreviousRecord = record[permissionGroup.app._id];

      const previousRecordValue =
        hasPreviousRecord && Object.values(record[permissionGroup.app._id]);

      if (previousRecordValue) {
        record[permissionGroup.app._id] = [
          ...previousRecordValue,
          permissionGroup,
        ];
      } else {
        record[permissionGroup.app._id] = [permissionGroup];
      }

      return record;
    },
    {} as Record<string, IAppPermissionGroupsAppPopulate[]>,
  );
  return userPermissionsByAppId;
};
