import {
  Checkbox,
  Hidden,
  HStack,
  Stack,
  Text,
  useDisclose,
  VStack,
} from 'native-base';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import Button from 'ui/components/Button';
import Icon from 'ui/components/Icon';
import Illustration, { ILLUSTRATIONS_NAME } from 'ui/components/Illustration';
import Loading from 'ui/components/Loading';

import { BookingDateSelectCard } from '~/components/BookingDateSelectCard/BookingDateSelectCard';
import { transformActivityList } from '~/components/CardBookingSelection/CardBookingSelectionEvent/cardBookingSelectionEvent.controller';
import { queryClient } from '~/config/react-query.config';
import { PAGES } from '~/constants/pages.constants';
import { useAppSelector } from '~/hooks/useAppSelector';
import { useOpportunityApplicationCommonController } from '~/pages/Authenticated/OpportunityApplication/controllers/OpportunityApplicationCommonController';
import { ActivitiesService } from '~/services/resources/activities';
import { ActivityApplicationsService } from '~/services/resources/activity-applications';
import { IActivity } from '~/types/interfaces/activity';

const Header = () => (
  <VStack
    space={1}
    borderBottomColor={'singletons.black'}
    borderBottomWidth={{ base: 0, md: 2 }}
    marginLeft={{ base: 0, md: -4 }}
    w={{ base: 'unset', md: 'calc(100% + 30px)' }}
    p={{ base: 0, md: 4 }}
    flexDir={{ base: 'column', md: 'row' }}
    alignItems={{ base: 'unset', md: 'center' }}
  >
    <Hidden till={'md'}>
      <Stack mr={4}>
        <Icon size={32} icon={'check-circle'} />
      </Stack>
    </Hidden>
    <Stack w={'full'}>
      <Text fontSize={'xl'} fontWeight={500} w={'75%'}>
        Would you like to join this action?
      </Text>
      <Text fontSize={'sm'} color={'gray.600'} w={'90%'} mt={'2'}>
        Please take a moment to review your application carefully
      </Text>
    </Stack>
  </VStack>
);

interface ActivityDetailsProps {
  activity: Partial<IActivity>;
  teamName?: string;
}

const ActivityDetails = ({ activity, teamName }: ActivityDetailsProps) => {
  const [
    {
      day,
      month,
      bookingDateSelectDetails,
      activityId,
      activityTitle,
      appId,
      organizationName,
      organizationLogo,
      targetAmount,
      measurementUnitPluralLabel,
    },
  ] = transformActivityList([activity], false);

  const { availableAppsFromPermissionGroupsById } = useAppSelector(
    ({ auth }) => auth,
  );

  return (
    <VStack space={4}>
      <Text fontSize={'md'}>Activity details</Text>
      <BookingDateSelectCard
        organizationLogo={organizationLogo}
        activityId={activityId}
        day={day}
        month={month}
        bookingDateSelectDetails={bookingDateSelectDetails}
        hideCardSelectIcon
        hideActivitySummary={false}
        activityTitle={activityTitle}
        appName={
          availableAppsFromPermissionGroupsById?.[appId || '']?.name || ''
        }
        organizationName={organizationName}
        teamName={teamName}
        targetAmount={targetAmount}
        measurementUnitPluralLabel={measurementUnitPluralLabel}
        spotsRemaining={0}
      />
    </VStack>
  );
};

interface SubmitCheckboxProps {
  onToggleCheckBox: () => void;
}

const SubmitCheckbox = ({ onToggleCheckBox }: SubmitCheckboxProps) => {
  return (
    <Checkbox value={'terms'} onChange={onToggleCheckBox}>
      <Text fontSize={'xs'} ml={4}>
        By submitting an application to a volunteer role on our platform, I
        accept the transfer of my personal information to third parties.
      </Text>
    </Checkbox>
  );
};

interface ActionsFooterProps {
  isLoading: boolean;
  onPressPrimaryAction: () => void;
  onPressSecondaryAction: () => void;
  isTermsAccepted: boolean;
}

const ActionsFooter = ({
  isLoading,
  isTermsAccepted,
  onPressSecondaryAction,
  onPressPrimaryAction,
}: ActionsFooterProps) => {
  return (
    <VStack
      space={3}
      flexDir={{ base: 'column', md: 'row-reverse' }}
      px={{ base: 0, md: 6 }}
    >
      <Stack w={'full'}>
        <Button
          isLoading={isLoading}
          isDisabled={!isTermsAccepted}
          onPress={onPressPrimaryAction}
        >
          Yes, join action
        </Button>
      </Stack>
      <Stack w={'full'}>
        <Button variant={'ghost'} onPress={onPressSecondaryAction}>
          Cancel
        </Button>
      </Stack>
    </VStack>
  );
};

export const JoinActionPage = () => {
  const { actionId } = useParams();
  const { selectedUserProfile } = useAppSelector(({ auth }) => auth);
  const { isOpen: isTermsAccepted, onToggle: handleAcceptTermsToggle } =
    useDisclose();

  const navigate = useNavigate();

  const { data: activityData, isLoading } = useQuery({
    queryKey: [ActivitiesService.URL, { actionId }],
    queryFn: async ({ queryKey }) => {
      const [, params] = queryKey;
      const { actionId } = params as { actionId: string };

      return await ActivitiesService.findById(actionId);
    },
    enabled: !!actionId,
  });

  const { applicationAppId } = useOpportunityApplicationCommonController({
    activityDefinitionId: activityData?.activityDefinition,
  });

  const { mutate: applyToActivity, isLoading: applicationIsLoading } =
    useMutation({
      mutationFn: async () => {
        await ActivitiesService.applyToActivity(
          actionId as string,
          selectedUserProfile?._id as string,
          applicationAppId,
        );
      },
      onSuccess: async () => {
        await queryClient.refetchQueries({
          queryKey: [ActivityApplicationsService.BASE_PATH],
        });

        navigate(PAGES.OpportunityApplicationConfirmation, {
          state: { appId: applicationAppId },
        });
      },
    });

  const handleApplyToActivity = () => {
    applyToActivity();
  };

  const handleCancel = () => {
    navigate(-1);
  };

  if (isLoading) {
    return (
      <VStack
        py={6}
        px={4}
        bg={'singletons.white'}
        m={2}
        rounded={12}
        h={'100%'}
      >
        <Loading containerHeight={'100%'} />
      </VStack>
    );
  }

  if (!activityData) {
    navigate('/404', { replace: true });

    return null;
  }

  return (
    <VStack
      space={9}
      py={6}
      px={{ base: 4, sm: 6, md: 10, lg: 20 }}
      h={'full'}
      justifyContent={'space-between'}
    >
      <HStack h={'full'} justifyContent={'center'} space={{ base: 0, md: 24 }}>
        <Hidden till={'md'}>
          <Stack
            alignItems={'center'}
            minH={'612px'}
            justifyContent={'center'}
            minW={'10rem'}
            w="30vw"
          >
            <Illustration
              resizeMode={'contain'}
              minW={'10rem'}
              width={{ base: '20rem', lg: '30rem' }}
              height={{ base: '20rem', lg: '30rem' }}
              name={ILLUSTRATIONS_NAME.DATA_MANAGEMENT}
            />
          </Stack>
        </Hidden>
        <VStack
          h={{ base: '70vh', md: 'full' }}
          minH={{ base: '70vh', md: '612px' }}
          w={{ base: 'full', md: '40vw' }}
          py={{ base: 6, md: 0 }}
          px={4}
          bg={'singletons.white'}
          m={2}
          rounded={12}
          space={{ base: 6, md: 0 }}
          justifyContent={{ base: 'flex-start', md: 'space-between' }}
        >
          <VStack space={6}>
            <Header />
            <ActivityDetails activity={activityData} />
          </VStack>
          <VStack space={{ base: 0, md: 6 }} py={{ base: 0, md: 6 }}>
            <SubmitCheckbox onToggleCheckBox={handleAcceptTermsToggle} />
            <Hidden till={'md'}>
              <ActionsFooter
                isTermsAccepted={isTermsAccepted}
                isLoading={applicationIsLoading}
                onPressPrimaryAction={handleApplyToActivity}
                onPressSecondaryAction={handleCancel}
              />
            </Hidden>
          </VStack>
        </VStack>
      </HStack>
      <Hidden from={'md'}>
        <ActionsFooter
          isTermsAccepted={isTermsAccepted}
          isLoading={applicationIsLoading}
          onPressPrimaryAction={handleApplyToActivity}
          onPressSecondaryAction={handleCancel}
        />
      </Hidden>
    </VStack>
  );
};
