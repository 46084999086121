import { useRef, useState } from 'react';
import { DropDownImperativeRef } from 'ui/components/v2/DropDown/Base/types';

import { AppSwitcherOption, AppSwitcherProps } from './types';

export const useAppSwitcherController = (props: AppSwitcherProps) => {
  const { onChange, options, selected } = props;
  const dropDownImperativeRef = useRef<DropDownImperativeRef>(null);

  const [selectedOption, setSelectedOption] = useState(selected);

  // -- Handlers
  const handleOptionPress = (option: AppSwitcherOption | null) => {
    dropDownImperativeRef.current?.close();
    requestAnimationFrame(() => {
      setSelectedOption(option);
    });

    onChange?.(option);
  };

  return {
    options,
    selected,
    selectedOption,
    dropDownImperativeRef,
    handleOptionPress,
  };
};
