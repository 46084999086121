export const getAvatarText = (title: string): string => {
  if (!title) return '';
  const avatarTitle = title.trim().split(' ');
  let initials = '';

  if (avatarTitle.length > 0) {
    initials += avatarTitle[0][0].toUpperCase();

    if (avatarTitle.length > 1) {
      initials += avatarTitle[avatarTitle.length - 1][0].toUpperCase();
    }
  }

  return initials;
};
