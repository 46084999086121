import { CustomOptionsItem } from '~/components/MobileAppBar/components/CustomOptionsItem';
import { UserParsedData } from '~/components/NavBar/navBar.types';
import { MenuItem } from '~/components/Sidebar/sidebar.types';
import { PAGES } from '~/constants/pages.constants';
import { MyWalletMenuPermissions } from '~/constants/volunteer.permissions.constants';

export const getAppBarItems = ({
  userParsedData,
  customHomeItem,
}: {
  userParsedData?: UserParsedData;
  customHomeItem?: MenuItem;
}): MenuItem[] => {
  const result = [];

  if (customHomeItem) {
    result.push(customHomeItem);
  } else {
    result.push({
      pagePath: PAGES.AllApps,
      text: 'Apps',
      icon: 'grid',
      permissions: MyWalletMenuPermissions.Apps,
    });
  }

  result.push(
    ...[
      {
        pagePath: PAGES.Activities,
        text: 'Activities',
        icon: 'calendar',
        permissions: MyWalletMenuPermissions.Activities,
      },
      {
        text: 'Profile',
        icon: 'user',
        pagePath: PAGES.Profile,
        permissions: MyWalletMenuPermissions.Profile,
      },
      {
        pagePath: null,
        text: 'More',
        icon: 'more-horizontal',
        customRender: <CustomOptionsItem userParsedData={userParsedData} />,
        isDrawerTab: true,
        permissions: [],
      },
    ],
  );

  return result;
};
