import { useQuery } from '~/hooks/useQuery';
import { IActivityApplication } from '~/types/interfaces/activity';

export const useLoadManyActivityApplications = (
  filter: Partial<Record<keyof IActivityApplication | string, any>> = {},
  projection: Partial<Record<keyof IActivityApplication | string, any>> = {},
  populate: Array<Record<string, any>> = [],
  isEnabled = false,
  limit = 999,
) => {
  const { data, isLoading, isFetched } = useQuery<{
    data: IActivityApplication[];
  }>('activity-application', {
    key: `activity-application-${JSON.stringify(filter)}`,
    queryOptions: { enabled: isEnabled, cacheTime: 0 },
    requestOptions: {
      params: {
        limit,
        filter: JSON.stringify(filter),
        projection: JSON.stringify(projection),
        populate: JSON.stringify(populate),
      },
    },
  });

  return { isLoading, data: data?.data ?? [], isFetched };
};
