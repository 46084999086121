import {
  Box,
  Button,
  HStack,
  Image,
  Pressable,
  Text,
  VStack,
} from 'native-base';
import Icon from 'ui/components/Icon';
import { colors } from 'ui/theme/colors';

import { AlertBarProps } from '../types';

export interface BaseAlertBar extends AlertBarProps {
  maxWidth?: string | number;
  borderRadius?: string | number;
  showActionButton?: boolean;
  showCloseButton?: boolean;
}

export const BaseAlertBar = ({
  backgroundColor,
  actionLabel,
  logoSrc,
  children,
  onAction,
  showActionButton = false,
  showCloseButton = false,
  maxWidth = 'unset',
  borderRadius = 0,
}: BaseAlertBar) => {
  const handleActionPress = () => {
    onAction?.();
  };

  return (
    <Pressable
      flexDir={'column'}
      w="full"
      maxWidth={maxWidth}
      py={3}
      px={{ base: 2, md: 4 }}
      bgColor={backgroundColor}
      borderRadius={borderRadius}
      overflow={'hidden'}
      _hover={{ cursor: showActionButton ? 'default' : 'pointer' }}
      onPress={showActionButton ? undefined : handleActionPress}
    >
      <HStack
        w="full"
        maxW={'1400px'}
        margin={'auto'}
        alignItems={'center'}
        space={{ base: 2, md: 6 }}
        px={1}
      >
        <Image
          h={'20px'}
          w={'44px'}
          resizeMode="contain"
          alt={`App Logo`}
          source={{ uri: logoSrc || '' }}
        />

        <Box flex={1}>{children}</Box>

        {showActionButton && (
          <VStack ml={'auto'} mr={0}>
            <Button
              px={8}
              backgroundColor={'singletons.white'}
              onPress={handleActionPress}
            >
              <Text
                fontSize={'sm'}
                numberOfLines={1}
                color={'singletons.black'}
                fontWeight={500}
              >
                {actionLabel || 'Submit'}
              </Text>
            </Button>
          </VStack>
        )}

        {showCloseButton && (
          <VStack ml={'auto'} mr={0}>
            <Button
              px={2}
              minW={'unset'}
              variant={'ghost'}
              onPress={handleActionPress}
            >
              <Icon icon="x" size={'24px'} color={colors.singletons.white} />
            </Button>
          </VStack>
        )}
      </HStack>
    </Pressable>
  );
};
