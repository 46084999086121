import { HStack, Stack, Text } from 'native-base';
import React from 'react';
import Tag from 'ui/components/Tags/Tag';
import { CustomTagProps } from 'ui/components/Tags/Tag/types';
import { ActivityTypeEnum, EEventApplicationType } from 'ui/enums';

import { CardRecommendationsHeaderProps } from '~/components/ActivityCards/CardRecommendations/components/CardRecommendationsHeader/cardRecommendationsHeadear.types';
import { ActivitiesBreadCrumb } from '~/pages/Authenticated/ViewActivityDetails/components/ActivityBreadCrumb';

export const tagPropsDictionary = {
  [ActivityTypeEnum.Event]: {
    text: 'EVENT',
    tagTextColor: 'singletons.darkText',
    tagBgColor: 'lime.200',
    icon: 'calendar',
  },
  [EEventApplicationType.Team]: {
    text: 'TEAM EVENT',
    tagTextColor: 'singletons.darkText',
    tagBgColor: 'green.200',
    icon: 'users',
  },
  [ActivityTypeEnum.Action]: {
    text: 'ACTION',
    tagTextColor: 'singletons.darkText',
    tagBgColor: 'orange.300',
    icon: 'puzzle',
  },
  [ActivityTypeEnum.OngoingOpportunity]: {
    text: 'ONGOING',
    tagTextColor: 'singletons.darkText',
    tagBgColor: 'purple.200',
    icon: 'repeat',
  },
  [EEventApplicationType.Individual]: {},
};

export const CardRecommendationsHeader = ({
  activityType,
  isTeamEvent,
  headerText = '',
  headerTextColor = 'singletons.darkText',
  activityDefinition,
}: CardRecommendationsHeaderProps) => {
  return (
    <HStack
      alignItems={'center'}
      flexDir={'row'}
      space={3}
      h={'fit-content'}
      w={'fit-content'}
    >
      <Stack space={4}>
        {activityDefinition ? (
          <ActivitiesBreadCrumb activityDefinition={activityDefinition} />
        ) : null}
        <Tag
          tagProps={{ m: 0 }}
          showIcon
          showCustomIcon={activityType === ActivityTypeEnum.Action}
          {...(tagPropsDictionary[
            isTeamEvent ? EEventApplicationType.Team : activityType
          ] as CustomTagProps)}
          fontWeight={'400'}
        />
      </Stack>
      <Text fontSize={'xs'} color={headerTextColor}>
        {headerText}
      </Text>
    </HStack>
  );
};
