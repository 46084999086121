import { HStack, Text } from 'native-base';
import Button from 'ui/components/Button';

export type OffPlatformActivitiesFooterProps = {
  onClick: VoidFunction;
};

export const OffPlatformActivitiesFooter: React.FunctionComponent<
  OffPlatformActivitiesFooterProps
> = (props: OffPlatformActivitiesFooterProps) => {
  const { onClick } = props;
  return (
    <HStack zIndex={999} w="full" marginBottom={8}>
      <Button
        leftIconName={'wifi-off'}
        leftIconColor="white"
        leftIconSize={16}
        onPress={onClick}
        h={37}
        w="full"
      >
        <Text color="white" fontWeight={500}>
          New activity off-platform
        </Text>
      </Button>
    </HStack>
  );
};

export default OffPlatformActivitiesFooter;
