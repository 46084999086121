import { HStack, Stack, Text, VStack } from 'native-base';
import React from 'react';
import Button from 'ui/components/Button';
import Icon, { IconEnum } from 'ui/components/Icon';

import { CardRecommendationsInteractionsProps } from '~/components/ActivityCards/CardRecommendations/components/CardRecommendationsInteractions/cardRecommendationsInteractions.types';

export const CardRecommendationsInteractions = ({
  isOpen,
  primaryAction,
}: CardRecommendationsInteractionsProps) => {
  return (
    <VStack space={4}>
      <Stack>
        <HStack
          display={{ base: 'none', lg: 'flex' }}
          alignItems={'center'}
          flexDir={'row'}
          space={4}
        >
          <Button
            w={'container'}
            bg={'singletons.black'}
            onPress={primaryAction.onPress}
          >
            <Text color={'singletons.white'}>{primaryAction.text}</Text>
          </Button>

          <i className={`card-caret-arrow${isOpen ? '--open' : ''}`}>
            <Icon
              isCustom
              size={6}
              icon={IconEnum.CARET_DOWN}
              color={'black'}
            />
          </i>
        </HStack>
      </Stack>
      {/*  TODO: Add secondary action */}
    </VStack>
  );
};
