import { StylesConfig } from 'react-select';

import { getSelectBaseStyle } from '../../inputs/Select/BaseSelect/styles';
import { LocationSuggestion } from '../types';

export const defaultStyle = (props: {
  hasError: boolean;
}): StylesConfig<LocationSuggestion> => {
  const { hasError } = props;

  const commonSelectStyles: any = getSelectBaseStyle({ hasError });
  return {
    ...commonSelectStyles,
    control: (baseStyles, state) => ({
      ...commonSelectStyles.control(baseStyles, state),
      paddingLeft: 18,
    }),
  };
};
