import { HStack, Menu, Text, VStack } from 'native-base';
import { ReactNode, useRef } from 'react';

import { DropDownBase } from '../Base';
import { DropDownImperativeRef } from '../Base/types';

export interface DropDownMenuItem<T = any> {
  value: string;
  label: string;
  payload?: T;
}

export type DropDownMenuProps = {
  children?: ReactNode;
  items: DropDownMenuItem[];
  selected?: string;
  onItemPress?: (item?: DropDownMenuItem) => void;
};

export const DropDownMenu: React.FC<DropDownMenuProps> = (props) => {
  const { children, items, selected, onItemPress } = props;

  const dropDownImperativeRef = useRef<DropDownImperativeRef>(null);

  const handleItemPress = (item?: DropDownMenuItem) => {
    dropDownImperativeRef.current?.close();

    setTimeout(() => {
      onItemPress?.(item);
    }, 25);
  };

  return (
    <DropDownBase
      ref={dropDownImperativeRef}
      overlayContent={
        <VStack py={2} minW={'304px'}>
          {items?.map((item) => {
            return (
              <Menu.Item
                w="full"
                p={0}
                key={item.value}
                isFocused={item.value === selected}
                onPress={() => handleItemPress(item)}
              >
                <HStack p={3}>
                  <Text color={'gray.800'} fontSize={'sm'} fontWeight={400}>
                    {item.label}
                  </Text>
                </HStack>
              </Menu.Item>
            );
          })}
        </VStack>
      }
    >
      {children}
    </DropDownBase>
  );
};

export default DropDownMenu;
