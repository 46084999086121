import { ValidateAllPermisisons } from '~/components/Permissions/ValidateAll';

import { ValidateAppPermissions } from './ValidateApp';
/**
 * @description
 * Local doit-volunteer wrapper for Permissions.Boolean component from packages/ui.
 * @remarks
 * This wrapper is used to avoid using the permissions redux state in the locations that are needed to validate permissions.
 * @variation ValidateAll - Used to validate all permissions available from ALL apps.
 * @variation ValidateApp - Used to validate permissions from only one app.
 * @description Common props:
 * @property disablePermissionsValidation - Used to disable the validation on the children
 * @property requiredPermissions - Required permissions to show the children.
 * @property fallbackComponent - In case you need to show something different for the user if they don't have the required
 * permission instead.
 * @description Specific props:
 * @property appIdToValidate (ValidateApp variant): The app that will be used to check if the user has permissions for this app.
 **/
export const Permissions = {
  ValidateAll: ValidateAllPermisisons,
  ValidateApp: ValidateAppPermissions,
};
