import Yup from '~/utils/validations/yup';

import { IAccessCodeFormFields } from './types';

export const defaultValues: IAccessCodeFormFields = {
  accessCode: '',
};

export const schemaValidation = Yup.object({
  accessCode: Yup.string().required(),
});
