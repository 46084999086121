import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { add as dateAdd } from 'date-fns';
import { ActivityRegion } from 'ui/types/activities';
import { ILocation } from 'ui/types/interfaces';

import { SortByType } from '~/pages/Authenticated/InApp/SearchView/types';
import { ExecutionPlatform, InAppState } from '~/store/slices/inApp/types';
import { ESliceNames } from '~/store/types';
import { App } from '~/types/interfaces/app';

export const DEFAULT_LOCATON = {
  lat: 51.5074,
  lng: -0.1278,
  placeID: 'ChIJdd4hrwug2EcRmSrV3Vo6llI',
  rawLocation: 'London',
};
const today = new Date();
const futureDate = dateAdd(today, { months: 3 });

const initialState: InAppState = {
  selectedApp: undefined,
  platform: ExecutionPlatform.BROWSER,
  location: null,
  searchFilters: {
    search: '',
    sortBy: SortByType.NEAREST,
    startDate: today,
    endDate: futureDate,
    distanceRadius: 100, // miles
    causes: [],
    countries: [],
    regions: [],
    showOnline: false,
    teamsNumber: 0,
    teamsMinSize: 0,
    teamsMaxSize: 10,
  },
  isLoading: false,
  showLocationModal: false,
  nationOptions: [],
  regionOptions: [],
};

export const inAppSlice = createSlice({
  name: ESliceNames.IN_APP,
  initialState,
  reducers: {
    selectApp: (state, { payload }: PayloadAction<{ app?: App }>) => {
      return {
        ...state,
        selectedApp: payload.app,
      };
    },
    clear: () => ({ ...initialState }),
    clearSearchFilters: (state) => {
      const newSearchFilters = {
        ...initialState.searchFilters,
      };

      return {
        ...state,
        searchFilters: newSearchFilters,
      };
    },
    setSearchFilters: (
      state,
      { payload }: PayloadAction<Record<string, unknown>>,
    ) => {
      return {
        ...state,
        searchFilters: { ...state.searchFilters, ...payload },
      };
    },
    setShowLocationModal: (state, { payload }: PayloadAction<boolean>) => {
      return {
        ...state,
        showLocationModal: payload,
      };
    },
    setRegionOptions: (
      state,
      {
        payload,
      }: PayloadAction<{
        nationOptions: ActivityRegion[];
        regionOptions: ActivityRegion[];
      }>,
    ) => {
      return {
        ...state,
        nationOptions: payload.nationOptions,
        regionOptions: payload.regionOptions,
      };
    },
    setIsLoading: (state, { payload }: PayloadAction<boolean>) => {
      return {
        ...state,
        isLoading: payload,
      };
    },
    setPlatform: (state, { payload }: PayloadAction<ExecutionPlatform>) => {
      return { ...state, platform: payload };
    },
    setLocation: (state, { payload }: PayloadAction<ILocation | null>) => {
      return { ...state, location: payload };
    },
  },
});

export const { reducer: inAppSliceReducer, actions: inAppSliceActions } =
  inAppSlice;
