import { PostHog } from 'posthog-js';

import { initializePostHog } from '~/config/posthog';
import { FeatureFlagKey, IFeatureFlag } from '~/types/interfaces/feature-flag';

class PostHogFeatureFlagService implements IFeatureFlag {
  private client: PostHog | undefined;

  constructor() {
    this.client = initializePostHog();
  }

  onFeatureFlags(callback: VoidFunction): void {
    if (!this.client) return;
    this.client?.onFeatureFlags(callback);
  }

  isFeatureEnabled(key: FeatureFlagKey): boolean {
    if (!this.client) return false;
    return this.client.isFeatureEnabled(key) || false;
  }
}

export default PostHogFeatureFlagService;
