import { VStack } from 'native-base';

import CommonInputWrapper from '../../../CommonInputWrapper';
import { ISelectOptionsItem } from '../types';
import CustomSelect from './components/CustomSelect';
import { useBaseSelectGroupController } from './controller';
import { IBaseSelectGroupProps } from './types';

export const BaseSelectGroup = (props: IBaseSelectGroupProps) => {
  const { label, helpText, errorMessage, isRequired = false } = props;

  const {
    values,
    stagedOption,
    handleStagedOptionChange,
    handleOptionChange,
    handleOptionDelete,
    availableOptions,
  } = useBaseSelectGroupController(props);

  return (
    <CommonInputWrapper
      isRequired={isRequired}
      label={label}
      errorMessage={errorMessage}
      helpText={helpText}
    >
      <VStack width={'full'} space="2">
        {values.map((value: ISelectOptionsItem, index: number) => {
          return (
            <CustomSelect
              key={index}
              value={value}
              options={availableOptions.options}
              variant="delete"
              onAction={() => handleOptionDelete(index)}
              onChange={(newValue) => handleOptionChange(index, newValue)}
            />
          );
        })}

        {availableOptions.count > 0 && (
          <CustomSelect
            variant="add"
            options={availableOptions.options}
            value={stagedOption}
            onChange={handleStagedOptionChange}
            showAction={false}
          />
        )}
      </VStack>
    </CommonInputWrapper>
  );
};

export default BaseSelectGroup;
