import { ColorType } from 'native-base/lib/typescript/components/types';

export enum IconEnum {
  PUZZLE = 'puzzle',
  PUZZLE_ACTIVE = 'puzzle-active',
  PUZZLE_TERTIARY = 'puzzle-tertiary',
  CSV_SHEET = 'csv-sheet',
  CALENDAR = 'calendar',
  REPEAT = 'repeat',
  WHATSAPP = 'whatsapp',
  BLOCKS = 'blocks',
  TAG = 'tag',
  AWARD = 'award',
  SPARKLES = 'sparkles',
  USER_CLOCK = 'user-clock',
  TARGET_AND_ARROW = 'target_and_arrow',
  CARET_DOWN = 'caret-down',
  CARET_UP = 'caret-up',
  USERS = 'users',
  USER = 'user',
  CLOCK_ARROW = 'clock-arrow',
  FILE_INPURT = 'file-import',
  FOLDER_ARROW_UP = 'folder-arrow-up',
  HAND_POINTER = 'hand-pointer',
  SWITCH = 'switch',
  QUESTION = 'question',
  HOUSE_HEART = 'house-heart',
}

export interface ICustomIcon {
  [key: string]: string;
}

export interface IIcon {
  icon?: IconEnum | string;
  color?: ColorType;
  size: number | string;
  isCustom?: boolean;
  enableAutoCustom?: boolean;
  alt?: string;
}
