import { VStack } from 'native-base';
import { ReactNode, useEffect } from 'react';
import Loading from 'ui/components/v2/Loading';

import { PAGES } from '~/constants/pages.constants';
import { useRouter } from '~/hooks/useRouter';
import { IEcosystem } from '~/types/interfaces/ecosystem';

/* Ensure thaT all ROUTES INSIDE OF IT WILL HAVE A ECOSYSTEM SELECTED */
export const SuspenseEcosystemLayout = ({
  children,
  selectedEcosystem,
  isEcosystemLoading,
}: {
  children: ReactNode;
  selectedEcosystem?: IEcosystem;
  isEcosystemLoading?: boolean;
}) => {
  const { replaceRoute } = useRouter();

  useEffect(() => {
    if (!isEcosystemLoading && !selectedEcosystem) {
      replaceRoute(PAGES.SwitchPersona);
    }
  }, [replaceRoute, isEcosystemLoading, selectedEcosystem]);

  if (isEcosystemLoading && !selectedEcosystem)
    return <Loading height={'100vh'} width={'100vw'} showSpinner />;

  return <VStack borderColor={'red'}>{children}</VStack>;
};
