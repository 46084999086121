import { HomeWidgetBase } from './Base';
import { HomeWidgetFeaturedActivity } from './FeaturedActivity';
import { HomeWidgetProfileProgress } from './ProfileProgress';
import { HomeWidgetVolunteeringHours } from './VolunteeringHours';
import { HomeWidgetYourApps } from './YourApps';

export const HomeWidget = {
  Base: HomeWidgetBase,
  YourApps: HomeWidgetYourApps,
  ProfileProgress: HomeWidgetProfileProgress,
  FeaturedActivity: HomeWidgetFeaturedActivity,
  VolunteeringHours: HomeWidgetVolunteeringHours,
};
