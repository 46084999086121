import { useMemo } from 'react';

import { PAGES } from '~/constants/pages.constants';
import { useLoadFeaturedActivityDefinitionByEcosystem } from '~/hooks/featured-activity-definition/useLoadFeaturedActivityDefinitionByEcosystem';
import { useAppSelector } from '~/hooks/useAppSelector';
import { useRouter } from '~/hooks/useRouter';
import {
  IHomeWidgetController,
  IHomeWidgetControllerResult,
} from '~/pages/Authenticated/v2/Home/components/Widget/types';

import { FeaturedActivityInfoData } from './Snippets/InfoSnippet';

export type HomeWidgetFeaturedActivityController =
  IHomeWidgetControllerResult<FeaturedActivityInfoData | null>;

export const useHomeWidgetFeaturedActivityController: IHomeWidgetController<
  HomeWidgetFeaturedActivityController
> = () => {
  const { goToRoute } = useRouter();
  const { selectedEcosystem } = useAppSelector((state) => state.ecosystem);
  const { data: featuredActivityDefinition, isLoading } =
    useLoadFeaturedActivityDefinitionByEcosystem(selectedEcosystem?._id);

  const featuredActivityInfo = useMemo(() => {
    if (!featuredActivityDefinition) return null;

    const { activityDefinitionSubDocument } = featuredActivityDefinition;
    const { title, organizationSubDocument } = activityDefinitionSubDocument;

    return {
      activityTitle: title || '',
      organizationName: organizationSubDocument?.name || '',
      organizationLogoUrl: organizationSubDocument?.logoThumbnail || '',
    };
  }, [featuredActivityDefinition]);

  const handleGoToFeaturedActivityDetails = () => {
    const activityDefinitionId = featuredActivityDefinition?.activityDefinition;
    if (!activityDefinitionId) return;

    const url = PAGES.ActivitiesDetails.replace(':id', activityDefinitionId);
    goToRoute(url);
  };

  const preventRender = !isLoading && !featuredActivityInfo;

  return {
    isLoading,
    preventRender,
    data: featuredActivityInfo,
    onCardPress: handleGoToFeaturedActivityDetails,
  };
};
