import { format, isValid as isValidDate } from 'date-fns';

import { formattedTime } from './formatter';

type SpecialCases = {
  [key: number]: string;
};

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const weekdayNames = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

const specialCases: SpecialCases = {
  1: 'st',
  2: 'nd',
  3: 'rd',
  21: 'st',
  22: 'nd',
  23: 'rd',
  31: 'st',
};

const getDaySuffix = (day: number) => {
  return specialCases[day] || 'th';
};

export const getDateMonthName = (date: Date, monthFormat = 'long') => {
  const monthIndex = date.getMonth();
  return monthFormat === 'long'
    ? monthNames[monthIndex]
    : monthNames[monthIndex].slice(0, 3);
};

export const formatDateUtil = (date: Date, monthFormat = 'long') => {
  const day = date.getDate();
  const daySuffix = getDaySuffix(day);
  const monthName = getDateMonthName(date, monthFormat);

  return `${monthName} ${day}${daySuffix}`;
};

export const formatTimeUtil = (date: Date) => {
  const hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const suffix = hours < 12 ? 'AM' : 'PM';
  const formattedHours = (hours % 12 || 12).toString();

  return `${formattedHours}:${minutes} ${suffix}`;
};

export const formatRangeHours = (startDate: string, endDate: string) => {
  const startFormatted = formattedTime(new Date(startDate), 'hh:mm a');
  const endFormatted = endDate
    ? formattedTime(new Date(endDate), 'hh:mm a')
    : '';

  return endDate ? `${startFormatted} - ${endFormatted}` : startFormatted;
};

export const formatDateWeekday = (date: Date) => {
  const weekday = weekdayNames[date.getDay()];
  const day = date.getDate();
  const monthName = getDateMonthName(date, 'long');
  const year = date.getFullYear();

  return `${weekday}, ${day} ${monthName} ${year}`;
};

export const formatDateToEnGbLocale = (date: Date) => {
  return new Date(date).toLocaleDateString('en-GB', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  });
};

export const DateFormatter = (entryDate?: Date | string) => {
  const instance = {
    getDay: () => '',
    getMonth: () => '',
    getWeekDay: () => '',
    getHours: () => '',
  };

  const date = entryDate ? new Date(entryDate) : null;
  if (!date || !isValidDate(date)) return instance;

  instance.getDay = () => String(date.getDate()).padStart(2, '0');
  instance.getMonth = () => getDateMonthName(date, 'short').toUpperCase();
  instance.getWeekDay = () => formatDateWeekday(date);
  instance.getHours = () => formatTimeUtil(date);

  return instance;
};

export const formatDateToTimeCountDown = (date: Date) => {
  const now = new Date().getTime();
  const dateTime = date.getTime();
  const difference = (now - dateTime) / 1000;

  if (difference < 0) return '';

  if (difference < 60) return 'Now';

  if (difference < 3600) {
    const minutes = Math.floor(difference / 60);
    return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
  }

  if (difference < 86400) {
    const hours = Math.floor(difference / 3600);
    return `${hours} hour${hours !== 1 ? 's' : ''} ago`;
  }

  return format(date, 'dd MMM h:mmaaa');
};

export const formatDate = (date: Date, formatPattern: string) => {
  return format(date, formatPattern);
};

export function getDayOfWeekFromNumber(num: number) {
  return weekdayNames[num - 1] || null;
}

/* NOTE:  This should be the default date formatter */
export const safeDateFormat = (
  dateInput?: Date | string | null,
  formatPattern = 'dd MMMM yyyy',
) => {
  if (!dateInput) return '';

  const date = new Date(dateInput);
  if (!isValidDate(date)) return '';

  return formatDate(date, formatPattern);
};
