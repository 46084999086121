import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ESliceNames } from '~/store/types';

import { MeasurementsDataState } from './types';

const initialState: MeasurementsDataState = {
  measurementsData: undefined,
};

export const measurementsDataSlice = createSlice({
  name: ESliceNames.MEASUREMENTS_DATA,
  initialState,
  reducers: {
    setMeasurementsData: (
      state,
      { payload }: PayloadAction<MeasurementsDataState>,
    ) => {
      state.measurementsData = payload as any;
      return state;
    },
  },
});

export const {
  reducer: measurementsDataSliceReducer,
  actions: measurementsDataSliceActions,
} = measurementsDataSlice;
