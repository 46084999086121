import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { doitVolunteersEcosystem } from '~/constants/ecosystem.constants';
import { EcosystemState } from '~/store/slices/ecosystem/types';
import { ESliceNames } from '~/store/types';
import { IEcosystem } from '~/types/interfaces/ecosystem';

const initialState: EcosystemState = {
  selectedEcosystem: undefined,
  signInEcosystem: undefined,
  isDefaultSignInEcosystem: true,
  guestEcosystem: doitVolunteersEcosystem,
};

export const ecosystemSlice = createSlice({
  name: ESliceNames.ECOSYSTEM,
  initialState,
  reducers: {
    selectEcosystem: (
      state,
      {
        payload,
      }: PayloadAction<{ ecosystem: IEcosystem; isDefaultEcosystem: boolean }>,
    ) => {
      return {
        ...state,
        selectedEcosystem: payload.ecosystem,
        isDefaultSignInEcosystem: payload.isDefaultEcosystem,
        signInEcosystem: undefined,
      };
    },
    resetEcosystem: (state) => {
      return {
        ...state,

        selectedEcosystem: initialState.selectedEcosystem,
        isDefaultSignInEcosystem: initialState.isDefaultSignInEcosystem,
      };
    },
    setSignInEcosystem: (
      state,
      {
        payload,
      }: PayloadAction<{ ecosystem: IEcosystem; isDefaultEcosystem: boolean }>,
    ) => {
      return {
        ...state,

        signInEcosystem: payload.ecosystem,
        isDefaultSignInEcosystem: payload.isDefaultEcosystem,
      };
    },
    clearSignInEcosystem: (state) => {
      return {
        ...state,

        signInEcosystem: initialState.signInEcosystem,
        isDefaultSignInEcosystem: initialState.isDefaultSignInEcosystem,
      };
    },
  },
});

export const {
  reducer: ecosystemSliceReducer,
  actions: ecosystemSliceActions,
} = ecosystemSlice;
