import { HStack, Pressable, Text } from 'native-base';
import React from 'react';
import Icon from 'ui/components/Icon';

interface LogProgressAndHistoryCardContentFooterProps {
  onPressEdit: () => void;
  isStep?: boolean;
}

// This component maybe should be adjusted as we develop,
// For the design proposed in the Figma, this first well.
export const LogProgressAndHistoryCardContentFooter = ({
  onPressEdit,
  isStep,
}: LogProgressAndHistoryCardContentFooterProps) => {
  return (
    <HStack p={2} space={2} justifyContent={'flex-end'} alignItems={'center'}>
      {isStep ? null : (
        <Pressable w={'container'} flexDir={'row'} onPress={onPressEdit}>
          <Icon size={16} icon={'edit'} color={'black'} />
          <Text fontWeight={500} fontSize={'xs'} pl={3}>
            Edit
          </Text>
        </Pressable>
      )}
    </HStack>
  );
};
