import { isValid as isValidDate } from 'date-fns';
import { useDisclose } from 'native-base';
import { useMemo } from 'react';
import {
  formatDateWeekday,
  formatTimeUtil,
  getDateMonthName,
} from 'ui/utils/formatDates';

import { ActivityDateSelectorProps } from '~/pages/Authenticated/v2/ActivityView/components/ActivitySelectors/ActivityDateSelector/types';
import { AttributeLabel } from '~/pages/Authenticated/v2/ActivityView/components/ActivitySelectors/ActivitySelectorCardBase/types';
import { padNumberWithZeros } from '~/utils/formatNumber';

export const useActivityDateSelectorController = (
  props: ActivityDateSelectorProps,
) => {
  const {
    availableSpotsCount,
    availableDates,
    blockedDates,
    selectedDate,
    onDateChange,
    onOpen: customOnOpen,
    showAvailableSpots = true,
  } = props;

  const { isOpen, onClose, onOpen } = useDisclose();

  // -- Hooks
  const formattedDate = useMemo(() => {
    const labels = { day: '', month: '', weekDay: '', hours: '' };
    if (selectedDate && isValidDate(selectedDate)) {
      labels.day = String(selectedDate.getDate()).padStart(2, '0');
      labels.month = getDateMonthName(selectedDate, 'short').toUpperCase();
      labels.weekDay = formatDateWeekday(selectedDate);
      labels.hours = formatTimeUtil(selectedDate);
    }
    return labels;
  }, [selectedDate]);

  // -- Handlers
  const handleCalendarChange = (newDate: Date) => {
    onClose();
    onDateChange(newDate);
  };

  // -- Render Statements
  const hideSelector = availableDates.length <= 1;

  // -- Computed values
  const datesTotal = availableDates.length;
  const tagLabel = datesTotal > 1 ? `+ ${availableDates.length} dates` : '';

  const cardExtraLabels: AttributeLabel[] = useMemo(() => {
    if (!showAvailableSpots) return [];

    let spotsStr =
      availableSpotsCount === 0 ? 'No spots available' : 'Unlimited spots';

    if (!!availableSpotsCount) {
      spotsStr = `${padNumberWithZeros(availableSpotsCount)} spots available`;
    }

    return [{ key: 'spots', text: spotsStr, icon: 'user-check' }];
  }, [availableSpotsCount, showAvailableSpots]);

  return {
    isOpen,
    tagLabel,
    availableDates,
    blockedDates,
    formattedDate,
    hideSelector,
    cardExtraLabels,
    onClose,
    onOpen: customOnOpen || onOpen,
    handleCalendarChange,
  };
};
