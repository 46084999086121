import { sanitizeQueryParams } from 'ui/utils/queryParams';

import { publicApi } from '~/services/api';
import { ACTIVITY_DEFINITION_SEARCH_MODULE_URL } from '~/services/resources/activity-definitions/search/constants';
import { ActivityDefinitionPublicSearchResponse } from '~/services/resources/activity-definitions/search/types';

import {
  OngoingFromHomeSearchFilters,
  OngoingInPersonSearchFilters,
} from './ongoing-public-search.types';

export class ActivityDefinitionOngoingPublicSearch {
  static BASE_URL = `${ACTIVITY_DEFINITION_SEARCH_MODULE_URL}/ongoing-opportunity`;

  static async findInPersonOngoing(
    filters: OngoingInPersonSearchFilters,
  ): Promise<ActivityDefinitionPublicSearchResponse[]> {
    try {
      const url = `${ActivityDefinitionOngoingPublicSearch.BASE_URL}/in-person`;

      const queryParams = sanitizeQueryParams(filters as Record<string, any>);
      const { data } = await publicApi.get(url, {
        params: queryParams,
      });

      return data || [];
    } catch (error) {
      return [];
    }
  }

  static async findFromHomeOngoing(
    filters: OngoingFromHomeSearchFilters,
  ): Promise<ActivityDefinitionPublicSearchResponse[]> {
    try {
      const url = `${ActivityDefinitionOngoingPublicSearch.BASE_URL}/from-home`;
      const queryParams = sanitizeQueryParams(filters as Record<string, any>);
      const { data } = await publicApi.get(url, {
        params: queryParams,
      });
      return data || [];
    } catch (error) {
      return [];
    }
  }
}
