import { VolunteeringHoursSnippets } from '~/pages/Authenticated/v2/Home/components/Widget/VolunteeringHours/Snippets';
import { VolunteeringHoursInfoData } from '~/pages/Authenticated/v2/Home/components/Widget/VolunteeringHours/Snippets/InfoSnippet';

export const VolunteeringHoursTabsFactory = ({
  data,
  onPress,
}: {
  data?: VolunteeringHoursInfoData | null;
  onPress?: VoidFunction;
}) => {
  const tabs = [
    <VolunteeringHoursSnippets.PlaceHolder key={1} onPress={onPress} />,
  ];
  if (data) {
    tabs.push(
      <VolunteeringHoursSnippets.Info key={2} onPress={onPress} data={data} />,
    );
  }
  return tabs;
};
