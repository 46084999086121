import debounce from 'debounce-promise';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ActivityApplicationEnum } from 'ui/enums';

import { GenericObject } from '~/hooks/types';
import { useAppSelector } from '~/hooks/useAppSelector';
import useInfinityActivityApplication from '~/hooks/useInfinityActivityApplication';
import { logActivitiesContentPageTabs } from '~/pages/Authenticated/LogActivities/constants';
import { SearchFormValues } from '~/pages/Authenticated/LogActivities/types';
import { useLogOffPlatformActivitiesController } from '~/pages/Authenticated/LogActivities/useLogOffPlatformActivitiesController';

const getInfinityActivityApplicationQueryParams = (
  tabIndex: number,
  appIds: string[],
  userProfileId: string,
  search?: string,
) => {
  const params: GenericObject = {
    filter: {
      app: {
        $in: appIds,
      },
      $or: [
        { status: { $ne: ActivityApplicationEnum.Canceled } },
        {
          $and: [
            { status: ActivityApplicationEnum.Canceled },
            { measurements: { $exists: true, $not: { $size: 0 } } },
          ],
        },
      ],
      userProfile: userProfileId,
    },
    sort: { updatedAt: 'desc' },
    populate: [
      {
        model: 'App',
        path: 'app',
        select: ['_id', 'name'],
      },
      {
        model: 'Measurement',
        path: 'measurements',
        populate: {
          model: 'MeasurementUnits',
          path: 'measurementUnit',
        },
      },
      {
        model: 'Organization',
        path: 'activitySubDocument.organization',
        select: ['_id', 'logoThumbnail'],
      },
    ],
  };

  if (search && tabIndex === 0) {
    params.filter = {
      ...(params.filter as GenericObject),
      'activitySubDocument.activityDefinitionSubDocument.title': {
        $regex: search,
        $options: 'i',
      },
    };
  }
  return params;
};

export const useLogActivitiesController = () => {
  // Providers
  const { selectedUserProfile, availableAppsFromPermissionGroupsById } =
    useAppSelector(({ auth }) => auth);

  const offPlatformController = useLogOffPlatformActivitiesController();

  // States
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [search, setSearch] = useState<{ text: string }>({ text: '' });
  const { control, watch } = useForm<SearchFormValues>();

  // Hooks
  const activityApplicationQueryParams = useMemo(
    () =>
      getInfinityActivityApplicationQueryParams(
        currentTabIndex,
        Object.keys(availableAppsFromPermissionGroupsById || {}).map(
          (app) => app,
        ),
        selectedUserProfile?._id || '',
        search.text,
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [search, selectedUserProfile, availableAppsFromPermissionGroupsById],
  );

  const {
    data,
    isLoading,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    isRefetching,
    refetch: refetchActivityApplication,
  } = useInfinityActivityApplication(activityApplicationQueryParams, false);

  const activityApplications = useMemo(
    () => data?.pages?.flatMap((page) => page.responseData?.data) ?? [],
    [data],
  );

  const totalActivitiesToDo = data?.pages[0]?.responseData?.count || 0;

  useEffect(() => {
    const debouncedCb = debounce((formValues) => {
      setSearch(formValues as any);
    }, 900);
    const subscription = watch(debouncedCb);
    return () => subscription.unsubscribe();
  }, [watch]);

  const tabs = useMemo(() => {
    return logActivitiesContentPageTabs(
      totalActivitiesToDo,
      offPlatformController.totalOffPlatformActivities,
    );
  }, [totalActivitiesToDo, offPlatformController]);

  // Handlers
  const handleOnActivityApplicationScrollEndReached = async () => {
    if (!isRefetching && !isFetchingNextPage && hasNextPage) {
      await fetchNextPage();
    }
  };

  const handleOnScrollEndReached = () => {
    if (currentTabIndex === 1) {
      offPlatformController.handleOnScrollEndReached();
      return;
    }
    handleOnActivityApplicationScrollEndReached();
  };

  const handleChangeTab = (tabIndex: number) => {
    setCurrentTabIndex(tabIndex);
  };

  // Render statement
  const shouldRenderSkeleton =
    isLoading ||
    isFetchingNextPage ||
    offPlatformController.isLoading ||
    offPlatformController.isFetchingNextPage;

  const shoudlRenderNoMoreActivities =
    (activityApplications ?? []).length > 0 && !hasNextPage;

  return {
    currentTabIndex,
    isLoading,
    activityApplications,
    control,
    tabs,
    handleChangeTab,
    handleOnScrollEndReached,
    refetchActivityApplication,
    offPlatformController,
    shouldRenderSkeleton,
    shoudlRenderNoMoreActivities,
  };
};
