import { ActivitySemanticType } from 'ui/types/activities';

import { ActivityFeedProps } from '~/pages/Authenticated/v2/ActivityView/components/ActivityFeed/type';
import { getActivityDefinitionSemanticType } from '~/utils/activity/activity-definition-getters';

export const useActivityFeedController = (props: ActivityFeedProps) => {
  const { activityDefinition, selectedActivity } = props;

  // -- Computed
  const activityType = getActivityDefinitionSemanticType(activityDefinition);
  const volunteerRewards = activityDefinition.volunteerRewards ?? [];
  const volunteerRequirements = activityDefinition.volunteerRequirements ?? [];
  const requirementOptions = (activityDefinition.requirementOptions ?? []).map(
    (req) => (req as any)?.displayName || '',
  );

  // -- Render statements
  const wasExternallyApplied =
    selectedActivity?.externalApplyLink && selectedActivity.userApplication;

  const hasVolunteerRequirements = activityType !== ActivitySemanticType.Action;

  return {
    activityType,
    requirementOptions,
    volunteerRewards,
    volunteerRequirements,
    wasExternallyApplied,
    hasVolunteerRequirements,
  };
};
