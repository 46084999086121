import { VStack } from 'native-base';
import Icon from 'ui/components/Icon';
import { colors } from 'ui/theme/colors';

import { InfoLocationTrailingProps } from '~/pages/Authenticated/v2/ActivityView/components/ActivityInfoCard/components/InfoLocationTrailing/types';

export const InfoLocationTrailing: React.FC<InfoLocationTrailingProps> = (
  props,
) => {
  const { icon = 'map-pin', color = colors.black } = props;
  return (
    <VStack height={'fit-content'} alignItems={'center'} pr={2}>
      <Icon icon={icon} size={'24px'} color={color} />
    </VStack>
  );
};
