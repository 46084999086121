import { HStack, Link, Text } from 'native-base';
import Icon from 'ui/components/Icon';
import { BHO_HELP_CENTER_URL } from 'ui/constants/commonUrl.constants';
import { colors } from 'ui/theme/colors';

export const HelpCentreLink = () => {
  return (
    <Link href={BHO_HELP_CENTER_URL} w="fit-content" isExternal>
      <HStack space={2} px={3} py={1}>
        <Icon icon="question" color={colors.black} size={6} enableAutoCustom />
        <Text fontSize={14} fontWeight={500} letterSpacing="150%">
          Help Centre
        </Text>
      </HStack>
    </Link>
  );
};
