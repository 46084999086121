import { Spinner, Stack } from 'native-base';

import { LoadingProps } from './types';

const Loading = ({
  containerHeight = '100vh',
  spinnerSize = 'lg',
  color,
}: LoadingProps) => {
  return (
    <Stack minH={containerHeight} justifyContent="center">
      <Spinner size={spinnerSize} color={color} />
    </Stack>
  );
};

export default Loading;
