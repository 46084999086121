import { EEventApplicationType } from 'ui/enums';
import { ActivitySemanticType, ActivityType } from 'ui/types/activities';
import { Coordinates } from 'ui/types/interfaces';
import {
  ActivityDefinition,
  SearchActivityDefinition,
} from 'ui/types/interfaces/activity-definition';
import { getActivitySummaryByScore } from 'ui/utils/activity/get-activity-symmary-by-score';

export function mapToActivitySemanticType(
  activityType: ActivityType,
  applicationType?: EEventApplicationType,
) {
  return applicationType === EEventApplicationType.Team
    ? ActivitySemanticType.TeamEvent
    : (activityType as unknown as ActivitySemanticType);
}

export function mapAttributesFromActivitySemanticType(
  semanticType: ActivitySemanticType,
): { activityType: ActivityType; applicationType: EEventApplicationType } {
  const isTeamEvent = semanticType == ActivitySemanticType.TeamEvent;

  const activityType = isTeamEvent ? ActivityType.Event : semanticType;

  const applicationType = isTeamEvent
    ? EEventApplicationType.Team
    : EEventApplicationType.Individual;

  return {
    activityType: activityType as unknown as ActivityType,
    applicationType,
  };
}

export const mapSearchActivityDefinition = (
  coordinates: Coordinates | null | undefined,
) => {
  return (
    data: Array<ActivityDefinition & { activitiesCount?: number }>,
  ): SearchActivityDefinition[] => {
    return data.map((activityDefinition: ActivityDefinition) => {
      const { activitiesSummary = [], type } = activityDefinition;

      const selectedActivity = getActivitySummaryByScore(
        activitiesSummary,
        type,
        coordinates,
      );

      return {
        ...activityDefinition,
        selectedActivity,
      } as SearchActivityDefinition;
    });
  };
};
