import { BrowserRouter } from 'react-router-dom';

import { useAppSelector } from '~/hooks/useAppSelector';
import { AuthenticatedRoutes } from '~/routes/Authenticated';
import { UnauthenticatedRoutes } from '~/routes/Unauthenticated';

export const Routes = () => {
  const { isAuthenticated } = useAppSelector(({ auth }) => auth);
  return (
    <BrowserRouter>
      {isAuthenticated ? <AuthenticatedRoutes /> : <UnauthenticatedRoutes />}
    </BrowserRouter>
  );
};
