import { useRef, useState } from 'react';

import { ActivitySelectorCardBaseProps } from '~/pages/Authenticated/v2/ActivityView/components/ActivitySelectors/ActivitySelectorCardBase/types';

/* 
  Native Base PopOver naturally manages the dropdown positioning. 
  However, after the build, it started exhibiting incorrect behaviors. 
  Therefore, we took on this responsibility and moved it to this controller. 
  It stores the 'body rect' coordinates of the Card (considering the scroll) 
  when the Card is clicked so that it can apply the necessary 
  translation transformations at the time of rendering.
*/

export const useActivitySelectorCardBaseController = (
  props: ActivitySelectorCardBaseProps,
) => {
  const { openPopover } = props;

  const triggerRef = useRef<HTMLDivElement | null>(null);
  const [popoverViewport, setPopoverViewport] = useState({
    top: 0,
    left: 0,
    width: 0,
  });

  const handleOpen = () => {
    if (triggerRef.current) {
      const rect = triggerRef.current.getBoundingClientRect();
      setPopoverViewport({
        top: rect.bottom + window.scrollY,
        left: rect.left + window.scrollX,
        width: rect.width,
      });
      openPopover();
    }
  };

  return { popoverViewport, handleOpen, triggerRef };
};
