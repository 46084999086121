import { VStack } from 'native-base';
import { useEffect, useState } from 'react';
import { Slider } from 'ui/components/v2/inputs/Slider';

import { SearchFilters } from '~/zustand/store/Search';

export type TeamSizeFilterSectionProps = {
  defaultTeamsMinSize: number;
  defaultTeamsMaxSize: number;
  onChange?: (newFilters: Partial<SearchFilters>) => void;
};

export const TeamSizeFilterSection = ({
  defaultTeamsMaxSize,
  defaultTeamsMinSize,
  onChange,
}: TeamSizeFilterSectionProps) => {
  const [value, setValue] = useState([
    defaultTeamsMinSize,
    defaultTeamsMaxSize,
  ]);

  useEffect(() => {
    setValue([defaultTeamsMinSize, defaultTeamsMaxSize]);
  }, [defaultTeamsMinSize, defaultTeamsMaxSize]);

  const handleSliderChange = (values: number[]) => {
    setValue(values);
  };

  const handleSliderChangeCompleted = (values: number[]) => {
    onChange?.({ teamsMinSize: values[0], teamsMaxSize: values[1] });
  };

  return (
    <VStack w="full" px={3} space={2} justifyContent={'space-between'}>
      <Slider.Multi
        unityLabel=""
        value={value}
        step={1}
        max={25}
        onChange={handleSliderChange}
        onChangeComplete={handleSliderChangeCompleted}
      />
    </VStack>
  );
};
