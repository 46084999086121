import { ActivitySemanticType } from 'ui/types/activities';

import { SearchRelevanceSortBy } from '~/services/resources/activity-definitions/search/types';
import { AttendanceType } from '~/types/interfaces/activity';
import {
  ActivitySearchCategory,
  ActivitySearchCategoryEnum,
} from '~/types/interfaces/activity-search/types';

export const DEFAULT_IN_APP_SEARCH_CATEGORIES = [
  new ActivitySearchCategory(
    ActivitySearchCategoryEnum.nearMyLocation,
    'Near my location',
    { iconName: 'map-pin' },
    {
      relevance: SearchRelevanceSortBy.Nearest,
      attendanceTypes: [AttendanceType.Local],
      distanceRadius: 50,
    },
  ),

  new ActivitySearchCategory(
    ActivitySearchCategoryEnum.remoteOpportunities,
    'Remote opportunities',
    { iconName: 'home' },
    {
      relevance: SearchRelevanceSortBy.Newest,
      attendanceTypes: [AttendanceType.Online],
    },
  ),

  new ActivitySearchCategory(
    ActivitySearchCategoryEnum.events,
    'Events',
    { iconName: 'calendar' },
    {
      relevance: SearchRelevanceSortBy.Newest,
      activityTypes: [ActivitySemanticType.Event],
      attendanceTypes: [AttendanceType.Local, AttendanceType.Online],
    },
  ),

  new ActivitySearchCategory(
    ActivitySearchCategoryEnum.actions,
    'Actions',
    { iconName: 'hand-pointer' },
    {
      relevance: SearchRelevanceSortBy.Newest,
      activityTypes: [ActivitySemanticType.Action],
      attendanceTypes: [AttendanceType.Online],
    },
  ),

  new ActivitySearchCategory(
    ActivitySearchCategoryEnum.teamEvents,
    'Team Events',
    { iconName: 'users' },
    {
      relevance: SearchRelevanceSortBy.Nearest,
      activityTypes: [ActivitySemanticType.TeamEvent],
      attendanceTypes: [AttendanceType.Local, AttendanceType.Online],
    },
  ),

  new ActivitySearchCategory(
    ActivitySearchCategoryEnum.ongoing,
    'Ongoing Opportunities',
    { iconName: 'repeat' },
    {
      relevance: SearchRelevanceSortBy.Newest,
      activityTypes: [ActivitySemanticType.Ongoing],
      attendanceTypes: [AttendanceType.Local, AttendanceType.Online],
    },
  ),
];
