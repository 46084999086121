import {
  Divider,
  Flex,
  HStack,
  Progress,
  Stack,
  Text,
  VStack,
} from 'native-base';
import { FiCheckSquare } from 'react-icons/fi';
import Button from 'ui/components/Button';
import Icon from 'ui/components/Icon';

import { authorizationList } from '~/pages/Unauthenticated/SignUp/AuthorizationPersonalData/constants';
import { colors } from '~/theme/colors';

import { AuthorizationPageProps } from '../types';

export const AuthorizationPage = ({
  onAllowButtonPress,
  onDenyButtonPress,
}: AuthorizationPageProps) => {
  return (
    <Stack
      width={{ base: '100%', lg: '50vw' }}
      minH="100%"
      justifyContent={{ base: 'initial' }}
      padding={{ base: 5, lg: '5rem' }}
      bgColor={colors.singletons.lightText}
      overflowY="auto"
    >
      <Stack
        flexShrink={0}
        bgColor="white"
        width={{ base: '100%', lg: '85%' }}
        borderRadius="2xl"
      >
        <Stack width="100%" p={4}>
          <HStack alignItems="center">
            <Icon icon="user-check" size={32} />
            <Stack ml={5} space={3}>
              <Text fontSize="2xl" fontWeight={500}>
                Authorization for the use of personal data
              </Text>
              <Text fontSize="md">
                Please review the following carefully before proceeding.
              </Text>
            </Stack>
          </HStack>
        </Stack>
        <Progress value={70} _filledTrack={{ bg: 'black' }} h={1} />
        <Stack width="100%" px={4} py={8}>
          <Text fontSize="sm" color="singletone.darkText">
            In order to provide you with our services, we need to access and
            utilize certain personal information from your profile, which
            includes:
          </Text>
          <Flex flexShrink={0} my={4}>
            {authorizationList.map((item) => (
              <VStack my={1} space={1} key={item.title}>
                <HStack
                  space={4}
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Stack>
                    <FiCheckSquare
                      color={colors.singletons.darkText}
                      size={16}
                    />
                  </Stack>
                  <Text fontWeight={500} w="100%">
                    {item.title}{' '}
                    {item?.description ? (
                      <Text fontWeight={300}>{item.description}</Text>
                    ) : null}
                  </Text>
                </HStack>
                <Divider />
              </VStack>
            ))}
          </Flex>
          <Text fontSize="sm" color="singletone.darkText">
            Rest assured, the Do IT platform is committed to handling this
            information in accordance with our terms of service and privacy
            policy. Your privacy and data security are our top priorities.
          </Text>
        </Stack>

        <Flex
          flexDir={{ base: 'column-reverse', lg: 'row' }}
          p={6}
          style={{ gap: 12 }}
        >
          <Button
            _text={{ color: 'error.600', fontWeight: 500 }}
            variant="ghost"
            maxWidth={{ base: '100%', lg: '49%' }}
            colorScheme="error"
            onPress={onDenyButtonPress}
          >
            Deny
          </Button>
          <Button
            _text={{ fontWeight: 500 }}
            maxWidth={{ base: '100%', lg: '49%' }}
            onPress={onAllowButtonPress}
          >
            Allow
          </Button>
        </Flex>
      </Stack>
    </Stack>
  );
};
