import { Box, Center, Text } from 'native-base';
import Button from 'ui/components/Button';
import Illustration, { ILLUSTRATIONS_NAME } from 'ui/components/Illustration';
import Loading from 'ui/components/Loading';

import { useAppSelector } from '~/hooks/useAppSelector';
import { useRouter } from '~/hooks/useRouter';

const Page404 = () => {
  const { acceptInvite } = useAppSelector(({ redirect }) => redirect);
  const { goToRoute } = useRouter();

  if (acceptInvite.isRedirecting) {
    return <Loading />;
  }

  return (
    <Center p={6} minH="100vh" bg="singletons.lightText">
      <Box alignItems="center" justifyContent="center">
        <Illustration
          name={ILLUSTRATIONS_NAME.PROBLEM_NO_CONNECTION_GREEN}
          width={208}
          height={186}
        />
        <Text
          fontSize="2xl"
          maxW={480}
          textAlign="center"
          mt={20}
          fontWeight={500}
          color="grey.900"
        >
          Ooops!
        </Text>
        <Text
          fontSize="MD"
          maxW={380}
          textAlign="center"
          mt={3}
          fontWeight={400}
          color="grey.900"
        >
          {`Sorry, we couldn't find the page you're looking for.`}
        </Text>

        <Button onPress={() => goToRoute('/')} mt={8}>
          Return to home page
        </Button>
      </Box>
    </Center>
  );
};

export default Page404;
