import { HStack, Pressable, Text, VStack } from 'native-base';
import Icon, { IconEnum } from 'ui/components/Icon';

import { LogItem } from '~/components/v2/LogCollapse/components/LogItem';
import { colors } from '~/theme/colors';

import { useLogCollapseController } from './controller';
import { ILogCollapseProps } from './types';

export const LogCollapse = (props: ILogCollapseProps) => {
  const {
    amountStr,
    measurements,
    showCollapsed,
    measurementIcon,
    showCollapsedTrigger,
    showEditButton,
    toggleCollapsedContent,
    handleLogAction,
    toggleEditAction,
  } = useLogCollapseController(props);

  return (
    <VStack
      borderWidth={1}
      bg={'gray.100'}
      w={'full'}
      borderColor={'gray.300'}
      borderRadius={'xl'}
      py={3}
      px={4}
    >
      <HStack w={'full'} justifyContent={'space-between'} space={4}>
        <HStack w={'full'} alignItems={'center'} space={2}>
          <Icon
            icon={measurementIcon}
            enableAutoCustom
            size={'20px'}
            color={colors.gray['600']}
          />
          <Text textTransform={'uppercase'} color={'gray.600'}>
            you&#39;ve done
          </Text>
          <Text fontSize={'lg'} fontWeight={'500'}>
            {amountStr}
          </Text>
        </HStack>

        {showCollapsedTrigger && (
          <Pressable onPress={toggleCollapsedContent}>
            <Icon
              isCustom
              size={6}
              icon={!showCollapsed ? IconEnum.CARET_DOWN : IconEnum.CARET_UP}
              color={'black'}
            />
          </Pressable>
        )}
      </HStack>

      {showCollapsed && (
        <VStack pt="3" space={2}>
          {measurements?.map((measurement) => {
            return (
              <LogItem
                key={measurement._id}
                measurement={measurement}
                showActionButtons={showEditButton}
                onAction={(a) => handleLogAction(a, measurement)}
              />
            );
          })}

          {!!measurements.length && props.onAction && (
            <HStack
              p={2}
              space={2}
              justifyContent={'flex-end'}
              alignItems={'center'}
            >
              <Pressable
                w={'container'}
                flexDir={'row'}
                onPress={toggleEditAction}
              >
                <Icon size={16} icon={'edit'} color={'black'} />
                <Text fontWeight={500} fontSize={'xs'} pl={3}>
                  Edit
                </Text>
              </Pressable>
            </HStack>
          )}
        </VStack>
      )}
    </VStack>
  );
};
