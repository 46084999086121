import {
  Box,
  HStack,
  Pressable,
  Stack,
  Text,
  useBreakpointValue,
  VStack,
} from 'native-base';
import { ReactNode } from 'react';
import Drawer from 'react-modern-drawer';
import Button from 'ui/components/Button';
import Icon from 'ui/components/Icon';
import { colors } from 'ui/theme/colors';

export type FilterDrawerScaffoldProps = {
  isOpen: boolean;
  title: string;
  onClose: VoidFunction;
  children: ReactNode;
  showActionButton?: boolean;
  actionButtonLabel?: string;
  cancelButtonLabel?: string;
  cancelButtonIcon?: string;
  isDisable?: boolean;
  brandColor?: string;
  disableColor?: string;
  onAction: VoidFunction;
  onCancel: VoidFunction;
};

export const FilterDrawerScaffold = (props: FilterDrawerScaffoldProps) => {
  const {
    title,
    isOpen = false,
    onClose,
    children,
    showActionButton = true,
    actionButtonLabel = 'Search',
    cancelButtonLabel = 'Cancel',
    cancelButtonIcon,
    brandColor = 'singletons.black',
    disableColor = colors.grey[200],
    onAction,
    onCancel,
    isDisable = false,
  } = props;

  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Drawer
      open={isOpen}
      onClose={onClose}
      direction="right"
      overlayColor={colors.black}
      overlayOpacity={0.3}
      duration={200}
      size={isMobile ? '100%' : '400px'}
      lockBackgroundScroll
      zIndex={999}
    >
      <Box h="full" overflowY={'hidden'} mt={2} backgroundColor={colors.white}>
        <VStack w="full" space={6}>
          {/* header */}
          <HStack alignItems={'center'} h="56px" overflow={'hidden'}>
            <Pressable p={2} onPress={onClose}>
              <HStack w="full" h={'full'} alignItems={'center'} space={2.5}>
                <Icon icon="chevron-left" size={16} />
                <Text fontSize={'xs'} fontWeight={500} color={colors.darkText}>
                  Back
                </Text>
              </HStack>
            </Pressable>

            <Stack
              position={'absolute'}
              left={0}
              right={0}
              margin={'auto'}
              zIndex={-1}
            >
              <Text
                padding={3}
                fontSize={'lg'}
                color={colors.gray[500]}
                noOfLines={1}
                fontWeight={500}
                textAlign={'center'}
              >
                {title}
              </Text>
            </Stack>
          </HStack>
        </VStack>

        <Box position={'relative'} flex={1} overflowY={'auto'}>
          <VStack
            position={'absolute'}
            left={0}
            top={0}
            w="full"
            h="fit-content"
            overflow={'hidden'}
            px={4}
            pt={2}
            pb={10}
          >
            {children}
          </VStack>
        </Box>

        {/* Footer */}
        <VStack width={'100%'} px={4} py={3} space={3}>
          {showActionButton && (
            <Button
              onPress={onAction}
              px={4}
              height={'46px'}
              backgroundColor={isDisable ? disableColor : brandColor}
              disabled={isDisable}
            >
              <Text color={colors.white} fontWeight={500} fontSize={'md'}>
                {actionButtonLabel}
              </Text>
            </Button>
          )}
          <Button variant={'ghost'} px={4} onPress={onCancel} height={'44px'}>
            <HStack alignItems={'center'} space={'10px'}>
              {cancelButtonIcon && (
                <Icon
                  icon={cancelButtonIcon}
                  size={16}
                  color={colors.darkText}
                />
              )}
              <Text color={colors.darkText} fontWeight={500} fontSize={'md'}>
                {cancelButtonLabel}
              </Text>
            </HStack>
          </Button>
        </VStack>
      </Box>
    </Drawer>
  );
};

export default FilterDrawerScaffold;
