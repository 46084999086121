import { Avatar, Box, Pressable, Text, VStack } from 'native-base';
import { getAvatarText } from 'ui/utils/getAvatarText';

import { AppTileProps } from './types';

export const AppTile = ({
  logoUrl,
  name,
  showName = true,
  width = { base: '32px', md: '40px' },
  height = { base: '32px', md: '40px' },
  onPress,
}: AppTileProps) => {
  return (
    <Pressable
      width={'full'}
      minH={'full'}
      overflowX={'hidden'}
      borderColor={'gray.300'}
      onPress={onPress}
      p={1}
      borderRadius={8}
    >
      <VStack width={'full'} alignItems={'center'} space={2}>
        <Box
          borderRadius={8}
          overflow={'hidden'}
          w={width}
          h={height}
          borderColor="gray.300"
          borderWidth={1}
        >
          <Avatar
            h="full"
            w="full"
            bgColor="muted.100"
            _text={{ color: 'darkText' }}
            source={{ uri: logoUrl || '' }}
            _image={{ borderRadius: 0 }}
            borderRadius={0}
          >
            {getAvatarText(name)}
          </Avatar>
        </Box>
        {showName && (
          <Text
            fontSize={{ base: '.65rem', md: 'xs' }}
            fontWeight={500}
            color={'darkText'}
            noOfLines={2}
            textAlign={'center'}
          >
            {name}
          </Text>
        )}
      </VStack>
    </Pressable>
  );
};
