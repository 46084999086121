import * as Yup from 'yup';
import { AnyObject, Maybe } from 'yup/lib/types';

import { VALIDATION_MESSAGES } from './validation-msg';
import { ValidationsRegex } from './validations';

Yup.addMethod(Yup.string, 'password', function name() {
  return this.matches(
    ValidationsRegex.Password,
    VALIDATION_MESSAGES.PASSWORD_NOT_SAFE,
  );
});

Yup.setLocale({
  mixed: {
    default: VALIDATION_MESSAGES.DEFAULT,
    required: VALIDATION_MESSAGES.REQUIRED,
    notType: VALIDATION_MESSAGES.DEFAULT,
  },
  string: {
    email: VALIDATION_MESSAGES.EMAIL,
    min: VALIDATION_MESSAGES.MIN_LENGTH,
    max: VALIDATION_MESSAGES.MAX_LENGTH,
  },
});

declare module 'yup' {
  interface StringSchema<
    TType extends Maybe<string> = string | undefined,
    TContext extends AnyObject = AnyObject,
    TOut extends TType = TType,
  > extends Yup.BaseSchema<TType, TContext, TOut> {
    cellPhone(): StringSchema<TType, TContext>;
    email(): StringSchema<TType, TContext>;
    password(): StringSchema<TType, TContext>;
  }
}

export default Yup;
