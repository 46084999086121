import { ActivityType } from 'ui/types/activities';
import { Coordinates } from 'ui/types/interfaces';
import { ActivityDefinition } from 'ui/types/interfaces/activity-definition';

export enum SearchRelevanceSortBy {
  Newest = 'Newest',
  Popular = 'Popular',
  Upcoming = 'Upcoming',
  Nearest = 'Nearest',
  // Closest = 'Closest',
}
export interface ActivityDefinitionPublicSearchFilters {
  apps: string[];
  text?: string;
  position: Coordinates | null;
  radius?: number;
  limit?: number;
  skip?: number;
  types?: ActivityType[];
  categories?: {
    periodOptions?: { _id: string }[];
    causeOptions?: { _id: string }[];
    requirementOptions?: { _id: string }[];
  };
}

export interface ActivityDefinitionPublicSearchResponse
  extends ActivityDefinition {
  activitiesCount?: number;
}
