import { Stack } from 'native-base';
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import BhoLogo from 'ui/assets/illustrations/BhoLogo.svg';

import { useAuth } from '~/hooks/useAuth';
import { SplashScreen } from '~/pages/Authenticated/InApp/components/SplashScreen';

import { BhoClosure } from '../../components/InAppViewLayout/BhoClosure';

const STORAGE_KEY = 'app:webview:user:data';

const CURRENT_LOCATION_KEY = 'inApp:currentLocation';

const DEFAULT_APP_LOGO = BhoLogo;

const DEFAULT_APP_NAME = '';

export const WEBVIEW_EVENTS_MAPPER = {
  app_user_data_inject: 'app:webview:user:data:injected',
  info_webpage_loaded: 'info:webpage:loaded',
  logout: 'logout',
  error_tag: 'error:',
  info_user_signed_in: 'info:user:signed:in',
  open_new_tab: 'open:new:tab:',
};

export const sendMessageToReactNativeWebview = (
  event: keyof typeof WEBVIEW_EVENTS_MAPPER,
  message?: string,
) => {
  if (typeof window.ReactNativeWebView !== undefined) {
    window?.ReactNativeWebView?.postMessage?.(
      WEBVIEW_EVENTS_MAPPER[event].concat(message ? message : ''),
    );
  }
};

const SignInMobile = () => {
  const [hasSetUserData, setHasSetUserData] = useState<boolean>(false);
  const hasCalledLogin = useRef(false);
  const [defaultApp, setDefaultApp] = useState();
  const { handleSignInMobile } = useAuth();
  const [isLoading, setIsLoading] = useState(true);

  const handleUserDataInLocalStorage = useCallback(() => {
    const userData = localStorage.getItem(STORAGE_KEY);
    if (!userData) return;

    if (userData) {
      const data = JSON.parse(userData);

      try {
        Object.entries(data.cognito).forEach(([key, value]) => {
          if (key.includes('userData')) {
            localStorage.setItem(key, JSON.stringify(value));
          } else localStorage.setItem(key, value as string);
        });
        setHasSetUserData(true);

        const defaultApp = data.appId;
        setDefaultApp(defaultApp);

        const location = data?.location || null;
        if (location) {
          localStorage.setItem(CURRENT_LOCATION_KEY, JSON.stringify(location));
        }
      } catch (error) {
        if (error instanceof Error) {
          sendMessageToReactNativeWebview('error_tag', error.message);
          sendMessageToReactNativeWebview('logout');
        }
      }
    }
  }, []);

  useEffect(() => {
    /**
     * This is to inform the webview that the webpage has been loaded
     * This also avoid the race condition of the webview not being ready
     */
    sendMessageToReactNativeWebview('info_webpage_loaded');
  }, []);

  useLayoutEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      /**
       * This is to handle the user data injected from the webview
       * and store it in the local storage
       */
      if (event.data === WEBVIEW_EVENTS_MAPPER.app_user_data_inject) {
        handleUserDataInLocalStorage();
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [handleUserDataInLocalStorage]);

  useEffect(() => {
    if (defaultApp && hasSetUserData && !hasCalledLogin.current) {
      hasCalledLogin.current = true;
      handleSignInMobile({ appId: defaultApp, redirectUserToInAppView: false });
    }
  }, [defaultApp, hasSetUserData, handleSignInMobile, hasCalledLogin]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Stack h="100vh" w="100vw" alignItems="center" justifyContent="center">
      {isLoading && (
        <SplashScreen imageURL={DEFAULT_APP_LOGO} title={DEFAULT_APP_NAME} />
      )}
    </Stack>
  );
};

export default SignInMobile;
