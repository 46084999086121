import { Box, HStack, Image, Skeleton, Stack, Text, VStack } from 'native-base';
import placeholderImage from 'ui/assets/svgs/placeholder.svg';

export type ActivityOrganizationSnippetProps = {
  organizationName: string;
  organizationLogoUrl?: string;
};
export const ActivityOrganizationSnippet = ({
  organizationName,
  organizationLogoUrl,
}: ActivityOrganizationSnippetProps) => {
  return (
    <HStack space={2} alignItems={'center'}>
      <Box
        width={'24px'}
        height={'24px'}
        borderColor={'gray.300'}
        backgroundColor={'white'}
        overflow={'hidden'}
        alignItems={'center'}
        justifyContent={'center'}
        borderRadius={100}
        borderWidth={1}
      >
        <Image
          h="full"
          w="full"
          resizeMode="cover"
          alt={`Logo from activity ${organizationName}`}
          source={{ uri: organizationLogoUrl }}
          fallbackSource={{ uri: placeholderImage }}
        />
        <Stack width="100%" height="100%">
          <Skeleton width="100%" height="100%" />
        </Stack>
      </Box>

      <VStack space={1}>
        <Text
          fontSize={'xs'}
          color={'gray.600'}
          fontWeight={400}
          numberOfLines={1}
        >
          by {organizationName}
        </Text>
      </VStack>
    </HStack>
  );
};
