import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { PAGES } from '~/constants/pages.constants';
import { useAppSelector } from '~/hooks/useAppSelector';
import { ExternalApplyLinkLocationStateProps } from '~/pages/Authenticated/ExternalApplyLinkRedirect/types';
import { ActivitiesService } from '~/services/resources/activities';
import { App } from '~/types/interfaces/app';

import { ExecutionPlatform } from '../../../store/slices/inApp/types';
import { sendMessageToReactNativeWebview } from '../../SignInMobile';

function _findAppId(
  activityAppId: string | App | undefined,
  selectedApp: App | undefined,
): string {
  if (typeof selectedApp !== 'undefined') {
    return selectedApp._id;
  }
  if (typeof activityAppId === 'string') {
    return activityAppId;
  }
  return (activityAppId as App)._id;
}

export const useExternalApplyLinkRedirectController = () => {
  // -- State & Hooks
  const [isContinueLoading, setIsContinueLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const {
    externalApplyLink,
    activityAppId,
  }: ExternalApplyLinkLocationStateProps = location?.state || {};
  const { id: activityId } = useParams<{ id: string }>();
  const { selectedUserProfile } = useAppSelector(({ auth }) => auth);
  const { selectedApp, platform } = useAppSelector((state) => state.inApp);
  const appId = _findAppId(activityAppId, selectedApp);
  const displayLink = externalApplyLink?.split('/').slice(0, 3).join('/') + '/';

  // -- Handlers
  const handleGoBack = () => {
    navigate(-1);
  };
  const handleContinue = async () => {
    try {
      setIsContinueLoading(true);
      await ActivitiesService.applyToActivity(
        activityId as string,
        selectedUserProfile?._id as string,
        appId,
      );

      openUrl(externalApplyLink);
      navigate(PAGES.LogActivities, {
        replace: true,
      });
    } catch (e) {
      console.log(e);
      toast.error(
        'An error occurred while trying to redirect you to this opportunity. Please try again later.',
      );
    } finally {
      setIsContinueLoading(false);
    }
  };

  const openUrl = (url?: string) => {
    if (platform === ExecutionPlatform.WEBVIEW) {
      sendMessageToReactNativeWebview('open_new_tab', externalApplyLink);
    } else {
      window.open(url, '_blank');
    }
  };

  // -- Render Effects
  useEffect(() => {
    if (!activityId || !selectedApp) {
    }
    if (!externalApplyLink) {
      navigate(PAGES.ActivitiesDetails.replace(':id', activityId || ''));
    }
  });

  return {
    displayLink,
    handleGoBack,
    isContinueLoading,
    handleContinue,
  };
};
