import InlineSearchInput from 'ui/components/InlineSearchInput';

import { useActivityLocationSearchController } from '~/pages/Authenticated/v2/ActivityView/components/ActivitySearch/ActivityLocationSearch/controller';
import LocationSearchOption from '~/pages/Authenticated/v2/ActivityView/components/ActivitySearch/ActivityLocationSearch/LocationSearchOption';
import { ActivityLocationSearchProps } from '~/pages/Authenticated/v2/ActivityView/components/ActivitySearch/ActivityLocationSearch/types';

export const ActivityLocationSearch = (props: ActivityLocationSearchProps) => {
  const { handleLocationSelect, loadLocationOptions, selectedOption } =
    useActivityLocationSearchController(props);

  return (
    <InlineSearchInput
      title={'Search'}
      inputIcon="search"
      value={selectedOption}
      onSelect={handleLocationSelect}
      onFetch={loadLocationOptions}
      optionElement={LocationSearchOption}
    />
  );
};

export default ActivityLocationSearch;
