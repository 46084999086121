import InlineSearchInput from 'ui/components/InlineSearchInput';

import { useActivityTeamSearchController } from '~/pages/Authenticated/v2/ActivityView/components/ActivitySearch/ActivityTeamSearch/controller';
import TeamSearchOption from '~/pages/Authenticated/v2/ActivityView/components/ActivitySearch/ActivityTeamSearch/TeamSearchOption';
import { ActivityTeamSearchProps } from '~/pages/Authenticated/v2/ActivityView/components/ActivitySearch/ActivityTeamSearch/types';

export const ActivityTeamSearch = (props: ActivityTeamSearchProps) => {
  const { handleTeamSelect, loadTeamsOptions, selectedOption } =
    useActivityTeamSearchController(props);

  return (
    <InlineSearchInput
      showSearchInput={false}
      value={selectedOption}
      onSelect={handleTeamSelect}
      onFetch={loadTeamsOptions}
      optionElement={TeamSearchOption}
    />
  );
};

export default ActivityTeamSearch;
