import { extendTheme } from 'native-base';

export const { colors } = extendTheme({
  colors: {
    primary: {
      50: '#D5EEEB',
      100: '#80D0C6',
      200: '#018F88',
      300: '#037973',
      400: '#006560',
      500: '#006560',
      600: '#00413E',
      700: '#002A28',
      800: '#001C1A',
      900: '#000B0A',
    },
    primaryAdmin: {
      50: '#D5EEEB',
      100: '#80D0C6',
      200: '#018F88',
      300: '#037973',
      400: '#006560',
      500: '#006560',
      600: '#00413E',
      700: '#002A28',
      800: '#001C1A',
      900: '#000B0A',
    },
    secondary: {
      50: '#CCEAD8',
      100: '#99D5B0',
      200: '#66C189',
      300: '#33AC61',
      400: '#00973A',
      500: '#008031',
      600: '#006A29',
      700: '#005B23',
      800: '#003C17',
      900: '#002D11',
    },
    grey: {
      50: '#E9EDF2',
      100: '#E2E5EB',
      200: '#D0D5DB',
      300: '#B9BDC7',
      400: '#A4A7B3',
      500: '#8D8F9E',
      600: '#797C8F',
      700: '#525466',
      800: '#3B3C52',
      900: '#1A1A23',
      bg: '#151518',
    },
    tertiary: {
      50: '#FCDED3',
      100: '#F9BDA7',
      200: '#F79C7C',
      300: '#F47B50',
      400: '#F15A24',
      500: '#CD4D1F',
      600: '#A93F19',
      700: '#913616',
      800: '#60240E',
      900: '#371508',
    },
    error: {
      400: '#F87171',
      600: '#DC2626',
    },
    activity: {
      event: '#3b52a533',
      teamEvent: '#188a8a33',
      action: '#e41b5033',
      ongoing: '#ffd23466',
    },
    bho: {
      primary: {
        50: '#E6E6FF',
        100: '#9999FF',
        200: '#6666FF',
        300: '#3333FF',
        400: '#0000FF',
        500: '#0000D9',
        600: '#0000B2',
        700: '#000099',
        800: '#000066',
        900: '#000033',
      },
    },
    doit: {
      primary: '#E41B50',
    },
    singletons: {
      white: '#FFFFFF',
      black: '#27272a',
      lightText: '#F7F7F7',
      darkText: '#27272a',
      greyText: '#71717A',
      lightGray: '#d4d4d4',
    },
  },
});
