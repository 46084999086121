import { useMemo } from 'react';
import { App } from 'ui/types/interfaces/app';

import { useAppSelector } from '~/hooks/useAppSelector';

export const useVolunteerEcosystemAvailableApps = () => {
  const { availableAppsFromPermissionGroupsById } = useAppSelector(
    ({ auth }) => auth,
  );

  const { appIds, apps } = useMemo((): { appIds: string[]; apps: App[] } => {
    const appIds: string[] = [];
    const apps: App[] = [];

    if (!availableAppsFromPermissionGroupsById) return { appIds, apps };

    for (const app of Object.values(availableAppsFromPermissionGroupsById)) {
      if (app?._id) {
        appIds.push(app._id);
        apps.push(app as App);
      }
    }

    return { appIds, apps };
  }, [availableAppsFromPermissionGroupsById]);

  const getAppById = (appId: string) => {
    const found = apps?.find((app) => app._id == appId);
    return found || null;
  };

  const isSingleAppPersona = apps.length === 1;

  return { appIds, apps, isSingleAppPersona, getAppById };
};
