import { useRef } from 'react';
import { IModalRefProps } from 'ui/components/Modals/Modal/types';

import { PAGES } from '~/constants/pages.constants';
import { useAppDispatch } from '~/hooks/useAppDispatch';
import { useRouter } from '~/hooks/useRouter';
import { redirectSliceActions } from '~/store/slices/redirect';

export const useAuthorizationController = () => {
  const denyModalRef = useRef<IModalRefProps>(null);
  const dispatch = useAppDispatch();
  const { goToRoute, replaceRoute } = useRouter();

  const handleAllow = async () => {
    goToRoute(PAGES.SignUp);
  };

  const handleDeny = async () => {
    dispatch(redirectSliceActions.removeAcceptInviteRedirect());
    replaceRoute(PAGES.GuestOpportunities);
  };

  const openDenyModal = () => denyModalRef.current?.open();
  const closeDenyModal = () => denyModalRef.current?.close();

  return {
    handleAllow,
    handleDeny,
    denyModalRef,
    openDenyModal,
    closeDenyModal,
  };
};
