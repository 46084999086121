import MeasurementService from '~/services/resources/measurement';
import { MeasurementsDataState } from '~/store/slices/measurementsData/types';

export const getMeasurementsMap = async (userProfileId: string) => {
  if (!userProfileId) return { mapApplicationsData: {}, count: 0 };

  try {
    const { data: responseData } = await MeasurementService.findByUserProfile(
      userProfileId,
    );

    const { data, count } = responseData;
    const mapApplications: any = {};

    for (const applicationData of data) {
      if (mapApplications[applicationData.activityApplication]) {
        mapApplications[applicationData.activityApplication].measurements.push(
          applicationData,
        );
      } else {
        mapApplications[applicationData.activityApplication] = {
          activityApplication: applicationData.activityApplicationSubDocument,
          measurements: [applicationData],
          measurementUnit:
            applicationData.activityApplicationSubDocument.activitySubDocument
              .activityDefinitionSubDocument.measurementUnit,
          app: applicationData.app,
        };
      }
    }

    const mapApplicationsData = mapApplications;

    return { mapApplicationsData, count };
  } catch (error) {
    console.error(`Error on trying to get applications measurements.`, error);
    return { mapApplicationsData: {}, count: 0 };
  }
};
