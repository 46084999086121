import { ActivitySemanticType } from 'ui/types/activities';
import { hasArrayIntersection } from 'ui/utils/validations/compare';

import { AttendanceType } from '~/types/interfaces/activity';

export type FilterWhiteListCanon = {
  activityTypes: ActivitySemanticType[];
  attendanceTypes: AttendanceType[];
};

export class FiltersWhiteListMap<K> extends Map<K, FilterWhiteListCanon> {
  constructor(entries: [K, FilterWhiteListCanon][]) {
    super(entries);
  }

  private matchesCriteria(
    canon: FilterWhiteListCanon,
    requiredCanon: FilterWhiteListCanon,
  ): boolean {
    // Verifica se o tipo de atividade eh permitido para esse Key
    const isActivityTypeAllowed =
      !canon.activityTypes.length ||
      hasArrayIntersection(canon.activityTypes, requiredCanon.activityTypes);

    // Verifica se o tipo de atendimento e permitido para esse Key
    const isAttendanceTypeAllowed =
      !canon.attendanceTypes.length ||
      hasArrayIntersection(
        canon.attendanceTypes,
        requiredCanon.attendanceTypes,
      );

    // Se ambos os tipos forem permitidos, adiciona a lista de opções validas
    return isActivityTypeAllowed && isAttendanceTypeAllowed;
  }

  public filterMatchingKeys(canon: FilterWhiteListCanon): K[] {
    const matchingKeys: K[] = [];
    for (const [key, requiredCanon] of this.entries()) {
      if (this.matchesCriteria(canon, requiredCanon)) {
        matchingKeys.push(key);
      }
    }
    return matchingKeys;
  }

  public match(key: K, canon: FilterWhiteListCanon): boolean {
    const requiredCanon = this.get(key);
    return !!requiredCanon && this.matchesCriteria(canon, requiredCanon);
  }
}
