import { ReactNode } from 'react';
import { MobileDrawerScaffold } from 'ui/components/v2/Drawers/MobileDrawerScaffold';

export type ActivityFilterMobileDrawerProps = {
  isOpen?: boolean;
  onClose?: VoidFunction;
  children: ReactNode;
};

export const ActivityFilterMobileDrawer = (
  props: ActivityFilterMobileDrawerProps,
) => {
  const { isOpen = false, onClose, children } = props;

  const handleCancel = () => {
    onClose?.();
  };

  const handleClose = () => {
    onClose?.();
  };

  return (
    <MobileDrawerScaffold
      title="Filters"
      isOpen={isOpen}
      onClose={handleClose}
      onCancel={handleCancel}
      showFooter={false}
      headerVariant="close"
    >
      {children}
    </MobileDrawerScaffold>
  );
};

export default ActivityFilterMobileDrawer;
