import { Box, Image, Skeleton, VStack } from 'native-base';

import { CoverImageCaption } from './CoverImageCaption';
import { CardCoverImageProps } from './types';

export const CardCoverImage = ({
  ratio = 56,
  maxWidth = 'unset',
  imageAlt = '',
  imageUrl = '',
  imageFallBackUrl = '',
  caption,
  backgroundColor = 'gray.400',
  borderRadius,
}: CardCoverImageProps) => {
  return (
    <VStack
      borderRadius={borderRadius}
      paddingBottom={`${ratio}%`}
      width={'100%'}
      maxWidth={maxWidth}
      backgroundColor={backgroundColor}
      overflow={'hidden'}
      position={'relative'}
    >
      {caption && <CoverImageCaption> {caption} </CoverImageCaption>}

      <Image
        position={'absolute'}
        top={0}
        left={0}
        w="full"
        h="full"
        resizeMode="cover"
        alt={imageAlt}
        source={{ uri: imageUrl || '' }}
        fallbackSource={{ uri: imageFallBackUrl }}
      />

      <Box w="full" h="full" position="absolute" zIndex={-1}>
        <Skeleton h={'full'} w={'full'} rounded="8" />
      </Box>
    </VStack>
  );
};
