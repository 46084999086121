import { Box, Menu, Text, VStack } from 'native-base';
import { useMemo, useState } from 'react';
import { MobileDrawerScaffold } from 'ui/components/v2/Drawers/MobileDrawerScaffold';
import { useDebounceFunction } from 'ui/hooks/useDebounceFunction';
import { colors } from 'ui/theme/colors';

import { SearchTextInput } from '../../../SearchTextInput';
import { SearchOption } from '../../types';
import { SearchOptionItem } from '../Item';
import { SearchDrawerProps } from './types';

const SearchDrawer = (props: SearchDrawerProps) => {
  const {
    isOpen = false,
    defaultValue,
    placeholder,
    options,
    onClose,
    onSubmit,
  } = props;

  const [inputValue, setInputValue] = useState(defaultValue || '');

  const onInputValueChange = useDebounceFunction(
    (value: string) => setInputValue(value),
    100,
  );

  const handleSubmit = (value?: string) => {
    onSubmit?.(value || inputValue);
  };

  const handleOptionPress = (option: SearchOption) => {
    setInputValue(option.value);
    handleSubmit(option.value);
  };

  const filterOptions = useMemo(() => {
    return options.filter((option) =>
      option.value.toLowerCase().includes(inputValue.toLowerCase()),
    );
  }, [inputValue, options]);

  return (
    <>
      <MobileDrawerScaffold
        isOpen={isOpen}
        title="Search"
        showActionButton={true}
        actionButtonIcon="search"
        actionButtonLabel="Search"
        onClose={onClose}
        onCancel={onClose}
        onAction={() => handleSubmit()}
      >
        <VStack px={2}>
          <Box width={'100%'} mb={6}>
            <SearchTextInput
              showIcon
              value={inputValue}
              placeholder={placeholder}
              onChange={onInputValueChange}
              onSubmit={handleSubmit}
            />
          </Box>

          <VStack>
            <Text
              mb={2}
              color={colors.gray[500]}
              fontWeight={500}
              fontSize={'sm'}
            >
              Enter your query or try these:
            </Text>

            {!!filterOptions?.length &&
              filterOptions.map((option) => {
                return (
                  <Menu.Item
                    w="full"
                    p={0}
                    key={option.value}
                    onPress={() => handleOptionPress(option)}
                    borderRadius={4}
                  >
                    <SearchOptionItem
                      label={option?.label || option.value}
                      icon="search"
                    />
                  </Menu.Item>
                );
              })}
          </VStack>
        </VStack>
      </MobileDrawerScaffold>
    </>
  );
};

export default SearchDrawer;
