import { useBreakpointValue } from 'native-base';
import { useMemo, useState } from 'react';
import { useDebounceFunction } from 'ui/hooks/useDebounceFunction';

import { SearchAutoCompleteProps, SearchOption } from './types';

export const useActivitySearchAutoCompleteController = (
  props: SearchAutoCompleteProps,
) => {
  const {
    onSubmit,
    isDisabled,
    defaultValue = '',
    suggestionValues,
    triggerSubmitOnClear = false,
    disableOnEmptyInput = false,
    onInputChange,
  } = props;
  const [searchOptions] = useState<SearchOption[]>(() =>
    (suggestionValues || [])?.map((item) => {
      const option: SearchOption = { value: item, label: item };
      return option;
    }),
  );
  const [inputValue, setInputValue] = useState<string>(defaultValue);

  const handleInputValueChange = useDebounceFunction((value: string) => {
    setInputValue(value);
    onInputChange?.(value);
  }, 100);

  const isMobile = useBreakpointValue({ base: true, sm: false });

  const filteredSearchOptions = useMemo(() => {
    const _inputValue = (inputValue || '').trim().toLowerCase();
    if (!_inputValue) return searchOptions;
    return searchOptions?.filter(({ value }) =>
      value.toLowerCase().includes(_inputValue),
    );
  }, [searchOptions, inputValue]);

  const handleInputClear = () => {
    if (triggerSubmitOnClear) {
      onSubmit('');
    }
  };

  const handleSubmit = (submitValue?: string) => {
    if (submitValue) {
      handleInputValueChange(submitValue);
    }
    onSubmit(submitValue || inputValue);
  };

  const isSubmitButtonDisabled = disableOnEmptyInput
    ? !inputValue?.length
    : isDisabled;

  return {
    isMobile,
    inputValue,
    defaultValue,
    isSubmitButtonDisabled,
    options: filteredSearchOptions,
    handleInputValueChange,
    handleInputClear,
    handleSubmit,
  };
};
