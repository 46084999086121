export function compareCaseInsensitive(a: string, b: string) {
  return a.toUpperCase() === b.toUpperCase();
}

export function hasArrayIntersection<T>(arr1: T[], arr2: T[]): boolean {
  return arr1.some((item) => arr2.includes(item));
}

export function areArraysEqual<T>(arr1: T[], arr2: T[]) {
  if (arr1.length !== arr2.length) return false;

  const sortedArr1 = [...arr1].sort();
  const sortedArr2 = [...arr2].sort();

  return sortedArr1.every((value, index) => value === sortedArr2[index]);
}
