import { Flex, Text } from 'native-base';
import { FiFrown } from 'react-icons/fi';
import { ILLUSTRATIONS_NAME } from 'ui/components/Illustration';

import FlowTwoColumnsTemplate from '~/components/Templates/FlowTwoColumnsTemplate';
import { useAccountDeletedController } from '~/pages/Common/AccountDeleted/useAccountDeletedController';

export const AccountDeleted = () => {
  const { handleGoToHome, handleGoToSignUp } = useAccountDeletedController();
  return (
    <Flex minH="full" w="full" flexShrink={0}>
      <FlowTwoColumnsTemplate
        ilustrationName={ILLUSTRATIONS_NAME.ERROR_SORRY_STATE_BROKEN}
        title="We are very sorry that you left us"
        icon={<FiFrown size={32} />}
        primaryButtonText="Go to Doit.life"
        primaryButtonOnPress={handleGoToHome}
        primaryButtonTextStyle={{ fontSize: 'md' }}
        secondaryButtonText="Create a new account"
        secondaryButtonOnPress={handleGoToSignUp}
        secondaryButtonTextStyle={{ fontSize: 'md' }}
        buttonsDirection="column-reverse"
        progress={100}
      >
        <Flex flex={1} flexShrink={0} flexGrow={1} w="full" px={4} py={8}>
          <Text fontSize="lg" color="singletone.darkText">
            We genuinely appreciate your contribution to the community. Please
            remember that our doors are always open, and we eagerly await the
            day when you decide to return to our community. In the meantime,
            take good care of yourself and stay well!
          </Text>
        </Flex>
      </FlowTwoColumnsTemplate>
    </Flex>
  );
};
