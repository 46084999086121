import { HStack, Pressable, Text } from 'native-base';
import Icon from 'ui/components/Icon';
import { colors } from 'ui/theme/colors';

export type ActivitySelectorNavButtonProps = {
  onPress: VoidFunction;
  isDisabled?: boolean;
  label: string;
};
export const ActivitySelectorNavButton = (
  props: ActivitySelectorNavButtonProps,
) => {
  const { onPress, isDisabled, label } = props;

  return (
    <Pressable
      h="fit-content"
      justifyContent={'flex-end'}
      marginBottom={0}
      marginTop={'auto'}
      onPress={onPress}
      isDisabled={isDisabled}
    >
      <HStack alignItems={'center'} justifyContent={'center'} space="10px">
        <Text
          fontWeight={500}
          fontSize={'xs'}
          color={isDisabled ? colors.gray[400] : colors.darkText}
        >
          {label}
        </Text>
        <Icon
          icon="chevron-down"
          size={'16px'}
          color={isDisabled ? colors.gray[400] : colors.darkText}
        />
      </HStack>
    </Pressable>
  );
};

export default ActivitySelectorNavButton;
