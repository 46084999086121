import { useAppSelector } from '~/hooks/useAppSelector';
import { useQuery } from '~/hooks/useQuery';
import { IUserProfile } from '~/types/interfaces/user';

export const useLoadUserProfiles = () => {
  const { user } = useAppSelector(({ auth }) => auth);

  const { data: userProfiles, isLoading } = useQuery<IUserProfile[]>(
    `user-profile/user/${user?._id}`,
    {
      queryOptions: { enabled: !!user?._id },
    },
  );

  return { isLoading, userProfiles };
};
