import {
  Box,
  HStack,
  Progress,
  Skeleton,
  Stack,
  Text,
  VStack,
} from 'native-base';
import { useMemo } from 'react';
import Icon, { IconEnum } from 'ui/components/Icon';
import { MeasurementUnit } from 'ui/types/measurementUnit';
import { getMeasurementTotalLogged } from 'ui/utils/getMeasurementTotalLogged';

import ActionCompletionMeasurementsCard from '~/pages/Authenticated/v2/ActivityView/views/ActivityLog/components/ActionApplicationCard/CompletionMeasurements';
import { ActionApplicationCardProps } from '~/pages/Authenticated/v2/ActivityView/views/ActivityLog/components/ActionApplicationCard/types';
import { IActivityApplication } from '~/types/interfaces/activity';
import { getActivityLogAmount } from '~/utils/activity/activity-definition-getters';

export const ActionApplicationCard: React.FC<ActionApplicationCardProps> = (
  props,
) => {
  const { activity, activityDefinition } = props;

  const measurementUnit =
    activityDefinition.measurementUnit as unknown as MeasurementUnit;

  const progress = useMemo(() => {
    const activityApplication =
      activity.userApplication as IActivityApplication;

    const total = Number(activityDefinition?.targetAmount || 0);
    const totalStr = measurementUnit
      ? getActivityLogAmount(measurementUnit, total)
      : total;

    const value = getMeasurementTotalLogged(activityApplication.measurements);
    const valueStr = measurementUnit
      ? getActivityLogAmount(measurementUnit, value)
      : value;

    return { total, totalStr, value, valueStr };
  }, [measurementUnit, activityDefinition, activity]);

  const isStepMeasurement = !!measurementUnit?.steps.length;

  if (!measurementUnit)
    return <Skeleton height={'120px'} width={'full'} borderLeftRadius={12} />;

  return (
    <VStack
      display={'flex'}
      alignItems={'center'}
      justifyContent={'center'}
      w={'full'}
      p={3}
      space={5}
    >
      <Text fontSize={'xl'} fontWeight={'500'}>
        Keep up the good work!
      </Text>

      {isStepMeasurement ? (
        <ActionCompletionMeasurementsCard
          measurementUnit={measurementUnit}
          activity={activity}
        />
      ) : (
        <Box w={'full'} borderRadius={8} bg={'gray.100'} px={4} pt={2} pb={4}>
          <HStack justifyContent="space-between" py={2}>
            <Stack>
              <Text>{progress?.valueStr || ''}</Text>
            </Stack>
            <HStack alignItems="center" space={1}>
              <Icon isCustom size={5} icon={IconEnum.TARGET_AND_ARROW} />
              <Text>{progress?.totalStr || ''}</Text>
            </HStack>
          </HStack>

          <Progress
            value={progress.value}
            max={progress.total}
            _filledTrack={{ bg: 'black' }}
          />
        </Box>
      )}
    </VStack>
  );
};

export default ActionApplicationCard;
