import { PermissionsBooleanProps, ValidationTypeEnum } from './types';
import { usePermissionBooleanController } from './usePermissionBooleanController';

export const PermissionBoolean = ({
  validationType = ValidationTypeEnum.Generic,
  bypassPermissions = false,
  availablePermissions = [],
  requiredPermissions = [],
  fallbackComponent = null,
  children,
}: PermissionsBooleanProps): JSX.Element => {
  const { hasValidPermission } = usePermissionBooleanController({
    availablePermissions,
    requiredPermissions,
    validationType,
  });
  return bypassPermissions || hasValidPermission ? (
    <>{children}</>
  ) : (
    <>{fallbackComponent}</>
  );
};
