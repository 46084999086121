import { PresenceTransition, VStack } from 'native-base';
import { ReactNode } from 'react';
import { colors } from 'ui/theme/colors';

const POPOVER_STYLE = {
  boxShadow: '0px 8px 12px 0px rgba(0, 0, 0, 0.08)',
};

export type DropDownPopOverAnchor = 'left' | 'right';

export type DropDownPopOverProps = {
  isOpen?: boolean;
  children: ReactNode;
  onClose?: VoidFunction;
  anchor?: DropDownPopOverAnchor;
  maxH?: string | number;
  maxW?: string | number;
  borderRadius?: string | number;
  top?: number;
};

export const DropDownPopOver = (props: DropDownPopOverProps) => {
  const {
    isOpen,
    children,
    anchor = 'right',
    maxH = '320px',
    maxW = 'unset',
    borderRadius = 12,
    top = 2,
  } = props;

  return (
    <>
      {isOpen && (
        <PresenceTransition
          visible={true}
          initial={{ opacity: 0, translateY: 25, scaleY: 0.25 }}
          animate={{ opacity: 1, transition: { duration: 160 } }}
        >
          <VStack
            zIndex={999}
            w="full"
            maxW={maxW}
            minW={'fit-content'}
            maxH={maxH}
            borderWidth={1}
            position={'absolute'}
            top={`calc(100% + ${top}px)`}
            right={anchor === 'right' ? 0 : 'unset'}
            left={anchor === 'left' ? 0 : 'unset'}
            overflowY={'auto'}
            backgroundColor={'white'}
            borderColor={colors.singletons.lightText}
            borderRadius={borderRadius}
            style={POPOVER_STYLE as any}
          >
            {children}
          </VStack>
        </PresenceTransition>
      )}
    </>
  );
};

export default DropDownPopOver;
