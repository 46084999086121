import { HStack, Pressable, Stack, Text, VStack } from 'native-base';
import Button from 'ui/components/Button';
import Icon from 'ui/components/Icon';
import { colors } from 'ui/theme/colors';

import { MobileDrawer } from '../MobileDrawer';
import { MobileDrawerScaffoldProps } from './types';

export const MobileDrawerScaffold = (props: MobileDrawerScaffoldProps) => {
  const {
    title,
    children,
    cancelButtonIcon,
    actionButtonIcon,
    actionButtonLabel = 'Submit',
    cancelButtonLabel = 'Cancel',
    showActionButton = false,
    showCancelButton = false,
    showFooter = true,
    isDisable = false,
    isOpen = false,
    headerVariant = 'back',
    onClose,
    onAction,
    onCancel,
  } = props;

  const useCloseHeaderVariant = headerVariant === 'close';

  return (
    <MobileDrawer isOpen={isOpen} onClose={onClose}>
      <VStack py={2} h={'full'}>
        {/* Header */}
        <HStack
          h="56px"
          justifyContent={useCloseHeaderVariant ? 'flex-end' : 'flex-start'}
          alignItems={'center'}
          overflow={'hidden'}
          px={2}
        >
          {useCloseHeaderVariant ? (
            <Pressable p={2} onPress={onClose}>
              <HStack w="full" h={'full'} alignItems={'center'} space={'10px'}>
                <Text fontSize={'xs'} fontWeight={500} color={colors.darkText}>
                  Close
                </Text>
                <Icon icon="x" size={16} />
              </HStack>
            </Pressable>
          ) : (
            <Pressable p={2} onPress={onClose}>
              <HStack w="full" h={'full'} alignItems={'center'} space={'10px'}>
                <Icon icon="chevron-left" size={16} />
                <Text fontSize={'xs'} fontWeight={500} color={colors.darkText}>
                  Back
                </Text>
              </HStack>
            </Pressable>
          )}

          <Stack
            position={'absolute'}
            left={0}
            right={0}
            margin={'auto'}
            zIndex={-1}
          >
            <Text
              padding={3}
              fontSize={'lg'}
              color={colors.gray[500]}
              noOfLines={1}
              fontWeight={500}
              textAlign={'center'}
            >
              {title}
            </Text>
          </Stack>
        </HStack>

        {/* Body */}
        <VStack position={'relative'} flex={1} overflowY={'auto'}>
          <VStack
            position={'absolute'}
            top={0}
            left={0}
            w="full"
            h="fit-content"
            p={2}
            pb={10}
          >
            {children}
          </VStack>
        </VStack>

        {/* Footer */}
        {showFooter && (
          <VStack width={'100%'} px={4} py={3} space={3}>
            {showActionButton && (
              <Button
                onPress={onAction}
                px={4}
                height={'46px'}
                backgroundColor={
                  isDisable ? colors.grey[200] : colors.singletons.black
                }
                disabled={isDisable}
              >
                <HStack alignItems={'center'} space={'10px'}>
                  {actionButtonIcon && (
                    <Icon
                      icon={actionButtonIcon}
                      size={16}
                      color={colors.white}
                    />
                  )}
                  <Text color={colors.white} fontWeight={500} fontSize={'md'}>
                    {actionButtonLabel}
                  </Text>
                </HStack>
              </Button>
            )}
            {showCancelButton && (
              <Button
                variant={'ghost'}
                px={4}
                onPress={onCancel}
                height={'44px'}
              >
                <HStack alignItems={'center'} space={'10px'}>
                  {cancelButtonIcon && (
                    <Icon
                      icon={cancelButtonIcon}
                      size={16}
                      color={colors.darkText}
                    />
                  )}
                  <Text
                    color={colors.darkText}
                    fontWeight={500}
                    fontSize={'md'}
                  >
                    {cancelButtonLabel}
                  </Text>
                </HStack>
              </Button>
            )}
          </VStack>
        )}
      </VStack>
    </MobileDrawer>
  );
};

export default MobileDrawerScaffold;
