import { Actionsheet, Box, Text, VStack } from 'native-base';
import React from 'react';

import { ISelectOptionsGroup, ISelectOptionsItem } from '../../types';

export type SelectActionSheetProps = {
  isOpen?: boolean;
  onClose?: VoidFunction;
  options: ISelectOptionsGroup[] | ISelectOptionsItem[];
  onItemPress: (item: ISelectOptionsItem) => void;
  title?: string;
};

export const SelectActionSheet = ({
  isOpen,
  onClose,
  options = [],
  onItemPress,
  title,
}: SelectActionSheetProps) => {
  const renderOptions = (options: ISelectOptionsItem[]) => {
    return (
      <>
        {options?.map((option) => (
          <Actionsheet.Item
            key={option.value}
            onPress={() => onItemPress(option)}
          >
            {option.label}
          </Actionsheet.Item>
        ))}
      </>
    );
  };

  const renderOptionGroups = (optionsGroups: ISelectOptionsGroup[]) => {
    return (
      <>
        {optionsGroups?.map((optionGroup, i) => (
          <React.Fragment key={i}>
            <Actionsheet.Item isDisabled py={1}>
              <Text fontSize={'xs'}>{optionGroup.label}</Text>
            </Actionsheet.Item>
            {renderOptions(optionGroup?.options || [])}
          </React.Fragment>
        ))}
      </>
    );
  };

  const isOptionGroup =
    (options[0] as ISelectOptionsGroup)?.options !== undefined;

  return (
    <Actionsheet isOpen={isOpen} onClose={onClose}>
      <Actionsheet.Content maxW={'480px'} margin={'auto'}>
        <VStack w="full" overflowY={'auto'}>
          {title && (
            <Box w="100%" h={60} px={4} justifyContent="center">
              <Text fontSize="14" color="gray.500">
                {title || ''}
              </Text>
            </Box>
          )}
          {isOptionGroup
            ? renderOptionGroups(options as ISelectOptionsGroup[])
            : renderOptions(options as ISelectOptionsItem[])}
        </VStack>
      </Actionsheet.Content>
    </Actionsheet>
  );
};
