import { FeaturedActivitySnippets } from '~/pages/Authenticated/v2/Home/components/Widget/FeaturedActivity/Snippets';
import { FeaturedActivityInfoData } from '~/pages/Authenticated/v2/Home/components/Widget/FeaturedActivity/Snippets/InfoSnippet';

export const HomeWidgetFeaturedActivityTabsFactory = ({
  data,
  onPress,
}: {
  data?: FeaturedActivityInfoData | null;
  onPress?: VoidFunction;
}) => {
  if (!data) return [];
  return [
    <FeaturedActivitySnippets.Info key={1} data={data} onPress={onPress} />,
  ];
};
