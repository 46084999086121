import { PermissionsBooleanProps } from './types';

export const validateGenericPermissions = ({
  availablePermissions,
  requiredPermissions,
}: Pick<
  PermissionsBooleanProps,
  'availablePermissions' | 'requiredPermissions'
>) => {
  if (
    !requiredPermissions ||
    (requiredPermissions && requiredPermissions.length === 0)
  ) {
    return true;
  }

  const userContainsPermissions = requiredPermissions.some(
    (requiredPermission) =>
      availablePermissions.find(
        (availablePermission) =>
          availablePermission.name === requiredPermission.name,
      ),
  );
  return userContainsPermissions;
};

export const validateSpecificAppPermissions = ({
  requiredPermissions,
  availablePermissions,
}: Pick<
  PermissionsBooleanProps,
  'requiredPermissions' | 'availablePermissions'
>) => {
  if (!requiredPermissions?.length) {
    return true;
  }

  const hasPermissions = requiredPermissions.some((requiredPermission) =>
    availablePermissions.find((availablePermissions) => {
      return availablePermissions.name === requiredPermission.name;
    }),
  );

  return hasPermissions;
};
