import { useVolunteerOrganization } from '~/hooks/organization/useVolunteerOrganization';
export const CompanyExclusiveContent = ({
  children,
}: {
  children: JSX.Element;
}) => {
  const { isLoading: isOrganizationLoading, isCompany: isCompanyOrganization } =
    useVolunteerOrganization();

  const preventRender = !isOrganizationLoading && !isCompanyOrganization;

  if (preventRender) return null;

  return children;
};
