import { api } from '~/services/api';
import { IAxiosResponse } from '~/types';
import { IUser, Preferences } from '~/types/interfaces/user';

const MODULE_URL = 'user';

export default class UserService {
  static async me(): Promise<IAxiosResponse<IUser>> {
    return await api.get(`${MODULE_URL}/me`);
  }

  static async updateUser(id: string, userData: Partial<IUser>) {
    return await api.put(`${MODULE_URL}/${id}`, userData);
  }

  static async updateUserPreferences(id: string, preferences: Preferences) {
    return await api.put(`${MODULE_URL}/${id}`, { preferences });
  }

  static async deleteById(id: string) {
    return await api.delete(`${MODULE_URL}/${id}`);
  }
}
