import { MeasurementCategory } from 'ui/enums';
import { MeasurementUnit } from 'ui/types/measurementUnit';
import { secondsToHoursAndMinutes } from 'ui/utils/secondsToDuration';

export const defaultMeasurementCategoryFormatterMap: Partial<
  Record<MeasurementCategory, (amount: number) => string>
> = {
  [MeasurementCategory.Time]: (amount: number) => {
    const { hours, minutes } = secondsToHoursAndMinutes(amount);
    return `${hours}h ${minutes}m`;
  },
};

export const extendedMeasurementCategoryFormatterMap: Partial<
  Record<MeasurementCategory, (amount: number) => string>
> = {
  [MeasurementCategory.Time]: (amount: number) => {
    const { hours } = secondsToHoursAndMinutes(amount);
    return `${hours} hours`;
  },
};

export function formatMeasurementAmount(
  amount: number,
  measurementUnit?: MeasurementUnit,
  useExtendedFormatter = false,
) {
  if (measurementUnit) {
    const formatterMap = useExtendedFormatter
      ? extendedMeasurementCategoryFormatterMap
      : defaultMeasurementCategoryFormatterMap;

    const formatter = formatterMap[measurementUnit.category];
    if (formatter) return formatter(amount);
  }

  const unitPlural = measurementUnit?.pluralLabel || '';
  const unitSingular = measurementUnit?.singularLabel || '';

  const unitSuffixLabel = amount != 1 ? unitPlural : unitSingular;

  return `${amount} ${unitSuffixLabel}`;
}
