import {
  Avatar,
  Box,
  HStack,
  Pressable,
  Skeleton,
  Stack,
  Text,
} from 'native-base';
import Illustration, { ILLUSTRATIONS_NAME } from 'ui/components/Illustration';
import { LocationButton } from 'ui/components/v2/LocationInputs/LocationButton';
import { getNameInitials } from 'ui/utils/getNameInititals';

import {
  CenterContainer,
  CenterContainerSize,
} from '~/components/Layouts/Scaffolds/CenterContainer';
import { AccountDropdown } from '~/components/NavBar/components/AccountDropdown';
import { useUserLocationController } from '~/hooks/useUserLocation/useUsersLocation';

export interface DesktopNavBarProps {
  height?: string | number;
  ecosystemLogoUrl: string;
  ecosystemName: string;
  onLogoPress?: VoidFunction;
}

export const DesktopNavBar = ({
  height = 72,
  onLogoPress,
  ecosystemLogoUrl = '',
  ecosystemName = '',
}: DesktopNavBarProps) => {
  const {
    location,
    openLocationModal,
    isLoading: isLocationLoading,
  } = useUserLocationController();

  return (
    <HStack
      w="100%"
      bg="white"
      borderBottomWidth="1"
      h={`${height}px`}
      borderBottomColor="gray.200"
      zIndex={999}
    >
      <CenterContainer size={CenterContainerSize.xl}>
        <HStack h="full" alignItems={'center'} justifyContent={'space-between'}>
          <Pressable onPress={onLogoPress}>
            <HStack space={3} alignItems={'center'}>
              <Avatar
                source={{ uri: ecosystemLogoUrl }}
                h={'48px'}
                borderRadius={'full'}
              >
                {getNameInitials(ecosystemName)}
              </Avatar>

              {/*
              NOTE: LEAVE IT HERE IN CASE CLIENT CHANGE OF IDEA
              <Stack space={0.5}>
                <Skeleton.Text lines={1} isLoaded={!!ecosystemName}>
                  <Text fontSize={'sm'} fontWeight={700}>
                    {ecosystemName}
                  </Text>
                </Skeleton.Text>

                <HStack flexDir={'row'} alignItems={'center'} space={1}>
                  <Skeleton.Text lines={1} w="100px" isLoaded={!!ecosystemName}>
                    <Text fontSize={'xs'} color={'gray.500'}>
                      {'Powered by '}
                    </Text>
                  </Skeleton.Text>

                  <Stack>
                    <Skeleton
                      width="30px"
                      height="16px"
                      borderRadius="full"
                      isLoaded={!!ecosystemName}
                    >
                      <Illustration
                        width="30px"
                        height="16px"
                        name={ILLUSTRATIONS_NAME.DOIT_TRANSPARENT_LOGO}
                      />
                    </Skeleton>
                  </Stack>
                </HStack>
              </Stack> */}
            </HStack>
          </Pressable>

          <HStack justifyContent={'flex-end'} alignItems="center" space={6}>
            <LocationButton
              isLoading={isLocationLoading}
              onPress={openLocationModal}
              text={location?.rawLocation || ''}
              width="fit-content"
            />
            <Box>
              <AccountDropdown />
            </Box>
          </HStack>
        </HStack>
      </CenterContainer>
    </HStack>
  );
};
