export const makeSearchPageBreadCrumbsNodes = (
  homePath: string,
  collectionName?: string,
): Array<{
  icon?: string;
  label: string;
  path: string;
}> => {
  return [
    {
      icon: 'home',
      path: homePath,
      label: 'Home',
    },
    {
      label: collectionName || `Search`,
      path: '',
    },
  ];
};
