import { RootPortal } from '~/components/RootPortal';
import { PreferenceLocationModal } from '~/components/v2/ModalTemplates/PreferenceLocationModal';
import { useEnablePreferenceLocationModal } from '~/hooks/useUserLocation/useEnablePreferenceLocationModal';
import { useUserLocationController } from '~/hooks/useUserLocation/useUsersLocation';
import LocationModal from '~/pages/Authenticated/InApp/modals/LocationModal';

export const LayoutModals = () => {
  const {
    location,
    showLocationModal,
    closeLocationModal,
    handleLocationChange,
  } = useUserLocationController();

  const { handleCloseModal, locationModalRef } =
    useEnablePreferenceLocationModal();

  return (
    <RootPortal>
      <PreferenceLocationModal
        modalRef={locationModalRef}
        handleCloseModal={handleCloseModal}
        onChange={handleLocationChange}
      />

      {/* Location Modal */}
      <LocationModal
        isOpen={showLocationModal}
        onClose={closeLocationModal}
        onSubmit={handleLocationChange}
        initialLocation={location}
      />
    </RootPortal>
  );
};
