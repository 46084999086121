import { Flex, ScrollView, Stack, Text } from 'native-base';
import Loading from 'ui/components/Loading';
import Modal from 'ui/components/Modals/Modal';
import { RedirectPage } from 'ui/components/RedirectPage';
import { SignUpBranding } from 'ui/components/SignUpBranding';

import ModalPrivacyPolicy from '~/components/ModalPrivacyPolicy';
import ModalTermsOfService from '~/components/ModalTermsOfService';
import { BHO_ORGANIZATION } from '~/config';
import { AuthorizationPage } from '~/pages/Unauthenticated/JoinAsMemberSignUp/AuthorizationPage';
import { ConfirmEmailPage } from '~/pages/Unauthenticated/JoinAsMemberSignUp/ConfirmEmailPage';
import { CreateAccountPage } from '~/pages/Unauthenticated/JoinAsMemberSignUp/CreateAccountPage';
import { colors } from '~/theme/colors';

import { useJoinAsMemberSignUp } from './controller';

export const JoinAsMemberSignUp = () => {
  const {
    modalPrivacyPolicyRef,
    modalTermsOfServiceRef,
    step,
    control,
    errors,
    isDisabled,
    inviteCode,
    isLoadingInviteCode,
    isSubmittingCreateAccount,
    coolDownTime,
    isSubmittingVerifyCode,
    isResendCodeDisabled,
    email,
    denyModalRef,
    hasExternalId,
    isMobile,
    openDenyModal,
    handleDeny,
    closeDenyModal,
    handleOpenPrivacyPolicy,
    handleOpenTermsOfService,
    onSignInPress,
    onVerifyCode,
    handleCreateAccount,
    handleResendCode,
    handleNext,
    handleBack,
  } = useJoinAsMemberSignUp();

  if (isLoadingInviteCode) {
    return <Loading />;
  }

  if (!inviteCode) {
    return (
      <RedirectPage
        title="Invite not found"
        text="We couldn't find your invitation. Please contact the owner of the invitation."
        onButtonPress={onSignInPress}
        buttonColor={colors.gray[900]}
      />
    );
  }

  const tabComponents: Record<number, React.ReactNode> = {
    0: (
      <AuthorizationPage
        onAllowButtonPress={handleNext}
        onDenyButtonPress={openDenyModal}
      />
    ),
    1: (
      <CreateAccountPage
        onOpenPrivacyPolicy={handleOpenPrivacyPolicy}
        onOpenTermsOfService={handleOpenTermsOfService}
        control={control}
        errors={errors}
        isDisabled={isDisabled}
        hasExternalId={hasExternalId}
        isSubmitting={isSubmittingCreateAccount}
        onSignInPress={onSignInPress}
        onSubmit={handleCreateAccount}
      />
    ),
    2: (
      <ConfirmEmailPage
        onBack={handleBack}
        control={control}
        errors={errors}
        coolDownTime={coolDownTime}
        handleResendCode={handleResendCode}
        isResendCodeDisabled={isResendCodeDisabled}
        isSubmitting={isSubmittingVerifyCode}
        email={email}
        onVerifyCode={onVerifyCode}
        isDisabled={isDisabled}
      />
    ),
  };

  return (
    <Flex
      minH="100vh"
      height="100vh"
      width="100%"
      flexDir={{ base: 'column', lg: 'row' }}
      overflowX="hidden"
      bgColor={colors.singletons.lightText}
    >
      <Stack
        width={{ base: '100%', lg: '55%' }}
        height={{ base: '100%' }}
        zIndex={{ md: -1 }}
      >
        <SignUpBranding
          ecosystemName={inviteCode?.configurations[0]?.ecosystemId?.name || ''}
          logo={
            inviteCode?.composeSettings?.logo ||
            inviteCode?.sharerOrganization?.logo
          }
          organizationName={inviteCode?.sharerOrganization?.name || ''}
          backgroundImage={inviteCode?.composeSettings?.backgroundImage}
          brandColor={inviteCode?.composeSettings?.brandColor}
          height={isMobile ? '67vh' : '100vh'}
          isBigHelpOutBranded={
            inviteCode?.sharerOrganization?._id === BHO_ORGANIZATION
          }
        />
      </Stack>

      <Stack
        height={{ lg: '100%' }}
        width={{ base: '100%', md: '55%' }}
        paddingTop={{ md: 42 }}
        borderRadius={{ base: 40 }}
        position={{ base: 'absolute', md: 'initial' }}
        top={{ base: '50%' }}
      >
        <ScrollView
          borderRadius={{ base: 40 }}
          minH="100%"
          width={{ base: '100%' }}
        >
          {tabComponents[step]}
        </ScrollView>
      </Stack>
      <ModalPrivacyPolicy modalRef={modalPrivacyPolicyRef} />
      <ModalTermsOfService modalRef={modalTermsOfServiceRef} />

      <Modal
        ref={denyModalRef}
        headerText="Deny authorization"
        leftBtnAction={closeDenyModal}
        leftBtnText="No, go back"
        rightBtnAction={handleDeny}
        rightBtnText="Yes, deny"
        modalContentMaxW={{ base: '90vw', lg: '669' }}
        modalContentW={{ base: '90vw', lg: '669' }}
      >
        <Stack space={5} py={5}>
          <Text fontWeight={400} fontSize="md" color="gray.500">
            Are you sure about this action?
          </Text>

          <Text fontWeight={400} fontSize="md" color="gray.500">
            If don&apos;t accept the terms, you will be redirected to the app
            home as a guest.
          </Text>
        </Stack>
      </Modal>
    </Flex>
  );
};
