import { PresenceTransition, Skeleton } from 'native-base';

import ActivityApplicationPanel from '~/pages/Authenticated/v2/ActivityView/views/ActivityApplication';
import { useActivityInteractiveTabsController } from '~/pages/Authenticated/v2/ActivityView/views/ActivityInteractiveTabs/controller';
import ActivityLogPanel from '~/pages/Authenticated/v2/ActivityView/views/ActivityLog/ActivityLogPanel';

/*
  -- ActivityInteractiveTabs --

  This is the desktop aside content for managing application and activity logs. 
  You can switch between application and log tabs.
*/

export const ActivityInteractiveTabs = () => {
  const {
    tabIndex,
    gotToTab,
    activities,
    selectedActivity,
    appliedActivities,
    hasApplicationsToLog,
    hasActivitiesToApply,
  } = useActivityInteractiveTabsController();

  return (
    <PresenceTransition
      visible
      style={{ width: '100%' }}
      initial={{ opacity: 0, translateY: 0.75 }}
      animate={{ opacity: 1, translateY: 1, transition: { duration: 300 } }}
    >
      {tabIndex === 0 ? (
        <Skeleton isLoaded={hasActivitiesToApply} h={'340px'}>
          {hasActivitiesToApply && (
            <ActivityApplicationPanel
              key={selectedActivity?._id}
              showGoBackButton={hasApplicationsToLog}
              onGoBackPress={() => gotToTab(1)}
            />
          )}
        </Skeleton>
      ) : (
        <Skeleton isLoaded={hasApplicationsToLog} h={'340px'}>
          {hasApplicationsToLog && (
            <ActivityLogPanel
              activitiesTotal={activities.length}
              appliedActivities={appliedActivities}
              key={appliedActivities.length}
              onGoBackPress={() => gotToTab(0)}
            />
          )}
        </Skeleton>
      )}
    </PresenceTransition>
  );
};

export default ActivityInteractiveTabs;
