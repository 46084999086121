import { useEffect, useMemo, useState } from 'react';

import PostHogFeatureFlagService from '~/services/feature-flag/posthogService';
import {
  FeatureFlagKey,
  FeatureFlagProvider,
  IFeatureFlag,
} from '~/types/interfaces/feature-flag';

let featureFlagService: IFeatureFlag | null = null;
export const initializeFeatureFlag = (provider: FeatureFlagProvider) => {
  if (featureFlagService) {
    if (process.env.NODE_ENV === 'development') {
      console.warn('Feature Flag has already been initialized.');
    }
    return featureFlagService;
  }

  try {
    if (![FeatureFlagProvider.posthog].includes(provider)) {
      throw new Error('Unsupported Feature Flag provider');
    }

    if (provider === 'posthog') {
      featureFlagService = new PostHogFeatureFlagService();
    }

    if (process.env.NODE_ENV === 'development') {
      console.info(`Feature Flag provider: ${provider} initialized.`);
    }

    return featureFlagService;
  } catch (error) {
    if (process.env.NODE_ENV === 'development') {
      console.error(`Invalid Feature Flag  configuration`, error);
      throw error;
    }
    return null;
  }
};

const useFeatureFlag = (key: FeatureFlagKey) => {
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    if (!featureFlagService) {
      const service = initializeFeatureFlag(FeatureFlagProvider.posthog);
      if (!service) return;

      service.onFeatureFlags(() => {
        setIsInitialized(true);
      });
    }
  }, []);

  const isFeatureEnabled = useMemo(() => {
    if (!isInitialized) return false;

    const isEnabled = featureFlagService?.isFeatureEnabled(key);
    return isEnabled;
  }, [isInitialized, key]);

  return { isFeatureEnabled };
};

export default useFeatureFlag;
