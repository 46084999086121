import { extendTheme } from 'native-base';

export const { components } = extendTheme({
  components: {
    Text: {
      baseStyle: {
        fontFamily: 'Poppins',
      },
      sizes: {
        xl: {
          fontSize: '64px',
        },
        lg: {
          fontSize: '32px',
        },
        md: {
          fontSize: '16px',
        },
        sm: {
          fontSize: '12px',
        },
      },
    },
    Container: {
      defaultProps: {
        flexShrink: 1,
      },
    },
    Stack: {
      defaultProps: {
        flexShrink: 1,
      },
    },
    Box: {
      defaultProps: {
        flexShrink: 1,
      },
    },
    Input: {
      defaultProps: {
        p: 4,
      },
      baseStyle: {
        fontFamily: 'Poppins',
        borderRadius: '12px',
        focusOutlineColor: 'primary.400',
        _focus: {
          style: {
            borderColors: 'primary.400',
          },
        },
      },
    },
    Link: {
      baseStyle: {
        color: 'primary.400',
        fontFamily: 'Poppins',
      },
    },
    Button: {
      variants: {
        primary: () => ({
          bg: 'primary.600',
          borderRadius: '12px',
          _text: {
            color: 'singletons.white',
          },
        }),
        outline: () => ({
          bg: 'transparent',
          borderRadius: '12px',
          borderColor: 'primary.100',
          _text: {
            color: 'primary.400',
          },
          _pressed: { bg: 'transparent', opacity: 0.6 },
        }),
        ghost: () => ({
          _text: {
            color: 'singletons.black',
          },
          _pressed: { bg: 'transparent', opacity: 0.6 },
        }),
      },
      defaultProps: {
        flexShrink: 1,
        h: '37px',
        w: '100%',
        _text: {
          fontFamily: 'Poppins',
        },
        variant: 'primary',
        _icon: {
          color: 'singletons.black',
        },
      },
    },
  },
});
