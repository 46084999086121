import { useMemo, useState } from 'react';

import { IBaseSelectGroupProps } from '../BaseSelectGroup/types';
import { ISelectOptionsGroup, ISelectOptionsItem, SelectValue } from '../types';

export const useBaseSelectGroupController = ({
  values,
  options,
  uniqueOptions,
  onChange,
}: IBaseSelectGroupProps) => {
  const [stagedOption, setStagedOption] = useState<SelectValue>(null);

  const availableOptions = useMemo((): {
    options: ISelectOptionsGroup[];
    count: number;
  } => {
    if (!uniqueOptions) return { options, count: options.length };

    const selectedValues = new Set(values.map((opt) => opt.value));
    let availableOptionsCount = 0;

    const availableOptions = options.map(({ label, options }) => {
      const uniqueOptions = options.filter(
        (option) => !selectedValues.has(option.value),
      );
      availableOptionsCount += uniqueOptions.length;
      return { label, options: uniqueOptions };
    });

    return { options: availableOptions, count: availableOptionsCount };
  }, [values, options, uniqueOptions]);

  const handleOptionChange = (index: number, option: any) => {
    const prevValues = [...values];
    prevValues.splice(index, 1, option);
    onChange?.(prevValues);
  };

  const handleOptionDelete = (index: number) => {
    const prevValues = [...values];
    prevValues.splice(index, 1);
    onChange?.(prevValues);
  };

  const handleStagedOptionChange = (option: SelectValue) => {
    setStagedOption(option);
    handleNewOption(option);
  };

  const handleNewOption = (option: SelectValue) => {
    const _stagedOption = option || stagedOption;

    if (!_stagedOption) return;
    const prevValues = [...values];
    onChange?.([...prevValues, _stagedOption as ISelectOptionsItem]);
    handleStagedOptionChange(null);
  };

  return {
    values,
    stagedOption,
    availableOptions,
    handleStagedOptionChange,
    handleNewOption,
    handleOptionDelete,
    handleOptionChange,
  };
};
