import { Box, HStack } from 'native-base';
import AsyncSelect from 'react-select/async';

import { colors } from '../../../../theme/colors';
import Icon from '../../../Icon';
import CommonInputWrapper from '../../CommonInputWrapper';
import { LocationSuggestionItem } from '../LocationSuggestionItem';
import { useLocationAutoCompleteController } from './controller';
import { LocationAutoCompleteProps } from './types';

const EmptyIndicator = () => <></>;

export const LocationAutoComplete = (props: LocationAutoCompleteProps) => {
  const {
    label,
    helpText,
    placeholder,
    errorMessage,
    isRequired = false,
  } = props;

  const {
    value,
    isLoading,
    inputValue,
    customStyle,
    handleChange,
    handleLoadOptions,
    handleInputChange,
  } = useLocationAutoCompleteController(props);

  return (
    <CommonInputWrapper
      isRequired={isRequired}
      errorMessage={errorMessage}
      helpText={helpText}
      label={label}
    >
      <HStack alignItems={'center'} w="100%">
        <Box
          position={'absolute'}
          left={'10px'}
          top={0}
          bottom={0}
          margin={'auto'}
          h="fit-content"
          zIndex={9}
        >
          <Icon icon="map-pin" size={'18px'} color={colors.gray[600]} />
        </Box>

        <Box flex={1}>
          <AsyncSelect
            isClearable
            isMulti={false}
            isLoading={isLoading}
            value={value}
            inputValue={inputValue}
            onChange={handleChange}
            onInputChange={handleInputChange}
            loadOptions={handleLoadOptions}
            components={{
              DropdownIndicator: EmptyIndicator,
              IndicatorSeparator: EmptyIndicator,
            }}
            menuPortalTarget={document.body}
            formatOptionLabel={(data) => <LocationSuggestionItem data={data} />}
            placeholder={placeholder}
            styles={customStyle}
          />
        </Box>
      </HStack>
    </CommonInputWrapper>
  );
};

export default LocationAutoComplete;
