import { Box, VStack } from 'native-base';
import { useEffect, useRef, useState } from 'react';
import InputSearchLocationInline from 'ui/components/InputSearchLocationInline';
import { ILocation } from 'ui/types/interfaces';

import { useAppSelector } from '~/hooks/useAppSelector';
import { FilterDrawerScaffold } from '~/pages/Authenticated/InApp/components/FilterDrawerScaffold';
import { GooglePlacesService } from '~/services/google/google-places-api';

export type LocationModalProps = {
  isOpen: boolean;
  onClose: VoidFunction;
  onSubmit: (newLocation?: ILocation) => void;
  initialLocation?: ILocation | null;
};

export type LocationModalFormType = {
  searchValue: string;
  address: string;
};

const FIXED_SUGGESTIONS: {
  icon: string;
  description: string;
  placeId: string;
}[] = [
  /*
  { icon: 'navigation', description: 'Use my current location', placeId: '-1 ' },
  { icon: 'map-pin', description: 'Anywhere', placeId: '-2' },
  { icon: 'home', description: 'From home', placeId: '-3' }, */
];

const LocationModal = (props: LocationModalProps) => {
  const { isOpen = false, onClose, onSubmit, initialLocation } = props;

  // -- Providers
  const { selectedApp } = useAppSelector(({ inApp }) => inApp);

  // -- States
  const previousSelectedLocation = useRef(null);
  const [selectedLocation, setSelectedLocation] =
    useState<any>(initialLocation);

  // -- Hooks
  useEffect(() => {
    setSelectedLocation(initialLocation);
  }, [initialLocation]);

  // -- Handlers
  const handleLocationSelect = (selected: any) => {
    setSelectedLocation(selected);
  };

  const handleSubmitButton = () => {
    onSubmit(selectedLocation);
    setSelectedLocation(null);
    onClose();
  };

  const handlCancelButton = () => {
    setSelectedLocation(null);
    onClose();
  };

  return (
    <>
      <FilterDrawerScaffold
        title="Search Location"
        actionButtonLabel="Set Location"
        isOpen={isOpen}
        onClose={onClose}
        onAction={handleSubmitButton}
        onCancel={handlCancelButton}
        showActionButton={
          selectedLocation &&
          selectedLocation?.placeID !== previousSelectedLocation.current
        }
        brandColor={selectedApp?.brandColor}
      >
        <VStack space={2} justifyContent={'space-between'}>
          <Box width={'100%'}>
            <InputSearchLocationInline
              location={selectedLocation}
              fixedSugestions={FIXED_SUGGESTIONS}
              onFetch={GooglePlacesService.autoComplete}
              onDetail={GooglePlacesService.getGeocodeByPlaceId}
              onSelect={handleLocationSelect}
              placeholder="Type you localization here..."
              brandColor={selectedApp?.brandColor}
            />
          </Box>
        </VStack>
      </FilterDrawerScaffold>
    </>
  );
};

export default LocationModal;
