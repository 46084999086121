import { useContext } from 'react';
import { mountStoreDevtool } from 'simple-zustand-devtools';
import { createStore, useStore } from 'zustand';
import { useShallow } from 'zustand/react/shallow';

import {
  ActivityViewContext,
  ActivityViewFilters,
  ActivityViewState,
  ActivityViewStateProps,
} from '~/pages/Authenticated/v2/ActivityView/store/types';
import { IUserActivity } from '~/pages/Authenticated/v2/ActivityView/types';
import { getLocationFromAddress } from '~/utils/getLocation';
export * from './types';

export const createActivityViewStore = (
  initProps: Pick<
    ActivityViewStateProps,
    'activityDefinition' | 'activityType' | 'activities' | 'activitiesMap'
  >,
) => {
  const DEFAULT_PROPS: ActivityViewStateProps = {
    activityDefinition: undefined,
    selectedActivity: undefined,
    activityType: undefined,
    activities: [],
    activitiesMap: new Map(),
    showCalendarDrawer: false,
    showLocationDrawer: false,
    showTeamDrawer: false,
    filters: {
      location: null,
      startDate: null,
    },
  };

  const store = createStore<ActivityViewState>()((set) => ({
    ...DEFAULT_PROPS,
    ...initProps,
    setFilters: (newFilters: ActivityViewFilters) =>
      set((state) => ({
        ...state,
        filters: newFilters,
      })),
    setSelectedActivity: (activity?: IUserActivity) => {
      const filters = {
        startDate: activity?.startDate ? new Date(activity.startDate) : null,
        location: activity?.address
          ? getLocationFromAddress(activity.address)
          : null,
      };
      set((state) => ({ ...state, selectedActivity: activity, filters }));
    },
    setShowCalendarDrawer: (value: boolean) => {
      set((state) => ({ ...state, showCalendarDrawer: value }));
    },
    setShowLocationDrawer: (value: boolean) => {
      set((state) => ({ ...state, showLocationDrawer: value }));
    },
    setShowTeamDrawer: (value: boolean) => {
      set((state) => ({ ...state, showTeamDrawer: value }));
    },
    reset: () => {
      set(() => DEFAULT_PROPS);
    },
  }));

  if (process.env.NODE_ENV === 'development') {
    mountStoreDevtool('Store', store);
  }

  return store;
};

export function useActivityViewStore<T>(
  selector: (state: ActivityViewState) => T,
): T {
  const store = useContext(ActivityViewContext);
  if (!store)
    throw new Error('Missing ActivityViewContext.Provider in the tree');
  return useStore(store, useShallow(selector));
}
