import { Checkbox, Flex, HStack, Text, VStack } from 'native-base';
import { Controller } from 'react-hook-form';

import { styles } from './styles';
import { StepOptionProps } from './types';

export const StepOption = ({ index, control }: StepOptionProps) => {
  return (
    <Controller
      control={control}
      name={`steps.${index}`}
      render={({ field: { onChange, value } }) => {
        const handleOnChange = (event: React.MouseEvent<HTMLElement>) => {
          event.preventDefault();
          onChange({ ...value, isChecked: !value.isChecked });
        };

        return (
          <span
            style={styles.spanCursor}
            onClick={(e) => {
              value.hasLogged ? e.preventDefault() : handleOnChange(e);
            }}
          >
            <HStack
              bg="white"
              px={3}
              py={3}
              rounded="xl"
              borderWidth="1"
              borderColor="gray.300"
              space={5}
              alignItems="center"
              bgColor="white"
            >
              <Checkbox
                value="true"
                isChecked={value.isChecked}
                accessibilityLabel={value.description || ''}
                isDisabled={value.hasLogged}
              />
              <VStack space={2} flex={1}>
                <HStack space={2}>
                  <Flex
                    justifyContent="center"
                    alignItems="center"
                    bg="darkText"
                    rounded="full"
                    w="6"
                    h="6"
                  >
                    <Text color="white" fontWeight={500}>
                      {index + 1}
                    </Text>
                  </Flex>
                  <VStack space={1} flex={1}>
                    <Text color="darkText" isTruncated fontWeight={500}>
                      {value.title}
                    </Text>
                    <Text color="gray.500" isTruncated numberOfLines={2}>
                      {value.description}
                    </Text>
                  </VStack>
                </HStack>
              </VStack>
            </HStack>
          </span>
        );
      }}
    />
  );
};
