import { useMemo } from 'react';

import { MakeFooterLocationNode, MakeSignUpTextNode } from '../utils';
import { ActionActivityCardProps } from './types';

export const useActionActivityCardController = ({
  isOnline,
  applicationsCount,
}: ActionActivityCardProps) => {
  const footerNodes = useMemo(() => {
    const nodes = [MakeSignUpTextNode(applicationsCount)];
    const location = MakeFooterLocationNode(undefined, true, isOnline);
    if (location) {
      nodes.unshift(location);
    }

    return nodes;
  }, [isOnline, applicationsCount]);

  return { footerNodes };
};
