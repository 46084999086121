import { Flex, Text } from 'native-base';
import { FiXCircle } from 'react-icons/fi';
import { ILLUSTRATIONS_NAME } from 'ui/components/Illustration';

import FlowTwoColumnsTemplate from '~/components/Templates/FlowTwoColumnsTemplate';
import { useDeleteAccountController } from '~/pages/Authenticated/Settings/DeleteAccount/useDeleteAccountController';
import { colors } from '~/theme/colors';

export const DeleteAccount = () => {
  const { isLoading, handleConfirmDeleteAccount, goBack } =
    useDeleteAccountController();
  return (
    <Flex minH="full" w="full">
      <FlowTwoColumnsTemplate
        ilustrationName={ILLUSTRATIONS_NAME.ERROR_WEB_DEVELOPMENT}
        title="Do you really want to delete your account?"
        icon={<FiXCircle size={32} />}
        primaryButtonText="Yes, delete account"
        primaryButtonProps={{ bg: colors.error[600] }}
        primaryButtonOnPress={handleConfirmDeleteAccount}
        primaryButtonTextStyle={{ fontSize: 'md' }}
        secondaryButtonText="No, go back"
        secondaryButtonOnPress={goBack}
        secondaryButtonTextStyle={{ fontSize: 'md' }}
        buttonsDirection="column-reverse"
        progress={100}
        progressProps={{ h: '2px' }}
        isLoading={isLoading}
      >
        <Flex flex={1} flexShrink={0} flexGrow={1} w="full" px={4} py={8}>
          <Text fontSize="lg" color="singletone.darkText">
            Once you delete your Do IT account, it can’t be restored. You’ll
            lose access to your activities and all your data will be permanently
            deleted.
          </Text>
        </Flex>
      </FlowTwoColumnsTemplate>
    </Flex>
  );
};
