import { sanitizeQueryParams } from 'ui/utils/queryParams';

import { publicApi } from '~/services/api';
import { ACTIVITY_DEFINITION_SEARCH_MODULE_URL } from '~/services/resources/activity-definitions/search/constants';
import { ActivityDefinitionPublicSearchResponse } from '~/services/resources/activity-definitions/search/types';

import {
  EventFromHomeSearchFilters,
  EventInPersonSearchFilters,
} from './events-public-search.types';

export class ActivityDefinitionEventsPublicSearch {
  static BASE_URL = `${ACTIVITY_DEFINITION_SEARCH_MODULE_URL}/event`;

  static async findInPersonEvents(
    filters: EventInPersonSearchFilters,
  ): Promise<ActivityDefinitionPublicSearchResponse[]> {
    try {
      const url = `${ActivityDefinitionEventsPublicSearch.BASE_URL}/in-person`;
      const queryParams = sanitizeQueryParams(filters as Record<string, any>);

      const { data } = await publicApi.get(url, {
        params: queryParams,
      });

      const responseData = data || [];
      return responseData;
    } catch (error) {
      return [];
    }
  }

  static async findFromHomeEvents(
    filters: EventFromHomeSearchFilters,
  ): Promise<ActivityDefinitionPublicSearchResponse[]> {
    try {
      const url = `${ActivityDefinitionEventsPublicSearch.BASE_URL}/from-home`;
      const queryParams = sanitizeQueryParams(filters as Record<string, any>);

      const { data } = await publicApi.get(url, {
        params: queryParams,
      });
      return data || [];
    } catch (error) {
      return [];
    }
  }
}
