import FeatherIcon from 'feather-icons-react';
import { Box, Image } from 'native-base';
import { useMemo } from 'react';

import { IconSVG } from './constants';
import { IconEnum, IIcon } from './types';

const Icon = (props: IIcon) => {
  const {
    icon = '',
    size,
    color,
    isCustom = false,
    enableAutoCustom = false,
    alt = '',
    ...restProps
  } = props;

  const customIcon = useMemo(() => IconSVG[icon] ?? null, [icon]);

  const isCustomIcon = useMemo(() => {
    if (isCustom && customIcon) return true;
    return enableAutoCustom === true && !!customIcon;
  }, [isCustom, enableAutoCustom, customIcon]);

  if (!icon) return null;
  return (
    <>
      {isCustomIcon ? (
        <Image
          // NOTE: Image component of nativebase need use uri to rerender image
          source={{ uri: customIcon }}
          size={size}
          color={color}
          alt={alt}
          tintColor={color}
          {...restProps}
        />
      ) : (
        <Box>
          <FeatherIcon icon={icon} size={size} color={color} {...restProps} />
        </Box>
      )}
    </>
  );
};

export default Icon;
export { IconEnum };
