import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { PAGES } from '~/constants/pages.constants';
import { useAuth } from '~/hooks/useAuth';
import { useRouter } from '~/hooks/useRouter';
import AuthService from '~/services/resources/auth';

import { COMMON_MESSAGES } from '../../../../constants/messages.constants';
import { schemaValidation } from './constants';
import { IAccessCodeFormFields } from './types';

export const useAccessCodeController = () => {
  const { goToRoute, location, replaceRoute } = useRouter();
  const [userEmail] = useState(location?.state?.email || '');
  const [userPassword] = useState(location?.state?.password || '');

  const isForgotPassword = location?.state.isForgotPassword;
  const isUserUnverified = location?.state.isUserUnverified;

  const [isLoading, setIsLoading] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IAccessCodeFormFields>({
    resolver: yupResolver(schemaValidation),
  });
  const { handleSignIn } = useAuth();

  const onSubmit = handleSubmit(async (data) => {
    try {
      setIsLoading(true);

      if (isForgotPassword) {
        if (isUserUnverified) {
          const response = await AuthService.confirmSignUp({
            email: userEmail,
            accessCode: data.accessCode,
            password: userPassword.trim(),
          });

          goToRoute(PAGES.ConfirmEmail, {
            state: {
              email: userEmail,
            },
          });
          toast.info(COMMON_MESSAGES.ACCOUNT_VERIFIED);
        } else {
          goToRoute(PAGES.ResetPassword, {
            state: {
              email: userEmail,
              password: userPassword.trim(),
              accessCode: data.accessCode,
            },
          });
        }
      } else {
        const response = await AuthService.confirmSignUp({
          email: userEmail,
          accessCode: data.accessCode,
          password: userPassword.trim(),
        });

        if (response) {
          await handleSignIn({
            email: userEmail,
            password: userPassword.trim(),
          });
        }
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  });

  const handleResendCode = async () => {
    try {
      setIsLoading(true);

      if (isForgotPassword) {
        await AuthService.requestForgotPassword({
          email: userEmail,
        });
      } else {
        await AuthService.resendConfirmationCode({ email: userEmail });
      }
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleGoBackToSignIn = () => {
    replaceRoute(PAGES.Root);
  };

  return {
    control,
    errors,
    userEmail,
    handleResendCode,
    handleGoBackToSignIn,
    onSubmit,
    isLoading,
  };
};
