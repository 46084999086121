import 'react-datepicker/dist/react-datepicker.css';
import './styles.css';

import { Box, HStack } from 'native-base';
import { useMemo } from 'react';
import ReactDatePicker from 'react-datepicker';
import Icon from 'ui/components/Icon';
import CommonInputWrapper from 'ui/components/v2/CommonInputWrapper';
import { BaseTextInput } from 'ui/components/v2/inputs/TextInput/BaseTextInput';
import { colors } from 'ui/theme/colors';
import { safeDateFormat } from 'ui/utils/formatDates';

import { IDatePickerProps } from './types';

export const DatePicker = (props: IDatePickerProps) => {
  const { portalId, dateFormat = 'dd/MM/yyyy' } = props;
  const {
    value,
    label,
    maxDate,
    name,
    helpText,
    isRequired,
    placeholder = 'Select Date',
    errorMessage,
    showTimeSelect,
    timeIntervals,
    usePortal = false,
    isDisabled = false,
    onChange,
  } = props;

  const handleChange = (date: Date | null) => {
    onChange?.(date, name);
  };

  const value_str = useMemo(
    () => safeDateFormat(value, dateFormat),
    [dateFormat, value],
  );

  return (
    <CommonInputWrapper
      isRequired={isRequired}
      label={label}
      errorMessage={errorMessage}
      helpText={helpText}
    >
      <ReactDatePicker
        name={name}
        selected={value}
        maxDate={maxDate}
        onChange={handleChange}
        disabled={isDisabled}
        dateFormat={dateFormat}
        placeholderText={placeholder}
        timeIntervals={timeIntervals}
        showTimeSelect={showTimeSelect}
        portalId={portalId || 'root-portal'}
        withPortal={usePortal}
        closeOnScroll
        customInput={
          <label>
            <HStack width={'full'}>
              <BaseTextInput
                placeholder={placeholder}
                fontSize={'sm'}
                value={value_str}
                isReadOnly
              />
              <Box marginLeft={-8} marginTop={'auto'} marginBottom={'auto'}>
                <Icon icon="calendar" size={'16px'} color={colors.muted[400]} />
              </Box>
            </HStack>
          </label>
        }
      />
    </CommonInputWrapper>
  );
};
