import { yupResolver } from '@hookform/resolvers/yup';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';

import { groupMeasurementsByUnit } from '~/utils/measurements/format';

import { schemaValidation } from './constants';
import { IOffPlatformLogFields, IOffPlatformLogFormProps } from './types';

export const useOffPlatformLogFormController = (
  props: IOffPlatformLogFormProps,
) => {
  const {
    onCancel,
    onSubmit,
    initialLogFormData,
    isLoading = false,
    measurementDefinition,
  } = props;

  const { control, handleSubmit, formState } = useForm<IOffPlatformLogFields>({
    resolver: yupResolver(schemaValidation),
    defaultValues: { ...initialLogFormData },
  });

  const onSubmitHandler = handleSubmit((data) => {
    onSubmit(data);
  });

  const onCancelHandler = () => {
    onCancel();
  };

  const measurementFields = initialLogFormData?.measurements || [];

  const groupedMeasurementsHistory = useMemo(() => {
    const measurements = measurementDefinition?.measurements || [];
    return groupMeasurementsByUnit(measurements);
  }, [measurementDefinition]);

  const isEditMode = measurementDefinition;

  return {
    onCancelHandler,
    onSubmitHandler,
    groupedMeasurementsHistory,
    measurementFields,
    isLoading,
    isEditMode,
    formState,
    control,
  };
};
