import { useEffect, useState } from 'react';
import { MultiValue, SingleValue } from 'react-select';

import { ILocation } from '../../../../types/interfaces';
import { parseLocationToSuggestion } from '../../LocationInputs/utils';
import { LocationSuggestion } from '../types';
import { defaultStyle } from './styles';
import { LocationAutoCompleteProps } from './types';

export const useLocationAutoCompleteController = (
  props: LocationAutoCompleteProps,
) => {
  const { provider, defaultValue, onChange, errorMessage } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const [value, setValue] = useState<LocationSuggestion | null>(
    parseLocationToSuggestion(defaultValue),
  );

  useEffect(() => {
    if (!defaultValue) return;
    setValue(parseLocationToSuggestion(defaultValue));
  }, [defaultValue]);

  const handleChange = async (
    newValue: SingleValue<LocationSuggestion> | MultiValue<LocationSuggestion>,
  ) => {
    const suggestion = newValue as LocationSuggestion;
    setValue(suggestion || null);
    setIsLoading(true);

    try {
      if (!suggestion?.value) {
        throw new Error('PlaceId not found');
      }

      const data = await provider.onDetailLoad(suggestion.value);
      if (!data) {
        throw new Error('Geo coordinates not found');
      }

      const newLocation: ILocation = {
        lat: data.lat,
        lng: data.lng,
        placeID: suggestion.value,
        rawLocation: suggestion.label,
      };

      onChange(newLocation);
    } catch (error) {
      onChange(null);
    } finally {
      setIsLoading(false);
    }
  };

  const handleLoadOptions = (textInput: string) => {
    return provider.onAutoCompleteLoad(textInput);
  };

  const handleInputChange = (newInputValue: string) => {
    setInputValue(newInputValue);
  };

  const customStyle = defaultStyle({ hasError: !!errorMessage });

  return {
    value,
    isLoading,
    inputValue,
    customStyle,
    handleChange,
    handleLoadOptions,
    handleInputChange,
  };
};
