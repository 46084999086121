import {
  Circle,
  Flex,
  HStack,
  Pressable,
  Stack,
  Text,
  VStack,
} from 'native-base';
import React from 'react';
import Icon from 'ui/components/Icon';
import { colors } from 'ui/theme/colors';

import { BadgeProps, TabProps } from '~/components/Tabs/tabs.types';

const Badge = ({ badgeNumber }: BadgeProps) => {
  return (
    <Circle
      bg={'singletons.black'}
      position={{ base: 'absolute', lg: 'relative' }}
      top={{ base: -5, lg: 0 }}
      right={{ base: -10, lg: 0 }}
      w={'18px'}
      h={'18px'}
    >
      <Text color={'singletons.white'} fontSize={'2xs'} fontWeight={500}>
        {badgeNumber}
      </Text>
    </Circle>
  );
};

export const Tab = ({
  label,
  icon,
  description,
  isCustomIcon,
  isActive,
  onPress,
  badgeNumber,
  rightElement,
}: TabProps) => {
  // When we use a custom icon, we use rem instead of px
  const iconSize = isCustomIcon ? 6 : 24;

  return (
    <Pressable flex={1} onPress={onPress} w={'100%'}>
      <HStack
        h={'fit-content'}
        bg={isActive ? 'singletons.white' : 'transparent'}
        flexDir={{ base: 'column', lg: 'row' }}
        borderBottomWidth={isActive ? 2 : 0}
        borderBottomColor={'singletons.black'}
        p={4}
        alignItems={'center'}
        space={3}
        borderTopRadius={8}
        overflow={'hidden'}
      >
        <Stack
          flex={'0 0 1'}
          position={'relative'}
          color={'gray.600'}
          minH={30}
        >
          {!!badgeNumber && badgeNumber > 0 ? (
            <Flex
              position={'relative'}
              display={{
                base: 'flex',
                lg: 'none',
              }}
            >
              <Icon
                size={iconSize}
                icon={icon}
                color={isActive ? 'singletons.black' : colors.gray[400]}
                isCustom={isCustomIcon}
              />
              <Badge badgeNumber={badgeNumber} />
            </Flex>
          ) : (
            <Icon
              size={iconSize}
              icon={icon}
              color={isActive ? 'singletons.black' : colors.gray[400]}
              isCustom={isCustomIcon}
            />
          )}
          {badgeNumber ? (
            <Stack display={{ base: 'none', lg: 'flex' }}>
              <Icon
                size={iconSize}
                icon={icon}
                color={isActive ? 'singletons.black' : colors.gray[400]}
                isCustom={isCustomIcon}
              />
            </Stack>
          ) : null}
        </Stack>
        <VStack
          space={1}
          flex={1}
          overflow={'hidden'}
          display={{ base: 'none', lg: 'flex' }}
        >
          <HStack alignItems={'center'} space={2}>
            <Text fontSize={'md'} fontWeight={500} noOfLines={1}>
              {label}
            </Text>
            {!!badgeNumber && badgeNumber > 0 && (
              <Badge badgeNumber={badgeNumber} />
            )}
          </HStack>
          <Text color={'gray.600'} fontSize={'xs'} noOfLines={1}>
            {description}
          </Text>
        </VStack>
        {!!rightElement && (
          <Stack
            flex={1}
            display={{ base: 'none', lg: 'flex' }}
            alignItems={'flex-end'}
          >
            {rightElement}
          </Stack>
        )}
      </HStack>
    </Pressable>
  );
};
