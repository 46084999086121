import { Box, Flex } from 'native-base';

import { NavBar } from '~/components/NavBar';

export const ActivityViewLayout = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  return (
    <Flex bg={'gray.100'} h={'100vh'}>
      <Flex bg={'singletons.white'} h={'100vh'}>
        <NavBar hideBackButton={false} hideMobileNavBar />
        <Box bg={'gray.100'} h={'100%'}>
          {children}
        </Box>
      </Flex>
    </Flex>
  );
};
