import './style.css';

import { HStack, Text, VStack } from 'native-base';
import React, { ReactNode } from 'react';
import placeholderImage from 'ui/assets/svgs/placeholder.svg';
import Icon from 'ui/components/Icon';
import { CardCoverImage } from 'ui/components/v2/Cards/components/CardCoverImage';
import { colors } from 'ui/theme/colors';

export type ActivityBaseCardProps = {
  children?: ReactNode;
  coverImageUrl?: string;
  coverImagePlaceHolder?: string;
  showCoverImage?: boolean;
  coverImageCaption?: ReactNode;
  footerNodes: { icon?: string; label: string }[];
  maxWidth?: string | number;
  onPress?: VoidFunction;
};

export const ActivityBaseCard = ({
  children,
  footerNodes,
  coverImageUrl,
  coverImageCaption,
  showCoverImage,
  maxWidth = 400,
  coverImagePlaceHolder = placeholderImage,
  onPress,
}: ActivityBaseCardProps) => {
  return (
    <div
      className="activity-card-base"
      style={{ maxWidth: maxWidth }}
      onClick={onPress}
    >
      {/* Cover Image */}
      {showCoverImage && (
        <CardCoverImage
          borderRadius={8}
          ratio={68}
          imageUrl={coverImageUrl}
          caption={coverImageCaption}
          imageFallBackUrl={coverImagePlaceHolder}
          backgroundColor="gray.100" //rgba(39, 39, 42, 0.8)
        />
      )}

      {/* Body */}
      <VStack px={1}>{children}</VStack>

      {/* Footer */}
      <VStack
        px={1}
        pt={2}
        borderTopColor={'gray.200'}
        marginBottom={0}
        marginTop={'auto'}
      >
        <HStack justifyContent={'flex-start'} alignItems={'center'}>
          {footerNodes?.map(({ label, icon }, i) => (
            <React.Fragment key={i}>
              {i > 0 && (
                <Text
                  fontSize={'sm'}
                  lineHeight={'100%'}
                  color={'gray.600'}
                  fontWeight={400}
                  px={2}
                >
                  {'|'}
                </Text>
              )}

              <HStack space={1} alignItems={'center'}>
                {icon && (
                  <Icon
                    icon={icon}
                    size={'14px'}
                    enableAutoCustom
                    color={colors.gray[400]}
                  />
                )}
                <Text fontSize={'10px'} color={'gray.600'} numberOfLines={1}>
                  {label}
                </Text>
              </HStack>
            </React.Fragment>
          ))}
        </HStack>
      </VStack>
    </div>
  );
};
