import { api } from '~/services/api';
import { IEcosystemFindAllDto, IEcosystemJoinUserDto } from '~/types/dtos';

export default class EcosystemService {
  static URL = '/ecosystem';
  static PUBLIC_URL = '/ecosystem/public';

  static async findAll({ filter }: IEcosystemFindAllDto) {
    return await api.get(this.URL, {
      params: { filter: JSON.stringify(filter) },
    });
  }

  static async findOne({ id }: { id: string }) {
    return await api.get(`${this.URL}/${id}`);
  }

  static async findOnePublic({ id }: { id: string }) {
    return await api.get(`${this.PUBLIC_URL}/${id}`);
  }

  static async joinUser({
    ecosystemId,
    organizationId,
  }: IEcosystemJoinUserDto) {
    await api.post(`${this.URL}/join/user/${ecosystemId}`, {
      organization: organizationId,
    });
  }
}
