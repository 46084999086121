import { Pressable } from 'native-base';
import Button from 'ui/components/Button';
import Icon from 'ui/components/Icon';
import { colors } from 'ui/theme/colors';

export type PrimaryCarouselNavButtonProps = {
  iconName: string;
  onPress?: VoidFunction;
  isDisabled?: boolean;
};
export const PrimaryCarouselNavButton = ({
  onPress,
  iconName,
  isDisabled,
}: PrimaryCarouselNavButtonProps) => {
  return (
    <Button
      p={0}
      minW={'unset'}
      variant="link"
      h={{ base: '42px', md: '48px' }}
      w={{ base: '42px', md: '48px' }}
      borderRadius={100}
      onPress={onPress}
      borderColor={colors.singletons.black}
      borderWidth={1}
      justifyContent={'center'}
      alignItems={'center'}
      isDisabled={isDisabled}
    >
      <Icon icon={iconName} size={24} />
    </Button>
  );
};
