import { Box, Flex, HStack, VStack } from 'native-base';
import AppSwitcher from 'ui/components/v2/AppInputs/AppSwitcher';
import SearchAutoComplete from 'ui/components/v2/SearchInputs/SearchAutoComplete';

import {
  CenterContainer,
  CenterContainerSize,
} from '~/components/Layouts/Scaffolds/CenterContainer';
import { ActivityFilterModal } from '~/components/Layouts/v2/SearchLayout/components/Modals/ActivityFilterModal';
import { STATIC_SEARCH_SUGGESTIONS_VALUES } from '~/components/Layouts/v2/SearchLayout/constants/search-suggestions';
import { useInAppHeaderController } from '~/pages/Authenticated/v2/InApp/components/Header/controller';

export const InAppHeader = () => {
  const {
    app,
    inputLabel,
    defaultTextValue,
    selectedAppOption,
    appSwitcherOptions,
    provisionalSearchFilters,
    isSearchAutoCompleteDisabled,
    provisionalSearchFiltersCount,
    handleAppChange,
    handleGoToAllApps,
    handleSearchSubmit,
    handleFilterButtonPress,
    handleFilterModalSubmit,
    handleSearchAutoCompleteChange,
  } = useInAppHeaderController();

  const brandColor = app?.brandColor || '';
  return (
    <VStack py={{ base: 4, md: 6 }} zIndex={2}>
      <CenterContainer size={CenterContainerSize.xl}>
        <Flex
          flexDirection={{ base: 'column', md: 'row' }}
          alignItems={{ base: 'flex-start', md: 'center' }}
        >
          <Box
            h="full"
            alignItems={'center'}
            justifyContent={'flex-start'}
            mr={{ base: 0, md: 8 }}
            mb={{ base: 2, md: 0 }}
          >
            <AppSwitcher
              key={selectedAppOption?.value}
              options={appSwitcherOptions}
              selected={selectedAppOption}
              onChange={handleAppChange}
              onAllAppsPress={handleGoToAllApps}
            />
          </Box>

          <HStack
            w="full"
            flex={1}
            alignItems={'flex-end'}
            justifyContent={'flex-end'}
          >
            <VStack w="full" maxW={{ base: 'unset', md: '640px' }}>
              <SearchAutoComplete
                label={inputLabel}
                onSubmit={handleSearchSubmit}
                onInputChange={handleSearchAutoCompleteChange}
                defaultValue={defaultTextValue}
                key={defaultTextValue}
                placeholder={
                  "Search for anything you'd like to help with — activities, causes, skills..."
                }
                suggestionValues={STATIC_SEARCH_SUGGESTIONS_VALUES}
                showFilterButton
                onFilterButtonPress={handleFilterButtonPress}
                buttonBackGroundColor={brandColor}
                isDisabled={isSearchAutoCompleteDisabled}
                filtersCount={provisionalSearchFiltersCount}
              />
            </VStack>
          </HStack>
        </Flex>
      </CenterContainer>

      {/* Filter Modal */}
      <ActivityFilterModal
        onSubmit={handleFilterModalSubmit}
        submitButtonLabel="Save and Close"
        defaultFilters={provisionalSearchFilters || undefined}
      />
    </VStack>
  );
};
