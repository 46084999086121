import { Auth } from 'aws-amplify';

import { COGNITO_AUTHENTICATION_FLOW } from '~/enums';
import {
  IAuthDTO,
  IAuthResponseDTO,
  IChangePasswordDTO,
  IConfirmEmail,
  IConfirmForgotPassword,
  IConfirmUpdatedEmailCode,
  IRequestForgotPassword,
  ISignUpDTO,
} from '~/types/dtos';
import { IUser } from '~/types/interfaces/user';

import { publicApi } from '../api';

Auth.configure({
  authenticationFlowType: COGNITO_AUTHENTICATION_FLOW.USER_PASSWORD_AUTH,
  region: import.meta.env.VITE_AWS_REGION,
  userPoolId: import.meta.env.VITE_AWS_USER_POOL_ID,
  userPoolWebClientId: import.meta.env.VITE_AWS_USER_POOL_WEB_CLIENT_ID,
});

export default class AuthService {
  static async signInWithEmailAndPassword(
    data: IAuthDTO,
  ): Promise<IAuthResponseDTO> {
    return await Auth.signIn(data.email, data.password);
  }

  static async signUpWithEmailAndPassword({
    email,
    password,
    attributes,
  }: ISignUpDTO) {
    return await Auth.signUp({
      username: email,
      password,
      attributes,
    });
  }

  static async signOut() {
    return await Auth.signOut();
  }

  static async isUserVerified(email: string) {
    return publicApi.get(`cognito/is-user-verified/${email}`);
  }

  static async requestForgotPassword({ email }: IRequestForgotPassword) {
    return await Auth.forgotPassword(email);
  }

  static async confirmForgotPassword({
    email,
    newPassword,
    code,
  }: IConfirmForgotPassword) {
    return await Auth.forgotPasswordSubmit(email, code, newPassword);
  }

  static async confirmSignUp({ email, accessCode }: IConfirmEmail) {
    return await Auth.confirmSignUp(email, accessCode);
  }

  static async resendConfirmationCode({ email }: IRequestForgotPassword) {
    return await Auth.resendSignUp(email);
  }

  static async updateUserAttributes({ email }: IRequestForgotPassword) {
    const user = await Auth.currentAuthenticatedUser();
    return await Auth.updateUserAttributes(user, {
      email,
    });
  }

  static async confirmUpdatedEmailCode({
    accessCode,
  }: IConfirmUpdatedEmailCode) {
    return await Auth.verifyCurrentUserAttributeSubmit('email', accessCode);
  }

  static async changePassword(
    user: IUser,
    { oldPassword, newPassword }: IChangePasswordDTO,
  ) {
    return await Auth.changePassword(user, oldPassword, newPassword);
  }
}
