import { HomeWidgetBase, WIDGET_COLORS } from '../Base';
import { useHomeWidgetFeaturedActivityController } from './controller';
import { HomeWidgetFeaturedActivityTabsFactory } from './Factory';

export const HomeWidgetFeaturedActivity = () => {
  const { data, isLoading, preventRender, onCardPress } =
    useHomeWidgetFeaturedActivityController({});

  if (preventRender) return null;

  return (
    <HomeWidgetBase
      isLoading={isLoading}
      minWidth={{ base: '240px', md: '312px' }}
      maxWidth={'312px'}
      title="Featured Activity"
      headerBackgroundColor={WIDGET_COLORS.blue}
      onPress={onCardPress}
    >
      {HomeWidgetFeaturedActivityTabsFactory({
        onPress: onCardPress,
        data: data,
      })}
    </HomeWidgetBase>
  );
};
