import { yupResolver } from '@hookform/resolvers/yup';
import { useBreakpointValue } from 'native-base';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { IModalRefProps } from 'ui/components/Modals/Modal/types';

import {
  CognitoError,
  CognitoErrorCode,
  CognitoErrorCodeMessages,
} from '~/constants/error.constants';
import {
  COMMON_MESSAGES,
  SIGN_UP_MESSAGES,
} from '~/constants/messages.constants';
import { PAGES } from '~/constants/pages.constants';
import { useAppDispatch } from '~/hooks/useAppDispatch';
import { useLoadPublicMemberInvite } from '~/hooks/useLoadPublicMemberInvite';
import { useRouter } from '~/hooks/useRouter';
import { SignUpFormFields } from '~/pages/Unauthenticated/JoinAsMemberSignUp/types';
import AuthService from '~/services/resources/auth';
import UserService from '~/services/resources/user';
import { authSliceActions } from '~/store/slices/auth';
import { redirectSliceActions } from '~/store/slices/redirect';

import { defaultValues, schemaValidation } from './constants';

export const useJoinAsMemberSignUp = () => {
  const [step, setStep] = useState(0);
  const [isSubmittingCreateAccount, setIsSubmittingCreateAccount] =
    useState(false);
  const [isResendCodeDisabled, setIsResendCodeDisabled] = useState(false);
  const [isSubmittingResendCode, setIsSubmittingResendCode] = useState(false);
  const [isSubmittingVerifyCode, setIsSubmittingVerifyCode] = useState(false);
  const [coolDownTime, setCoolDownTime] = useState(60);

  const isMobile = useBreakpointValue({ base: true, md: false });

  const modalTermsOfServiceRef = useRef<IModalRefProps>(null);
  const modalPrivacyPolicyRef = useRef<IModalRefProps>(null);
  const denyModalRef = useRef<IModalRefProps>(null);

  const currentValidationSchema = schemaValidation[step];

  const {
    goToRoute,
    replaceRoute,
    params: { code },
    location,
  } = useRouter();
  const dispatch = useAppDispatch();

  const {
    control,
    handleSubmit,
    formState: { errors, isValid, isDirty },
    getValues,
    setError,
  } = useForm<SignUpFormFields>({
    resolver: yupResolver(currentValidationSchema),
    defaultValues,
    mode: 'all',
  });

  const externalId = getValues('externalId');

  const { data: inviteCode, isLoading: isLoadingInviteCode } =
    useLoadPublicMemberInvite(code);

  const hasExternalId =
    inviteCode?.sharerOrganization?.organizationSettings?.hasExternalId;

  const isDisabled = !isValid || !isDirty || isSubmittingCreateAccount;
  const email = getValues('email');

  const handleNext = () => {
    if (step === 2) return;
    setStep((prev) => prev + 1);
  };

  const handleBack = () => {
    if (step === 0) return;
    setStep((prev) => prev - 1);
  };

  const onSignInPress = () => {
    goToRoute(location.pathname.replace('signup', 'sign-in'));
  };

  const handleOpenTermsOfService = () => modalTermsOfServiceRef.current?.open();
  const handleOpenPrivacyPolicy = () => modalPrivacyPolicyRef.current?.open();
  const closeDenyModal = () => denyModalRef?.current?.close();

  const handleDeny = async () => {
    dispatch(redirectSliceActions.removeAcceptInviteRedirect());
    replaceRoute(PAGES.GuestOpportunities);
  };

  const openDenyModal = async () => {
    denyModalRef?.current?.open();
  };

  const handleCreateAccount = handleSubmit(async (data) => {
    try {
      if (hasExternalId && !externalId) {
        setError('externalId', {
          type: 'required',
          message: 'The field is required',
        });
        return;
      }
      setIsSubmittingCreateAccount(true);
      await AuthService.signUpWithEmailAndPassword({
        email: data.email,
        password: data.password.trim(),
        attributes: {
          name: `${data.firstName} ${data.lastName}`,
          phone_number: data.phoneNumber.replace(/\s/g, ''),
          'custom:ecosystemId':
            inviteCode?.configurations[0].ecosystemId._id || '',
          'custom:platform': 'web',
        },
      });

      handleNext();
    } catch (err) {
      const error = err as CognitoError;
      if (error.code === CognitoErrorCode.UsernameExistsException) {
        toast.info(CognitoErrorCodeMessages[error.code]);
        goToRoute(PAGES.SignIn);
        return;
      }
      if (error.code === CognitoErrorCode.NotAuthorized) {
        toast.info(CognitoErrorCodeMessages.UsernameExistsException);
        goToRoute(PAGES.SignIn);
        return;
      }
      toast.error(SIGN_UP_MESSAGES.CREATE_ACCOUNT_ERROR);
    } finally {
      setIsSubmittingCreateAccount(false);
    }
  });

  const handleResendCode = async () => {
    try {
      const email = getValues('email');
      if (isResendCodeDisabled) return;
      setIsSubmittingResendCode(true);
      await AuthService.resendConfirmationCode({ email });
      toast.success(COMMON_MESSAGES.ACCESS_CODE_SENT);
      setIsResendCodeDisabled(true);
    } catch (error) {
      toast.error(COMMON_MESSAGES.UNABLE_SEND_ACCESS_CODE);
      setIsResendCodeDisabled(false);
    } finally {
      setIsSubmittingResendCode(false);
    }
  };

  const onVerifyCode = handleSubmit(async (data) => {
    try {
      if (!data.accessCode) return;
      setIsSubmittingVerifyCode(true);
      const email = getValues('email');
      const password = getValues('password');
      await AuthService.confirmSignUp({
        email,
        accessCode: data.accessCode,
        password: password.trim(),
      });
      const response = await AuthService.signInWithEmailAndPassword({
        email,
        password: password.trim(),
      });

      dispatch(
        redirectSliceActions.addAcceptInviteRedirect({
          isRedirecting: true,
          externalId,
        }),
      );

      dispatch(authSliceActions.signIn(response));
      const { data: responseMeData } = await UserService.me();
      const userId = responseMeData._id;

      dispatch(authSliceActions.update({ _id: userId, isAuthenticated: true }));

      toast.success(COMMON_MESSAGES.CODE_VERIFIED_WITH_SUCCESS);

      const inviteURL = location.pathname.replace('signup', '');

      // FIXME: Temporary workaround for route availability after authentication
      // The inviteURL route is not immediately available after user authentication
      // due to the asynchronous nature of state updates and route rendering.
      // Remove this setTimeout once a proper solution is in place.
      setTimeout(() => {
        goToRoute(inviteURL);
      }, 1000);
    } catch (error) {
      toast.error(COMMON_MESSAGES.UNABLE_SEND_ACCESS_CODE);
    } finally {
      setIsSubmittingVerifyCode(false);
    }
  });

  useEffect(() => {
    if (!isResendCodeDisabled) return;
    const interval = setInterval(() => {
      if (coolDownTime === 1) {
        setIsResendCodeDisabled(false);
        setCoolDownTime(60);
        return;
      }
      setCoolDownTime((current) => current - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [coolDownTime, isResendCodeDisabled, setCoolDownTime]);

  return {
    step,
    modalTermsOfServiceRef,
    modalPrivacyPolicyRef,
    control,
    isSubmittingResendCode,
    isSubmittingCreateAccount,
    isSubmittingVerifyCode,
    isDisabled,
    errors,
    isLoadingInviteCode,
    inviteCode,
    coolDownTime,
    isResendCodeDisabled,
    email,
    denyModalRef,
    hasExternalId,
    isMobile,
    openDenyModal,
    handleDeny,
    closeDenyModal,
    handleOpenPrivacyPolicy,
    handleOpenTermsOfService,
    handleNext,
    handleBack,
    handleCreateAccount,
    handleResendCode,
    onVerifyCode,
    onSignInPress,
  };
};
