import { useMediaQuery } from 'native-base';
import { useDispatch } from 'react-redux';

import { PAGES } from '~/constants/pages.constants';
import { useRouter } from '~/hooks/useRouter';
import { redirectSliceActions } from '~/store/slices/redirect';

export const useInvitationDeclinedController = () => {
  const { goToRoute } = useRouter();
  const dispatch = useDispatch();
  const [isMobile] = useMediaQuery({
    maxWidth: 480,
  });
  const handleDecline = () => {
    dispatch(redirectSliceActions.removeAcceptInviteRedirect());
    goToRoute(PAGES.Root);
  };

  return { handleDecline, isMobile };
};
