import { HStack, Text } from 'native-base';
import Icon from 'ui/components/Icon';
import { colors } from 'ui/theme/colors';

import { InfoLabelProps } from '~/pages/Authenticated/v2/ActivityView/components/ActivityInfoCard/components/types';

export const InfoLabel = ({
  text,
  icon,
  fontColor = colors.darkText,
  iconColor = colors.gray[400],
  hightLight = false,
  numberOfLines = 1,
}: InfoLabelProps) => {
  return (
    <HStack space={2} alignItems={'center'}>
      {icon && <Icon icon={icon} size={18} color={iconColor} />}
      <Text
        numberOfLines={numberOfLines}
        fontSize={'sm'}
        fontWeight={hightLight ? 500 : 400}
        color={fontColor}
        flex={1}
      >
        {text}
      </Text>
    </HStack>
  );
};
