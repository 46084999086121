/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Avatar,
  Box,
  Flex,
  Hidden,
  HStack,
  Pressable,
  Stack,
  Text,
  Tooltip,
} from 'native-base';
import Button from 'ui/components/Button';
import Icon, { IconEnum } from 'ui/components/Icon';

import { ActivitySummaryTitle } from '~/components/ActivityCards/CardRecommendations/components/ActivitySummaryTitle';
import { CardRecommendationsHeader } from '~/components/ActivityCards/CardRecommendations/components/CardRecommendationsHeader';
import { LogProgressAndHistory } from '~/compound-components/log-progress-and-history/log-progress-and-history.composition';
import { LogProgressAndHistoryCardContentVariations } from '~/compound-components/log-progress-and-history/log-progress-and-history-content/log-progress-and-history-content.types';
import { getNameInitials } from '~/utils/getInitials';

import { useLogActivityCardController } from './controller';
import { LogProgressAndHistoryProps } from './types';

export const LogActivityCard = <
  Variation extends LogProgressAndHistoryCardContentVariations,
>(
  props: LogProgressAndHistoryProps<Variation>,
) => {
  const {
    goalProgress,
    goalValue,
    listType,
    goalUnit,
    items,
    onGoingActivityTotalHours,
    activityName,
    activityType,
    appName,
    headerText,
    organizationLogo,
    organizationName,
    goalCategory,
    steps,
    totalStepsLogged,
    onLogActivityPress,
    primaryButtonIconName = 'plus-circle',
    primaryButtonText = 'Log activity',
    isExpandable = true,
    isAction,
    isEditPressed,
    onEditPress,
    isStep,
    isDisabled = false,
    actionToolTipLabel = '',
    isTeam = false,
  } = props;
  const { breakpointValue, isExpanded, toggleIsExpanded } =
    useLogActivityCardController({ isExpandable });

  return (
    <Pressable
      padding={4}
      borderRadius="xl"
      borderWidth="1px"
      borderColor="gray.300"
      bgColor="gray.100"
      flexShrink="none"
      style={
        {
          cursor:
            !breakpointValue.hideArrow && isExpandable ? 'pointer' : 'initial',
        } as any
      }
      onPress={() => {
        !breakpointValue.hideArrow &&
          isExpandable &&
          items?.length &&
          toggleIsExpanded();
      }}
    >
      <Flex
        flexDir={{ base: 'column', lg: 'row' }}
        width="100%"
        justifyContent="space-between"
        style={
          {
            gap: '24px',
          } as any
        }
      >
        <Stack maxW={{ base: '100%', lg: '80%' }} width="100%" space={2}>
          <HStack justifyContent="space-between">
            <CardRecommendationsHeader
              isTeamEvent={isTeam}
              activityType={activityType}
              headerText={headerText}
              headerTextColor="gray.600"
            />
            {!breakpointValue.hideArrow && isExpandable && (
              <Box pt={1}>
                <Icon
                  isCustom
                  size={6}
                  icon={IconEnum.CARET_DOWN}
                  color={'black'}
                />
              </Box>
            )}
          </HStack>
          <HStack
            space={4}
            width="100%"
            flexDir={{ base: 'column', lg: 'row' }}
          >
            <Hidden till="lg">
              <Avatar
                borderWidth="1px"
                borderColor="gray.200"
                rounded={12}
                height={12}
                width={12}
                source={{
                  uri: organizationLogo,
                }}
                _image={{
                  rounded: 12,
                }}
              >
                {getNameInitials(organizationName)}
              </Avatar>
            </Hidden>

            <Stack width="100%" space={4}>
              <HStack space={3}>
                <ActivitySummaryTitle
                  activityName={activityName}
                  appName={appName}
                  organizationName={organizationName}
                />
              </HStack>

              <LogProgressAndHistory.Root
                isExpandable={!!items?.length}
                isExpanded={isExpanded}
              >
                <LogProgressAndHistory.Card
                  goalUnit={goalUnit}
                  goalValue={goalValue}
                  goalProgress={goalProgress}
                  goalCategory={goalCategory}
                  onGoingActivityTotalHours={onGoingActivityTotalHours}
                  steps={steps}
                  totalStepsLogged={totalStepsLogged}
                  isAction={isAction}
                >
                  <LogProgressAndHistory.CardContent
                    isEditPressed={isEditPressed}
                    listType={listType}
                    items={items}
                  >
                    {onEditPress && (
                      <LogProgressAndHistory.ContentFooter
                        onPressEdit={onEditPress}
                        isStep={isStep}
                      />
                    )}
                  </LogProgressAndHistory.CardContent>
                </LogProgressAndHistory.Card>
              </LogProgressAndHistory.Root>
            </Stack>
          </HStack>
        </Stack>
        <Stack
          space={4}
          maxWidth={{ base: '100%', lg: '20%' }}
          width="100%"
          alignItems="flex-end"
        >
          <Tooltip
            label={actionToolTipLabel}
            display={!actionToolTipLabel ? 'none' : 'unset'}
          >
            <Stack maxWidth={{ base: '100%', lg: '184px' }} width="100%">
              <Button
                leftIconName={primaryButtonIconName}
                leftIconColor="white"
                leftIconSize={16}
                onPress={onLogActivityPress}
                isDisabled={isDisabled}
              >
                <Text color="white" fontWeight={500}>
                  {primaryButtonText}
                </Text>
              </Button>
            </Stack>
          </Tooltip>
        </Stack>
      </Flex>
    </Pressable>
  );
};
