import { useBreakpointValue } from 'native-base';

import { NonAuthenticatedHeaderProps } from '~/components/NavBar/components/NonAuthenticatedHeader/types';
import { PAGES } from '~/constants/pages.constants';
import { useRouter } from '~/hooks/useRouter';

export const useNonAuthHeaderController = ({
  isDefaultSignInEcosystem,
}: Partial<NonAuthenticatedHeaderProps>) => {
  const { replaceRoute } = useRouter();

  const logoWidth = useBreakpointValue({
    base: isDefaultSignInEcosystem ? '72px' : '112px',
    md: isDefaultSignInEcosystem ? '72px' : '56px',
  });

  const logoHeight = useBreakpointValue({
    base: isDefaultSignInEcosystem ? '42px' : '112px',
    md: isDefaultSignInEcosystem ? '42px' : '56px',
  });

  const handleGoToSignIn = () => replaceRoute(PAGES.Root);

  return { logoWidth, logoHeight, handleGoToSignIn };
};
