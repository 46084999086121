import { Box, Pressable } from 'native-base';
import { colors } from 'ui/theme/colors';

export const HomeWidgetNavButton = ({
  isSelected,
  onPress,
}: {
  isSelected: boolean;
  onPress: VoidFunction;
}) => {
  const backgroundColor = isSelected ? colors.gray[800] : colors.gray[400];

  return (
    <Pressable onPress={onPress}>
      <Box w={2} h={2} backgroundColor={backgroundColor} borderRadius={100} />
    </Pressable>
  );
};
