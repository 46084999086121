import { SearchActivityDefinition } from 'ui/types/interfaces/activity-definition';

import useInfinityLoad from '~/hooks/useInfinityLoad';
import { ActivityDefinitionUnifiedPublicSearch } from '~/services/resources/activity-definitions/search/unified/unified-public-search';
import { UnifiedSearchFilters } from '~/services/resources/activity-definitions/search/unified/unified-public-search.types';

export type InfinityActivityDefinitionUnifiedSearchFilters = Omit<
  UnifiedSearchFilters,
  'limit' | 'skip'
>;

export function useInfinityActivityDefinitionUnifiedSearch(
  filters: Partial<InfinityActivityDefinitionUnifiedSearchFilters>,
  isEnabled = false,
  itemsPerPage?: number,
) {
  const response = useInfinityLoad({
    queryKey: `activity-definition-public-search-unified-${JSON.stringify({
      filters,
    })}`,
    customQueryFunction: ({ limit, skip }) => {
      const params = { ...filters, limit, skip };
      return ActivityDefinitionUnifiedPublicSearch.findAll(params);
    },
    disabled: !isEnabled,
    itemsPerPage,
  });

  const serializedData = ((response.data?.pages as any) ?? []).flatMap(
    (page: { responseData: SearchActivityDefinition[] }) => page.responseData,
  );

  return { ...response, data: serializedData };
}
