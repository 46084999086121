export enum AvailabilityMessageStyle {
  SUCCESS = 1,
  WARNING = 2,
  ERROR = 3,
}

export class AvailabilityResult {
  constructor(
    public isAvailable: boolean,
    public message: string,
    public messageStyle: number,
  ) {}
}

export class Unavailable extends AvailabilityResult {
  constructor(message: string) {
    super(false, message, AvailabilityMessageStyle.ERROR);
  }
}

export class Available extends AvailabilityResult {
  constructor(message = '', style = AvailabilityMessageStyle.SUCCESS) {
    super(true, message, style);
  }
}
