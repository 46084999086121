import { Outlet } from 'react-router-dom';

import { AuthLayout } from '~/components/Layouts/AuthLayout';
import { UnauthLayout } from '~/components/Layouts/UnauthLayout';

export const outlet = (isAuthLayout?: boolean) =>
  isAuthLayout ? (
    <AuthLayout>
      <Outlet />
    </AuthLayout>
  ) : (
    <UnauthLayout>
      <Outlet />
    </UnauthLayout>
  );
