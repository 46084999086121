import { Flex, Hidden, HStack, Stack, Text, VStack } from 'native-base';
import Button from 'ui/components/Button';
import Illustration, { ILLUSTRATIONS_NAME } from 'ui/components/Illustration';

import { useActivitiesToLogEmptyStateController } from './activitiesToLogEmptyStateController';

export const OffplatormActivitiesEmptyState = ({}) => {
  const { onPressExploreOpportunities, onPressViewMyActivities } =
    useActivitiesToLogEmptyStateController();
  return (
    <HStack
      flex={1}
      w={'100%'}
      alignItems={'center'}
      justifyContent={'center'}
      space={4}
    >
      <Hidden till={'sm'}>
        <Stack
          height="100%"
          w={'35%'}
          justifyContent="center"
          alignItems="flex-end"
        >
          <Illustration
            name={ILLUSTRATIONS_NAME.ERROR_SORRY_STATE_BROKEN}
            width={{ base: 150, lg: 168 }}
            height={{ base: 150, lg: 168 }}
          />
        </Stack>
      </Hidden>

      <VStack
        w={{
          base: '100%',
          md: '55%',
        }}
        justifyContent="center"
        alignItems={{
          base: 'center',
          md: 'flex-start',
        }}
      >
        <VStack space={6} px={{ base: 4 }}>
          <Stack>
            <Text
              fontSize={{ base: 'xs', lg: 'sm' }}
              textAlign={{ base: 'center', lg: 'left' }}
              color={'gray.500'}
              maxW={{ base: '100%', md: '100%' }}
            >
              <span>It seems you haven&lsquo;t logged any activity yet.</span>
              <br />
              <span>To start, explore and book upcoming opportunities</span>
              <br />
              <span>or create a “New activity off-platform”.</span>
            </Text>
          </Stack>

          <Flex
            flexDir={{ base: 'column', lg: 'row' }}
            style={{ gap: 12 }}
            alignItems="center"
          >
            <Button
              variant="outline"
              onPress={onPressExploreOpportunities}
              width="175px"
            >
              Explore opportunities
            </Button>
          </Flex>
        </VStack>
      </VStack>
    </HStack>
  );
};
