import {
  Box,
  Divider,
  Flex,
  Hidden,
  HStack,
  Input,
  Stack,
  Text,
  VStack,
} from 'native-base';
import { Controller } from 'react-hook-form';
import Button from 'ui/components/Button';
import { CustomDatePicker } from 'ui/components/DateTimePickers/DatePicker';
import Icon from 'ui/components/Icon';
import Illustration, { ILLUSTRATIONS_NAME } from 'ui/components/Illustration';
import { ActivityTypeEnum } from 'ui/enums';

import { ActivityDetails } from '~/pages/Authenticated/LogActivity/components/ActivityDetails';
import {
  getSubTitleTextByActivityType,
  getTitleTextByActivityType,
} from '~/pages/Authenticated/LogActivity/components/LogHoursActivity/useLogHoursActivityController';
import { StepOption } from '~/pages/Authenticated/LogActivity/LogAction/components/StepOption';

import { LogActionProps } from './types';
import { useLogActionController } from './useLogActionUnitsController';

export const LogAction = ({
  activityApplication,
  measurements,
  measurementUnit,
  app,
  refetchMeasurements,
}: LogActionProps) => {
  const {
    control,
    fields,
    goBack,
    isSteps,
    isSubmitting,
    isValid,
    measurementUnitName,
    onSubmit,
  } = useLogActionController({
    activityApplication,
    measurements,
    measurementUnit,
    app,
    refetchMeasurements,
  });

  return (
    <Flex width="full" alignItems="center" overflowY="auto" bgColor="gray.100">
      <Box
        minH={{ base: '90vh', md: '100vh' }}
        w={{ base: '100%', md: 'min(90%, 1200px)' }}
        justifyContent={{ lg: 'center' }}
        alignItems="center"
        padding={{ base: 0, lg: 4 }}
        flexShrink="none"
      >
        <Flex
          width="100%"
          alignItems="center"
          flexDirection={{ base: 'column', lg: 'row' }}
        >
          <Hidden from="base" till="lg">
            <Flex width="40%">
              <Illustration
                name={ILLUSTRATIONS_NAME.DATA_MANAGEMENT}
                width="400px"
                height="400px"
              />
            </Flex>
          </Hidden>
          <Flex
            width={{ base: '100%', lg: '60%' }}
            bgColor={{ base: 'none', lg: 'white' }}
            borderRadius="lg"
          >
            <Flex
              style={{ gap: 16 }}
              flexDirection={{ base: 'column', lg: 'row' }}
              alignItems={{ lg: 'center' }}
              padding={4}
            >
              <Hidden till="lg">
                <Icon icon="plus-circle" size={32} />
              </Hidden>
              <Stack space={2}>
                <Text
                  fontSize={{ base: 'lg', lg: '2xl' }}
                  fontWeight={500}
                  color={{ base: 'gray.600', lg: 'darkText' }}
                >
                  {getTitleTextByActivityType(ActivityTypeEnum.Action)}
                </Text>
                <Text
                  fontSize="md"
                  color={{ base: 'gray.500', lg: 'gray.600' }}
                >
                  {isSteps
                    ? 'Check the steps for the day'
                    : getSubTitleTextByActivityType(ActivityTypeEnum.Action)}
                </Text>
              </Stack>
            </Flex>
            <Hidden till="lg">
              <Divider mt={4} height="3px" bgColor="darkText" />
            </Hidden>
            <VStack space={4} padding={{ base: 4, lg: 6 }}>
              {isSteps ? (
                <>
                  {fields.map((field, index) => (
                    <StepOption
                      key={field.id}
                      control={control}
                      index={index}
                    />
                  ))}
                </>
              ) : (
                <>
                  <HStack alignItems="center" space={2}>
                    <Controller
                      control={control}
                      name="value"
                      render={({ field: { value, onChange } }) => (
                        <Input
                          w={{ base: '50%', lg: '25%' }}
                          bg="white"
                          py="1"
                          rounded="xl"
                          keyboardType="numeric"
                          placeholder="0"
                          fontSize="3xl"
                          value={value?.toString()}
                          onChange={(e) =>
                            onChange(e.nativeEvent.text.replace(/\D/g, ''))
                          }
                        />
                      )}
                    />
                    <Text fontSize="xl" color="gray.400" ml="2">
                      {measurementUnitName}
                    </Text>
                  </HStack>
                  <Box w={{ base: '70%', lg: '25%' }}>
                    <CustomDatePicker
                      inputProps={{
                        height: 12,
                        padding: 2,
                      }}
                      control={control}
                      name="date"
                      dateFormat="dd/MM/yyyy"
                      maxDate={new Date()}
                      showTimeSelect={false}
                    />
                  </Box>
                </>
              )}

              <Stack mt={6} space={10}>
                <Stack space={4}>
                  <Stack space={2}>
                    <Text fontSize="md" fontWeight={500}>
                      Activity details
                    </Text>
                    <Hidden from="lg">
                      <Divider width="100%" />
                    </Hidden>
                  </Stack>
                  <ActivityDetails
                    refetchMeasurements={refetchMeasurements}
                    measurements={measurements || []}
                    activityApplication={activityApplication}
                    app={app}
                    measurementUnit={measurementUnit}
                  />
                </Stack>
                <Flex
                  width="100%"
                  mt={10}
                  flexDir={{ base: 'column-reverse', lg: 'row' }}
                  style={{ gap: 4 }}
                  padding={1}
                >
                  <Button
                    variant="ghost"
                    colorScheme="darkText"
                    width={{ base: '100%', lg: '50%' }}
                    onPress={goBack}
                  >
                    Cancel
                  </Button>
                  <Button
                    onPress={onSubmit}
                    isDisabled={!isValid || isSubmitting}
                    isLoading={isSubmitting}
                    width={{ base: '100%', lg: '50%' }}
                  >
                    Log
                  </Button>
                </Flex>
              </Stack>
            </VStack>
          </Flex>
        </Flex>
      </Box>
    </Flex>
  );
};
