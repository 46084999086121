import './styles.css';

import { VStack } from 'native-base';

import { useGenericCarouselController } from './controller';
import SlidesMemorizer from './SlidesMemorizer';
import { GenericCarouselProps } from './types';

export const GenericCarousel = (props: GenericCarouselProps) => {
  const { slides, columns, translateX, scrollRef, horizontalMargin } =
    useGenericCarouselController(props);

  return (
    <VStack space={4} mx={horizontalMargin}>
      {/* Viewport */}
      <div className="generic-carousel-viewport" ref={scrollRef}>
        {/* Slider */}
        <SlidesMemorizer
          slides={slides}
          columns={columns}
          space={props.space}
          translateX={translateX}
        />
      </div>
    </VStack>
  );
};
