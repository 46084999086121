import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { NotificationState } from '~/store/slices/notification/types';
import { ESliceNames } from '~/store/types';

const initialState: NotificationState = {
  isNotificationOpen: false,
  content: undefined,
};

export const notificationSlice = createSlice({
  name: ESliceNames.NOTIFICATION,
  initialState,

  reducers: {
    notify: (state, { payload }: PayloadAction<NotificationState>) => {
      return { ...state, ...payload, isNotificationOpen: true };
    },
    reset: () => {
      return initialState;
    },
  },
});

export const {
  reducer: notificationSliceReducer,
  actions: notificationSliceActions,
} = notificationSlice;
