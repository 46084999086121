import { yupResolver } from '@hookform/resolvers/yup';
import debounce from 'debounce-promise';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { IconEnum } from 'ui/components/Icon/types';
import { IModalRefProps } from 'ui/components/Modals/Modal/types';
import { EEventApplicationType } from 'ui/enums';
import { ActivityType } from 'ui/types/activities';
import {
  getFilteredAppsListByPermissions,
  getViewPermissionForActivityType,
} from 'ui/utils/permissions';

import { OpportunitiesTabsPermissions } from '~/constants/volunteer.permissions.constants';
import { useAppSelector } from '~/hooks/useAppSelector';
import useInfinityActivity from '~/hooks/useInfinityActivity';
import { usePermissions } from '~/hooks/usePermissions';
import { findOpportunitiesPageSearchSchema } from '~/pages/Authenticated/FindOpportunitiesPage/findOpportunities.schema';
import {
  FindOpportunitiesContentPageTab,
  FindOpportunitiesPageSearchFormValues,
  FormDefaultValues,
} from '~/pages/Authenticated/FindOpportunitiesPage/findOpportunities.types';
import { FindOpportunitiesPageProps } from '~/pages/Authenticated/FindOpportunitiesPage/types';

export const findOpportunitiesContentPageTabs: FindOpportunitiesContentPageTab[] =
  [
    {
      icon: 'calendar',
      label: 'Events',
      description: 'Activities with fixed day and time',
      opportunityType: ActivityType.Event,
      requiredPermissions: OpportunitiesTabsPermissions.Events,
    },
    {
      icon: 'users',
      label: 'Team Events',
      description: 'Group activities',
      opportunityType: EEventApplicationType.Team,
      requiredPermissions: OpportunitiesTabsPermissions.TeamEvents,
    },
    {
      icon: IconEnum.PUZZLE,
      isCustomIcon: true,
      label: 'Actions',
      description: 'Activities with a measurable target',
      opportunityType: ActivityType.Action,
      requiredPermissions: OpportunitiesTabsPermissions.Actions,
    },
    {
      icon: 'repeat',
      label: 'Ongoing Opportunities',
      description: 'Long-term commitment',
      opportunityType: ActivityType.Ongoing,
      requiredPermissions: OpportunitiesTabsPermissions.OngoingOpportunities,
    },
  ];

const formDefaultValue: FormDefaultValues = {
  search: '',
  city_or_postcode: undefined,
};

interface GetInfinityQueryParams {
  search?: string;
  apps: string[] | null;
  position?: { lat: number; lng: number };
  activityType: string;
  radius?: number;
}

const getInfinityQueryParams = ({
  search,
  position,
  activityType,
  apps,
  radius,
}: GetInfinityQueryParams) => {
  const appsQuery =
    !!apps && apps.length > 0 ? { apps: JSON.stringify(apps) } : null;
  const applicationTypeIsTeam = activityType === EEventApplicationType.Team;

  const applicationTypeFilter = () => {
    if (activityType === ActivityType.Action) {
      return undefined;
    }

    if (applicationTypeIsTeam) {
      return EEventApplicationType.Team;
    } else {
      return EEventApplicationType.Individual;
    }
  };

  const activityTypeFilter =
    activityType === EEventApplicationType.Team ? 'Event' : undefined;

  return {
    // TODO: Get the list of apps that the user have access.
    text: search,
    activityTypes: JSON.stringify([activityTypeFilter || activityType]),
    position: JSON.stringify(position),
    eventApplicationTypes:
      applicationTypeFilter() && JSON.stringify([applicationTypeFilter()]),
    radius,
    ...appsQuery,
  };
};

export const useFindOpportunitiesPageController = ({
  isGuestPage,
}: FindOpportunitiesPageProps) => {
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const { getOpportunitiesTabsByPermissions } = usePermissions();

  const filteredOpportunitiesTabs = getOpportunitiesTabsByPermissions(
    findOpportunitiesContentPageTabs,
  );

  const { opportunityType } = filteredOpportunitiesTabs[currentTabIndex] || {
    opportunityType: 'None',
  };

  const activityTypeForOpportunityFilter =
    opportunityType !== EEventApplicationType.Team
      ? opportunityType
      : ActivityType.Event;
  const isTeamEvent = opportunityType === EEventApplicationType.Team;

  const [{ search, city_or_postcode }, setDebouncedFormValues] =
    useState<FormDefaultValues>(formDefaultValue);

  const { userPermissionsGroupsByAppId, allUserPermissionsFromAvailableApps } =
    useAppSelector(({ permissions }) => permissions);

  const { user, availableAppsFromPermissionGroupsById, isLoading } =
    useAppSelector(({ auth }) => auth);

  const { selectedEcosystem } = useAppSelector(({ ecosystem }) => ecosystem);

  const userPosition = useMemo(() => {
    if (user?.preferences?.address?.location) {
      return {
        lng: user?.preferences?.address?.location?.coordinates[0] as number,
        lat: user?.preferences?.address?.location?.coordinates[1] as number,
      };
    }
  }, [user?.preferences?.address?.location]);

  const { control, watch } = useForm<FindOpportunitiesPageSearchFormValues>({
    resolver: yupResolver(findOpportunitiesPageSearchSchema),
    //TODO: SOLVE THAT TYPE ERROR
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    defaultValues: formDefaultValue,
  });

  const handleChangeTab = (index: number) => {
    setCurrentTabIndex(index);
  };

  const appsWithActivityPermission = useMemo(() => {
    if (!filteredOpportunitiesTabs) return [];
    const appsList = Object.keys(
      availableAppsFromPermissionGroupsById as Object,
    );

    const permissionsToValidate = getViewPermissionForActivityType({
      activityType: activityTypeForOpportunityFilter,
      isTeamEvent: isTeamEvent,
    });

    const filteredApps = getFilteredAppsListByPermissions({
      availableApps: appsList,
      permissionsToValidate,
      availableAppPermissionGroupsByApp: userPermissionsGroupsByAppId,
    });

    return filteredApps;
  }, [
    availableAppsFromPermissionGroupsById,
    userPermissionsGroupsByAppId,
    isTeamEvent,
    activityTypeForOpportunityFilter,
    filteredOpportunitiesTabs,
  ]);
  const queryParams = useMemo(() => {
    let appsQueryParams = null;

    if (appsWithActivityPermission.length > 0) {
      appsQueryParams = appsWithActivityPermission;
    }

    return getInfinityQueryParams({
      search,
      apps: appsQueryParams,
      position: city_or_postcode || userPosition,
      activityType: opportunityType,
      radius: 100 * 1000, //100km
    });
  }, [
    search,
    city_or_postcode,
    opportunityType,
    userPosition,
    appsWithActivityPermission,
  ]);

  const isFetchActivitiesDisabled = useMemo(() => {
    return (
      !queryParams?.apps ||
      queryParams.apps.length === 0 ||
      (!user?.preferences?.address && !isGuestPage)
    );
  }, [queryParams, user, isGuestPage]);

  const {
    data: activitiesData,
    isLoading: isLoadingActivitiesData,
    hasNextPage: activitiesHasNextPage,
    fetchNextPage: activitiesFetchNextPage,
    isRefetching: activitiesIsRefetching,
    isFetchingNextPage: activitiesIsFetchingNextPage,
  } = useInfinityActivity(queryParams, isFetchActivitiesDisabled);

  const activities = useMemo(
    () => activitiesData?.pages.flatMap((page) => page.data) ?? [],
    [activitiesData],
  );

  const handleOnScrollEndReached = async () => {
    if (
      !activitiesIsRefetching &&
      !activitiesIsFetchingNextPage &&
      activitiesHasNextPage
    ) {
      await activitiesFetchNextPage();
    }
  };

  useEffect(() => {
    const debouncedCb = debounce((formValues) => {
      setDebouncedFormValues(formValues as FormDefaultValues);
    }, 900);

    const subscription = watch(debouncedCb);

    return () => subscription.unsubscribe();
  }, [watch]);

  //TO-DO: This is used to maintain the app permissions updated, to avoid mismatched state with Org+. We should change this when we have a better solution
  const isLoadingOpportunities =
    isLoadingActivitiesData || (!isGuestPage && !selectedEcosystem);

  return {
    control,
    currentTabIndex,
    handleChangeTab,
    activities,
    isLoadingOpportunities,
    activitiesHasNextPage,
    activitiesFetchNextPage,
    activitiesIsRefetching,
    activitiesIsFetchingNextPage,
    handleOnScrollEndReached,
    allUserPermissionsFromAvailableApps,
    filteredOpportunitiesTabs,
  };
};
