import { Box, Pressable, Text, VStack } from 'native-base';
import { ReactNode } from 'react';
import Drawer from 'react-modern-drawer';

import { colors } from '~/theme/colors';

export type MobileTrayDrawerProps = {
  title?: string;
  isOpen: boolean;
  onClose: VoidFunction;
  options?: Array<{ label: string; value: string; details?: string }>;
  onPress?: (value: string) => void;
  children?: ReactNode;
  size?: number;
};

const MobileTrayDrawer = (props: MobileTrayDrawerProps) => {
  const {
    title = '',
    isOpen = false,
    onClose,
    options,
    onPress,
    children,
    size = 296,
  } = props;

  return (
    <Drawer
      open={isOpen}
      onClose={onClose}
      direction="bottom"
      overlayColor={colors.black}
      overlayOpacity={0.3}
      duration={200}
      size={size}
      style={{
        backgroundColor: 'transparent',
        boxShadow: 'none',
      }}
    >
      <Box
        w="full"
        h="full"
        borderTopRadius={'20px'}
        backgroundColor={colors.white}
        p={3}
      >
        <VStack>
          {/* header */}
          <VStack justifyContent={'center'} space={3} paddingBottom={3}>
            <Pressable
              alignSelf={'center'}
              w="80px"
              h="8px"
              borderRadius={4}
              backgroundColor={colors.gray[200]}
              onPressOut={onClose}
            />
            {!!title && (
              <Text paddingTop={3} fontSize={'sm'} color={colors.gray[500]}>
                {title}
              </Text>
            )}
          </VStack>

          {(options ?? []).map((option) => {
            return (
              <Pressable
                key={option.value}
                onPress={() => onPress && onPress(option.value)}
              >
                <Text padding={4} fontSize={'md'} color={colors.gray[800]}>
                  {option.label} {option.details && option.details}
                </Text>
              </Pressable>
            );
          })}

          {children}
        </VStack>
      </Box>
    </Drawer>
  );
};

export default MobileTrayDrawer;
