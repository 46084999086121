import { ActivitySemanticType } from 'ui/types/activities';
import { Coordinates } from 'ui/types/interfaces';
import { convertMilesToMeters } from 'ui/utils/location/transform-distance';

import {
  EventFromHomeSearchFilters,
  EventInPersonSearchFilters,
  FromHomeEventSearchRelevanceType,
  InPersonEventSearchRelevanceType,
} from '~/services/resources/activity-definitions/search/events/events-public-search.types';
import { SearchFilters } from '~/zustand/store/Search';

import {
  mapCausesFilters,
  mapDateFilters,
  mapTeamFilters,
  mapTypeFilters,
} from './map-unified-search-api-filters';

export const mapEventInPersonSearchApiFilters = (
  appIds: string[],
  coordinates: Coordinates | null,
  filters: Partial<SearchFilters>,
): Omit<EventInPersonSearchFilters, 'limit' | 'skip'> => {
  // Get Date range
  const [startDate, endDate] = mapDateFilters(
    filters.startDate,
    filters.endDate,
  );

  // get Application type
  const { eventApplicationTypes } = mapTypeFilters(filters.activityTypes || []);

  // Convert distance radius
  const radius = filters?.distanceRadius
    ? convertMilesToMeters(filters.distanceRadius)
    : undefined;

  let teamsMinSize, teamsMaxSize;
  if (filters.activityTypes?.includes(ActivitySemanticType.TeamEvent)) {
    const [_teamsMinSize, _teamsMaxSize] = mapTeamFilters(filters);
    teamsMinSize = _teamsMinSize;
    teamsMaxSize = _teamsMaxSize;
  }

  const { causeOptions } = mapCausesFilters(filters?.causes || []);

  const apiFilters: Omit<EventInPersonSearchFilters, 'limit' | 'skip'> = {
    relevance: filters.relevance as InPersonEventSearchRelevanceType,
    position: coordinates,
    apps: appIds,
    startDate,
    endDate,
    radius,
    eventApplicationTypes,
    teamsMinSize,
    teamsMaxSize,
    categories: {
      causeOptions,
    },
  };

  return apiFilters;
};

export const mapEventFromHomeSearchApiFilters = (
  appIds: string[],
  coordinates: Coordinates | null,
  filters: Partial<SearchFilters>,
): Omit<EventFromHomeSearchFilters, 'limit' | 'skip'> => {
  // get Application type
  const { eventApplicationTypes } = mapTypeFilters(filters.activityTypes || []);

  // Convert distance radius
  const radius = filters?.distanceRadius
    ? convertMilesToMeters(filters.distanceRadius)
    : undefined;

  let teamsMinSize, teamsMaxSize;
  if (filters.activityTypes?.includes(ActivitySemanticType.TeamEvent)) {
    const [_teamsMinSize, _teamsMaxSize] = mapTeamFilters(filters);
    teamsMinSize = _teamsMinSize;
    teamsMaxSize = _teamsMaxSize;
  }

  const { causeOptions } = mapCausesFilters(filters?.causes || []);

  const apiFilters: Omit<EventFromHomeSearchFilters, 'limit' | 'skip'> = {
    relevance: filters.relevance as FromHomeEventSearchRelevanceType,
    apps: appIds,
    position: null,
    //radius,
    eventApplicationTypes,
    regions: [],
    teamsMinSize,
    teamsMaxSize,
    categories: {
      causeOptions,
    },
  };

  return apiFilters;
};
