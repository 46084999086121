import { HStack, Stack, VStack } from 'native-base';
import React from 'react';
import CardPropInfo from 'ui/components/Cards/CardPropInfo';
import ReadMore from 'ui/components/ReadMore';
import { colors } from 'ui/theme/colors';

import ArticlePreview from '~/components/ArticlePreview';
import { useActivityAboutController } from '~/pages/Authenticated/v2/ActivityView/components/ActivityAbout/controller';
import { ActivityAboutProps } from '~/pages/Authenticated/v2/ActivityView/components/ActivityAbout/types';

export const ActivityAbout = (props: ActivityAboutProps) => {
  const { causeOptions, description } = props;
  const { spaceOptionsInfo } = useActivityAboutController(props);

  return (
    <VStack space={6}>
      <HStack space={6}>
        <CardPropInfo
          textColor={'gray.600'}
          icon={'heart'}
          info={`Causes: ${causeOptions
            .map((cause) => cause.displayName)
            .join(', ')}`}
          justifyContent={'flex-start'}
          w="50%"
        />

        {!!spaceOptionsInfo && (
          <CardPropInfo
            {...spaceOptionsInfo}
            textColor={'gray.600'}
            justifyContent={'flex-start'}
            w="50%"
          />
        )}
      </HStack>
      {!!description && (
        <Stack flexShrink={0}>
          <ArticlePreview text={description} maxHeight={150} />
        </Stack>
      )}
    </VStack>
  );
};

export default React.memo(ActivityAbout);
