import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { PAGES } from '~/constants/pages.constants';
import { useRouter } from '~/hooks/useRouter';
import AuthService from '~/services/resources/auth';

import { COMMON_MESSAGES } from '../../../../constants/messages.constants';
import { schemaValidation } from './constants';
import { IConfirmEmailFields } from './types';

export const useConfirmEmailController = () => {
  const { goToRoute, goBack, location } = useRouter();
  const [isLoading, setIsLoading] = useState(false);
  const email = location?.state?.email;

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IConfirmEmailFields>({
    resolver: yupResolver(schemaValidation),
    defaultValues: {
      email: email || '',
    },
  });

  const onSubmit = handleSubmit(async (data) => {
    try {
      setIsLoading(true);

      const verifiedResponse = await AuthService.isUserVerified(data.email);

      if (!verifiedResponse.data.verified) {
        await AuthService.resendConfirmationCode({ email: data.email });
        toast.info(COMMON_MESSAGES.ACCOUNT_UNVERIFIED_SEND_MESSAGE);
        goToRoute(PAGES.AccessCode, {
          state: {
            email: data.email,
          },
        });
      } else {
        await AuthService.requestForgotPassword({
          email: data.email,
        });
      }

      goToRoute(PAGES.AccessCode, {
        state: {
          email: data.email,
          isForgotPassword: true,
          isUserUnverified: !verifiedResponse.data.verified,
        },
      });

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  });

  return {
    control,
    errors,
    goBack,
    onSubmit,
    isLoading,
    email,
  };
};
