import { PermissionsWrapper } from 'ui/components/PermissionsWrapper';

import { ValidateAllPermissionsProps } from './types';
import { useApplyPermissionsController } from './useValidateAllPermissionsController';

export const ValidateAllPermisisons = ({
  disablePermissionsValidations = false,
  requiredPermissions,
  children,
}: ValidateAllPermissionsProps) => {
  const { allUserPermissionsFromAvailableApps } =
    useApplyPermissionsController();
  return (
    <PermissionsWrapper.Boolean
      bypassPermissions={disablePermissionsValidations}
      availablePermissions={allUserPermissionsFromAvailableApps}
      requiredPermissions={requiredPermissions}
    >
      {children}
    </PermissionsWrapper.Boolean>
  );
};
