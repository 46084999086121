import { Flex, HStack, Stack, VStack } from 'native-base';
import { FiLock } from 'react-icons/fi';
import { ILLUSTRATIONS_NAME } from 'ui/components/Illustration';
import Input from 'ui/components/Input';

import FlowTwoColumnsTemplate from '~/components/Templates/FlowTwoColumnsTemplate';
import { colors } from '~/theme/colors';

import { useChangePasswordController } from './useChangePasswordController';

export const ChangePassword = () => {
  const { control, errors, onSubmit, goBack, isLoading, isMobile } =
    useChangePasswordController();

  return (
    <Flex flex={1} minH={'full'} w="full" flexShrink={0}>
      <FlowTwoColumnsTemplate
        ilustrationName={ILLUSTRATIONS_NAME.ACCOUNTS_SECURITY}
        title="Reset password"
        subtitle={'Please create a strong password to secure your account.'}
        icon={<FiLock size={32} />}
        progress={100}
        primaryButtonText="Continue"
        primaryButtonVariant="outline"
        primaryButtonOnPress={onSubmit}
        primaryButtonProps={{ borderColor: 'gray.400' }}
        primaryButtonTextStyle={{ fontSize: 'md' }}
        secondaryButtonTextStyle={{ fontSize: 'md' }}
        secondaryButtonOnPress={goBack}
        secondaryButtonText="Back"
        isLoading={isLoading}
      >
        <Stack
          flex={1}
          minH={'full'}
          space="4"
          w="full"
          px={6}
          py={8}
          justifyContent={{ base: 'center', md: 'flex-start' }}
        >
          <VStack flex={1} minH={'full'} space={4}>
            <Stack>
              <Input
                name="currentPassword"
                control={control}
                placeholder="Insert your current password"
                label={!isMobile ? 'Current password' : ''}
                isRequired
                leftIconName="lock"
                isFullWidth
                errorMessage={
                  errors.currentPassword?.message || errors.newPassword?.message
                }
                iconErrorMessage="info"
                leftIconColor={colors.muted[400]}
                type="password"
                labelColor="gray.600"
              />
            </Stack>

            <Stack>
              <Input
                name="newPassword"
                control={control}
                placeholder="Type your new password"
                label={!isMobile ? 'New password' : ''}
                isRequired
                leftIconName="lock"
                isFullWidth
                leftIconColor={colors.muted[400]}
                type="password"
                showPasswordRules
                labelColor="gray.600"
              />
            </Stack>
          </VStack>
        </Stack>
      </FlowTwoColumnsTemplate>
    </Flex>
  );
};
