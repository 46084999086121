import { Flex, VStack } from 'native-base';

import { NavBar } from '~/components/NavBar';
import { navbarHeightInPx } from '~/components/NavBar/navBar.controller';

export const GuestLayout = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  return (
    <Flex
      bg={'singleton.white'}
      h={{
        base: `calc(100vh - ${navbarHeightInPx}px)`,
        md: `calc(100vh)`,
      }}
      flexShrink={0}
    >
      <NavBar isGuest />

      <VStack bg={'gray.100'} space={6} h={{ base: 'auto', md: 'full' }}>
        {children}
      </VStack>
    </Flex>
  );
};
