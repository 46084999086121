import { useState } from 'react';

import { useAppSelector } from '~/hooks/useAppSelector';
import { useLoadUserTeams } from '~/hooks/useLoadTeams/useLoadUserTeams';
import { ITeam } from '~/types/interfaces/team';

export const useTeamApplicationController = () => {
  const { selectedUserProfile } = useAppSelector(({ auth }) => auth);

  const [selectedTeam, setSelectedTeam] = useState<ITeam | null>(null);

  const [showTeamDrawer, setShowTeamDrawer] = useState(false);

  const { data: teams, isLoading } = useLoadUserTeams(
    selectedUserProfile?._id || '',
    !!selectedUserProfile?._id,
  );

  const handleNewTeam = (team: ITeam | null) => {
    setSelectedTeam(team);
    setShowTeamDrawer(false);
  };

  const openMobileTeamDrawer = () => {
    setShowTeamDrawer(true);
  };

  const closeMobileTeamDrawer = () => {
    setShowTeamDrawer(false);
  };

  return {
    availableTeams: teams,
    isLoading,
    selectedTeam,
    handleNewTeam,
    showTeamDrawer,
    openMobileTeamDrawer,
    closeMobileTeamDrawer,
  };
};

export default useTeamApplicationController;
