import {
  HStack,
  PresenceTransition,
  Pressable,
  Text,
  VStack,
} from 'native-base';
import { ResponsiveValue } from 'native-base/lib/typescript/components/types';
import React, { ReactElement, useMemo, useRef, useState } from 'react';
import { colors } from 'ui/theme/colors';

import { HomeWidgetNavButton } from './NavButton';
import { HomeWidgetSkeleton } from './Skeleton';

export type HomeWidgetBaseProps = {
  title: string;
  headerBackgroundColor: string;
  children: JSX.Element | JSX.Element[];
  minWidth?: ResponsiveValue<string | number>;
  maxWidth?: ResponsiveValue<string | number>;
  defaultTabIndex?: number;
  isLoading?: boolean;
  onPress?: VoidFunction;
};

export const HomeWidgetBase = ({
  title,
  onPress,
  children,
  minWidth,
  maxWidth,
  isLoading = false,
  defaultTabIndex = 0,
  headerBackgroundColor,
}: HomeWidgetBaseProps) => {
  const prevIndexRef = useRef(defaultTabIndex);
  const [tabIndex, setTabIndex] = useState(defaultTabIndex);

  const tabs = useMemo(() => {
    const childrenCount = React.Children.count(children);
    if (!!isLoading || !childrenCount) return [];

    return React.Children.map(children, (child, index) => {
      const translateX = prevIndexRef.current < tabIndex ? 150 : -150; // Define a direção do translateX
      const isVisible = tabIndex === index;
      return (
        <PresenceTransition
          key={index}
          visible={isVisible}
          initial={{ opacity: 0, translateX }}
          animate={{ transition: { duration: 100 } }}
        >
          {React.cloneElement(child as ReactElement, {
            style: { display: tabIndex === index ? 'block' : 'none' },
          })}
        </PresenceTransition>
      );
    });
  }, [children, tabIndex, isLoading]);

  const handleSwitchTabIndex = (newIndex: number) => {
    setTabIndex((prev) => {
      prevIndexRef.current = prev;
      return newIndex;
    });
  };

  if (isLoading)
    return <HomeWidgetSkeleton minWidth={minWidth} maxWidth={maxWidth} />;

  return (
    <VStack
      w="full"
      flex={1}
      borderRadius={8}
      overflow={'hidden'}
      backgroundColor={colors.singletons.white}
      minW={minWidth}
      maxWidth={maxWidth}
    >
      <Pressable
        px={3}
        py={{ base: 2, md: 3 }}
        backgroundColor={headerBackgroundColor}
        onPress={onPress}
      >
        <Text
          textAlign={'left'}
          fontSize={{ base: 'xs', md: 'md' }}
          lineHeight={'125%'}
          fontWeight={600}
          numberOfLines={1}
          color={colors.singletons.white}
        >
          {title}
        </Text>
      </Pressable>

      <VStack p={{ base: 2, md: 3 }} h="102px" overflow={'hidden'}>
        {/* Content */}
        <VStack justifyContent={'center'} h="88px">
          {tabs.length ? tabs?.[tabIndex] : null}
        </VStack>

        {/* Navigator */}
        {tabs.length > 1 && (
          <HStack
            mt={2}
            space={2}
            justifyContent={'center'}
            alignItems={'center'}
          >
            {tabs.map((_, index) => (
              <HomeWidgetNavButton
                key={index}
                isSelected={tabIndex === index}
                onPress={() => handleSwitchTabIndex(index)}
              />
            ))}
          </HStack>
        )}
      </VStack>
    </VStack>
  );
};

export * from './constants';
