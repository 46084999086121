import { PAGES } from '~/constants/pages.constants';
import { useRouter } from '~/hooks/useRouter';

export const useActivitiesToLogEmptyStateController = () => {
  const { goToRoute } = useRouter();

  const onPressExploreOpportunities = () => goToRoute(PAGES.AllApps);

  const onPressViewMyActivities = () => goToRoute(PAGES.Activities);

  return { onPressExploreOpportunities, onPressViewMyActivities };
};
