import { VStack } from 'native-base';
import { useEffect, useRef } from 'react';

import { CoverImageCaptionProps } from './types';

const STYLE_OBJECT = {
  backgroundImage:
    'linear-gradient(0deg, rgba(39, 39, 42, 0.85) 0%, rgba(39, 39, 42, 0.85) 100%)',
};

export const CoverImageCaption = ({ children }: CoverImageCaptionProps) => {
  const ref = useRef();

  useEffect(() => {
    if (ref.current) {
      (ref?.current as any)?.setNativeProps({ style: STYLE_OBJECT });
    }
  }, [ref]);

  return (
    <VStack
      position={'absolute'}
      top={0}
      left={0}
      width={'full'}
      height={'full'}
      zIndex={999}
      ref={ref}
    >
      <VStack h="full">{children}</VStack>
    </VStack>
  );
};
