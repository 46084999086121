import { Box, HStack, Text } from 'native-base';
import { useMemo } from 'react';
import { colors } from 'ui/theme/colors';
import { safeDateFormat } from 'ui/utils/formatDates';

export type ActivityDateSnippetProps = {
  date?: Date;
  datesCount?: number;
};
export const ActivityDateSnippet = ({
  date,
  datesCount,
}: ActivityDateSnippetProps) => {
  const dateStr = useMemo(
    () => safeDateFormat(date, 'EEE, dd MMM - h:mmaaa'),
    [date],
  );

  return (
    <HStack space={2} alignItems={'center'}>
      <HStack space={2} alignItems={'center'}>
        <Text
          fontSize={'sm'}
          color={'gray.600'}
          fontWeight={500}
          numberOfLines={1}
        >
          {dateStr}
        </Text>

        {!!datesCount && (
          <Box
            borderColor={colors.gray[200]}
            borderRadius={40}
            w="fit-content"
            py={'2px'}
            px={2}
            borderWidth={1}
          >
            <Text
              fontSize={'xs'}
              color={'gray.500'}
              fontWeight={500}
              numberOfLines={1}
            >
              + {datesCount} dates
            </Text>
          </Box>
        )}
      </HStack>
    </HStack>
  );
};
