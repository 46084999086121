import { AxiosResponse } from 'axios';
import { IActivityApplication } from 'doit-bho-volunteer/src/types/interfaces/activity';
import { MeasurementCategory } from 'ui/enums';

import { api } from '~/services/api';

export class ActivityApplicationsService {
  static BASE_PATH = 'activity-application';
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  static async findAllWithFilter(params: any): Promise<
    AxiosResponse<{
      data: IActivityApplication[];
      count: number;
    }>
  > {
    return await api.get(`${this.BASE_PATH}`, { params });
  }

  static async createMeasurementAmount(
    activityApplicationId: string,
    payload: { amount: number; measurementDate: Date | string },
  ) {
    return await api.post(
      `${this.BASE_PATH}/${activityApplicationId}/measurement-amount`,
      payload,
    );
  }

  static async createMeasurementSteps(
    activityApplicationId: string,
    payload: {
      steps: { step: number; completedAt: Date | string }[];
    },
  ) {
    return await api.post(
      `${this.BASE_PATH}/${activityApplicationId}/measurement-steps`,
      {
        ...payload,
        measurementDate: new Date(),
        measurementType: MeasurementCategory.Completion,
      },
    );
  }

  static async updateMeasurementSteps(
    activityApplicationId: string,
    payload: {
      steps: { step: number; completedAt: Date | string }[];
    },
  ) {
    return await api.put(
      `${this.BASE_PATH}/${activityApplicationId}/measurement-steps`,
      {
        steps: payload.steps,
      },
    );
  }

  static async deleteMeasurementUnit(id: string) {
    return await api.delete(`activity-application/measurement-amount/${id}`);
  }
}
