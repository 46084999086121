import {
  Box,
  Button,
  Checkbox,
  Flex,
  HStack,
  Link,
  Stack,
  Text,
  VStack,
} from 'native-base';
import { Controller } from 'react-hook-form';
import Input from 'ui/components/Input';
import { colors } from 'ui/theme/colors';

import { ICreateAccountProps } from './types';

export const CreateAccount = ({
  control,
  errors,
  hasExternalId,
  onOpenPrivacyPolicy,
  onOpenTermsOfService,
  onGoToSignIn,
}: ICreateAccountProps) => (
  <Stack w="full">
    <Flex flexDirection={{ md: 'row', base: 'column' }} style={{ gap: 12 }}>
      <Box flex="1">
        <Input
          name="firstName"
          control={control}
          placeholder="Type your first name"
          label="First Name"
          isRequired
          isFullWidth
          errorMessage={errors.firstName?.message}
          iconErrorMessage="info"
          leftIconColor={colors.muted[400]}
        />
      </Box>
      <Box flex="1">
        <Input
          isFullWidth
          name="lastName"
          control={control}
          placeholder="Type your last name"
          type="text"
          label="Last Name"
          leftIconColor={colors.muted[400]}
          isRequired
          errorMessage={errors.lastName?.message}
          iconErrorMessage="info"
        />
      </Box>
    </Flex>

    <Flex flexDirection={{ base: 'column' }} style={{ gap: 12 }}>
      <Box flex="1">
        <Input
          name="email"
          control={control}
          placeholder="Type your email address"
          label="Email address"
          isRequired
          errorMessage={errors.email?.message}
          iconErrorMessage="info"
          leftIconName="mail"
          leftIconColor={colors.muted[400]}
        />
      </Box>
      <Box flex="1">
        <Input
          isRequired
          isFullWidth
          isPhoneInput
          control={control}
          name="phoneNumber"
          placeholder="Type your phone number"
          label="Phone Number"
          iconErrorMessage="info"
          backgroundColor="white"
          borderColor="muted.200"
          errorMessage={errors.phoneNumber?.message}
          leftIconColor={colors.muted[400]}
        />
      </Box>
    </Flex>

    {hasExternalId ? (
      <Box flex="1">
        <Input
          isFullWidth
          isRequired
          control={control}
          name="externalId"
          iconErrorMessage="info"
          backgroundColor="white"
          borderColor="muted.200"
          label="External ID"
          errorMessage={errors.externalId?.message}
          placeholder="Type your external id"
          leftIconColor={colors.muted[400]}
        />
      </Box>
    ) : null}

    <Input
      name="password"
      control={control}
      placeholder="Create a password"
      type="password"
      label="Password"
      leftIconName="lock"
      leftIconColor={colors.muted[400]}
      isRequired
      errorMessage={errors.password?.message}
      iconErrorMessage="info"
      helperText={
        'Your password must contain at least 8 characters (uppercase, lowercase, numbers and special characters)'
      }
      showPasswordRules={true}
    />

    <HStack alignItems="center">
      <Controller
        control={control}
        name="termsOfService"
        render={({ field: { onChange, value } }) => (
          <VStack>
            <HStack alignItems="center" space="3" mt="4">
              <Checkbox
                id={'termsOfService'}
                onChange={onChange}
                name="termsOfService"
                value="true"
                isChecked={value}
                my={3}
              >
                <Text maxW="100%" fontSize={14} fontWeight="400" width="100%">
                  I read and accept the{' '}
                  <Link
                    color="darkText"
                    onPress={() => {
                      onChange('true');
                      onOpenTermsOfService();
                    }}
                  >
                    terms of service
                  </Link>{' '}
                  and{' '}
                  <Link
                    color="darkText"
                    onPress={() => {
                      onChange('true');
                      onOpenPrivacyPolicy();
                    }}
                  >
                    privacy policy.
                  </Link>
                </Text>
              </Checkbox>
            </HStack>
            {errors.termsOfService?.message ? (
              <Text color="error.600" fontSize={12} mt={1}>
                {errors.termsOfService?.message}
              </Text>
            ) : null}
          </VStack>
        )}
      />
    </HStack>

    {onGoToSignIn ? (
      <VStack alignSelf="center" space="2" w="full" mt={4}>
        <Stack w="full">
          <Text mx="auto" mt="6" mb="2">
            You already have an account?
          </Text>
          <Button bgColor="white" onPress={onGoToSignIn}>
            <Text color="primary.600" fontSize={16} fontWeight="500">
              Sign in
            </Text>
          </Button>
        </Stack>
      </VStack>
    ) : null}
  </Stack>
);
