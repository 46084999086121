import { useMemo } from 'react';

import {
  CustomTagProps,
  TAG_BG_COLOR,
  TAG_ICON,
  TAG_TEXT_COLOR,
} from './types';

const useTagController = ({
  type = 'info',
  tagBgColor,
  tagTextColor,
  fontSize,
  text,
  icon,
  leftIconSize = 15,
  textTransform = 'uppercase',
  fontWeight,
  showIcon = false,
  showCustomIcon,
  alignSelf = 'flex-start',
}: CustomTagProps) => {
  const textColor = TAG_TEXT_COLOR[type];
  const bgColor = tagBgColor || TAG_BG_COLOR[type];
  const _fontWeight = useMemo(() => {
    if (fontWeight) return fontWeight;
    return type === 'info' ? '500' : 'normal';
  }, [fontWeight, type]);

  const _icon = useMemo(() => {
    if (!showIcon) return null;
    const x = icon || TAG_ICON[type];
    return x ? x : null;
  }, [icon, type, showIcon]);

  return {
    bgColor,
    fontWeight: _fontWeight,
    tagTextColor: tagTextColor ? tagTextColor : textColor,
    textStyle: {
      fontSize,
    },
    text,
    _icon,
    showIcon,
    leftIconSize,
    textTransform,
    showCustomIcon,
    alignSelf,
  };
};

export default useTagController;
