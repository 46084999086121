import { HStack, Stack, Text } from 'native-base';
import { Controller } from 'react-hook-form';
import LogInput from 'ui/components/v2/inputs/LogInput';
import { MeasurementCategory } from 'ui/enums';
import { colors } from 'ui/theme/colors';

import { useOffPlatformLogInputController } from '~/pages/Authenticated/OffPlatformApplication/components/OffPlatformLogInput/controller';
import { OffPlatformLogInputProps } from '~/pages/Authenticated/OffPlatformApplication/components/OffPlatformLogInput/types';

export const OffPlatformLogInput = (props: OffPlatformLogInputProps) => {
  const { index, measurementUnit, control, errorMessage } = props;

  const { unitLabels } = useOffPlatformLogInputController(props);
  return (
    <HStack
      p={4}
      alignItems={'center'}
      justifyContent={'space-between'}
      borderColor={colors.gray[200]}
      borderRadius={'xl'}
      borderWidth={1}
      backgroundColor={'white'}
    >
      <Text
        width={'55%'}
        fontSize={'sm'}
        fontWeight={500}
        color={colors.gray[600]}
      >
        {unitLabels.plural}
      </Text>

      <Stack width={'50%'}>
        <Controller
          control={control}
          name={`measurements[${index}].amount`}
          render={({ field: { onChange, value } }) => {
            if (measurementUnit.category === MeasurementCategory.Time) {
              return (
                <LogInput.Time
                  value={value}
                  onChange={onChange}
                  errorMessage={errorMessage}
                  showMinutes
                />
              );
            }

            return (
              <LogInput.Custom
                value={value}
                onChange={onChange}
                unitLabel={unitLabels.suffix}
                errorMessage={errorMessage}
              />
            );
          }}
        />
      </Stack>
    </HStack>
  );
};
