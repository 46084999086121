import { Route } from 'react-router-dom';

import { PAGES } from '~/constants/pages.constants';
import { _Test } from '~/pages/_Test';
import SignInMobile from '~/pages/SignInMobile';
import { JoinAsMemberSignIn } from '~/pages/Unauthenticated/JoinAsMemberSignIn';
import { JoinAsMemberSignUp } from '~/pages/Unauthenticated/JoinAsMemberSignUp';
import { ConfirmEmail } from '~/pages/Unauthenticated/PasswordRecovery/ConfirmEmail';
import { ResetPassword } from '~/pages/Unauthenticated/PasswordRecovery/ResetPassword';
import SignIn from '~/pages/Unauthenticated/SignIn';
import AuthorizationPersonalData from '~/pages/Unauthenticated/SignUp/AuthorizationPersonalData';
import SignUp from '~/pages/Unauthenticated/SignUp/CreateAccount';
import EnterAccessCode from '~/pages/Unauthenticated/SignUp/EnterAccessCode';

import { outlet } from '../../routes.outlet';

export const AuthenticationRoutes = (
  <>
    <Route path="/" element={<SignIn />} />
    <Route path="/mobile" element={<SignInMobile />} />
    <Route path="/:slug" element={<SignIn />} />
    <Route path={PAGES.Test} element={<_Test />} />
    <Route path={PAGES.InvitationSignIn} element={<JoinAsMemberSignIn />} />
    <Route path={PAGES.InvitationSignup} element={<JoinAsMemberSignUp />} />
    <Route path={PAGES.InvitationSignupSlug} element={<JoinAsMemberSignUp />} />
    <Route path={PAGES.InvitationSignInSlug} element={<JoinAsMemberSignIn />} />

    <Route element={outlet(false)}>
      <Route path={PAGES.SignUp} element={<SignUp />} />
      <Route path={PAGES.AccessCode} element={<EnterAccessCode />} />
      <Route
        path={PAGES.AuthorizationPersonalData}
        element={<AuthorizationPersonalData />}
      />
      <Route path={PAGES.ConfirmEmail} element={<ConfirmEmail />} />
      <Route path={PAGES.ResetPassword} element={<ResetPassword />} />
    </Route>
  </>
);
