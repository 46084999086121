import { Heading, HStack, Spinner, Text } from 'native-base';

import { colors } from '../../theme/colors';
import { LoadingLinearProps } from './types';

const LoadingLinear = ({
  spinnerSize = 'sm',
  color = colors.darkText,
  label = 'Loading...',
}: LoadingLinearProps) => {
  return (
    <HStack space={2} justifyContent="center" alignItems={'center'}>
      <Spinner size={spinnerSize} color={color} />
      <Heading fontWeight={500} color={color} fontSize="xs">
        {label}
      </Heading>
    </HStack>
  );
};

export default LoadingLinear;
