import { useEffect, useRef, useState } from 'react';
import useIntersection from 'ui/hooks/useIntersection';

export type useInfiniteScrollControllerProps = {
  dataCount: number;
  isLoading?: boolean;
  isFetchingNextPage: boolean;
  isRefetching: boolean;
  hasNextPage?: boolean;
  fetchNextPage?: VoidFunction;
};
const ON_MOUNT_TRIGGER_DELAY = 3000;

export const useInfiniteScrollController = ({
  dataCount,
  isRefetching,
  isFetchingNextPage,
  isLoading = false,
  hasNextPage = false,
  fetchNextPage,
}: useInfiniteScrollControllerProps) => {
  // States
  const topRef = useRef<HTMLElement | null>(null);
  const debounceRef = useRef<NodeJS.Timeout | null>(null);
  const [isBelowTop, setIsBelowTop] = useState(false);

  const [shouldFetch, setShouldFetch] = useState(false);
  const initialDelayRef = useRef<NodeJS.Timeout | null>(null);

  // Hooks
  useEffect(() => {
    initialDelayRef.current = setTimeout(() => {
      setShouldFetch(true);
    }, ON_MOUNT_TRIGGER_DELAY);

    return () => {
      if (initialDelayRef.current) {
        clearTimeout(initialDelayRef.current);
      }
    };
  }, []);

  const handleOnScrollEndReached = () => {
    const preventFetchNextPage =
      !hasNextPage || isFetchingNextPage || isRefetching || isLoading;
    if (preventFetchNextPage || !shouldFetch) return;

    if (debounceRef.current) {
      clearTimeout(debounceRef.current);
    }
    debounceRef.current = setTimeout(() => {
      fetchNextPage?.();
    }, 300);
  };

  const loadTriggerRef = useIntersection(handleOnScrollEndReached);

  // Handlers
  const handleScrollToTop = () => {
    topRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  };

  // Back to top scroll button hook trigger
  useEffect(() => {
    const handleScroll = () => {
      // Check the current scroll position
      const scrollY = window.scrollY || window.pageYOffset; // Current vertical scroll position
      const windowHeight = window.innerHeight; // Height of the viewport

      // Check if the user has scrolled more than one screen height
      const isUserBelowOneScreen = scrollY > windowHeight;

      setIsBelowTop(isUserBelowOneScreen);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Render Statements
  const showBackToTopButton = isBelowTop;

  const isNextPageLoading = isLoading || isFetchingNextPage;

  const isRefetchingLoading = isRefetching && !isFetchingNextPage;

  const isEmpty = !isLoading && !isRefetching && dataCount == 0;

  const isOutOfData = !isRefetching && !hasNextPage && dataCount > 0;

  return {
    isLoading,
    isNextPageLoading,
    isRefetchingLoading,
    topRef,
    isEmpty,
    isOutOfData,
    loadTriggerRef,
    showBackToTopButton,
    handleScrollToTop,
  };
};
