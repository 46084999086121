import { BookingCardActionContentGoal } from '~/compound-components/booking-selection-card/booking-card-action-content/booking-card-action-goals/booking-card-action-goals';

import { BookingCardActionContentRoot } from './booking-card-action-content-root/booking-card-action-content-root';
import { BookingCardActionContentTitle } from './booking-card-action-content-title/booking-card-action-content-title';

export const BookingCardActionContent = {
  Root: BookingCardActionContentRoot,
  Title: BookingCardActionContentTitle,
  Goal: BookingCardActionContentGoal,
};
