export enum AccountMenuOptionsKeys {
  Home = 'home',
  Apps = 'apps',
  Activities = 'activities',
  Teams = 'teams',
  LogActivity = 'log_activity',
  Opportunities = 'opportunities',
  Profile = 'Profile',
  Settings = 'settings',
  SwitchPersona = 'switchPersona',
  GoToMyAdminArea = 'go_to_my_admin_area',
  HelpCentre = 'help_centre',
  SignOut = 'sign_out',
}
