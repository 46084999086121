import {
  Box,
  Flex,
  Hidden,
  HStack,
  Progress,
  Stack,
  Text,
  useMediaQuery,
  VStack,
} from 'native-base';
import Button from 'ui/components/Button';
import Illustration, { ILLUSTRATIONS_NAME } from 'ui/components/Illustration';

import { HelpCentreLink } from '~/components/HelpCentre/HelpCentreLink';
import { colors } from '~/theme/colors';

import { styles } from './styles';
import { FlowTwoColumnProps } from './types';

const FlowTwoColumnsTemplate = ({
  children,
  ilustrationName = ILLUSTRATIONS_NAME.POINTER_UP,
  primaryButtonText,
  secondaryButtonText,
  primaryButtonOnPress,
  title,
  subtitle,
  icon,
  progress = 0,
  progressProps,
  primaryButtonVariant,
  secondaryButtonVariant = 'link',
  primaryButtonTextStyle,
  secondaryButtonTextStyle,
  secondaryButtonOnPress,
  isLoading,
  primaryButtonProps,
  secondaryButtonProps,
  buttonsDirection = 'row',
}: FlowTwoColumnProps) => {
  const [isMobile] = useMediaQuery({
    maxWidth: 480,
  });

  return (
    <Flex flex={1} pb={{ base: 0, md: 4 }}>
      <Hidden from="md">
        <HStack
          pt={2}
          pb={3}
          justifyContent={'flex-end'}
          background="singletons.lightText"
        >
          <HelpCentreLink />
        </HStack>
      </Hidden>

      <Flex
        flex={1}
        flexDirection={styles.flexDirection}
        justifyContent={{ base: 'flex-start', md: 'space-around' }}
      >
        <Flex
          flex={{ base: 'auto', md: 0.35 }}
          mt={{ base: 12, lg: 32 }}
          w={styles.imageContainerW}
          alignItems={{ base: 'center', lg: 'flex-end' }}
        >
          <Illustration
            width={{ base: '128px', md: '200px', lg: '400px' }}
            height={{ base: '128px', md: '200px', lg: '400px' }}
            name={ilustrationName}
          />
        </Flex>
        <Flex
          alignItems="center"
          flex={{ base: 'auto', md: 0.6 }}
          mt={{ base: 0, md: 8 }}
        >
          <Flex
            flex={1}
            w={{ base: '100%', lg: '100%', xl: '75%', '2xl': '50%' }}
            bgColor={isMobile ? 'transparent' : 'white'}
            flexShrink={{ base: 0, md: 1 }}
            borderRadius="2xl"
          >
            <VStack py={2}>
              <Hidden till="md">
                <HStack justifyContent={'flex-end'}>
                  <HelpCentreLink />
                </HStack>
              </Hidden>
              <HStack
                w="full"
                my={2}
                px={4}
                alignItems="center"
                justifyContent="space-between"
              >
                {!isMobile ? (
                  <Stack w="5%" mr={6} alignItems="center">
                    {icon || null}
                  </Stack>
                ) : null}
                <Box w="95%">
                  <Text
                    color="gray.700"
                    fontSize={{ base: 'xl', md: '2xl' }}
                    fontWeight="500"
                  >
                    {title}
                  </Text>
                  {subtitle ? (
                    <Text
                      color={colors.singletons.darkText}
                      fontSize={{ base: 'sm', md: 'md' }}
                      fontWeight="400"
                      mt={2}
                    >
                      {subtitle}
                    </Text>
                  ) : null}
                </Box>
              </HStack>
            </VStack>
            <Progress
              rounded="0"
              size="xs"
              _filledTrack={styles.progressFilledTrack}
              value={progress}
              {...progressProps}
            />
            <Flex flex={1} mb={{ base: 4, md: 0 }}>
              {children || null}
            </Flex>
            <Flex
              flexDirection={isMobile ? 'column-reverse' : buttonsDirection}
              my={{ base: 0, md: 4 }}
              mb={{ base: 4, md: 0 }}
            >
              {secondaryButtonText && (
                <Box flex={1} mx={4} mb={{ base: 0, md: 4 }}>
                  <Button
                    variant={secondaryButtonVariant}
                    onPress={secondaryButtonOnPress}
                    _text={secondaryButtonTextStyle}
                    {...secondaryButtonProps}
                  >
                    {secondaryButtonText}
                  </Button>
                </Box>
              )}
              <Box flex={1} mx={8} mb={4}>
                <Button
                  variant={primaryButtonVariant}
                  onPress={primaryButtonOnPress}
                  _text={primaryButtonTextStyle}
                  isLoading={isLoading}
                  disabled={isLoading}
                  {...primaryButtonProps}
                >
                  {primaryButtonText}
                </Button>
              </Box>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default FlowTwoColumnsTemplate;
