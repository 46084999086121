import { ActivityRegion } from 'ui/types/activities';
import { ILocation } from 'ui/types/interfaces';

import {
  IOption,
  SortByType,
} from '~/pages/Authenticated/InApp/SearchView/types';
import { App } from '~/types/interfaces/app';

export type SearchFilterValues = {
  search: string;
  sortBy: SortByType;
  startDate: Date;
  endDate: Date;
  distanceRadius: number;
  teamsNumber: number;
  causes: IOption[];
  countries: IOption[];
  regions: IOption[];
  showOnline: boolean;
  teamsMinSize: number;
  teamsMaxSize: number;
};

export enum ExecutionPlatform {
  BROWSER = 1,
  WEBVIEW = 2,
  OTHER_DEVICE = 3,
}

export interface InAppState {
  selectedApp?: App;
  platform: ExecutionPlatform;
  location: ILocation | null;
  searchFilters: SearchFilterValues;
  showLocationModal: boolean;
  nationOptions: ActivityRegion[];
  regionOptions: ActivityRegion[];
  isLoading: boolean;
}
