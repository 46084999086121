import { IPermission } from 'ui/types/interfaces/permission';

export enum ValidationTypeEnum {
  // Use the mapped permissions from all apps, without verifying the app. Used in Tabs for now. This will be improved in the future.
  Generic = 'Generic',
  // Used for a granular app permission validation, in case when we have a disabled permission for 1 app and enabled for other app, we can see the app details.
  SpecificApp = 'SpecificApp',
}

export interface PermissionsBooleanProps {
  validationType?: ValidationTypeEnum;
  requiredPermissions?: IPermission[] | Partial<IPermission>[] | [];
  availablePermissions: IPermission[] | Partial<IPermission>[] | [];
  fallbackComponent?: JSX.Element | null;
  // Temporary fallback for volunteer guest user
  bypassPermissions?: boolean;
  children: JSX.Element;
}
