import { ActivityCardSkeleton } from 'ui/components/v2/Cards/ActivityCard/Skeleton';

export const MakeActivityCarouselItemSkeletonSlides = ({
  rows,
}: {
  rows: number;
}) => {
  const items = Array(rows).fill(0);
  return items.map((_, index) => {
    return { element: <ActivityCardSkeleton key={index} /> };
  });
};
