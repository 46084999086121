import { colors } from 'ui/theme/colors';
import { ActivitySemanticType } from 'ui/types/activities';

import { ActivityTypeBadgeStyle } from './types';

export const DEFAULT_BADGE_STYLE = {
  backgroundColor: colors.white,
  icon: 'clock',
  text: '...',
};

export const ActivityTypeBadgeStyleMap = new Map<
  ActivitySemanticType,
  ActivityTypeBadgeStyle
>([
  [
    ActivitySemanticType.Event,
    {
      text: 'EVENT',
      backgroundColor: colors.activity['event'],
      icon: 'calendar',
    },
  ],
  [
    ActivitySemanticType.Action,
    {
      text: 'ACTION',
      backgroundColor: colors.activity['action'],
      icon: 'hand-pointer',
    },
  ],
  [
    ActivitySemanticType.Ongoing,
    {
      text: 'ONGOING',
      backgroundColor: colors.activity['ongoing'],
      icon: 'repeat',
    },
  ],
  [
    ActivitySemanticType.TeamEvent,
    {
      text: 'TEAM EVENT',
      backgroundColor: colors.activity['event'],
      icon: 'users',
    },
  ],
]);
