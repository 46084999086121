export const COGNITO_ERRORS_CODE = {
  NOT_AUTHORIZED_EXCEPTION: 'NotAuthorizedException',
  USER_NOT_CONFIRMED_EXCEPTION: 'UserNotConfirmedException',
};

export const COMMON_ERRORS = {
  ERROR_ON_LOAD: 'Error on load data',
  ERROR_ON_LOAD_EVENT: 'Error on loading event',
  ERROR_ON_LOAD_EVENTS: 'Error on loading events',
  ERROR_ON_LOAD_VOLUNTEERS: 'Error on loading volunteers',
  ERROR_ON_LOAD_OPPORTUNITIES: 'Error on loading opportunities',
};

export enum CognitoErrorCode {
  UserNotConfirmed = 'UserNotConfirmedException',
  UsernameExistsException = 'UsernameExistsException',
  ExpiredCode = 'ExpiredCodeException',
  NotAuthorized = 'NotAuthorizedException',
  CodeMismatch = 'CodeMismatchException',
}

export enum CognitoErrorCodeMessages {
  UserNotConfirmed = `We've sent you a confirmation code to your email address`,
  UsernameExistsException = `An account with the given email already exists.`,
  ExpiredCode = `Code expired. Please request a new one.`,
  NotAuthorized = `Incorrect email or password.`,
  CodeMismatch = `Invalid verification code provided, please try again.`,
  OldPasswordIncorrect = 'Incorrect username or password.',
  ChangePasswordLimitAttempts = 'Attempt limit exceeded, please try after some time.',
}

export interface CognitoError {
  code: CognitoErrorCode;
  message: string;
}
