import {
  Organization,
  OrganizationType,
} from 'ui/types/interfaces/organization';
import { User } from 'ui/types/interfaces/user';
import { UserProfile } from 'ui/types/user';

export interface VolunteerUserFieldsDto {
  name?: string;
  address?: string;
  birthDate?: Date | string;
  phoneNumber?: string;
  profilePicture?: string;
}

export interface EmployeeUserFieldsDto extends VolunteerUserFieldsDto {
  group?: string;
  externalId?: string;
}

export const calculateUserProfileProgress = (
  user: User,
  userProfile: UserProfile,
  organization: Organization,
) => {
  const volunteerFields: VolunteerUserFieldsDto = {
    name: user.name,
    address: user?.preferences?.address?.street,
    birthDate: user?.birthDate,
    phoneNumber: user.phoneNumber,
    profilePicture: userProfile.profilePicture,
  };

  let requiredAttributesCount = Object.keys(volunteerFields).length;
  let filledAttributesCount = Object.values(volunteerFields).filter(
    (v) => !!v,
  ).length;

  if (organization.type === OrganizationType.COMPANY) {
    const { organizationSettings } = organization;

    const employeeFields: EmployeeUserFieldsDto = {
      ...volunteerFields,
      externalId: userProfile?.externalId,
      group: userProfile?.organizationDepartment,
    };

    if (organizationSettings?.hasExternalId !== true) {
      delete employeeFields.externalId;
    }

    if (!organizationSettings?.departments?.length) {
      delete employeeFields.group;
    }

    requiredAttributesCount = Object.keys(employeeFields).length;
    filledAttributesCount = Object.values(employeeFields).filter(
      (v) => !!v,
    ).length;
  }

  const percentProgress =
    (filledAttributesCount / requiredAttributesCount) * 100;

  return percentProgress;
};
