import { Text } from 'native-base';
import { useState } from 'react';
import DoitLogo from 'ui/assets/svgs/doit-new-logo-white.svg';
import { AlertBar } from 'ui/components/v2/Alerts/AlertBar';
import { colors } from 'ui/theme/colors';

export type MaintenanceAlertBarProps = {
  onPress?: VoidFunction;
  defaultShow?: boolean;
};

export const MaintenanceAlertBar = ({
  defaultShow = false,
  onPress,
}: MaintenanceAlertBarProps) => {
  const [show, setShow] = useState(defaultShow);

  const handleJoinAction = () => {
    onPress?.();
    setShow((prev) => !prev);
  };

  if (!show) return null;

  return (
    <AlertBar.Mixed
      backgroundColor={colors.gray[800]}
      actionLabel="Login to Doit Life"
      onAction={handleJoinAction}
      bottomOffset={'84px'}
      logoSrc={DoitLogo}
      showCloseButton
    >
      <Text
        fontSize={{ base: 'xs', md: 'md' }}
        lineHeight={'150%'}
        fontWeight={400}
        color={colors.singletons.white}
      >
        {`We’re currently in maintenance to roll out exciting new features. Thank you for your patience!`}
      </Text>
    </AlertBar.Mixed>
  );
};
