import { Box, Hidden, HStack, Text, VStack } from 'native-base';
import { FilterButton } from 'ui/components/v2/Buttons/FilterButton';
import { colors } from 'ui/theme/colors';

import {
  CenterContainer,
  CenterContainerSize,
} from '~/components/Layouts/Scaffolds/CenterContainer';
import { SortByButton } from '~/components/Layouts/v2/SearchLayout/components/SortByButton';
import { useSearchFeedHeaderController } from '~/pages/Authenticated/v2/Search/components/SearchFeed/FeedHeader/controller';

export type SearchFeedHeaderProps = {
  searchText?: string;
  resultsCount?: number;
};
export const SearchFeedHeader = ({
  resultsCount = 0,
  searchText = '',
}: SearchFeedHeaderProps) => {
  const {
    sortByValue,
    filtersCount,
    sortByOptions,
    handleOpenFilterModal,
    handleChangeSortByValue,
  } = useSearchFeedHeaderController();

  const hasSearchText = !!searchText.length;

  return (
    <VStack>
      <CenterContainer size={CenterContainerSize.xl}>
        <VStack space={{ base: 3, sm: 6 }}>
          <HStack
            space={{ base: 0, sm: hasSearchText ? 4 : 0 }}
            alignItems={'center'}
          >
            <Hidden till="md">
              <Text
                fontSize={'md'}
                fontWeight={500}
                color={colors.singletons.darkText}
              >
                {hasSearchText && `Searching for ”${searchText}”`}
              </Text>
            </Hidden>
            {resultsCount ? (
              <Text
                fontSize={{ base: 'xs', sm: 'sm' }}
                fontWeight={400}
                color={colors.gray[500]}
              >
                {`${resultsCount} result${resultsCount === 1 ? '' : 's'} found`}
              </Text>
            ) : null}
          </HStack>

          <HStack justifyContent={'space-between'}>
            <Box>
              <FilterButton.Base
                outlined
                label="Filter"
                icon="sliders"
                onPress={handleOpenFilterModal}
                filterCount={filtersCount}
              />
            </Box>

            <Box>
              <SortByButton
                value={sortByValue}
                onChange={handleChangeSortByValue}
                sortByOptions={sortByOptions}
              />
            </Box>
          </HStack>
        </VStack>
      </CenterContainer>
    </VStack>
  );
};

export default SearchFeedHeader;
