import { HStack, Pressable, Text } from 'native-base';
import Icon from 'ui/components/Icon';
import LoadingLinear from 'ui/components/LoadingLinear';
import { colors } from 'ui/theme/colors';

import { LocationButtonProps } from './types';

export const LocationButton = ({
  text,
  textColor = colors.singletons.black,
  iconColor = colors.singletons.black,
  maxWidth = '240px',
  width = 'full',
  onPress,
  isLoading = false,
}: LocationButtonProps) => {
  return (
    <Pressable
      width={width}
      maxWidth={maxWidth}
      onPress={onPress}
      alignItems="flex-start"
    >
      <HStack
        space={2}
        w="full"
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <HStack space={2} alignItems={'center'}>
          {isLoading ? (
            <LoadingLinear label="checking location..." />
          ) : (
            <>
              <Icon icon={'map-pin'} color={iconColor} size={16} />
              <Text
                fontSize="sm"
                numberOfLines={1}
                flex={1}
                maxW={maxWidth}
                color={textColor}
                lineHeight={'125%'}
                mr={2}
              >
                {text || 'Select your location'}
              </Text>
            </>
          )}
        </HStack>

        <Icon icon="chevron-down" color={iconColor} size={16} />
      </HStack>
    </Pressable>
  );
};
