import { useEffect, useMemo, useState } from 'react';
import { SearchActivityDefinition } from 'ui/types/interfaces/activity-definition';
import { mapSearchActivityDefinition } from 'ui/utils/activity/mappers';

import { useSearchRoutes } from '~/components/Layouts/v2/SearchLayout/hooks/use-search-routes';
import { useInfinityActivityDefinitionUnifiedSearch } from '~/hooks/activity-definitions/useInfinityActivityDefinitionUnifiedSearch';
import { useVolunteerEcosystemAvailableApps } from '~/hooks/useApps/useVolunteerEcosystemAvailableApps';
import { useInfiniteScrollController } from '~/hooks/useInfinityLoad/useinfiniteScrollController';
import { useUserLocationController } from '~/hooks/useUserLocation/useUsersLocation';
import { UnifiedSearchFilters } from '~/services/resources/activity-definitions/search/unified/unified-public-search.types';
import { mapUnifiedSearchApiFilters } from '~/utils/activity-search/map-unified-search-api-filters';
import { useInApp } from '~/zustand/store/InApp';
import { useSearch } from '~/zustand/store/Search';

import useAnalytics from '../../../../../../hooks/useAnalytics/useAnalytics';
import { mapSuggestionCollections } from './mappers/mapSuggestions';

export const SEARCH_FEED_ITEMS_PER_PAGE = 15;

export const SearchFeedController = () => {
  // Providers
  const { app } = useInApp((state) => state);
  const { goToActivityDetails } = useSearchRoutes();
  const { track } = useAnalytics();

  const { appliedFilters, filtersAppliedAt, suggestions } = useSearch(
    (state) => state,
  );
  const setSuggestions = useSearch((state) => state.setSuggestions);

  const { appIds } = useVolunteerEcosystemAvailableApps();
  const { coordinates } = useUserLocationController();

  // States
  const [isFilterSet, setIsFilterSet] = useState<boolean>(false);
  const [usersFilters, setUsersFilters] = useState<
    Partial<UnifiedSearchFilters>
  >({});

  // Hooks
  useEffect(() => {
    const isInApp = !!app;

    if (!appIds || !coordinates || !appliedFilters?.relevance) {
      setIsFilterSet(false);
      return;
    }
    // Define carousel suggestions
    setSuggestions(mapSuggestionCollections(appliedFilters));
    const appsToFilters = isInApp ? [app._id] : appIds;

    // Apply Api Filters
    setUsersFilters(
      mapUnifiedSearchApiFilters(appsToFilters, coordinates, appliedFilters),
    );

    /* Emit search text to firebase */
    if (appliedFilters.searchText) {
      track('search', {
        content: appliedFilters.searchText,
        filters: JSON.stringify(appliedFilters),
      });
    }

    setIsFilterSet(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [app, appliedFilters, appIds, coordinates]);

  const {
    data,
    isLoading,
    dataUpdatedAt,
    hasNextPage,
    isRefetching,
    isFetchingNextPage,
    fetchNextPage,
  } = useInfinityActivityDefinitionUnifiedSearch(
    { ...usersFilters },
    isFilterSet,
    SEARCH_FEED_ITEMS_PER_PAGE,
  );
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataUpdatedAt, isRefetching, isFetchingNextPage]);

  const activityDefinitions = useMemo(() => {
    if (!isFilterSet || !data?.length || isLoading || !usersFilters.relevance)
      return [];

    return mapSearchActivityDefinition(usersFilters.position)(data);
  }, [isFilterSet, isLoading, data, usersFilters]);

  // Handlers

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleActivityCardPress = (item: SearchActivityDefinition) => {
    goToActivityDetails(item._id, item?.selectedActivity?._id);
  };

  const infiniteScroll = useInfiniteScrollController({
    isLoading,
    hasNextPage,
    isRefetching,
    isFetchingNextPage,
    dataCount: activityDefinitions.length,
    fetchNextPage,
  });

  return {
    hasNextPage,
    suggestions,
    appliedFilters,
    infiniteScroll,
    activityDefinitions,
    filtersAppliedAt,
    handleScrollToTop,
    handleActivityCardPress,
    fetchNextPage,
  };
};
