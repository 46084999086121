export enum InputSize {
  sm = '42px',
  md = '46px',
  lg = '56px',
}

export interface CommonInputProps {
  label?: string;
  name?: string;
  helpText?: string;
  isRequired?: boolean;
  isReadOnly?: boolean;
  isDisabled?: boolean;
  errorMessage?: string;
  placeholder?: string;
  size?: InputSize;
  fontSize?: string | number;
  onBlur?: VoidFunction;
}
