import './styles.css';

import { Box } from 'native-base';
import React, { useMemo } from 'react';

import {
  CenterContainer,
  CenterContainerSize,
} from '~/components/Layouts/Scaffolds/CenterContainer';

import { GridRowProps } from './types';

const GridRow = ({
  data = [],
  gridColumns,
  renderItem,
  renderSkeletonItem,
  keyExtractor,
  showSkeleton = true,
}: GridRowProps<unknown>) => {
  const gridTemplateColumns = useMemo(() => {
    return `repeat(${gridColumns}, 1fr)`;
  }, [gridColumns]);

  const filledData = useMemo(() => {
    const emptySlots = gridColumns - data.length;
    if (emptySlots > 0) {
      return [...data, ...Array(emptySlots).fill(null)];
    }
    return data;
  }, [data, gridColumns]);

  return (
    <Box h={'100%'} my={4} alignItems={'center'} justifyContent={'center'}>
      <CenterContainer size={CenterContainerSize.xl}>
        <div className="grid-row-container" style={{ gridTemplateColumns }}>
          {filledData?.map((item, index: number) => {
            return (
              <Box
                w="full"
                alignItems={'center'}
                justifyContent={'flex-start'}
                key={item && keyExtractor ? keyExtractor(item, index) : index}
              >
                {item
                  ? renderItem(item, index)
                  : renderSkeletonItem && showSkeleton
                  ? renderSkeletonItem()
                  : null}
              </Box>
            );
          })}
        </div>
      </CenterContainer>
    </Box>
  );
};

export default React.memo(GridRow);
