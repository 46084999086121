import './card-recomendation.css';

import {
  Flex,
  HStack,
  Pressable,
  Skeleton,
  Stack,
  Text,
  VStack,
} from 'native-base';
import React from 'react';
import Button from 'ui/components/Button';
import { ActivityTypeEnum } from 'ui/enums';

import { useCardRecommendationsController } from '~/components/ActivityCards/CardRecommendations/cardRecommendations.controller';
import {
  ActivityCardRecommendationsProps,
  ActivityCardRecommendationsSkeletonListProps,
} from '~/components/ActivityCards/CardRecommendations/cardRecommendations.types';
import { ActivitySummaryTitle } from '~/components/ActivityCards/CardRecommendations/components/ActivitySummaryTitle';
import { CardRecommendationsAvatar } from '~/components/ActivityCards/CardRecommendations/components/CardRecommendationsAvatar';
import { CardRecommendationsHeader } from '~/components/ActivityCards/CardRecommendations/components/CardRecommendationsHeader';
import { CardRecommendationsInfoContent } from '~/components/ActivityCards/CardRecommendations/components/CardRecommendationsInfoContent';
import { CardRecommendationsInteractions } from '~/components/ActivityCards/CardRecommendations/components/CardRecommendationsInteractions';
import { CardRecommendationsRequirementSection } from '~/components/ActivityCards/CardRecommendations/components/CardRecommendationsRequirementSection';

export const ActivityCardRecommendations = ({
  activity,
  isGuest,
}: ActivityCardRecommendationsProps) => {
  const {
    isOpen,
    onToggle,
    eventSummary,
    handleGoToActivityDetail,
    handleGoToGuestActivityDetail,
  } = useCardRecommendationsController({
    activity,
  });

  return (
    <Pressable onPress={onToggle} flexShrink={0}>
      <div className={`card-recommendations${isOpen ? '--open' : ''}`}>
        <Flex
          flexShrink={0}
          w={'full'}
          borderWidth={1}
          borderColor={'gray.300'}
          padding={{ base: 3, lg: 4 }}
          borderRadius={12}
          bg={'gray.50'}
        >
          <HStack flex={1} w={'100%'} justifyContent={'space-between'}>
            <VStack
              flex={1}
              w={'fit-content'}
              space={'2.5'}
              maxW={{ base: '100%', lg: '100%' }}
            >
              <CardRecommendationsHeader
                activityType={eventSummary.activityType as ActivityTypeEnum}
                isTeamEvent={eventSummary.isTeamEvent}
                headerText={eventSummary.headerText}
              />

              <HStack h={'fit-content'} space={2} w={'100%'}>
                <CardRecommendationsAvatar
                  logo={eventSummary.organizationLogo}
                  initials={eventSummary.organizationInitials}
                />

                <VStack space={3} w={'100%'}>
                  <ActivitySummaryTitle
                    activityName={eventSummary.activityName}
                    appName={eventSummary.appName}
                    organizationName={eventSummary.organizationName}
                  />

                  <CardRecommendationsInfoContent
                    infos={eventSummary.shownInfos}
                  />
                </VStack>
              </HStack>
            </VStack>

            <CardRecommendationsInteractions
              isOpen={isOpen}
              primaryAction={{
                text:
                  eventSummary.activityType === ActivityTypeEnum.Action
                    ? 'Join'
                    : 'Apply',
                onPress: () =>
                  isGuest
                    ? handleGoToGuestActivityDetail(activity.activityDefinition)
                    : handleGoToActivityDetail(activity.activityDefinition),
              }}
            />
          </HStack>
          <div className={'card-recommendations-content'}>
            <VStack
              padding={2}
              borderRadius={12}
              ml={{ base: 0, lg: 12 }}
              space={2}
              pt={6}
            >
              {eventSummary.hiddenInfos?.map((infoRow, index) => {
                return (
                  <CardRecommendationsInfoContent
                    key={'infos-row-' + index}
                    infos={infoRow}
                    minW={'2xs'}
                  />
                );
              })}

              <CardRecommendationsRequirementSection
                requirementOptions={eventSummary.requirementOptions}
                volunteerRequirements={eventSummary.volunteerRequirements}
                volunteerRewards={eventSummary.volunteerRewards}
              />

              <Stack mt={4} display={{ base: 'flex', lg: 'none' }}>
                <Button
                  w={'container'}
                  bg={'singletons.black'}
                  onPress={() =>
                    handleGoToActivityDetail(activity.activityDefinition)
                  }
                >
                  <Text color={'singletons.white'}>
                    {eventSummary.activityType === ActivityTypeEnum.Action
                      ? 'Join'
                      : 'Apply'}
                  </Text>
                </Button>
              </Stack>
            </VStack>
          </div>
        </Flex>
      </div>
    </Pressable>
  );
};

export const ActivityCardRecommendationsSkeleton = () => {
  return (
    <Flex
      w={'full'}
      borderWidth={1}
      borderColor={'gray.300'}
      padding={{ base: 3, lg: 4 }}
      borderRadius={12}
      bg={'gray.50'}
      // minH={{ base: '32', lg: '' }}
    >
      <HStack flex={1} justifyContent={'space-between'}>
        <VStack space={3}>
          <HStack space={6} alignItems={'center'}>
            <Skeleton h="6" w={'20'} rounded="full" />
            <Skeleton
              h="3"
              w={{
                base: 100,
                md: 100,
                lg: 150,
              }}
              rounded="full"
            />
          </HStack>
          <VStack
            space={'2.5'}
            ml={{
              base: -1,
              lg: 2,
            }}
          >
            <HStack space={2}>
              <Stack ml={-2} display={{ base: 'none', lg: 'flex' }}>
                <Skeleton h={12} w={12} rounded="full" />
              </Stack>
              <VStack justifyContent={'space-between'}>
                <HStack space={3} alignItems={'center'}>
                  <Skeleton
                    h="3"
                    w={{ base: 70, md: 100, lg: 150 }}
                    rounded="full"
                  />
                  <Skeleton
                    h="2.5"
                    w={{ base: 30, md: 90, lg: 100 }}
                    rounded="full"
                  />
                  <Skeleton
                    h="2.5"
                    w={{ base: 30, md: 90, lg: 100 }}
                    rounded="full"
                  />
                </HStack>
                <Skeleton
                  h="3"
                  w={140}
                  rounded="full"
                  display={{ base: 'none', lg: 'flex' }}
                />
              </VStack>
            </HStack>
            <HStack space={2} display={{ base: 'flex', lg: 'none' }}>
              <VStack mt={'3'} ml={2}>
                <Skeleton
                  h={{ base: '2', sm: '3' }}
                  w={{ base: '40', md: 100, lg: 150 }}
                  rounded="full"
                />
              </VStack>
            </HStack>
          </VStack>
        </VStack>
      </HStack>
    </Flex>
  );
};

export const ActivityCardRecommendationsSkeletonList = ({
  quantity,
}: ActivityCardRecommendationsSkeletonListProps) => {
  const listOfSkeletons = quantity ? Array(quantity).fill(0) : [1];

  return (
    <VStack space={4}>
      {listOfSkeletons.map((_, index) => {
        return <ActivityCardRecommendationsSkeleton key={index} />;
      })}
    </VStack>
  );
};
