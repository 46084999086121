import { Stack, useBreakpointValue } from 'native-base';
import Modal from 'ui/components/Modals/Modal';

import { colors } from '~/theme/colors';

import { ModalRemoveProps } from './types';

const ModalRemove = ({
  modalRef,
  handleCloseModal,
  handleDelete,
  headerText,
  children,
  rightBtnLoading,
  rightBtnText = 'Yes, delete',
}: ModalRemoveProps) => {
  return (
    <Modal
      headerText={headerText}
      ref={modalRef}
      modalContentMaxW={{ base: '100vw', lg: '669' }}
      modalContentMaxH={{ base: '100vh', lg: 'auto' }}
      modalContentW={{ base: '80vw', lg: '669' }}
      headerFontSize={{ base: 'md', lg: '2xl' }}
      btnFontWeight="normal"
      leftBtnAction={handleCloseModal}
      leftBtnText="No, go back"
      rightBtnAction={handleDelete}
      rightBtnText={rightBtnText}
      rightBtnBgColor={colors.error[600]}
      rightBtnLoading={rightBtnLoading}
      // Fix responsive modal on mobile scroll view.
      _overlay={{ style: { position: 'fixed' } as any }}
      modalContentProps={{ mt: 0 }}
    >
      <Stack py="2" space={6}>
        <Stack>{children}</Stack>
      </Stack>
    </Modal>
  );
};

export default ModalRemove;
