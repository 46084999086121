import { useBreakpointValue } from 'native-base';
import { useState } from 'react';

export const useLogProgressAndHistoryRootController = () => {
  const [isInsideExpanded, setIsInsideExpanded] = useState(false);

  const breakpointValue = useBreakpointValue({
    lg: {
      hideArrow: false,
      contentWidth: 'full',
    },
    base: {
      hideArrow: true,
      contentWidth: 'full',
    },
  });

  const toggleIsExpanded = () => {
    setIsInsideExpanded((prev) => !prev);
  };

  return {
    toggleIsExpanded,
    isInsideExpanded,
    breakpointValue,
  };
};
