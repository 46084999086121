import { HStack, Text } from 'native-base';
import React from 'react';
import Icon from 'ui/components/Icon';

import { BookingCardActionContentGoalProps } from './booking-action-goals.types';

export const BookingCardActionContentGoal = ({
  text,
  icon,
}: BookingCardActionContentGoalProps) => {
  return (
    <HStack alignItems={'center'} space={2} mt={2}>
      <Icon isCustom size={10} icon={icon} />
      <Text color={'gray.600'} fontWeight="700" fontSize="3xl">
        {text}
      </Text>
    </HStack>
  );
};
