import FeatherIcon from 'feather-icons-react';
import { Button as NBComponent } from 'native-base';

import { ICustomButtonProps } from './types';

const Button = ({
  children,
  leftIconName,
  leftIconColor = 'white',
  leftIconSize = 16,
  rightIconColor = 'white',
  rightIconSize = 16,
  rightIconName,
  borderRadius = 12,
  minW,
  ...rest
}: ICustomButtonProps) => {
  return (
    <NBComponent
      borderRadius={borderRadius}
      minW={minW || 16}
      leftIcon={
        leftIconName ? (
          <FeatherIcon
            icon={leftIconName}
            size={leftIconSize}
            color={leftIconColor}
          />
        ) : undefined
      }
      rightIcon={
        rightIconName ? (
          <FeatherIcon
            icon={rightIconName}
            size={rightIconSize}
            color={rightIconColor}
          />
        ) : undefined
      }
      {...rest}
    >
      {children}
    </NBComponent>
  );
};

export default Button;
