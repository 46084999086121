import { useBreakpointValue } from 'native-base';
import { useEffect, useRef, useState } from 'react';
import { BreakPointValues } from 'ui/components/v2/Carousel/PrimaryCarousel/types';

import {
  DEFAULT_COLUMNS,
  DEFAULT_SPAN_PER_ITEMS,
} from '~/components/v2/List/Grid/constants';

import { GridProps } from './types';

export const useGridController = ({
  data,
  isLoadingForwardData,
  columns = DEFAULT_COLUMNS,
  spanPerRows = DEFAULT_SPAN_PER_ITEMS,
}: GridProps<unknown, unknown>) => {
  const prevGridColumns = useRef(0);
  const prevGroupedDataLengthRef = useRef(0);
  const [isLoading, setIsLoading] = useState(false);
  const [groupedData, setGroupedData] = useState<unknown[][]>([]);

  const gridColumns: number = useBreakpointValue(columns as BreakPointValues);
  const spanPerRowsValue = useBreakpointValue(spanPerRows as BreakPointValues);

  // hooks
  useEffect(() => {
    if (
      isLoadingForwardData ||
      prevGroupedDataLengthRef.current < data.length
    ) {
      setIsLoading(true);
    }
  }, [data, isLoadingForwardData]);

  useEffect(() => {
    if (
      !gridColumns ||
      (prevGridColumns.current === gridColumns &&
        prevGroupedDataLengthRef.current === data.length)
    ) {
      return;
    }

    prevGroupedDataLengthRef.current = data.length;
    prevGridColumns.current = gridColumns;

    const groupSize = gridColumns;
    const _groups: unknown[][] = Array.from(
      { length: Math.ceil(data.length / groupSize) },
      (_, groupIndex) =>
        data.slice(groupIndex * groupSize, (groupIndex + 1) * groupSize),
    );

    setGroupedData(_groups);
    requestAnimationFrame(() => {
      setIsLoading(false);
    });
  }, [data, isLoadingForwardData, gridColumns]);

  return {
    isLoadingForward: isLoading,
    gridColumns,
    spanPerRowsValue,
    groupedData,
  };
};
