import { useEffect } from 'react';

export const useListenToBrowserBackAction = ({
  isEnabled,
  onBackAction,
}: {
  isEnabled: boolean;
  onBackAction: () => void;
}) => {
  useEffect(() => {
    if (isEnabled) {
      // Add a fake history route to avoid the back button from redirecting to the previous page
      window.history.pushState(
        'fake-route',
        document.title,
        window.location.href,
      );

      addEventListener('popstate', onBackAction);
      return () => {
        removeEventListener('popstate', onBackAction);
        // If we exit the page without using the back button, we need to clear the fake route from the history
        if (window.history.state === 'fake-route') {
          window.history.back();
        }
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEnabled]);
};
