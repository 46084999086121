import { Box, useBreakpointValue } from 'native-base';
import Drawer from 'react-modern-drawer';
import { colors } from 'ui/theme/colors';

import { MobileDrawerProps } from './types';

export const MobileDrawer = (props: MobileDrawerProps) => {
  const { children, isOpen = false, onClose } = props;

  const size = useBreakpointValue({ base: '100%', md: '400px' });

  return (
    <Drawer
      zIndex={999}
      open={isOpen}
      onClose={onClose}
      direction="right"
      overlayColor={colors.black}
      overlayOpacity={0.3}
      duration={200}
      size={size}
      lockBackgroundScroll
    >
      <Box h="full" overflowY={'hidden'} backgroundColor={colors.white}>
        {children}
      </Box>
    </Drawer>
  );
};

export default MobileDrawer;
