export const APPLICATION_POPULATE = [
  {
    model: 'Measurement',
    path: 'measurements',
    populate: {
      model: 'MeasurementUnits',
      path: 'measurementUnit',
    },
  },
  {
    model: 'App',
    path: 'app',
  },
];

export const APPLICATION_PROJECTION = {
  userProfileSubDocument: 0,
  activitySubDocument: 0,
};
